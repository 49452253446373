import axios from 'axios';

export const editPriceUnit = async (newName: string, id: string): Promise<{ name: string, _id: string }> => {
  const response = await axios.put(`/api/priceUnits/${id}`, {
    name: newName
  });

  return response.data;
}

export const deletePriceUnit = async (id: string): Promise<void> => {
  const response = await axios.delete(`/api/priceUnits/${id}`);

  return response.data;
}