import React from 'react';
import _ from 'lodash';
import { NotificationManager } from 'react-notifications';
import ReactLoading from 'react-loading';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { injectIntl } from "react-intl";
import { ServiceSupplier } from '../../services/service-supplier';
import { serviceAreas } from '../../services/service-areas';
import serviceCreditSafe from '../../services/service-creditSafe';
import services from '../../services';
import BaseComponent from '../BaseComponent';
import { ReasonBox } from './reason-box';
import appState from '../../state/AppStateContainer';
import { validateEmail, validateOptionalPhone } from '../../util';
import { checkOrgNo, isValidOrganizationNumber } from '../../utils/bt-number';
import './app-add-supplier.css';
import 'react-toggle/style.css';
import { CustomToggle } from '../../components/shared';
import MSGBubbleIcon from '../../assets/images/msg_bubble.png'
import TrashIcon from '../../components/svgs/trashIcon';
import Field from '../../components/shared/Field/Field';
import BtButton from '../bt-button/bt-button';
import Modal from '../../components/shared/Modal/Modal';
import Selector from '../../components/shared/Selector/Selector';
import { ReactComponent as ClipIcon } from '../../assets/svg/clip.svg';
import DatePicker from '../../components/shared/DatePicker/DatePicker';
import File from '../../components/File/File';
import { CrossSvg } from '../../components/svgs';
import SupplierMemberContainer from '../page-quotation/ContactInfoForm/SupplierMemberContainer/SupplierMemberContainer';
import SuggestedContactContainer from '../page-quotation/ContactInfoForm/SupplierMemberContainer/SuggestedContactContainer';
import moment from 'moment';
import { regions } from "../../staticData/regions";
import SupplierControls from './supplier-controls';
import ImagePreview from '../../components/shared/ImagePreview/ImagePreview';
import { FileScope } from '../../util/appEnum';
import FileUpload from '../../components/shared/FileUpload/FileUpload';
import StyledCheckbox from '../../components/shared/StyledCheckbox/StyledCheckbox';
import RecommendedAd from './recommended-ad';
import LogoForm from './logoForm';
import TooltipContainer from '../../components/shared/TooltipContainer/TooltipContainer';
import { ReactComponent as VerifiedIcon } from '../../assets/svg/verifiedIcon.svg';
import { ReactComponent as InfoIcon } from '../../assets/svg/InfoIconLight.svg';
import FileCommentDate from './file-comment-date';
import serviceSales from '../../services/service-sales';

const identityFieldOptions = [
  {label: 'Org.nr', value: 'organizationNumber'},
  {label: 'VAT-nummer', value: 'vatNumber'},
];

const newContact = () => ({tempId: Date.now(), name: '', email: '', phone: '', title: '', editMode: true, errors: {}, isRemoved: false});

const invoiceIntervalOptions = [
  {label:"6 månader: 15 000 kr", value: "6 månader: 15 000 kr"}, 
  {label: "12 månader: 25 000 kr", value: "12 månader: 25 000 kr"}
];
class AppAddEditSupplier extends BaseComponent {

  getSelectedIdentityField = (data) => {
    const selectedOption = identityFieldOptions.find(option => option.value === data.selectedIdentityField);

    if (selectedOption) return selectedOption;

    if (`${data.organizationNumber}`.startsWith('0') && data.vatNumber) {
      return { label: 'VAT-nummer', value: 'vatNumber' };
    }

    return { label: 'Org.nr', value: 'organizationNumber' };
  }

  constructor(props) {
    super(props);

    const editSupplierData = props.editSupplierData;
    const supplierProfile = props.editSupplierData?.supplierProfile ?? this.emptySupplierProfile();
    console.log({supplierProfile});

    this.state = {
      showMsgBubble: false,
      suggestions: [],
      data: {
        name: editSupplierData.name,
        city: editSupplierData.city,
        areaIds: editSupplierData.areaIds || [],
        organizationNumber: editSupplierData.organizationNumber,
        vatNumber: editSupplierData.vatNumber,
        selectedIdentityField: this.getSelectedIdentityField(editSupplierData),
        website: editSupplierData.website,
        description: editSupplierData.description,
        address: editSupplierData.address,
        postnumber: editSupplierData.postnumber,
        kommun: editSupplierData.kommun,
        isBlacklist: editSupplierData.isBlacklist ? editSupplierData.isBlacklist : false,
        blacklistReason: editSupplierData.blacklistReason ? editSupplierData.blacklistReason : [],
        frameworkAgreement: editSupplierData.frameworkAgreement,
        frameworkAgreementDate: editSupplierData.frameworkAgreement && editSupplierData.frameworkAgreement.validUntilDate,
        frameworkAgreementComment: editSupplierData.frameworkAgreement && editSupplierData.frameworkAgreement.comment,
        updateFrameworkAgreement: false,
        liabilityInsurance: editSupplierData.liabilityInsurance,
        liabilityInsuranceDate: editSupplierData.liabilityInsurance && editSupplierData.liabilityInsurance.validUntilDate,
        liabilityInsuranceComment: editSupplierData.liabilityInsurance && editSupplierData.liabilityInsurance.comment,
        updateLiabilityInsurance: false,
        profileLiabilityInsurance: supplierProfile?.liabilityInsurance,
        profileLiabilityInsuranceDate: supplierProfile?.liabilityInsurance?.validUntilDate,
        profileLiabilityInsuranceComment: supplierProfile?.liabilityInsurance?.comment,
        updateLiabilityInsurance: false,
        ue2021Form: editSupplierData.ue2021Form,
        ue2021FormDate: editSupplierData.ue2021Form && editSupplierData.ue2021Form.submittedDate,
        ue2021FormComment: editSupplierData.ue2021Form && editSupplierData.ue2021Form.comment,
        updateUE2021Form: false,
        existingOtherFiles: editSupplierData.otherFiles || [],
        newOtherFiles: [],
        logo: editSupplierData.logo,
        updateLogo: false,
        isFeatured: editSupplierData?.isFeatured,
        featuredInfo: editSupplierData?.featuredInfo,
        updateFeaturedInfoImage: false,
        message: "",
        invoiceInterval: invoiceIntervalOptions[0].value,
      },
      files: {},
      supplierId: editSupplierData.supplierId,
      slide: 1,
      suggestionType: 'city',
      suggestionLimit: 5,
      checkedAreaItems: [],
      dropDownCities: [],
      formErrors: {},
      defaultAreaIds: [],
      orgNumLoader: false,
      areas: [],
      contacts: editSupplierData.contacts?.length ? editSupplierData.contacts : [newContact()],
      editFrameworkAgreement: false,
      editLiabilityInsurance: false,
      editUE2021Form: false,
      ads: [],
      showQuickCheck: false,
    };
  
    this.reasonBoxRef = React.createRef();
  }
  inputsWrapper;

  element;
  projectName;
  modalHide;
  btnSaveButton;
  innerInput;

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.editSupplierData != nextProps.editSupplierData && nextProps.editSupplierData) {
      this.setState({
        showMsgBubble: false,
      });

      const data = {};
      const editSupplierData = nextProps.editSupplierData;

      data.name = editSupplierData.name;
      data.city = editSupplierData.city;
      data.areaIds = editSupplierData.areaIds;
      data.organizationNumber = editSupplierData.organizationNumber;
      data.vatNumber = editSupplierData.vatNumber;
      data.selectedIdentityField = this.getSelectedIdentityField(editSupplierData);
      data.website = editSupplierData.website;
      data.description = editSupplierData.description;
      data.address = editSupplierData.address;
      data.postnumber = editSupplierData.postnumber;
      data.kommun = editSupplierData.kommun;
      data.isBlacklist = editSupplierData.isBlacklist ? editSupplierData.isBlacklist : false;
      data.blacklistReason = editSupplierData.blacklistReason
        ? editSupplierData.blacklistReason
        : [];
      data.frameworkAgreement = editSupplierData.frameworkAgreement;
      data.frameworkAgreementDate = editSupplierData.frameworkAgreement && editSupplierData.frameworkAgreement.validUntilDate;
      data.frameworkAgreementComment = editSupplierData.frameworkAgreement && editSupplierData.frameworkAgreement.comment;
      data.updateFrameworkAgreement = false;
      data.liabilityInsurance = editSupplierData.liabilityInsurance;
      data.liabilityInsuranceDate = editSupplierData.liabilityInsurance && editSupplierData.liabilityInsurance.validUntilDate;
      data.liabilityInsuranceComment = editSupplierData.liabilityInsurance && editSupplierData.liabilityInsurance.comment;
      data.updateLiabilityInsurance = false;
      data.ue2021Form = editSupplierData.ue2021Form;
      data.ue2021FormDate = editSupplierData.ue2021Form && editSupplierData.ue2021Form.submittedDate;
      data.ue2021FormComment = editSupplierData.ue2021Form && editSupplierData.ue2021Form.comment;
      data.updateUE2021Form = false;
      data.existingOtherFiles = editSupplierData.otherFiles || [];
      data.newOtherFiles = [];
      data.logo = editSupplierData.logo;
      data.updateLogo = false;
      data.updateFeaturedInfoImage = false;
      data.isFeatured = editSupplierData.isFeatured;
      data.featuredInfo = editSupplierData.featuredInfo;

      if (!editSupplierData.supplierId && !editSupplierData.address) {
        data.address = '';
      }

      this.setState(
        {
          data: data,
          supplierId: editSupplierData.supplierId,
          slide: 1,
        },
        () => {
          this.resetValidators();
        },
      );

      this.fetchCities().then(() => this.fetchAreas().then(() => {
        this.addDefaultAreaIds();

        const ads = (editSupplierData?.ads || []).map(ad => ({
          ...ad,
          errors: {},
          areas: ad.areas.map(area => this.state.areas.find(_area => _area.id == area)),
          regions: ad.regions.map(region => regions.find(_region => region === _region.label))
        }));
    
        this.setState({ ads });

        if (editSupplierData && editSupplierData.supplierId) {
          ServiceSupplier.getSupplierMembers(editSupplierData.supplierId).then(contacts => {
            if (contacts && contacts.length) this.setState({ contacts: contacts.map(contact => ({ ...contact, editMode: false })) });
          });
        }
      }));
    }
  }

  addDefaultAreaIds = (areas) => {
    const { editSupplierData } = this.props;
    let defaultIds = editSupplierData.areaIds;
    if (areas && defaultIds) {
      const filteredArray = defaultIds.map(sup => areas.find(s => s.id === sup));
      this.setState({ defaultAreaIds: filteredArray });
    }
  };

  componentDidMount() {
    (async() => {
      const { editSupplierData } = this.props;
      await this.fetchCities();
      const areas = await this.fetchAreas();
      this.addDefaultAreaIds(areas);
  
      if (editSupplierData && editSupplierData.supplierId) {
        const ads = (editSupplierData?.ads || []).map(ad => ({
          ...ad,
          errors: {},
          areas: ad.areas.map(area => areas.find(_area => _area.id == area)),
          regions: ad.regions.map(region => regions.find(_region => region === _region.label))
        }));
    
        this.setState({ ads });
  
        const contacts = await ServiceSupplier.getSupplierMembers(editSupplierData.supplierId);
        if (contacts && contacts.length) this.setState({ contacts: contacts.map(contact => ({ ...contact, editMode: false })) });
      }
    })();
  }

  fetchCities = async () => {
    const citiesData = await services.getCities();
    let dropDownCities = [];
    citiesData.forEach(item => dropDownCities.push({ label: item, value: item }));
    this.setState({ dropDownCities });
  };

  fetchAreas = async () => {
    const areasData = await serviceAreas.getAreas();
    let areas = [];
    areasData.forEach(item => areas.push({ id: item._id, label: item.name, value: item.name }));
    this.setState({ areas });
    return areas;
  };

  fetchSupplierProfile = async (orgNumber) => {
    console.log("FFEEETHCING")
    const response = await serviceSales.fetchSupplierProfileForOrgNumber(orgNumber);
    this.setState({ supplierProfile: response });
  }

  toggleBtnLoader = () => this.setState({ btnLoader: !this.state.btnLoader });

  onSaveSupplier = async () => {
    const validateSupMembers = (members) => {
      let valid = true;
      const newMembers = members.map(member => ({...member, errors: {}}));
      for (const member of newMembers) {
        if (!member.isRemoved) {
          Object.entries(member).forEach(([key, value]) => {
            if ((key === 'name' || key === 'email') && value === '') {
              member.errors[key] = 'Obligatoriskt';
              valid = false;
            } else if (key === 'email' && validateEmail(value)) {
              member.errors[key] = 'Ogiltig e-postadress';
              valid = false;
            } else if (key === 'phone' && value && validateOptionalPhone(value)) {
              member.errors[key] = 'Ogiltigt telefonnummer';
              valid = false;
            }
          })
        }
      }

      this.setState({ contacts: newMembers });

      return valid;
    }

    const validateAds = (ads) => {
      let valid = true;

      const newAds = ads.map(ad => ({...ad, errors: {}}));
      for (const ad of newAds) {
        Object.entries(ad).forEach(([key, value]) => {
          if ((key === 'areas' || key === 'regions') && value?.length === 0) {
            ad.errors[key] = 'Obligatoriskt';
            valid = false;
          }
        })
      }

      this.setState({ ads: newAds });

      return valid;
    }

    const { data, supplierId, contacts, ads, agreedToContract } = this.state;
    let formErrors = {};
    let formData = Object.assign({},data);

    formData.ads = ads.map(({ _id, startDate, endDate, areas, regions }) => ({ _id, startDate, endDate, areas: areas.map(area => area.id), regions: regions.map(region => region.label)}));

    formData.contacts = contacts;
    formData.removedMembers = contacts.filter(contact => contact.isRemoved).map(contact => contact._id);
    formData.isNewContacts = supplierId && contacts.length > 0;
    formData.selectedIdentityField = formData.selectedIdentityField.value;

    const fieldNames = {
      name: 'Företagsnamn',
      city: 'Län',
      areaIds: 'Professionella områden',
      organizationNumber: 'Organisationsnummer',
      vatNumber: 'VAT - Nummer',
      website: 'Webbplats',
      description: 'Beskrivning',
      address: 'Adress',
      postnumber: 'Postnummer',
      kommun: 'Kommun',
      isBlacklist: 'isBlacklist',
      blacklistReason: 'blacklistReason',
      contacts: 'contacts',
      isNewContacts: 'isNewContacts',
      removedMembers: 'removedMembers',
    };

    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        if (
          key == 'website' ||
          key == 'postnumber' ||
          key == 'city' ||
          key == 'isBlacklist' ||
          key == 'blacklistReason' ||
          key == 'isNewContacts' ||
          key == 'address' ||
          key == 'description' ||
          key == 'removedMembers' ||
          key == 'frameworkAgreement' ||
          key == 'updateFrameworkAgreement' ||
          key == 'frameworkAgreementComment' ||
          key == 'frameworkAgreementDate' ||
          key == 'liabilityInsurance' ||
          key == 'updateLiabilityInsurance' ||
          key == 'liabilityInsuranceComment' ||
          key == 'liabilityInsuranceDate' ||
          key == 'profileLiabilityInsurance' ||
          key == 'profileLiabilityInsuranceComment' ||
          key == 'profileLiabilityInsuranceDate' ||
          key == 'ue2021Form' ||
          key == 'updateUE2021Form' ||
          key == 'ue2021FormComment' ||
          key == 'ue2021FormDate' ||
          key == 'ads' ||
          key == 'isFeatured' ||
          key == 'featuredInfo' ||
          key == 'logo' ||
          key == 'updateLogo' ||
          key == 'updateFeaturedInfoImage' ||
          key == 'newOtherFiles' ||
          key == 'existingOtherFiles' ||
          key == 'message' ||
          key == 'invoiceAddress' ||
          key == 'invoiceReference' ||
          key == 'invoiceInterval' ||
          identityFieldOptions.find(option => option.value == key && option.value != formData.selectedIdentityField)
        )
          continue;

        const val = formData[key];
        if (
          !val ||
          (val instanceof Array && val.length == 0) ||
          (key == 'organizationNumber' && formData.selectedIdentityField == key && val.length !== 11)
        ) {
          if (!fieldNames[key]) {
            console.log(`Form error for unknown field ${key}, make it optional or add it to the fieldNames object.`);
          }
          formErrors[key] = 'Obligatoriskt';
        }
      }
    }
    const invalidContacts = !validateSupMembers(contacts);
    const invalidAds = !validateAds(ads);
    if (Object.keys(formErrors).length > 0 || invalidContacts || invalidAds) {
      this.setState({ formErrors: formErrors, btnLoader: false });
      if (this.props.supplierApplication) {
        NotificationManager.error("Ett eller flera fält saknades eller var ogiltigt, se rödmarkeringar.", "Ej skickat");
      }
      return;
    }

    if (appState.getCurrentOrgSlug() === 'superadmin' && this.props.supplierApplication) {
      if (!agreedToContract) {
        NotificationManager.error("Du måste godkänna användaravtalet", "Ej skickat");
        return;
      }
    }

    this.toggleBtnLoader();
    formData.kommun =
      formData.kommun && formData.kommun.value ? formData.kommun.value : formData.kommun;
    
    try {
      const otherFiles = this.state.data.newOtherFiles.reduce((prev, curr, index) => {
        prev[`otherFiles${index}`] = curr.file;
        return prev;
      }, {});
      const files = {...otherFiles, ...this.state.files};

      if (supplierId) {
        formData.supplierId = supplierId;
        const response = await ServiceSupplier.updateSupplier({ ...formData }, files);
        this.handleSuccessResponse(response, 'Leverantören uppdaterades');
      } else {
        if (this.props.supplierApplication) {
          const response = await ServiceSupplier.saveSupplierApplication({ ...formData }, files);
          this.handleSuccessResponse(response, 'Ansökan skickad');
        } else if ((appState.getCurrentOrg()?.onlyAdminsCanCreateSuppliers && appState.isNonAdminUser())) {
          const response = await ServiceSupplier.saveSupplierApplication({ ...formData, internalApplication: true }, files);
          this.handleSuccessResponse(response, 'Ansökan skickad');
        } else {
          const response = await ServiceSupplier.saveSupplier({ ...formData }, files);
          this.handleSuccessResponse(response, 'Leverantören har lagts till');
        }
      }
    } catch (e) {
      if (e?.response?.data?.existingFields) {
        const newFormErrors = {};
        e?.response?.data?.existingFields.forEach(field => {
          newFormErrors[field] = `${fieldNames[field]} finns redan`;
        })
        this.setState({ formErrors: {...formErrors, ...newFormErrors}, btnLoader: false });
        if (this.props.supplierApplication) {
          NotificationManager.error("Ett eller flera fält saknades eller var ogiltigt, se rödmarkeringar.", "Fel")
        }
      } else {
        this.handleErrorResponse(e);
      }
    } finally {
      this.setState({ btnLoader: false });
    }
  };

  handleSuccessResponse = (data, msg) => {
    NotificationManager.success(msg);
    this.props.saveSupplier(data);
    this.props.setShow(false);
  };

  handleErrorResponse = e => {
    this.toggleBtnLoader();
    NotificationManager.error(
      this.props.intl.formatMessage({
        id: _.get(e, 'response.data.error', 'error.unexpectedError'),
        defaultMessage: 'An unexpected error has occurred',
      }),
      this.props.intl.formatMessage({
        id: 'global.error',
        defaultMessage: 'Error',
      }),
    );
  };

  handleChange = (field, value, isFile = false) => {
    let {data, files} = this.state;
    if (isFile) {
      files[field] = value;
    } else {
      data[field] = value;
    }

    this.setState({ data, files, formErrors: {...this.state.formErrors, [field]: ''} });
  };

  handleOrgChange = async value => {
    let data = this.state.data;

    let orgVal = value?.trim();
    const isRemove =
      !orgVal.includes('-') && data?.organizationNumber && data?.organizationNumber.includes('-');

    if (orgVal.length === 6) orgVal = isRemove ? orgVal : orgVal + '-';

    const isNum = isValidOrganizationNumber(orgVal);

    if (!isNum || orgVal.length > 11) {
      return;
    }

    data.organizationNumber = orgVal;

    this.setState({ data, formErrors: {...this.state.formErrors, organizationNumber: ''} });

    if (checkOrgNo(orgVal) && !this.props.supplierApplication) {
      try {
        await Promise.all([
          this.fetchCreditSafeData(orgVal),
          this.fetchSupplierProfile(orgVal),
        ]);
        return;
      } catch (e) {
        console.log(e);
      }
    } else {
      this.setState({ supplierProfile: this.emptySupplierProfile() })
      this.setState({ showQuickCheck: false });
    }
  };

  onIdentityFieldChange = value => {
    const { data } = this.state;
    if (data.selectedIdentityField?.value === 'organizationNumber') {
      this.handleOrgChange(value);
    } else if (data.selectedIdentityField?.value === 'vatNumber') {
      this.handleChange('vatNumber', value)
    }
  }

  toggleOrgNumLoader = () => this.setState({ orgNumLoader: !this.state.orgNumLoader });

  fetchCreditSafeData = async orgNum => {
    const organizationHasSyna = !this.props.supplierApplication && appState.getOrgFromUrl()?.hasSyna;
    if (!organizationHasSyna) return;
    this.toggleOrgNumLoader();
    let response;
    try {
      response = await serviceCreditSafe.getCreditCompanyData(orgNum, this.state.supplierId);
    } catch (error) {
      response = error.response;
    }

    this.toggleOrgNumLoader();
    if (response.data.error) {
      this.setState({ formErrors: {...this.state.formErrors, organizationNumber: response.data.error} });
      this.setState({showQuickCheck: false});
    } else if (response.data.Svar && response.data.Svar._attributes.ec.toLowerCase() == 'ok') {
      let synaData = serviceCreditSafe.filterSynaCompanyData(response.data.Svar.Objektlista);
      this.setState({showQuickCheck: true});
      this.setCompanyData(synaData);
    } else if (
      response.status === 200 &&
      response.data &&
      response.data.companies &&
      response.data.companies.length
    ) {
      const creditSafeData = serviceCreditSafe.filterCreditSafeCompanyData(
        response.data.companies[0],
      );
      this.setState({showQuickCheck: true});
      this.setCompanyData(creditSafeData);
    }
  };

  setCompanyData = compData => {
    compData.organizationNumber = this.state.data.organizationNumber;
    if (compData.kommun) {
      let _kommun = compData.kommun;
      const checkCity = this.state.dropDownCities.findIndex(
        c => c.value.toLowerCase() === _kommun.toLowerCase(),
      );
      if (checkCity > -1) {
        compData.kommun = {
          label: _kommun,
          value: _kommun,
        };
      }
    }

    this.setState({ data: {...this.state.data, ...compData }});
  };

  checkAreaItems = areaIds => {
    let dataIds = areaIds.map(s => s.id);
    let data = this.state.data;
    data.areaIds = dataIds;
    this.setState({ data });
  };

  toggleReason = () => {
    if (this.reasonBoxRef.current) this.reasonBoxRef.current.reset();
    this.setState({
      showMsgBubble: !this.state.showMsgBubble,
    });
  };

  submitReason = async reason => {
    const { data } = this.state;
    const _reasonList =
      data.blacklistReason && data.blacklistReason.length ? data.blacklistReason : [];
    let reasonObj = { reason, date: new Date(), adminName: appState.state.token.name };
    _reasonList.unshift(reasonObj);
    data.blacklistReason = _reasonList;
    this.setState({ data });
    if (this.props.editSupplierData.name && this.props.editSupplierData.isBlacklist) {
      let formData = data;
      formData.blacklistReason = _reasonList;
      await ServiceSupplier.saveReason({
        reason: reasonObj,
        supName: this.props.editSupplierData.name,
      });
      this.props.saveSupplier(formData, true);
    }
  };

  renderWarning = () => {
    const { data, showMsgBubble } = this.state;
    const checkBlacklist = data.blacklistReason && data.blacklistReason.length;
    if (data.isBlacklist && appState.isManagementRole()) {
      return (
        <span className="img-warning-hover">
          {/* <img src="/assets/images/blacklisted-person.png" className="img-warning" /> */}
          <img
            title={
              data.blacklistReason && checkBlacklist > 0 ? data.blacklistReason[0].reason : null
            }
            src={MSGBubbleIcon}
            onClick={this.toggleReason}
            className={
              (data.blacklistReason && checkBlacklist > 0) || showMsgBubble
                ? 'img-msg-show'
                : 'img-msg-show'
            }
          />
          <ReasonBox
            ref={this.reasonBoxRef}
            showModal={showMsgBubble}
            blacklistReason={data.blacklistReason}
            submitReason={this.submitReason}
            onCancel={this.toggleReason}
          />
        </span>
      );
    }
    return null;
  };

  onAddArea = async newAreaName => {
    const { defaultAreaIds } = this.state;

    if (!newAreaName) return;

    this.setState({ loading: true });

    try {
      const updatedAreas = await serviceAreas.addArea({ name: newAreaName });
      if(updatedAreas && this.props.fetchNewAreas) this.props.fetchNewAreas(updatedAreas);

      NotificationManager.success(`Yrkeskategori tillagd`);

      const newAddedArea = updatedAreas.find(el => el.name.toLowerCase() === newAreaName.toLowerCase());
      if (newAddedArea) {
        defaultAreaIds.push({
          id: newAddedArea._id,
          value: newAddedArea.name,
          label: newAddedArea.name,
        });
        this.checkAreaItems(defaultAreaIds);
      }
      this.setState({ defaultAreaIds });
    } catch (error) {
      console.log(error, 'error');
      NotificationManager.error(`Något gick fel`);
    } finally {
      this.fetchAreas();
      this.setState({ loading: false });
    }
  };

  onSelect = areaIds => {

    this.setState({ defaultAreaIds: [...areaIds], formErrors: {...this.state.formErrors, areaIds: ''}});
    this.checkAreaItems(areaIds);
  };

  addFrameworkAgreement() {
    this.setState({ 
      data: { 
        ...this.state.data, 
        updateFrameworkAgreement: true
      }, 
      editFrameworkAgreement: true
    });
  }

  removeFrameworkAgreement() {
    this.setState({ 
      data: { 
        ...this.state.data, 
        frameworkAgreement: null, 
        frameworkAgreementDate: null, 
        frameworkAgreementComment: null,
        updateFrameworkAgreement: true
      },
      files: {
        ...this.state.files,
        frameworkAgreementFile: null
      },
      editFrameworkAgreement: false
    });
  }

  hasFrameworkAgreement(data) {
    return data.frameworkAgreement && (data.frameworkAgreement.file || data.frameworkAgreement.validUntilDate || data.frameworkAgreement.comment)
  }

  addLiabilityInsurance() {
    this.setState({ 
      data: { 
        ...this.state.data, 
        updateLiabilityInsurance: true
      }, 
      editLiabilityInsurance: true
    });
  }

  addUE2021Form() {
    this.setState({ 
      data: { 
        ...this.state.data, 
        ue2021FormDate: new Date(), 
        ue2021FormComment: `Inskickat av ${appState.getUserName()}`,
        updateUE2021Form: true
      }, 
      editUE2021Form: true
    });
  }

  removeLiabilityInsurance() {
    this.setState({ 
      data: { 
        ...this.state.data, 
        liabilityInsurance: null, 
        liabilityInsuranceDate: null, 
        liabilityInsuranceComment: null,
        updateLiabilityInsurance: true
      },
      files: {
        ...this.state.files,
        liabilityInsuranceFile: null
      },
      editLiabilityInsurance: false
    });
  }

  removeUE2021Form() {
    this.setState({ 
      data: { 
        ...this.state.data, 
        ue2021Form: null, 
        ue2021FormDate: null, 
        ue2021FormComment: null,
        updateUE2021Form: true
      },
      files: {
        ...this.state.files,
        ue2021Form: null
      },
      editUE2021Form: false
    });
  }

  renderFrameworkAgreement(data) {
    return this.renderFileCommentDate(
      data.frameworkAgreement, 
      this.state.editFrameworkAgreement, 
      () => this.removeFrameworkAgreement(),
      () => this.addFrameworkAgreement(),
      "frameworkAgreement",
      "Lägg till ramavtal",
      'Ramavtal',
    );
  }

  renderLiabilityInsurance(liabilityInsurance, label, readOnly) {
    return this.renderFileCommentDate(
      liabilityInsurance, 
      this.state.editLiabilityInsurance, 
      () => this.removeLiabilityInsurance(),
      () => this.addLiabilityInsurance(),
      "liabilityInsurance",
      "Lägg till ansvarsförsäkring",
      label,
      false,
    );
  }

  renderProfileLiabilityInsurance(liabilityInsurance, label) {
    return this.renderFileCommentDate(
      liabilityInsurance, 
      false, 
      () => {},
      () => {},
      "profileLiabilityInsurance",
      "Lägg till ansvarsförsäkring",
      label,
      true, 
      FileScope.SupplierProfileFile,
      { organizationNumber: this.state.data.organizationNumber },
    );
  }


  renderUE2021Form(data) {
    return this.renderFileCommentDate(
      data.ue2021Form, 
      this.state.editUE2021Form, 
      () => this.removeUE2021Form(),
      () => this.addUE2021Form(),
      "ue2021Form",
      "Lägg till UE2021-blankett",
      'UE2021',
    );
  }

  renderFileCommentDate(item, isEditing, onRemove, onAdd, namePrefix, addButtonText, headline, readOnly, scope, scopeOptions) {
    const comment = this.state.data?.[namePrefix + "Comment"];
    const date = this.state.data?.[namePrefix + "Date"];
    const itemWithUpdatedCommentAndDate = {...item, comment, validUntilDate: date};
    if (readOnly && !(item?.file?.file || item?.file?.fileName || comment || date)) return null;

    return <FileCommentDate 
      item={itemWithUpdatedCommentAndDate} 
      isEditing={isEditing} 
      onRemove={readOnly ? undefined : onRemove} 
      onAdd={onAdd} 
      namePrefix={namePrefix} 
      addButtonText={addButtonText} 
      headline={headline} 
      handleChange={this.handleChange.bind(this)}
      newFile={this.state.files?.[namePrefix + "File"]}
      scope={scope ?? FileScope.SupplierFiles}
      scopeOptions={scopeOptions ?? { supplierId: this.state.supplierId }}
    />;
  }
/*
    const agreementHeadline = namePrefix == "ue2021Form" ? "Blankett" : "Avtal";
    const datePickerHeadline = namePrefix == "ue2021Form" ? "Inskickat datum" : "Giltigt till";
    const pickedDate = item?.validUntilDate || item?.submittedDate;
    if (isEditing) {
      return <React.Fragment>
        <div className="ar-add-sup-member" style={{ gap: '15px', display: 'flex', flexDirection: 'column', fontSize: '16px' }}>
          <div className='ar-add-sup-member-headline'>
            {headline}
            <div className="ar-remove-sup-member" onClick={onRemove}>
              <CrossSvg size='16px' />
            </div>
          </div>
          
          <div className='ar-add-sup-member-agreement-headline'>
            {agreementHeadline}
            <div className="app-chat-mail-batch" style={{ marginTop: '5px', display: 'flex', alignItems: 'center', gap: '10px' }}>
              { !this.state.files?.[namePrefix + "File"] && <label style={{ letterSpacing: 'normal' }}>
                <BtButton class='addEditSupplierUploadFileButton' color='white' size='xxs' leftIcon={
                  <ClipIcon style={{ width: '15px', height: '15px', fill: 'var(--gray-700)' }} />
                }>
                  Ladda upp fil
                  <input
                    type="file"
                    name={namePrefix + "File"}
                    onClick={event => {
                      event.target.value = null;
                    }}
                    onChange={(e) => this.handleChange(namePrefix + "File", e.target.files[0], true)}
                    style={{ display: 'none' }}
                  />
                </BtButton>
              </label>}
              <div className="upload-files-wrapper uploaded-files">
                {this.state.files && this.state.files[namePrefix + "File"] &&
                    this.generateUploadedFiles(this.state.files[namePrefix + "File"], namePrefix) 
                }
              </div>
            </div>
          </div>
          
          <DatePicker
            label={datePickerHeadline}
            selected={this.state.data[namePrefix + "Date"]}
            onChange={(date, e) => {
              e.preventDefault();
              this.setState({
                data: {
                  ...this.state.data,
                  [namePrefix + "Date"]: date
                }
              });
            }}
          />
          <Field
            label='Kommentar'
            value={this.state.data[namePrefix + "Comment"] ?? ""}
            onChange={(value) => this.handleChange(namePrefix + "Comment", value)}
          />
        </div>
      </React.Fragment>;
    } else {
      if (this.hasFileCommentOrDate(item)) {
        return <dl className="frameworkAgreement">
          <div className='ar-add-sup-member-headline'>
            {headline}
            <div className="ar-remove-sup-member" onClick={onRemove}>
              <CrossSvg size='16px' />
            </div>
          </div>
          {item.file && <div>
            <dt style={{ marginBottom: '5px' }}>{agreementHeadline}</dt>
            <dd>
              <File
                scope={FileScope.SupplierFiles}
                scopeOptions={{ supplierId: this.state?.supplierId }}
                file={item.file.file}
                fileName={item.file.fileName}
              />
            </dd>
          </div>}
          {pickedDate && <div>
            <dt>{datePickerHeadline}</dt>
            <dd>{(pickedDate) && new Date(pickedDate).toLocaleDateString()}</dd>
          </div>}
          {item.comment && <div>
            <dt>Kommentar</dt>
            <dd>{item.comment}</dd>
          </div>}
        </dl>
      } else {
        return <div>
          <BtButton 
            color="white" 
            size="xxs"
            onClick={onAdd}
            leftIcon={<AddCircleIcon htmlColor="var(--orange-500)"/>}
          >
              {addButtonText}
          </BtButton>
        </div>;  
      }
    }
  }*/

  renderOtherFiles(data) {
    const hasOtherFiles = this.state.data?.existingOtherFiles?.length > 0 || this.state.data?.newOtherFiles?.length > 0;

    return <div>
      {hasOtherFiles && (
        <p>
          Övriga filer
        </p>
      )}
      <FileUpload 
        existingFiles={this.state.data?.existingOtherFiles}
        setExistingFiles={value => this.setState({data: {...data, existingOtherFiles: value}})}
        newFiles={this.state.data?.newOtherFiles}
        setNewFiles={value => this.setState({data: {...data, newOtherFiles: value}})}
        icon={!hasOtherFiles && <AddCircleIcon htmlColor="var(--orange-500)"/>}
        label={`Lägg till ${hasOtherFiles ? "fler " : ""}övriga filer`}
        scope={FileScope.SupplierFiles}
        scopeOptions={{ supplierId: this.state.supplierId }}
      />
    </div>    
  }

  renderLogo(data) {
    return (
      <LogoForm 
        logo={data?.logo}
        scope={FileScope.SupplierLogo}
        scopeOptions={{supplierId: this.state.supplierId}}
        image={this.state.files.logo}
        onChange={(file) => {
          if (file) {
            this.setState({ data: {...data, updateLogo: true, logo: file} }, () => {
              this.handleChange('logo', file, true);
            });
          } else {
            this.setState({ data: {...data, logo: {}, updateLogo: true}, files: {...this.state.files, logo: undefined} });
          }
        }}
      />
    )
  }

  renderAdImage(data) {
    return (
      <div>
        <p style={{ marginTop: 0, marginBottom: '7px', fontSize: '14px', fontWeight: 500, color: 'var(--gray-700)' }}>Ad bild</p>
        <ImagePreview
          image={data?.featuredInfo?.image}
          fileId={data?.featuredInfo?.image?.file}
          scope={FileScope.FeaturedSupplierImage}
          scopeOptions={{ supplierId: this.state.supplierId }}
          orgSlug={appState.getCurrentOrgSlug()}
          onRemove={() => this.setState({ data: {...data, featuredInfo: {...data?.featuredInfo, image: {}}, updateFeaturedInfoImage: true}, files: {...this.state.files, featuredInfoImage: undefined} })}
          width='250px'
        />
        {!(data?.featuredInfo?.image?.file || data?.featuredInfo?.image?.name) && (
          <label style={{ letterSpacing: 'normal' }}>
            <BtButton 
              color="white"
              size="xxs"
              leftIcon={<AddCircleIcon htmlColor="var(--orange-500)"/>}
              style={{ width: 'fit-content' }}
              multiple
            >
              {`Lägg till bild`}
              <input
                type="file"
                name={'featuredInfoImage'}
                accept='image/png, image/gif, image/jpeg'
                onClick={event => {
                  event.target.value = null;
                }}
                onChange={(e) => {
                  this.setState({ data: {...data, updateFeaturedInfoImage: true, featuredInfo: {...data?.featuredInfo, image: e.target.files[0]}} }, () => {
                    this.handleChange('featuredInfoImage', e.target.files[0], true);
                  });
                }}
                style={{ display: 'none' }}
              />
            </BtButton>
          </label>
        )}
      </div>
    )
  }

  emptySupplierProfile = () => ({
    areas: [],
    places: [],
    contacts: [],
    otherEmailAddresses: [],
  });

  renderFormSlide() {
    const {
      areas,
      data,
      orgNumLoader,
      dropDownCities,
      contacts,
      defaultAreaIds,
      formErrors,
      loading,
      ads,
      showQuickCheck,
    } = this.state;

    const selectedIdentityField = data.selectedIdentityField;
    const organizationHasSyna = !this.props.supplierApplication && appState.getOrgFromUrl()?.hasSyna;
    

    const getErrorMessage = (fieldName) => {
      if (formErrors[fieldName]) {
        if (fieldName == 'organizationNumber') {
          const isValidOrgNumber = /^[0-9]{6}-[0-9]{4}$/.test(data.organizationNumber); // not the same as isValidOrganizationNumber
          if (data.organizationNumber && !isValidOrgNumber) {
            return 'Ogiltigt nummer';
          }
        }
        return formErrors[fieldName];
      }
      return '';
    }

    const renderSupplierProfileValue = (value, setValue) => {
      if (!value) return null;
      return (
        <span style={{ fontSize: "12px", color: "var(--gray-500)", lineHeight: "16px" }}>
          Leverantören har angett: <span style={{color: "var(--gray-700)", fontWeight: 500, whiteSpace: "pre-line" }}>
            {value}
          </span>
        </span>
      );
    }

    const supplierProfile = this.state.supplierProfile ?? this.props.editSupplierData.supplierProfile ?? this.emptySupplierProfile();

    const renderProfileInfo = (d) => {
      const otherEmailAddress = supplierProfile.otherEmailAddresses.find(o => o.email == d.email);      
      const contact = supplierProfile.contacts.find(c => c.email == d.email);

      if (contact) {
        const diff = [
          contact.name && contact.name != d.name && <div>Namn: {contact.name}</div>,
          contact.title && contact.title != d.title && <div>Titel: {contact.title}</div>,
          contact.phone && contact.phone != d.phone && <div>Telefon: {contact.phone}</div>,
        ].filter(x=>x);
        return (
          <TooltipContainer renderReferenceComponent={(className, ref) => <VerifiedIcon className={className} ref={ref} style={{ marginRight: "8px" }} />}>
            <div key="tooltip" style={{maxWidth: "512px", fontWeight: 400, fontSize: "14px", color: "var(--gray-700)" }}>                
              {diff.length > 0 ? (<>
                E-postadressen matchar leverantörens egna uppgifter, men följande uppgifter skiljer sig:
                {diff}
              </>) : (
                <>Kontaktpersonen matchar leverantörens egna uppgifter.</>
              )}
            </div>
          </TooltipContainer>
        );
      }
      if (otherEmailAddress) {
        if (otherEmailAddress.redirectTo) {
          const supplierProfileContact = supplierProfile.contacts.find(c => c._id == otherEmailAddress.redirectTo);
          const email = supplierProfileContact?.email;
          const contact = contacts.find(c => c.email == email);
          const name = contact?.name ?? supplierProfileContact?.name;
          if (name) {
            return (
              <TooltipContainer renderReferenceComponent={(className, ref) => <InfoIcon className={className} ref={ref} style={{ marginRight: "8px" }} />}>
                <div style={{maxWidth: "512px", fontWeight: 400, fontSize: "14px", color: "var(--gray-700)" }}>
                  Leverantören rekommenderar: Kontakta <span style={{fontWeight: 500, color: "var(--gray-900)"}}>{name}</span> i stället 
                  {otherEmailAddress.comment && ` (${otherEmailAddress.comment})`}
                  .
                </div>
              </TooltipContainer>
            );
          }
        } else if (otherEmailAddress.comment) {        
          return (
            <TooltipContainer renderReferenceComponent={(className, ref) => <InfoIcon className={className} ref={ref} style={{ marginRight: "8px" }} />}>
              <div style={{maxWidth: "512px", fontWeight: 400, fontSize: "14px", color: "var(--gray-700)" }}>
                Meddelande från leverantören: <span style={{fontWeight: 500, color: "var(--gray-900)"}}>{otherEmailAddress.comment}</span>
              </div>
            </TooltipContainer>
          );
        }
      }
      return null;
    }

    const renderRecommendedContacts = () => {
      const recommendedContacts = supplierProfile.contacts.filter(profileContact => !contacts.find(c => c.email == profileContact.email));
      if (recommendedContacts.length == 0) return null;
      return (
        <>
          <div className="add-supplier-fields add-edit-supplier-fields">
            <div>
              <div>
                <h3 className="inner-sec-title">Leverantörens föreslagna kontaktpersoner</h3>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', gap: '25px', flexDirection: 'column' }}>
            {recommendedContacts.map(contact => (
              <SuggestedContactContainer 
                member={contact}
                key={contact.email}
                onAdd={() => {
                  this.setState({ contacts: [...contacts, { ...newContact(), name: contact.name, title: contact.title, email: contact.email, phone: contact.phone }] })
                }}          
              />
            ))}
          </div>
        </>
      );
    }

    return (
      <div className="mx-content overflow-s custom-scrollbar" >
        <div className={'slide-1'} key="slide1" style={{ display: 'flex', flexDirection: 'column', gap: '25px', width: '650px' }}>
          {!this.state.supplierId && appState.getCurrentOrg()?.onlyAdminsCanCreateSuppliers && appState.isNonAdminUser() && (
            <p style={{ fontSize: "14px", color: "var(--gray-700)" }}>Endast administratörer kan lägga till leverantörer i {appState.getCurrentOrg()?.name}. 
            Detta formulär skapar i stället en leverantörsansökan, som kan godkännas efter granskning av en administratör.</p>
          )}
          <div style={{ display: 'flex', gap: '10px', alignItems: 'flex-end', rowGap: '10px', columnGap: '10px' }}>
            <Field
              value={data?.[selectedIdentityField?.value] || ''}
              onChange={this.onIdentityFieldChange}
              placeholder={selectedIdentityField?.value === 'organizationNumber' ? '101010-1010' : 'SE123456789012'}
              fieldOptions={identityFieldOptions}
              selectedField={selectedIdentityField}
              onFieldChange={field => this.setState({ data: {...data, selectedIdentityField: field}, showQuickCheck: field.value == "vatNumber" ? false : showQuickCheck}) }
              className='identityField'
              style={{ fontSize: '14px', fontWeight: 400, color: 'var(--gray-900)' }}
              required
              label='Organisationsnummer/VAT-nummer'
              errorMessage={getErrorMessage(selectedIdentityField?.value)}
            />
            {organizationHasSyna && <BtButton
              class='refreshSynaButton'
              loaderShow={orgNumLoader}
              color='white'
              disabled={orgNumLoader || !data.organizationNumber || selectedIdentityField?.value != 'organizationNumber' || !checkOrgNo(data.organizationNumber)}
              onClick={() => {
                if (data.organizationNumber && selectedIdentityField?.value == 'organizationNumber' && checkOrgNo(data.organizationNumber) && !orgNumLoader) {
                  this.fetchCreditSafeData(data.organizationNumber)
                }
              }}>
                Slå upp
            </BtButton>}
          </div>
          <div key="supplierName">
            <Field required label='Företagsnamn' errorMessage={getErrorMessage('name')} value={data.name} onChange={(value) => this.handleChange('name', value)} />
            {renderSupplierProfileValue(supplierProfile.name)}
          </div>
          <div key="quickCheck">
            {showQuickCheck && (
              <SupplierControls 
                organizationNumber={this.state.data.organizationNumber}
                hasSyna={organizationHasSyna}
                className='supplierControls-addSupplierModal'
                history={this.props.history}
              />
            )}
          </div>
          <Field multiline label='Beskrivning' value={data.description} onChange={(value) => this.handleChange('description', value)} />

          {this.renderLogo(data)}

          <div key="address">
            <Field multiline label='Adress' errorMessage={getErrorMessage('address')} value={data.address} onChange={(value) => this.handleChange('address', value)} />
            {renderSupplierProfileValue(supplierProfile.address)}
          </div>

          <Selector
            required
            label='Kommun'
            placeholder="Välj"
            errorMessage={getErrorMessage('kommun')}
            value={data.kommun}
            options={dropDownCities.map(c => {
              return {
                value: c.value,
                label: c.value,
              };
            })}
            onChange={v => {
              let _data = data || {};
              _data.kommun = v;
            
              this.setState({ data: _data, formErrors: {...formErrors, kommun: ''} });
            }}
          />

          {/* appState.isManagementRole() && !this.props.supplierApplication && (
            <div className="blackList-main-container">
              <CustomToggle
                checked={data.isBlacklist}
                onChange={this.toggleBlacklist}
                icons={false}
                label="Spärra leverantör"
              />
              {this.renderWarning()}
            </div>
          ) */}

          <div key="website">
            <Field label='Webbplats' type='url' value={data.website} onChange={(value) => this.handleChange('website', value)} />
            {renderSupplierProfileValue(supplierProfile.website)}
          </div>

          <div key="areas">
            <Selector
              required
              isDisabled={loading}
              isLoading={loading}
              errorMessage={getErrorMessage('areaIds')}
              label='Yrkeskategori'
              placeholder='Välj'
              isMulti
              isCreatable={!this.props.supplierApplication && appState.isAdminRole()}
              options={areas}
              onCreateOption={this.onAddArea}
              onChange={this.onSelect}
              value={defaultAreaIds}
            />
            {renderSupplierProfileValue(supplierProfile.areas.map(a => a.name).join(", "))}
          </div>

          <div key="files">
            <h3 className="inner-sec-title">Filer</h3>
            <div style={{ display: 'flex', gap: '24px', flexDirection: 'column', }}>
              {this.renderFrameworkAgreement(data)}
              
              {this.renderLiabilityInsurance(data.liabilityInsurance, 'Ansvarsförsäkring')}

              {this.renderProfileLiabilityInsurance(supplierProfile.liabilityInsurance, 'Ansvarsförsäkring från leverantörsprofil')}

              {!this.props.supplierApplication && this.renderUE2021Form(data)}

              {this.renderOtherFiles(data)}
            </div>
          </div>

          <div className="add-supplier-fields add-edit-supplier-fields">
            <div>
              <div>
                <h3 className="inner-sec-title">Kontaktpersoner</h3>
              </div>
            </div>

            {this.props.supplierApplication && <p>Eventuella frågor om denna ansökan kommer skickas till den första kontaktpersonen nedan.</p>}

            <div style={{ display: 'flex', gap: '25px', flexDirection: 'column' }}>
              {contacts.map((contact, index) => {
                const profileInfo = renderProfileInfo(contact);
                return (
                  <SupplierMemberContainer
                    errors={contact.errors}
                    key={contact._id || contact.tempId}
                    editMode={contact.editMode}
                    member={contact} 
                    profileInfo={profileInfo}
                    onEdit={() => {
                      this.setState(({ contacts }) => {
                        const newContacts = [...contacts];
                        newContacts[index].editMode = true;
                        return {contacts: newContacts};
                      });
                    }}
                    onChange={(values) => {
                      this.setState(({ contacts }) => {
                        const newContacts = [...contacts];
                        Object.entries(values).forEach(([key, value]) => {
                          newContacts[index][key] = value;
                          if (newContacts[index].errors?.[key]) {
                            newContacts[index].errors[key] = '';
                            if ((key === 'name' || key === 'email') && value === '') {
                              newContacts[index].errors[key] = 'Obligatoriskt';
                            } else if (key === 'email' && validateEmail(value)) {
                              newContacts[index].errors[key] = 'Ogiltig e-postadress';
                            } else if (key === 'phone' && value && validateOptionalPhone(value)) {                              
                              newContacts[index].errors[key] = 'Ogiltigt telefonnummer';
                            }
                          }
                        });                        
                        return {contacts: newContacts};
                      })
                    }}
                    onRemove={(contact._id || contacts.filter(c => !c.isRemoved).length > 1) && ((value) => {
                      this.setState(({ contacts }) => {
                        let newContacts = [...contacts];
                        const contact = newContacts[index];

                        if (contact._id) {
                          contact.isRemoved = value;
                          if (contacts.filter(c => !c.isRemoved).length == 0) {
                            newContacts.push(newContact());
                          }
                        } else {
                          newContacts = newContacts.filter(_contact => _contact.tempId !== contact.tempId || _contact._id !== contact._id);
                        }
                        return {contacts: newContacts};
                      })
                    })}
                  />
                )
              })}
            </div>
            <div style={{ paddingTop: contacts?.length > 0 ? '24px' : 0 }}>
              <BtButton 
                color="white" 
                size="xxs"
                leftIcon={<AddCircleIcon htmlColor="var(--orange-500)"/>}
                onClick={() => {
                  this.setState({ contacts: [...contacts, newContact()] })
                }}
              >
                Lägg till kontaktperson
              </BtButton>
            </div>
          </div>
          {renderRecommendedContacts()}
          {appState.getCurrentOrgSlug() === 'superadmin' && !this.props.supplierApplication && (
            <div className="add-supplier-fields add-edit-supplier-fields" style={{marginBottom: !data?.isFeatured ? "175px" : 0}}>
              <div>
                <div>
                  <h3 className="inner-sec-title">Annonsplaceringar</h3>
                </div>
              </div>
              <div style={{ display: 'flex', gap: '25px', flexDirection: 'column' }}>
                {ads?.map((ad, index) => {
                  return (
                    <RecommendedAd deletable index={index} ad={ad} areas={areas} setState={(getUpdatedState) => this.setState(({ ads }) => ({ ads: getUpdatedState(ads) }))} />
                  )
                })}
              </div>
              <div style={{ paddingTop: ads?.length > 0 ? '24px' : 0 }}>
                <BtButton 
                  color="white" 
                  size="xxs"
                  leftIcon={<AddCircleIcon htmlColor="var(--orange-500)"/>}
                  onClick={() => {
                    this.setState(({ ads }) => {
                      return { ads: [
                        ...ads,
                        {
                          errors: {},
                          startDate: moment().toDate(),
                          endDate: moment().add(1, 'month').toDate(),
                          regions: [],
                          areas: []
                        }
                      ]}
                    })
                  }}
                >
                  Lägg till annonsplacering
                </BtButton>
              </div>
              <div>
                <CustomToggle
                  checked={data.isFeatured}
                  onChange={() => this.setState({ data: { ...data, isFeatured: !data.isFeatured, featuredInfo: !data.isFeatured ? {
                    startDate: moment().toDate(),
                    endDate: moment().add(1, 'month').toDate(),
                  } : {} }})}
                  icons={false}
                  label="Utvald"
                />
                {data.isFeatured && (
                  <div className='featuredSupplierOptions'>
                    <DatePicker
                      label='Startdatum'
                      selected={data?.featuredInfo?.startDate}
                      maxDate={moment(data?.featuredInfo?.endDate).toDate()}
                      onChange={(value) => this.setState(({ data }) => {
                        data.featuredInfo.startDate = value;
                        return { data };
                      })}
                    />
                    <DatePicker
                      label='Slutdatum'
                      selected={data?.featuredInfo?.endDate}
                      minDate={moment(data?.featuredInfo?.startDate).toDate()}
                      onChange={(value) => this.setState(({ data }) => {
                        data.featuredInfo.endDate = value;
                        return { data };
                      })}
                    />
                    {this.renderAdImage(data)}
                    <Field
                      label='Titel'
                      value={data?.featuredInfo?.title}
                      onChange={(value) => this.setState({ data: {...data, featuredInfo: {...data.featuredInfo, title: value}} })}
                    />
                    <Field
                      label='Beskrivning'
                      multiline
                      value={data?.featuredInfo?.description}
                      onChange={(value) => this.setState({ data: {...data, featuredInfo: {...data.featuredInfo, description: value}} })}
                    />
                    <Field
                      label='URL'
                      value={data?.featuredInfo?.url}
                      onChange={(value) => this.setState({ data: {...data, featuredInfo: {...data.featuredInfo, url: value}} })}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          {this.props.supplierApplication && (<>
            {appState.getCurrentOrgSlug() === 'superadmin' && (<>
                <div>
                  <h3 className="inner-sec-title">Beställning</h3>
                </div>
                <Field label='Fakturaaddress (e-post för PDF-faktura)' value={data.invoiceAddress} onChange={(value) => this.handleChange('invoiceAddress', value)} />
                <Field label='Märkning/referens på faktura' value={data.invoiceReference} onChange={(value) => this.handleChange('invoiceReference', value)} />
                <Selector 
                  label="Avtalslängd och pris" 
                  options={invoiceIntervalOptions} 
                  value={invoiceIntervalOptions.find(o => o.value == data.invoiceInterval)}
                  onChange={(value) => this.handleChange('invoiceInterval', value.value)}
                />
                <div>
                  <p style={{fontWeight: 500}}>Användarvillkor och avtal</p>
                  <p><a href="http://www.accurator.se/sidor/anvandaravtal-leverantorer" target="_blank" rel="noreferrer">http://www.accurator.se/sidor/anvandaravtal-leverantorer</a></p>
                  <p>Genom godkännande av ovanstående villkor godkänner Leverantören samt förbinder sig att följa Användarvillkoren. Användaren bekräftar också att man 
                    har läst och tagit del av integritetspolicyn och samtycker till att Accurator behandlar personuppgifterna i enlighet med Integritetspolicyn.
                    Användarvillkoren, Integritetspolicyn och den information som Accurator, från tid till annan, anger på Webbplatsen utgör det fullständiga 
                    avtalet mellan Användaren och Accurator rörande användningen av Webbplatsen och Tjänsten.</p>
                  <label style={{color: "var(--gray-700)", letterSpacing: 0, fontSize: "14px"}}><StyledCheckbox checked={this.state.agreedToContract} onChange={value => this.setState({agreedToContract:value})} />Jag godkänner ovanstående användaravtal. <span style={{marginLeft: "3px", color: "var(--orange-500)"}}>*</span></label>
                </div>
            </>)}
            <div>
              <div>
                <h3 className="inner-sec-title">Övriga uppgifter</h3>
              </div>
              <p>Kompletterande uppgifter som kan användas vid bedömning av ansökan, men som inte blir en del av företagspresentationen i leverantörsdatabasen.</p>
              <Field multiline label='Meddelande' value={data.message} onChange={(value) => this.handleChange('message', value)} />
            </div>
          </>)}
        </div>
      </div>
    );
  }

  toggleBlacklist = e => {
    const { data } = this.state;
    data.isBlacklist = e.target.checked;
    this.setState({ data });
  };

  renderButtons = () => {
    const { btnLoader, supplierId } = this.state;
    const { onModalHide } = this.props;
    return (
      <div className="ar-supplier-modal-footer">
        <div className="footer-btn-container">
          <button onClick={onModalHide} disabled={btnLoader} className="ar-button secondary">
            Avbryt
          </button>
          <button
            disabled={btnLoader}
            onClick={this.onSaveSupplier}
            ref={el => (this.btnSaveButton = el)}
            className="ar-button primary"
          >
            {btnLoader ? (
              <ReactLoading color="#fff" type="spinningBubbles" height="20px" width="20px" />
            ) : supplierId ? (
              `Spara`
            ) : (
              `Lägg till leverantör`
            )}
          </button>
        </div>
      </div>
    );
  };

  renderQuickCheck() {}
  render() {
    if (this.props.supplierApplication) {
      return <>        
        {this.renderFormSlide()}
        <div style={{marginTop: "16px"}}>
          <BtButton onClick={this.onSaveSupplier} loaderShow={this.state.btnLoader}>Skicka</BtButton>
        </div>
      </>;
    } else {
      return (
        <Modal
          title={this.state.supplierId ? 'Redigera leverantör' : 'Lägg till leverantör'}
          show={this.props.show}
          setShow={this.props.setShow}
          buttonInfo={{ label: this.state.supplierId ? `Spara` : `Lägg till`, action: this.onSaveSupplier, loaderShow: this.state.btnLoader, disabled: this.state.btnLoader }}
          isScrollable
        >
          {this.renderFormSlide()}
        </Modal>
      );  
    }
  }
}

export default injectIntl(AppAddEditSupplier);