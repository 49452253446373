import BackButton from '../../components/shared/BackButton/BackButton';
import styles from './page-internal-bid-form.module.scss';
import { RouteComponentProps } from 'react-router-dom';
import { useEffect, useState, VFC } from 'react';
import { ServiceQuotation } from '../../services/service-quotation';
import Tabs from '../../components/shared/Tabs';
import QuotationForm, { VersionData } from '../page-quotation/QuotationForm/QuotationForm';
import AppLayout from '../../components/layout/app-layout/app-layout';

type Supplier = {
  id: string;
  name: string;
  slug: string;
}

type Params = {
  areaSlug: string;
  project: string;
  supplierSlug: string;
}

type RouteTypes = RouteComponentProps<Params, Record<string, unknown>, { supplierId?: string }>

type Props = {
  history: RouteTypes['history'];
  match: RouteTypes['match'];
}

const PageInternalBidForm: VFC<Props> = ({ history, match }) => {
  const { areaSlug, project, supplierSlug } = match.params;
  const [projectName, setProjectName] = useState('');
  const [areaName, setAreaName] = useState('');
  const [loading, setLoading] = useState(true);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [selectedTab, setSelectedTab] = useState<number>(-1);
  const [selectedSupplier, setSelectedSupplier] = useState<Supplier>();
  const [quotationVersions, setQuotationVersions] = useState<VersionData[]>([]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const { projectName, areaName, uniqueSuppliers }: { projectName: string, areaName: string, uniqueSuppliers: Supplier[] } = await ServiceQuotation.getUniqueQuotationSuppliers(project, areaSlug);
      setSuppliers(uniqueSuppliers);
      setProjectName(projectName);
      setAreaName(areaName);
      setLoading(false);
    })()
  }, []);

  useEffect(() => {
    if (suppliers?.length > 0) {
      if (supplierSlug) {
        setSelectedTab(suppliers.findIndex(supplier => supplier.slug === supplierSlug) || 0);
      } else {
        setSelectedTab(0);
      }
    }
  }, [suppliers]);

  useEffect(() => {
    setSelectedSupplier(suppliers[selectedTab])
  }, [selectedTab]);

  useEffect(() => {
    if (selectedSupplier) {
      (async () => {
        const quotation = await ServiceQuotation.getProjectQuotation(project, areaSlug, selectedSupplier.id);
        setQuotationVersions(quotation);
      })()
    }
  }, [selectedSupplier]);

  return (
    <AppLayout title={<BackButton onClick={() => {
      const url = match.url.split('/');
      url.splice(5);
      history.push(url.join('/'));
    }} path={[projectName, `Anbud för ${areaName}`]} />}>
      <div className={styles.container}>
        {!loading && selectedSupplier && (
          <>
            <div className={styles.content}>
              <Tabs tabs={suppliers.map(supplier => supplier.name)} selected={selectedTab} onChange={tabIndex => {
                  setSelectedTab(tabIndex);
                  const updatedUrl = match.url.substring(0, match.url.lastIndexOf('/') + 1) + suppliers[tabIndex].slug;
                  history.push(updatedUrl)
                }} />
              <QuotationForm key={quotationVersions?.[0]?.supplier?._id} quotationVersions={quotationVersions} />
            </div>
          </>
        )}
      </div>
      </AppLayout>
  );
}

export default PageInternalBidForm;