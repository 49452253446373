import React, { FC, ReactElement } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import appState from "../../../state/AppStateContainer";
import Tabs from "../Tabs";

export type TabsType = ReactElement<React.ComponentProps<typeof Tabs>> | Array<ReactElement<React.ComponentProps<typeof Tabs>>> ;

type Params = {};
type RouteTypes = RouteComponentProps<null, Record<string, unknown>, Params>;

type Props = {
  tabInfo: {
    name: string,
    path: string,
    component: FC<{ tabs: TabsType }>,
    props?: any,
  }[],
  history: RouteTypes['history'],
}

type SelectedTabsProps = {
  tabs: {
    name: string,
    path: string,
  }[],
  selected: number,
  history: RouteTypes['history'],
  baseUrl: string,
}

const SelectedTabs: FC<SelectedTabsProps> = ({ tabs, selected, history, baseUrl }) => (
  <Tabs tabs={tabs.map(x => x.name)} selected={selected} onChange={(tabIndex) => {
    history.push(`${baseUrl}${tabs[tabIndex].path}`);
  }} />
)

export const PageWithTabs: FC<Props> = ({ tabInfo, history }) => {
  const url = appState._getCurrentOrgUrl();

  return (
    <Switch>
      {tabInfo.map((tab, i) => (
        <Route
          exact
          key={tab.name}
          path={`${url}${tab.path}`}
          render={
            () => (
              <tab.component
                {...tab.props}
                tabs={
                  <SelectedTabs
                    tabs={tabInfo}
                    selected={i}
                    history={history}
                    baseUrl={url}
                  />
                }
              />
            )
          }
        />
      ))}
    </Switch>
  )
}