import { FC, useEffect, useState } from "react"
import { SearchIcon, TrashIcon } from "../../components/svgs";
import { OptionType } from "../../models/global.model";
import BtButton from "../bt-button/bt-button";
import { ReactComponent as EditIcon } from '../../assets/svg/editIcon.svg';
import Modal from "../../components/shared/Modal/Modal";
import Field from "../../components/shared/Field/Field";
import styles from './app-edit-list.module.scss';
import { Loading } from "../../components/shared";
import { NotificationManager } from "react-notifications";

type Props = {
  name: string;
  list: OptionType[];
  onChangeLabel: (label: string, id: string) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
  loading: boolean;
  plural?: string
}

const AppEditList : FC<Props> = ({ name, list, onChangeLabel, onDelete, loading, plural }) => {
  const [searchValue, setSearchValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [processing, setProcessing] = useState(false);
  const [errorMessage, _setErrorMessage] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editModalKey, setEditModalKey] = useState(Date.now());
  const [deleteModalKey, setDeleteModalKey] = useState(Date.now() + 1);

  useEffect(() => {
    if (showEditModal) {
      setEditModalKey(Date.now());
    }
  }, [showEditModal]);
  useEffect(() => {
    if (showDeleteModal) {
      setDeleteModalKey(Date.now());
    }
  }, [showDeleteModal]);
  const filteredList = list
    .filter(item => item?.label.toLowerCase().includes(searchValue.toLowerCase()))
    .sort((a, b) => (a?.label.toLowerCase() ?? "").localeCompare(b?.label.toLowerCase() ?? ""));
  return <>
    <Field 
      value={searchValue}
      onChange={value => setSearchValue(value)}
      placeholder="Sök"
      icon={<SearchIcon size={18} />}
    />
    <ul className={styles.list}>
      {loading ? (
        <li key="empty" className={styles.empty}>
          <Loading type="inline" />
        </li>  
      ) : (
        filteredList.length ? (filteredList.map(item => item && (
          <li key={item.value}>
            {item.label}
            <div className={styles.buttons}>
              <BtButton
                color='white'
                size='xxs'
                leftIcon={<EditIcon style={{ width: '14px', fill: 'var(--gray-700)' }} />}
                onClick={() => {
                  setInputValue(item.label);
                  setSelectedId(item.value);
                  setShowEditModal(true);
                }}
              />
              <BtButton
                color='white'
                size='xxs'
                leftIcon={<TrashIcon size='14px' color='var(--gray-700)' />}
                onClick={() => {
                  setInputValue(item.label);
                  setSelectedId(item.value);
                  setShowDeleteModal(true);
                }}
              />
            </div>
          </li>
        ))) : (
          <li key="empty" className={styles.empty}>
            Inga {plural ?? name.toLowerCase()} matchar din sökning.
          </li>
        )
      )}
    </ul>
    <Modal
      key={editModalKey}
      title={"Redigera " + name.toLowerCase()}
      show={showEditModal}
      setShow={setShowEditModal}
      buttonInfo={{
        label: 'Spara',
        loaderShow: processing,
        //disabled: !(editingOption?.option && editingOption?.index >= 0),
        action: (async () => {
          setProcessing(true);
          try {
            await onChangeLabel(inputValue, selectedId);
          } catch (e : any) {          
            const message = e.toString();
            const data = e.response?.data;
            const details = data?.error ?? data?.message ?? data?.toString();
            NotificationManager.error([message, details].filter(x=>x).join(": "), "Kunde inte spara");
            setProcessing(false);
            return;
          }
          NotificationManager.success("Sparat");
          setProcessing(false);
          setShowEditModal(false);
        }),
      }}
    >
      <Field
        value={inputValue}
        onChange={(value) => setInputValue(value)}
        onBlur={(_event => setInputValue(inputValue.trim()))}
        errorMessage={errorMessage}
      />
    </Modal>
    <Modal
      key={deleteModalKey}
      title={`Ta bort ` + name.toLowerCase()}
      show={showDeleteModal}
      setShow={setShowDeleteModal}
      buttonInfo={{
        label: 'Ta bort',
        loaderShow: processing,
        action: (async () => {
          setProcessing(true);
          try {
            await onDelete(selectedId);
          } catch (e : any) {
            const message = e.toString();
            const data = e.response?.data;
            const details = data?.error ?? data?.message ?? data.toString();
            NotificationManager.error([message, details].filter(x=>x).join(": "), "Kunde inte ta bort");
            setProcessing(false);
            return;
          }
          NotificationManager.success(`Tog bort ${name.toLowerCase()}`);
          setProcessing(false);
          setShowDeleteModal(false);
        }),
      }}
    >
      <span style={{maxWidth:"600px", fontSize: "14px"}}>{`Är du säker på att du vill ta bort "${inputValue}"?`}</span>
    </Modal>
  </>
}

export default AppEditList;