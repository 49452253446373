import React, { useState, useEffect } from 'react';
import { areEqual, isValidEmail } from '../../util';
import CloseIcon from '@material-ui/icons/Close';
import Toggle from 'react-toggle';
import DatePicker from '../../components/shared/DatePicker/DatePicker';
import './selected-supplier-module.css';
import StyledCheckbox from './../../components/shared/StyledCheckbox/StyledCheckbox';
import BtButton from '../bt-button/bt-button';
import Selector from '../../components/shared/Selector/Selector';

const SelectedSupplierModule = React.memo(
  ({
    contactPersons,
    selectedSuppliers,
    onRemoveSupplier,
    quoteVersion,
    quoteVersions,
    onSelectContactPerson,
    onToggleQuotation,
    handleQuoteExpiry,
    extendedDate,
    checkQuotationExpiry,
    onSaveSelectedSuppliersClick,
    showLoading,
    errorMsg,
  }) => {
    const [sendAutoBid, setSendAutoBid] = useState(quoteVersions?.length  > 0);

    const handleQuotationToggle = () => {
      setSendAutoBid(prevState => {
        onToggleQuotation(!prevState);
        return !prevState
      });
    };

    useEffect(() => {
      setSendAutoBid(quoteVersions?.length > 0);
      onToggleQuotation(quoteVersions?.length > 0);
    }, [quoteVersions?.length > 0]);

    const renderDropdown = sup => {
      const selectedContact = contactPersons.find(mem => mem.supplierId === sup.sup_id)?.supplierMember || sup.supMember[0];
      return sup.supMember && sup.supMember.length > 0 && sendAutoBid ? (
        <div className="kontact-person">
          <p className="color-gray-700">Kontaktperson</p>
          <Selector
            className='selectSuppliersDropdown'
            placeholder="Välj kontaktperson"
            options={
              sup.supMember
                .filter(supMember => isValidEmail(supMember.email))
                .map(supMember => ({
                label: supMember.name,
                value: supMember,
              }))
            }
            value={{ label: selectedContact.name, value: selectedContact }}
            onChange={e => onSelectContactPerson(e.value)}
          />
        </div>
      ) : (
        <div></div>
      );
    };

    const renderQuoteToggle = () => {
      return (
        quoteVersions?.length  > 0 && selectedSuppliers && selectedSuppliers.length > 0 && (
          <div className="autoBid-toggle">
            <p>Förfrågningsunderlag</p>
            <label className="autoBid-toggle-label">
              <Toggle
                disabled={!(selectedSuppliers && selectedSuppliers.length)}
                className="ar-checkbox-toggle autobid-switch"
                checked={sendAutoBid}
                onChange={handleQuotationToggle}
                icons={false}
              />
              <p style={{ fontSize: '14px', color: 'var(--gray-700)' }}>Skicka senaste förfrågningsunderlaget automatiskt</p>
            </label>
          </div>
        )
      );
    };

    const renderDateExtend = () => {
      let exp_check = checkQuotationExpiry();
      const _extDate = extendedDate ? new Date(extendedDate) : null;

      return quoteVersions?.length  > 0 && exp_check && sendAutoBid && (
        <div className="autoQuoteCheckbox">
          <div className="date-extend-container">
            <p>
              Välj nytt utgångsdatum<br/>
              <span>Utgångsdatumet för förfrågningsunderlaget har gått ut. Du kan ändra datumet här.</span>
            </p>
            <div className="date-extend-sub-container">
              <DatePicker
                value={_extDate}
                selected={_extDate}
                minDate={new Date()}
                popperPlacement="top-end"
                popperClassName="column-date-end-calendar-position-fix"
                onChange={handleQuoteExpiry}
                style={{ color: 'var(--gray-700)' }}
              />
              <div style={{ color: 'red', marginTop: '16px', fontSize: '14px' }}>{errorMsg}</div>
            </div>
          </div>            
        </div>
      );
    };

    const handleRemoveSup = sup => {
      onRemoveSupplier(null, sup);
    };

    const renderSuppliers = sup => {
      return (
        <div className={`selected-supplier-mod`} key={sup.sup_id}>
          <div className="sup_name_head">
            <StyledCheckbox checked onClick={() => handleRemoveSup(sup)} />
            <div className="sup_name">{sup.sup_name}{sup.isRecommended && <span>{' (läggs till i leverantörsdatabasen)'}</span>}</div>
          </div>
          <div className="icon-dropdown-container">
            {renderDropdown(sup)}
            <div className='selectedSupplierCloseDiv' onClick={() => handleRemoveSup(sup)}>
              <span>Ta bort</span>
              <CloseIcon className="supList-remove-icon" htmlColor='var(--gray-500)' />
            </div>
          </div>
        </div>
      );
    };
    const ValdComponent = React.memo(() => (
      <React.Fragment>
        <b>{selectedSuppliers.length}</b> {`vald${selectedSuppliers.length > 1 ? 'a' : ''}`}
      </React.Fragment>
    ));
    ValdComponent.displayName = 'ValdComponent';

    const vald = selectedSuppliers.length ? <ValdComponent /> : 'Inga valda leverantörer';

    const buttonText = (() => {
      if (quoteVersions?.length > 0) {
        if (sendAutoBid) {
          return 'Spara och skicka förfrågan';
        } else {
          return 'Spara utan att skicka förfrågan';
        }
      } else {
        return 'Spara'
      }
    })()

    return (
      <React.Fragment>
        <div className="selected-supplier-mod-head">
          <div>
            Valda leverantörer
            <span className="vald">{vald}</span>
          </div>
        </div>

        {selectedSuppliers && selectedSuppliers.length > 0 ? (
          <div style={{display:"flex", flexDirection:"column", gap:"16px"}}>
            {selectedSuppliers.map(sup => renderSuppliers(sup))}
          </div>
        ) : (
          <span style={{ fontSize: '14px' }}>Dina valda leverantörer kommer visas här.</span>
        )}
        {renderQuoteToggle()}
        {renderDateExtend()}
        {selectedSuppliers.length > 0 && (<>
          <span className="border"></span>
          <div style={{display:"flex", justifyContent: "flex-end"}}>
            <BtButton
              onClick={onSaveSelectedSuppliersClick}
              loaderShow={showLoading}
            >
              {buttonText}
            </BtButton>
          </div>
        </>)}
      </React.Fragment>
    );
  },
  areEqual,
);

SelectedSupplierModule.displayName = 'SelectedSupplierModule';

export default SelectedSupplierModule;
