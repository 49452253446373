import * as React from 'react';
import PropTypes from 'prop-types';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import UserDetailsForm from '../page-registration/user-details';
import appState from '../../state/AppStateContainer';
import Tabs from '../../components/shared/Tabs'

import './styles.css';
import { ServiceUser } from '../../services/service-user';
import AppChangePassword from '../app-password/app-change-password';
import _ from 'lodash';
import { styled } from '@material-ui/core';
import AppLayout from '../../components/layout/app-layout/app-layout';
import AppEditDemands from '../app-edit-options/app-edit-demands';
import AppEditPriceFields from '../app-edit-options/app-edit-price-fields';
import AppEditErfaQuestions from '../app-edit-options/app-edit-erfa-questions';
import AppEditCategories from '../app-edit-options/app-edit-categories';
import { Switch, Route, withRouter } from 'react-router-dom';
import AppEditPriceUnits from '../app-edit-options/app-edit-price-units';
import OrganizationSettings from './OrganizationSettings';

// import { styled } from '@material-ui/styles';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      className="custom-tab-style"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {(value === index) && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function PageSettings({ history, match }) {
  const url = appState._getCurrentOrgUrl() + "/settings";
  const isPurchasing = appState.isPurchasingActive();
  const tabs = [
    {
      title: "Inställningar", 
      link: "",
      component: <OrganizationSettings />,
      show: true,
    },
    {
      title: "Prisfält", 
      link: "/price-fields",
      component: <AppEditPriceFields />,
      show: isPurchasing,
    },
    {
      title: "Skallkrav", 
      link: "/requirements",
      component: <AppEditDemands />,
      show: isPurchasing,
    },
    {
      title: "Erfarenhetsåterföringsfrågor", 
      link: "/erfa-questions",
      component: <AppEditErfaQuestions />,
      show: isPurchasing,
    },
    {
      title: "Yrkeskategorier", 
      link: "/categories",
      component: <AppEditCategories />,
      show: isPurchasing,
    },
    {
      title: "Enheter", 
      link: "/price-units",
      component: <AppEditPriceUnits />,
      show: isPurchasing,
    },
  ].filter(tab => tab.show);

  const tabNames = tabs.map(t => t.title);

  const handleChange = (newValue) => {
    const links = tabs.map(t => t.link);
    history.push(url + links[newValue]);
  };

  const renderRoute = (tab, i) => (
    <Route path={`${url}${tab.link}`} key={tab.title}>
      <>
        <Tabs tabs={tabNames} selected={i} onChange={handleChange} />
        {tab.component}
      </>
    </Route>
  );

  return (
    <AppLayout title={`Inställningar för ${appState.getCurrentOrgName()}`}>

    <div className="settings-container">
      <Switch>
        {tabs.slice(1).map((tab,i) => renderRoute(tab, i+1))}
        {renderRoute(tabs[0], 0)}
      </Switch>
    </div>
    </AppLayout>
  );
}

export default withRouter(PageSettings);