import React, { createRef, useCallback, useEffect, useMemo, useRef } from 'react';
import { Checkbox, Radio, RadioGroup } from '@material-ui/core';
import { areEqual } from '../../../util';
import { isArray } from 'lodash';
import SearchBarWithActions from './../SearchBarWithActions/SearchBarWithActions';
import { useState } from 'react';
import { ServiceUser } from '../../../services/service-user';
import appEnum from '../../../util/appEnum';
import appState from '../../../state/AppStateContainer';
import SearchIcon from '@material-ui/icons/Search';
import BtButton from '../../../ts-components/bt-button/bt-button';
import NotificationCounter from '../NotificationCounter/NotificationCounter';
const FiltersDropdown = props => {
  const {
    multiple,
    onSubmitFilter,
    onResetFilter,
    checkboxes,
    toggleFilter,
    showFilter,
    filtersData: parentFilterState,
  } = props;
  const wrapperRef = useRef();
  const mounted = useRef();
  const countRef = useRef(0);
  let listRefs = useRef(checkboxes.map(() => createRef()));
  let arrowsRef = useRef(checkboxes.map(() => createRef()));
  let searchBarRefs = useRef(checkboxes.map(() => createRef()));
  const [searchValue, setSearchValue] = useState('');
  const [numberOfFilters, setNumberOfFilters] = useState(0);
  const prevState = useRef(); //for saving previous state
  const filterData = useMemo(() => {
    if (!mounted.current) {
      let temp = {};
      checkboxes.forEach(el => {
        const name = el.name;
        temp[name] = null;
      });
      mounted.current = true;
      return temp;
    }
    prevState.current = { ...parentFilterState };
    return prevState.current;
  }, [checkboxes, parentFilterState]);
  useEffect(() => {
    if (parentFilterState) {
      handleCounter();
    }
  }, [parentFilterState]);
  useEffect(() => {
    prevState.current = {};
    function handleClickEvent(e) {
      const wrapperElement = wrapperRef.current;
      if (wrapperElement && !wrapperElement.contains(e.target)) {
        toggleFilter(false);
      }
    }
    window.addEventListener('click', handleClickEvent);
    return () => {
      window.removeEventListener('click', handleClickEvent);
    };
  }, []);
  const handleCheckboxChange = index => {
    let ulNode = listRefs.current[index].current;
    let arrowNode = arrowsRef.current[index].current;
    let searchBarRef = searchBarRefs.current[index].current;
    arrowNode.classList.toggle('active');
    ulNode.classList.toggle('active');
    if (searchBarRef.style.display === 'none') {
      searchBarRef.style.display = 'block';
    } else {
      searchBarRef.style.display = 'none';
    }
  };
  const handleChange = e => {
    const { name, value, checked } = e.target;
    if (!multiple) {
      filterData[name] = value;
    } else {
      if (checked) {
        filterData[name] = isArray(filterData[name]) ? [...filterData[name], value] : [value];
      } else {
        filterData[name] = isArray(filterData[name]) && filterData[name].filter(el => el !== value);
      }
    }
  };
  const handleSubmit = async () => {
    handleCounter();
    onSubmitFilter(filterData);
    prevState.current = filterData;
    toggleFilter(false);
    await ServiceUser.updatePreferences(
      appState.getUserId(),
      filterData,
      appEnum.userPrefrences.projectFilter,
    );
    setSearchValue({...{}})
  };
  const handleCounter = () => {
    var temp = 0;
    for (const key in filterData) {
      if (filterData[key] && filterData[key].length) {
        temp += 1;
      }
    }
    setNumberOfFilters(temp);
  };
  const handleReset = async () => {
    for (const key in filterData) {
      filterData[key] = [];
    }
    await ServiceUser.updatePreferences(
      appState.getUserId(),
      filterData,
      appEnum.userPrefrences.projectFilter,
      );
    setSearchValue({...{}})
    countRef.current.innerText = 0;
    countRef.current.classList.remove('active');
    onResetFilter();
  };

  const renderData = useCallback(({ name, subChecks }) => {
    const data = subChecks.filter(e => e.label.toLowerCase().includes(searchValue[name] || ''));
    return data.length > 0 ? data.map(({ label, name, value, checked = null }, i) => (
      <li key={label} className=" filters-list-item">
        <label className="checkbox-container">
          <Checkbox
            disableRipple
            name={name}
            value={value}
            color="primary"
            style={{ color: 'var(--orange-500)', fontWeight: '500' }}
            onChange={handleChange}
            defaultChecked={
              (filterData[name] &&
                filterData[name].length &&
                filterData[name].includes(value)) ||
              false
            }
          />
          {label}
        </label>
      </li>
    )) : <div className='filter-empty-msg'>Kunde inte hitta något resultat</div>
  });

  return (
    <div className=" bt-select-wrapper bt-select-filter-wrapper notranslate" ref={wrapperRef}>
      <div style={{ border: 0 }}>
        <div className=" wrapper filter-dropdown" style={{ position: 'relative' }}>
          {/* <span className=" values" onClick={() => toggleFilter(!showFilter)}>
            <span className="description translate">Filter</span>
          </span>
          <div className="filter-counter" ref={countRef}>
            0
          </div> */}
          <BtButton
            leftIcon={<svg
              xmlns="http://www.w3.org/2000/svg"
              enableBackground="new 0 0 24 24"
              height="18px"
              viewBox="0 0 24 24"
              width="18px"
              fill="var(--gray-7 00)"
            >
              <g>
                <path d="M0,0h24 M24,24H0" fill="none" className="path-none" />
                <path d="M7,6h10l-5.01,6.3L7,6z M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6 c0,0,3.72-4.8,5.74-7.39C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z" />
                <path d="M0,0h24v24H0V0z" fill="none" className="path-none" />
              </g>
            </svg>}
            color='white'
            onClick={() => toggleFilter(!showFilter)}
          >
            Filter
          </BtButton>
          <NotificationCounter counter={numberOfFilters} style={{ position: 'absolute', top: 'calc(50% - 8px)', right: 'calc(50% + 14px)', transform: 'translate(50%, -50%)' }} />
          {showFilter && (
            <div className={`filter-inner-wrap show`}>
              <div className=" btn-container">
                <div className=" btn-filter-abort" onClick={handleReset}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enableBackground="new 0 0 24 24"
                    height="18px"
                    viewBox="0 0 24 24"
                    width="18px"
                    fill="transparent"
                  >
                    <g>
                      <rect height="24" width="24" />
                    </g>
                    <g>
                      <g>
                        <path fill='var(--gray-700)' d="M16.95,6l-3.57,4.55l1.43,1.43c1.03-1.31,4.98-6.37,4.98-6.37C20.3,4.95,19.83,4,19,4H6.83l2,2H16.95z" />
                        <path fill='var(--gray-700)' d="M2.81,2.81L1.39,4.22L10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-2.17l5.78,5.78l1.41-1.41L2.81,2.81z" />
                      </g>
                    </g>
                  </svg>
                  Rensa filter
                </div>
                <div className=" btn-filter-submit" onClick={handleSubmit}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enableBackground="new 0 0 24 24"
                    height="18px"
                    viewBox="0 0 24 24"
                    width="18px"
                    fill="#ffff"
                  >
                    <g>
                      <path d="M0,0h24 M24,24H0" fill="none" className="path-none" />
                      <path d="M7,6h10l-5.01,6.3L7,6z M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6 c0,0,3.72-4.8,5.74-7.39C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z" />
                      <path d="M0,0h24v24H0V0z" fill="none" className="path-none" />
                    </g>
                  </svg>
                  Spara filter
                </div>
              </div>

              <ul className="filters-list" style={{ paddingLeft: '0' }}>
                {checkboxes.map(({ label, name, value, checked = null, subChecks }, index) => (
                  <li
                    key={label}
                    className=" filters-list-item filters-list-item-parent"
                    style={{ paddingTop: '10px' }}
                  >
                    <div
                      className=" checkbox-container"
                      onClick={() => handleCheckboxChange(index)}
                    >
                      <i
                        className="fas fa-chevron-right filter-arrow"
                        ref={arrowsRef.current[index]}
                      ></i>
                      <span className="" style={{ fontWeight: '550' }}>
                        {label}
                      </span>
                    </div>
                    <SearchBarWithActions
                      searchicon={<SearchIcon />}
                      value={searchValue[name]}
                      ref={searchBarRefs.current[index]}
                      className="custom"
                      style={{ padding: '15px 15px 0px 15px', display: 'none' }}
                      onChange={value =>
                        setSearchValue(prevValues => ({
                          ...prevValues,
                          [name]: value.toLowerCase(),
                        }))
                      }
                    />
                    {subChecks && (
                      <ul
                        className=" filters-list filters-inner-list"
                        ref={listRefs.current[index]}
                      >
                        {multiple ? (
                          <React.Fragment>{renderData({ name, subChecks })}</React.Fragment>
                        ) : (
                          <React.Fragment>
                            <RadioGroup name={name} onChange={handleChange}>
                              {subChecks.map(({ label, name, value, checked = null }, i) => (
                                <li key={label} className=" filters-list-item">
                                  <div className="checkbox-container">
                                    <Radio
                                      disableRipple
                                      value={value}
                                      inputProps={{ 'aria-label': value }}
                                      defaultChecked={
                                        (filterData[name] &&
                                          filterData[name].length &&
                                          filterData[name].includes(value)) ||
                                        false
                                      }
                                    />
                                    {label}
                                  </div>
                                </li>
                              ))}
                            </RadioGroup>
                          </React.Fragment>
                        )}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(FiltersDropdown, areEqual);
