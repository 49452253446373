
import axios from "axios";
import { ExistingFile, NewFile } from "../components/shared/FileUpload/FileUpload";

export type Area = {
  _id: string;
  name: string;
}

export type Contact = {
  _id: string;
  name: string;
  title: string;
  email: string;
  phone: string;
}

export type OtherEmailAdress = {
  email: string;
  comment: string;
  redirectToId?: string;
  count: number;
  orgs: string[]
}


export type SupplierProfile = {
  name: string;
  orgNr?: string;
  logo?: {
    file: string;
    name: string;
  };
  newLogo?: File;
  presentation: string;
  address: string;
  website: string;
  areas: Area[];
  places: string[];
  contacts: Contact[];
  otherEmailAddresses: OtherEmailAdress[];
  liabilityInsurance?: {
    file?: {
      fileName: string,
      file: string,
      mimeType: string,
    },
    validUntilDate?: Date,
    comment: string,
  };
  newLiabilityInsurance?: File;
  existingFiles: ExistingFile[];
  newFiles: NewFile[];
}

export type Invoice = {
  _id: string,
  organization: string,
  product: string,
  startDate: Date,
  endDate: Date,
  invoiceNumber: string,
  cause: "ORDER" | "RENEWAL",
  amount: string,
}

export type SubscriptionEvent = {
  _id: string,
  action: "ORDER" | "RENEW" | "CANCEL" | "UNCANCEL" | "EXPIRE",
  date: Date,
  user: string,
  price: string,
  duration: string,
  durationUnit: "MONTH" | "WEEK",
}

export type Subscription = {
  active?: {
    startDate: Date, 
    endDate: Date, 
    autoRenew: boolean,
    price: string,
    duration: string,
    durationUnit: "MONTH" | "WEEK",
    adId: string,
    cancelAt: Date,
    cancellationDelay: string,
  }, 
  invoices: Invoice[],
  history: SubscriptionEvent[],
}


class ServiceSales {
  async saveSupplierProfile(supplierProfile : SupplierProfile) {
    const formData = new FormData();
    const { newLogo, newLiabilityInsurance, newFiles, ...json } = supplierProfile;
    formData.set("json", JSON.stringify(json));
    if (newLogo) formData.set("newLogo", newLogo);
    if (newLiabilityInsurance) formData.set("newLiabilityInsurance", newLiabilityInsurance);
    if (newFiles && newFiles.length > 0) {
      for (let i = 0; i < newFiles.length; i++) {
        formData.append(`newFiles`, newFiles[i].file);
      }
    }
    const response = await axios.put("/api/sales/supplier-profile", formData);
    return response.data;
  }

  async fetchSupplierProfile() : Promise<[SupplierProfile, Area[]]> {
    const response = await axios.get("/api/sales/supplier-profile");
    const {areaOptions, ...supplierProfile } = response.data;
    return [supplierProfile, areaOptions];
  }

  async fetchSupplierProfileForOrgNumber(orgNumber : string) : Promise<SupplierProfile> {
    const response = await axios.get(`/api/sales/supplier-profile/${orgNumber}`);
    return response.data;
  }

  async fetchAllQuotationsSubscription(): Promise<Subscription> {
    const response = await axios.get("/api/sales/all-quotations/");
    return response.data;
  }

  async orderAllQuotationsSubscription(): Promise<Subscription> {
    const response = await axios.post("/api/sales/all-quotations/order");
    return response.data;
  }

  async cancelAllQuotationsSubscription(): Promise<Subscription> {
    const response = await axios.post("/api/sales/all-quotations/cancel");
    return response.data;
  }

  async fetchRecommendedSubscription(): Promise<Subscription> {
    const response = await axios.get("/api/sales/recommended/");
    return response.data;
  }

  async orderRecommendedSubscription(): Promise<Subscription> {
    const response = await axios.post("/api/sales/recommended/order");
    return response.data;
  }

  async cancelRecommendedSubscription(): Promise<Subscription> {
    const response = await axios.post("/api/sales/recommended/cancel");
    return response.data;
  }

  async fetchPublishedQuotationsSubscription(): Promise<Subscription> {
    const response = await axios.get("/api/sales/published-quotations/");
    return response.data;
  }

  async orderPublishedQuotationsSubscription(): Promise<Subscription> {
    const response = await axios.post("/api/sales/published-quotations/order");
    return response.data;
  }

  async cancelPublishedQuotationsSubscription(): Promise<Subscription> {
    const response = await axios.post("/api/sales/published-quotations/cancel");
    return response.data;
  }
  async fetchInvoices(): Promise<Invoice[]> {
    const response = await axios.get("/api/sales/invoices");
    return response.data;
  }

  async saveInvoiceNumber(_id: string, invoiceNumber: string) {
    const response = await axios.put("/api/sales/invoices/" + _id, { invoiceNumber });
    return response.data;
  }

  async hasProfileBeenUpdated() {
    const response = await axios.get("/api/sales/supplier-profile-has-been-updated");
    return response.data;
  }
} 

export default new ServiceSales();