import React from "react";
import { AppChatMessage } from "../../ts-components/app-chat-message/app-chat-message";
import appState from "../../state/AppStateContainer";
import { CustomAvatar, Loading } from '../../components/shared';

import "./app-chat-messages.css";
import PropTypes from "prop-types";

//import { IAppChatSubmitData } from '../app-chat/app-chat';

export class AppChatMessages extends React.Component {
  currentClient = this.props.currentClient;
  messages = this.props.messages;
  static contextTypes = {
    router: PropTypes.object
  };
  render() {
    if (this.props.loading) {
      return (
        <div className="no-conversation-wrapper">
          <div className="no-conversation" style={{
            position: 'absolute', left: '50%', top: '2em',
          }}>
            <Loading type="inline"></Loading>
          </div>
        </div>
      );    
    }
    if(appState.state.token){
    return (
      <div className="app-chat-messages">
        {(this.props.messages.length > 0 && this.props.currentId !== appState.state.token.id &&
          this.props.messages.map((item, key) => {
            return (
              <AppChatMessage
                {...item}
                chatOnly= {this.props.chatOnly}
                key={this.props.messages.length - 1 - key}
                scope={this.props.scope}
                scopeOptions={this.props.scopeOptions}
              ></AppChatMessage>
            );
          })) || (
            <div className="no-conversation-wrapper">
              <div className="no-conversation" style={this.props.currentId === appState.state.token.id ? {
                position: 'absolute', left: '50%', top: '50%',
                transform: 'translate(-50%, -50%)'
              }:{
                marginTop: '2em',              
                // display: "none"
              }}>
                {this.props.currentClient.name && <CustomAvatar alt={this.props.currentClient.name} src={this.props.currentClient.avatarUrl} size="70px"  />}
                <h2>{this.props.currentClient.name}</h2>
                <p>{this.props.currentClient.email}</p>
                <p>{this.props.currentClient.phone}</p>
              </div>
            </div>
        )}
      </div>
    );
    }
    return (
      this.props.messages.length > 0 && 
        this.props.messages.map((item, key) => {
          return (
            <AppChatMessage
              {...item}
              chatOnly= {this.props.chatOnly}
              key={this.props.messages.length - 1 - key}
              scope={this.props.scope}
              scopeOptions={this.props.scopeOptions}
            ></AppChatMessage>
          );
        })
    )
  }
}
