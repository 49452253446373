import { BarChart } from '../../components/shared/Charts/BarChart/BarChart';
import SummaryBarList from '../../components/shared/Charts/SummaryBarList/SummaryBarList';
import { NewsCardProps } from './NewsCard/NewsCard';
import { OverviewItemType } from './Tabs/Overview/OverviewTab';
import { TemplateOptionType } from './TemplateItem/TemplateItem';
import { LineChart } from '../../components/shared/Charts/LineChart/LineChart';
import SummaryCard from '../../components/shared/Charts/SummaryCard/SummaryCard';
import PieChart from '../../components/shared/Charts/PieChart/PieChart';

const translation = {
  activeUsers: 'Aktiva användare',
  'Bids': 'Anbud',
  yesNoBid: 'Avsett lämna anbud men inte skickat in anbud',
  'No': 'Avser att inte lämna anbud',
  noAnswer: 'Inget svar',
  quotationResponsiveness: 'Svar på anbudsförfrågningar',
  quotationResponsivenessTip: 'Hur många anbud samt avsiktsförklaringar fick vi tillbaka',
  monthlyActiveUser: 'Aktiva användare per månad',
  users: 'Användare',
  months: 'Månader',
  totalSavings: 'Total inköpsbesparing',
  totalSavingsTip: 'Skillnad mellan total kontraktssumma och budget',
  totalSavingsPercentage: 'Inköpsbesparing i procent',
  totalSavingsPercentageTip: 'Total inköpsbesparing i procent av budget',
  bidRate: 'Anbudsfrekvens',
  bidRateTip: 'Procentandel av tillfrågade leverantörer som lämnade in anbud',
  answerRate: 'Svarsfrekvens',
  answerRateTip: 'Procentandel av tillfrågade leverantörer som svarade (lämnade avsiktsförklaring eller anbud)',
  bidsReceived: 'Mottagna anbud',
  bidsReceivedTip: 'Totalt antal inkomna anbud från leverantörer',
  quotationsSent: 'Skickade förfrågningar', 
  quotationsSentTip: 'Totalt antal tillfrågade leverantörer',
  savingsAreaPercentage: 'Inköpsbesparing per yrkeskategori i procent',
  savingsAreaPercentageTipList: 'Procentuell skillnad mellan total kontraktssumma och budget per yrkesområde i pågående projekt du har tillgång till',
  savings: 'Inköpsbesparing',
  area: 'Yrkesområde',
  savingsAreaPercentageTipBar: 'Procentuell skillnad mellan total kontraktssumma och budget per yrkesområde i pågående projekt du har tillgång till',
  percentageDiff: 'Skillnad i procent',
  savingsArea: 'Inköpsbesparing per yrkesområde',
  savingsAreaTipList: 'Skillnad mellan total kontraktssumma och budget per yrkesområde i pågående projekt du har tillgång till',
  savingsAreaTipBar: 'Skillnad mellan total kontraktssumma och budget per yrkesområde i pågående projekt du har tillgång till',
  difference: 'Skillnad',
  savingsProjectPercentage: 'Inköpsbesparing per projekt i procent',
  project: 'Projekt',
  savingsProjectPercentageTipList: 'Procentuell skillnad mellan total kontraktssumma och budget per aktivt projekt som du har tillgång till',
  savingsProjectPercentageTipBar: 'Procentuell skillnad mellan total kontraktssumma och budget per aktivt projekt som du har tillgång till',
  savingsProject: 'Inköpsbesparning per projekt',
  amount: 'Antal',
  savingsProjectTipList: 'Skillnaden mellan mellan total kontraktssumma och budget per aktivt projekt som du har tillgång till',
  savingsProjectTipBar: 'Skillnaden mellan mellan total kontraktssumma och budget per aktivt projekt som du har tillgång till',
  contractArea: 'Kontraktsvolym per yrkesområde',
  contractAreaTipList: 'Kontraktsvolym per yrkesområde i pågående projekt som du har tillgång till',
  contractAmount: 'Kontraktsvolym',
  contractAreaTipBar: 'Kontraktsvolym per yrkesområde i pågående projekt som du har tillgång till',
  contractProjectTipList: 'Kontraktsvolym per pågående projekt du har tillgång till',
  contractProject: 'Kontraktsvolym per projekt',
  contractProjectTipBar: 'Kontraktvolym per aktivt projekt du har tillgång till',
  bidsReceivedAreaTipList: 'Inkomna anbud per yrkesområde i pågående projekt du har tillgång till',
  bidsReceivedAreaTipBar: 'Inkomna anbud per yrkesområde i pågående projekt du har tillgång till',
  bidsReceivedArea: 'Inkomna anbud per yrkesområde',
  bidsReceivedProjectTipList: 'Inkomna anbud per aktivt projekt du har tillgång till',
  bidsReceivedProjectTipBar: 'Inkomna anbud per aktivt projekt du har tillgång till',
  bidsReceivedProject: 'Inkomna anbud per projekt',
  received: 'Mottagen',
  quotationsArea: 'Förfrågningsunderlag per yrkesområde',
  quotationsProject: 'Förfrågningsunderlag per projekt',
  quotationsAreaTipList: 'Antal förfrågningsunderlag per yrkesområde i pågående projekt du har tillgång till',
  quotationsAreaTipBar: 'Antal förfrågningsunderlag per yrkesområde i pågående projekt du har tillgång till',
  quotationsProjectTipList: 'Antal förfrågningsunderlag per pågående projekt du har tillgång till',
  quotationsProjectTipBar: 'Antal förfrågningsunderlag per pågående projekt du har tillgång till',
  quotations: 'Förfrågningsunderlag',
  sent: 'Skickat',
  bidRateArea: 'Anbudsfrekvens per yrkesområde',
  bidRateAreaTipList: 'Procentandel av anbud i förhållande till det totala antalet tillfrågade leverantörer per yrkesområde av pågående projekt du har tillgång till',
  bidRateAreaTipBar: 'Procentandel av anbud i förhållande till det totala antalet tillfrågade leverantörer per yrkesområde av pågående projekt du har tillgång till',
  bidRateProject: 'Anbudsfrekvens per projekt',
  bidRateProjectTipList: 'Procentandel av anbud i förhållande till det totala antalet tillfrågade leverantörer per pågående projekt du har tillgång till',
  bidRateProjectTipBar: 'Procentandel av anbud i förhållande till det totala antalet tillfrågade leverantörer per pågående projekt du har tillgång till',
  budgetProject: 'Budget per projekt',
  budgetProjectTipList: 'Total budget per pågående projekt du har tillgång till',
  budgetProjectTipBar: 'Total budget per pågående projekt du har tillgång till',
  budgetArea: 'Budget per yrkesområde',
  budgetAreaTipList: 'Total budget per yrkesområde i pågående projekt du har tillgång till',
  budgetAreaTipBar: 'Total budget per yrkesområde i pågående projekt du har tillgång till',
  budget: 'Budget',
  averageSuppliersArea: 'Antal tillfrågade leverantörer i genomsnitt per yrkesområde',
  suppliersAsked: 'Tillfrågade leverantörer',
  averageSuppliersAreaTipList: 'Antal leverantörer som får en förfrågan i genomsnitt per område i pågående projekt du har tillgång till',
  averageSuppliersAreaTipBar: 'Antal leverantörer som får en förfrågan i genomsnitt per område i pågående projekt du har tillgång till',
  averageSuppliersAsked: 'Antal tillfrågade leverantörer i genomsnitt',
  activePurchases: 'Pågående inköp',
  activePurchasesTip: 'Alla pågående inköp i pågående projekt som du har tillgång till',
  closedProjects: 'Avslutade projekt',
  closedProjectsTip: 'Alla avslutade projekt',
  openProjects: 'Pågående projekt',
  openProjectsTip: 'Alla pågående projekt',
  numOfPurchases: 'Antal inköp',
  activePurchasesArea: 'Pågående inköp per yrkesområde',
  activePurchasesAreaTipList: 'Antal inköp per yrkesområde i pågående projekt som du har tillgång till',
  activePurchasesAreaTipBar: 'Antal inköp per yrkesområde i pågående projekt som du har tillgång till',
  activePurchasesProject: 'Inköp per pågående projekt',
  activePurchasesProjectTipList: 'Antal inköp i pågående projekt som du har tillgång till',
  activePurchasesProjectTipBar: 'Antal inköp i pågående projekt som du har tillgång till',
  quotationsBidsPerProject: 'Förfrågningsunderlag och anbud per projekt',
  quotationsBidsPerArea: 'Förfrågningsunderlag och anbud per yrkesområde',
  quotationsBidsPerProjectTipBar: 'Tillfrågade leverantörer och anbud per yrkesområde i pågående projekt du har tillgång till',
  quotationsBidsPerAreaTipBar: 'Tillfrågade leverantörer och anbud per pågående projekt du har tillgång till',
}
type TranslationType = keyof typeof translation;

export const UNIT = {
  'AMOUNT': 'AMOUNT',
  'PERCENTAGE': 'PERCENTAGE',
} as const;
export type UNIT = typeof UNIT[keyof typeof UNIT];

export type ProjectsType = {
  open: number,
  closed: number,
}

export type ActiveUsers = {
  month: string,
  activeUsers: number,
}[]

export type ActiveBidCardsType = {
  total: {
    amount: number,
    numOfCards: number,
  },
  projects: {
    budget: number,
    numOfPurchases: number,
    name: string,
  }[],
  areas: {
    budget: number,
    numOfPurchases: number,
    averageSuppliersAsked: number,
    name: string,
  }[]
}
export type ContractAmountType = {
  total: number,
  projects: {
    contractAmount: number,
    name: string,
  }[],
  areas: {
    contractAmount: number,
    name: string,
  }[]
}

export type ProcurementSavingsType = {
  total: {
    difference: number,
    percentageDiff: number,
  },
  projects: {
    difference: number,
    percentageDiff: number,
    name: string,
  }[],
  areas: {
    difference: number,
    percentageDiff: number,
    name: string,
  }[],
}

export type RSSFeedType = NewsCardProps[]

export type BidsAndIntent = {
  total: {
    sent: number,
    received: number,
    intent: number,
    bidRate: number,
    answerRate: number,
    responsiveness: {
      bid: number,
      yesNoBid: number,
      no: number,
      noAnswer: number,
    },
  },
  projects: {
    sent: number,
    received: number,
    intent: number,
    bidRate: number,
    answerRate: number,
    name: string,
  }[],
  areas: {
    sent: number,
    received: number,
    intent: number,
    bidRate: number,
    answerRate: number,
    averageSuppliersAsked: number,
    name: string,
  }[]
}

export type TemplateOptionCollectionType = {[id: string]: TemplateOptionType};

const getDataFromArrayForSummaryBarList = (array: any[], label: string, name = 'name') => {
  return [...array].filter(x => x[label] !== undefined).sort((a, b) => b[label] - a[label]).map(x => ({ name: x[name], value: x[label] }));
}

const translateProperties = (array: any[], translationObject: TranslationType[]) => {
  return array.map(element => {
    const keysToReplace = Object.keys(element).filter(x => translationObject.find(y => y === x));
    const keysToKeep = Object.keys(element).filter(x => !translationObject.find(y => y === x));

    const result: any = {};

    keysToKeep.forEach(x => {
      result[x] = element[x]
    });

    keysToReplace.forEach(x => {
      const propertyToTranslate = translationObject.find(y => y === x);
      if (propertyToTranslate) {
        result[translation[propertyToTranslate]] = element[x] !== undefined ? element[x] : undefined;
      }
    });
    
    return result;
  }).filter(x => translationObject.map(y => x[translation[y]]).every(z => z !== undefined))
}

export type TEMPLATE_OPTION_IDS = '1' | '1a' | '2' | '2a' | '4' | '5' | '5a' | '6' | '6a' | '7' | '7a' | '8' | '9' | '9a' | '10' | '10a' | '11' | '11a' | '12' | '12a' | '13' | '13a' | '16' | '17' | '18' | '19' | '21' | '22' | '22a' | '23' | '24' | '26' | '26a' | '27' | '27a' | '28' | '28a' | '29' | '30' | '30a' | '31' | '31a' | '32' | '32a' | '33' | '33a' | '34' | '35';

export const getTemplateItemOptions = (
  activeBidCards: ActiveBidCardsType,
  bidsAndIntent: BidsAndIntent,
  contractAmount: ContractAmountType,
  procurementSavings: ProcurementSavingsType,
  activeUsers: ActiveUsers,
  projects: ProjectsType,
): [TemplateOptionCollectionType, (id: TEMPLATE_OPTION_IDS) => JSX.Element] => {
  const TemplateItemOptions = {
    '1': {
      id: '1',
      name: translation.activePurchasesProject,
      itemType: OverviewItemType.LARGE,
      component: (
        <BarChart
          title={translation.activePurchasesProject}
          data={translateProperties(activeBidCards.projects, ['numOfPurchases'])}
          categories={[translation.numOfPurchases]}
          index="name"
          infoText={translation.activePurchasesProjectTipBar}
        />
      )
    },
    '1a': {
      id: '1a',
      name: translation.activePurchasesProject,
      itemType: OverviewItemType.MEDIUM,
      component: (
        <SummaryBarList
          title={translation.activePurchasesProject}
          keyColumnName={translation.project}
          valueColumName={translation.activePurchases}
          data={getDataFromArrayForSummaryBarList(activeBidCards.projects, 'numOfPurchases')}
          infoText={translation.activePurchasesProjectTipList}
        />
      )
    },
    '2':{
      id: '2',
      name: translation.activePurchasesArea,
      itemType: OverviewItemType.LARGE,
      component: (
        <BarChart
          title={translation.activePurchasesArea}
          data={translateProperties(activeBidCards.areas, ['numOfPurchases'])}
          categories={[translation.numOfPurchases]}
          index="name"
          infoText={translation.activePurchasesAreaTipBar}
        />
      )
    },
    '2a': {
      id: '2a',
      name: translation.activePurchasesArea,
      itemType: OverviewItemType.MEDIUM,
      component: (
        <SummaryBarList
          title={translation.activePurchasesArea}
          keyColumnName={translation.area}
          valueColumName={translation.activePurchases}
          data={getDataFromArrayForSummaryBarList(activeBidCards.areas, 'numOfPurchases')}
          infoText={translation.activePurchasesAreaTipList}
        />
      )
    },
    '23': {
      id: '23',
      name: translation.openProjects,
      itemType: OverviewItemType.SMALL,
      component: (
        <SummaryCard
          title={translation.openProjects}
          value={projects.open}
          infoText={translation.openProjectsTip}
        />
      )
    },
    '24': {
      id: '24',
      name: translation.closedProjects,
      itemType: OverviewItemType.SMALL,
      component: (
        <SummaryCard
          title={translation.closedProjects}
          value={projects.closed}
          infoText={translation.closedProjectsTip}
        />
      )
    },
    '4': {
      id: '4',
      name: translation.activePurchases,
      itemType: OverviewItemType.SMALL,
      component: (
        <SummaryCard
          title={translation.activePurchases}
          value={activeBidCards.total.numOfCards}
          infoText={translation.activePurchasesTip}
        />
      )
    },
    '5': {
      id: '5',
      name: translation.averageSuppliersArea,
      itemType: OverviewItemType.LARGE,
      component: (
        <BarChart
          title={translation.averageSuppliersArea}
          data={translateProperties(bidsAndIntent.areas, ['averageSuppliersAsked'])}
          categories={[translation.averageSuppliersAsked]}
          index="name"
          infoText={translation.averageSuppliersAreaTipList}
        />
      )
    },
    '5a': {
      id: '5a',
      name: translation.averageSuppliersArea,
      itemType: OverviewItemType.MEDIUM,
      component: (
        <SummaryBarList
          title={translation.averageSuppliersArea}
          keyColumnName={translation.area}
          valueColumName={translation.suppliersAsked}
          data={getDataFromArrayForSummaryBarList(bidsAndIntent.areas, 'averageSuppliersAsked')}
          infoText={translation.averageSuppliersAreaTipList}
        />
      )
    },
    '6': {
      id: '6',
      name: translation.budgetArea,
      itemType: OverviewItemType.LARGE,
      component: (
        <BarChart
          title={translation.budgetArea}
          data={translateProperties(activeBidCards.areas.filter(area => area?.budget), ['budget'])}
          categories={[translation.budget]}
          index="name"
          unit={UNIT.AMOUNT}
          infoText={translation.budgetAreaTipBar}
        />
      )
    },
    '6a': {
      id: '6a',
      name: translation.budgetArea,
      itemType: OverviewItemType.MEDIUM,
      component: (
        <SummaryBarList
          title={translation.budgetArea}
          keyColumnName={translation.area}
          valueColumName={translation.budget}
          unit={UNIT.AMOUNT}
          data={getDataFromArrayForSummaryBarList(activeBidCards.areas.filter(area => area?.budget), 'budget')}
          infoText={translation.budgetAreaTipBar}
        />
      )
    },
    '26': {
      id: '26',
      name: translation.budgetProject,
      itemType: OverviewItemType.LARGE,
      component: (
        <BarChart
          title={translation.budgetProject}
          data={translateProperties(activeBidCards.projects.filter(project => project?.budget), ['budget'])}
          categories={[translation.budget]}
          index="name"
          unit={UNIT.AMOUNT}
          infoText={translation.budgetProjectTipBar}
        />
      )
    },
    '26a': {
      id: '26a',
      name: translation.budgetProject,
      itemType: OverviewItemType.MEDIUM,
      component: (
        <SummaryBarList
          title={translation.budgetProject}
          keyColumnName={translation.project}
          valueColumName={translation.budget}
          unit={UNIT.AMOUNT}
          data={getDataFromArrayForSummaryBarList(activeBidCards.projects.filter(project => project?.budget), 'budget')}
          infoText={translation.budgetProjectTipList}
        />
      )
    },
    '27': {
      id: '27',
      name: translation.bidRateProject,
      itemType: OverviewItemType.LARGE,
      component: (
        <BarChart
          title={translation.bidRateProject}
          data={translateProperties(bidsAndIntent.projects.filter(project => project?.bidRate), ['bidRate'])}
          categories={[translation.bidRate]}
          index="name"
          unit={UNIT.PERCENTAGE}
          infoText={translation.bidRateProjectTipBar}
        />
      )
    },
    '27a': {
      id: '27a',
      name: translation.bidRateProject,
      itemType: OverviewItemType.MEDIUM,
      component: (
        <SummaryBarList
          title={translation.bidRateProject}
          keyColumnName={translation.project}
          valueColumName={translation.bidRate}
          unit={UNIT.PERCENTAGE}
          data={getDataFromArrayForSummaryBarList(bidsAndIntent.projects.filter(project => project?.bidRate), 'bidRate')}
          infoText={translation.bidRateProjectTipList}
        />
      )
    },
    '7': {
      id: '7',
      name: translation.bidRateArea,
      itemType: OverviewItemType.LARGE,
      component: (
        <BarChart
          title={translation.bidRateArea}
          data={translateProperties(bidsAndIntent.areas.filter(area => area?.bidRate), ['bidRate'])}
          categories={[translation.bidRate]}
          index="name"
          unit={UNIT.PERCENTAGE}
          infoText={translation.bidRateAreaTipList}
        />
      )
    },
    '7a': {
      id: '7a',
      name: translation.bidRateArea,
      itemType: OverviewItemType.MEDIUM,
      component: (
        <SummaryBarList
          title={translation.bidRateArea}
          keyColumnName={translation.area}
          valueColumName={translation.bidRate}
          unit={UNIT.PERCENTAGE}
          data={getDataFromArrayForSummaryBarList(bidsAndIntent.areas.filter(area => area?.bidRate), 'bidRate')}
          infoText={translation.bidRateAreaTipList}
        />
      )
    },
    '29': {
      id: '29',
      name: translation.quotationsBidsPerProject,
      itemType: OverviewItemType.LARGE,
      component: (
        <BarChart
          title={translation.quotationsBidsPerProject}
          data={bidsAndIntent.areas.filter(area => area?.['sent'] || area?.['received']).map(area => ({ [translation['received']]: area.received, [translation['sent']]: area.sent, name: area.name }))}
          categories={[translation['sent'], translation['received']]}
          index="name"
          colors={["orange", "cyan"]}
          infoText={translation.quotationsBidsPerProjectTipBar}
        />
      )
    },
    '8': {
      id: '8',
      name: translation.quotationsBidsPerArea,
      itemType: OverviewItemType.LARGE,
      component: (
        <BarChart
          title={translation.quotationsBidsPerArea}
          data={bidsAndIntent.areas.filter(area => area?.['sent'] || area?.['received']).map(area => ({ [translation['received']]: area.received, [translation['sent']]: area.sent, name: area.name }))}
          categories={[translation['sent'], translation['received']]}
          index="name"
          colors={["orange", "cyan"]}
          infoText={translation.quotationsBidsPerAreaTipBar}
        />
      )
    },
    '28': {
      id: '28',
      name: translation.quotationsProject,
      itemType: OverviewItemType.LARGE,
      component: (
        <BarChart
          title={translation.quotationsProject}
          data={translateProperties(bidsAndIntent.projects.filter(project => project?.['sent']), ['sent'])}
          categories={[translation.sent]}
          index="name"
          infoText={translation.quotationsProjectTipBar}
        />
      )
    },
    '28a': {
      id: '28a',
      name: translation.quotationsProject,
      itemType: OverviewItemType.MEDIUM,
      component: (
        <SummaryBarList
          title={translation.quotationsProject}
          keyColumnName={translation.project}
          valueColumName={translation.quotations}
          data={getDataFromArrayForSummaryBarList(bidsAndIntent.projects.filter(project => project?.['sent']), 'sent')}
          infoText={translation.quotationsProjectTipList}
        />
      )
    },
    '30': {
      id: '30',
      name: translation.bidsReceivedProject,
      itemType: OverviewItemType.LARGE,
      component: (
        <BarChart
          title={translation.bidsReceivedProject}
          data={translateProperties(bidsAndIntent.projects.filter(project => project?.['received']), ['received'])}
          categories={[translation.received]}
          index="name"
          colors={['cyan']}
          infoText={translation.bidsReceivedProjectTipBar}
        />
      )
    },
    '30a': {
      id: '30a',
      name: translation.bidsReceivedProject,
      itemType: OverviewItemType.MEDIUM,
      component: (
        <SummaryBarList
          title={translation.bidsReceivedProject}
          keyColumnName={translation.project}
          valueColumName={translation.bidsReceived}
          data={getDataFromArrayForSummaryBarList(bidsAndIntent.projects.filter(project => project?.['received']), 'received')}
          color='cyan'
          infoText={translation.bidsReceivedProjectTipBar}
        />
      )
    },
    '9': {
      id: '9',
      name: translation.quotationsArea,
      itemType: OverviewItemType.LARGE,
      component: (
        <BarChart
          title={translation.quotationsArea}
          data={translateProperties(bidsAndIntent.areas.filter(area => area?.['sent']), ['sent'])}
          categories={[translation.sent]}
          index="name"
          infoText={translation.quotationsAreaTipBar}
        />
      )
    },
    '9a': {
      id: '9a',
      name: translation.quotationsArea,
      itemType: OverviewItemType.MEDIUM,
      component: (
        <SummaryBarList
          title={translation.quotationsArea}
          keyColumnName={translation.area}
          valueColumName={translation.quotations}
          data={getDataFromArrayForSummaryBarList(bidsAndIntent.areas.filter(area => area?.['sent']), 'sent')}
          infoText={translation.quotationsAreaTipList}
        />
      )
    },
    '10': {
      id: '10',
      name: translation.bidsReceivedArea,
      itemType: OverviewItemType.LARGE,
      component: (
        <BarChart
          title={translation.bidsReceivedArea}
          data={translateProperties(bidsAndIntent.areas.filter(area => area?.['received']), ['received'])}
          categories={[translation.received]}
          index="name"
          colors={['cyan']}
          infoText={translation.bidsReceivedAreaTipBar}
        />
      )
    },
    '10a': {
      id: '10a',
      name: translation.bidsReceivedArea,
      itemType: OverviewItemType.MEDIUM,
      component: (
        <SummaryBarList
          title={translation.bidsReceivedArea}
          keyColumnName={translation.area}
          valueColumName={translation.bidsReceived}
          data={getDataFromArrayForSummaryBarList(bidsAndIntent.areas.filter(area => area?.['received']), 'received')}
          color='cyan'
          infoText={translation.bidsReceivedAreaTipList}
        />
      )
    },
    '31': {
      id: '31',
      name: translation.contractProject,
      itemType: OverviewItemType.LARGE,
      component: (
        <BarChart
          title={translation.contractProject}
          data={translateProperties(contractAmount.projects.filter(area => area?.contractAmount), ['contractAmount'])}
          categories={[translation.contractAmount]}
          index="name"
          unit={UNIT.AMOUNT}
          infoText={translation.contractProjectTipBar}
        />
      )
    },
    '31a': {
      id: '31a',
      name: translation.contractProject,
      itemType: OverviewItemType.MEDIUM,
      component: (
        <SummaryBarList
          title={translation.contractProject}
          keyColumnName={translation.project}
          valueColumName={translation.amount}
          unit={UNIT.AMOUNT}
          data={getDataFromArrayForSummaryBarList(contractAmount.projects.filter(area => area?.contractAmount), 'contractAmount')}
          infoText={translation.contractProjectTipList}
        />
      )
    },
    '11': {
      id: '11',
      name: translation.contractArea,
      itemType: OverviewItemType.LARGE,
      component: (
        <BarChart
          title={translation.contractArea}
          data={translateProperties(contractAmount.areas.filter(area => area?.contractAmount), ['contractAmount'])}
          categories={[translation.contractAmount]}
          index="name"
          unit={UNIT.AMOUNT}
          infoText={translation.contractAreaTipBar}
        />
      )
    },
    '11a': {
      id: '11a',
      name: translation.contractArea,
      itemType: OverviewItemType.MEDIUM,
      component: (
        <SummaryBarList
          title={translation.contractArea}
          keyColumnName={translation.area}
          valueColumName={translation.amount}
          unit={UNIT.AMOUNT}
          data={getDataFromArrayForSummaryBarList(contractAmount.areas.filter(area => area?.contractAmount), 'contractAmount')}
          infoText={translation.contractAreaTipList}
        />
      )
    },
    '32': {
      id: '32',
      name: translation.savingsProject,
      itemType: OverviewItemType.LARGE,
      component: (
        <BarChart
          title={translation.savingsProject}
          data={translateProperties(procurementSavings.projects, ['difference'])}
          categories={[translation.difference]}
          index="name"
          unit={UNIT.AMOUNT}
          infoText={translation.savingsProjectTipBar}
        />
      )
    },
    '32a': {
      id: '32a',
      name: translation.savingsProject,
      itemType: OverviewItemType.MEDIUM,
      component: (
        <SummaryBarList
          title={translation.savingsProject}
          keyColumnName={translation.project}
          valueColumName={translation.amount}
          unit={UNIT.AMOUNT}
          data={getDataFromArrayForSummaryBarList(procurementSavings.projects, 'difference')}
          infoText={translation.savingsProjectTipList}
        />
      )
    },
    '33': {
      id: '33',
      name: translation.savingsProjectPercentage,
      itemType: OverviewItemType.LARGE,
      component: (
        <BarChart
          title={translation.savingsProjectPercentage}
          data={translateProperties(procurementSavings.projects, ['percentageDiff'])}
          categories={[translation.percentageDiff]}
          index="name"
          unit={UNIT.PERCENTAGE}
          infoText={translation.savingsProjectPercentageTipBar}
        />
      )
    },
    '33a': {
      id: '33a',
      name: translation.savingsProjectPercentage,
      itemType: OverviewItemType.MEDIUM,
      component: (
        <SummaryBarList
          title={translation.savingsProjectPercentage}
          keyColumnName={translation.project}
          valueColumName={translation.savings}
          unit={UNIT.PERCENTAGE}
          data={getDataFromArrayForSummaryBarList(procurementSavings.projects, 'percentageDiff')}
          infoText={translation.savingsProjectPercentageTipList}
        />
      )
    },
    '12': {
      id: '12',
      name: translation.savingsArea,
      itemType: OverviewItemType.LARGE,
      component: (
        <BarChart
          title={translation.savingsArea}
          data={translateProperties(procurementSavings.areas, ['difference'])}
          categories={[translation.difference]}
          index="name"
          unit={UNIT.AMOUNT}
          infoText={translation.savingsAreaTipBar}
        />
      )
    },
    '12a': {
      id: '12a',
      name: translation.savingsArea,
      itemType: OverviewItemType.MEDIUM,
      component: (
        <SummaryBarList
          title={translation.savingsArea}
          keyColumnName={translation.area}
          valueColumName={translation.savings}
          unit={UNIT.AMOUNT}
          data={getDataFromArrayForSummaryBarList(procurementSavings.areas, 'difference')}
          infoText={translation.savingsAreaTipList}
        />
      )
    },
    '13': {
      id: '13',
      name: translation.savingsAreaPercentage,
      itemType: OverviewItemType.LARGE,
      component: (
        <BarChart
          title={translation.savingsAreaPercentage}
          data={translateProperties(procurementSavings.areas, ['percentageDiff'])}
          categories={[translation.percentageDiff]}
          index="name"
          unit={UNIT.PERCENTAGE}
          infoText={translation.savingsAreaPercentageTipBar}
        />
      )
    },
    '13a': {
      id: '13a',
      name: translation.savingsAreaPercentage,
      itemType: OverviewItemType.MEDIUM,
      component: (
        <SummaryBarList
          title={translation.savingsAreaPercentage}
          keyColumnName={translation.area}
          valueColumName={translation.savings}
          unit={UNIT.PERCENTAGE}
          data={getDataFromArrayForSummaryBarList(procurementSavings.areas, 'percentageDiff')}
          infoText={translation.savingsAreaPercentageTipList}
        />
      )
    },
    // '14': {
    //   id: '14',
    //   name: 'Total budget',
    //   itemType: OverviewItemType.SMALL,
    //   component: (
    //     <div>
    //       <Card key='Total budget'>
    //         <Text>Total budget</Text>
    //         <Metric>{formatAmount(activeBidCards.total.amount ?? 0) || '0'} SEK</Metric>
    //       </Card>
    //     </div>
    //   )
    // },
    // '15': {
    //   id: '15',
    //   name: 'Intent',
    //   itemType: OverviewItemType.SMALL,
    //   component: (
    //     <SummaryCard
    //       title='Intent'
    //       value={bidsAndIntent.total.intent}
    //       infoText='Total number of submitted intent, positive or negative'
    //     />
    //   )
    // },
    '17': {
      id: '17',
      name: translation.quotationsSent,
      itemType: OverviewItemType.SMALL,
      component: (
        <SummaryCard
          title={translation.quotationsSent}
          value={bidsAndIntent.total.sent}
          infoText={translation.quotationsSentTip}
        />
      )
    },
    '18': {
      id: '18',
      name: translation.bidsReceived,
      itemType: OverviewItemType.SMALL,
      component: (
        <SummaryCard
          title={translation.bidsReceived}
          value={bidsAndIntent.total.received}
          infoText={translation.bidsReceivedTip}
        />
      )
    },
    '16': {
      id: '16',
      name: translation.answerRate,
      itemType: OverviewItemType.SMALL,
      component: (
        <SummaryCard
          title={translation.answerRate}
          value={bidsAndIntent.total.answerRate}
          infoText={translation.answerRateTip}
          unit={UNIT.PERCENTAGE}
        />
      )
    },
    '19': {
      id: '19',
      name: translation.bidRate,
      itemType: OverviewItemType.SMALL,
      component: (
        <SummaryCard
          title={translation.bidRate}
          value={bidsAndIntent.total.bidRate}
          infoText={translation.bidRateTip}
          unit={UNIT.PERCENTAGE}
        />
      )
    },
    // '20': {
    //   id: '20',
    //   name: 'Total budget',
    //   itemType: OverviewItemType.SMALL,
    //   component: (
    //     <div>
    //       <Card key='Total budget'>
    //         <Text>Total contract amount</Text>
    //         <Metric>{formatAmount(contractAmount.total) || '0'} SEK</Metric>
    //       </Card>
    //     </div>
    //   )
    // },
    '21': {
      id: '21',
      name: translation.totalSavings,
      itemType: OverviewItemType.SMALL,
      component: (
        <SummaryCard
          title={translation.totalSavings}
          value={procurementSavings.total.difference}
          infoText={translation.totalSavingsTip}
          unit={UNIT.AMOUNT}
        />
      )
    },
    '35': {
      id: '35',
      name: translation.totalSavingsPercentage,
      itemType: OverviewItemType.SMALL,
      component: (
        <SummaryCard
          title={translation.totalSavingsPercentage}
          value={procurementSavings.total.percentageDiff}
          infoText={translation.totalSavingsPercentageTip}
          unit={UNIT.PERCENTAGE}
        />
      )
    },
    '22': {
      id: '22',
      name: translation.monthlyActiveUser,
      itemType: OverviewItemType.LARGE,
      component: (
        <LineChart
          title={translation.monthlyActiveUser}
          data={translateProperties(activeUsers, ['activeUsers'])}
          categories={[translation.activeUsers]}
          index="month"
        />
      )
    },
    '22a': {
      id: '22a',
      name: translation.monthlyActiveUser,
      itemType: OverviewItemType.MEDIUM,
      component: (
        <SummaryBarList
          title={translation.monthlyActiveUser}
          keyColumnName={translation.users}
          valueColumName={translation.months}
          data={getDataFromArrayForSummaryBarList(activeUsers, 'activeUsers', 'month')}
        />
      )
    },
    '34': {
      id: '34',
      name: translation.quotationResponsiveness,
      itemType: OverviewItemType.MEDIUM,
      component: (
        <PieChart
          title={translation.quotationResponsiveness}
          infoText={translation.quotationResponsivenessTip}
          categories={[translation.Bids, translation.yesNoBid, translation.No, translation.noAnswer]}
          data={[
            {name: translation.Bids, value: bidsAndIntent.total.responsiveness.bid},
            {name: translation.yesNoBid, value: bidsAndIntent.total.responsiveness.yesNoBid},
            {name: translation.No, value: bidsAndIntent.total.responsiveness.no},
            {name: translation.noAnswer, value: bidsAndIntent.total.responsiveness.noAnswer},
          ]}
        />
      )
    }
  };

  const templateItemRenderer = (id: keyof typeof TemplateItemOptions): JSX.Element => TemplateItemOptions[id].component;

  return [
    TemplateItemOptions,
    templateItemRenderer,
  ];
}