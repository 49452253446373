import { FC } from "react"
import styles from "./ProjectTab.module.scss";
import { TEMPLATE_OPTION_IDS } from "../../helper";

type Props = {
  templateItemRenderer: (id: TEMPLATE_OPTION_IDS) => JSX.Element,
}

const ProjectTab: FC<Props> = ({ templateItemRenderer, }) => {
  return (
      <div className={styles.tabContainer}>
        <div className={styles.container}>
          {templateItemRenderer('23')}
          {templateItemRenderer('24')}
        </div>
        <div className={styles.chartContainer}>
          {templateItemRenderer('1')}
          {templateItemRenderer('1a')}
          {templateItemRenderer('26')}
          {templateItemRenderer('26a')}
          {templateItemRenderer('27')}
          {templateItemRenderer('27a')}
          {templateItemRenderer('28')}
          {templateItemRenderer('28a')}
          {templateItemRenderer('29')}
          <div></div>
          {templateItemRenderer('30')}
          {templateItemRenderer('30a')}
          {templateItemRenderer('31')}
          {templateItemRenderer('31a')}
          {templateItemRenderer('32')}
          {templateItemRenderer('32a')}
          {templateItemRenderer('33')}
          {templateItemRenderer('33a')}
        </div>
      </div>
  )
}

export default ProjectTab;