
import AppLayout from '../../components/layout/app-layout/app-layout';
import Tabs from '../../components/shared/Tabs';
import {useState} from 'react';
import styles from '../page-help/page-help.module.scss';
import appState from '../../state/AppStateContainer';

const CampaignArchiveForPurchase = () => {
  const srcDoc=`
  <style type="text/css">
    <!--
    .display_archive {font-family: arial,verdana; font-size: 16px;}
    .campaign {line-height: 125%; margin: 5px;}
    //-->
  </style>
  <script language="javascript" src="//accurator.us5.list-manage.com/generate-js/?u=0f29c0eb3cd43b10a9c4fec49&fid=64645&show=10" type="text/javascript"></script>`;
  return <iframe title={"archive"} srcDoc={srcDoc} style={{ borderWidth: 0, width: "100%", minHeight: "400px" }}></iframe>
}

const CampaignArchiveForSales = () => {
  const srcDoc=`
  <style type="text/css">
    <!--
    .display_archive {font-family: arial,verdana; font-size: 16px;}
    .campaign {line-height: 125%; margin: 5px;}
    //-->
  </style>
  <script language="javascript" src="//accurator.us5.list-manage.com/generate-js/?u=0f29c0eb3cd43b10a9c4fec49&fid=64756&show=10" type="text/javascript"></script>`;
  return <iframe title={"archive"} srcDoc={srcDoc} style={{ borderWidth: 0, width: "100%", minHeight: "400px" }}></iframe>
}


const PageUpdates = () => {
  const [tab, setTab] = useState(0);

  const tabs = [];
  if (appState.isPurchasingActive()) tabs.push("För inköpare");
  if (appState.isSalesActive()) tabs.push("För leverantörer");

  return <AppLayout title="Uppdateringar i Accurator">
  <div className={styles.pageHelp}>
  <Tabs tabs={tabs} selected={tab} onChange={setTab}></Tabs>
  <div className={styles.container}>
    {tabs[tab] == "För inköpare" && <>
      <p>Här hittar du våra 10 senaste nyhetsbrev om förändringar i Accurator.</p>
      <CampaignArchiveForPurchase />
    </>}
    {tabs[tab] == "För leverantörer" && <>
      <p>Här hittar du våra 10 senaste nyhetsbrev om förändringar i Accurator.</p>
      <CampaignArchiveForSales />
    </>}
  </div>
  </div>
  </AppLayout>
}

export default PageUpdates;