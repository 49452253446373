import React from "react";
import DatePicker from "react-datepicker";
import { ServiceSupplier } from "../../services/service-supplier";
import { getUID } from "../../global/app";
import commonHelper from "../../util/commonHelper";
import { ServiceQuotation } from "../../services/service-quotation";
import "./app-chat-mail-batch.css";
import "../app-select-industry-types/app-select-industry-types.css";
import BTCheck from "../bt-check/bt-check";
import { btSplitColumns, btHasObject } from "../../utils/bt-array";
import { NotificationManager } from "react-notifications";
import { ServiceCheckExtension } from "../../services/service-check-file-extension";
import appState from "../../state/AppStateContainer";
import Loading from '../../components/shared/Loading';

import _ from "lodash";
import { injectIntl } from "react-intl";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import 'moment/locale/sv.js';

import { ServiceUser } from "../../services/service-user";
import LeftIcon from '../../assets/images/left.png';
import RightIcon from '../../assets/images/right.png';
import TrashIcon from '../../assets/svg/trash.svg';
import HTMLParser from "../../components/shared/HTMLParser/HTMLParser";
import { manipulateRawBlocks } from "../../util";
import BtGrid from "../bt-grid/bt-grid";
import BtRow from "../bt-row/bt-row";
import BtColumn from "../bt-column/bt-column";

//! Do not delete this file. The css files that are imported are important as some styles are used in the rest of the application.
//! We can not move the import statements because it will mess with the css order and some styles might get overwritten
export class AppChatMailBatch extends React.Component {
}

export default withRouter(injectIntl(AppChatMailBatch));
