import React from "react";
import "./app-chat-start.css";

export class AppChatStart extends React.Component {
  render() {
    return <div />;
  }
}
// <div className="column-content app-chat-start">
// <p>
//   {" "}
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     width="9"
//     height="14"
//     viewBox="0 0 9 14"
//   >
//     <path
//       fill="#FA6400"
//       fillRule="evenodd"
//       d="M3.28206469,7 L7.93269901,11.6506343 C8.355767,12.0737023 8.355767,12.759631 7.93269901,13.182699 C7.50963103,13.605767 6.8237023,13.605767 6.40063432,13.182699 L0.983967654,7.76603235 C0.560899671,7.34296436 0.560899671,6.65703564 0.983967654,6.23396765 L6.40063432,0.817300987 C6.8237023,0.394233004 7.50963103,0.394233004 7.93269901,0.817300987 C8.355767,1.24036897 8.355767,1.9262977 7.93269901,2.34936568 L3.28206469,7 Z"
//     />
//   </svg>{" "}
//   Klicka på en kollega för att påbörja en konversation
// </p>
// <div class="bg fadein">
//   <img src="/assets/images/intern.png" />
// </div>
// </div>
