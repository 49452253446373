import { CSSProperties, FC } from "react";
import TooltipContainer from "../TooltipContainer/TooltipContainer";
import { ReactComponent as ErrorIcon } from '../../../assets/svg/errorIcon.svg';
import styles from './ErrorMessageIcon.module.scss';
import WarningIcon from "../../svgs/warningIcon";

type Props = {
  errorMessage?: string,
  style?: CSSProperties,
  errorMessageStyles?: CSSProperties,
  isWarning?: boolean,
}
export const ErrorMessageIcon: FC<Props> = ({ style, errorMessageStyles, errorMessage, isWarning }) => {
  const icon = (className: string, ref: any) => isWarning ? <div style={{...style, marginBottom: '-3px'}} className={className} ref={ref}><WarningIcon color="var(--yellow-500)" size="24px" /></div> : <ErrorIcon fill={'var(--red-500)'} style={style} className={className} ref={ref} />;
  return (
    errorMessage ? (
      <TooltipContainer renderReferenceComponent={icon} className={styles.errorTooltip}>
        <span style={{ fontSize: '14px', letterSpacing: 'initial', ...errorMessageStyles }}>{errorMessage}</span>
      </TooltipContainer>
    ) : (
      null
    )
  )
}