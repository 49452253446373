import axios from "axios";
import { SlackChannels } from "../util/appEnum";

export const logInsuranceClick = async () => {
  const res = await axios.post('/api/logs/insurance')
  return res.data();
}

export const logMessage = async (message: string, channel: SlackChannels) => {
  const res = await axios.post('/api/logs/message', { message, channel })
  return res.data();
}