/**
* A helper class provides functionality of registering, un-registering validators and performing validation.
*/
export default class ValidationContext {

  //variable declarations

  _validators = []

  registerValidator(validator) {
    if (!validator) {
      return
    }

    if (this._validators.indexOf(validator) <= -1) {
      this._validators.push(validator)
    }
  }

  unRegisterValidator(validator) {
    if (!validator) {
      return
    }

    let index = this._validators.indexOf(validator)
    if (index > -1) {
      this._validators.splice(index, 1)
    }
  }

  validate() {
    let isValid = true
    for (let v of this._validators) {
      if (!v.validate()) {
        isValid = false

        //break
      }
    }

    return isValid
  }

  reset() {
    for (let v of this._validators) {
      v.reset()
    }
  }


}
