import axios from "axios";
import fileDownload from "js-file-download";
import BaseService from "./baseService";

class ServiceQuotationController extends BaseService {
  async getDemands() {
    const response = await axios.get("/api/demands");
    return response.data;
  }

  async sendQuotation(projectSlug, areaSlug, data) {
    // Getting data ready
    const formData = new FormData();
    for (let key in data) {
      if (key === "newFiles") {
        formData.append("newFileSizes", JSON.stringify(Object.values(data.newFiles).map(f => ({
          key: f.id,
          name: f.fileObj.name, 
          size: f.fileObj.size,
        })))); // This is to compare uploaded files against
        for (const fileKey in data.newFiles) {
          formData.append(fileKey, data.newFiles[fileKey].fileObj);          
        }
      } else {
        if (data[key] !== undefined && data[key] !== null)
          formData.append(key, data[key]);
      }
    }
    const response = await axios.post(
      `/api/project/${projectSlug}/${areaSlug}/quotation`,
      formData
    );
    return response.data;
  }

  async getQuotation(projectSlug, areaSlug, version) {
    let url = `/api/project/${projectSlug}/${areaSlug}/quotation`;
    if (version != null) url += "?version=" + version;

    const response = await axios.get(url);
    return response.data;
  }

  async updateQuotationIsSeen(payload) {
    const response = await axios.post(`/api/quotation/seen`,payload);
    return response.data;
  }

  async getProjectQuotationByEncryptedId(id){
    const response = await axios.get(`/api/quotation/${id}`);
    return response.data;
  }

  async saveProjectQuotationInfo(data) {
    // try {
    const formData = new FormData();
    for (let key in data) {
      if (key === "newFiles") {
        for (const fileKey in data.newFiles) {
          formData.append(fileKey, data.newFiles[fileKey].fileObj);
        }
      } else if (key === "supplierPriceDetails") {
        formData.append(key, JSON.stringify(data.supplierPriceDetails));
      } else if (key === "demands") {
        formData.append(key, JSON.stringify(data.demands));
      } else {
        formData.append(key, data[key]);
      }
    }
    
    const response = await axios.post("/api/quotation/bid", formData);
    return response.data;
    // } catch (error) {
    //   console.error("error", error);
    // }
  }

  async saveSalesProjectQuotationBid(data) {

    const formData = new FormData();
    for (let key in data) {
      if (key === "newFiles") {
        for (const fileKey in data.newFiles) {
          formData.append(fileKey, data.newFiles[fileKey].fileObj);
        }
      } else if (key === "supplierPriceDetails") {
        formData.append(key, JSON.stringify(data.supplierPriceDetails));
      } else if (key === "demands") {
        formData.append(key, JSON.stringify(data.demands));
      } else {
        formData.append(key, data[key]);
      }
    }
    
    const response = await axios.post("/api/sales/quotation/bid", formData);
    return response.data;
  }

  async addDemand(data) {
    const response = await axios.post("/api/demand", data);
    return response.data;
  }

  async getPriceTypes() {
    const response = await axios.get("/api/priceTypes");
    return response.data;
  }

  async getPriceUnits() {
    const response = await axios.get("/api/priceUnits");
    return response.data;
  }

  async addPriceType(data) {
    const response = await axios.post("/api/pricetype", data);
    return response.data;
  }

  async getProjectQuotation(projectSlug, areaSlug, supplierId, versionNumber) {
    const response = await axios.get(`/api/projects/${projectSlug}/areas/${areaSlug}/suppliers/${supplierId}/quotation?versionNumber=${versionNumber}`);
    return response.data;
  }
  async getUniqueQuotationSuppliers(projectSlug, areaSlug) {
    const response = await axios.get(`/api/projects/${projectSlug}/areas/${areaSlug}/uniqueQuotationSuppliers`);
    return response.data;
  }
  async updateBidIntent(projectSlug, areaSlug, supplierId, versionNumber, intentStatus, supplierMemberId) {
    const response = await axios.put(`/api/projects/${projectSlug}/areas/${areaSlug}/suppliers/${supplierId}/versionNumber/${versionNumber}/bidIntent`, {...intentStatus, supplierMemberId});
    return response.data;
  }

  async shareQuotation(data) {
    const response = await axios.post(`/api/quotation/share`, data);
    return response.data;
  }

  async fetchAllReceived(page, isExpired, hasBid) {
    const response = await axios.get(`/api/sales/quotation/received`, { params: { page, isExpired, hasBid }});
    return response.data;
  }
  async fetchAllPublicQuotations(page,) {
    const response = await axios.get(`/api/sales/quotation/public`, { params: { page, }});
    return response.data;
  }
  async fetchPublicQuotation({projectId, areaId, orgId}) {
    const response = await axios.get(`/api/sales/orgs/${orgId}/projects/${projectId}/areas/${areaId}/quotation`);
    return response.data;
  }
  async getProjectQuotationForSalesOrg(quotationId) {
    const response = await axios.get(`/api/sales/quotation/${quotationId}`);
    return response.data;
  }
  async createContactForSalesOrgUserToOpenQuotation(quotationId) {
    const response = await axios.post(`/api/sales/quotation/${quotationId}/contacts`);
    return response.data;
  }

  getDownloadQuotationFilesInZipURL({
    projectSlug,
    areaSlug,
    supplierId,
    versionNumber,
    orgId,
  }) {
    return `/api/projects/${projectSlug}/areas/${areaSlug}/suppliers/${supplierId}/versionNumber/${versionNumber}/orgs/${orgId}/zip-file`
  }

  getDownloadQuotationResponseFilesInZipURL({
    projectSlug,
    areaSlug,
    supplierId,
    versionNumber,
    orgId,
  }) {
    return `/api/projects/${projectSlug}/areas/${areaSlug}/suppliers/${supplierId}/versionNumber/${versionNumber}/orgs/${orgId}/bid-response-zip-file`
  }

  async fetchSupplierState(organizationNumber) {
    const response = await axios.get(`/api/sales/supplier-state/${organizationNumber}`);
    return response.data;
  }
}
export const ServiceQuotation = new ServiceQuotationController();
