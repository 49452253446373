export class CommonHelper {
  ellipseString(str = "", length = 17) {
    return str.length > length ? `${str.substring(0, length)}...` : str;
  }

  strEqualIgnoreCase(a, b) {
    return typeof a === "string" && typeof b === "string"
      ? a.localeCompare(b, undefined, { sensitivity: "accent" }) === 0
      : a === b;
  }

  toTimestamp(strDate) {
    if (strDate) {
      return new Date(strDate).getTime();
    }

    return strDate;
  }

  getQueryStringArray() {
    let location = window.location;
    let qsObject = {};

    if (location && location.search) {
      let search = location.search;

      if (search[0] == "?") search = search.substr(1);

      let arr1 = search.split("&");

      for (let i = 0; i < arr1.length; i++) {
        let arr2 = arr1[i].split("=");
        if (arr2.length >= 2) qsObject[arr2[0]] = arr2[1];
      }
    }

    return qsObject;
  }
}

export default new CommonHelper();
