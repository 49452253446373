import axios from "axios";
import BaseService from "./baseService";

class ServiceDashboardClass extends BaseService {
  getProjects = async () => {
    const response = await axios.get('/api/dashboard/open-closed-projects');

    return response.data;
  }

  getBidsAndIntent = async () => {
    const response = await axios.get('/api/dashboard/bids-intent');

    return response.data;
  }
  getActiveUsers = async () => {
    const response = await axios.get('/api/dashboard/active-users');

    return response.data;
  }
  getActiveBidCards = async () => {
    const response = await axios.get('/api/dashboard/active-bid-cards');

    return response.data;
  }
  getContractAmount = async () => {
    const response = await axios.get('/api/dashboard/contract-amount');

    return response.data;
  }
  getProcurementSavings = async () => {
    const response = await axios.get('/api/dashboard/procurement-savings');

    return response.data;
  }
  getRssFeed = async () => {
    const response = await axios.get('/api/dashboard/rss');

    return response.data;
  }

  async getFeaturedSuppliers() {
    const response = await axios.get(`/api/suppliers/featured-suppliers`);
    return response.data;
  }

  async getFeaturedSupplier(supplierId) {
    const response = await axios.get(`/api/suppliers/featured-supplier/${supplierId}`);
    return response.data;
  }

  async logClickThrough(supplierId) {
    const response = await axios.post(`/api/dashboard/featured-supplier/${supplierId}/click-through-log`);
    return response.data;
  }

  async logRegistryLookup(supplierId) {
    const response = await axios.post(`/api/dashboard/featured-supplier/${supplierId}/registry-lookup-log`);
    return response.data;
  }

  async logRegistryAddition(supplierId) {
    const response = await axios.post(`/api/dashboard/featured-supplier/${supplierId}/registry-addition-log`);
    return response.data;
  }
}

export const ServiceDashboard = new ServiceDashboardClass();
