import React from "react";
import "./page-internal-chat-member.css";
import { serviceProfile } from "../../services/service-profile";
import BtButton from "../bt-button/bt-button";
import appState from "../../state/AppStateContainer";
import appEnum from "../../util/appEnum";
import CommonHelper from "../../util/commonHelper";
import _ from "lodash";
import app from "../../global/app";

//! Do not delete this file. The css files that are imported are important as some styles are used in the rest of the application.
//! We can not move the import statements because it will mess with the css order and some styles might get overwritten
export default class PageInternalChatMember extends React.Component {
}
