import BtColumn from '../../../ts-components/bt-column/bt-column';
import BtGrid from '../../../ts-components/bt-grid/bt-grid';
import BtRow from '../../../ts-components/bt-row/bt-row';
import LeftMenuBar from '../../shared/left-menu-bar/leftMenuBar';
import appState from '../../../state/AppStateContainer';
import TopMenu from '../../shared/top-menu/top-menu';

type Props = {
  title: React.ReactNode;
  children?: React.ReactNode;
  hideNavbar?: boolean;
};

const AppLayout: React.VFC<Props> = ({ children, title, hideNavbar = false }) => {
  const isSupplier = appState.isSupplierRole();
  return (
    <BtGrid className="fullHeightView">
      <BtRow>
        <BtColumn className="bt-contract">
          {hideNavbar ? null : <LeftMenuBar />}
        </BtColumn>
        <BtColumn className="scrollable-content">
          <BtRow className="bt-contract">{!isSupplier && <TopMenu title={title} />}</BtRow>
          <BtRow>
            <BtColumn>{children}</BtColumn>
          </BtRow>
        </BtColumn>
      </BtRow>
    </BtGrid>
  );
};

export default AppLayout;
