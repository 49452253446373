
import AppLayout from '../../components/layout/app-layout/app-layout';
import Tabs from '../../components/shared/Tabs';
import {useState} from 'react';
import styles from './page-help.module.scss';

const PageHelp = () => {
  const [tab, setTab] = useState(0);
  return <AppLayout title="Instruktionsfilmer">
  <div className={styles.pageHelp}>
  <Tabs tabs={["Kom igång med Accurator", "Vanliga frågor"]} selected={tab} onChange={setTab}></Tabs>
  <div className={styles.container}>
    {tab == 0 && <>
      <h3>Kom igång med Accurator</h3>
      <p>Filmerna i denna samling går igenom inköpsprocessen i Accurator. Använd menyn i spelaren för att välja film.</p>

      <div style={{padding:"56.25% 0 0 0",position:"relative"}}><iframe src='https://vimeo.com/showcase/10064665/embed' allowFullScreen frameBorder='0' style={{position:"absolute",top:0,left:0,width:"100%",height:"100%"}}></iframe></div>
    </>}
    {tab == 1 && <>
      <h3>Accurator FAQ</h3>
      <p>Filmerna i denna samling visar hur man löser vanliga frågor som dyker upp i Accurator. Använd menyn i spelaren för att välja film.</p>
      <div style={{padding:"56.25% 0 0 0",position:"relative"}}><iframe src='https://vimeo.com/showcase/10067308/embed' allowFullScreen frameBorder='0' style={{position:"absolute",top:0,left:0,width:"100%",height:"100%"}}></iframe></div>
    </>}
  </div>
  </div>
  </AppLayout>
}

export default PageHelp;