export function btOnlyNumbers(string: string, allowDots: boolean = false, fixed: number = -1) {
  var num;
  if (allowDots) {
    num = +string.replace(/(?!-)[^0-9.]/g, '');
  } else {
    num = +string.replace(/\D/g, '');
  }

  if (fixed > -1) {
    var tt = num + '';
    if (tt.indexOf('.') != -1) {
      num = num.toFixed(fixed);
    }
  }

  return num;
}

export function btRemoveNumbers(str) {
  return str.replace(/[0-9]/g, '');
}

export function btFormatNumber(num, fixed: number = -1) {
  if (fixed > -1) {
    var tt = num + '';
    if (tt.indexOf('.') != -1) {
      num = +num;
      num = num.toFixed(fixed);
      tt = num + '';

      if (tt.indexOf('.00') >= 0) {
        num = +num;
      }
    }
  }

  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
}

export const checkOrgNo = orgNo => {
  return /^\d{6}-\d{4}$/.test(orgNo);
};

export const isValidOrganizationNumber = num => {
  const check1 = /^[0-9]*$/.test(num.slice(0, 6));
  const hasDash = num.slice(6, 7) == '-';
  const check2 = num.slice(7) != '' ? /^[0-9]*$/.test(num.slice(7)) : true;

  return num.length < 7 ? check1 : check1 && hasDash && check2;
};
