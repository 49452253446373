import { FC, useEffect, useState } from "react";
import NewsCard, { NewsCardProps } from "../../NewsCard/NewsCard";
import { RSSFeedType } from "../../helper";
import styles from "./NewsTab.module.scss";

type Props = {
  rssFeed: RSSFeedType,
}

const NewsTab: FC<Props> = ({ rssFeed }) => {
  const [rssFeedColumns, setRssFeedColumns] = useState<RSSFeedType[]>([]);
  const [numberOfNewsColumns, setNumberOfNewsColumns] = useState(3);

  useEffect(() => {
    const onResize = () => {
      const widthOfContainer = window.innerWidth - 256 - 60; // 256 => width of left menu bar, 60 => 30 margin on each side of main content
      const marginsBetweenColumns = (Math.floor(widthOfContainer/250) - 1)*16;
      setNumberOfNewsColumns(Math.floor(( widthOfContainer - marginsBetweenColumns)/250)); // 250 => width of news card
    }
    onResize();
    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, []);

  useEffect(() => {
    const columns = rssFeed.reduce((total: RSSFeedType[], curr: NewsCardProps, index: number) => {
      total[index % numberOfNewsColumns].push(curr);
      return total;
    }, Array(numberOfNewsColumns).fill(null).map(_ => []));

    setRssFeedColumns(columns);
  }, [numberOfNewsColumns])
  
  return (
    <div className={styles.container}>
      {rssFeedColumns.map((column, index) => (
        <div key={index}>
          {column.map(feed => <NewsCard key={feed.link} {...feed} />)}
        </div>
      ))}
    </div>
  )
}

export default NewsTab;