import React from 'react';
import AppChangePassword from "../app-password/app-change-password"
import _ from "lodash";
import '../page-login/page-login.css';
import CraneImage from '../../assets/images/crane.jpg'
import BtBackground from '../bt-background/bt-background';
import BtGrid from '../bt-grid/bt-grid';
import BtRow from '../bt-row/bt-row';
import BtColumn from '../bt-column/bt-column';
import BtCenter from '../bt-center/bt-center';

export default class PageChangePassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // changePassword: false
        };
    }
    // componentDidMount() {
    //     console.log(_.get(this, "props.location.state.changePassword"))
    //     this.setState({
    //         changePassword: _.get(this, "props.location.state.changePassword")
    //     })
    // }
    modalForgotPassword;
    showFogotPassword = e => {
        this.modalForgotPassword.show = true;
    }
    render() {
        return (
            <div className="page-login">
                <BtGrid>
                    <BtRow>
                        <BtColumn className="bt-w50 first-column bg-white">
                            <BtCenter>
                                <AppChangePassword className="page-padding" ></AppChangePassword>
                            </BtCenter>
                        </BtColumn>
                        <BtColumn className="second-column">
                            <BtBackground className="bg-login bt-background-position-left" src={CraneImage} />
                        </BtColumn>
                    </BtRow>
                </BtGrid>
            </div>
        );
    }
}
