import React, { forwardRef, ReactNode, useState } from 'react';
import { Autocomplete } from '@mui/material';
import styles from './Field.module.scss';
import Selector, { SelectorType } from '../Selector/Selector';
import { ErrorMessageIcon } from '../ErrorMessageIcon/ErrorMessageIcon';

type Props = {
  value?: string,
  onChange?: (value: string) => void,
  placeholder?: string,
  errorMessage?: string,
  warningMessage?: string,
  isEditMode?: boolean,
  style?: React.CSSProperties,
  className?: string,
  fieldOptions?: SelectorType[],
  selectedField?: SelectorType,
  onFieldChange?: (selectedField: SelectorType) => void,
  multiline?: boolean,
  icon?: ReactNode,
  label?: string,
  required?: boolean,
  disabled?: boolean,
  type?: string,
  autocomplete?: string[],
  onBlur?: React.FocusEventHandler<HTMLInputElement>, 
  unit?: string,
  onFocusStylesEnabled?: boolean,
  onFocusClassName?: string,
  onFocus?: React.FocusEventHandler<HTMLInputElement>,
  onClick?: React.MouseEventHandler<HTMLLabelElement>,
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>,
  id?: string,
}

const Field = forwardRef<HTMLInputElement, Props>(({
  id,
  value,
  onChange,
  placeholder,
  errorMessage,
  warningMessage,
  isEditMode = true,
  style,
  className,
  fieldOptions,
  selectedField,
  onFieldChange,
  multiline,
  icon,
  label,
  required,
  disabled,
  type,
  autocomplete,
  onBlur,
  unit,
  onFocusStylesEnabled,
  onFocusClassName,
  onFocus,
  onClick,
  onKeyDown,
}, ref) => {
  const [selectedValue, _setSelectedValue] = useState(value);
  
  const renderInput = () => {
    const input = (inputProps : any) => <input
      id={id}
      ref={ref}
      style={style}
      value={value}
      onChange={e => onChange?.(e.target.value)}
      placeholder={placeholder}
      //className={styles.field}
      disabled={disabled}
      type={type}
      {...inputProps}
      className={styles.field}
      onFocus={e => {
        if (disabled) return;
        if (inputProps.onFocus) inputProps.onFocus(e);
        if (onFocus) onFocus(e);
      }}
      onBlur={e => {
        if (disabled) return;
        if (inputProps.onBlur) inputProps.onBlur(e);
        if (onBlur) onBlur(e);
      }}
      onKeyDown={e => {
        if (disabled) return;
        if (inputProps.onKeyDown) inputProps.onKeyDown(e);
        if (onKeyDown) onKeyDown(e);
      }}
    />;
    if (autocomplete) {
      return <Autocomplete
        id={id}
        options={autocomplete} 
        freeSolo 
        renderInput={_params => <div ref={_params.InputProps.ref}>{input(_params.inputProps)}</div>}
        inputValue={value}
        onInputChange={(_event, value) => onChange?.(value ?? "")}
        value={selectedValue} // This keeps the filtering working after you press enter
        onChange={(_event, _value) => undefined} // This keeps the filtering working after you press enter
        fullWidth
      />
    } else {
      return input({});
    }
  }

  return (
    <>
      {isEditMode ? (
      <label style={{ width: '100%' }} onClick={onClick}>
        {label && <div className={styles.label}>{label} {required ? <span className={styles.required}>*</span> : ""} </div>}
        <div className={`${styles.container} ${errorMessage ? styles.error : ''} ${multiline ? styles.textarea : ''} ${value ? styles.hasContent : ''} ${onFocusStylesEnabled ? `${styles.onFocusStyles} ${onFocusClassName}` : ''} ${className}`}>
          {selectedField && fieldOptions?.length && onFieldChange && (
            <Selector<SelectorType>
              className={styles.fieldSelector}
              isSearchable={false}
              onChange={onFieldChange}
              value={selectedField}
              options={fieldOptions}
            />
          )}
          {icon && <div className={styles.icon}>{icon}</div>}
          {multiline ? (
            <textarea
              id={id}
              style={style}
              value={value}
              onChange={e => onChange?.(e.target.value)}
              placeholder={placeholder}
              className={`${styles.field} ${styles.textarea}`}
              disabled={disabled}
            />
          ) : (
            renderInput()
          )}
          {unit && (
            <div className={styles.unit}>{unit}</div>
          )}
          {(errorMessage || warningMessage) && <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '4px',
          }}>
            {errorMessage && (
              <ErrorMessageIcon
                style={{ whiteSpace: 'nowrap' }}
                errorMessage={errorMessage}
              />
            )}
            {warningMessage && (
              <ErrorMessageIcon
                style={{ whiteSpace: 'nowrap' }}
                errorMessage={warningMessage}
                isWarning={!!warningMessage}
            />)}
          </div>}
        </div>
      </label>
      ) : (
        <p style={style} className={`${styles.viewOnlyContainer} ${className}`}>
          <span>{value}</span>
          <input id={id} ref={ref} type="hidden" value={value} />
        </p>
      )}
    </>
  )
});

Field.displayName = 'Field';

export default Field;