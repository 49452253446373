import DatePicker from "../../../components/shared/DatePicker/DatePicker";
import appState from "../../../state/AppStateContainer";
import moment from "moment";
import _ from "lodash";
import { FC } from "react";
import styles from "./TableDatePicker.module.scss";
import { autosave } from "../../../util";

type Props = {
  date: Date | undefined,
  onChange: (date: Date) => Promise<void>,
  errorMessage?: string,
  label?: string,
  fieldClassName?: string,
  onFocusStylesEnabled?: boolean,
  warningMessage?: string,
}

const TableDatePicker: FC<Props> = ({ date, onChange, errorMessage, warningMessage, label, fieldClassName, onFocusStylesEnabled = true, }) => {
  const value = date && moment(date);

  return appState.isReadOnlyAccessRole() && !label ? (
    value ? <span style={{ whiteSpace: 'nowrap' }}>{moment(value).format('D MMMM YYYY')}</span> : null
  ) : (
    <div className={styles.dateButton} onClick={(e) => { e.stopPropagation() }}>
      <DatePicker
        label={label}
        selected={value?.toDate()}
        fieldClassName={`${onFocusStylesEnabled ? styles.datePickerOnTable : ''} ${value?.toDate() ? styles.datePickerOnTableStylesWhenContent : ''} ${fieldClassName}`}
        isClearable
        isDisabled={appState.isReadOnlyAccessRole()}
        onFocusStylesEnabled={onFocusStylesEnabled}
        errorMessage={errorMessage}
        warningMessage={warningMessage}
        onChange={async date => {
          autosave(async () => await onChange(date));
        }}
      />
    </div>
  )
}

export default TableDatePicker;