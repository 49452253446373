import appState from '../../../state/AppStateContainer';
import { BidFormContentContainer } from '../StaticComponents';
import { useEffect, useState, VFC } from 'react';
import CollapsibleView from '../CollapsibleView/CollapsibleView';
import { SupplierAccountCard, SupplierStatus } from '../SupplierAccountCard/supplierAccountCard';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'btn-remove-industry': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      'bt-check': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      'bt-row': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      'bt-button': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      'bt-column': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

const sortVersions = (a: VersionData, b: VersionData) => {
  if (parseInt(a.quoteVersionNo) === parseInt(b.quoteVersionNo)) return 0;

  return parseInt(a.quoteVersionNo) > parseInt(b.quoteVersionNo) ? -1 : 1;
}

export type VersionData = {
  quotation: {
    id: number,
    project: any,
    demands: {demandId: {_id: string}}[],
    area: any,
    attachments: any[],
    priceDetails: {
      priceType: {
        name: string,
        _id: string
      },
      price: number
    }[],
    content: string
  },
  demands: {
    demandId: string
  }[],
  supplierBid?: {
    demands: {demandId: string}[],
    attachments: any[],
    message: string | null,
    content: string | null,
    price: number | null,
    priceDetails: {
      priceType: {
        name: string,
        _id: string
      },
      price: number
    }[],
    quotationValidity: string
  },
  supplier: {
    slug: string,
    _id: string,
    name: string
  },
  supplierSlug: string,
  supplierMemberId: string,
  quoteVersionNo: string,
  quoteRefId: string,
  status: {
    expirationDate: string,
    isLocked: boolean,
    reason: string,
    submittedAt: string
  },
  intentStatus: {
    intent: string,
    declaredAt: string,
    declaredBy: string,
    reasonDeclined: string
  },
}

type Props = {
  quotationVersions: VersionData[],
  onSubmit?: () => void,
  isPublic?: boolean,
  supplierStatus?: SupplierStatus,
};
const QuotationForm: VFC<Props> = ({ quotationVersions, onSubmit, isPublic, supplierStatus }) => {
  const [projectData, setProjectData] = useState<{
    name: string,
    number: string,
    orgName: string,
  }>();
  const [supplierData, setSupplierData] = useState<{
    name: string
  }>();
  const [versions, setVersions] = useState<VersionData[]>(quotationVersions.sort(sortVersions));

  useEffect(() => {
    setVersions(quotationVersions.sort(sortVersions))
  }, [quotationVersions.length])
  

  useEffect(() => {
    if (versions?.[0]) {
      const { quotation, supplier } = versions[0];
      setProjectData(quotation.project);
      setSupplierData(supplier);
    }
  }, [versions.length]);

  const renderHeaderInfo = () => projectData && (
    <div style={{ margin: '26px 0px', marginTop: versions[0].supplierMemberId ? '26px' : '0px' }} className="generic-card">
      <div className="info-head-container">
        {renderInfo('Beställare', projectData.orgName ?? appState.getCurrentOrgName())}
        {renderInfo('Projekt', projectData.name)}
        {projectData.number && renderInfo('Projektnummer', projectData.number)}
        {versions?.[0]?.quotation?.area?.name && renderInfo('Yrkeskategori', versions?.[0]?.quotation?.area?.name)}
      </div>
    </div>
  );

  const renderInfo = (title: string, info: string) => (
    <div>
      <div className="title">{title}</div>
      <div className="content">{info}</div>
    </div>
  );

  return (projectData && (isPublic || supplierData) && versions?.length > 0) ? (
    <BidFormContentContainer projectData={projectData} supplierData={supplierData} isSupplierView={versions[0].supplierMemberId}>
      <SupplierAccountCard supplierStatus={supplierStatus} />
      {renderHeaderInfo()}
      {versions?.map((versionData, i) => (
        <CollapsibleView
          openView={i == 0}
          key={versionData?.quotation?.id}
          versionData={versionData}
          onSubmit={onSubmit}
          isPublic={isPublic}
        />
      ))}
    </BidFormContentContainer>
  ) : null;
}

export default QuotationForm;
