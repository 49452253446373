import type { BidIntentStatus } from "../page-quotation/BidIntentControls/BidIntentControls";

export type Request = {
  isDeleted: boolean;
  project: string;
  date: string;
  timeLeft: Date;
  answered: boolean;
  intent: BidIntentStatus;
  org: string;
  won: boolean;
  version: string;
  deliveryStart: string;
  deliveryEnd: string;
  recipients: string[];
}

export type FeaturedAdInfo = {
  errors?: {title?: string, image?: string}
  url?: string,
  image: {
    file: string,
  } | File,
  title: string,
  description?: string,
  startDate: Date,
  endDate: Date,
}

export const Period = {
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  DAY: 'DAY',
} as const;
export type Period = typeof Period[keyof typeof Period]

export type InvoiceInterval = {
  price: number,
  duration: number,
  period: Period,
  cancellationDelay: string,
}

export type AdApplicationStatus = 'PENDING' | 'REJECTED' | 'APPROVED';

export type SalesOrganizationApplicationStatus = 'PENDING' | 'SIGNED' | 'REJECTED';
