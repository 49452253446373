import './Selector.modules.scss';
import Select, { components, createFilter, GroupBase, SingleValue, MultiValue, StylesConfig, OnChangeValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { ReactNode, useCallback} from 'react';
import TooltipContainer from '../TooltipContainer/TooltipContainer';
import { ReactComponent as ErrorIcon } from '../../../assets/svg/errorIcon.svg';

export type SelectorType = {
  label: string,
  value: string,
  __isNew__?: boolean
} | null

export type Props<T extends SelectorType, IsMulti extends boolean> = {
  options: T[],
  value: IsMulti extends true ? MultiValue<T> : SingleValue<T>,
  onChange: (value: OnChangeValue<T, IsMulti>) => void,
  isCreatable?: boolean,
  styles?: StylesConfig<T, IsMulti, GroupBase<T>>,
  isSearchable?: boolean,
  className?: string,
  placeholder?: string,
  mini?: boolean,
  formatCreateLabel?: (value: string) => ReactNode,
  required?: boolean,
  label?: string,
  errorMessage?: string,
  isOptionDisabled?: (x:T) => boolean,
  components?: any,
  isMulti?: IsMulti,
  isClearable?: boolean,
  isDisabled?: boolean,
  isSelected?: (value: T) => boolean,
}

const MultiValueComponent = (props: any) => {
  return (
    <components.MultiValue key={props.data.value.toString()} {...props}>
      <span style={{ fontSize: '14px' }}>{props.data.label}</span>
    </components.MultiValue>
  );
};

const Selector = <T extends SelectorType, IsMulti extends boolean = false>({ options, value, onChange, isCreatable, isDisabled, styles, isSearchable, mini, formatCreateLabel, className, label, required, errorMessage, isMulti, isSelected, ...props } : Props<T, IsMulti>) => {
  const IndicatorContainerWithErrorMessage = useCallback((props: any) => (
      <components.IndicatorsContainer {...props}>
        {errorMessage && (
          <TooltipContainer renderReferenceComponent={(className, ref) => <ErrorIcon style={{ marginRight: '5px' }} className={className} ref={ref} />}>
            <span>{errorMessage}</span>
          </TooltipContainer>
        )}
        {props.children}
      </components.IndicatorsContainer>
  ), [errorMessage]);

  const SelectContainerWithLabel = useCallback((props: any) => (
    <components.SelectContainer {...props}>
      {label && <div className="selector-label">{label} {required ? <span className="selector-label-required">*</span> : ""} </div>}
      {props.children}
    </components.SelectContainer>
  ), [label, required]);

  const OptionWithCheckbox = useCallback((props: any) => {
    return (
    <div className="ar-multiselect-checkboxes">
      <components.Option key={`${props.value}`} {...props}>
        {props.data.__isNew__ ? props.children : (
          <>
            <input type="checkbox" checked={isSelected ? isSelected(props.data) : props.isSelected} readOnly />{' '}
            <label style={{ letterSpacing: 'normal' }}>{props.label} </label>
          </>
        )}
      </components.Option>
    </div>
  )}, [isSelected]);

  const mergedComponents = (() => {
    const defaultMergedComponents: {[x:string]: any} = {
      IndicatorsContainer: IndicatorContainerWithErrorMessage,
      SelectContainer: SelectContainerWithLabel,
      MultiValueComponent,
    };
    if (isMulti) {
      defaultMergedComponents.Option = OptionWithCheckbox;
    }
    return {
      ...defaultMergedComponents,
      ...props.components,
    }
  })();

  const mergedStyles = {
    valueContainer: (provided : any) =>  {
      const customStyles: {[x:string]: string} = {
        ...provided,
      };
      if (isMulti) {
        customStyles.minHeight = '28px';
      } else {
        customStyles.height = isSearchable ? provided.height : '28px';
      }
      return customStyles;
    },
    ...styles,
  };

  const filterOption = isSearchable ? createFilter({
    stringify: option => option.label.toString()
  }) : undefined;

  return (
    <>
      {isCreatable ? (
        <CreatableSelect<T, IsMulti>
          placeholder='Välj'
          {...props}
          isSearchable={isSearchable}
          className={`accSelector-container ${className} ${mini ? 'mini' : ''} ${errorMessage ? "selector-error" : ""}`}
          classNamePrefix='accSelector'
          options={options}
          value={value}
          onChange={onChange}
          styles={mergedStyles}
          formatCreateLabel={formatCreateLabel}
          filterOption={filterOption}
          components={mergedComponents}
          hideSelectedOptions={false}
          backspaceRemovesValue={false}
          closeMenuOnSelect={!isMulti}
          isMulti={isMulti}
          isDisabled={isDisabled}
          noOptionsMessage={() => 'Inga alternativ'}
        />
      ) : (
        <Select<T, IsMulti>
          placeholder='Välj'
          {...props}
          isSearchable={isSearchable}
          className={`accSelector-container ${className} ${mini ? 'mini' : ''} ${errorMessage ? "selector-error" : ""}`}
          classNamePrefix='accSelector'
          options={options}
          value={value}
          onChange={onChange}
          styles={mergedStyles}
          filterOption={filterOption}
          components={mergedComponents}
          hideSelectedOptions={false}
          backspaceRemovesValue={false}
          closeMenuOnSelect={!isMulti}
          isMulti={isMulti}
          isDisabled={isDisabled}
          noOptionsMessage={() => 'Inga alternativ'}
        />
      )}
    </>
  )
};

export default Selector;