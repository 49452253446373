import { FC } from "react";
import styles from './bid-overview.module.scss';
import TooltipContainer from "../../components/shared/TooltipContainer/TooltipContainer";
import HTMLParser from "../../components/shared/HTMLParser/HTMLParser";
import Selector from "../../components/shared/Selector/Selector";
import BtButton from "../bt-button/bt-button";
import _ from "lodash";
import moment from "moment";
import { BidIntentStatus, bidIntentOptions } from "../page-quotation/BidIntentControls/BidIntentControls";
import { MoreTimeIcon } from '../../components/svgs/moreTimeIcon';
import RequestQuoteIcon from '../../components/svgs/requestQuoteIcon';
import { ReactComponent as OpenedEyeIcon } from '../../assets/svg/openedEyeIcon.svg';
import { ReactComponent as CircleQuestionIcon } from '../../assets/svg/circleQuestionIcon.svg';
import { ReactComponent as CircleDollarIcon } from '../../assets/svg/circleDollarIcon.svg';
import { ReactComponent as CircleXIcon } from '../../assets/svg/circleXIcon.svg';
import { ReactComponent as CircleCheckIcon } from '../../assets/svg/circleCheckIcon.svg';
import { ReactComponent as TriangleWarningIcon } from '../../assets/svg/triangleWarningIcon.svg';
import { ReactComponent as MessageIcon } from '../../assets/svg/messageIcon.svg';
import { ReactComponent as DownloadIcon } from '../../assets/svg/download2.svg';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { formatPrice } from '../../util';
import DownloadButton from "../../components/shared/DownloadButton";
import { ServiceQuotation } from "../../services/service-quotation";
import Spinner from "../../components/shared/Spinner/Spinner";

type HasId = {
  _id: string;
}

type HasName = {
  name: string;
}

type HasSlug = {
  slug: string;
}

type HasOrgId = {
  orgId: string;
}

type BidIntent = {
  intent: BidIntentStatus;
  declaredBy: HasName;
  declaredAt: string;
  reasonDeclined: string;
}

type Supplier = {
  supplierId: HasId & HasName;
  bidLink: string;
  isSeen: boolean;
  bidIntentStatus: BidIntent;
  isSubmitted: boolean;
  message: string;
  reservations: string;
  attachments: any[];
}


type Props = RouteComponentProps & {
  modifiedSuppliers: any[];
  versionData: any[];
  showDefaultBidValidityDate: boolean;
  removeDuplicates: (originalArray: any[], prop: string) => any[]; 
  onCalendar: (item: Supplier, v?: any[], version?: {refVersion : number}) => void;
  changeSupplierVersion: (selectedVersion: number, supplierId : HasId) => void;  
  getVersionInState: (supplierId: string) => number;
  calcDaysLeft: (date : Date) => number;
  currentProject: HasSlug & HasName & HasOrgId;
  areaSlug: string;
  areaName: string;
}

const BidOverview : FC<Props> = ({
  modifiedSuppliers, 
  versionData, 
  showDefaultBidValidityDate, 
  removeDuplicates, 
  onCalendar, 
  changeSupplierVersion, 
  getVersionInState,
  calcDaysLeft,
  currentProject,
  areaSlug,
  areaName,
  history,  
}) => {
  const keyOf = (supplier : Supplier, i : number) => supplier.supplierId._id + "copy" + Math.floor(i/originalLength);

  const getPricesForSupplier = (supplierId : string) => {
    const version = getVersionInState(supplierId)
    if (version !== undefined) {

      let quoteVersions = _.head(versionData);
      const supplierBids = quoteVersions.versions[version].supplierBids.find(
        (x: {supplierId: string}) => x.supplierId === supplierId,
      );
      let priceDetails = quoteVersions.versions[version].priceDetails;

      if (supplierBids && supplierBids.priceDetails) {
        const prices = supplierBids.priceDetails.map((bids : { price : string, priceType : string }) => {
          const formattedPrice = formatPrice(bids.price);
          priceDetails = priceDetails.filter((pd : { priceType? : HasId }) => pd.priceType);
          let findPriceDetail = priceDetails.find((details : { priceType : HasId }) => details.priceType._id == bids.priceType);
          const result = {
            name: findPriceDetail && findPriceDetail.priceType && findPriceDetail.priceType.name,
            unit: findPriceDetail?.unit ?? "kr",
            price: formattedPrice ? formattedPrice : '-',
          };

          return result;
        });

        return prices;
      } else {
        return priceDetails.map((p : { priceType: HasName }) => ({ name: p.priceType.name }));
      }
    }
    return [];
  }

  const getDemandsForSupplier = (supplierId: string) => {
    const version = getVersionInState(supplierId)
    if (version !== undefined) {

      let quoteVersions = _.head(versionData);
      const supplierBids = quoteVersions.versions[version].supplierBids.find(
        (x : { supplierId : string }) => x.supplierId === supplierId,
      );
      let demands = quoteVersions.versions[version].demands;
      if (demands && supplierBids?.demands) {
        return demands.map((d : { demandId : HasId & HasName}) => ({name: d.demandId.name, value: !!supplierBids.demands.find((sd : {demandId : string }) => sd.demandId === d.demandId._id)}));
      } else if (demands) {
        return demands.map((d : { demandId : HasId & HasName}) => ({name: d.demandId.name}));
      }
    }
    return [];
  }

  const getQuoteValidity = (validity : Date) => {
    return validity ? moment(validity).format('YYYY-MM-DD') : ""
   }

  const getQuotationVersions = (supplierId: {_id: string}) => {
    if (!supplierId) return []; // was null

    const quotationVersions = [];
    const getVersion = !_.isEmpty(versionData) ? versionData[0].versions : undefined;
    if (!_.isEmpty(getVersion)) {
      for (const qv of getVersion) {
        for (const s of qv.suppliers) {
          if (s.supplierId === supplierId._id) quotationVersions.push(qv);
        }
      }
    }

    return removeDuplicates(quotationVersions, '_id');
  }

  const renderVersionSelector = (supplier: Supplier) => {
    const { supplierId } = supplier;
    const versionInState = getVersionInState(supplierId._id);
    const quotationVersions = getQuotationVersions(supplierId);
    const options = quotationVersions.map(v => ({
      label: `V${v.refVersion + 1}`,
      value: v.refVersion,
    }));
    return options && options.length > 0 && (
      <div className={styles.center}>
        <div style={{width: 125, display:"flex", justifyContent: "flex-end"}}>
          <Selector
            options={options}
            mini
            isSearchable={false}
            value={options.find(x => x.value == versionInState) ?? null}
            onChange={(option) => {
              if (option != null) {
                changeSupplierVersion(option.value, supplierId);
              }
            }}
            styles={{container: (baseStyles) => ({...baseStyles, margin:0})}}
          />
        </div>
        <TooltipContainer
          renderReferenceComponent={(className, ref) =>
            <BtButton
              ref={ref}
              class={className}
              color="white" 
              size="xxs"
              onClick={() => history.push(supplier.bidLink, {
                supplierId: supplier.supplierId._id
              })}          
            >
              <RequestQuoteIcon size={18} color='var(--gray-700)' />
            </BtButton>
          }        
        >
          <span style={{ whiteSpace: 'nowrap' }}>Öppna anbudsformuläret</span>
        </TooltipContainer>
      </div>
    );
  }

  const renderStatusIcons = (supplier: Supplier) => {
    const { supplierId } = supplier;
    const versionInState = getVersionInState(supplierId._id);
    const quotationVersions = getQuotationVersions(supplierId);
    const getVersion = !_.isEmpty(versionData) ? versionData[0].versions : undefined;

    let eyeIcon = supplier?.isSeen;
    // old quotation eye icon fix!
    let isOldQuotation;

    // push all relative versions of the supplier
    if (!_.isEmpty(getVersion)) {
      for (const qv of getVersion) {
        for (const s of qv.suppliers) {
          if (s.supplierId === supplierId._id) quotationVersions.push(qv);
        }
      }

      if (versionInState || versionInState == 0) {
        if (!eyeIcon && isOldQuotation) eyeIcon = supplier?.isSeen;

        isOldQuotation = moment(getVersion[versionInState].quotationDate).isBefore(
          '2020-04-13',
        );
      }
    }

    let bidIntent = supplier?.bidIntentStatus;
    const getBidIntentTitle = () => {
      if (bidIntent?.intent === BidIntentStatus.ACCEPTED) {
        return 'Avser att lämna anbud';
      } else if (bidIntent?.intent === BidIntentStatus.DECLINED) {
        return 'Avser att inte lämna anbud';
      }
      return '';
    };

    return (
      <div style={{display:"flex", justifyContent: "center", gap: "8px"}}>
        {eyeIcon ? (
          <TooltipContainer renderReferenceComponent={(className, ref) => <OpenedEyeIcon className={className} ref={ref} style={{ width: '16px', height: '16px', fill: 'var(--gray-700)' }} />}>
            <span style={{ whiteSpace: 'nowrap' }}>Leverantören har öppnat anbudssidan</span>
          </TooltipContainer>
        ) : (
          <TooltipContainer renderReferenceComponent={(className, ref) => <OpenedEyeIcon className={className} ref={ref} style={{ width: '16px', height: '16px', fill: 'var(--gray-300)' }} />}>
            <span style={{ whiteSpace: 'nowrap' }}>Anbudssidan ej öppnad</span>
          </TooltipContainer>
        )}
        {
          bidIntent?.intent && bidIntent.intent !== BidIntentStatus.UNDECIDED && bidIntentOptions[bidIntent.intent] ? (
            <TooltipContainer className='bidIntentTooltip' renderReferenceComponent={ (className, ref) =>
              ({
                [BidIntentStatus.ACCEPTED]: <CircleCheckIcon className={className} ref={ref} fill="var(--gray-700)" width='16px' height='16px' />,
                [BidIntentStatus.DECLINED]: <CircleXIcon className={className} ref={ref} fill="var(--gray-700)" width='16px' height='16px' />,
              }?.[bidIntent.intent as BidIntentStatus.ACCEPTED | BidIntentStatus.DECLINED] || null)              
            }>
              <div>
                <p>
                  <strong>{getBidIntentTitle()}</strong>
                </p>
                <p>
                  {`${bidIntent?.declaredBy?.name} svarade den ${moment(bidIntent.declaredAt).locale('sv').format('D MMMM YYYY HH:mm')}`}
                </p>
              </div>
              {bidIntent?.reasonDeclined && (
                <div>
                  <p>
                    <strong>Orsak:</strong>
                  </p>
                  <p>
                    {`${bidIntent?.reasonDeclined}`}
                  </p>
                </div>
              )}
            </TooltipContainer>
          ) : (
            <TooltipContainer className='bidIntentTooltip' renderReferenceComponent={(className, ref) => <CircleQuestionIcon className={className} ref={ref} fill="var(--gray-300)" />}>
              <div>
                <p>
                  Ingen avsiktsförklaring
                </p>
              </div>
            </TooltipContainer>
          )
        }
        {supplier.isSubmitted ? (
          <TooltipContainer className='bidIntentTooltip' renderReferenceComponent={(className, ref) => <CircleDollarIcon className={className} ref={ref} style={{color:"var(--gray-700)"}} />}>
              <div>
                <p>
                  Leverantören har lämnat anbud
                </p>
              </div>
          </TooltipContainer>          
        ) : (
          <TooltipContainer className='bidIntentTooltip' renderReferenceComponent={(className, ref) => <CircleDollarIcon className={className} ref={ref} style={{color:"var(--gray-300)"}}/>}>
              <div>
                <p>
                  Inget anbud
                </p>
              </div>
          </TooltipContainer>          
        )}
      </div>
    );
  }

  const renderPriceField = (suppliers : Supplier[], prices : (HasName & { price: string, unit: string })[][], i : number, name : string) => {
    const price = prices[i].find((p : HasName) => p.name === name);
    return <td key={keyOf(suppliers[i], i) + "_price_" + name} className={styles.right}>{price?.price}&nbsp;{price?.unit}</td>
  }

  const renderPriceFieldRows = (suppliers : Supplier[]) => {
    const prices = suppliers.map(supplier => getPricesForSupplier(supplier.supplierId._id));
    const distinctFields : string[] = Array.from(prices.reduce((set, pricesForSupplier) => { pricesForSupplier.map((price : HasName) => set.add(price.name)); return set; }, new Set()));
    return distinctFields.map(name =>
      <tr key={"pricerow_" + name}>
        <td key="name">{name}</td>
        {suppliers.map((_supplier, i) => renderPriceField(suppliers, prices, i, name))}</tr>
    );
  }

  const renderDemand = (suppliers : Supplier[], demands : (HasName & { value: boolean })[][], i : number, name : string) => {
    const demand = demands[i].find((p : HasName) => p.name === name);    
    if (demand && demand.value !== undefined) {
      return <td key={keyOf(suppliers[i], i) + "_demand_" + name}><div className={styles.center}>
        {demand?.value ? (
          <div className="collective-agreementFlag true">
            <CheckIcon />
          </div>
        ) : (
          <div className="collective-agreementFlag false">
            <CloseIcon />
          </div>
        )}</div></td>
    } else {
      return <td key={keyOf(suppliers[i], i) + "_demand_" + name}>&nbsp;</td>;
    }
  }

  const renderDemandRows = (suppliers : Supplier[]) => {
    const demands = suppliers.map(supplier => getDemandsForSupplier(supplier.supplierId._id));
    const distinctFields : string[] = Array.from(demands.reduce((set, demandsForSupplier) => { demandsForSupplier.map((demand : HasName) => set.add(demand.name)); return set; }, new Set()));
    return distinctFields.map(name =>
      <tr key={"demandrow_" + name}>
        <td key="name">{name}</td>
        {suppliers.map((_supplier, i) => renderDemand(suppliers, demands, i, name))}
      </tr>
    );
  }

  const renderExtendDateButton = (supplier: Supplier) => {
    const { supplierId } = supplier;
    const versionInState = getVersionInState(supplierId._id);
    const quotationVersions = getQuotationVersions(supplierId);
    const getVersion = !_.isEmpty(versionData) ? versionData[0].versions : undefined;
  
    // push all relative versions of the supplier
    if (!_.isEmpty(getVersion)) {
      for (const qv of getVersion) {
        for (const s of qv.suppliers) {
          if (s.supplierId === supplierId._id) quotationVersions.push(qv);
        }
      }
    } 
    return (
      <TooltipContainer
        renderReferenceComponent={(className, ref) =>
          <BtButton
            ref={ref}
            class={className}
            color="white" 
            size="xxs" 
            onClick={() => onCalendar(supplier, quotationVersions, getVersion[versionInState])}
          >
            <MoreTimeIcon color='var(--gray-700)' size={18} />
          </BtButton>
        }        
      >
        <span style={{ whiteSpace: 'nowrap' }}>Förläng anbudstiden</span>
      </TooltipContainer>
    );
  }


  const renderMessage = (supplier: Supplier) => {
    return (<>
      {supplier.message && (
        <TooltipContainer renderReferenceComponent={(className, ref) => <MessageIcon className={className} ref={ref} style={{ top: '2px', width: '18px', fill: 'var(--gray-700)'}}/>}>
          <div style={{ minWidth: '250px' }}>
            <strong>Meddelande:</strong>
            <HTMLParser data={supplier.message}/>
          </div>
        </TooltipContainer>
      )}
    </>);
  }
  const renderReservations = (supplier: Supplier) => {
    return (<>
      {supplier.reservations && (
        <TooltipContainer renderReferenceComponent={(className, ref) => <TriangleWarningIcon className={className} ref={ref} style={{ top: '2px', width: '19px', fill: 'var(--gray-700)'}}/>}>
            <div style={{ minWidth: '250px' }}>
              <strong>Reservationer:</strong>
              <HTMLParser data={supplier.reservations}/>
            </div>
        </TooltipContainer>
      )}
    </>);
  }

  const renderAttachments = (supplier: Supplier) => {
    const projectSlug = currentProject.slug;
    const projectName = currentProject.name;
    const supplierId = supplier.supplierId._id;
    const supplierName = supplier.supplierId.name;
    const quoteVersionNo = getVersionInState(supplier.supplierId._id);
    return (<div style={{display:"grid",justifyContent:"center"}}>
      {supplier.attachments.length > 0 && (
        <TooltipContainer renderReferenceComponent={(className, ref) =>
          <DownloadButton
            url={ServiceQuotation.getDownloadQuotationResponseFilesInZipURL({
              projectSlug,
              areaSlug,
              supplierId,
              versionNumber: quoteVersionNo,
              orgId: currentProject.orgId,
            })}
            fileName={`Anbud från ${supplierName} på ${projectName} - ${areaName} - V${quoteVersionNo + 1}.zip`}
            render={({ downloading, progress, onClick }) => (
              downloading ? (
                <BtButton class={className} ref={ref} color="white" size="xxs" disabled={true}>
                  <Spinner progress={progress} size="xxs" />
                </BtButton>
              ) : (
                <BtButton class={className} ref={ref} color="white" size="xxs" onClick={onClick}>
                  <DownloadIcon/>
                </BtButton>
              )
            )}
          />
        }>
          <div style={{ minWidth: '250px' }}>
            <strong>Bilagor (ladda ner alla som .zip):</strong>
            <ul>
              {supplier.attachments.map(a => <li key={"attachment_" + a._id}>{a.fileName}</li>)}              
            </ul>
            
          </div>
        </TooltipContainer>
      )}
    </div>);
  }

  const renderOtherContent = (supplier: Supplier) => {
    return <div style={{display:"flex", gap: 16, justifyContent: "center"}}>
      {renderMessage(supplier)}
      {renderReservations(supplier)}
      {renderAttachments(supplier)}
    </div>;
  }

  const renderColumnBasedTable = () => {
    const suppliers = modifiedSuppliers.sort((a, b) => (a.isSubmitted ? 0 : 1) - (b.isSubmitted ? 0 : 1));
    return (
      <table className={styles.table}>
        <thead>
          <tr>
            <th>&nbsp;</th>
            {suppliers.map((supplier, i) => <th key={keyOf(supplier, i)} className={styles.supplierName}><div>{supplier.supplierId.name}</div></th>)}
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Version</th>
            {suppliers.map((supplier, i) => <td key={keyOf(supplier, i)}><div className={styles.center}>
              {renderVersionSelector(supplier)}
            </div></td>)}
          </tr>
          {/*<tr>
            <th>Anbudsformulär</th>
            {suppliers.map(supplier => <td><div className={styles.center}>
              <BtButton 
                color="white" 
                size="xxs"
                onClick={() => this.props.history.push(supplier.bidLink, {
                  supplierId: supplier.supplierId._id
                })}          
              >
                <RequestQuoteIcon size="18" color='var(--gray-700)' /> Öppna
              </BtButton>
            </div></td>)}
          </tr>*/}
          <tr>
            <th>Anbudstid</th>
            {suppliers.map((supplier, i) => <td key={keyOf(supplier, i)}><div className={styles.center}>
              {supplier.date && (
                <div style={{width: 125, display: 'flex', justifyContent: 'flex-end'}}>
                  <TooltipContainer renderReferenceComponent={(className, ref) =>
                    <div className={className} ref={ref} style={{whiteSpace: 'nowrap'}}>
                      {calcDaysLeft(supplier.date) < 0 ? (-calcDaysLeft(supplier.date) + " dagar sedan") : (calcDaysLeft(supplier.date) + " dagar kvar")}
                    </div>
                  }>
                    <div style={{minWidth: 250}}>
                      {(calcDaysLeft(supplier.date) < 0 ? "Anbudstiden gick ut " : "Anbudstiden går ut ") + moment(supplier.date).format("YYYY-MM-DD")}
                    </div>
                  </TooltipContainer>
                </div>                
              )}
              {renderExtendDateButton(supplier)}
            </div></td>)}
          </tr>
          <tr>
            <th>Status</th>
            {suppliers.map((supplier, i) => <td key={keyOf(supplier, i)}>{renderStatusIcons(supplier)}</td>)}
          </tr>
          <tr className={styles.empty}>
            <th>&nbsp;</th>
            {suppliers.map((supplier, i) => <td key={keyOf(supplier, i)}>&nbsp;</td>)}
          </tr>
          <tr className={styles.empty}>
            <th>Prisfält</th>
            {suppliers.map((supplier, i) => <td key={keyOf(supplier, i)}>&nbsp;</td>)}
          </tr>
          {renderPriceFieldRows(suppliers)}
          <tr className={styles.empty}>
            <th>&nbsp;</th>
            {suppliers.map((supplier, i) => <td key={keyOf(supplier, i)}>&nbsp;</td>)}
          </tr>
          <tr className={styles.empty}>
            <th>Skallkrav</th>
            {suppliers.map((supplier, i) => <td key={keyOf(supplier, i)}>&nbsp;</td>)}
          </tr>
          {renderDemandRows(suppliers)}
          <tr className={styles.empty}>
            <th>&nbsp;</th>
            {suppliers.map((supplier, i) => <td key={keyOf(supplier, i)}>&nbsp;</td>)}
          </tr>
          <tr>
            <th>Övrigt innehåll</th>
            {suppliers.map((supplier, i) => <td key={keyOf(supplier, i)}>{renderOtherContent(supplier)}</td>)}
          </tr>
          {showDefaultBidValidityDate && (
            <tr>
              <th>Giltigt till</th>
              {suppliers.map((supplier, i) => <td key={keyOf(supplier, i)}><div className={styles.center}>{getQuoteValidity(supplier?.quotationValidity)}</div></td>)}
            </tr>
          )}
        </tbody>
      </table>
    )
  }

  const originalLength = modifiedSuppliers.length;
  return (
    <>
      <div style={{overflowX: "auto", overflowY: "visible", width: "calc(100vw - 384px)", position: "relative", paddingBottom: "16px"}}>
        {renderColumnBasedTable()}
      </div>
    </>
  )      

}

export default withRouter(BidOverview);