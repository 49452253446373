// formatted with prettier (shift + alt + f)
import React, { Component } from 'react';
import _ from 'lodash';
import BTCheck from '../bt-check/bt-check';
import { serviceAreas } from '../../services/service-areas';
import SupplierDatabaseMini from '../app-supplier-database/supplier-database-mini';
import { alphas, getAllNonAlphaAreas, truncateString } from '../../util';
import './app-add-area.css';
import BtGrid from '../bt-grid/bt-grid';
import BtRow from '../bt-row/bt-row';
import BtColumn from '../bt-column/bt-column';
import BtButton from '../bt-button/bt-button';
import appState from '../../state/AppStateContainer';
import Modal from '../../components/shared/Modal/Modal';
import { CustomNavLink } from '../../components/routes';

const searchIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <g fill="none" fillRule="evenodd" transform="translate(-1 -1)">
      <polygon points="0 0 20 0 20 20 0 20" />
      <path
        fill="#000000"
        d="M14.6975818,3.90241824 C17.4224231,6.62725957 17.6565717,10.899619 15.4000275,13.8904201 L18.4119477,16.9020656 C19.1976219,17.6877398 18.0191106,18.8662511 17.2334364,18.0805769 L14.2577679,15.1046644 C11.2587143,17.6717713 6.74091358,17.5360771 3.90241824,14.6975818 C0.921416143,11.7165797 0.921416143,6.88342033 3.90241824,3.90241824 C6.88342033,0.921416143 11.7165797,0.921416143 14.6975818,3.90241824 Z M5.08092954,5.08092954 C2.75080126,7.41105781 2.75080126,11.1889422 5.08092954,13.5190705 C7.41105781,15.8491987 11.1889422,15.8491987 13.5190705,13.5190705 C15.8491987,11.1889422 15.8491987,7.41105781 13.5190705,5.08092954 C11.1889422,2.75080126 7.41105781,2.75080126 5.08092954,5.08092954 Z"
      />
    </g>
  </svg>
);

const plusIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <path
      fill="#FF5E15"
      fillRule="evenodd"
      d="M6.66666667,5.33333333 L11.3333333,5.33333333 C11.7015232,5.33333333 12,5.63181017 12,6 C12,6.36818983 11.7015232,6.66666667 11.3333333,6.66666667 L6.66666667,6.66666667 L6.66666667,11.3333333 C6.66666667,11.7015232 6.36818983,12 6,12 C5.63181017,12 5.33333333,11.7015232 5.33333333,11.3333333 L5.33333333,6.66666667 L0.666666667,6.66666667 C0.298476833,6.66666667 0,6.36818983 0,6 C0,5.63181017 0.298476833,5.33333333 0.666666667,5.33333333 L5.33333333,5.33333333 L5.33333333,0.666666667 C5.33333333,0.298476833 5.63181017,0 6,0 C6.36818983,0 6.66666667,0.298476833 6.66666667,0.666666667 L6.66666667,5.33333333 Z"
    />
  </svg>
);

const DEFAULT_STATE = {
  areaSearchTerm: '',
  currentStep: 1,
  newAreaName: '',
  newAreaNameInput: '',
};

export default class AppAddArea extends Component {
  state = {
    ...DEFAULT_STATE,
    areas: [],
  };

  componentDidMount = async () => {
    this.getAreas();
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    if (!_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state)) {
      return true;
    }

    return false;
  };

  getAreas = async () => {
    const areas = await serviceAreas.getAreas();
    this.setState({ areas });
  };

  setInputValue = ({ target }) => {
    if (target.name === 'newAreaNameInput' && !target.value) {
      this.setState({ [target.name]: target.value, currentStep: 1 });
      return;
    }

    this.setState({ [target.name]: target.value });
  };

  addAreaFromInput = () => {
    const { newAreaNameInput } = this.state;
    if (!newAreaNameInput) return;

    this.setState({ currentStep: 2, newAreaName: newAreaNameInput });
  };

  renderAreaItem = item => {
    const isSuperAdminOrganization = appState.isSuperAdmin() && appState.getCurrentOrgSlug() === 'superadmin';
    return (
      <ul key={item._id}>
        <li data-uid={item._id} className="app-add-area-item" title={item.name}>
          {
            isSuperAdminOrganization ? (
              <CustomNavLink to={`/categories/${item.slug}`}><span className="name">{truncateString(item.name, 40)}</span></CustomNavLink>
            ) : ( 
              <span className="name">{truncateString(item.name, 40)}</span>
            )
          }          
        </li>
      </ul>
    );
  };

  renderLeftSection = () => {
    const { areas = [], areaSearchTerm, currentStep, newAreaName } = this.state;

    if (currentStep === 1) {
      return (
        <BtRow className="overflow-listq custom-scrollbar">
          <BtColumn>
            {areaSearchTerm ? (
              areas
                .filter(
                  area =>
                    area.name && area.name.toLowerCase().startsWith(areaSearchTerm.toLowerCase()),
                )
                .map(this.renderAreaItem)
            ) : (
              <div>
                {alphas.map(alpha => (
                  <div key={alpha}>
                    <span className="app-add-area-item-label">{alpha}.</span>
                    {(alpha === '#'
                      ? getAllNonAlphaAreas(areas)
                      : areas.filter(area => area.name && area.name.toUpperCase().startsWith(alpha))
                    ).map(this.renderAreaItem)}
                  </div>
                ))}
              </div>
            )}
          </BtColumn>
        </BtRow>
      );
    } else {
      return (
        <div className="new-area-name-container">
          {newAreaName ? (
            <BTCheck uid={newAreaName} checked disableClick title={newAreaName}>
              <span className="name" title={newAreaName}>
                {truncateString(newAreaName, 30)}
              </span>
              <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className="checkmark__circle" cx="26" cy="26" r="23" fill="none" />
                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
              </svg>
            </BTCheck>
          ) : (
            <div />
          )}
        </div>
      );
    }
  };

  handleSubmit = () => {
    const { newAreaName } = this.state;
    const { handleAddAreaWithSuppliers } = this.props;

    const supplierIds = this.supplierDatabaseMiniInstance
      ? this.supplierDatabaseMiniInstance.getSupplierIds()
      : [];

    handleAddAreaWithSuppliers(newAreaName, supplierIds);
  };

  render() {
    const { areas = [], areaSearchTerm, currentStep, newAreaNameInput } = this.state;
    const { loading } = this.props;

    const allSubcategories = areas.map(o => o.subAreas).flat();
    const alreadyExists =
      !![...areas, ...allSubcategories].find(
        (s) => s && s.name && s.name.trim().toLowerCase() === newAreaNameInput.trim().toLowerCase(),
      ) || loading;

    return (
      <div className="app-add-area">
        <div className="page-paddings">
          <div className="bt-modal-close bt-modal-close-area" onClick={this.props.closeModal}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 612 612">
              <g id="cross">
                <polygon points="612,36.004 576.521,0.603 306,270.608 35.478,0.603 0,36.004 270.522,306.011 0,575.997 35.478,611.397 306,341.411 576.521,611.397 612,575.997 341.459,306.011" />
              </g>
            </svg>
          </div>

          <BtGrid>
            <BtRow className="modal--lg--style">
              <BtColumn className="colum-left">
                <div className="content-left">
                  <div className="app-add-area-items">
                    <div className="search-bar">
                      {searchIcon}
                      <input
                        type="text"
                        onChange={this.setInputValue}
                        name="areaSearchTerm"
                        value={areaSearchTerm}
                        placeholder="Sök yrkeskategori"
                      />
                    </div>

                    <div
                      className="add-area-input"
                      title={alreadyExists ? 'kategori eller underkategori finns redan' : ''}
                    >
                      {plusIcon}
                      <input
                        type="text"
                        onChange={this.setInputValue}
                        name="newAreaNameInput"
                        value={newAreaNameInput}
                        placeholder="Lägg till yrkeskategori"
                      />
                      <BtButton disabled={alreadyExists} onClick={this.addAreaFromInput} size="xxs" style={{marginLeft: "16px", height: "30px", verticalAlign: "top"}}>
                        Skapa
                      </BtButton>
                    </div>

                    {this.renderLeftSection()}

                    {currentStep !== 1 && (
                      <div
                        className="step-2-left"
                        title={alreadyExists ? 'kategori eller underkategori finns redan' : ''}
                      >
                        <BtButton disabled={alreadyExists} onClick={this.handleSubmit} className="bt-button">
                          Spara yrkeskategori
                        </BtButton>
                      </div>
                    )}
                  </div>
                </div>
              </BtColumn>

              <BtColumn className="colum-right">
                <div className="content-right">
                  {currentStep === 1 ? (
                    <div className="step-1-right">
                      <h2>Skapa ny yrkeskategori</h2>
                    </div>
                  ) : (
                      <SupplierDatabaseMini
                        mini
                        ref={el => (this.supplierDatabaseMiniInstance = el)}
                       />
                  )}
                </div>
              </BtColumn>
            </BtRow>
          </BtGrid>
        </div>
      </div>
    );
  }
}
