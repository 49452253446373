import React from 'react';
import Toggle from 'react-toggle';

function CustomToggle({ customClass, checked, onChange, icons, label }) {
  return (
    <label className={`ar-label ar-label-checkbox ${customClass}`} style={{ margin: 0 }}>
      {label && <span>{label}</span>}
      <Toggle className="ar-checkbox-toggle" checked={checked} onChange={onChange} icons={icons} />
    </label>
  );
}
export default React.memo(CustomToggle)