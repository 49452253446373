import { FC } from "react";
import { BidIntentStatus } from "../page-quotation/BidIntentControls/BidIntentControls";
import styles2 from '../page-dashboard-V2/preflight.module.scss';
import moment from "moment";
import { LineChart } from "../../components/shared/Charts/LineChart/LineChart";
import SummaryCard from "../../components/shared/Charts/SummaryCard/SummaryCard";
import PieChart from "../../components/shared/Charts/PieChart/PieChart";
import SummaryBarList from "../../components/shared/Charts/SummaryBarList/SummaryBarList";
import type { Request } from "./types";
import { Color } from "@tremor/react";
import _ from "lodash";

type Props = {
  requests: Request[],
  adStartDate?: Date,
}

const ReceivedBidRequestStats: FC<Props> = ({ requests, adStartDate }) => {
  const now = Date.now();
  const DAY_IN_MILLIS = 24 * 60 * 60 * 1000;

  const inProgressNoBid = requests.filter((r) => new Date(r.timeLeft).getTime() >= now && !r.answered);
  const inProgressHasBid = requests.filter((r) => new Date(r.timeLeft).getTime() >= now && r.answered);

  const ongoingUnanswered = inProgressNoBid.filter((request) => request.intent == BidIntentStatus.UNDECIDED);
  const ongoingAccepted = inProgressNoBid.filter((request) => request.intent == BidIntentStatus.ACCEPTED);
  const ongoingDeclined = inProgressNoBid.filter((request) => request.intent == BidIntentStatus.DECLINED);
  const ongoingBids = inProgressHasBid;
  const groupRequestsByStatus = (reqs: Request[]) => [
    reqs.filter((request) => !request.answered && request.intent == BidIntentStatus.UNDECIDED),
    reqs.filter((request) => !request.answered && request.intent == BidIntentStatus.ACCEPTED),
    reqs.filter((request) => !request.answered && request.intent == BidIntentStatus.DECLINED),
    reqs.filter((request) => request.answered),
  ];

  const recentRequests = requests.filter((r) => {
    const days = (now - new Date(r.timeLeft).getTime()) / DAY_IN_MILLIS;
    return days <= 30 && days >= 0;
  });
  const olderRequests = requests.filter((r) => (now - new Date(r.timeLeft).getTime()) / DAY_IN_MILLIS > 30);
  const [recentUnanswered, recentAccepted, recentDeclined, recentBids] = groupRequestsByStatus(recentRequests);
  const [olderUnanswered, olderAccepted, olderDeclined, olderBids] = groupRequestsByStatus(olderRequests);

  const topOrganizations = Array.from(requests.map((r) => r.org).reduce((map, key) => map.set(key, (map.get(key) ?? 0) + 1), new Map()).entries())
    .map((pair: [key: string, value: number]) => ({ key: pair[0], value: pair[1], name: pair[0] }))
    .sort((a, b) => b.value - a.value);

  const weeklyRequests = _.groupBy(requests, r => moment(r.date).format("[Vecka] W GGGG"));
  const weeklyBids = _.groupBy(requests.filter((r) => r.answered), r => moment(r.date).format("[Vecka] W GGGG"));

  let chartData: { Vecka: string, "Förfrågningar": number | null, "Anbud": number | null, "Förfrågningar efter annonsering"?: number | null, "Anbud efter annonsering"?: number | null, isAfterAd: boolean, won: number }[] = [];
  if (requests.length > 0) {
    const dates = requests.map((r) => new Date(r.date).getTime());
    const firstDate = Math.min.apply(this, dates);
    const lastDate = Math.max.apply(this, dates);
    let date = moment(firstDate);
    const lastWeek = moment(lastDate).format("[Vecka] W GGGG");
    const weeks = [];
    let week = "";
    let i = 0;
    while (week != lastWeek && i < 10000) {
      week = date.format("[Vecka] W GGGG");
      weeks.push(week);
      date = date.add(7, "days");
      i++;
    }
    const adStartWeek = moment(adStartDate).format('W GGGG');
    const [startWeek, startYear] = adStartWeek.split(' ').map(x => parseInt(x));
    const startDate = parseInt(`${startYear}${startWeek < 10 ? `0${startWeek}` : startWeek}`);

    chartData = weeks.map((w: string) => {
      const [, week, year] = w.split(' ').map(x => parseInt(x));
      const dateString = parseInt(`${year}${week < 10 ? `0${week}` : week}`);

      if (startDate && startDate <= dateString) {
        return {
          Vecka: w,
          "Förfrågningar": null,
          "Anbud": null,
          "Förfrågningar efter annonsering": weeklyRequests[w]?.length ?? 0,
          "Anbud efter annonsering": weeklyBids[w]?.length ?? 0,
          won: weeklyRequests[w]?.filter(r => r.won)?.length ?? 0,
          isAfterAd: true,
        }
      }
      return {
        Vecka: w,
        "Förfrågningar": weeklyRequests[w]?.length ?? 0,
        "Anbud": weeklyBids[w]?.length ?? 0,
        "Förfrågningar efter annonsering": null,
        "Anbud efter annonsering": null,
        won: weeklyRequests[w]?.filter(r => r.won)?.length ?? 0,
        isAfterAd: false,
      }
    });
  }
  // Antal förfrågningar: Totalt, Obesvarad, Ja, Nej, Anbud
  // Per vecka
  // Pågående, senaste 30 dagarna
  // Totalt
  // Anbudsfrekvens
  // Beställare top 5 
  // Vinnande anbud
  // Vinnande / totalt antal anbud
  // Vinnande / förfrågningar
  // Vinnande per beställare
  // Vinnande / anbud per beställare

  const lineChartTitles = ["Förfrågningar", "Anbud"];
  const lineChartColors: Color[] = ['orange', 'cyan',];
  if (adStartDate) {
    lineChartTitles.push('Förfrågningar efter annonsering');
    lineChartTitles.push('Anbud efter annonsering');
    lineChartColors.push('yellow');
    lineChartColors.push('emerald');
  }

  const areAdStats = adStartDate;

  const requestsCardTitle = areAdStats ? "Förfrågningar per vecka (före / efter)" : "Förfrågningar totalt";
  const bidsCardTitle = areAdStats ? "Anbud per vecka (före / efter)" : "Anbud totalt";
  const averageWonProcurementsCardTitle = areAdStats ? "Vunna upphandlingar per vecka (före / efter)" : "Vunna upphandlingar totalt";

  const chartDataAfterAd = chartData.filter(x => x.isAfterAd);
  const chartDataBeforeAd = chartData.filter(x => !x.isAfterAd);

  const averageRequestsPerWeekAfterAd = +((chartDataAfterAd.reduce((result, current) => result + (current?.["Förfrågningar efter annonsering"] || 0), 0) / (chartDataAfterAd.length || 1))).toFixed(2);
  const averageRequestsPerWeekBeforeAd = +((chartDataBeforeAd.reduce((result, current) => result + (current?.["Förfrågningar"] || 0), 0) / (chartDataBeforeAd.length || 1))).toFixed(2);
  const averageTendersPerWeekAfterAd = +((chartDataAfterAd.reduce((result, current) => result + (current?.["Anbud efter annonsering"] || 0), 0) / (chartDataAfterAd.length || 1))).toFixed(2);
  const averageTendersPerWeekBeforeAd = +((chartDataBeforeAd.reduce((result, current) => result + (current?.["Anbud"] || 0), 0) / (chartDataBeforeAd.length || 1))).toFixed(2);
  const averageWonCategoriesPerWeekAfterAd = +((chartDataAfterAd.reduce((result, current) => result + (current?.won || 0), 0) / (chartDataAfterAd.length || 1))).toFixed(2);
  const averageWonCategoriesPerWeekBeforeAd = +((chartDataBeforeAd.reduce((result, current) => result + (current?.won || 0), 0) / (chartDataBeforeAd.length || 1))).toFixed(2);

  const percentageIncreasedRequests = ((averageRequestsPerWeekAfterAd - averageRequestsPerWeekBeforeAd) / (averageRequestsPerWeekBeforeAd || 1)) * 100;
  const percentageIncreasedTenders = ((averageTendersPerWeekAfterAd - averageTendersPerWeekBeforeAd) / (averageTendersPerWeekBeforeAd || 1)) * 100;
  const percentageIncreasedWonCategories = ((averageWonCategoriesPerWeekAfterAd - averageWonCategoriesPerWeekBeforeAd) / (averageWonCategoriesPerWeekBeforeAd || 1)) * 100;

  return (
    <div style={{
      marginBottom: "16px",
      width: "100%",
      display: "grid",
      gridTemplateColumns: !areAdStats ? "calc(33% - 16px) calc(33% - 16px) calc(33% - 16px)" : '',
      gridTemplateRows: `128px 1fr ${areAdStats ? '' : '1fr'}`,
      gap: "16px",
    }} className={styles2.pageDashboardPreflightTailwindStyles}>
      <SummaryCard
        title={requestsCardTitle}
        value={areAdStats ? averageRequestsPerWeekBeforeAd.toFixed(2).replace('.', ',') : requests.length}
        value2={areAdStats ? averageRequestsPerWeekAfterAd.toFixed(2).replace('.', ',') : undefined}
        badgePercentage={areAdStats && percentageIncreasedRequests > 0 ? percentageIncreasedRequests : undefined}
      />
      <SummaryCard
        title={bidsCardTitle}
        value={areAdStats ? averageTendersPerWeekBeforeAd.toFixed(2).replace('.', ',') : requests.filter((request) => request.answered).length}
        value2={areAdStats ? averageTendersPerWeekAfterAd.toFixed(2).replace('.', ',') : undefined}
        badgePercentage={areAdStats && percentageIncreasedTenders > 0 ? percentageIncreasedTenders : undefined}
      />
      <SummaryCard
        title={averageWonProcurementsCardTitle}
        value={areAdStats ? averageWonCategoriesPerWeekBeforeAd.toFixed(2).replace('.', ',') : requests.filter((request) => request.won).length}
        value2={areAdStats ? averageWonCategoriesPerWeekAfterAd.toFixed(2).replace('.', ',') : undefined}
        infoText={!areAdStats ? "Totalt antal inköp där inköparen valt er som vinnande leverantör och signerat kontrakt" : ''}
        badgePercentage={areAdStats && percentageIncreasedWonCategories > 0 ? percentageIncreasedWonCategories : undefined}
      />

      {!areAdStats && (
        <>
          <PieChart
            title={"Pågående förfrågningar"}
            infoText={""}
            categories={[
              `Obesvarade: ${ongoingUnanswered.length}`,
              `Avser lämna anbud: ${ongoingAccepted.length}`,
              `Avser inte lämna anbud: ${ongoingDeclined.length}`,
              `Anbud inlämnat: ${ongoingBids.length}`,
            ]}
            data={[
              { name: "Obesvarade", value: ongoingUnanswered.length },
              { name: "Avser lämna anbud", value: ongoingAccepted.length },
              { name: "Avser inte lämna anbud", value: ongoingDeclined.length },
              { name: "Anbud inlämnat", value: ongoingBids.length },
            ]}
            colors={['yellow', 'slate', 'rose', 'emerald']}
          />
          <PieChart
            title={"Förfrågningar utgångna senaste 30 dagarna"}
            infoText={"Förfrågningar där anbudstiden gick ut senaste 30 dagarna"}
            categories={[
              `Obesvarade: ${recentUnanswered.length}`,
              `Avser lämna anbud (men inget anbud lämnat): ${recentAccepted.length}`,
              `Avser inte lämna anbud: ${recentDeclined.length}`,
              `Anbud inlämnat: ${recentBids.length}`,
            ]}
            data={[
              { name: "Obesvarade", value: recentUnanswered.length },
              { name: "Avser lämna anbud", value: recentAccepted.length },
              { name: "Avser inte lämna anbud", value: recentDeclined.length },
              { name: "Anbud inlämnat", value: recentBids.length },
            ]}
            colors={['yellow', 'slate', 'rose', 'emerald']}
          />
          <PieChart
            title={"Äldre förfrågningar"}
            infoText={"Förfrågningar där anbudstiden har gått ut för mer än 30 dagar sedan"}
            categories={[
              `Obesvarade: ${olderUnanswered.length}`,
              `Avser lämna anbud (men inget anbud lämnat): ${olderAccepted.length}`,
              `Avser inte lämna anbud: ${olderDeclined.length}`,
              `Anbud inlämnat: ${olderBids.length}`,
            ]}
            data={[
              { name: "Obesvarade", value: olderUnanswered.length },
              { name: "Avser lämna anbud", value: olderAccepted.length },
              { name: "Avser inte lämna anbud", value: olderDeclined.length },
              { name: "Anbud inlämnat", value: olderBids.length },
            ]}
            colors={['yellow', 'slate', 'rose', 'emerald']}
          />
        </>
      )}
      <LineChart
        title="Förfrågningar och anbud per vecka"
        categories={lineChartTitles}
        index={"Vecka"}
        data={chartData}
        colors={lineChartColors}
        style={{ gridColumnStart: 1, gridColumnEnd: areAdStats ? 4 : 3 }}
      />
      {!areAdStats && (
        <SummaryBarList
          title="Förfrågningar per beställare totalt"
          keyColumnName="Namn"
          valueColumName="Förfrågningar"
          data={
            topOrganizations
          }
        />
      )}
    </div>
  );
}

export default ReceivedBidRequestStats;