/**
* A helper class responsible for retreiving configuration of the web defined in the index.html file using a json variable.
*
*/
import appState from "../state/AppStateContainer";

export class ConfigHelper {
    getConfigObject() {
        return window.siteConfig;
    }
    
    getBaseAPIPath() {
        let obj = this.getConfigObject();

        let baseAPIPath = obj.appKeys.BaseAPIPath;
        if (baseAPIPath != undefined && baseAPIPath.length > 0) {
            if (baseAPIPath[baseAPIPath.length - 1] == '/')
                return baseAPIPath
            else
                return baseAPIPath + '/'
        }
        else
            return ''
    }

    getOrgBasedUrl(apiUrl, params){
        let currentOrgUrl = appState._getCurrentOrgUrl();
        let orgUrl = currentOrgUrl || "/default";
        if(params && params.orgSlug && !currentOrgUrl) orgUrl = `/${params.orgSlug}`;
        const concatUrl = "/api/organizations";
        if(apiUrl.startsWith(concatUrl)) return apiUrl;
        return apiUrl.replace("/api", `${concatUrl}${orgUrl}`);
    }
}

const configHelper = new ConfigHelper()
export default configHelper