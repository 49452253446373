import Radio from "@material-ui/core/Radio";
import { FC, useCallback, useState } from "react";
import { OptionType } from "../../../models/global.model";
import { validateEmail } from "../../../util";
import Field from "../Field/Field";
import Modal from "../Modal/Modal";
import UserSelector, { UserOptionType } from "../UserSelector/UserSelector";
import styles from "./AddRecipientDialog.module.scss";

type Props = {
  show: boolean;
  setShow: (b: boolean) => void;
  recipients : UserOptionType[];
  userOptions : UserOptionType[];
  onSave: (user: UserOptionType) => void;
  title?: string,
}

const AddRecipientDialog: FC<Props> = ({show, setShow, recipients, userOptions, onSave, title}) => {
  const [addExisting, setAddExisting] = useState(true);
  const [selectorValue, setSelectorValue] = useState<UserOptionType|null>(null);
  const [nameValue, setNameValue] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [emailError, setEmailError] = useState("");

  const isOptionDisabled = useCallback(
    (option: OptionType) => !!recipients.find(recipient => recipient._id == option?.value), 
    [recipients]
  );

  const validateAndSave = () => {
    if (addExisting) {
      if (selectorValue) {
        onSave(selectorValue);
        setShow(false);
      }
    } else {
      // validate
      const name = nameValue.trim();
      const email = emailValue.trim();
      let valid = true;
      if (!name) {
        setNameError("Namn är obligatoriskt.");
        valid = false;
      }
      if (!email) {
        setEmailError("E-postadress är obligatoriskt.");
        valid = false;
      } else if (validateEmail(email)) {
        setEmailError("Ogiltig e-postadress.");
        valid = false;
      } else if (recipients.find(recipient => recipient.email.trim() == email)) {
        setEmailError("E-postadressen finns redan bland mottagarna.");
        valid = false;          
      }
      if (valid) {
        const now = new Date().toISOString();
        onSave({name, email, _id: now, value: now, label: name});
        setShow(false);
      }
    }
  };

  return (
    <Modal 
      title={title || "Lägg till mottagare"}
      show={show} 
      setShow={setShow} 
      buttonInfo={{label: "Lägg till", action: validateAndSave}}
      hasCloseButton={false}>
      <div className={styles.addRecipient}>
        <div
          className={`${addExisting ? styles.selected : ''} ${styles.rowContainer}`}
          onClick={() => {
            setAddExisting(true);
          }}
        >
          <div className={styles.radioIcon}>
            <Radio
              edge="start"
              checked={addExisting}
              tabIndex={-1}
              disableRipple
              //inputProps={{ 'aria-labelledby': labelId }}
              checkedIcon={<span className={`${styles.icon} ${styles.checkedIcon}`} />}
              icon={<span className={styles.icon} />}
              className={styles.sharingModal}
            />
          </div>
          <div style={{ marginTop: '3px' }}>
            <UserSelector
              value={selectorValue}
              onChange={setSelectorValue}
              options={userOptions}
              isOptionDisabled={isOptionDisabled}
              label="Registrerad användare"
            />
          </div>
        </div>
        <div
          className={`${!addExisting ? styles.selected : ''} ${styles.rowContainer}`}
          onClick={() => {
            setAddExisting(false);
          }}
        >            
          <div className={styles.radioIcon}>
            <Radio
              edge="start"
              checked={!addExisting}
              tabIndex={-1}
              disableRipple
              //inputProps={{ 'aria-labelledby': labelId }}
              checkedIcon={<span className={`${styles.icon} ${styles.checkedIcon}`} />}
              icon={<span className={styles.icon} />}
              className={styles.sharingModal}
            />
          </div>
          <div style={{ marginTop: '3px' }}>
            <div className="selector-label">Annan mottagare</div>
            <div className={styles.row}>
            <div className={styles.column}>
              <Field
                label="Namn"
                required={true}
                value={nameValue}
                onChange={value => {setNameError(""); setNameValue(value);}}
                errorMessage={nameError}
              />
            </div>
            <div className={styles.column}>
              <Field 
                label="E-post" 
                required={true}
                value={emailValue}
                onChange={value => {setEmailError(""); setEmailValue(value);}}
                errorMessage={emailError}
              />
            </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AddRecipientDialog;