//import { Component, h, Host, Element, Prop } from '@stencil/core';
import React from "react";
import { btNL2BR } from "../../utils/bt-string";
//import { IAppChatWriteMessageSubmitData } from '../app-chat-write-message/app-chat-write-message';
//import anime from 'animejs';
import { AppHeadsup } from "../app-headsup/app-headsup";
import { AppChatHeaderClients } from "../app-chat-header-clients/app-chat-header-clients";
import { AppChatMessages } from "../app-chat-messages/app-chat-messages";
import { AppChatFileUploads } from "../app-chat-file-uploads/app-chat-file-uploads";
import PropTypes from "prop-types";
import _ from "lodash";
import "./app-chat.css";
import appState from "../../state/AppStateContainer";
import moment from "moment";
import { ServiceProjects } from "../../services/service-projects";
import appEnum, { FileScope } from "../../util/appEnum";
import BackButton from '../../components/shared/BackButton/BackButton';
moment.locale("sv");
import styles from "./app-chat.module.scss";
import BtGrid from "../bt-grid/bt-grid";
import BtRow from "../bt-row/bt-row";
import BtColumn from "../bt-column/bt-column";
import ChatInput from "../app-chat-write-message/ChatInput";
import { getImageUrl } from "../../components/shared/ImagePreview/ImagePreview";
import WarningIcon from "../../components/svgs/warningIcon";
import { isValidEmail } from "../../util";
/* 
    export interface IAppChatSubmitData extends IAppChatWriteMessageSubmitData {
    message: string,
    type: string;
    name: string;
    email: string;
    phone: string;
    date: string;
    timestamp: string;
    read: boolean;
    newMessage: boolean;
}
 */
export const resizeScroller = () => {
  const scroller = document.getElementsByClassName('chat-message-scroller')?.[0];
  const footerHeight = (document.getElementsByClassName('app-chat-footer')?.[0])?.offsetHeight ?? 16;
  if (scroller) {          
    scroller.style.height = `calc(100vh - ${scroller.getBoundingClientRect().top + footerHeight}px)`;
  }  
}

export class AppChat extends React.Component {
  intervalID = 0;

  //elTextarea;
  //elBtnSend;
  state = {
    clients: [],
    currentSupplier: {},
    currentClient: {},
    totalFilesClient: [],
    totalFilesUser: [],
    messages: []
  };
  elColumnOverflow;
  elMessages;
  elColumnUploads;
  elAppChatWriteMessage;
  elAppChatFileUploads;
  elAppHeadsup;

  element;

  messages = this.props.messages;
  currentSupplier = this.props.currentSupplier;
  currentClient = this.props.currentClient;
  clients = this.props.clients;
  totalFilesClient = this.props.totalFilesClient;
  totalFilesUser = this.props.totalFilesUser;
  baseURL = this.props.baseURL;
  role = this.props.role;
  newMessage = [];
  chatOnly;
  notReadMessageCounter;
  static contextTypes = {
    router: PropTypes.object
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    // if (this.props.currentSupplier === nextProps.currentSupplier) {
    /*this.componentHasHeight(this.elColumnOverflow).then(() => {
      this.bind();
      this.windowResizeCallback(null);
      setTimeout(() => {
        this.windowResizeCallback(null);
      }, 200);
    });*/

    this.intervalID = setTimeout(() => {
      this.resizeAppChatWriteMessage();
      this.resizeAppChatFileUploads();
      this.positionAppHeadsup();
      // this.handleHeadsup();
    }, 1000);
    // }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  componentDidMount() {
    resizeScroller();
  }

  componentDidUpdate() {
    resizeScroller();
  }

  componentHasHeight(el) {
    return new Promise((_resolve, _reject) => {
      let timeout = () => {
        setTimeout(() => {
          if (el.offsetHeight > 1) {
            _resolve();
          } else {
            timeout();
          }
        }, 0);
      };
      timeout();
    });
  }

  bind() {
    this.unbind();
    window.addEventListener("resize", this.windowResizeCallback);
  }

  unbind() {
    window.removeEventListener("resize", this.windowResizeCallback);
  }

  windowResizeCallback = () => {
    this.resizeAppMessages();
    this.resizeAppChatWriteMessage();
    this.resizeAppChatFileUploads();
  };

  resizeAppMessages() {
    // this.elMessages.style.height = 0 + "px";
    // this.elMessages.style.height =
    //   this.elColumnOverflow.offsetHeight - 0 + "px"; //this.elAppChatWriteMessage.offsetHeight) + 'px';
  }

  resizeAppChatWriteMessage() {
    // this.elAppChatWriteMessage.style.width = this.elColumnOverflow.offsetWidth + 'px';
  }

  resizeAppChatFileUploads() {
    // var rect = this.elColumnUploads.getBoundingClientRect();
    // this.elColumnUploads.style.height = window.innerHeight - rect.top + "px";
  }

  positionAppHeadsup() {
    // var elRect = this.elAppHeadsup.getBoundingClientRect();
    // var messagesRect = this.elMessages.getBoundingClientRect();
    // var rect = this.elColumnUploads.getBoundingClientRect();
    // this.elAppHeadsup.style.top = messagesRect.top + "px";
    // this.elAppHeadsup.style.left = rect.left - elRect.width - 50 + "px";
  }

  handleHeadsup() {
    var newMessages = this.elMessages.querySelectorAll(".new-message");
    var found = 0;
    newMessages.forEach(el => {
      // Check if message is in viewport
      if (!this.isInViewport(el)) {
        found++;
      } else {
        el.classList.remove("new-message");
      }
    });

    this.notReadMessageCounter = found;
  }

  isInViewport = elem => {
    var bounding = elem.getBoundingClientRect();
    var itemTop =
      bounding.top - this.elColumnUploads.getBoundingClientRect().top;
    var viewportHeight = this.elMessages.getBoundingClientRect().height;
    return (
      itemTop >= -10 &&
      (itemTop < viewportHeight / 2 ||
        itemTop + (bounding.height - 40) <= viewportHeight)
    );
  };

  sendMessage(data, animateScroll) {
    // if (data.message == "") {
    //   return;
    // }

    var func = () => {
      // this.element.classList.remove("animate-opacity");
      this.props.currentClient.hasConversation = true;
      let userAvatarUrl = this.props.messages.find((msg)=> (data.type === "user") && (msg.email === data.email));
      data.avatarUrl = userAvatarUrl ? userAvatarUrl.avatarUrl : data.avatarUrl;
      this.props.messages.unshift(data);
      this.setState({
        clients: [...this.props.clients]
      });
      // this.element.classList.add("added-message");
      // setTimeout(() => {
      //   this.element.classList.add("animate-opacity");
      //   this.element.classList.remove("added-message");
      //   this.handleHeadsup();
      // }, 1000);

      if (data.type == "client") {
        this.setState({
          totalFilesClient: [...this.props.totalFilesClient, ...data.files]
        });
      } else {
        this.setState({
          totalFilesUser: [...this.props.totalFilesUser, ...data.files]
        });
      }

      this.resizeAppChatFileUploads();
    };

    if (animateScroll) {
      this.scrollTop(func);
    } else {
      func();
    }
  }

  scrollTop(func) {
    var scrollYpos = this.elMessages.scrollTop;
    var duration = 1000;
    var delay = 0;
    if (scrollYpos <= 0) {
      duration = 0;
      delay = 0;
    } else if (scrollYpos < 200) {
      duration = 300;
    }

    var obj = {
      targets: this.elMessages,
      scrollTop: 0,
      duration: duration,
      delay: delay,
      easing: "easeInOutCubic",
      complete: func
    };
    if (!func || func instanceof Event) {
      delete obj.complete;
    }

    //anime(obj);
  }

  responseDemo() {
    setTimeout(() => {
      var today = new Date();

      var month = today.getMonth();
      month = month < 10 ? "0" + month : month;
      var day = today.getDay();
      day = day < 10 ? "0" + day : day;
      var date = today.getFullYear() + "-" + month + "-" + day;

      var hours = today.getHours();
      hours = hours < 10 ? "0" + hours : hours;
      var minutes = today.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var time = hours + ":" + minutes;

      var files = [];
      files[0] = { file: "http://norman.xls", date: date };
      files[1] = { file: "http://offert.pdf", date: date };
      files[2] = { file: "http://handlingar.doc", date: date };
      files[3] = {
        file: "http://Skärmavbild 2019-09-01 kl. 12.05.23.jpg",
        date: date
      };

      var cc = files.filter(() => {
        if (Math.round(Math.random() * 2) == 1) {
          return true;
        }
        return false;
      });

      var data = {};
      data.message = `Eiusmod magna minim voluptate cillum ea.`;

      if (Math.round(Math.random() * 2) == 1) {
        data.message = `Amet in voluptate qui sit magna esse sint. Id culpa laborum aute aliquip culpa sint commodo et ea et. Magna sunt cillum qui nulla incididunt cupidatat. Velit laborum veniam nisi esse cupidatat do dolor ex mollit minim quis cillum adipisicing tempor.

Aute sit culpa pariatur non laborum cupidatat velit sunt Lorem irure. Eiusmod Lorem commodo ut qui velit nulla mollit pariatur. Aute officia esse officia ullamco eu labore duis pariatur cupidatat magna. Dolore id id cupidatat aliqua incididunt dolore qui cupidatat nostrud qui. Laboris dolor non laborum elit dolore dolore officia veniam irure deserunt. Ipsum officia ullamco nulla quis magna veniam dolore ad ullamco ut.`;
      }

      data.message = btNL2BR(data.message);
      data.name = this.props.currentClient.name;
      data.email = this.props.currentClient.email;
      data.phone = this.props.currentClient.phone;
      data.type = "client";
      data.date = "Igår " + time;
      data.newMessage = true;
      data.files = [...cc];

      this.sendMessage(data, false);
    }, 3000);
  }

  elWriteMessageUpdate() {
    this.windowResizeCallback(null);
  }

  elWriteMessageComplete() {
    this.windowResizeCallback(null);
  }

  async elWriteMessageSubmit(data) {
    if (data.message == "" && _.isEmpty(data.files)) {
      return;
    }
    var today = new Date();
    const messageObj = {};
    messageObj.message = data.message;
    messageObj.name = appState.state.token.name;
    messageObj.email = appState.state.token.email;
    messageObj.phone = appState.state.token.phone;
    messageObj.type = "user";
    messageObj.date = moment();
    messageObj.timestamp =
      today.getFullYear() + "-" + today.getMonth() + "-" + today.getDay();
    messageObj.files = data.files.map(x => ({
      // date:moment().format("YYYY-MM-DD"),
      // id: x.lastModified,
      mimeType: x.mimeType,
      date: moment(),
      file: x.name
    }));
    const response = await ServiceProjects.postAdminMessage(
      this.props.currentProjectId,
      this.props.currentSupplier._id,
      this.props.currentClient._id,
      data.message,
      data.files,
      this.props.areaId
    );
    messageObj.files = response.result.attachment.map((file) => {
      file.date = moment();
      file.user = {email: appState.state.token.email, name: appState.state.token.name}

      return file;
    }); // Get updated filenames from azure blob store
    messageObj.message = response.result.message;
    messageObj.avatarUrl = appState.state.token.avatarUrl;
    this.sendMessage(messageObj);
    // this.responseDemo();
  }

  async elWriteSupplierMessageSubmit(data) {
    if (data.message == "" && _.isEmpty(data.files)) {
      return;
    }
    var today = new Date();
    const messageObj = {};

    messageObj.message = data.message;
    messageObj.name = this.props.currentClient.name;
    messageObj.email = this.props.currentClient.email;
    messageObj.phone = this.props.currentClient.phone;
    messageObj.type = "client";
    messageObj.date = moment();
    messageObj.timestamp =
      today.getFullYear() + "-" + today.getMonth() + "-" + today.getDay();

    const response = await ServiceProjects.postSupplierMessage(
      this.props.currentProjectId,
      this.props.currentSupplier._id,
      this.props.currentClient._id,
      data.message,
      data.files,
      this.props.areaId
    );
    messageObj.files = response.result.attachment;
    messageObj.message = response.result.message;
    this.sendMessage(messageObj);
  }
  

  getErrorMessage() {
    if (!this.props.currentClient.isActive) {
      return 'Kontaktpersonen är borttagen från leverantören. Det går inte att skicka fler meddelanden till borttagna personer.'
    }
    if (!isValidEmail(this.props.currentClient.email) && !appState.isSupplierRole()) {
      return 'Kontaktpersonen har en ogiltig e-postadress. Det går inte att skicka fler meddelanden.'
    }
    return '';
  }

  render() {
    const isQuoteScreen = window.location.pathname.includes("/quotation/info");
    const errorMessage = this.getErrorMessage();
    return (
      <div className="app-chat">
        <div ref={el => (this.elAppHeadsup = el)}>
          <AppHeadsup
            onClick={this.scrollTop.bind(this)}
            count={this.notReadMessageCounter}
          />
        </div>
        <BtGrid
          id="chat-msg-cn-wrapper"
          className={`chat-msg-cn-wrapper ${
            this.props.role === appEnum.Role.Supplier ? "only--supplier" : ""
          }`}
        >
          {!this.chatOnly && this.props.role != "supplier" && (
            <BtRow
              className={`bt-contract app-chat-header ${
                this.chatOnly ? "chat-only" : ""
              }`}
            >

              <BtColumn>
                <AppChatHeaderClients
                  role={this.props.role}
                  adminUser={this.props.adminUser}
                  clients={this.props.clients}
                  baseURL={this.props.baseURL}
                  notification={this.props.notification}
                  resizeScroller={resizeScroller}
                ></AppChatHeaderClients>
              </BtColumn>
              <BtColumn className="bt-contract bt-vcenter">
                <div className="company-name">
                  <span>{this.props.currentSupplier.name}</span>
                </div>
              </BtColumn>
            </BtRow>
          )}
          {this.props.role == "supplier" && 
            <div className={styles.supplierHeaderRow}> 
              <h2>Kontakta beställare</h2>
              <h3>{this.props.currentSupplier.name}</h3>
            </div>
          }
          <BtRow className="chat-message-window">
            <div
              className={`custom-scrollbar chat-message-scroller ${isQuoteScreen ? "style--2" : ""}`}
              ref={el => (this.elMessages = el)}
            >
              <AppChatMessages
                currentClient={this.props.currentClient}
                scope={FileScope.ProjectConversation}
                scopeOptions={{
                  projectId: this.props.currentProjectId,
                  supplierId: this.props.currentSupplier._id,
                  areaId: this.props.areaId,
                  clientId: this.props.currentClient._id,
                }}
                messages={
                  _.isEmpty(this.state.messages)
                    ? this.props.messages
                    : this.state.messages
                }
                class="messages"
                loading={this.props.loading}
              ></AppChatMessages>
            </div>
            <BtColumn
              className="bt-contract column-uploads uploads-gallery-wrapper"
              id="uploads-gallery-wrapper"
              ref={el => (this.elColumnUploads = el)}
            >
              <AppChatFileUploads
                nameUser={`${this.props.adminUser.name}`}
                nameClient={`${this.props.currentClient.name}`}
                scope={FileScope.ProjectConversation}
                scopeOptions={{
                  projectId: this.props.currentProjectId,
                  supplierId: this.props.currentSupplier._id,
                  areaId: this.props.areaId,
                  clientId: this.props.currentClient._id,
                }}
                filesClient={
                  _.isEmpty(this.state.totalFilesClient)
                    ? this.props.totalFilesClient
                    : this.state.totalFilesClient
                }
                filesUser={
                  _.isEmpty(this.state.totalFilesUser)
                    ? this.props.totalFilesUser
                    : this.state.totalFilesUser
                }
                ref={el => (this.elAppChatFileUploads = el)}
              ></AppChatFileUploads>
            </BtColumn>
          </BtRow>
          {this.props.currentClient.email && !appState.isReadOnlyAccessRole() && (
            (!errorMessage) ? (
              <BtRow className="bt-contract app-chat-footer">
                <BtColumn>
                  <ChatInput 
                    onSubmit={(
                      this.props.role !== appEnum.Role.Supplier
                        ? this.elWriteMessageSubmit.bind(this)
                        : this.elWriteSupplierMessageSubmit.bind(this)
                    )}
                    resizeScroller={resizeScroller} 
                  />
                </BtColumn>
              </BtRow>
            ) : (
              <BtRow className="bt-contract app-chat-footer">
                <div className="deletedSupplierContactMessage">
                  <WarningIcon color="var(--yellow-400)" />
                  {errorMessage}
                </div>
              </BtRow>
            ))}
        </BtGrid>
      </div>
    );
  }
}
