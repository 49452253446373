import React from 'react';
import ReactLoading from 'react-loading';
import { areEqual } from '../../util';

type LoadingType =
  | 'blank'
  | 'balls'
  | 'bars'
  | 'bubbles'
  | 'cubes'
  | 'cylon'
  | 'spin'
  | 'spinningBubbles'
  | 'spokes';

interface Props {
  loadingStyle?: LoadingType;
  type?: string;
  color?: string;
  height?: string | number;
  width?: string | number;
  message?: string;
  size?: string | number;
  className?: string;
}

const Loading: React.FC<Props> = ({
  type = '',
  color = '#ff5e15',
  height,
  width,
  loadingStyle = 'spinningBubbles',
  message,
  size,
  className,
}) => {
  if (type == 'inline') {
    return (
      <ReactLoading
        className={className}
        type={loadingStyle}
        color={color}
        height={size || height}
        width={size || width}
      />
    );
  }

  return (
    <React.Fragment>
      <div
        style={{
          position: 'fixed',
          backgroundColor: '#000',
          opacity: 0.3,
          zIndex: 9999,
          height: '100%',
          width: '100%',
          top: '0px',
          left: '0px',
        }}
        onClick={undefined}
      />
      <div
        style={{
          position: 'absolute',
          borderRadius: '5px',
          height: '100px',
          width: '200px',
          left: '50%',
          marginLeft: '-100px',
          top: '50%',
          marginTop: '-100px',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',

          backgroundColor: 'rgba(0,0,0,.6)',
          color: 'white',
          zIndex: '99999999',
        }}
      >
        <p>
          <strong>{message || 'Loading...'}</strong>
        </p>
        <ReactLoading color="silver" type="spin" height="20px" width="20px" />
      </div>
    </React.Fragment>
  );
};

export default React.memo(Loading, areEqual);
