import React, { useEffect, useRef, useState, VFC } from 'react';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import './styles.css';

type props = {
  isOpen: boolean;
  children: React.ReactNode;
  header?: string | React.ReactNode;
  headerClassName?: string;
  titleClassName?: string;
  iconContainerClassName?: string;
  contentClassName?: string;
  contentContainerClassName?: string;
  collapsibleClassName?: string;
  onChangeCollapse?: () => void;
};

const Collapsible: VFC<props> = ({
  isOpen,
  onChangeCollapse,
  collapsibleClassName,
  headerClassName,
  titleClassName,
  iconContainerClassName,
  contentClassName,
  contentContainerClassName,
  children,
  header,
}) => {
  const [height, setHeight] = useState(0);

  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current?.getBoundingClientRect().height);
      const resizeObserver = new ResizeObserver(el => {
        if (height != el[0].contentRect.height) {
          setHeight(el[0].contentRect.height);
        }
      });
      resizeObserver.observe(ref.current);      
      return () => resizeObserver.disconnect();
    }
  }, []);
  return (
    <div className={`collapsible-card-generic ${collapsibleClassName ?? ""}`}>
      <div>
        <div className={`collapsible-header-generic ${headerClassName ?? ""} ${!isOpen ? 'remove-border' : ''}`}>
          <div className={`title-text-generic ${titleClassName ?? ""}`}>{header}</div>
          <div className={`collapsible-icon-container-generic ${iconContainerClassName ?? ""}`} onClick={onChangeCollapse}>
            {isOpen ? <RemoveIcon /> : <AddIcon />}
          </div>
        </div>
      </div>
      <div className={`collapsible-content-generic ${contentClassName ?? ""} ${isOpen ? 'isOpen' : ''}`} style={{ height: isOpen ? height : 0 }}>
        <div ref={ref}>
          <div className={`${contentContainerClassName ?? ""} ${isOpen ? `collapsible-content-padding-generic` : ''}`} >{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Collapsible;
