import './page-tips.css';
import React, { useEffect, useState } from 'react';
import { getTips, updateTips } from '../../services/service-tips';
import { serviceAreas } from '../../services/service-areas';
import appState from '../../state/AppStateContainer';
import BackButton from '../../components/shared/BackButton/BackButton';
import AppLayout from '../../components/layout/app-layout/app-layout';
import ScrollToTop from '../scroll-to-top/scroll-to-top';
import Editor from '../../components/shared/Editor/Editor';

const PageTips = ({ match }) => {
  const [areaName, setAreaName] = useState('');
  const [editing, setEditing] = useState(false);
  const [prevHTML, setPrevHTML] = useState('');
  const [tips, setTips] = useState('');

  useEffect(() => {
    serviceAreas.getArea(match.params.areaSlug).then(setAreaName);
    getTips(match.params.areaSlug).then((html) => setTips(html));
  }, [])  

  const onSave = () => {
    updateTips({ areaSlug: match.params.areaSlug, tips });
    setEditing(false);
  }
  const onCancel = () => {
    setTips(prevHTML);
    setEditing(false);
  }

  const toggleEditing = () => {
    setEditing(oldState => {
      if (!oldState) {
        setPrevHTML(tips);
      }
      return !oldState;
    });
  }

  return (
    <AppLayout
      title={
        <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
          <BackButton to={`/projects/${match.params.project}/${match.params.areaSlug}`} />
          <h3>Upphandlingstips för {areaName}</h3>
        </div>
      }
    >
      <ScrollToTop />
      <div className={`tipsContainer ${editing ? 'editing' : ''}`}>
        <div className='header'>
          {appState.isManagementRole() && <button className='ar-button' onClick={toggleEditing}>Redigera</button>}
        </div>
        <Editor value={tips} onChange={setTips} hideToolbar={!editing} isDisabled={!editing} />
        <div className='buttonDiv'>
          <button className='ar-button secondary' onClick={onCancel}>Avbryt</button>
          <button className='ar-button' onClick={onSave}>Spara</button>
        </div>
      </div>
    </AppLayout>
  );
}

export default PageTips;