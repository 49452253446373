import { FC } from 'react';
import styles from './TinyRippler.module.scss';

type Props = {
  show: boolean,
}

export const TinyRippler: FC<Props> = ({ show = true }) => {
  return (
    <div className={`${styles.rippler} ${show ? styles.show : ''}`}><div></div><div></div></div>
  )
}