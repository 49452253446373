import React, { MouseEventHandler, ReactNode, forwardRef } from "react";

import './bt-button.css';

type Props = {
  leftIcon?: ReactNode,
  rightIcon?: ReactNode,
  align?: string,
  color?: string,
  class?: string,
  type?: string,
  size?: string,
  cy?: any,
  disabled?: boolean,
  loaderShow?: boolean,
  style?: React.CSSProperties,
  onClick?: (MouseEventHandler<HTMLDivElement>),
  children?: ReactNode,
}

export default forwardRef(function BtButton({children, ...props}: Props, ref: React.ForwardedRef<HTMLDivElement>) {
  const { leftIcon, rightIcon, align='right', color='', class: varClass, size='base', cy } = props;
  const isLoadingOrDisabled = props.disabled || props.loaderShow;
  return (
    <div ref={ref} style={props.style} className={`bt-button bt-align-${align} ${size}BtButtonSize ${color} ${varClass} ${props.loaderShow ? 'loader-show' : ''} ${isLoadingOrDisabled ? 'disabled' : ''}`}
      onClick={props.onClick ?? (() => {})} data-cy={cy}>
      <div className={`${isLoadingOrDisabled ? 'disabled' : ''}`}>
          <span className='slot'>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                {leftIcon}
                {children}
              </div>
              {rightIcon}
          </span>
      </div>
      <span className="loader">
          <span className="bounce1"></span>
          <span className="bounce2"></span>
          <span className="bounce3"></span>
      </span>
    </div>
  )
})
