import styles from './bidConfirmation.module.scss';
import SkapaProjektImage from '../../../assets/images/skapa_projekt.png';
import { SupplierAccountCard, SupplierStatus } from '../SupplierAccountCard/supplierAccountCard';
import { useEffect, useState } from 'react';
import { ServiceQuotation } from '../../../services/service-quotation';
import { Loading } from '../../../components/shared';

export default function BidConfirmation() {
  const [supplierStatus, setSupplierStatus] = useState<SupplierStatus>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const supplierStatus = await ServiceQuotation.fetchSupplierState(window.location.href.split('/')[window.location.href.split('/').length - 1]);

      setSupplierStatus(supplierStatus);
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return (
      <div style={{ height: '100%', width: '100%', display: 'grid', placeItems: 'center' }}>
        <Loading type='inline' />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img src={SkapaProjektImage} alt="Success" />
        <div>
          <p>Tack för ditt anbud!</p>
          <p>Vi gör vårt absolut bästa för att återkomma med ett besked så fort vi har tagit ett beslut. Om du önskar ställa någon fråga under anbudstiden så hänvisar vi till länken som följde med i samma mejl som förfrågningsunderlaget. All dialog kring projektet sker via den kommunikationsytan som öppnas när du klickat på länken.</p>
        </div>
      </div>

      <SupplierAccountCard supplierStatus={supplierStatus} />
    </div>
  );
}