import { FC, } from "react";
import { RouteComponentProps, } from "react-router-dom";
import { PageWithTabs } from "../../components/shared/PageWithTabs/PageWithTabs";
import { EventTab } from "./EventTab";
import { MonitoredSupplierTab } from "./MonitoredSupplierTab";

type Params = {
  supplier?: {
    organizationNumber: string,
    name: string,
  },
  project?: {
    id: string,
    name: string,
  }
};
type RouteTypes = RouteComponentProps<null, Record<string, unknown>, Params>;

type Props = {
  history: RouteTypes['history'];
  match: RouteTypes['match'];
  location: RouteTypes['location'];
}

export const PageSupplierMonitoring: FC<Props> = ({
  location,
  history
}) => {
  const tabInfo = [{
    name: 'Leverantörer',
    path: '/supplier-monitoring',
    component: MonitoredSupplierTab,
  }, {
    name: "Händelser",
    path: '/supplier-monitoring/events',
    component: EventTab,
    props: { location, history },
  }];

  return (
    <PageWithTabs tabInfo={tabInfo} history={history} />
  );
};