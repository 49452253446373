import axios from "axios";
import _ from "lodash";
import BaseService from "./baseService";
import { BehaviorSubject } from "rxjs";


class ServiceAreasController extends BaseService {
  data$ = new BehaviorSubject([]);
  areaStorage = null;
  getUpdatedAreas(areas) {
    if (areas && areas.length) {
      return areas.filter((a) => {
        if (a.subAreas.length) {
          let subAreaList = a.subAreas.map((id) => {
            return areas.find((__) => __._id == id);
          });
          a.subAreas = subAreaList.filter(Boolean);
          return a;
        }
        if (!a.isSubArea) {
          return a;
        }
      });
    }
    return [];
  }

  async getAreas(newArea) {
    return (await this.getAreasIncludingDeleted(newArea)).filter(area => area.isActive);
  }

  async getAreasIncludingDeleted(newArea) {
    if (this.areaStorage && !newArea) {
      return Promise.resolve(this.areaStorage);
    }
    const response = await axios.get("/api/areas");
    this.areaStorage = this.getUpdatedAreas(response.data);
    return this.areaStorage;    
  }

  updateArea(areaId, subArea) {
    const area = this.areaStorage.find(_ => _.id === areaId);
    const prevState = this.areaStorage.filter(_ => _._id !== areaId);
    this.areaStorage = [...prevState, {...area, subAreas: [...area.subAreas, subArea]}];
    return this.areaStorage
  }

  async fetchAreas() {
    const response = await axios.get("/api/areas");
    this.areaStorage = this.getUpdatedAreas(response.data);
    return this.areaStorage;
  }

  async addArea(data = {}) {
    const response = await axios.post("/api/areas/add", data);
    this.areaStorage = this.getUpdatedAreas(response.data);

    // this.areaStorage = response.data;
    return this.areaStorage;
  }

  async addAreaBatch(projectSlug, selectedCategories) {
    const response = await axios.post(`/api/projects/${projectSlug}/areas/batch-add`, {
      selectedCategories
    });

    return response.data;
  }

  async getArea(slug) {
    const res = await axios.get(`/api/areas/${slug}`);
    return res.data;
  }

  async deleteArea(id) {
    const res = await axios.delete(`/api/areas/${id}`);
    const index = this.areaStorage.findIndex(a => a._id === id);
    this.areaStorage.splice(index, 1);
    this.data$.next(this.areaStorage);
    return res.data;
  }

  async renameArea(id, name) {
    const res = await axios.put(`/api/areas/${id}`, {_id: id, name });
    const area = this.areaStorage.find(a => a._id === id);
    area.name = name;
    this.data$.next(this.areaStorage);
    return res.data;
  }

  async removeSubArea(areaId,subAreaId) {
    var areaIndex = this.areaStorage.findIndex((val) => {
      if (val._id == areaId) {
        return true;
      }
    });
    if (areaIndex > -1) {
      var subAreaIndex = this.areaStorage[areaIndex].subAreas.findIndex((val)=>{
        if (val._id == subAreaId) {
          return true;
        }
      })
      this.areaStorage[areaIndex].subAreas.splice(subAreaIndex, 1);
      this.areaStorage = [...this.areaStorage];
      this.data$.next(this.areaStorage);
      return this.areaStorage
    }
  }

  async getAllCustomerAreas() {
    const res = await axios.get(`/api/areas/allCustomerAreas`);
    return res.data;
  }

  async saveCustomerAreas(slug, customerAreas) {
    const res = await axios.put(`/api/areas/${slug}/customerAreas`, {customerAreas});
    return res.data;
  }

  async fetchSuperAdminAreas() {
    const res = await axios.get(`/api/areas/all/from-superadmin`);
    return res.data;
  }

  async getAverageAdditionsPercent(slug) {
    const res = await axios.get(`/api/areas/${slug}/averageAdditionsPercent`);
    return res.data;
  }

}

export const serviceAreas = new ServiceAreasController();
