import AppLayout from '../../components/layout/app-layout/app-layout';
import styles from './page-dashboard-v2.module.scss';
import { FC, useCallback, useEffect, useState } from 'react';
import { ServiceDashboard } from '../../services/service-dashboard';
import Tabs from '../../components/shared/Tabs';
import "moment/locale/sv";
import { Loading } from '../../components/shared';
import OverviewTab from './Tabs/Overview/OverviewTab';
import { ActiveBidCardsType, ActiveUsers, BidsAndIntent, ContractAmountType, ProcurementSavingsType, ProjectsType, RSSFeedType, getTemplateItemOptions } from './helper';
import NewsTab from './Tabs/News/NewsTab';
import GeneralTab from './Tabs/General/GeneralTab';
import ProjectTab from './Tabs/Project/ProjectTab';
import AreaTab from './Tabs/Area/AreaTab';
import BtButton from '../bt-button/bt-button';
import { ReactComponent as RefreshIcon } from '../../assets/svg/refreshIcon.svg';
import styles2 from './preflight.module.scss';
import './dashboard.css';

type Props = {}

const Dashboard: FC<Props> = () => {
  const [projects, setProjects] = useState<ProjectsType>({
    open: 0,
    closed: 0,
  });
  const [bidsAndIntent, setBidsAndIntent] = useState<BidsAndIntent>({
    total: {
      sent: 0,
      received: 0,
      intent: 0,
      bidRate: 0,
      answerRate: 0,
      responsiveness: {
        bid: 0,
        yesNoBid: 0,
        no: 0,
        noAnswer: 0,
      },
    },
    projects: [],
    areas: []
  });
  const [activeUsers, setActiveUsers] = useState<ActiveUsers>([]);
  const [activeBidCards, setActiveBidCards] = useState<ActiveBidCardsType>({
    total: {
      amount: 0,
      numOfCards: 0,
    },
    projects: [],
    areas: []
  });
  const [contractAmount, setContractAmount] = useState<ContractAmountType>({
    total: 0,
    projects: [],
    areas: []
  });
  const [procurementSavings, setProcurementSavings] = useState<ProcurementSavingsType>({
    total: {
      difference: 0,
      percentageDiff: 0,
    },
    projects: [],
    areas: []
  });
  const [rssFeed, setRssFeed] = useState<RSSFeedType>([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshingData, setRefreshingData] = useState(false);
  // const [templateOptions, setTemplateOptions] = useState<TemplateOptionCollectionType>({});

  const fetchRssFeed = async () => {
    const rssFeed = await ServiceDashboard.getRssFeed();
    setRssFeed(rssFeed);
  }

  const fetchProjects = async () => {
    const projects = await ServiceDashboard.getProjects();
    setProjects(projects);
  }

  const fetchBidsAndIntent = async () => {
    const bidsAndIntent = await ServiceDashboard.getBidsAndIntent();
    setBidsAndIntent(bidsAndIntent);
  }

  const fetchActiveUsers = async () => {
    const activeUsers = await ServiceDashboard.getActiveUsers();
    setActiveUsers(activeUsers);
  }

  const fetchActiveBidCards = async () => {
    const activeBidCards: ActiveBidCardsType = await ServiceDashboard.getActiveBidCards();
    setActiveBidCards(activeBidCards);
  }

  const fetchContractAmount = async () => {
    const contractAmount = await ServiceDashboard.getContractAmount();
    setContractAmount(contractAmount);
  }

  const fetchProcurementSavings = async () => {
    const procurementSavings = await ServiceDashboard.getProcurementSavings();
    setProcurementSavings(procurementSavings);
  }

  const fetchData = async () => {
    setIsLoading(true);
    await Promise.all([
      fetchRssFeed(),
      fetchProjects(),
      fetchBidsAndIntent(),
      fetchActiveUsers(),
      fetchActiveBidCards(),
      fetchContractAmount(),
      fetchProcurementSavings(),
    ]);
    setIsLoading(false);
  }

  useEffect(() => {
    (async () => {
      await fetchData();
    })()
  }, []);

  const cachedFn = useCallback((activeBidCards, bidsAndIntent, contractAmount, procurementSavings, activeUsers, projects) => {
    return getTemplateItemOptions(activeBidCards, bidsAndIntent, contractAmount, procurementSavings, activeUsers, projects)
  }, [activeBidCards, bidsAndIntent, contractAmount, procurementSavings, activeUsers, projects]);

  const [templateOptions, templateItemRenderer] = cachedFn(activeBidCards, bidsAndIntent, contractAmount, procurementSavings, activeUsers, projects);
  // pageDashboardMainContainer
  return (
    <AppLayout title={'Startsida'}>
      <div className={`${styles.container} ${styles2.pageDashboardPreflightTailwindStyles}`}>
        <div style={{ display: 'flex', }}>
          <Tabs
            tabs={[ 'Översikt', 'Allmänt', 'Projekt', 'Kategori', 'Nyheter' ]}
            selected={selectedTab}
            onChange={setSelectedTab}
          />
          <BtButton
            style={{ marginTop: '7px' }}
            disabled={isLoading}
            loaderShow={refreshingData}
            leftIcon={<RefreshIcon />}
            color='white'
            size='xxs'
            onClick={async () => {
              setRefreshingData(true);
              await fetchData();
              setRefreshingData(false);
            }}
          />
        </div>

        {isLoading ? (
          <div className={styles.loader}>
            <Loading type='inline' />
          </div>
        ) : (
          <div className={styles.tabContainer}>
            {selectedTab === 0 && <OverviewTab templateOptions={templateOptions} rssFeed={rssFeed} />}
            {selectedTab === 1 && <GeneralTab templateItemRenderer={templateItemRenderer} />}
            {selectedTab === 2 && <ProjectTab templateItemRenderer={templateItemRenderer} />}
            {selectedTab === 3 && <AreaTab templateItemRenderer={templateItemRenderer} />}
            {selectedTab === 4 && <NewsTab rssFeed={rssFeed} />}
          </div>
        )}
      </div>
    </AppLayout>
  );
}

export default Dashboard;