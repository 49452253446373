//import { RouterHistory, MatchResults, LocationSegments, injectHistory } from '@stencil/router';
import React from "react";
import { AppChat } from "../app-chat/app-chat";
import PropTypes from "prop-types";
import $ from "jquery";

import "./page-projects-single-inbox.css";
import { ServiceProjects } from "../../services/service-projects";
import _ from "lodash";
import appState from "../../state/AppStateContainer";
import { ServiceUser } from '../../services/service-user';
import appEnum from "../../util/appEnum";
import AppLayout from "../../components/layout/app-layout/app-layout";
import BackButton from "../../components/shared/BackButton/BackButton";

export default class PageProjectsSingleInbox extends React.Component {
  state = {
    baseURL: "",
    dataLoaded: false,
    clients: [],
    currentSupplier: {},
    currentClient: {},
    totalFilesClient: [],
    totalFilesUser: [],
    messages: [],
    currentProject: { area: [] },
    getConversation: {
      conversation: []
    },
    adminUser: {},
    loading: true,
  };
  key;
  appChat;

  // Props
  clients = Array();
  currentSupplier = {};
  currentClient = {};
  totalFilesClient = Array();
  totalFilesUser = Array();
  messages = Array();
  location;
  history;
  static contextTypes = {
    router: PropTypes.object
  };
  baseURL;

  async componentDidMount() {
    if (
      !appState.state.token &&
      (this.props.role || appState.getRoleFromUrlParams()) != "supplier"
    ) {
      this.props.history.push("/");
    }

    if (!_.isNull(this.props.currentProject)) {
      this.setState(
        {
          currentProject: this.props.currentProject,
          role: this.props.role || appState.getRoleFromUrlParams()
        },
        async () => {
          this.props.checkRole(this.state.role);
          const clients = await this.getClients();
          this.setState({
            clients: clients
          });
          await this.loadMessages();
        }
      );
    } else if (_.get(this, "context.router.route.match.params.project")) {
      const project = await this.getProjectBySlug(_.get(this, "context.router.route.match.params.project"));
      this.setState(
        {
          role: this.props.role || appState.getRoleFromUrlParams(),
          currentProject: project
        },
        async () => {
          this.props.checkRole(this.state.role);
          const clients = await this.getClients();
          this.setState({
            clients: clients
          });
          await this.loadMessages();
        }
      );
    }

    this.hasClickedSupplier = false;
  }

  fixOldBidFormLink = (msg, supMemberId) => {
	  const oldLinkText = "Klicka här för att se inlämnat bud";
	  if (msg.includes(oldLinkText)) {
      let parent = document.createElement('div');
      parent.innerHTML = msg;
      parent.querySelectorAll('a').forEach(aTag => {
        if(aTag && aTag.href && !aTag.href.split(",")[1] && aTag.innerText === oldLinkText) {
          aTag.href = aTag.href+","+supMemberId;
        }
      });
		  return parent.outerHTML;
	  }
	  return msg;
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !appState.state.token &&
      (nextProps.role || appState.getRoleFromUrlParams()) != "supplier"
    ) {
      this.props.history.push("/");
    }
    // window.location.reload()
    const newPathName = nextProps.location.pathname;
    if (
      !_.isEqual(this.props.currentProject, nextProps.currentProject) ||
      this.props.location.pathname != newPathName
    ) {
      this.setState({loading: true});
      const project = await this.getProjectBySlug(_.get(this, "context.router.route.match.params.project"));
      this.setState(
        {
          role: nextProps.role || appState.getRoleFromUrlParams(),
          currentProject: project
        },
        async () => {
          this.props.checkRole(this.state.role);
          const clients = await this.getClients();
          this.setState({
            clients: clients
          });
          await this.loadMessages();
        }
      );
    }
  }

  async getProjectBySlug(slug) {
    if (appState.isSupplierRole()) {
      const supplier = await ServiceUser.getUserById(appState.state.token.id);
      return await ServiceProjects.getProjectBySlugForSupplier(
        slug,
        supplier.supplierId
      );
    }

    return await ServiceProjects.projectBySlug(slug)
  }

  getParams() {
    return this.props?.params ?? 
      this.context?.router?.match?.params ?? 
      this.props?.match?.params
  }

  getArea() {

    const { type } = this.getParams();
  
    let areaItem = [];
    // if (type && !_.isEmpty(this.state.currentProject.area)) {
    const currentProject = this.state.currentProject;
    areaItem = currentProject.area.filter(val => {
      if (val.areaId && val.areaId.slug == type) {
        return true;
      }
    });
    this.setState({
      areaId: areaItem[0].areaId._id,
      areaName: areaItem[0].areaId.name,
    });
    return areaItem[0];
    // }
  }

  getSupplier() {
    const { supplier } = this.getParams();
    var area = this.getArea();
    var supplierItem = area.suppliers.filter(val => {
      if (val.supplierId.slug == supplier) {
        return true;
      }
    });
    const currentSupplier = supplierItem[0] && supplierItem[0].supplierId;
    this.setState({
      currentSupplier: currentSupplier
    });
    return currentSupplier;
  }

  async getClients() {
    var s = this.getSupplier();
    let clients = await ServiceProjects.getSupplierMembers(s._id, true);
    let clientsWithConversation = await ServiceProjects.getConversationMembers(
      this.state.currentProject._id,
      this.state.areaId,
      this.state.currentSupplier._id,
    );
    return clients
      .map(c => ({...c, hasConversation: !!clientsWithConversation.find(conv => conv._id == c._id)}))
      .filter(c => c.isActive || clientsWithConversation.find(conv => conv._id == c._id));
  }

  handleHistory() {
    this.clients = this.getClients();
    this.loadMessages();
  }

  async sortClients(clients) {
    clients = await this.getClients();
    var hasConversationArr = [];
    var restArr = [];
    clients.filter(val => {
      if (val.hasConversation) {
        hasConversationArr.push(val);
      } else {
        restArr.push(val);
      }
    });

    hasConversationArr.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    restArr.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    return [...hasConversationArr, ...restArr];
  }

  async loadMessages() {
    var locationURL = window.location.pathname;
    var client = locationURL;

    this.key = client;

    let clients = await this.sortClients();
    let currentClient = {};
    let basePath;
    if (this.props.supplierMemberId) {
      currentClient = clients.find(x => x._id === this.props.supplierMemberId);
    } else {
      if (!this.context.router.route.match.params.supplierMember) {
        currentClient = { ...clients[0] };
        basePath = `${this.key}/${currentClient.slug}`;
        this.context.router.history.replace(basePath);
      } else {
        const { supplierMember } = this.context.router.route.match.params;
  
        currentClient = clients.find(val => {
          if (val.slug == this.context.router.route.match.params.supplierMember) {
            return true;
          }
        });
        currentClient = currentClient;
  
        let currentSupplierMenu = $(`[data-supplier-slug=${currentClient.slug}${this.state.areaId}]`);
        if (!this.hasClickedSupplier && currentSupplierMenu.length > 0) {
          currentSupplierMenu[0].click();
          this.hasClickedSupplier = true;
        }
      }  
    }

    const payload = {
      suppliermemberId: currentClient._id,
      projectId: this.state.currentProject._id,
      supplierId: this.state.currentSupplier._id,
      areaId: this.state.areaId
    };
    this.props.setCurrentData(payload);

    const getConversation = await ServiceProjects.getConversation(
      payload.supplierId,
      payload.projectId,
      payload.suppliermemberId,
      payload.areaId
    );

    if (this.props.clearNotification) {
      const currentMemberNotification = this.getMemberNotification(payload);
      this.props.clearNotification(currentMemberNotification);
    }

    let conversation = [];
    let totalFilesClient = [];
    let totalFilesUser = [];

    if (getConversation) {
      const isSupplierRole = appState.isSupplierRole();
      conversation = getConversation.conversation.map(x => {
        x.message = isSupplierRole ? this.fixOldBidFormLink(x.message, payload.suppliermemberId) : x.message;
        return ({
        message: x.message,
        name: x.adminId ? x.adminId.name : x.supplierMemberId ? x.supplierMemberId.name : "",
        email: x.adminId ? x.adminId.email : x.supplierMemberId ? x.supplierMemberId.email : "",
        phone: x.adminId ? x.adminId.phone : x.supplierMemberId ? x.supplierMemberId.phone : "",
        type: x.adminId ? "user" : "client",
        date: x.createdAt,
        avatarUrl :x.adminId ? x.adminId.avatarUrl :"",
        files: x.attachment
      })
    });
      totalFilesClient = _.flatten(
        getConversation.conversation
          .filter(x => x.supplierMemberId)
          .map(x =>
            x.attachment.map(a => ({
              ...a,
              date: x.createdAt
            }))
          )
      ); 
      totalFilesUser = _.flatten(
        getConversation.conversation
          .filter(x => x.adminId)
          .map(x =>
            x.attachment.map(a => ({
              ...a,
              user: x.adminId,
              date: x.createdAt
            }))
          )
      );
    }

    //   const totalFilesClient=[ { file: "http://excel.xls", date: "2019-01-10" },
    //   { file: "http://offert.pdf", date: "2019-01-18" },
    //   {
    //     file: "http://Skärmavbild 2019-09-01 kl. 12.05.23.jpg",
    //     date: "2019-01-18"
    //   }
    // ];

    // const totalFilesUser = [
    //   { file: "http://excel.xls", date: "2019-01-18" },
    //   { file: "information om kollektivet.doc", date: "2019-01-18" },
    //   { file: "http://uträkning.pdf", date: "2019-01-22" }
    // ];

    this.setState({
      adminUser: getConversation ? getConversation.adminId : {},
      clients,
      currentClient,
      messages: conversation,
      totalFilesUser,
      totalFilesClient,
      baseURL: basePath,
      loading: false,
    });
  }

  getAllMemberNotifications = (supplierId) => {
    const { areaId } = this.state;
    const { currentProject } = this.props;
    let notification = [];

    const area =
      currentProject &&
      currentProject.area &&
      currentProject.area.find(ar => ar && ar.areaId && ar.areaId._id === areaId);
    
      if(area && area.suppliers && area.suppliers.length){
      const supplier = area.suppliers.find(s => s.supplierId._id === supplierId);
      const supNotifications = supplier && supplier.supplierId && supplier.supplierId.notification;
      if(supNotifications) notification = supNotifications;
    }

    return notification;
  }

  getMemberNotification = (payload) => {
    const { supplierId, suppliermemberId } = payload;
    const allMemberNotifications = this.getAllMemberNotifications(supplierId);

    if (!suppliermemberId || !allMemberNotifications.length) return;

    const notification = allMemberNotifications.find(not => not.supplierMemberId === suppliermemberId);
    return notification;
  }

  goBack = () => {
    this.context.router.history.push(this.context.router.history.location.pathname.split('/').slice(0, 5).join('/'));
  }

  render() {
    const isBidForm = window.location.pathname.includes("/quotation/info");
    const supplierId = this.state.currentSupplier && this.state.currentSupplier._id
    return (
      <AppLayout hideNavbar={isBidForm}
       title={
          <BackButton 
           path={[this.state.currentProject?.name, this.state.areaName, this.state.currentSupplier?.name, `Meddelanden`]}
           onClick={this.goBack}
          />}
          >
        <div className="page-projects-single-inbox">
          <AppChat
            adminUser={this.state.adminUser}
            role={this.state.role}
            areaId={this.state.areaId}
            ref={el => this.state.appChat}
            baseURL={this.state.baseURL}
            messages={this.state.messages}
            totalFilesClient={this.state.totalFilesClient}
            totalFilesUser={this.state.totalFilesUser}
            clients={this.state.clients}
            currentSupplier={this.state.currentSupplier}
            currentProjectId={this.state.currentProject._id}
            currentClient={this.state.currentClient}
            notification={this.getAllMemberNotifications(supplierId)}
            loading={this.state.loading}
          ></AppChat>
        </div>
      </AppLayout>
    );
  }
}

//injectHistory(PageProjectsSingleInbox);
