import React from 'react';

export const LogoutIcon = () => (
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.1667 11.3333L16.5 8.00001M16.5 8.00001L13.1667 4.66668M16.5 8.00001L4.83333 8.00001M9.83333 11.3333V12.1667C9.83333 13.5474 8.71405 14.6667 7.33333 14.6667H4C2.61929 14.6667 1.5 13.5474 1.5 12.1667V3.83334C1.5 2.45263 2.61929 1.33334 4 1.33334H7.33333C8.71405 1.33334 9.83333 2.45263 9.83333 3.83334V4.66668"
      stroke="#4B5563"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
