import { CSSProperties, FC, ReactNode } from "react";
import styles from "./card.module.scss";
type Props = {
  title?: ReactNode;
  gap?: number;
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}

const Card : FC<Props> = ({ title, children, gap, className, style }) => {
  return (
    <div className={`${styles.card} ${className}`} style={gap ? {gap, display: "flex", flexDirection: "column", ...style} : style }>
      {title && (
        <div className={styles.title}>{title}</div>
      )}
      {children}
    </div>
  );
}

export default Card;