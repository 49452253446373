import React, { useEffect, useState } from 'react';
import { ServiceProjects } from "../../services/service-projects";
import PageProjectsSingleInbox from '../page-projects/page-projects-single-inbox';
import { Loading } from '../../components/shared';
import appState from '../../state/AppStateContainer';

const PageSupplierMessages = ({ projectSlug, areaSlug, supplierSlug, supplierId, supplierMemberId }) => {
  const [currentProject, setCurrentProject] = useState();
  useEffect(() => {
    (async () => {
      if (projectSlug) {
        let currentProject;
        if (appState.getUserRole() == "supplier") {
          currentProject = await ServiceProjects.getProjectBySlugForSupplier(projectSlug, supplierId);
        } else {
          let projects = await ServiceProjects.getProject(projectSlug);
          if (projects && projects.length > 0) {
            currentProject = projects[0];
          } else {
            currentProject = null;
          }
        }
        setCurrentProject(currentProject);
      }
    })();
  }, [projectSlug, supplierId]);

  return <>
    {currentProject && <>
      <PageProjectsSingleInbox 
        /*{...props}*/
        params={{type:areaSlug, supplier: supplierSlug}}
        location="supplier"
        checkRole={() => console.log("checkRole called")}
        setCurrentData={() => console.log("setCurrentData called")}
        role="supplier"
        currentProject={currentProject}
        clearNotification={()=> console.log("clearNotification called")}
        supplierMemberId={supplierMemberId}
      />
    </>}
    {!currentProject && 
      <div style={{display:"flex", width:"100%", height:"100%", justifyContent:"center", alignItems:"center"}}><Loading type="inline" /></div>
    }
  </>;
}

export default PageSupplierMessages;
