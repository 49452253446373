import Field from "../../../components/shared/Field/Field";
import { autosave, formatAmount, weeksToMilliseconds } from "../../../util";
import appState from "../../../state/AppStateContainer";
import { FC, useEffect, useState } from "react";

type Props = {
  value: string | number | undefined,
  projectSlug: string,
  rowData: {
    areaId: {
      slug: string,
    },
    purchaseCompleted: Date | undefined,
    productionStartsAt: Date | undefined,
  },
  onBlur: (value: string | number | undefined) => Promise<void>,
  isLeadTime?: boolean,
  onFocusStylesEnabled?: boolean,
  label?: string,
  className: string,
  unit?: string,
  style?: React.CSSProperties
}

const TableField: FC<Props> = ({ label, value, onBlur, isLeadTime, onFocusStylesEnabled = true, className, unit="kr", style }) => {
  let fieldValue = '';

  if (value === 0) {
    fieldValue = '0';
  } else if (value) {
    fieldValue = formatAmount(value);
  }

  const [editValue, setEditValue] = useState<string | undefined>(fieldValue);
  useEffect(() => {
    setEditValue(fieldValue);
  }, [fieldValue]);

  return appState.isReadOnlyAccessRole() && !label ? (
    fieldValue ? (
      <span style={{ whiteSpace: 'nowrap' }}>
        {isLeadTime ? (
          `${fieldValue} ${typeof value === 'number' && Math.abs(value) === 1 ? ' vecka' : ' veckor'}`
        ) : (
          `${fieldValue} ${unit}`
        )}
      </span>
    ) : null
  ) : (
    <Field
      label={label}
      style={{ minWidth: '150px', textAlign: "right", paddingRight: "0px", ...style }}
      className={className}
      onFocusStylesEnabled={onFocusStylesEnabled}
      onClick={e => e.stopPropagation()}
      value={editValue}
      disabled={appState.isReadOnlyAccessRole()}
      unit={isLeadTime ? (
        (typeof value === 'number' && Math.abs((value)) === 1) ? 'vecka' : 'veckor'
      ) : (
        unit
      )}
      onChange={value => setEditValue(formatAmount(value))}
      onBlur={async e => {
        let tempValue: string | number | undefined;
        if (isLeadTime) {
          tempValue = weeksToMilliseconds(e.target.value);
          setEditValue(e.target.value);
        } else {
          tempValue = formatAmount(e.target.value);
          setEditValue(tempValue?.toString());
        }

        if (onBlur) {
          autosave(async () => {
            await onBlur(tempValue);
          });  
        }
        
      }}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key == "Enter") {
          e.currentTarget.blur();
          e.preventDefault();
        } else if (e.key == "Escape") {
          e.currentTarget.value = fieldValue;
          e.currentTarget.blur();
          e.preventDefault();
        }
      }}
    />
  )
}

export default TableField;