import { FC, ReactNode, useEffect, useRef, useState } from "react";
import Modal from "../Modal/Modal";
import styles from './ConversationModal.module.scss';
import CustomAvatar from "../CustomAvatar";
import appState from "../../../state/AppStateContainer";
import moment from "moment";
import Editor from "../Editor/Editor";
import HTMLParser from "../HTMLParser/HTMLParser";
import * as Sentry from "@sentry/react";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
import { htmlToPlainTest } from "../../../util";

type Message = {
  content: string,
  addedBy: {
    _id: string,
    name: string,
    avatarUrl: string,
  },
  addedAt: string,
  updatedAt: string,
}

type MessageProps = {
  message: Message,
  minified?: boolean,
}

const Message: FC<MessageProps> = ({ message, minified }) => {
  const getFormattedDate = (date: string) => {
    const momentDate = moment(date).locale('sv');
    const timeDifference = momentDate.diff(moment(), 'seconds') * -1;

    if (timeDifference === 0) return 'Nu';
    if (timeDifference >= 60*60*24) return momentDate.format('YYYY-MM-DD HH:mm'); // More than a day

    return moment(date).locale('sv').fromNow();
  }

  return (
    <div className={`${styles.message} ${minified && styles.minified} ${(!minified && appState.getUserId() === message.addedBy._id) ? styles.rightAligned : ''}`}>
      {!minified && (
        <CustomAvatar
          alt={message.addedBy.name}
          src={message.addedBy.avatarUrl}
          size={"38px"}
        />
      )}
      <div className={styles.messageDetails}>
        <div>
          {!minified && <span className={styles.name}>{message.addedBy.name}</span>}
          <HTMLParser data={message.content} />
        </div>
        {!minified && <span>{getFormattedDate(message.addedAt)}</span>}
      </div>
    </div>
  )
}

type Props = {
  title: string,
  fetchConversation: () => Promise<Message[]>,
  updateConversation: (content: string) => Promise<Message[]>,
  render: (hasContent:boolean, content: ReactNode, openModal: () => void) => JSX.Element,
}

const ConversationModal: FC<Props> = ({ title, fetchConversation, updateConversation, render }) => {
  const [isModalShown, setIsModalShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputText, setInputText] = useState('');
  const [conversation, setConversation] = useState<Message[]>([]);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    (async () => {
      try {
        const conversation = await fetchConversation();

        setConversation(conversation);
      } catch (error) {
        const errorMsg = _.get(error, 'response.data.error', 'Oväntat fel (' + error + ')');
        NotificationManager.error(errorMsg, 'Misslyckat');
        Sentry.captureException(error);
      }
    })()
  }, []);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [conversation]);

  const onSubmit = async () => {
    if (!inputText || appState.isReadOnlyAccessRole()) {
      return;
    }

    setIsLoading(true);
                
    try {
      const conversation = await updateConversation(inputText);
      setConversation(conversation);
      setInputText('');
    } catch (error) {
      const errorMsg = _.get(error, 'response.data.error', 'Oväntat fel (' + error + ')');
      NotificationManager.error(errorMsg, 'Misslyckat');
      Sentry.captureException(error);
    }
    
    setIsLoading(false);
  }

  const openModal = () => {
    setInputText('');
    setIsModalShown(prevState => !prevState);
    setTimeout(() => {
      if (ref.current) {
        ref.current.style.scrollBehavior = 'auto';
        ref.current.scrollTop = ref.current.scrollHeight;
        ref.current.style.scrollBehavior = 'smooth';
      }
    }, 10);
  }

  return (
    <div className={styles.container}>
      {render(
        !!conversation[conversation.length - 1]?.content,
        htmlToPlainTest(conversation[conversation.length - 1]?.content),
        openModal,
      )}
      
      <Modal
        show={isModalShown}
        setShow={setIsModalShown}
        title={title}
        buttonInfo={!appState.isReadOnlyAccessRole() && { label: 'Lägg till', action: onSubmit, loaderShow: isLoading, disabled: !inputText }}
        hasCloseButton
        unmountWhenHidden
      >
        <div className={styles.modalContainer}>
          <div className={`${styles.conversationContainer} ${appState.isReadOnlyAccessRole() ? styles.readOnly : ''}`} ref={ref}>
            {conversation?.map((message, index) => <Message key={index} message={message} />)}
          </div>
          {!appState.isReadOnlyAccessRole() && <Editor value={inputText} onChange={setInputText} wrapperStyle={{ width: '600px', height: '200px' }} />}
        </div>
      </Modal>
    </div>
  )
}

export default ConversationModal;