import { FC, useEffect, useState } from "react";
import BtButton from "../../bt-button/bt-button";
import styles from "./NewsCard.module.scss";
import moment from "moment";
import { ServiceDashboard } from "../../../services/service-dashboard";
import appState from "../../../state/AppStateContainer";

export type NewsCardProps = {
  image: string,
  title: string,
  link?: string,
  timestamp?: string,
  website?: string,
  onFeaturedSupplierClick?: () => Promise<void>,
  description?: string,
  supplierId?: string,
  adPreviewMode?: boolean,
}

const NewsCard: FC<NewsCardProps> = ({ image, title, link, timestamp, website, onFeaturedSupplierClick, description, supplierId, adPreviewMode }) => {
  const [img, setImg] = useState(image);

  useEffect(() => {
    if (image && !img) {
      setImg(image);
    }
  }, [image]);

  const content = (
    <>
      {img ? <img src={img} onError={() => setImg('')} width='250px' /> : <div></div>}
      <div className={styles.detailsContainer}>
        {website && <span>{website}</span>}
        <div>
          {timestamp && <span>{moment(timestamp).locale('sv').format('D MMMM YYYY')}</span>}
          {(onFeaturedSupplierClick || adPreviewMode) && (
            <span>Annons</span>
          )}
        </div>
      </div>
      <p>{title}</p>
      <p className={styles.description}>{description}</p>
      {(onFeaturedSupplierClick || adPreviewMode) && !appState.isClientRole() && (
        <div className={styles.buttonContainer}>
          <BtButton color='white' size='xxs' onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (onFeaturedSupplierClick) {
              onFeaturedSupplierClick();
            }
          }}>Visa i leverantörsdatabasen</BtButton>
        </div>
      )}
    </>
  )

  return link ? (
    <a href={link} target='_blank' rel='noreferrer' className={`${styles.newsFeedCard}`} onClick={supplierId && !adPreviewMode ? () => ServiceDashboard.logClickThrough(supplierId) : () => {}}>
      {content}
    </a>
  ) : (
    <div className={`${styles.newsFeedCard}`} onClick={supplierId && !adPreviewMode ? () => ServiceDashboard.logClickThrough(supplierId) : () => {}}>
      {content}
    </div>
  )
}

export default NewsCard;