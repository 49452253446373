import axios from "axios";

export class ServiceSubCategoryController {
  async addSubArea(data) {
    try {
      const response = await axios.post("/api/sub_area/", data);
      return response.data;
    } catch (error) {
      console.error("error", error);
    }
  }

  async deleteSubArea(data) {
    try {
      const response = await axios.delete("/api/sub_area/", { data });
      return response.data;
    } catch (error) {
      console.error("error", error);
    }
  }
}

export const ServiceSubCategory = new ServiceSubCategoryController();
