import { BarList, Card, Color, Flex, Text } from "@tremor/react";
import { FC, useState } from "react";
import { formatAmount } from "../../../../util";
import styles from './SummaryBarList.module.scss';
import { ReactComponent as InfoIcon } from '../../../../assets/svg/InfoIconLight.svg';
import { UNIT } from "../../../../ts-components/page-dashboard-V2/helper";
import { Fragment } from "react";
import { Button, TextInput } from "@tremor/react";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowsExpandIcon } from "@heroicons/react/outline";
import { SearchIcon } from "@heroicons/react/solid";
import TooltipContainer from "../../TooltipContainer/TooltipContainer";

type Props = {
  title: string,
  data: {
    key?: string;
    value: number;
    name: string;
    icon?: React.JSXElementConstructor<any>;
    href?: string;
    target?: string;
    color?: Color;
  }[],
  keyColumnName: string,
  valueColumName: string,
  unit?: UNIT,
  style?: React.CSSProperties,
  infoText?: string,
  color?: Color,
}

const SummaryBarList: FC<Props> = ({ data, title, keyColumnName, valueColumName, unit, color = 'orange', style, infoText }) => {
  const percentageFormatter = (number: string): string =>
  `${number}%`;

  const amountFormatter = (value: number) => formatAmount(value ?? 0) || '0';
  let valueFormatter = amountFormatter;
  if (unit === UNIT.PERCENTAGE) {
    valueFormatter = (value: number) => percentageFormatter(amountFormatter(value));
  } else if (unit === UNIT.AMOUNT) {
    valueFormatter = (value: number) => `${amountFormatter(value)} kr`
  }

  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const filteredData = data.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );
  const closeModal = () => setIsOpen(false);
  const openModal = () => {
    setSearchQuery('');
    setIsOpen(true);
  };

  return (
    <div style={style} className={styles.summaryBarListWrapper}>
      <Card key={title}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <Text>
            {title}
          </Text>
          {infoText && (
            <TooltipContainer renderReferenceComponent={(className, ref) => <div className={className} ref={ref}><InfoIcon /></div>}>
              <div style={{ maxWidth: "300px", fontSize: "14px", color: "var(--gray-700)" }}>
                <p style={{ fontWeight: 500, color: "var(--gray-900)"}}>{title}</p>
                <p style={{marginTop: "0.5em"}}>{infoText}</p>
              </div>
            </TooltipContainer>
          )}
        </div>
        <Flex className="mt-6">
          <Text>{keyColumnName}</Text>
          <Text className="text-right">{valueColumName}</Text>
        </Flex>
        <BarList
          data={data?.slice(0, 5) ?? []}
          className="mt-2"
          color={color}
          valueFormatter={valueFormatter}
        />
        {data?.length > 5 && (
          <Button
            style={{ border: '1px solid var(--gray-300)', cursor: "pointer" }}
            icon={ArrowsExpandIcon}
            className="mt-4 w-full bg-white border-gray-200 text-gray-500 hover:bg-gray-50 hover:border-gray-300"
            onClick={openModal}
          >
            Visa mer
          </Button>
        )}
      </Card>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900 bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className="w-full max-w-xl transform overflow-hidden ring-tremor bg-white
                                    p-6 text-left align-middle shadow-tremor transition-all rounded-xl"
                >
                  <Flex alignItems="center" justifyContent="between">
                    <Text className="text-base text-gray-700 font-medium">{keyColumnName}</Text>
                    <Text className="uppercase">{valueColumName}</Text>
                  </Flex>
                  <TextInput
                    icon={SearchIcon}
                    placeholder="Sök..."
                    className="mt-6"
                    onChange={(event) => setSearchQuery(event.target.value)}
                  />
                  <div className="relative mt-4 h-[450px] overflow-y-auto">
                    <BarList
                      data={filteredData}
                      color={color}
                      className="mr-4" // to give room for scrollbar
                      showAnimation={false}
                      valueFormatter={valueFormatter}
                    />
                    <div className="sticky inset-x-0 bottom-0 p-6 bg-gradient-to-t from-white to-transparent h-20" />
                  </div>
                  <Button
                    style={{ border: '1px solid var(--gray-300)', cursor: "pointer" }}
                    className="mt-4 w-full bg-white border-gray-200 text-gray-500 hover:bg-gray-50 hover:border-gray-300"
                    onClick={closeModal}
                  >
                    Avbryt
                  </Button>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export default SummaryBarList;