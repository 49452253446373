import React from 'react';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { formatCurrency, uniqueID, getTrimmedFirstString } from '../util';
var fileDownload = require('js-file-download');

class ServiceCreditSafe {
  credibilityData = {};
  report = {
    companyIdentification: {
      basicInformation: {
        registeredCompanyName: 'Reg company name',
      },
    },
    creditScore: {
      currentCreditRating: {
        commonValue: 'A',
        commonDescription: 'string',
        creditLimit: {},
        providerValue: {
          maxValue: 'string',
          minValue: 'string',
          value: 'string',
        },
        providerDescription: 'string',
        pod: 0,
        assessment: 'string',
      },
    },
    companySummary: {
      businessName: 'string',
      country: 'AF',
      companyNumber: 'string',
      companyRegistrationNumber: 'string',
      mainActivity: {
        code: 'string',
        industrySector: 'string',
        description: 'string',
        classification: 'string',
      },
      companyStatus: {
        status: 'Active',
        description: 'string',
      },
      creditRating: {
        commonValue: 'A',
        commonDescription: 'string',
        creditLimit: {
          currency: 'AFA',
          value: 'string',
        },
        providerValue: {
          maxValue: 'string',
          minValue: 'string',
          value: 'string',
        },
      },
    },
    companyIdentification: {
      basicInformation: {
        businessName: 'string',
        registeredCompanyName: 'string',
        companyRegistrationNumber: 'string',
        country: 'AF',
        vatRegistrationNumber: 'string',
        vatRegistrationDate: '2019-08-24T14:15:22Z',
        companyRegistrationDate: '2019-08-24T14:15:22Z',
        operationsStartDate: '2019-08-24T14:15:22Z',
        commercialCourt: 'string',
        legalForm: {
          commonCode: 'string',
          providerCode: 'string',
          description: 'string',
        },
        ownershipType: 'string',
        companyStatus: {
          status: 'Active',
          description: 'string',
        },
        principalActivity: {
          code: 'string',
          industrySector: 'string',
          description: 'string',
          classification: 'string',
        },
        contactAddress: {
          type: 'string',
          simpleValue: 'string',
          street: 'string',
          houseNumber: 'string',
          city: 'string',
          postalCode: 'string',
          province: 'string',
          telephone: 'string',
          directMarketingOptOut: true,
          directMarketingOptIn: true,
          country: 'AF',
        },
      },
      contactInformation: {
        mainAddress: {
          type: 'string',
          simpleValue: 'string',
          street: 'string',
          houseNumber: 'string',
          city: 'string',
          postalCode: 'string',
          province: 'string',
          telephone: 'string',
          directMarketingOptOut: true,
          directMarketingOptIn: true,
          country: 'AF',
        },
        websites: ['string'],
      },
      otherInformation: {
        employeesInformation: [
          {
            year: 0,
            numberOfEmployees: 'string',
          },
        ],
      },
    },
  };

  getLatestReportDate = compData => {
    return (
      (compData &&
        compData.creditReport &&
        compData.creditReport.creditScore &&
        new Date(compData.creditReport.creditScore.latestRatingChangeDate).toString()) ||
      new Date().toDateString()
    );
  };

  getHighRiskSuppliers = async (result = []) => {
    if (Object.values(this.credibilityData).length && this.credibilityData.supData) {
      let highRiskSupp = this.credibilityData.supData
        .filter(s => s.comp_data && s.comp_data.highRiskBit)
        .map(s => {
          let currentDate = this.getLatestReportDate(s.comp_data);
          const notification = {
            _id: uniqueID(),
            projectId: s,
            title: `Projekt ${s.projectName}`,
            description: `${s.supplier.name} har tappat kreditvärdighet.`,
            timeStamp: currentDate,
            type: 'creditworthiness',
          };
          const newProjectNotification = {
            _id: uniqueID(),
            date: currentDate,
            notifications: [notification],
            projectId: s.projectId,
            project: { projectId: s.projectId, name: s.projectName },
          };

          const pIndex = result.findIndex(r => r.project && r.project[0].projectId === s.projectId);

          const dateWiseProjectIndex =
            pIndex > -1 &&
            result[pIndex].project &&
            result[pIndex].project.findIndex(p => p.date === currentDate);

          //=============================================
          // add new date notification in existing project
          //
          if (pIndex > -1 && dateWiseProjectIndex === -1)
            result[pIndex].project.push(newProjectNotification);
          //
          // add notification in existing project and date
          //
          else if (pIndex > -1 && dateWiseProjectIndex > -1)
            result[pIndex].project[dateWiseProjectIndex].notifications.unshift(notification);
          //
          // add fresh notification with project
          //
          else
            result.push({
              _id: uniqueID(),
              project: [newProjectNotification],
            });
        });
      return highRiskSupp;
    }

    return [];
  };

  getCredibilityData = async () => {
    if (!Object.values(this.credibilityData).length) {
      console.log('No data found... fetching data!!!');
      this.credibilityData = await this.fetchCredibilityData();
    }

    return this.credibilityData;
  };

  getCreditScore = report => {
    const ratingData = (report.creditScore && report.creditScore.currentCreditRating) || {};
    return ratingData;
  };

  getCreditRank = report => {
    const ratingData =
      (report.creditScore &&
        report.creditScore.currentCreditRating &&
        report.creditScore.currentCreditRating.commonValue) ||
      'Saknas';
    return ratingData;
  };

  getCreditRankDesc = () => {
    const ratingData = this.report.companySummary.creditRating;
    return ratingData.commonDescription;
  };

  getCreditValue = report => {
    const creditValue =
      (report.creditScore &&
        report.creditScore.currentCreditRating &&
        report.creditScore.currentCreditRating.providerValue &&
        report.creditScore.currentCreditRating.providerValue.value) ||
      '';
    return creditValue;
  };

  getCreditInfo = report => {
    return (report.creditScore && report.creditScore.currentCreditRating) || {};
  };

  getCompanyBasics = report =>
    (report && report.companyIdentification && report.companyIdentification.basicInformation) || {};

  getCompanyContactInfo = report => (report && report.contactInformation) || {};

  getCompanyOtherInfo = report =>
    (report && report.companyIdentification && report.companyIdentification.otherInformation) || {};

  getEmployeesInfo = report =>
    (report &&
      report.otherInformation &&
      report.otherInformation.employeesInformation &&
      report.otherInformation.employeesInformation[0]) ||
    {};

  getCreditRating = (withRankLabel, withRankVal) => {
    return (
      <div>
        {withRankLabel && <div className="sc-rankLabel">{this.getCreditRank()}</div>}
        {withRankVal && <div className="sc-rankLabel">{this.getCreditValue()}</div>}
        <div>{this.getCreditRankDesc()}</div>
      </div>
    );
  };

  downloadPdf = async id => {
    return axios
      .get('/api/creditSafe/report', {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/pdf',
          'Content-Type': 'application/pdf',
        },
        params: { companyId: id, pdf: true },
      })
      .then(res => {
        const fileData = res.data;
        fileDownload(fileData, 'CreditReport.pdf', 'application/pdf');
        return fileData;
      })
      .catch(error => {
        NotificationManager.error('Det gick inte att ladda ner. Försök igen senare', '', 3000);
        console.log(error, 'error in pdf download');
      });
  };

  getCreditCompanyData = async (orgNum, supplierId) => {
    const res = await axios.get(`/api/credibility/company`, { params: { regNum: orgNum, supplierId } });
    return res;
  };

  getCompanyImg = async id => {
    const res = await axios.get(`/api/creditSafe/company/image`, { params: { id } });
    return res;
  };

  isBetween = (n, a, b) => (n - a) * (n - b) <= 0;

  getRiskClass = val => {
    if (this.isBetween(val, 71, 100)) {
      return 'vLowRisk';
    } else if (this.isBetween(val, 51, 70)) {
      return 'lowRisk';
    } else if (this.isBetween(val, 30, 50)) {
      return 'moderateRisk';
    } else if (this.isBetween(val, 21, 29)) {
      return 'highRisk';
    } else if (this.isBetween(val, 1, 20)) {
      return 'vHighRisk';
    } else {
      return '';
    }
  };

  getDirectors = report => {
    return (report.directors && report.directors.currentDirectors) || [];
  };

  getDescription = report => {
    return (
      (report.companyIdentification &&
        report.companyIdentification.basicInformation &&
        report.companyIdentification.basicInformation.principalActivity) ||
      ''
    );
  };

  getNumberOfEmployee = report => {
    return (
      (report.companyIdentification &&
        report.companyIdentification.otherInformation &&
        report.companyIdentification.otherInformation.employeesInformation &&
        report.companyIdentification.otherInformation.employeesInformation[0] &&
        report.companyIdentification.otherInformation.employeesInformation[0].numberOfEmployees) ||
      ''
    );
  };

  getCreditLimit = cr => cr.currency + ' ' + formatCurrency(cr.value);

  fetchCredibilityData = async () => {
    const res = await axios.get(`/api/suppliers/credibility/winner`);
    return res.data;
  };

  suppliersCredibility = async suppliers => {
    const res = await axios.get(`/api/creditSafe/suppliersCredibility`, { params: { suppliers } });
    return res.data;
  };

  filterSynaCompanyData = compData => {
    const companyObj = compData.Objekt && compData.Objekt.Omfragad;
    const { Namnlista, Adresslista, Bolsate } = companyObj;
    let compObj = {
      kommun: '',
      address: '',
      postnumber: '',
    };
    compObj.name = (Namnlista && Namnlista.Namn && Namnlista.Namn._text) || '';
    compObj.kommun = (Bolsate && Bolsate._text && getTrimmedFirstString(Bolsate._text)) || '';

    if (Adresslista) {
      const { Adress } = Adresslista;
      compObj.address = `${(Adress && Adress.Gatabox && Adress.Gatabox._text) || ''}
${(Adress && Adress.Postnr && Adress.Postnr._text+" ") || ''}${
        (Adress && Adress.Postort && Adress.Postort._text) || ''
      }`;
    }
    return compObj;
  };

  filterCreditSafeCompanyData = compData => {
    return {
      kommun: compData.address.city,
      name: compData.name,
      address: compData.address.simpleValue,
      postnumber: compData.address.postCode,
    };
  };
}

export default new ServiceCreditSafe();
