import styles from './page-scrive-request.module.scss';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AppLayout from '../../components/layout/app-layout/app-layout';
import BackButton from '../../components/shared/BackButton/BackButton';
import BtButton from '../bt-button/bt-button';
import Collapsible from '../../components/shared/Collapsible';
import appState from '../../state/AppStateContainer';
import AddCircle from '@material-ui/icons/AddCircle';
import { FileType } from '../../models/global.model';
import { ServiceUser } from '../../services/service-user';
import { TrashIcon } from '../../components/svgs';
import AddRecipientDialog from '../../components/shared/AddRecipientDialog/AddRecipientDialog';
import { UserOptionType as UserType } from '../../components/shared/UserSelector/UserSelector';
import TooltipContainer from '../../components/shared/TooltipContainer/TooltipContainer';
import { NotificationManager } from 'react-notifications';
import Field from '../../components/shared/Field/Field';
import { ReactComponent as ClipIcon } from '../../assets/svg/clip.svg';
import { ServiceCheckExtension } from '../../services/service-check-file-extension';
import File from '../../components/File/File';
import { getFileObject } from '../../util';
import RadioButton from '../../components/shared/RadioButton/RadioButton';
import { ServiceSupplier } from '../../services/service-supplier';
import { sortArray } from '../../utils/bt-array';
import { ServiceContract } from '../../services/service-contract';
import { ServiceProjects } from '../../services/service-projects';
import _ from 'lodash';
import UserModalWithRadioButtons from './UserModalWithRadioButtons';
import EmailTemplate from '../../components/shared/EmailTemplate/EmailTemplate';
import FileUpload, { NewFile } from '../../components/shared/FileUpload/FileUpload';
import { formatAmount } from '../../util';
import Selector, { SelectorType } from '../../components/shared/Selector/Selector';
import SupplierControls from '../app-add-supplier/supplier-controls';
import ScrollToTop from '../scroll-to-top/scroll-to-top';
import { ServiceSyna } from '../../services/service-syna';
import * as Sentry from "@sentry/react";
import StyledCheckbox from '../../components/shared/StyledCheckbox/StyledCheckbox';
import serviceSupplierMonitoring from "../../services/service-supplier-monitoring";
import { isValidOrganizationNumber } from '../../utils/bt-number';
import { ManualContractService } from '../../services/service-manualContract';

type Params = {
  areaSlug: string;
  project: string;
  version: string;
};

type RouteTypes = RouteComponentProps<Params, Record<string, unknown>>;

type Props = {
  history: RouteTypes['history'];
  match: RouteTypes['match'];
  currentProject: CurrentProjectType;
};

type Area = {
  areaId: {
    slug: string;
    name: string;
    _id: string;
  };
  suppliers: Supplier[];
  contract: {
    title: string;
  };
};

type Supplier = {
  reward: boolean;
  areaName: string;
  areaId: string;
  supplierId: {
    _id: string;
    name: string;
    organizationNumber: string;
  };
};

type CurrentProjectType = {
  area: Area[];
  suppliers: Supplier[];
  name: string;
  slug: string;
  _id: string;
};

type CollapsibleTypes = {
  contract: boolean;
  internalSignatories: boolean;
  externalSignatories: boolean;
  email: boolean;
};

type UserOptionType = UserType & { isNew?: boolean, tempId?: string, signOrder?: SelectorType };
type ExternalUserType = {
  _id: string,
  name: string,
  email: string,
  phone: string,
  title: string,
  tempId?: string,
  signOrder?: SelectorType,
};

const withoutExtension = (fileName: string) => {
  if (!fileName) return fileName;
  const lastDot = fileName.lastIndexOf(".");
  return lastDot != -1 ? fileName.substring(0, lastDot) : fileName;
}

const PageScriveRequest: FC<Props> = ({ history, match, currentProject }) => {
  const [internalRecipients, setInternalRecipients] = useState<UserOptionType[]>([]);
  const [internalRecipientOptions, setInternalRecipientOptions] = useState<UserOptionType[]>([]);
  const [externalRecipients, setExternalRecipients] = useState<ExternalUserType[]>([]);
  const [externalRecipientOptions, setExternalRecipientOptions] = useState<ExternalUserType[]>([]);
  const [showAddRecipient, setShowAddInternalUser] = useState(false);
  const [showAddMember, setShowAddExternalUser] = useState(false);
  const [addRecipientDialogKey, setAddRecipientDialogKey] = useState(0);
  const [signingMethod, setSigningMethod] = useState('standard');
  const [area, setArea] = useState<Area>({
    areaId: {
      slug: '',
      name: '',
      _id: '',
    },
    suppliers: [],
    contract: {
      title: '',
    },
  });
  const [collapsibleViews, setCollapsibleViews] = useState<CollapsibleTypes>({
    contract: true,
    internalSignatories: true,
    externalSignatories: true,
    email: true,
  });
  const [file, setFile] = useState<FileType>({
    id: '',
    fileName: '',
  });
  const [title, setTitle] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [attachments, setAttachments] = useState<NewFile[]>([]);
  const [amount, setAmount] = useState("");
  const [isSupplierAlreadyMonitored, setIsSupplierAlreadyMonitored] = useState<undefined | boolean>();
  const [monitorSupplier, setMonitorSupplier] = useState(false);

  const isManualContract = match.path.split('/')[5] === 'manual-contract';

  const fetchUsers = async () => {
    const roles = await ServiceUser.getUsersWithRoles();
    const users =
      roles.length &&
      roles.reduce(
        (arr: UserOptionType[], elem: { users: UserOptionType[] }) => [...arr, ...elem.users],
        [],
      );
    const people = users.map((user: UserOptionType) => ({
      ...user,
      label: user.name,
      value: user._id,
    }));
    setInternalRecipientOptions(people);
  };

  useEffect(() => {
    (async () => {
      if (!isManualContract) {
        await fetchUsers();
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const orgNumber = area?.suppliers?.find(s => s.reward)?.supplierId?.organizationNumber;
        
      if (orgNumber && isValidOrganizationNumber(orgNumber)) {
        await ServiceSyna.getSynaReport(orgNumber);
        const monitoredSupplier = await serviceSupplierMonitoring.fetchMonitoredSupplier(orgNumber);
        setIsSupplierAlreadyMonitored(!!monitoredSupplier);
      } else {
        setIsSupplierAlreadyMonitored(false);
      }
    })();
  }, [area?.suppliers?.find(s => s.reward)?.supplierId?.organizationNumber]);

  useEffect(() => {
    const selectedArea = currentProject?.area?.find(a => a?.areaId?.slug == match.params.areaSlug);

    if (selectedArea) {
      (async () => {
        await handleSelectedArea(selectedArea);
      })();
    } else {
      errorNotification('Välj rätt kategori');
    }
  }, [currentProject]);
  
  useEffect(() => {
    const fileName = file?.fileName;
    if (fileName) {
      setTitle(withoutExtension(fileName));
    }
  }, [file]);

  const getSignOrderOptions = () => {
    const numberOfParts = internalRecipients.length + externalRecipients.length;
    const result = [];
    for (let i = 1; i <= numberOfParts; i++) {
      result.push({label: "" + i, value: "" + i});
    }
    return result;
  }

  const getValidationMessage = () => {
    const validationMessagesArray = [];
    if (!file?.id) {
      validationMessagesArray.push(`en kontraktsfil${!isManualContract ? ' (*.pdf, max 10 MB)' : ''}`);
    } else if (title?.length === 0) {
      validationMessagesArray.push('titel');
    }
    if (amount?.length === 0) {
      validationMessagesArray.push('kontraktssumma');
    }
    if (!isManualContract && internalRecipients?.length === 0) {
      validationMessagesArray.push('minst en intern avtalstecknare');
    }
    if (!isManualContract && externalRecipients?.length === 0) {
      validationMessagesArray.push('minst en extern avtalstecknare');
    }

    let str = validationMessagesArray.join(', ');
    if (str) {
      const lastIndex = str.lastIndexOf(',');
      if (lastIndex !== -1) {
        str = str.substring(0, lastIndex) + ' och' + str.substring(lastIndex + 1);
      }

      return 'Lägg till ' + str + '.';
    }

    return '';
  };

  const handleSelectedArea = async (selectedArea: Area) => {
    setArea(selectedArea);
    const winningSupplier = selectedArea?.suppliers?.find(_ => _.reward == true);

    if (!winningSupplier) {
      errorNotification('Du måste markera leverantören som vinnare i kategorin', 'Misslyckat ');
      return;
    }

    await getSuppliers(winningSupplier?.supplierId?._id);
  };

  const errorNotification = (msg: string, title?: string) => {
    NotificationManager.error(msg, title, 3000);
    goBack();
  };

  const getSuppliers = async (_id: string) => {
    const _supplierMembers = await ServiceSupplier.getAuthSupplierMembers(_id, true);
    let sortedMembers = sortArray(_supplierMembers);
    sortedMembers = sortedMembers.map((member: UserOptionType) => ({
      ...member,
      label: member.name,
      value: member._id,
    }));
    setExternalRecipientOptions(sortedMembers);
  };

  const goBack = () => {
    const url = match.url.split('/');
    url.splice(5);
    history.push(url.join('/'));
  };

  const loadFiles = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files && event?.target?.files[0];

    if (file && file?.size <= 0) {
      NotificationManager.error('Skadad fil');
      return;
    }
    if (file && file?.size > 10000000) {
      NotificationManager.error("Filen var större än 10 MB", "För stor fil");
      return;
    }
    const isValid = ServiceCheckExtension.checkFileExtension(file);
    if (!isValid) {
      NotificationManager.error(`Det gick inte att lägga till ${file?.name} (otillåten filtyp).`);
      return;
    }
    setFile(getFileObject(file));
  };

  const validateAndChangeAmount = (value: string) => {  
    setAmount(formatAmount(value));
  }
  const renderContract = () => {
    return (
      <Collapsible
        header={<strong>Kontrakt</strong>}
        isOpen={collapsibleViews.contract}
        onChangeCollapse={() =>
          setCollapsibleViews(state => ({ ...state, contract: !state.contract }))
        }
      >
        <div className={styles.contractContainer}>
          {file?.id ? (<>
            <div className={styles.inputContainer}>
              <span className={styles.label}>Kontraktsfil (*.pdf, max 10 MB)</span>
              <File
                key={file.id}
                file={file.fileId || file.fileName}
                fileName={file.fileName}
                onRemove={() =>
                  setFile({
                    id: '',
                    fileName: '',
                  })
                }
                blob={file.fileObj}
              />
            </div>
            <Field
              className="add-project-field"
              label="Titel"
              value={title}
              onChange={setTitle}
              style={{ fontSize: '14px' }}
              required
            />
          </>) : (
            <div className={styles.inputContainer}>
              <span className={styles.label}>Kontraktsfil (*.pdf)</span>
              <label style={{ letterSpacing: 'initial', color: 'var(--gray-700)' }}>
                <BtButton
                  color="white"
                  size="xxs"
                  leftIcon={
                    <ClipIcon style={{ width: '15px', height: '15px', fill: 'var(--gray-700)' }} />
                  }
                >
                  <span>Ladda upp kontrakt</span>
                  <input
                    type="file"
                    accept={'application/pdf'}
                    style={{ display: 'none' }}
                    onChange={loadFiles}
                  />
                </BtButton>
              </label>
            </div> 
          )}
          <div className={styles.inputContainer}>
            <span className={styles.label}>Bilagor (*.pdf)</span>
            <FileUpload 
              existingFiles={[]}
              setExistingFiles={() => {}}
              newFiles={attachments}
              setNewFiles={setAttachments}
              accept=".pdf"
              maxSize={10000000}
            />
          </div>
          
          {!isManualContract && (
            <>
              <span className={styles.description}><p>Varje PDF får vara maximalt 10 MB i Scrive. Detta gäller både själva kontraktet och bilagorna.</p></span>
              <div className={styles.inputContainer}>
                <span className={styles.label}>Signeringsmetod</span>
                <RadioButton
                  label="Standard"
                  value="standard"
                  checked={signingMethod === 'standard'}
                  onChange={e => setSigningMethod(e.target.value)}
                />
                <RadioButton
                  label="Bank-ID"
                  value="bankId"
                  checked={signingMethod === 'bankId'}
                  onChange={e => setSigningMethod(e.target.value)}
                />
              </div>
            </>
          )}
          <Field
            label="Kontraktssumma/uppskattad totalkostnad i SEK"
            value={amount}
            onChange={validateAndChangeAmount}
            style={{ fontSize: '14px' }}
            required
          />
          <span className={styles.description}>Används för att analysera och följa upp inköpsvolymer och skillnader mellan anbud, kontraktssumma och slutkostnad.</span>
        </div>
      </Collapsible>
    );
  };

  const renderInternalSignatories = () => {
    const onAddInternalUser = (user: UserOptionType) => {
      const isDate = !isNaN(Date.parse(user?._id));
      if (isDate) {
        user.isNew = true;
        user._id = '';
        user.tempId = Date.now() + '';
      }
      setInternalRecipients(prev => [...prev, user]);
    };
    const removeInternalUser = (user: UserOptionType) => {
      user.signOrder = undefined;
      setInternalRecipients(prev => prev.filter(x => x.isNew ? x.tempId != user.tempId : x._id != user._id));
    };
    const showAddInternalUserModal = () => {
      setAddRecipientDialogKey(prev => prev + 1);
      setShowAddInternalUser(true);
    };
    return (
      <Collapsible
        header={<strong>Interna avtalstecknare</strong>}
        isOpen={collapsibleViews.internalSignatories}
        onChangeCollapse={() =>
          setCollapsibleViews(state => ({
            ...state,
            internalSignatories: !state.internalSignatories,
          }))
        }
        contentContainerClassName={styles.recipients}
      >
        <table>
          <thead>
            <tr>
            <th>Avtalstecknare</th>
            <th>Signeringsordning</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
        {internalRecipients &&
          internalRecipients.map(recipient => (
            <tr key={recipient._id || recipient.tempId} className={styles.recipient}>
              <td>
                <strong>{recipient.name}</strong>
                <br />
                <span>{recipient.email}</span>
              </td>
              <td>
                <Selector 
                  mini
                  options={getSignOrderOptions()}
                  value={recipient.signOrder || {label: "1", value: "1"}}
                  onChange={value => {
                    if (value) {
                      setInternalRecipients(internalRecipients.map(ir => {
                        if ((ir._id || ir.tempId) == (recipient._id || recipient.tempId)) {
                          ir.signOrder = value;
                        }
                        return ir;
                      })); 
                    }
                  }}
                />
              </td>
              <td>
                <BtButton color="white" size="xxs" onClick={() => removeInternalUser(recipient)}>
                  <TrashIcon size="16px" />
                </BtButton>
              </td>
            </tr>
          ))
        }
        </tbody>
        </table>
        <BtButton class="white" size="xxs" onClick={showAddInternalUserModal}>
          <AddCircle style={{ fontSize: '16px', fill: 'var(--orange-500)' }} />
          <span>Lägg till mottagare</span>
        </BtButton>
        <AddRecipientDialog
          title='Lägg till intern avtalstecknare'
          show={showAddRecipient}
          setShow={setShowAddInternalUser}
          recipients={internalRecipients}
          userOptions={internalRecipientOptions}
          onSave={onAddInternalUser}
          key={addRecipientDialogKey}
        />
      </Collapsible>
    );
  };

  const renderExternalSignatories = () => {
    const onAddExternalUsers = (externalUser: ExternalUserType) => {

      const isDate = externalUser?._id === 'newContactPerson';
      if (isDate) {
        externalUser._id = '';
        externalUser.tempId = Date.now() + '';
      }

      setExternalRecipients(prev => [...prev, externalUser]);
    };
    const removeExternalUser = (externalUser: ExternalUserType) => {
      externalUser.signOrder = undefined;
      setExternalRecipients(prev => prev.filter(x => !x._id ? x.tempId != externalUser.tempId : x._id != externalUser._id ));
    };
    const showAddExternalUsersModal = () => {
      setAddRecipientDialogKey(prev => prev + 1);
      setShowAddExternalUser(true);
    };
    return (
      <Collapsible
        header={<strong>Externa avtalstecknare</strong>}
        isOpen={collapsibleViews.externalSignatories}
        onChangeCollapse={() =>
          setCollapsibleViews(state => ({
            ...state,
            externalSignatories: !state.externalSignatories,
          }))
        }
        contentContainerClassName={styles.recipients}
      >
        <table>
          <thead>
            <tr>
              <th>Avtalstecknare</th>
              <th>Signeringsordning</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {externalRecipients &&
              externalRecipients.map(externalRecipient => (
                <tr key={externalRecipient._id || externalRecipient.tempId} className={styles.recipient}>
                  <td>
                    <strong>{externalRecipient.name}</strong>
                    <br />
                    <span>{externalRecipient.email}</span>
                  </td>
                  <td>
                    <Selector 
                      mini
                      options={getSignOrderOptions()}
                      value={externalRecipient.signOrder || {label: "1", value: "1"}}
                      onChange={value => {
                        if (value) {
                          setExternalRecipients(externalRecipients.map(er => {
                            if ((er._id || er.tempId) == (externalRecipient._id || externalRecipient.tempId)) {
                              er.signOrder = value;
                            }
                            return er;
                          }));
                        }
                      }}
                        />
                  </td>
                  <td>
                    <BtButton
                      color="white"
                      size="xxs"
                      onClick={() => removeExternalUser(externalRecipient)}
                    >
                      <TrashIcon size="16px" />
                    </BtButton>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
        <BtButton class="white" size="xxs" onClick={showAddExternalUsersModal}>
          <AddCircle style={{ fontSize: '16px', fill: 'var(--orange-500)' }} />
          <span>Lägg till mottagare</span>
        </BtButton>
        <UserModalWithRadioButtons
          title='Lägg till extern avtalstecknare'
          show={showAddMember}
          setShow={setShowAddExternalUser}
          recipients={externalRecipients}
          users={externalRecipientOptions}
          onSubmit={onAddExternalUsers}
          key={addRecipientDialogKey}
          primaryButtonLabel="Lägg till"
        />
      </Collapsible>
    );
  };

  const renderEmailViewer = () => {
    const { name, phone } = appState.state.token;
    const orgName = appState.getCurrentOrgName();
    const projectName = currentProject.name;
    const winningSupplier = area?.suppliers?.find(_ => _.reward == true);

    return (
      <Collapsible
        header={<strong>E-postmeddelande</strong>}
        isOpen={collapsibleViews.email}
        onChangeCollapse={() => setCollapsibleViews(state => ({ ...state, email: !state.email }))}
      >
        <div className={styles.emailContainer}>
          <EmailTemplate>
            <p>Hej &lt;förnamn&gt;,</p>
            <p>
              {name} på {orgName} har bjudit in dig för att signera kontraktet {title || `[titel]`}.
            </p>

            <p> Öppna avtalet i Scrive och följ instruktionerna för att signera: </p>

            <a style={{ alignSelf: 'center', backgroundColor: 'var(--orange-500)', padding: '16px', borderRadius: '6px', cursor: 'not-allowed' }}>
              <strong style={{ textDecoration: 'none', color: 'var(--white)' }}>
                Öppna avtalet i Scrive
              </strong>
            </a>

            <div>
              <p><strong>Anbudsinformation:</strong></p>
              <ul style={{ paddingLeft: '32px', margin: 0}}>
                <li><p>Beställare: {orgName}</p></li>
                <li><p>Projekt: {projectName}</p></li>
                <li><p>Kategori: &lt;yrkeskategori&gt;</p></li>
                <li><p>Leverantör: {winningSupplier?.supplierId?.name}</p></li>
              </ul>
            </div>

            <div>
            <p><strong>Avsändare:</strong></p>
            <ul style={{ paddingLeft: '32px', margin: 0}}>
              <li><p>Namn: {name}</p></li>
              {phone ? <li><p>Telefonnummer: {phone}</p></li> : ''}
              
            </ul>
          </div>
          </EmailTemplate>
        </div>
      </Collapsible>
    );
  };

  const renderSupplierControls = () => {
    const organizationHasSyna = appState.getOrgFromUrl()?.hasSyna;
    const winningSupplierOrgNumber = area?.suppliers?.find(_ => _.reward == true)?.supplierId?.organizationNumber;
    return winningSupplierOrgNumber && (
      <Collapsible
        header={<strong>Leverantörskontroll</strong>}
        isOpen={collapsibleViews.externalSignatories}
        onChangeCollapse={() =>
          setCollapsibleViews(state => ({
            ...state,
            externalSignatories: !state.externalSignatories,
          }))
        }
        contentContainerClassName={styles.recipients}
      >
        {organizationHasSyna && <p className={styles.supplierControls}>En kreditupplysning hämtas automatiskt och sparas tillsammans med kontraktet när du skickar ut det för signering.</p>}
        <SupplierControls
          organizationNumber={winningSupplierOrgNumber}
          hasSyna={organizationHasSyna}
          className={styles.supplierControls}
          history={history}
        />
      </Collapsible>
    )
  }

  const validationMessage = getValidationMessage();

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      const winningSupplier = area?.suppliers?.find(_ => _.reward == true);
      const projectId = currentProject?._id;
      const areaId = area?.areaId?._id;
      const supplierId = winningSupplier?.supplierId?._id;
      const slug = currentProject.slug;

      let supplierControlsHTML = '';

      const supplierControlsContainer = document.getElementById('supplier-controls-container');
      if (supplierControlsContainer) {
        supplierControlsHTML = supplierControlsContainer.innerHTML;
      }

      if (isManualContract) {
        const uploadedFile = await ManualContractService.uploadContract(
          slug,
          areaId,
          title,
          file?.fileObj,
          supplierId,
          attachments.map(attachment => attachment.file),
          amount,
          monitorSupplier,
          supplierControlsHTML,
        );
        if(uploadedFile){
          NotificationManager.success(`Filen har laddats upp`, "Sparat");
          ServiceProjects.addManualContract(currentProject._id, areaId, uploadedFile);
        }
      } else {
        const response = await ServiceContract.sendContract(
          projectId,
          areaId,
          title,
          file?.fileObj,
          supplierId,
          internalRecipients,
          externalRecipients,
          signingMethod,
          attachments.map(attachment => attachment.file),
          amount,
          monitorSupplier,
          supplierControlsHTML,
        );
  
        ServiceProjects.addContract(projectId, areaId, response);
        NotificationManager.success('Kontraktet skickades till Scrive för signering', 'Skickat');
      }

      goBack();
    } catch (error) {
      const errorMsg = _.get(error, 'response.data.message', 'Oväntat fel (' + error + ')');
      NotificationManager.error(errorMsg, 'Misslyckat');
      Sentry.captureException(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <AppLayout
      title={
        <BackButton
          onClick={goBack}
          path={[currentProject.name, `${isManualContract ? "Lägg till signerat kontrakt" : "E-signering"} för ${area?.areaId?.name}`]}
        />
      }
    >
      <ScrollToTop />
      <div className={styles.container}>
        <div className={styles.content}>
          {renderContract()}
          {!isManualContract && renderInternalSignatories()}
          {!isManualContract && renderExternalSignatories()}
          {!isManualContract &&renderEmailViewer()}
          {renderSupplierControls()}
          <div style={{ alignSelf: 'flex-end', display: 'flex', alignItems: 'center', gap: '16px', }}>
            {isSupplierAlreadyMonitored !== undefined && <TooltipContainer
              renderReferenceComponent={(className, ref) =>
                <label ref={ref} className={`${className} ${styles.monitoringCheckbox} ${isSupplierAlreadyMonitored ? styles.monitored : ''}`}>
                  <StyledCheckbox
                    checked={monitorSupplier || isSupplierAlreadyMonitored}
                    onChange={() => !isSupplierAlreadyMonitored ? setMonitorSupplier(!monitorSupplier) : {}}
                  />
                  Bevaka leverantör
                </label>
              }
            >
              {isSupplierAlreadyMonitored && <div style={{ width: 'max-content', maxWidth: '310px' }}>Leverantören bevakas redan.</div>}
            </TooltipContainer>}
            <TooltipContainer
              renderReferenceComponent={(className, ref) =>
                <div ref={ref} className={className}>
                  <BtButton
                    loaderShow={submitting}
                    onClick={handleSubmit}
                    disabled={!!validationMessage}
                  >
                    {isManualContract ? 'Spara' : 'Skicka'}
                  </BtButton>
                </div>
              }
            >
              {validationMessage && <div style={{ width: 'max-content', maxWidth: '310px' }}>{validationMessage}</div>}
            </TooltipContainer>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default PageScriveRequest;
