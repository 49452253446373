import { FC } from "react";
import ImagePreview from "../../components/shared/ImagePreview/ImagePreview";
import BtButton from "../bt-button/bt-button";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import appState from "../../state/AppStateContainer";
import { ScopeOptions } from "../../models/global.model";
import { FileScope } from "../../util/appEnum";

type Props = {
  logo?: {
    file?: string,    
    name?: string,    
  }
  image?: File,
  onChange: (value: File | null) => void,
  scope: FileScope,
  scopeOptions: ScopeOptions,

}

const LogoForm : FC<Props> = ({logo, onChange, image, scope, scopeOptions}) => {  
  return (
    <div>
      <p style={{ marginTop: 0, marginBottom: '7px', fontSize: '14px', fontWeight: 500, color: 'var(--gray-700)' }}>Logotyp</p>
      <ImagePreview
        image={image}
        fileId={logo?.file}
        scope={scope}
        scopeOptions={scopeOptions}
        orgSlug={appState.getCurrentOrgSlug()}
        onRemove={() => onChange(null)}
      />
      {!(logo?.file || logo?.name) && (
        <label style={{ letterSpacing: 'normal' }}>
          <BtButton 
            color="white"
            size="xxs"
            leftIcon={<AddCircleIcon htmlColor="var(--orange-500)"/>}
            style={{ width: 'fit-content' }}
          >
            {`Lägg till logotyp`}
            <input
              type="file"
              name={'logo'}
              accept='image/png, image/gif, image/jpeg'
              onClick={event => {
                (event.target as HTMLInputElement).value = "";
              }}
              onChange={(e) => {       
                const input = e.target as HTMLInputElement;         
                if (input && input.files)
                  onChange(input.files[0]);
              }}
              style={{ display: 'none' }}
            />
          </BtButton>
        </label>
      )}
    </div>
  );
}

export default LogoForm;