import React from 'react';
import _ from 'lodash';
import { ServiceUser } from '../../services/service-user';
import '../app-select-industry-types/app-select-industry-types.css';
import '../app-chat-mail-batch/app-chat-mail-batch.css';
import '../app-select-industry-types/app-select-industry-types.css';
import appEnum from '../../util/appEnum';
import commonHelper from '../../util/commonHelper';
import { truncateString } from '../../util';
import Loading from '../../components/shared/Loading';
import BtGrid from '../bt-grid/bt-grid';
import BtRow from '../bt-row/bt-row';
import BtColumn from '../bt-column/bt-column';

//! Do not delete this file. The css files that are imported are important as some styles are used in the rest of the application.
//! We can not move the import statements because it will mess with the css order and some styles might get overwritten
export default class PageProjectUsersAreas extends React.Component {
}
