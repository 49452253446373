import React from 'react';

export default function Logo({ size = '32px', type }) {
  if(type === "logoWithTitle") {
    return(
      <svg className="logo" width={size} height={size} viewBox="0 0 307 259" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M146.019 61.984L112.924 61.847L66.461 178.674H99.616L111.049 149.684H169.172V121.505H122.284L146.019 61.984Z" fill="#374151"/>
        <path d="M169.032 0L135.951 0.0330009L208.001 178.674H241.205L169.032 0Z" fill="#374151"/>
        <path d="M24.384 257.277L22.7266 253.252H9.98999L8.37999 257.277H0.852386L16.5244 219.21L32.3854 257.277L24.384 257.277ZM12.405 247.287H20.3119L16.3819 237.532L12.405 247.287Z" fill="#374151"/>
        <path d="M65.5882 255.288C62.5683 257.521 58.8773 258.657 55.1248 258.508C52.5767 258.552 50.0453 258.088 47.6778 257.145C45.3103 256.202 43.154 254.797 41.3343 253.013C39.5145 251.229 38.0677 249.101 37.078 246.752C36.0882 244.404 35.5753 241.882 35.569 239.333C35.5627 236.785 36.0631 234.261 37.0413 231.907C38.0194 229.554 39.4556 227.419 41.2665 225.625C43.0774 223.832 45.2267 222.417 47.5895 221.462C49.9523 220.507 52.4814 220.031 55.0297 220.062C58.7474 219.903 62.4125 220.985 65.4464 223.14L62.037 228.679C59.9976 227.216 57.5395 226.452 55.03 226.501C48.26 226.501 43.0515 231.757 43.0515 239.285C43.0515 246.812 48.4492 252.068 55.1723 252.068C57.7102 252.012 60.1644 251.15 62.1796 249.606L65.5882 255.288Z" fill="#374151"/>
        <path d="M100.017 255.289C96.997 257.521 93.306 258.656 89.5535 258.508C87.0054 258.552 84.474 258.089 82.1065 257.145C79.739 256.202 77.5827 254.797 75.763 253.013C73.9433 251.229 72.4964 249.101 71.5067 246.752C70.5169 244.404 70.004 241.882 69.9977 239.333C69.9914 236.785 70.4919 234.261 71.47 231.907C72.4481 229.554 73.8844 227.419 75.6953 225.625C77.5062 223.832 79.6555 222.417 82.0183 221.462C84.3811 220.507 86.9102 220.031 89.4585 220.062C93.1762 219.902 96.8413 220.985 99.8751 223.14L96.4658 228.679C94.4262 227.216 91.9681 226.452 89.4585 226.501C82.6885 226.501 77.48 231.757 77.48 239.285C77.48 246.812 82.8776 252.068 89.6008 252.068C92.1387 252.012 94.5929 251.15 96.6081 249.606L100.017 255.289Z" fill="#374151"/>
        <path d="M107.771 221.294H115.157V245.251C115.157 249.417 117.335 251.548 120.46 251.548C123.584 251.548 125.763 249.417 125.763 245.251V221.294H133.149V245.63C133.149 253.726 127.656 258.508 120.46 258.508C113.263 258.508 107.771 253.82 107.771 245.63V221.294Z" fill="#374151"/>
        <path d="M142.935 221.294H151.837C160.927 221.294 166.278 227.022 166.278 234.503C166.387 236.502 165.93 238.492 164.958 240.242C163.986 241.993 162.54 243.434 160.786 244.399L169.165 257.277H160.406L150.321 241.699V257.277H142.935V221.294ZM151.363 227.732H150.321V241.178H151.363C156.145 241.178 158.796 238.433 158.796 234.455C158.796 230.478 156.145 227.732 151.363 227.732Z" fill="#374151"/>
        <path d="M197.526 257.277L195.87 253.252H183.133L181.523 257.277H173.996L189.666 219.21L205.529 257.277L197.526 257.277ZM185.548 247.287H193.455L189.525 237.532L185.548 247.287Z" fill="#374151"/>
        <path d="M228.825 221.294V227.922H222.007V257.277H214.62V227.922H207.802V221.294H228.825Z" fill="#374151"/>
        <path d="M253.067 220.063C256.859 220.109 260.553 221.276 263.683 223.416C266.813 225.557 269.241 228.575 270.66 232.092C272.079 235.609 272.426 239.466 271.658 243.18C270.889 246.893 269.04 250.297 266.342 252.962C263.644 255.627 260.218 257.434 256.496 258.157C252.773 258.88 248.92 258.485 245.421 257.023C241.922 255.561 238.933 253.097 236.831 249.941C234.729 246.784 233.608 243.077 233.608 239.285C233.581 236.733 234.067 234.201 235.039 231.841C236.01 229.481 237.446 227.341 239.262 225.547C241.077 223.754 243.235 222.344 245.607 221.402C247.979 220.459 250.516 220.004 253.067 220.063ZM253.067 252.068C259.791 252.068 265.047 246.813 265.047 239.285C265.047 231.756 259.981 226.501 253.067 226.501C246.297 226.501 241.088 231.757 241.088 239.285C241.088 246.812 246.344 252.068 253.067 252.068Z" fill="#374151"/>
        <path d="M280.583 221.294H289.485C298.575 221.294 303.926 227.022 303.926 234.503C304.035 236.502 303.578 238.492 302.606 240.242C301.635 241.993 300.188 243.434 298.434 244.399L306.814 257.277H298.054L287.97 241.699V257.277H280.583L280.583 221.294ZM289.011 227.732H287.97V241.178H289.011C293.793 241.178 296.445 238.433 296.445 234.455C296.445 230.478 293.793 227.732 289.011 227.732Z" fill="#374151"/>
      </svg>
    )
  }
    return (
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        width={size} height={size} viewBox="41.662 314.299 110.463 112.947"
        enableBackground="new 41.662 314.299 110.463 112.947">
        <path fill="#001659" d="M106.501,314.299l45.624,112.947h-20.99L85.588,314.32L106.501,314.299z M71.032,353.395l20.921,0.087
          L76.95,391.107h29.639v17.813H69.847l-7.227,18.326H41.662L71.032,353.395z"/>
      </svg>
    );
}
