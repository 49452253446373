import { CSSProperties, ForwardedRef, forwardRef, useEffect, useState } from "react";
import MiddleEllipsis from "react-middle-ellipsis";
import styles from './File.module.scss';
import DownloadButton from "../shared/DownloadButton";
import Spinner from "../shared/Spinner/Spinner";
import { ServiceDownload } from "../../services/service-download";
import { ReactComponent as CircleXIconFilled } from '../../assets/svg/circleXIconFilled.svg';
import { ReactComponent as FileIconBackground } from '../../assets/svg/file-icon-background.svg';
import { FileScope } from "../../util/appEnum";
import { ScopeOptions } from "../../models/global.model";
import _ from "lodash";

type Props = {
  fileName: string,
  disabled?: boolean,
  file?: string,
  url?: string,
  onRemove?: () => void,
  style?: CSSProperties,
  scope?: FileScope,
  scopeOptions?: ScopeOptions,
  blob?: Blob,
  className?: string,
}

const File = forwardRef(function File({ style, fileName = '', disabled, file, url, onRemove, scope, scopeOptions, blob, className, }: Props, ref: ForwardedRef<HTMLDivElement>) {
  const [icon, setIcon] = useState('');

  const checkImage = (src: string, bad: () => void) => {
    const img = new Image();
    img.onerror = bad;
    img.src = src;
  }

  const getFileExtension = (fileName: string) => {
    return fileName?.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
  }

  useEffect(() => {
    const images = importAll(require.context('../../assets/file-icons/', false, /\.(png|jpe?g|svg)$/));
    const tempFileIcon = images[`${getFileExtension(fileName.toLowerCase())}.svg`];
    const tempIcon = tempFileIcon ? tempFileIcon : images['blank.svg'];
    setIcon(tempIcon);
    checkImage(tempIcon, () => {
      setIcon(images['blank.svg']);
    });
  }, [])


  const importAll = (source: { keys: () => string[] } & ((filename: string) => string)) => {
    const images: { [filename: string]: string } = {};
    source.keys().forEach(item => { images[item.replace('./', '')] = source(item); });
    return images
  }

  const getFilename = (file: string) => {
    return file?.slice(((file.lastIndexOf("/") - 1) >>> 0) + 2) || file;
  }

  return (
    <DownloadButton
      disabled={disabled}
      url={url || (file && scope && !_.isEmpty(scopeOptions) && ServiceDownload.getFileDownloadURL(file, scope, scopeOptions)) || ''}
      blob={blob}
      fileName={fileName}
      render={({ downloading, progress, onClick }) => (
        <div ref={ref} className={`${className} ${styles.container} ${disabled ? styles.disabled : ''}`} style={style} onClick={onClick}>
          {onRemove && (
            <CircleXIconFilled
              onClick={e => {
                e.stopPropagation();
                onRemove();
              }}
              className={styles.removeIcon}
            />
          )}
          <div className={styles.fileIconContainer}>
            <FileIconBackground />
            <img src={icon} />
          </div>
          <div className={styles.textContainer} style={{ width: !downloading ? '95%' : 'calc(95% - 40px)' }}>
            <MiddleEllipsis>
              <span>{getFilename(fileName)}</span>
            </MiddleEllipsis>
            <span>{getFileExtension(fileName?.toLowerCase())?.toUpperCase()}</span>
          </div>

          {downloading && <Spinner isHidden={!downloading} size='xxs' progress={progress} />}
        </div>
      )}
    />
  )
})

export default File;