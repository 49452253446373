import styles from './page-erfa-request.module.scss';
import { FC, SetStateAction, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AppLayout from '../../components/layout/app-layout/app-layout';
import BackButton from '../../components/shared/BackButton/BackButton';
import BtButton from '../bt-button/bt-button';
import Collapsible from '../../components/shared/Collapsible';
import Editor from '../../components/shared/Editor/Editor';
// import EmailFooter from '../../components/shared/EmailFooter/EmailFooter';
import appState from '../../state/AppStateContainer';
import TitleWrapper from '../../components/shared/TitleWrapper/TitleWrapper';
import DraggableDropdown from '../../components/shared/DraggableDropdown/draggable-dropdown';
import AddCircle from '@material-ui/icons/AddCircle';
import { OptionType } from '../../models/global.model';
import { ServiceErfa } from '../../services/service-erfa';
import { ServiceUser } from '../../services/service-user';
import { TrashIcon } from '../../components/svgs';
import StyledCheckbox from '../../components/shared/StyledCheckbox/StyledCheckbox';
import AddRecipientDialog from '../../components/shared/AddRecipientDialog/AddRecipientDialog';
import { UserOptionType } from '../../components/shared/UserSelector/UserSelector';
import TooltipContainer from '../../components/shared/TooltipContainer/TooltipContainer';
import { NotificationManager } from "react-notifications";
import EmailTemplate from '../../components/shared/EmailTemplate/EmailTemplate';

type Params = {
  areaSlug: string;
  project: string;
  version: string;
};

type RouteTypes = RouteComponentProps<Params, Record<string, unknown>>;

type Props = {
  history: RouteTypes['history'];
  match: RouteTypes['match'];
  currentProject: CurrentProjectType;
}
type Area = {
  reward: boolean,
  areaName: string,
  areaId: string,
  supplierId: {
    _id: string,
    name: string
  }
};
type CurrentProjectType = {
  area: {
    suppliers: Area[]
  }[],
  name: string,
  slug: string,
}

export const getValidationMessage = (areas: string[], recipients: UserOptionType[], questions: OptionType[]) => {
  const validationMessagesArray = [];
  if (areas?.length === 0) {
    validationMessagesArray.push('minst en leverantör att utvärdera');
  }
  if (recipients?.length === 0) {
    validationMessagesArray.push('minst en mottagare');
  }
  if (questions?.length === 0) {
    validationMessagesArray.push('minst en fråga');
  }
  const questionCounts = questions.map(o => o?.label.toLowerCase().trim() ?? "").reduce((map, key) => map.set(key, (map.get(key) ?? 0) + 1), new Map<string, number>());
  const duplicateQuestions = Array.from(questionCounts.values()).find(count => count > 1);
  const emptyQuestions = questionCounts.get("") ?? 0;
  const questionValidation = [
    duplicateQuestions ? "Samma fråga finns med flera gånger." : "",
    emptyQuestions == 1 ? "En fråga är tom." : "",
    emptyQuestions > 1 ? "Flera frågor är tomma." : "",
  ].filter(x => x).join(" ");


  let str = validationMessagesArray.join(', ');
  if (str) {
    const lastIndex = str.lastIndexOf(',');
    if (lastIndex !== -1) {
      str = str.substring(0, lastIndex) + ' och' + str.substring(lastIndex + 1);
    }

    return ('Välj ' + str + '. ' + questionValidation).trim();
  }

  return questionValidation;
}

const PageErfaRequest: FC<Props> = ({history, match, currentProject}) => {
  const [questions, setQuestions] = useState<OptionType[]>([]);
  const [questionsOpen, setQuestionsOpen] = useState(true);
  const [questionOptions, setQuestionOptions] = useState<OptionType[]>([]);
  const [recipientsOpen, setRecipientsOpen] = useState(true);
  const [recipients, setRecipients] = useState<(UserOptionType[])>([]);
  const [userOptions, setUserOptions] = useState<UserOptionType[]>([]);
  const [showAddRecipient, setShowAddRecipient] = useState(false);
  const [addRecipientDialogKey, setAddRecipientDialogKey] = useState(0);
  const [areAreasOpen, setAreAreasOpen] = useState(true);
  const [areas, setAreas] = useState<Area[]>([]);
  const [selectedAreas, setSelectedAreas] = useState<string[]>([]);
  const [isEmailOpen, setIsEmailOpen] = useState(true);
  const [emailText, setEmailText] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const fetchQuestions = async () => {
    const options = await ServiceErfa.getQuestions();
    setQuestionOptions(options.map((o: {_id: string, question: string }) => ({value: o._id, label: o.question})));      
  };

  const fetchUsers = async () => {
    const roles = await ServiceUser.getUsersWithRoles();
    let users = roles.length && roles.reduce((arr : UserOptionType[], elem : {users : UserOptionType[]}) => [...arr, ...elem.users], []);
    const people = users.map((user: UserOptionType) => ({ ...user, label: user.name, value: user._id }));
    setUserOptions(people);
  }

  useEffect(() => {
    (async () => {
      await fetchQuestions();
      await fetchUsers();
    })();
  }, [])

  useEffect(() => {
    const temp: Area[] = []; 
    currentProject.area.forEach(area => area.suppliers.forEach(supplier => {
      if (supplier.reward) {
        temp.push(supplier);
      }
    }));
    setAreas(temp);
  }, [currentProject])
  
  const goBack = () => {
    const url = match.url.split('/');
    url.splice(4);
    history.push(url.join('/'));
  };

  const renderSuppliers = () => {
    const isAreaSelected = (id: string) => {
      return selectedAreas.includes(id);
    }

    return <Collapsible
      header={<strong>Välj leverantörer som ska utvärderas</strong>}
      isOpen={areAreasOpen}
      onChangeCollapse={() => setAreAreasOpen(oldState => !oldState)}
    >
      <div className={styles.supplierList}>
        {areas.map(area => {
          return (
            area.areaId && <label key={area.areaId}>
              <StyledCheckbox 
                checked={isAreaSelected(area.areaId)}
                onChange={() => {
                  setSelectedAreas(oldState => {
                    let temp = [...oldState];
                    const supplierId = area.areaId;
                    if (temp.includes(supplierId)) {
                      temp = temp.filter(id => id !== supplierId)
                    } else {
                      temp.push(supplierId);
                    }
                    return temp;
                  })
                }}
              />
              <div>
                <span>{area.areaName}</span>
                <span>{area.supplierId.name}</span>
              </div>
            </label>
          )}
        )}
      </div>
    </Collapsible>;
  }

  const renderRecipients = () => {
    const onAddRecipient = (user: UserOptionType) => {
      setRecipients(prev => [...prev, user]);
    }
    const removeRecipient = (user: UserOptionType) => {      
      setRecipients(prev =>  prev.filter(x=>x._id != user._id));
    }
    const showAddRecipientModal = () => {
      setAddRecipientDialogKey(prev => prev + 1);
      setShowAddRecipient(true);
    }
    return <Collapsible
      header={<strong>{recipients?.length ?? 0} mottagare</strong>}
      isOpen={recipientsOpen}
      onChangeCollapse={() => setRecipientsOpen(prev => !prev)}      
      contentContainerClassName={styles.recipients}
    >
      {recipients && recipients.map(recipient => 
        <div key={recipient.email} className={styles.recipient}>          
          <div>
            <strong>{recipient.name}</strong><br/>
            <span>{recipient.email}</span>
          </div>
          <div>
            <BtButton color="white" size="xxs" onClick={() => removeRecipient(recipient)}>
              <TrashIcon size="16px" />
            </BtButton>
          </div>
        </div>
      )}
      <BtButton class='white' size='xxs' onClick={showAddRecipientModal}>
        <AddCircle style={{ fontSize: '16px', fill: 'var(--orange-500)' }} />
        <span>Lägg till mottagare</span>
      </BtButton>
      <AddRecipientDialog
        show={showAddRecipient}
        setShow={setShowAddRecipient}
        recipients={recipients}
        userOptions={userOptions}
        onSave={onAddRecipient}
        key={addRecipientDialogKey}
      />
      
    </Collapsible>;
  }

  const renderQuestions = () => {
    const list = questions;
    const addField = (
      _list: OptionType[],
      setList: (value: SetStateAction<OptionType[]>) => void
    ) => {
      setList((prevState: OptionType[]) => [...prevState, { label: '', value: Date.now().toString() }]);
    };

    return <Collapsible
      header={<strong>Utvärdering</strong>}
      isOpen={questionsOpen}
      onChangeCollapse={() => setQuestionsOpen(prev => !prev)}
      contentContainerClassName={styles.questions}
    >
      <TitleWrapper title="VÄLJ FRÅGOR" className={styles.titleWrapper}>
        <div style={{ position: 'relative' }}>
          <div style={{ marginLeft: '-32px', marginRight: '-32px' }}>
            <DraggableDropdown
              options={questionOptions}
              list={list}
              onChange={setQuestions}
            />
          </div>

          <BtButton class='white' size='xxs' onClick={() => addField(list, setQuestions)}>
            <AddCircle style={{ fontSize: '16px', fill: 'var(--orange-500)' }} />
            <span>Lägg till fråga</span>
          </BtButton>
        </div>
      </TitleWrapper>
    </Collapsible>;
  }

  const renderEmailViewer = () => {
    const { name, phone } = appState.state.token;
    const orgName = appState.getCurrentOrgName();
    const projectName = currentProject.name;

    return <Collapsible
      header={<strong>E-postmeddelande</strong>}
      isOpen={isEmailOpen}
      onChangeCollapse={() => setIsEmailOpen(oldState => !oldState)}
    >
      <div className={styles.emailContainer}>
        <EmailTemplate>
          <p>Hej &lt;förnamn&gt;,</p>
          <p>{name} på {orgName} har bjudit in dig att fylla i erfarenhetsdokumentet för &lt;yrkeskategori&gt; till projektet {projectName} gällande hur &lt;leverantör&gt; har skött sig som leverantör. Ditt svar sparas i vår leverantörsdatabas och hjälper oss att göra smartare inköp i framtiden. Följ länken nedan för att öppna formuläret:</p>

          <a style={{ alignSelf: 'center', backgroundColor: 'var(--orange-500)', padding: '16px', borderRadius: '6px', cursor: 'not-allowed' }}>
            <strong style={{ textDecoration: 'none', color: 'var(--white)' }}>
              Öppna formuläret
            </strong>
          </a>

          <p>Tack för att du hjälper oss att bli bättre!</p>

          <div>
            <p><strong>Avsändare:</strong></p>
            <ul style={{ paddingLeft: '32px', margin: 0}}>
              <li><p>Projekt: {projectName}</p></li>
              <li><p>Kategori: &lt;yrkeskategori&gt;</p></li>
            </ul>
          </div>

          <div>
            <p><strong>Avsändare:</strong></p>
            <ul style={{ paddingLeft: '32px', margin: 0}}>
              <li><p>Namn: {name}</p></li>
              {phone ? <li><p>Telefonnummer: {phone}</p></li> : ''}
              <li><p>Företag: {orgName}</p></li>
            </ul>
          </div>

          <div>
            <p className={styles.bold} style={{ marginBottom: '10px' }}>Meddelande från avsändaren (valfritt):</p>
            <Editor value={emailText} onChange={setEmailText} wrapperStyle={{ height: '400px' }} />
          </div>
        </EmailTemplate>
      </div>
    </Collapsible>;
  }

  const validationMessage = getValidationMessage(selectedAreas, recipients, questions);

  const handleSubmit = async () => {
    setSubmitting(true);
    const suppliers = selectedAreas.map(selectedAreaId => areas.find(area => area.areaId === selectedAreaId));

    const _questions = questions.map(q => {
      const existing = questionOptions.find(o => o?.label === q?.label);
      return { 
        value: existing?.value ?? "new", 
        label: q?.label, 
        __isNew__: !existing,
      };
    });

    const erfaObj = {
      questions: _questions,
      users: recipients,
      suppliers: suppliers,
      projectSlug: currentProject.slug,
      emailText,
    }

    await ServiceErfa.submitErfa(erfaObj);
    setSubmitting(false);
    NotificationManager.success(`Skickat`);
    goBack();
  }

  return (
    <AppLayout title={
      <BackButton
        onClick={goBack}
        path={[currentProject.name, `Erfarenhetsåterföring`]}      
      />
    }>
      <div className={styles.container}>
        <div className={styles.content}>
          {renderSuppliers()}
          {renderRecipients()}
          {renderQuestions()}
          {renderEmailViewer()}
          <TooltipContainer
            renderReferenceComponent={(className, ref) => (
              <div style={{ alignSelf: 'flex-end' }} ref={ref} className={className}>
                <BtButton
                  loaderShow={submitting}
                  onClick={handleSubmit}
                  disabled={!!validationMessage}
                >
                  Skicka
                </BtButton>
              </div>
          )}>
            {validationMessage && (
                <span style={{ maxWidth: '310px', width: 'max-content' }}>{validationMessage}</span>
            )}
          </TooltipContainer>
        </div>
      </div>
    </AppLayout>
  );
}

export default PageErfaRequest;
