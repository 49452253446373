import axios from "axios";
import BaseService from "./baseService";
import { truncateStringMiddle } from "../util";
import FileDownload from "js-file-download";

export class ServiceExcelController extends BaseService {
  
  async exportExcel(data) {
    const response = await axios.post('/api/excel/export', data, {
      responseType: "blob"
    });
    // if (response.status == 200) {
    const buf = response.data;
    FileDownload(buf, `${truncateStringMiddle(data.label)}.xlsx`, "application/vnd.openxmlformats");
    // }
    // return response.data;
  }

  async uploadExcel(file, slug, areaId) {
    const payload = {
      areaId,
      file
    };
    const formData = new FormData();
    for (let key in payload) {
      formData.append(key, payload[key]);
    }
    const response = await axios.post("/api/attachment/" + slug, formData);
    return response.data;
  }
}

export const ServiceExcel = new ServiceExcelController();
