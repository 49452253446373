import { FC, ReactNode } from "react";

import './bt-center.css';

type Props = {
  className?: string, 
  children: ReactNode,
}

const BtCenter : FC<Props> = ({ className, children }) => {
  return (
    <div className={`bt-center ${className}`}>{children}</div>
  );
}

export default BtCenter;