export class APPEnum {

  DeliveryStatus = {
    Seen: "seen",
    Sent: "sent"
  };

  ProjectChangeItemType = {
    WinnerAdded: 0,
    WinnerRemoved: 1,
    PriceUpdated: 2,
    EndDateUpdated: 3
  };

  Entreprenad = {
    shared: "Utförandeentreprenad",
    general: "Totalentreprenad",
    other: "Övrigt"
  };

  Role = {
    SuperAdmin: "superAdmin",
    Admin: "admin",
    Supplier: "supplier",
    Management: "Management",
    Inköp: "Inköp",
    Kalkyl: "Kalkyl",
    Client: "Client"
  };

  ProjectRole = {
    Inköp: "Inköp",
    Projektledare: "Projektledare",
    Kalkyl: "Kalkyl",
    Entreprenadingenjör: "Entreprenadingenjör",
    Projektingenjör: "Projektingenjör",
    Platschef: "Platschef",
    Projektansvarig: "Projektansvarig",
    Projektchef: "Projektchef",
    Arbetschef: "Arbetschef",
    Arbetsledare: "Arbetsledare"
  };

  PriceType = {
    Hourly: "hourly",
    TotalPrice: "totalPrice"
  };

  ProjectCost = {
    1: "0–50",
    2: "50–100",
    3: "100–150",
    4: "150–200",
    5: "200–250",
    6: "250–350",
    7: "350–450",
    8: "450–550",
    9: "550–700",
    10: "700–800",
    11: "800–1000",
    12: "1000–"
  };
  userPrefrences = {
    projectTable: "projectTable",
    projectFilter: "projectFilter",
    projectOverview: "projectOverview",
    listViewBeta: "listViewBeta",
  }

  getObjectKeyValue(obj: any) {
    if (!obj) return null;

    const arr = Object.keys(obj).map(k => ({ key: k, value: obj[k] }));

    return arr;
  }

  getEntreprenadDataSource() {
    return this.getObjectKeyValue(this.Entreprenad);
  }

  getProjectCostDataSource() {
    return this.getObjectKeyValue(this.ProjectCost);
  }

  getProjectRoleDataSource() {
    return this.getObjectKeyValue(
      this.ProjectRole,
    );
  }

  getProjectCostValueByKey(key: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12) {
    return this.ProjectCost[key];
  }
  getEntreprenadCostValueByKey(key: 'shared' | 'general' | 'other') {
    return this.Entreprenad[key];
  }
}

export default new APPEnum();


export enum FileScope {
  ProjectConversation = 'project-conversation',
  InternalConversation = 'internal-conversation',
  SupplierFiles = 'supplier-files',
  Contract = 'contract',
  ContractAttachment = 'contract-attachment',
  BidRequestAttachment = 'bid-request-attachment',
  BidResponseAttachment = 'bid-response-attachment',
  ProjectEvaluation = 'project-evaluation',

  OrganizationLogo = 'organization-logo',
  SupplierLogo = 'supplier-logo',
  FeaturedSupplierImage = 'featured-supplier-image',
  UserAvatar = 'user-avatar',
  FeaturedAdImage = 'featured-ad-image',
  SupplierProfileLogo = "supplier-profile-logo",
  SupplierProfileFile = "supplier-profile-file",
  SalesOrgContract = 'sales-org-contract',
  SalesOrgApplicationContract = 'sales-org-application-contract',
}

export enum PrequalificationDecision {
  APPROVED = "APPROVED",
  INBOX = "INBOX",
  REJECTED = "REJECTED",
}

export enum SavingState {
  HIDDEN = "HIDDEN",
  SAVING = "SAVING",
  DONE = "DONE",
}

export enum AdApplicationStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export enum AdApplicationType {
  RECOMMENDED = "RECOMMENDED",
  FEATURED = "FEATURED",
}

export enum SlackChannels {
  SUPPLIERS = "SUPPLIERS",
  INSURANCE = "INSURANCE",
}