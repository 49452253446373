import React from 'react';
import { CustomAvatar } from '../../components/shared';
import { areEqual } from '../../util';
import BtColumn from '../bt-column/bt-column';
import BtGrid from '../bt-grid/bt-grid';
import BtRow from '../bt-row/bt-row';
import './app-chat-person.css';

const AppChatPerson = props => {
  const { name, avatar, email, phone, small, hasConversation = true } = props;
  return (
    <BtGrid
      className={`
            ${hasConversation ? 'has-conversation' : ''}
            ${small ? 'small' : ''}`}
    >
      <BtGrid className="bt-contract">
        <BtRow>
          <BtColumn className="bt-vcenter">
            <CustomAvatar size={'50px'} alt={name} src={avatar} className="org-switcher-avatar" />
          </BtColumn>
          <BtColumn className="bt-vcenter" style={{ marginLeft: '5px' }}>
            <div className="wrapper">
              <div className="body">{name}</div>
              <div className="foot">{email}</div>
              <div className="foot">{phone}</div>
            </div>
          </BtColumn>
        </BtRow>
      </BtGrid>
    </BtGrid>
  );
};

export default React.memo(AppChatPerson, areEqual);
