import { FC, useEffect, useState } from "react";
import AppLayout from "../../components/layout/app-layout/app-layout";
import BackButton from "../../components/shared/BackButton/BackButton";
import QuotationForm, { VersionData } from "../page-quotation/QuotationForm/QuotationForm";
import { ServiceQuotation } from "../../services/service-quotation";
import { RouteComponentProps } from "react-router-dom";
import styles from "./page-public-bid-request.module.scss";

type Params = {
  projectId: string;
  areaId: string;
  orgId: string;
}

type RouteTypes = RouteComponentProps<Params, Record<string, unknown>>;


type Props = {
  history: RouteTypes['history'];
  match: RouteTypes['match'];
}

const PagePublicBidRequest: FC<Props> = ({ match, history }) => {
  const [loading, setLoading] = useState(false);
  const [quotationVersions, setQuotationVersions] = useState<VersionData[]>([]);
  const [projectName, setProjectName] = useState('');
  const [areaName, setAreaName] = useState('');

  const { projectId, areaId, orgId } = match.params;

  useEffect(() => {
    setLoading(true);
    ServiceQuotation.fetchPublicQuotation({ projectId, areaId, orgId }).then(data => {
      const { area, project } = data[0]?.quotation ?? {};
      setProjectName(project.name);
      setAreaName(area.name);
      setQuotationVersions(data);
    }).finally(() => setLoading(false));
  }, []);

  return (
    <AppLayout title={<BackButton key={'x'} onClick={() => {
      const url = match.url.split('/');
      url.splice(3);
      history.push(url.join('/'));
    }} path={[projectName, `Anbud för ${areaName}`]} />}>
      <div className={styles.container}>
        {!loading && (
          <>
            <div className={styles.content}>
              {quotationVersions && <QuotationForm key={projectName} quotationVersions={quotationVersions} isPublic />}
            </div>
          </>
        )}
      </div>
    </AppLayout>
  );
}

export default PagePublicBidRequest;