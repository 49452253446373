import axios from "axios";
import { BehaviorSubject } from "rxjs";
import { ServiceProjectType } from "./service-project-type";
import { btSort } from "../utils/bt-array";
import { post, put } from "../utils/bt-http";
import { apiUrl } from "../global/app";
import BaseService from "./baseService";
//import update from 'immutability-helper';
import configHelper from '../util/configHelper';
import io from 'socket.io-client';

export class ServiceChatController extends BaseService {
  data$ = new BehaviorSubject([]);
  //dataCurrent$ = new BehaviorSubject({ areas: [] });
  storage = [];
  current;

  async getNotifications() {
    const response = await axios.get("/api/internal/chat-notifications");
    this.storage = response.data;
    return this.storage;
  }

  async updateNotifications(id) {
    const i = await this.storage.findIndex(c => c.fromUserId == id);
    if(i > -1){
      this.storage[i].count++
    }else{
      this.storage.push({
        count: 1,
        fromUserId: id
      })
    }
    return this.storage;
  }

  async clearNotifications(id) {
    const i = await this.storage.findIndex(c => c.fromUserId == id);
    if(i > -1){
      this.storage[i].count = 0
    }
    return this.storage;
  }

  async getChatMessages(slug) {
    const response = await axios.get("/api/internal/chat", {
      params: { slug }
    });
    return response.data;
  }

  async setChatDeliveryStatus(slug, deliveryStatus) {
    const response = await axios.put(`/api/internal/chat/${slug}/deliveryStatus`, 
      { deliveryStatus }
    );
    return response.data;
  }

  async sendMessage(toUserId, message, fileData) {
    try {
      const data = {
        toUserId,
        message,
        fileData
      };

      const formData = new FormData();
      for (let key in data) {
        if (key === "fileData") {
          for (const file of data.fileData) {
            formData.append(key, file);
          }
        } else {
          formData.append(key, data[key]);
        }
      }

      const response = await axios.put("/api/internal/chat", formData);
      return response.data;
    } catch (error) {
      console.error("error", error);
    }
  }

  createSocket(token) {
    return io(configHelper.getBaseAPIPath(), {auth: { token }});    
  }
}

export const ServiceChat = new ServiceChatController();
