import { FC, useEffect, useState } from 'react';
import File from '../../components/File/File';
import styles from './ChatInput.module.scss';
import { FileClip } from '../../components/svgs';
import { ReactComponent as SendMessageIcon } from '../../assets/svg/send-message.svg';
import BtButton from '../bt-button/bt-button';
import appState from '../../state/AppStateContainer';

type Props = {
  onSubmit?: (data: {message: string, files: File[]}) => void;
  resizeScroller: () => void;
}

type FileAndKey = {
  file: File;
  key: string;
}

const ChatInput: FC<Props> = ({ onSubmit, resizeScroller }) => {
  const [message, setMessage] = useState('');
  const [files, setFiles] = useState<FileAndKey[]>([]);
  useEffect(() => {
    resizeScroller();
  }, [files]);

  const url = appState._getCurrentOrgUrl();
  const isInternScreenAndAdmin = window.location.pathname.includes(`${url}/chat`) && appState.isSuperAdmin();

  const onClick = () => {
    if (isInternScreenAndAdmin) return;

    if (onSubmit) {
      onSubmit({
        message: message || "",
        files: files.map(fileAndKey => fileAndKey.file),
      });
      setMessage("");
      setFiles([]);
    }
  }

  return (
    <div className={`${styles.container} ${message ? styles.hasContent : ''}`}>
      <textarea className={styles.input} value={message} rows={1}
      onInput={e => {
        const el = e.target as HTMLElement; 
        el.style.height = "";
        el.style.height = el.scrollHeight + "px";
        resizeScroller();
      }} 
      onChange={(e) => setMessage(e.target.value)} placeholder='Skriv meddelande...' disabled={isInternScreenAndAdmin}></textarea>
      <div className={styles.bottomContainer}>
        <div className={`${styles.fileContainer} ${files.length > 0 ? styles.hasFilesSelected : ''}`}>
          {files?.map((fileAndKey, index) => (
            <File
              key={fileAndKey.key}
              fileName={fileAndKey.file.name}
              file={fileAndKey.file.name}
              onRemove={() => {
                setFiles(files.filter((_, i) => index !== i))
              }}
              blob={fileAndKey.file}
            />))
          }
          <label className={`${styles.fileUpload} ${isInternScreenAndAdmin ? styles.fileUploadDisabled : ''}`}>
            <BtButton color='gray' style={{ height: '50px' }} leftIcon={<FileClip />} disabled={isInternScreenAndAdmin}>Bifoga fil</BtButton>
            <input disabled={isInternScreenAndAdmin} type='file' style={{ display: 'none' }} multiple onChange={(e) => {
              if (e?.target?.files?.length) {
                const newFiles = Array.from(e.target.files).map(file => ({file: file, key: Date.now().toString() + "L" + file.lastModified}));                
                setFiles([...files, ...newFiles]);
                e.target.value = '';
              }
            }
            }/>
          </label>
        </div>
        <BtButton class={styles.sendButton} color='gray' onClick={onClick} disabled={isInternScreenAndAdmin} cy="send">
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <SendMessageIcon /><span>Skicka</span>
          </div>
        </BtButton>
      </div>
  </div>
  )
}

export default ChatInput;