import { FC } from "react";
import styles from './AreaTab.module.scss'
import { TEMPLATE_OPTION_IDS } from "../../helper";

type Props = {
  templateItemRenderer: (id: TEMPLATE_OPTION_IDS) => JSX.Element,
}

const AreaTab: FC<Props> = ({ templateItemRenderer }) => {
  return (
    <div className={styles.container}>
      {templateItemRenderer('2')}
      {templateItemRenderer('2a')}
      {templateItemRenderer('5')}
      {templateItemRenderer('5a')}
      {templateItemRenderer('6')}
      {templateItemRenderer('6a')}
      {templateItemRenderer('7')}
      {templateItemRenderer('7a')}
      {templateItemRenderer('8')}
      <div></div>
      {templateItemRenderer('9')}
      {templateItemRenderer('9a')}
      {templateItemRenderer('10')}
      {templateItemRenderer('10a')}
      {templateItemRenderer('11')}
      {templateItemRenderer('11a')}
      {templateItemRenderer('12')}
      {templateItemRenderer('12a')}
      {templateItemRenderer('13')}
      {templateItemRenderer('13a')}
    </div>
  )
}

export default AreaTab;