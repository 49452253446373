import { BadgeDelta, Card, Metric, Text } from "@tremor/react";
import { formatAmount } from "../../../../util";
import { FC } from "react";
import { ReactComponent as InfoIcon } from '../../../../assets/svg/InfoIconLight.svg';
import { UNIT } from "../../../../ts-components/page-dashboard-V2/helper";
import './SummaryCard.css';
import TooltipContainer from "../../TooltipContainer/TooltipContainer";

type Props = {
  title?: string,
  value?: string | number,
  value2?: string | number,
  infoText?: string,
  unit?: UNIT,
  badgePercentage?: number,
}

const SummaryCard: FC<Props> = ({ title, value, value2, infoText, unit, badgePercentage }) => {
  const percentageFormatter = (number: string): string =>
  `${number}%`;

  let metric = formatAmount(value ?? 0) || '0';
  if (unit === UNIT.PERCENTAGE) {
    metric = percentageFormatter(metric);
  } else if (unit === UNIT.AMOUNT) {
    metric = `${metric} kr`
  }

  let metric2 = formatAmount(value2 ?? 0) || '0';
  if (unit === UNIT.PERCENTAGE) {
    metric2 = percentageFormatter(metric2);
  } else if (unit === UNIT.AMOUNT) {
    metric2 = `${metric2} kr`
  }

  const metricText = value2 !== undefined ? `${metric} / ${metric2}` : metric;

  return (
    <div>
      <Card style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <Text>
            {title}
          </Text>
          {infoText && (
            <TooltipContainer renderReferenceComponent={(className, ref) => <div className={className} ref={ref}><InfoIcon /></div>}>
              <div style={{ maxWidth: "300px", fontSize: "14px", color: "var(--gray-700)" }}>
                <p style={{ fontWeight: 500, color: "var(--gray-900)"}}>{title}</p>
                <p style={{marginTop: "0.5em"}}>{infoText}</p>
              </div>
            </TooltipContainer>
          )}
          {badgePercentage && badgePercentage > 0 && (
            <BadgeDelta
              deltaType={badgePercentage >= 0 ? 'moderateIncrease' : 'moderateDecrease'}
              isIncreasePositive={true}
              size="xs"
            >{`${badgePercentage >= 0 ? '+' : '' }${formatAmount(badgePercentage ?? 0) || '0'} %`}</BadgeDelta>
          )}
        </div>
        <Metric>{metricText}</Metric>
      </Card>
    </div>
  )
}

export default SummaryCard;

