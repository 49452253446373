import { FC, useCallback } from "react";
import Selector, { SelectorType } from "../../components/shared/Selector/Selector";
import { MultiValue } from "react-select";

type Props = {
  places: string[],
  onChange: (values: string[]) => void,
  readonly?: boolean,
  required?: boolean,
  errorMessage?: string,
}

const placeOptions = [
  "Hela Sverige",
  "Blekinge",
  "Dalarna",
  "Gotland",
  "Gävleborg",
  "Göteborg",
  "Halland",
  "Jämtland",
  "Jönköping",
  "Kalmar",
  "Kronoberg",
  "Norrbotten",
  "Skaraborg",
  "Skåne",
  "Stockholm",
  "Stockholm, centralt",
  "Stockholm, norra",
  "Stockholm, södra",
  "Södermanland",
  "Uppsala",
  "Värmland",
  "Västerbotten",
  "Västernorrland",
  "Västmanland",
  "Älvsborg",
  "Örebro",
  "Östergötland",
].map(s => ({label: s, value: s.replace(/å/g, "a").replace(/Å/g, "A").replace(/ä/g, "a").replace(/Ä/g, "A").replace(/ö/g, "o").replace(/Ö/g, "O")}));


/*const groups = [
  {group: "Stockholm", contents: placeOptions.map(o => o.value).filter(s => s.startsWith("Stockholm,"))},
  {group: "Hela Sverige", contents: placeOptions.slice(1).map(o => o.value).filter(s => !s.startsWith("Stockholm,"))},
];*/

type Tree = {
  value: string,
  parent?: Tree,
  children: Tree[],
}

const makeTree = (value: string, children: Tree[]) => ({ value, children });

const tree = makeTree("Hela Sverige", [
  ...placeOptions.slice(1).map(o => o.value).filter(s => !s.startsWith("Stockholm")).map(s => makeTree(s, [])),
  makeTree("Stockholm", placeOptions.slice(1).map(o => o.value).filter(s => s.startsWith("Stockholm,")).map(s => makeTree(s, [])))  
]);

const nodes = new Map<string, Tree>();
const finishTree = (tree: Tree, parent?: Tree) => {
  nodes.set(tree.value, tree);
  tree.parent = parent;
  for (const child of tree.children) {
    finishTree(child, tree);
  }
}
finishTree(tree);


const unselectDescendants = (newValues: SelectorType[], node: Tree) => {
  console.log("unselectDescendands", node.value);
  for (let child of node.children) {
    newValues = newValues.filter(v => v?.value != child.value);
    newValues = unselectDescendants(newValues, child);
  }
  return newValues;
}

const PlaceSelector: FC<Props> = ({ places, onChange, readonly, required, errorMessage }) => {
  const onPlacesChange = (values: MultiValue<SelectorType>) => {
    const isSelected = (set: Set<string>, s: string) => {
      let node = nodes.get(s);
      while (node) {
        if (set.has(node.value)) return true;
        node = node.parent;
      }
      return false;
    };  
    let newValues = [...values];
    let set = new Set(newValues.map(v => v?.value ?? ""));
    const prev = new Set(places);

    const added = [];
    for (let value of newValues) {
      if (!prev.has(value?.value ?? "")) added.push(value);
    }

    for (let value of added) {
      const s = value?.value ?? "";
      let node = nodes.get(s);
      console.log({s, node})
      if (node) {
        if (isSelected(prev, s)) {
          // uncheck
          console.log("deselecting", s);
          // remove direct
          newValues = newValues.filter(v => v?.value != s);
          // foreach ancestor on path to root
          while (node.parent) {
            // if node is checked
            if (isSelected(set, node.parent.value)) {
              // remove node
              newValues = newValues.filter(v => v?.value != node?.parent?.value);
              // check all children except for current node
              for (let child of node.parent.children) {
                if (child.value != node.value) {
                  const option = placeOptions.find(o => o.value == child.value);
                  if (option && !set.has(child.value)) {
                    newValues.push(option);
                  }
                }
              }
            }
            node = node?.parent;
          }
        } else {          
          console.log("selecting", s);

          // if all siblings are already selected, unselect siblings and select parent
          while (node.parent) {
            const siblings = node.parent?.children ?? [];
            let all = true;
            for (const sibling of siblings) {
              all = all && isSelected(set, sibling.value);
            }
            if (all) {
              newValues = newValues.filter(v => !siblings.map(s=>s.value).includes(v?.value ?? ""));
              const parent = placeOptions.find(o => o.value == node?.parent?.value ?? "");
              if (parent) {
                console.log("selecting parent", parent.value)
                newValues.push(parent); // could parent already be in newValues?
              }
              set = new Set(newValues.map(v => v?.value ?? ""));
            }
            node = node?.parent;
          }

          // unselect all descendants
          node = nodes.get(s);
          if (node) {
            newValues = unselectDescendants(newValues, node);
          }
        }
      }
    }
    
    onChange(newValues.map(v => v?.value ?? ""));
  };

  const isSelected = useCallback((value: SelectorType) => {
    const s = value?.value ?? "";
    let node = nodes.get(s);
    while (node) {
      if (places.includes(node.value)) return true;
      node = node.parent;
    }
    return false;
  }, [places]);

  if (readonly) {
    return <div>{places.map(s => placeOptions.find(po => po.value == s)?.label ?? s).map(place => (
      <span style={{
        fontSize: "10px",
        textTransform: "uppercase",
        lineHeight: "16px",
        fontWeight: "600",
        padding: "2px 8px",
        borderRadius: "12px",
        marginRight: "8px",
        letterSpacing: "0.5px",
        color: "var(--gray-800)",
        backgroundColor: "var(--gray-100)",
      }}>{place}</span>
    ))}</div>
  }
  return <Selector<SelectorType, true> isMulti required={required} errorMessage={errorMessage} isSearchable options={placeOptions} label="Platser" value={places.map(s => (placeOptions.find(po => po.value == s) ?? {label: s, value: "id"+s}))} onChange={onPlacesChange} isSelected={isSelected}/>
}

export default PlaceSelector;