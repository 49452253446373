import { FC, useState } from 'react';
import AppLayout from '../../components/layout/app-layout/app-layout';
import BtButton from '../bt-button/bt-button';
import { ServiceDownload } from '../../services/service-download';
import { NotificationManager } from "react-notifications";

type Props = {}

const PageReports: FC<Props> = ({}) => {
  const [loadingRecommendedSuppliers, setLoadingRecommendedSuppliers] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingContracts, setLoadingContracts] = useState(false);
  const [loadingProjects, setLoadingProjects] = useState(false);

  const downloadReport = async (report: string, setLoading: (value: boolean) => void) => {
    setLoading(true);
    const now = new Date().toISOString().replace("T", "_").replace(/[.].*/, "");
    try {
      await ServiceDownload.downloadUrl(`/api/reports/${report}`, `${report}-${now}.csv`, "text/csv");
    } catch (e) {
      NotificationManager.error("" + e, "Något gick fel");
    } finally {
      setLoading(false);
    }
  }
  const downloadRecommendedSuppliersReport = async () => {
    return await downloadReport("recommended-suppliers", setLoadingRecommendedSuppliers);
  }
  const downloadUsersReport = async () => {
    return await downloadReport("users", setLoadingUsers);
  }
  const downloadContractsReport = async () => {
    return await downloadReport("contracts", setLoadingContracts);
  }
  const downloadProjectsReport = async () => {
    return await downloadReport("projects", setLoadingProjects);
  }
  return <AppLayout title="Rapporter">
    <div style={{margin:"32px"}}>
      <BtButton onClick={downloadRecommendedSuppliersReport} loaderShow={loadingRecommendedSuppliers} color="dark">Rekommenderade leverantörer</BtButton>
    </div>
    <div style={{margin:"32px"}}>
      <BtButton onClick={downloadUsersReport} loaderShow={loadingUsers} color="dark">Användare</BtButton>
    </div>
    <div style={{margin:"32px"}}>
      <BtButton onClick={downloadContractsReport} loaderShow={loadingContracts} color="dark">Kontrakt</BtButton>
    </div>
    <div style={{margin:"32px"}}>
      <BtButton onClick={downloadProjectsReport} loaderShow={loadingProjects} color="dark">Projekt</BtButton>
    </div>
  </AppLayout>
}

export default PageReports;