import axios from "axios";

export const getTips = async (areaSlug) => {
  const res = await axios.get(`/api/tips/${areaSlug}`);
  return res.data;
}

export const updateTips = async ({ tips, areaSlug}) => {
  const res = await axios.put(`/api/tips/${areaSlug}`, {
    tips
  });
  return res.data;
}