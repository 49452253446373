import styles from './top-menu.module.scss';
import OrganizationSwitcher from '../../../controls/OrganizationSwitcher/organization-switcher';
import UserProfileMenu from '../../../controls/UserProfileMenu/UserProfileMenu';
import TooltipContainer from '../TooltipContainer/TooltipContainer';
import { ReactComponent as CloudSyncIcon } from '../../../assets/svg/cloudSyncIcon.svg';
import { ReactComponent as CloudDoneIcon } from '../../../assets/svg/cloudDoneIcon.svg';
import appState from '../../../state/AppStateContainer';
import { SavingState } from '../../../util/appEnum';

type Props = {
  title: React.ReactNode;
};

const TopMenu: React.VFC<Props> = ({ title }) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div style={{display:"flex", alignItems: "center", gap: "16px"}}>
        {appState.getAutosavingState() === SavingState.SAVING && (
          <TooltipContainer renderReferenceComponent={(className, ref) => <CloudSyncIcon className={className} ref={ref} fill="var(--gray-700)" width={20} />} >
            <span style={{ whiteSpace: 'nowrap' }}>Sparar ändringar...</span>
          </TooltipContainer>
        )}
        {appState.getAutosavingState() === SavingState.DONE && (
          <TooltipContainer renderReferenceComponent={(className, ref) => <CloudDoneIcon className={className} ref={ref} fill="var(--gray-700)" width={20} />} >
            <span style={{ whiteSpace: 'nowrap' }}>Dina ändringar är sparade</span>
          </TooltipContainer>
        )}
        <OrganizationSwitcher />
        <UserProfileMenu />
      </div>
    </div>
  );
};

export default TopMenu;
