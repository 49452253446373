import React from 'react';
import './Toolbar.css';

/**
 * @param actions: { title?: string, component: ReactElement, action?: () => void, disabledAllowHover?: boolean, disabledFully?: boolean }[]
 */
export default function Toolbar({ actions }) {

  if (!actions) return null;

  const getDisabledClassName = (element) => {
    let disabledClassName = '';
    if (element.disabledAllowHover) {
      disabledClassName = 'toolbarOptionDisabled_AllowHover';
    } else if (element.disabledFully) {
      disabledClassName = 'toolbarOptionDisabled_Fully';
    }
    return disabledClassName;
  }
  
  return (
    <div className='toolbarContainer'>
      {actions.map((element, index) => (
        <div key={index} onClick={element.action} title={element.title}
          className={getDisabledClassName(element)}
          tabIndex={(element.disabledAllowHover || element.disabledFully) ? (-1) : undefined}
        >
          {element.component}
        </div>
      ))}
    </div>
  );
}