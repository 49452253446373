import React from 'react';
import './app-headsup.css'

export class AppHeadsup extends React.Component {

    element;
    count = 0;

    componentDidLoad() {
        this.element.classList.add('animation-enabled');
    }

    render() {
        var countRender = 1;
        if(this.count > 1) {
            countRender = this.count;
        }
        return (
            <div className="app-headsup animation-enabled">
                <span className="count">{countRender}</span>
                <span className="message">{countRender == 1 ? ' nytt meddelande' : ' nya meddelanden'}</span>
            </div>
        );
    }
}
