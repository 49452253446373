import React from 'react';
import { Link } from 'react-router-dom';
import appState from '../../state/AppStateContainer';

export default function CustomLink(props) {
  const { children, to, ...rest } = props;
  const slug = appState.getCurrentOrgSlug();
  let _to = to;
  if (typeof _to === 'object') _to.pathname = `/${slug}${to.pathname}`;
  else if (typeof _to === 'string') _to = `/${slug}${to}`;

  return <Link to={_to} {...rest}>{children}</Link>;
}
