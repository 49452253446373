import { CSSProperties, FC } from "react";
import {  Card, Color, Text, LineChart as TremorLineChart } from '@tremor/react';
import styles from './LineChart.module.scss';

type Props = {
  title: string,
  index: string,
  data: any[],
  categories: string[],
  colors?: Color[],
  style?: CSSProperties,
}

export const LineChart: FC<Props> = ({ title, index, data = [], categories = [], colors = ['orange', 'cyan'], style }) => {

  return (
    <div style={style} className={styles.lineChartWrapper}>
      <Card>
        <Text>{title}</Text>
        <TremorLineChart
          data={data}
          categories={categories}
          index={index}
          colors={colors}
          startEndOnly={false}
          showXAxis={true}
          showYAxis={true}
          autoMinValue={false}
          yAxisWidth={56}
          showTooltip={true}
          showLegend={true}
          showGridLines={true}
          showAnimation={true}
          className="mt-0"
          noDataText="Ingen data"
        />
      </Card>
    </div>
  )
}

export {}