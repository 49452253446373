import axios from "axios";
import BaseService from "./baseService";

export class ServiceId06Controller extends BaseService{
  async checkId06(orgNumber) {
    const response = await axios.get("/api/id06", {
        params: {company_registration_number: orgNumber}
    });
    return response;
  }
}

export const ServiceId06 = new ServiceId06Controller();
