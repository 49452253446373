import axios from "axios";
import { BehaviorSubject } from "rxjs";
import BaseService from "./baseService";
import { post, put } from "../utils/bt-http";

class ServiceTasksController extends BaseService {
  async addTasks(data) {
    const response = await axios.post("/api/tasks", data);
    return response.data;
  }

  async updateTasks(data) {
    const response = await axios.put("/api/tasks", data);
    return response.data;
  }

  async deleteTasks(id) {
    const response = await axios.delete("/api/tasks/", { params: { id } });
    return response.data;
  }

  async getTasks() {
    const response = await axios.get("/api/tasks");
    return response.data;
  }

  async getProjectUsers(projectId) {
    const response = await axios.get(`/api/users/task`, {
      params: { projectId }
    });
    return response.data;
  }

  async getProjectsForTasks() {
    const response = await axios.get("/api/company/projects", {});
    return response.data;
  }

  async getProjectsTasks(searchFilter, projectId, assignTo) {
    const response = await axios.get("/api/projects/tasks/all", {
      params: { projectId, searchFilter, assignTo }
    });
    return response.data;
  }
}

export const ServiceTasks = new ServiceTasksController();

// export default ServiceProjectsController;
