import React, { useState, useEffect } from 'react';
import AppLabel from '../app-label/app-label';
import BtButton from '../bt-button/bt-button';

import { areEqual, numberValidator, validateOptionalPhone } from '../../util';
import FileDropper from '../../components/shared/FileDropper';
import { cropAndScale } from '../../components/shared/cropImage';
import { serviceProfile } from '../../services/service-profile';
import { ServiceUser } from '../../services/service-user';
import _ from 'lodash';
import { NotificationManager } from 'react-notifications';
import str from '../../localization/strings.json';
import appState from '../../state/AppStateContainer';
import { getImageUrl } from '../../components/shared/ImagePreview/ImagePreview';
import { FileScope } from '../../util/appEnum';

const UserDetailsForm =
  ({ history, isProfile, user, setParentUser }) => {
    const [userData, setUserData] = useState({});
    const [errors, setErrors] = useState({});
    const [loader, setLoader] = useState(false);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [image, setImage] = useState(null);

    useEffect(() => {
      if (user) {
        if (isProfile) setUserProfileData(user);
        else{
          user.password = "";
          user.name = "";
          user.phone = "";
          user.avatarUrl = "";
          setUserData(user);}
      }
      // else if (location && location.state && location.state.userData) {
      //   location.state.userData.name = '';
      //   setUserData(location.state.userData);
      // }
    }, [user]);

    const setUserProfileData = ({
      email = '',
      name = '',
      phone = '',
      ...rest
    }) => {
      let objToServe = {
        email,
        name,
        phone,
        ...rest,
      };
      setUserData(objToServe);
    };
      
    const onSubmit = async () => {
      if (fieldValidationError()) return;
      let user;
      setLoader(true);

      if (image) {
        if (croppedAreaPixels) {
          const file = await cropAndScale(image, croppedAreaPixels, 280, 280);
          userData.image = file;
        }
      }

      if (isProfile) {
        delete userData.projects;
        userData.isProfile = true;
        userData.id = userData._id;
        user = await serviceProfile.dbUpdateMemberProfile(userData);
      } else {
        user = await serviceProfile.registerUser(userData);
      }
      setLoader(false);

      if (user.error) {
        let message = _.get(str.sv, user.error);
        return NotificationManager.error(message);
      } else {
        if (!isProfile) {
          NotificationManager.success('Profilen har skapats');
          if(user && user.userData && user.userData.accessToken) {
            appState.signout();
            appState.setState({loader: true});
            await appState.setToken(user, true);
          }
          return history.push('/');
        }
        // if (user.imgId) {
        //   const authService = new ServiceAuthController();
        //   user.avatarUrl = authService.getAvatarUrl(user.imgId);
        // }

        appState.setToken({
          userData: { ..._.get(appState, 'state.token'), ...user.data },
        });
        appState.setAatarFetchStateToFalse()
      }

      if (setParentUser) setParentUser(user.data);

      NotificationManager.success('Profilen har uppdaterats');
    };

    const fieldValidationError = () => {
      let reqFields = ['name', 'phone'];
      let signupFields = ['password', 'confirmPassword'];
      reqFields = !isProfile ? [...reqFields, ...signupFields] : reqFields;
      let _errors = errors;
      reqFields.forEach(field => {
        if(field === 'name') userData[field] = userData[field] ? userData[field].trim() : "";
        if (!userData[field]) {
          _errors[field] = 'Obligatoriskt fält';
        }
        else if(field === "password"){
          if(userData.password.length < 8) _errors[field] = 'Lösenordet måste innehålla minst 8 tecken.';
          else delete _errors[field];
        }
        else if(field === "phone"){
      
          if(validateOptionalPhone(userData.phone)) _errors[field] = 'Ogiltigt telefonnummer';
          else delete _errors[field];
        }
        else if(field === "confirmPassword"){
          if(userData.password !== userData.confirmPassword) _errors[field] = 'Lösenord är inte samma';
          else delete _errors[field];
        }
        else {
          delete _errors[field];
        } 
        setErrors({ ..._errors });
      });
      return Object.entries(_errors).length;
    };

    const handleChange = ({ target }) => {
      let _data = userData;
      _data[target.name] = target.value;
      setUserData(prevState => ({ ...prevState, ..._data }));
    };

    const inputField = (label, name, disabled, type = 'text') => (
      <AppLabel class="ar-label v2" headline={label}>
        <input
          autoCapitalize="off"
          className={`addSup-orgNo ar-text-field ${disabled ? 'disabled' : ''}`}
          name={name}
          value={userData[name] || ''}
          disabled={disabled}
          type={type}
          onChange={handleChange}
        />
        {errors[name] ? <p className="error show no-text-transform">{errors[name]}</p> : null}
      </AppLabel>
    );

    const onFileChange = ({ image, croppedAreaPixels }) => {
      setImage(image);
      setCroppedAreaPixels(croppedAreaPixels);
    };

    return (
      <div className="input-wrapper custom-input-wrapper">
        {inputField('E-post', 'email', true)}
        {!isProfile && inputField('Lösenord', 'password', false, 'password')}
        {!isProfile && inputField('Bekräfta lösenord', 'confirmPassword', false, 'password')}
        {inputField('Namn', 'name')}
        {inputField('Telefonnummer', 'phone')}

        <AppLabel class="ar-label v2" headline="Profilbild (valfritt)">
          <FileDropper
            userName={userData.name || ''}
            onChange={onFileChange}
            avatarUrl={user && getImageUrl(userData.imgId, appState.getCurrentOrgSlug(), FileScope.UserAvatar, {userId: userData._id})}
          />
        </AppLabel>

        <div
          className="btn-login-wrapper"
          style={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-end' }}
        >
          <BtButton class="btn-login ar-button" loaderShow={loader} onClick={onSubmit}>
            Spara
          </BtButton>
        </div>
      </div>
    );
  }

export default UserDetailsForm;
