import React from 'react';

export const CalendarIcon = (props) => (
  <svg
    viewBox="0 0 395.79 415.37"
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    fill="#f3f3f2"
    {...props}
  >
    <g>
      <path
        d="M83,417c-.34-13.66-.77-27.32-1-41-.31-18-.62-36-.63-54,0-15.84.57-31.69.56-47.53,0-17.51-.54-35-.56-52.52,0-12.4.46-24.8.72-37.21,2.15.14,4.3.41,6.44.41q168.12,0,336.25,0A53.42,53.42,0,0,1,431,186Q431,244.49,430.91,303c-4.29-1-8.55-2.16-12.88-2.81-11.43-1.71-22.89-2.89-34.28.38-6.93,2-14.21,3.18-20.73,6.08-14.7,6.52-25.76,17.68-35.92,29.91-5.82,7-10.78,14.47-13,23.21-2.6,10.13-6.53,20.11-4.54,31.08.9,4.94,0,10.19.65,15.21.55,4.31,2.17,8.48,3.22,12.74A5.72,5.72,0,0,1,313,421c-5.36-2.85-11-1.13-16.54-1.11q-104,.22-207.94,0C86.69,419.86,84.86,418,83,417Zm177.37-72.12c-7.39-.11-15.44,7.21-15.44,14,0,7.68,7.26,15,15,15.05,8.3.09,14.78-6,15-14.06A14.54,14.54,0,0,0,260.39,344.93ZM315.33,261A14.5,14.5,0,1,0,301,246.33,14.2,14.2,0,0,0,315.33,261ZM204.54,232A14.5,14.5,0,1,0,219,246.66,14.52,14.52,0,0,0,204.54,232Zm55.72,29A14.5,14.5,0,1,0,246,246.13,14.52,14.52,0,0,0,260.26,261ZM387,246.35A14.5,14.5,0,1,0,372.52,261,14.52,14.52,0,0,0,387,246.35ZM161,303.73A14.5,14.5,0,1,0,146.15,318,14.52,14.52,0,0,0,161,303.73ZM204.53,289A14.5,14.5,0,1,0,219,303.65,14.51,14.51,0,0,0,204.53,289ZM275,303.36A14.5,14.5,0,1,0,260.52,318,14.52,14.52,0,0,0,275,303.36ZM161,360.3A14.5,14.5,0,1,0,146.57,375,14.52,14.52,0,0,0,161,360.3ZM204.38,346A14.52,14.52,0,0,0,190,360.76,14.5,14.5,0,1,0,204.38,346Z"
        transform="translate(-81.39 -51.93)"
      />
      <path
        className={`calendar-icon`}
        fill={'#ffa600'}
        d="M431,186a53.42,53.42,0,0,0-6.22-.71q-168.12-.05-336.25,0c-2.15,0-4.3-.27-6.44-.41.06-21.15.06-42.31.21-63.46,0-6.55,1.16-12.77,6.49-17.53A20.76,20.76,0,0,1,103.12,98q23,0,46,0a7.68,7.68,0,0,1,.75,2.28A67.5,67.5,0,0,0,160.16,137c-1.73,8.72,5.6,17.41,15.2,17.34,8.93-.07,16.7-9.09,14.49-17.41a10.57,10.57,0,0,0-6-9.75c-5.51-4.21-11.17-4.25-17-.62-.84-1-2-1.77-2.47-2.88A52.23,52.23,0,0,1,160,98l30.13-.09h10.8l122.15.05c.47,3.88,1,7.76,1.39,11.65,1,9.89,4.87,18.78,9.71,27.31-.59,10.41,5.56,17.29,15.12,17.45,7.86.13,16.79-7.65,14.59-17.31.13-4.6-2.11-7.74-6-9.87-5.5-4.5-11.17-4.15-16.95-.58-1-1.28-2.23-2.43-2.8-3.86C334.94,114.8,333.2,106.59,334,98l30.07-.11h10.86q22.94.06,45.88.13c12.63.05,20.17,7.83,20.11,20.36-.11,22.44-.07,44.88-.09,67.33Z"
        transform="translate(-81.39 -51.93)"
      />
      <path
        className={`calendar-icon`}
        fill={'#ffa600'}
        d="M349,447c-17-15.51-25.83-34.74-25.35-57.77.79-37.77,25.95-68,63.14-74.61,28.07-5,52.14,3.67,71.28,25.15a15.24,15.24,0,0,0,1,3.73,77.31,77.31,0,0,1,10.13,43.73,75,75,0,0,1-18.72,45.63,76,76,0,0,1-96.77,15.61C352.33,447.64,350.58,447.47,349,447Zm59.27-48.91c8.39,0,16.18,0,24,0,4,0,6.73-2.12,7.6-5.94s-.22-7.43-4.15-9.17a15.24,15.24,0,0,0-5.81-1c-7.09-.11-14.17,0-21.7,0,0-4.47,0-8.27,0-12.07s.17-7.66,0-11.49c-.17-5-3.16-8.06-7.6-8.17a8.54,8.54,0,0,0-8.36,7.71c-.22,2.14-.08,4.33-.08,6.49,0,5.75,0,11.5,0,17.53-7.71,0-14.68,0-21.65,0-6.62,0-10.13,3-10,8.39s3.52,7.73,10.06,7.64c7.09-.1,14.18,0,21.57,0,0,8.23-.07,15.55,0,22.87.07,5.58,3.07,8.91,7.87,9,5,.12,8.12-3.24,8.17-9.08C408.34,413.48,408.29,406.17,408.29,398.08Z"
        transform="translate(-81.39 -51.93)"
      />
      <path
        fill="#dadada"
        d="M83,417c1.83,1,3.67,2.82,5.5,2.82q104,.21,207.94,0c5.58,0,11.18-1.74,16.54,1.11l1.75,8H95.46C84.43,429,83.74,428.33,83,417Z"
        transform="translate(-81.39 -51.93)"
      />
      <path
        fill="#a2a8ae"
        d="M334,98c-.82,8.61.92,16.81,4.09,24.74.57,1.43,1.85,2.58,2.8,3.86,2.56,2.47,4.73,6.17,7.77,7.14,4.79,1.53,7-3.07,9.18-6.56,3.91,2.13,6.16,5.27,6,9.87-1,.87-2,1.74-3,2.59-6.86,5.73-14.88,6.08-22,.94-1.61-1.17-3.14-2.44-4.71-3.67-4.84-8.53-8.68-17.42-9.71-27.31-.41-3.89-.93-7.77-1.39-11.65,1.36-10.5,1.11-21.38,6.92-30.85a51.08,51.08,0,0,1,6.32-8.9c8.83-9.16,20.87-8.13,28.27,2.31,7.93,11.19,10,24.07,10.36,37.39H364.08c.47-10.87-1.47-21.18-7.71-30.27-4.54-6.62-9.67-6.71-14.29-.31C335.46,76.44,334.08,87.07,334,98Z"
        transform="translate(-81.39 -51.93)"
      />
      <path
        fill="#a2a8ae"
        d="M160,98a52.23,52.23,0,0,0,4.42,25.71c.47,1.11,1.63,1.92,2.47,2.87,3,8.48,9,9.84,14.87,3.34.76-.85,1.39-1.81,2.08-2.72a10.57,10.57,0,0,1,6,9.75c-11.82,9.85-18.39,9.87-29.69.07a67.5,67.5,0,0,1-10.31-36.74A7.68,7.68,0,0,0,149.1,98c1.94-9.71,3.06-19.7,6.14-29,1.7-5.16,6.1-9.89,10.33-13.65,6.33-5.63,16.89-3.76,22.85,3,7,7.93,10.12,17.44,11.5,27.66.53,3.94.68,7.94,1,11.91h-10.8c.22-10.82-1.58-21.16-7.74-30.3C178,61,172,61,167.68,67.64,161.68,76.82,159.74,87.15,160,98Z"
        transform="translate(-81.39 -51.93)"
      />
      <path
        fill="#d8d8d8"
        d="M431,186l9.81-.26q.07,60.09.12,120.17a15.31,15.31,0,0,1-.51,2.31L430.91,303Q431,244.49,431,186Z"
        transform="translate(-81.39 -51.93)"
      />
      <path
        fill={'#a2a9b0'}
        d="M260.39,344.93A14.54,14.54,0,0,1,275,360c-.26,8.09-6.75,14.15-15,14.06-7.74-.09-15-7.37-15-15.05C244.95,352.14,253,344.81,260.39,344.93Z"
        transform="translate(-81.39 -51.93)"
      />
      <path
        fill={'#a2a9b0'}
        d="M315.33,261A14.5,14.5,0,1,1,330,246.67,14.21,14.21,0,0,1,315.33,261Z"
        transform="translate(-81.39 -51.93)"
      />
      <path
        fill={'#a2a9b0'}
        d="M204.54,232A14.5,14.5,0,1,1,190,246.59,14.51,14.51,0,0,1,204.54,232Z"
        transform="translate(-81.39 -51.93)"
      />
      <path
        fill={'#a2a9b0'}
        d="M260.26,261A14.5,14.5,0,1,1,275,246.62,14.52,14.52,0,0,1,260.26,261Z"
        transform="translate(-81.39 -51.93)"
      />
      <path
        fill={'#a2a9b0'}
        d="M387,246.35A14.5,14.5,0,1,1,372.23,232,14.5,14.5,0,0,1,387,246.35Z"
        transform="translate(-81.39 -51.93)"
      />
      <path
        fill={'#a2a9b0'}
        d="M161,303.73A14.5,14.5,0,1,1,146.6,289,14.52,14.52,0,0,1,161,303.73Z"
        transform="translate(-81.39 -51.93)"
      />
      <path
        fill={'#a2a9b0'}
        d="M204.53,289A14.5,14.5,0,1,1,190,303.6,14.51,14.51,0,0,1,204.53,289Z"
        transform="translate(-81.39 -51.93)"
      />
      <path
        fill={'#a2a9b0'}
        d="M275,303.36A14.5,14.5,0,1,1,260.23,289,14.5,14.5,0,0,1,275,303.36Z"
        transform="translate(-81.39 -51.93)"
      />
      <path
        fill={'#a2a9b0'}
        d="M161,360.3A14.5,14.5,0,1,1,146.18,346,14.51,14.51,0,0,1,161,360.3Z"
        transform="translate(-81.39 -51.93)"
      />
      <path
        fill={'#a2a9b0'}
        d="M204.38,346A14.5,14.5,0,1,1,190,360.76,14.51,14.51,0,0,1,204.38,346Z"
        transform="translate(-81.39 -51.93)"
      />
      <path
        fill="#fbfafa"
        d="M334.17,136.89c1.57,1.23,3.1,2.5,4.71,3.67,7.11,5.14,15.13,4.79,22-.94,1-.85,2-1.73,3-2.59,2.2,9.66-6.73,17.44-14.59,17.31C339.73,154.18,333.58,147.3,334.17,136.89Z"
        transform="translate(-81.39 -51.93)"
      />
      <path
        fill="#fcfafa"
        d="M160.16,137c11.3,9.8,17.87,9.79,29.69-.07,2.21,8.31-5.55,17.34-14.49,17.4C165.75,154.4,158.42,145.71,160.16,137Z"
        transform="translate(-81.39 -51.93)"
      />
      <path
        fill="#fbf9f8"
        d="M357.85,127.16c-2.19,3.49-4.39,8.09-9.18,6.56-3-1-5.21-4.67-7.77-7.14C346.68,123,352.35,122.66,357.85,127.16Z"
        transform="translate(-81.39 -51.93)"
      />
      <path
        fill="#fbf8f8"
        d="M183.83,127.18c-.69.91-1.32,1.87-2.08,2.72-5.86,6.5-11.86,5.14-14.87-3.34C172.66,122.93,178.32,123,183.83,127.18Z"
        transform="translate(-81.39 -51.93)"
      />
      <path
        fill="#f8fbf9"
        d="M408.29,398.08c0,8.09,0,15.4,0,22.7-.05,5.84-3.14,9.2-8.17,9.08-4.79-.11-7.8-3.45-7.87-9-.09-7.32,0-14.63,0-22.87-7.39,0-14.48-.08-21.57,0-6.54.09-9.89-2.36-10.06-7.64s3.34-8.36,10-8.39c7,0,13.93,0,21.65,0,0-6,0-11.78,0-17.53,0-2.16-.14-4.35.08-6.49a8.54,8.54,0,0,1,8.36-7.71c4.44.11,7.43,3.21,7.6,8.17.13,3.82,0,7.66,0,11.49s0,7.6,0,12.07c7.53,0,14.62-.06,21.7,0a15.24,15.24,0,0,1,5.81,1c3.93,1.74,5,5.26,4.15,9.17s-3.58,5.92-7.6,5.94C424.47,398.11,416.67,398.08,408.29,398.08Z"
        transform="translate(-81.39 -51.93)"
      />
    </g>
  </svg>
);

export const SimpleCalenderIcon = props => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.66667 4.83333V1.5M12.3333 4.83333V1.5M4.83333 8.16667H13.1667M3.16667 16.5H14.8333C15.7538 16.5 16.5 15.7538 16.5 14.8333V4.83333C16.5 3.91286 15.7538 3.16667 14.8333 3.16667H3.16667C2.24619 3.16667 1.5 3.91286 1.5 4.83333V14.8333C1.5 15.7538 2.24619 16.5 3.16667 16.5Z"
        stroke="#9CA3AF"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};