import { CSSProperties, FC, useEffect, useState } from 'react';
import './Editor.module.scss';
import { Editor as TinyEditor } from '@tinymce/tinymce-react';
import { Editor as EditorType, RawEditorOptions } from 'tinymce';

//! To upgrade the editor you have to download a new zip file
//! from https://www.tiny.cloud/docs/tinymce/6/react-zip-host/ and
//! put the content of the /js folder from the unzipped folder on /public

//! Note: Language file for the Swedish translation of the editor is at /public/tinymce/langs/sv_SE.js

type Props = {
  value: string,
  onChange: (value: string) => void,
  onEnter?: () => void,
  placeholder?: string,
  isDisabled?: boolean,
  hideToolbar?: boolean,
  wrapperClassName?: string,
  wrapperStyle?: CSSProperties,
  onLoadContent?: (event: any) => void, 
}

const init : RawEditorOptions & { selector?: undefined; target?: undefined; } = {
  language: 'sv_SE',
  menubar: false,
  branding: false,
  plugins: 'preview importcss searchreplace autolink directionality code visualblocks visualchars fullscreen image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount charmap quickbars emoticons ',
  toolbar: 'bold italic underline forecolor backcolor | fontfamily fontsize blocks alignGroup numlist bullist charmap emoticons preview print image link removeformat | undo redo',
  quickbars_selection_toolbar: 'bold italic underline forecolor backcolor fontsizeinput quicklink blockquote image removeformat',
  quickbars_insert_toolbar: false,
  font_size_formats: '8px 9px 10px 11px 12px 14px 18px 24px 30px 36px 48px 60px 72px 96px',
  resize: false,
  elementpath: false,
  contextmenu: false,
  height: '100%',
  paste_data_images: false,
  block_formats: 'Normal=p;Rubrik 1=h1;Rubrik 2=h2;Rubrik 3=h3;Rubrik 4=h4;Rubrik 5=h5;Rubrik 6=h6',
  toolbar_groups: {
    alignGroup: {
      icon: "align-left",
      items: "alignleft aligncenter alignright alignjustify"
    }
  },
};

const Editor: FC<Props> = ({ value, onChange, onEnter, placeholder, isDisabled, hideToolbar, wrapperClassName, wrapperStyle, onLoadContent }) => {
  const [editor, setEditor] = useState<EditorType | null>(null);

  useEffect(() => {
    const headerElement = editor?.id ? document.querySelector(`#${editor?.id} + .tox-tinymce .tox-editor-header`) : null;

    if (!headerElement) return;
    if (hideToolbar) {
      editor?.dom.addClass(headerElement, 'hidden');
    } else {
      editor?.dom.removeClass(headerElement, 'hidden');
    }
  }, [hideToolbar, editor]);

  init.placeholder = placeholder;

  return (
    <div className={wrapperClassName} style={{ height: '100%', ...wrapperStyle }}>
      <TinyEditor
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        onInit={(_, editor) => {
          setEditor(editor);
        }}
        init={init}
        value={value}
        onEditorChange={newValue => {
          onChange(newValue);
        }}
        onKeyUp={(e) => {
          if (onEnter && e.key === 'Enter' && !e.shiftKey) {
            onEnter();
          }
        }}
        onLoadContent={onLoadContent}
        disabled={isDisabled}
      />
    </div>
  );
}

export default Editor;