import axios from "axios";
import NProgress from "nprogress";
import appState from "../state/AppStateContainer";
import configHelper from "../util/configHelper"
import { showErrorNotification } from "../util"
import _ from "lodash";

class Services {
  areaStorage = null;
  cityStorage = null;
  formattedCities = null;
  
  constructor() {
    // axios.interceptors.request.use(
    //   function(config) {
    //     config.baseURL = configHelper.getBaseAPIPath();

    //     // Do something before request is sent
    //     if (config.progressBar) {
    //       NProgress.start();
    //     }
    //     if (appState.state.token)
    //       config.headers.authorization =
    //         "Bearer " + appState.state.token.accessToken;
    //     return config;
    //   },
    //   function(error) {
    //     // Do something with request error
    //     console.error("axiosRequestError", error);
    //     NProgress.done();
    //     console.error(error);
    //     return Promise.reject(error);
    //   }
    // );

    // Add a response interceptor
    // axios.interceptors.response.use(
    //   function(response) {
    //     // Do something with response data
    //     NProgress.done();
    //     return response;
    //   },
    //   async function(error) {
    //     debugger;
    //     if (_.get(error, "response.status") === 401) {
    //       // window.location = "/permissionsChanged";
    //     }

    //     if (error.message === "Network Error") {
    //       showErrorNotification();

    //       console.error("axioserror", error);

    //       //window.location = "/internetError";
    //     }
    //     // Do something with response error
    //     NProgress.done();
    //     console.error(error);
    //     return Promise.reject(error);
    //   }
    // );
  }

  async getAreas(){
    if (this.areaStorage) {
      return Promise.resolve(this.areaStorage);
    }
    const response = await axios.get("/api/areas");

    this.areaStorage = response.data;
    return this.areaStorage;
  }

  async getCities() {
    if (this.cityStorage) {
      return Promise.resolve(this.cityStorage);
    }
    const response = await axios.get("/api/cities");
    let cities = response.data;
    if (cities instanceof Array) {
      cities = [...new Set(cities)];
    }

    return this.cityStorage = cities;
  }

  async getFormattedCities() {
    if (this.formattedCities) {
      return Promise.resolve(this.formattedCities);
    }
    const response = await axios.get("/api/cities");
    let cities = response.data;
    if (cities instanceof Array) {
      cities = cities.map(item => ({ label: item, value: item }));
    }
    return this.formattedCities = cities;
  }

}

export default new Services();
