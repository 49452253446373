import axios from "axios";
import BaseService from "./baseService";
import FileDownload from "js-file-download";
import mimeType from "mime-types";
export class ServiceDownloadController extends BaseService {  
  getFileDownloadURL(filePath, scope, scopeOptions) {    
    const fileId = filePath.indexOf(".") != -1 ? filePath.split(".").slice(0, -1).join(".") : filePath;

    const url = fileId && scope ? `/api/util/download/${scope}?blobPath=${encodeURIComponent(fileId)}&${Object.entries(scopeOptions).map(([key, value]) => `${key}=${value?.toString()}`).join('&') || ''}` : '';
    return url;
  }
  async downloadUrl(url, fileName, mimeType) {
    const response = await axios.get(url, {
      progressBar: true,
      responseType: "blob"
    });
    let data = [];

    FileDownload(
      response.data,
      fileName,
      mimeType,
      // "application/pdf image/jpeg image/jpg application/vnd.ms-excel application/msword application/vnd.openxmlformats-officedocument.wordprocessingml.document application/vnd.openxmlformats-officedocument.wordprocessingml.template"
    );
  }
  async downloadFile(key, extension, fileName, scope, scopeOptions) {
    console.info("downloading file: ", { key });
    return this.downloadUrl(this.getFileDownloadURL(key, scope, scopeOptions), fileName, mimeType.lookup(extension));
  }
  async downloadSupplierExcelFile() {
    return this.downloadUrl("/api/supplier/download", "Leverantörsdatabas.xlsx", "application/vnd.openxmlformats");
  }
}

export const ServiceDownload = new ServiceDownloadController();
