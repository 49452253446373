import BaseService from "./baseService";

export const allowedFileExtensions = [
  "application/vnd.rar",
  "application/octet-stream",
  "application/x-rar-compressed",
  "application/x-zip-compressed",
  "multipart/x-zip",
  "application/zip",
  "application/x-compressed",
  "application/pdf",
  "image/jpg",
  "image/jpeg",
  "image/png",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
]

class ServiceCheckFileExtension extends BaseService {

    getFileExtension(file) {
        return file && file.slice(((file.lastIndexOf(".") - 1) >>> 0) + 2);
      }

    checkPdfExtension(file){
        return file.type.toLowerCase() == "application/pdf"
    }

    checkFileExtension(files) {
        
        
        if(files.type == "" && files.name){
            console.info("checkFileExtension", this.getFileExtension(files.name));
            return ["rar", "dwg", "ifc"].indexOf(this.getFileExtension(files.name)) !== -1; 
        }
        else {
          return !!allowedFileExtensions.find(fileExtension => fileExtension === files.type.toLowerCase());
        }
    }
}

export const ServiceCheckExtension = new ServiceCheckFileExtension();
