import axios from "axios";
import fileDownload from "js-file-download";
import { FC, useEffect, useState } from "react";
import mimeType from "mime-types";

type Props = {
  url: string,
  fileName: string,
  render: FC<{downloading: boolean, progress: number, onClick: (() => Promise<void>) | undefined}>
  disabled?: boolean,
  blob?: Blob,
}

const DownloadButton: FC<Props> = ({ url, fileName, render, disabled, blob }) => {
  const [downloading, setDownloading] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (downloading && progress >= 1) {
      setDownloading(false);
      setProgress(0);
    }
  }, [progress]);

  const onClick = async () => {
    if (blob) {
      fileDownload(blob, fileName, mimeType.lookup(fileName) || undefined);
    } else {
      if (!downloading) {
        setDownloading(true);
        const response = await axios.get(url, {
          onDownloadProgress: (e) => setProgress(e.loaded / (e?.total || 1)),
          responseType: 'blob',
        });
    
        fileDownload(
          response.data,
          fileName,
          mimeType.lookup(fileName) || undefined
        );
      }
    }
  }

  return (
    render({ downloading, progress, onClick: !disabled ? onClick : undefined })
  )
}

export default DownloadButton;