export function btNL2BR(string: string) {
	return string.replace(/\n/g, "<br />");
}

export function btStripTags(input: string, allowed: string = '') {
	// making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
	var allowed = (((allowed || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('')

	var tags = /<\/?([a-z0-9]*)\b[^>]*>?/gi
	var commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi

	var after = input;
	// removes tha '<' char at the end of the string to replicate PHP's behaviour
	after = (after.substring(after.length - 1) === '<') ? after.substring(0, after.length - 1) : after;

	// recursively remove tags to ensure that the returned string doesn't contain forbidden tags after previous passes (e.g. '<<bait/>switch/>')
	while (true) {
		var before = after
		after = before.replace(commentsAndPhpTags, '').replace(tags, function ($0, $1) {
			return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : ''
		})

		// return once no more tags are removed
		if (before === after) {
			return after
		}
	}
}

export function btTrim(string: string) {
	return string.replace(/^\s+|\s+$/gm,'');
}

export function btDirname(path) {
	return path.replace(/\\/g, '/')
		.replace(/\/[^/]*\/?$/, '')
}