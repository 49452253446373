import React from 'react';

export const SearchIcon = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
  >
    <g fill="none" fillRule="evenodd" transform="translate(-1 -1)">
      <polygon points="0 0 20 0 20 20 0 20"></polygon>
      <path
        fill="#92929D"
        d="M14.6975818,3.90241824 C17.4224231,6.62725957 17.6565717,10.899619 15.4000275,13.8904201 L18.4119477,16.9020656 C19.1976219,17.6877398 18.0191106,18.8662511 17.2334364,18.0805769 L14.2577679,15.1046644 C11.2587143,17.6717713 6.74091358,17.5360771 3.90241824,14.6975818 C0.921416143,11.7165797 0.921416143,6.88342033 3.90241824,3.90241824 C6.88342033,0.921416143 11.7165797,0.921416143 14.6975818,3.90241824 Z M5.08092954,5.08092954 C2.75080126,7.41105781 2.75080126,11.1889422 5.08092954,13.5190705 C7.41105781,15.8491987 11.1889422,15.8491987 13.5190705,13.5190705 C15.8491987,11.1889422 15.8491987,7.41105781 13.5190705,5.08092954 C11.1889422,2.75080126 7.41105781,2.75080126 5.08092954,5.08092954 Z"
      ></path>
    </g>
  </svg>
);
