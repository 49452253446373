import moment from "moment";
import DatePicker from "../../components/shared/DatePicker/DatePicker";
import Selector from "../../components/shared/Selector/Selector";
import BtButton from "../bt-button/bt-button";
import { FC } from "react";
import { regions } from "../../staticData/regions";
import { TrashIcon } from "../../components/svgs";

type Props = {
  index: number,
  ad: any,
  setState: any,
  areas: {
    id: string,
    label: string,
    value: string,
  }[],
  deletable?: boolean,
}

const RecommendedAd: FC<Props> = ({ index, ad, areas, setState, deletable }) => {
  return (
    <div key={index} className={`supplierAdPlacement ${deletable ? 'supplierAdPlacementDeletable' : ''}`}>
      <DatePicker
        label='Startdatum'
        selected={ad?.startDate}
        maxDate={ad?.endDate && moment(ad?.endDate).toDate()}
        onChange={(value) => setState((ads: any) => {
          ads[index].startDate = value;
          
          return ads;
        })}
      />
      {deletable && (
        <>
          <DatePicker
            label='Slutdatum'
            selected={ad?.endDate}
            minDate={moment(ad?.startDate).toDate()}
            onChange={(value) => setState((ads: any) => {
              ads[index].endDate = value;
              return ads;
            })}
          />
        
          <BtButton
            color='white'
            size='xxs'
            leftIcon={<TrashIcon size='14px' color='var(--gray-700)' />}
            onClick={() => setState((ads: any) => ads.filter((_: any, _index: number) => index !== _index))}
          />
        </>
      )}
      <Selector
        isMulti
        label='Län'
        options={regions}
        value={ad?.regions}
        onChange={(value) => setState((ads: any) => {
          ads[index].regions = value;
          ads[index].errors = {...ads[index].errors, regions: ''}
          
          return ads;
        })}
        errorMessage={ad?.errors?.regions}
        required
      />
      <Selector
        isMulti
        label='Yrkeskategorier'
        options={areas}
        value={ad?.areas}
        onChange={(value) => setState((ads: any) => {
          ads[index].areas = value;
          ads[index].errors = {...ads[index].errors, areas: ''}
          return ads;
        })}
        errorMessage={ad?.errors?.areas}
        required
      />
    </div>
  )
}

export default RecommendedAd;