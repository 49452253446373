import { CSSProperties, FC } from "react";
import { BarChart as TremorBarChart, Card, Text, Color } from '@tremor/react';
import styles from './BarChart.module.scss';
import { formatAmount } from "../../../../util";

import { ReactComponent as InfoIcon } from '../../../../assets/svg/InfoIconLight.svg';
import { UNIT } from "../../../../ts-components/page-dashboard-V2/helper";
import TooltipContainer from "../../TooltipContainer/TooltipContainer";

type Props = {
  title: string,
  index: string,
  data: any[],
  categories: string[],
  colors?: Color[],
  unit?: UNIT,
  style?: CSSProperties,
  infoText?: string,
}

export const BarChart: FC<Props> = ({ title, index, data = [], categories = [], colors = ['orange'], unit, style, infoText }) => {
  const percentageFormatter = (number: string): string =>
  `${number}%`;

  const amountFormatter = (value: number) => formatAmount(value ?? 0) || '0';
  let valueFormatter = amountFormatter;
  if (unit === UNIT.PERCENTAGE) {
    valueFormatter = (value: number) => percentageFormatter(amountFormatter(value));
  } else if (unit === UNIT.AMOUNT) {
    valueFormatter = (value: number) => `${amountFormatter(value)} kr`
  }

  let yAxisWidth = 44;
  if (unit === UNIT.AMOUNT) {
    yAxisWidth = 112;
  }

  return (
    <div style={style} className={styles.barChartWrapper}>
      <Card>        
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <Text>{title}</Text>
          {infoText && (
            <TooltipContainer renderReferenceComponent={(className, ref) => <div className={className} ref={ref}><InfoIcon /></div>}>
              <div style={{ maxWidth: "300px", fontSize: "14px", color: "var(--gray-700)" }}>
                <p style={{ fontWeight: 500, color: "var(--gray-900)"}}>{title}</p>
                <p style={{marginTop: "0.5em"}}>{infoText}</p>
              </div>
            </TooltipContainer>
          )}
        </div>
        <TremorBarChart
          data={data}
          categories={categories}
          index={index}
          colors={colors}
          layout="horizontal"
          valueFormatter={valueFormatter}
          stack={false}
          relative={false}
          startEndOnly={false}
          showXAxis={true}
          showYAxis={true}
          autoMinValue={false}
          yAxisWidth={yAxisWidth}
          showTooltip={true}
          showLegend={true}
          showGridLines={true}
          showAnimation={true}
          // height="h-full"
          className="mt-0"
          noDataText="Ingen data"
        />
      </Card>
    </div>
  )
}

export {}