export default async () => {
    let context;
    var userAgent = navigator.userAgent || navigator.vendor || (window).opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        context = "wp";
    } else if (/android/i.test(userAgent)) {
        context = "md";
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
        context = "ios";
    } else {
        context = "desktop";
    }

    document.documentElement.setAttribute('mode', context);
};


var stencilUID = -1;
export function getUID() {
    stencilUID++;
    return stencilUID;
}

export var apiUrl = 'https://app.accurator.se/api';
// if(window.location.origin == 'http://localhost:3333') {
//     apiUrl = 'http://api.accurator.mbi';
// }