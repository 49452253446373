import { FC, useEffect, useMemo, useState } from "react"
import AppLayout from "../../components/layout/app-layout/app-layout";
import Tabs from "../../components/shared/Tabs";
import serviceSales, { Invoice } from "../../services/service-sales";
import DataTable from "../../components/shared/DataTable/DataTable";
import { autosave, formatDate } from "../../util";
import Field from "../../components/shared/Field/Field";


type Props = {}


const InvoiceNumberField : FC<{ value: string, onBlur: (value: string) => void }> = ({value, onBlur}) => {
  const [inputValue, setInputValue] = useState(value);
  return (
    <Field
      value={inputValue}
      onChange={setInputValue}
      onBlur={() => onBlur(inputValue)}
    />
  );
}
const PageInvoices : FC<Props> = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [invoiceNumbers, setInvoiceNumbers] = useState<{[id: string]: string}>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const invoices = await serviceSales.fetchInvoices();
      setInvoices(invoices);
      const invoiceNumbers = invoices.reduce((obj: {[id: string]: string}, invoice: Invoice) => {
        obj[invoice._id] = invoice.invoiceNumber;
        return obj;
      }, {});
      setInvoiceNumbers(invoiceNumbers);
      setLoading(false);
    })();
  }, []);

  const columns = useMemo(() => [
    {
      title: "Produkt",
      field: "product",
    },
    {
      title: "Organisation",
      field: "organization",
    },
    {
      title: "Fakturaadress",
      field: "invoiceEmail",
    },
    {
      title: "Fakturareferens",
      field: "invoiceRef",
    },
    {
      title: "Från",
      field: "startDate",
      render: (rowData: Invoice) => formatDate(rowData.startDate),
    },
    {
      title: "Till",
      field: "endDate",
      render: (rowData: Invoice) => formatDate(rowData.endDate),
    },
    {
      title: "Belopp",
      field: "amount",
    },
    {
      title: "Anledning",
      field: "amount",
      render: (rowData: Invoice) => rowData.cause == "ORDER" ? "Beställning" : "Förnyad",
    },
    {
      title: "Fakturanummer",
      field: "invoiceNumber",
      render: (rowData: Invoice) => {
        const id = rowData._id;
        return (
          <InvoiceNumberField
            value={invoiceNumbers[id] ?? ""}
            onBlur={(value: string) => {
              setInvoiceNumbers({...invoiceNumbers, [id]: value}); 
              autosave(async () => {
                await serviceSales.saveInvoiceNumber(rowData._id, value);
              });
            }}
          />
        );
      }
    }
  ], [invoiceNumbers]);

  return (
    <AppLayout title="Fakturor">
      <div style={{margin: "32px"}}>
        <div className="tab-container">
          <Tabs
            tabs={["Alla"]}
            selected={selectedTab}
            onChange={tab => { 
              setSelectedTab(tab); 
            }}
          />
          <div className="tab-actions">
          </div>
        </div>
        <DataTable 
          columns={columns}
          data={invoices}
          isLoading={loading}
        />
      </div>
    </AppLayout>
  );

}

export default PageInvoices;