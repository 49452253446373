import { FC, useState } from "react";
import Modal from "../../../components/shared/Modal/Modal";
import StyledCheckbox from "../../../components/shared/StyledCheckbox/StyledCheckbox";
import { ServiceUser } from "../../../services/service-user";
import appState from "../../../state/AppStateContainer";
import styles from './SupplierAdPopUp.module.scss';

type Props = {
  show: boolean,
  setShow: (value: boolean) => void,
  areaName: string,
  municipalityName: string,
  setTabToRecommended: () => void,
  numberOfRecommendedSuppliers: number,
}

const SupplierAdPopUp: FC<Props> = ({ show, setShow, areaName, municipalityName, setTabToRecommended, numberOfRecommendedSuppliers }) => {
  const [isChecked, setIsChecked] = useState(false);

  const updateShowPreference = () => {
    ServiceUser.updatePreferences(appState.getUserId(), {preventPopUp: isChecked}, 'supplierAds');
  }

  return (
    <Modal
      title='Rekommenderade leverantörer'
      show={show}
      setShow={(value) => {
        updateShowPreference();
        setShow(value);
      }}
      buttonInfo={{ label: 'Visa leverantörer', action: () => {
        updateShowPreference();
        setTabToRecommended();
        setShow(false);
      }}}
    >
      <div className={styles.container}>
        <span>Du har {numberOfRecommendedSuppliers} leverantörer som vill lämna anbud på {areaName} i {municipalityName}</span>
        <label>
          <StyledCheckbox checked={isChecked} onChange={() => setIsChecked(prevState => !prevState)} />
          <span>Visa inte igen</span>
        </label>
      </div>
    </Modal>
  )
}

export default SupplierAdPopUp;