import axios from "axios";

export type Result = {
  id: string,
  title: string,
  tags: {
    type: 'Project' | 'User' | 'Supplier',
    suppliers: {
      projectInfo?: {
        name: string,
        id: string,
      },
      areaInfo?: {
        name: string,
        id: string,
      },
      supplierInfo: {
        name: string,
        organizationNumber: string,
        id: string,
      }
    }[],
  }
};

export type MonitoredSupplier = {
  name: string;
  organizationNumber: string;
  monitoredSince: Date;
  lastEventDate: Date;
}

export type MonitoringType = {
  value: string,
  description: string,
  codes: {
    [key: string]: string,
  },
  types: {
    [key: string]: string,
  },
}

export type MonitoringEvent = {
  date: string,
  week: string,
  description: string,
  code: string,
  type: string,
  supplier: {
    notFound?: boolean,
    name: string,
    organizationNumber: string,
  }
}

export type Sort = {
  field?: string;
  order?: "asc" | "desc";
}

class ServiceSupplierMonitoring {
  async search(query: string): Promise<Result[]> {
    const response = await axios.get("/api/supplier-monitoring/search", { params: { q: query } });
    return response.data;
  }
  async fetchMonitoredSuppliers(sort?: Sort): Promise<MonitoredSupplier[]> {
    const response = await axios.get("/api/supplier-monitoring/monitored-entities", { params: { sort } });
    return response.data;
  }
  async addMonitoredEntity(data: { projectId?: string, areaId?: string, supplierId?: string }): Promise<MonitoredSupplier[]> {
    const response = await axios.post("/api/supplier-monitoring/monitored-entities", data);
    return response.data;
  }
  async removeMonitoredSuppliers(organizationNumbers: string[]): Promise<MonitoredSupplier[]> {
    const response = await axios.delete("/api/supplier-monitoring/monitored-entities", { data: { organizationNumbers } });
    return response.data;
  }

  async fetchMonitoringTypes(): Promise<MonitoringType[]> {
    const response = await axios.get("/api/supplier-monitoring/monitoring-types");
    return response.data;
  }

  async fetchFilteredSuppliers(query: string): Promise<Result[]> {
    const response = await axios.get("/api/supplier-monitoring/filter-search", { params: { q: query } });
    return response.data;
  }
  async fetchEvents(sort?: Sort, filteredEventTypes?: string[], filteredSupplierOrgNumbers?: string[]): Promise<MonitoringEvent[]> {
    const response = await axios.get("/api/supplier-monitoring/events", { params: { sort, filteredEventTypes, filteredSupplierOrgNumbers } });
    return response.data;
  }

  async fetchMonitoredSupplier(organizationNumber: string): Promise<MonitoredSupplier> {
    const response = await axios.get(`/api/supplier-monitoring/monitored-supplier`, { params: { organizationNumber } });
    return response.data;
  }

  async fetchSuppliersFromProject(projectId: string): Promise<Result> {
    const response = await axios.get(`/api/supplier-monitoring/suppliers-from-project`, { params: { projectId } });
    return response.data;
  }
}

export default new ServiceSupplierMonitoring();