import AccuratorLogo from '../../assets/images/logo-next-light.svg';
import TitleWrapper from '../../components/shared/TitleWrapper/TitleWrapper';
import BtColumn from '../bt-column/bt-column';
import BtRow from '../bt-row/bt-row';

export const LabeledBLock = ({ label, children, containerClass = '', childrenClass = '' }) => {
  return (
    <div className={`quotation-label-block-container ${containerClass}`}>
      <div className="quotation-label-head">{label}</div>
      <div className={childrenClass}>{children}</div>
    </div>
  );
};

export const DemandsContainer = ({ children }) => (
  <BtRow>
    <BtColumn className="app-select-industry-types">
      <TitleWrapper title='Skallkrav'>
        <div className="headline-sub-label">Bocka för de krav ni uppfyller:</div>
        {children}
      </TitleWrapper>
    </BtColumn>
  </BtRow>
);

export const BidFormContentContainer = ({ projectData, supplierData, isSupplierView, children }) => (
  <div style={{ padding: isSupplierView ? '30px' : '0px', width: '100%' }}>
    {isSupplierView && projectData.name && (
      <div className="quotation-header">
        <div>
          <span className="project-head">{projectData.name}{projectData.number && ','}</span>
          {projectData.number && <span>{projectData.number}</span>}
        </div>
        {supplierData && <div className="sup-info">{supplierData.name}</div>}
      </div>
    )}

    <div className="quotation-body">{children}</div>
  </div>
);

export const LeftMenuContainer = ({ children }) => (
  <BtColumn className="bt-contract">
    <div className="menu-left quotation--menu">
      <div className="logo-wrapper">
        <img src={AccuratorLogo} fill="current" style={{ transform: 'scale(2)' }} />
      </div>

      <ul className="menu-list">
        {children}
      </ul>
    </div>
  </BtColumn>
);
