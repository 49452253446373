import axios from "axios";

export type Result = {
  title: string,
  path: string,
  tags: {
    [key: string]: string
  },
}

export type Results = {
  q: string,
  results: Result[],
}

class ServiceSearch {
  async search(query: string) : Promise<Results> {
    const response = await axios.get("/api/search", { params: { q: query } });
    return response.data;
  }
}

export default new ServiceSearch();