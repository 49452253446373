import React, { useState } from 'react';
import CustomDrawer from '../../components/shared/Drawer';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import './collectiveAgreement.css';
import CollectiveAccordion from './collectiveAccordion';
import { Loading } from '../../components/shared';
import BtButton from '../bt-button/bt-button';
import TooltipContainer from '../../components/shared/TooltipContainer/TooltipContainer';

export default function CollectiveAgreement({ agreementData, loading, error }) {
  const [drawerState, toggleDrawerState] = useState(false);
  let totalCount = 0;
  if (agreementData) {
    let { kollektivavtalskollen, byggInstSektorn } = agreementData;
    totalCount = kollektivavtalskollen.source_data.length + 
      byggInstSektorn.collectiveAgreements.length +
      byggInstSektorn.substituteAgreements.length;
  }

  const renderBtn = () => (
    <a
      className="kollectiv-link"
      onClick={() => {
        toggleDrawerState(!drawerState);
      }}
    >
      
      <span className="visa-avtal-text">Visa avtal</span>
    </a>
  );

  const agreementTeller = () => {
    if (error) {
      return error;
    }

    const noAgreement = !agreementData || totalCount == 0;

    let title = ``;
    if (!noAgreement) {
      if (agreementData.kollektivavtalskollen?.source_data?.length > 0) {
        const agreements = (agreementData.kollektivavtalskollen.source_data).reduce((total, source) => {
          total = [...total, ...(source?.agreements ?? []).map(agreement => agreement.agreement)];
          return total;
        }, []);
        title = `Kollektivavtalskollen: Har ${agreements.join(', ').replace(/,([^,]*)$/m, " och $1")}.\n`;
      }
      if (agreementData.byggInstSektorn) {
        title += `Bygg- och installationssektorn: `
        if (agreementData.byggInstSektorn.memberships?.length > 0) {
          title += `Medlem i ${agreementData.byggInstSektorn.memberships.map(m => m.name).join(', ').replace(/,([^,]*)$/m, " och $1")}. `
        }
        let totalAgreements = [];
        if (agreementData.byggInstSektorn.collectiveAgreements?.length > 0) {
          totalAgreements = totalAgreements.concat(agreementData.byggInstSektorn.collectiveAgreements.map(agreement => agreement.Avtal));
        }
        if (agreementData.byggInstSektorn.substituteAgreements?.length > 0) {
          totalAgreements = totalAgreements.concat(agreementData.byggInstSektorn.substituteAgreements.map(agreement => agreement.Avtal));
        }

        if (totalAgreements.length > 0) {
          title += `Har ${totalAgreements.join(', ').replace(/,([^,]*)$/m, " och $1")}.`
        }
      }
    }

    return loading ? (
      [<div key="loader"><Loading loadingStyle="bubbles" type='inline' color="#ff5e15" size='30px' /></div>, <div key="empty"></div>]
    ) : (<>
      <div key="indicator">
        {totalCount > 0 ? (
          <div className="collective-agreementFlag true" title={title}>
            <CheckIcon /> Ja
          </div>
        ) : (
          <TooltipContainer renderReferenceComponent={(className, ref) =>
            <div ref={ref} className={`collective-agreementFlag false ${className}`}>
              <CloseIcon /> Nej
            </div>
          }>
            <p style={{width: "25em", textAlign: "left"}}>            
              Vi visar data från tjänsten Kollektivavtalskollen som inkluderar avtal med Byggnads, 
              Kommunal, Fastighet, Elektrikerna, Handels, Målarna, Akavia, Forena och Finansförbundet 
              (fler förbund/avtal kommer, denna lista är senast uppdaterad 2024-08-21).
            </p>
            <p style={{width: "25em", textAlign: "left"}}>
              Vi hämtar också data från Bygg- och installationssektorn som innehåller information från 
              arbetsgivarorganisationer och inkluderar medlemskap, kollektivavtal och hängavtal från Byggföretagen, 
              Installatörsföretagen, Maskinentreprenörerna, Plåt & Ventföretagen, Måleriföretagen och Glasbranschföreningen.
            </p>
          </TooltipContainer>
        )}
      </div>
      <div key="button">
        <BtButton
          color="white"
          size="xxs"
          disabled={noAgreement}
          class='supplierControlsElementToHide'
          onClick={() => {
            if (noAgreement) return;
            toggleDrawerState(!drawerState);
          }}
          key="button"
        >Visa avtal</BtButton>
      </div>
    </>);
  };

  return (
    <React.Fragment>
      {/*renderBtn()*/}
      {agreementTeller()}
      <CustomDrawer position="right" isOpen={drawerState} toggleDrawer={toggleDrawerState}>
        <div className="custom-drawer-body">
          <CollectiveAccordion data={agreementData} />
        </div>
      </CustomDrawer>
    </React.Fragment>
  );
}

// var acc = document.getElementsByClassName("accordion");
// var i;

// for (i = 0; i < acc.length; i++) {
//   acc[i].addEventListener("click", function() {
//     this.classList.toggle("active");
//     var panel = this.nextElementSibling;
//     if (panel.style.display === "block") {
//       panel.style.display = "none";
//     } else {
//       panel.style.display = "block";
//     }
//   });
// }
