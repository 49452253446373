import React from "react";

export default function TrashIcon({ size = "" , color = 'var(--gray-500)' }) {
  return (
    <svg width={size || '22px'} height={size || '22px'} viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Component/icon/ic_Trash">
        <use stroke="none"  fill="none" />
        <path d="M5.66331 2.7L6.33539 0.683772C6.4715 0.27543 6.85364 0 7.28407 0L12.6841 0C13.1145 0 13.4966 0.27543 13.6328 0.683772L14.3048 2.7L18.984 2.7C19.5363 2.7 19.984 3.14772 19.984 3.7C19.984 4.25229 19.5363 4.7 18.984 4.7L18.127 4.7L17.3931 17.1762C17.2998 18.7619 15.9867 20 14.3983 20L5.56988 20C3.98145 20 2.66833 18.7619 2.57506 17.1762L1.84116 4.7L1 4.7C0.447715 4.7 0 4.25229 0 3.7C0 3.14772 0.447715 2.7 1 2.7L5.66331 2.7ZM7.77148 2.7001L12.1966 2.7001L11.9633 2.0001L8.00482 2.0001L7.77148 2.7001ZM3.8446 4.7002L16.1235 4.7002L15.3965 17.0589C15.3654 17.5875 14.9277 18.0002 14.3982 18.0002L5.56986 18.0002C5.04039 18.0002 4.60268 17.5875 4.57159 17.0589L3.8446 4.7002ZM7.79647 15.4979C8.34768 15.4634 8.76659 14.9887 8.73214 14.4375L8.28214 7.23746C8.24769 6.68625 7.77292 6.26734 7.22171 6.30179C6.6705 6.33624 6.25159 6.81101 6.28604 7.36222L6.73604 14.5622C6.77049 15.1134 7.24526 15.5323 7.79647 15.4979ZM11.686 7.23746C11.7204 6.68625 12.1952 6.26734 12.7464 6.30179C13.2976 6.33624 13.7165 6.81101 13.6821 7.36222L13.2321 14.5622C13.1976 15.1134 12.7229 15.5323 12.1717 15.4979C11.6205 15.4634 11.2015 14.9887 11.236 14.4375L11.686 7.23746Z" transform="translate(2 2)" id="Shape" fill={color} fillRule="evenodd" stroke="none" />
      </g>
    </svg>
  );
}