import React from 'react';
export default function RequestQuoteIcon({ color, size=24 }) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height={size + "px"} viewBox="0 0 24 24" width={size + "px"} fill={color ?? '#000'}>
        <g>
          <rect fill="none" height="24" width="24"/>
          <path d="M14,2H6C4.9,2,4,2.9,4,4v16c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8L14,2z M6,20V4h7v4h5v12H6z M11,19h2v-1h1 c0.55,0,1-0.45,1-1v-3c0-0.55-0.45-1-1-1h-3v-1h4v-2h-2V9h-2v1h-1c-0.55,0-1,0.45-1,1v3c0,0.55,0.45,1,1,1h3v1H9v2h2V19z"/>
        </g>
      </svg>
    );
}