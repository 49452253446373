// import { Component, h, Host, Prop, Watch } from '@stencil/core';

import React from 'react';
import './bt-select.css';

export default class BtDropdown extends React.Component{
// @Component({
//     tag: 'bt-select',
//     styleUrl: 'bt-select.scss'
// })
// export class BtDropdown {

    constructor(props) {
        super(props);
        this.state = {
            labelValue: this.props.labelValue,
        };
      }
    select;
    name =  this.props.name;
    description;
    value;
    data;
    labelValue;
    btChange;

    // @Watch('value')
    valueChanged() {
        const { value } = this;
        this.select.value = value;
        this.update();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.value != nextProps.value) {
            this.select.value = nextProps.value;
            this.update();
        }
    }

    // @Watch('labelValue')
    // labelValueChanged(value) {
    //     const { labelValue } = this.value;
    //     const parser = new DOMParser();
    //     const decodedString = parser.parseFromString(`<!doctype html><body>${labelValue}`, 'text/html').body.textContent;
    //     this.labelValue = decodedString;
    // }

    selectChangeCallback(e) {
        this.update();
        if (this.btChange) {
            this.btChange(e);
        }
    }

    componentDidMount() {
        //this.update();
    }

    update() {
        if(this.select.selectedIndex !== -1){
            this.labelValue = this.select.options[this.select.selectedIndex].innerHTML;
            this.setState({labelValue:this.labelValue});
        }
        this.value = this.select.value;
        this.props.handleChange(this.value,this.name)
        // const parser = new DOMParser();
        // const decodedString = parser.parseFromString(`<!doctype html><body>${labelValue}`, 'text/html').body.textContent;
        // this.labelValueChanged(this.labelValue)
       
    }

    render() {
        return (
            <div className={'bt-select'} select="">
                <div className="wrapper">
                    <span className="values">
                        {this.props.description && <span className="description">{this.props.description}</span>}
                        <span className="labelValue">{this.state.labelValue}</span>
                    </span>
                    <span className="icon-down">
                        <span className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 10 7">
                                <path fill="black" fillRule="evenodd" d="M3.47901419,-1.01678865 L8.84529033,3.27623226 C9.06092129,3.44873704 9.09588198,3.76338322 8.92337721,3.97901419 C8.90031133,4.00784654 8.87412268,4.03403519 8.84529033,4.05710107 L3.47901419,8.35012198 C3.26338322,8.52262676 2.94873704,8.48766607 2.77623226,8.2720351 C2.70530704,8.18337858 2.66666667,8.07322332 2.66666667,7.95968758 L2.66666667,-0.626354243 C2.66666667,-0.902496618 2.89052429,-1.12635424 3.16666667,-1.12635424 C3.28020241,-1.12635424 3.39035767,-1.08771387 3.47901419,-1.01678865 Z" transform="rotate(90 5.5 3.167)" />
                            </svg>
                        </span>
                    </span>
                    <select ref={el => this.select = el} name={this.name} onChange={this.selectChangeCallback.bind(this)}>
                        {this.props.data.map((item) => {
                            return (<option key={item.value} value={item.value}>{item.label}</option>)
                        })}
                    </select>
                </div>
            </div>
        );
    }
}
