import axios from 'axios';
import NProgress from 'nprogress';
import appState from '../state/AppStateContainer';
import configHelper from '../util/configHelper';
import { showErrorNotification } from '../util';
import _ from 'lodash';

var roleChangeRetry = false;

const handleAccessDenied = async () => {
  showErrorNotification('Du har inte behörighet för den här åtgärden');
};

const handleAuthorization = async () => {
  showErrorNotification('Användaren har ingen åtkomst');
  await appState.signout();
  window.location = '/';
};


const handleRoleChange = _.debounce(async () => {
  let currentOrgSlug = appState._getCurrentOrgSlug();
  if (currentOrgSlug){
    await appState.fetchAndSetToken({ slug: currentOrgSlug  }, true);
   }
}, 2000);

const handleExpiredToken = async () => {
  showErrorNotification('Du har blivit utloggad, logga in igen.');
  appState.persistString("urlToRedirect", window.location.pathname);
  await appState.signout();
  window.location = '/';
};



export const installInterceptors = () => { 
  axios.interceptors.request.use(
    function (config) {
      let apiUrl = config.url;
      let params = config.params;
      config.baseURL = configHelper.getBaseAPIPath();
      config.url = configHelper.getOrgBasedUrl(apiUrl, params);
      if (config.progressBar) {
        NProgress.set(0);
        config.onDownloadProgress = (e) => {
          if (!e.total) {
            NProgress.done();
          } else {
            NProgress.set(e.loaded / e.total);
          }
        }
      }
      if (appState.getUserToken())
        config.headers.authorization = 'Bearer ' + appState.getUserToken();
      return config;
    },
    function (error) {
      // Do something with request error
      console.error('axiosRequestError', error);
      NProgress.done();
      console.error(error);
      return Promise.reject(error);
    },
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    async function (response) {
      if (response?.headers?.user_role_changed) {
        await handleRoleChange();
      }
      // Do something with response data
      NProgress.done();
      return response;
    },
    async function (error) {
      const response = error?.response;
      const errorStatus = response?.status;
      const errorMessage = response?.data?.message;
      const role = appState.getUserRole();
      const orgSlug = appState._getCurrentOrgSlug();


      //Condition to avoid unnecessary access denied issue 
      //when user try to access the URL without any orgSlug like test.accurator.se/
      //and authenticate/authorize/role-change error should only be shown when user is logged-in 
      if (orgSlug && orgSlug !== 'default' && appState.getUserId()) {        
        if (response?.headers?.user_role_changed){
          await handleRoleChange();
        }
        
        if (errorStatus === 401 && errorMessage === 'token.expired') {
          await handleExpiredToken();
        } else if (errorStatus === 401) {
          handleAuthorization();
        } else if (
          errorStatus === 415 || (role != 'supplier' && errorStatus === 403)
        ) {
          handleAccessDenied();
        }
      } else if ((orgSlug == 'default' || !orgSlug) && appState.getUserId() && errorStatus === 401 && errorMessage === 'token.expired') {
        await handleExpiredToken();
      } else if (errorStatus === 401) {
        handleAuthorization();
      }

      if (error.message === 'Network Error') showErrorNotification(`Nätverksfel. Kontrollera din internetanslutning.`);

      NProgress.done();
      console.error(error);
      return Promise.reject(error);
    },
  );
}
