import { FC } from "react";
import { components, SingleValue } from "react-select";
import { OptionType } from "../../../models/global.model";
import appState from "../../../state/AppStateContainer";
import { FileScope } from "../../../util/appEnum";
import CustomAvatar from "../CustomAvatar";
import { getImageUrl } from "../ImagePreview/ImagePreview";
import Selector from "../Selector/Selector";

type Props = {
  value : UserOptionType | null;
  onChange : (value: SingleValue<UserOptionType>) => void;
  options : UserOptionType[];
  isOptionDisabled : (user: UserOptionType) => boolean;
  label : string;
}

export type UserOptionType = OptionType & {
  _id: string;
  name: string;
  email: string;
  imgId?: string;
}

const renderOption = (value: UserOptionType) => (
  value ? <CustomAvatar style={{ marginRight: '8px' }} size="24px" src={value?.imgId ? getImageUrl(value.imgId, appState.getCurrentOrgSlug(), FileScope.UserAvatar, { userId: value._id }) : ''} alt={value.label} /> : null
);

const selectorStyles = {
  singleValue: (provided: {}, _ : any) => ({
    ...provided,
    marginLeft: 2,
  }),
  input: (provided: {}, _ : any) => ({
    ...provided,
    marginLeft: 0,
  }),
  option: (provided: {}, _ : any) => ({
    ...provided,
    paddingLeft: 18,
  }),
}

// These need to be defined outside of the render function (or be memoized) in order 
// to not cause re-rendering, blinking and lost focus
const selectorComponents = {
  Option: ({ children, ...rest } : any) => (
    <components.Option {...rest}>
      {renderOption(rest.data)} {children}
    </components.Option>
  ),
  SingleValue: ({ children, getValue, ...rest } : any) => {
    const value = getValue()[0];
    return (
      <components.SingleValue {...rest}>
        {value && (
          value ? <CustomAvatar 
            style={{ marginRight: '8px'}} 
            size="24px" 
            src={value?.imgId ? getImageUrl(value.imgId, appState.getCurrentOrgSlug(), FileScope.UserAvatar, { userId: value._id }) : ''} 
            alt={value.label} 
          /> : null
        )} 
        <div style={{display:"inline-block", lineHeight:"24px", verticalAlign:"middle"}}>{children}</div>
      </components.SingleValue>
    );
  },
}

const UserSelector : FC<Props> = ({value, onChange, options, isOptionDisabled, label}) => {
  return <Selector<UserOptionType>
    value={value}
    onChange={onChange}
    options={options}
    placeholder=''
    isOptionDisabled={isOptionDisabled}
    label={label}
    components={selectorComponents}
    styles={selectorStyles}
  />
}

export default UserSelector;