import { FC, useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import BtButton from "../../../ts-components/bt-button/bt-button";
import { DraggableIndicatorIcon } from "../../svgs";
import CustomToggle from "../CustomToggle";
import CustomizeIcon from "../../svgs/customizeIcon";
import styles from './CustomizationMenu.module.scss';
import { autosave } from "../../../util";

type Column = {
  name: string,
  show: boolean,
  static?: boolean,
}

type Props = {
  columns: Column[],
  setColumns: (columns: Column[]) => Promise<void>,
}

const CustomizationMenu: FC<Props> = ({ columns, setColumns }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isCustomizationOpen, setCustomizationOpen] = useState(false);

  const onUpdate = async (data: Column[]) => {
    autosave(async () => await setColumns(data.map(column => ({
      ...column,
      show: !column.static ? column.show : true,
    }))))
  }

  useEffect(() => {
    const handleWindowClick = (e: MouseEvent) => {
      const container = ref.current;
      if (container && !container.contains(e.target as Node)) {
        setCustomizationOpen(false);
      }
    }
    window.addEventListener('click', handleWindowClick);

    return () => {
      window.removeEventListener('click', handleWindowClick);
    }
  }, []);

  const handleOnDragEnd = async (result: DropResult) => {
    const items = Array.from(columns);
    const [reorderedItem] = items.splice(result.source.index, 1);
    if (result.destination) {
      items.splice(result.destination.index, 0, reorderedItem);
    }
  
    onUpdate(items);
  }
  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <div ref={ref} className={styles.tableCustomizationContainer}>
        <BtButton
          leftIcon={<CustomizeIcon />}
          color='white'
          onClick={() => setCustomizationOpen(prevState => !prevState)}
        >
          Anpassa tabell
        </BtButton>
        
        <Droppable droppableId="columns">
          {(provided) => (
            <ul className={styles.tableCustomizationMenu} style={{ display: isCustomizationOpen ? 'flex' : 'none' }} {...provided.droppableProps} ref={provided.innerRef}>
              {columns.map((column, index) => {
                return (
                  <Draggable key={column.name} draggableId={column.name} index={index}>
                    {(_provided) => (
                      <li ref={_provided.innerRef} {..._provided.draggableProps} {..._provided.dragHandleProps}>
                        <div>
                          <DraggableIndicatorIcon />
                          <span>{column.name}</span>
                        </div>
                        {!column.static && (
                          <CustomToggle
                            customClass=''
                            label=''
                            icons={false}
                            checked={column.show}
                            onChange={() => {
                              const newArr = [...columns];
                              newArr[index].show = !column.show;
                              onUpdate(newArr);
                            }}
                          />
                        )}
                      </li>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
        
      </div>
    </DragDropContext>
  )
}

export default CustomizationMenu;