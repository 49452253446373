import { FC, useState } from "react";
import Field from "../../components/shared/Field/Field";
import Modal from "../../components/shared/Modal/Modal";
import { NotificationManager } from "react-notifications";
import { ServiceAuth } from "../../services/service-auth";
import { validateEmail } from "../../util";

type Props = {
  initialEmail: string,
  show: boolean,
  setShow: (show : boolean) => void,
}

const ForgotPassword : FC<Props> = ({initialEmail, show, setShow}) => {
  const [email, setEmail] = useState(initialEmail);
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");

  const sendPasswordResetEmail = async () => {
    const trimmedEmail = email.trim();
    if (validateEmail(trimmedEmail) != "") {
      if (trimmedEmail) {
        setEmailError("Ogiltig e-postadress")
      } else {
        setEmailError("Du måste fylla i e-postadress")
      }
      return;
    }
    setLoading(true);
    try {
      await ServiceAuth.forgotPassword(trimmedEmail);
      NotificationManager.success('En länk för att återställa lösenordet har skickats till din e-post', 'Skickat');
      setLoading(false);
      setShow(false);
    } catch (e) {
      setLoading(false);
      NotificationManager.error(
        'Det finns inget konto med den angivna informationen.',
        'Inget konto hittades',
      );
    }
  };

  const onChange = (value : string) => {
    setEmailError("");
    setEmail(value);
  };

  return (
    <Modal 
      title="Återställ ditt lösenord"
      show={show} 
      setShow={setShow}
      buttonInfo={{label: "Skicka", action: sendPasswordResetEmail, loaderShow: loading}}
    >
      <div style={{maxWidth:"600px"}} className="custom-forgot-modal">
        <div className="forgot-modal-description">
          Fyll i den e-postadress du använde när du registrerade dig så skickar vi 
          e-post med en länk du kan använda for att återställa ditt 
          lösenord.
        </div>

        <Field 
          type="email" 
          errorMessage={emailError}
          label="E-post" 
          value={email}
          onChange={onChange} 
        />
      </div>
    </Modal>
  );
}


export default ForgotPassword;