import React, { FC, ReactNode, useEffect, useState } from 'react';
import styles from './TooltipContainer.module.scss';
import { usePopper } from 'react-popper';
import type { Modifier } from '@popperjs/core';

type Props = {
  renderReferenceComponent: (className: string, ref: any) => ReactNode,
  style?: React.CSSProperties,
  className?: string,
  delayed?: boolean,
}

const hideArrowWhenNotPointingInCenter: Partial<Modifier<string, object>> = {
  name: 'applyArrowHide',
  enabled: true,
  phase: 'write',
  fn({ state }) {
    const { arrow } = state.elements;

    if (arrow) {
      if (state.modifiersData.arrow && state.modifiersData.arrow.centerOffset !== 0) {
        arrow.setAttribute('data-hide', '');
      } else {
        arrow.removeAttribute('data-hide');
      }
    }
  },
};

const moveArrowWhenFlipping: Partial<Modifier<string, object>> = {
  name: 'moveArrowWhenFlipping',
  enabled: true,
  phase: 'write',
  fn({ state }) {
    const placement = state.placement;
    const { arrow } = state.elements;
    if (arrow) {
      if (placement === 'bottom') {
        arrow.setAttribute('data-top', '');
        arrow.removeAttribute('data-right');
        arrow.removeAttribute('data-bottom');
        arrow.removeAttribute('data-left');
      } else if (placement === 'top') {
        arrow.setAttribute('data-bottom', '');
        arrow.removeAttribute('data-top');
        arrow.removeAttribute('data-right');
        arrow.removeAttribute('data-left');
      } else if (placement === 'left') {
        arrow.setAttribute('data-right', '');
        arrow.removeAttribute('data-top');
        arrow.removeAttribute('data-bottom');
        arrow.removeAttribute('data-left');
      } else if (placement === 'right') {
        arrow.setAttribute('data-left', '');
        arrow.removeAttribute('data-top');
        arrow.removeAttribute('data-right');
        arrow.removeAttribute('data-bottom');
      }
    }
  }
}

const TooltipContainer: FC<Props> = ({ children, renderReferenceComponent, className, delayed, }) => {
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const { styles: popperStyles, attributes, forceUpdate } = usePopper(referenceElement, popperElement, {
    placement: 'top',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement,
          padding: 8,
        }
      }, {
        name: 'offset',
        options: {
          offset: [0, 13],
        },
      },
      hideArrowWhenNotPointingInCenter,
      moveArrowWhenFlipping,
      {
        name: "eventListeners",
        options: {
          scroll: false,
          resize: false,
        }
      }
    ],
  });

  useEffect(() => {
    if (forceUpdate && referenceElement) {
      let lastUpdateTimestamp = Date.now();
      referenceElement.onmouseover = () => {
        if (Date.now() - lastUpdateTimestamp > 500) {
          forceUpdate();
          lastUpdateTimestamp = Date.now();
        }
      }
    }

    return () => {
      if (referenceElement) {
        referenceElement.onmouseover = null;
      }
    }
  }, [forceUpdate, referenceElement]);

  return (
    <>
      {renderReferenceComponent && renderReferenceComponent(styles.referenceContainer, setReferenceElement)}

      {children && (
        <div ref={setPopperElement} className={`${styles.popperContainer} ${className || ''} ${delayed ? styles.delayed : ''}`} style={popperStyles.popper} {...attributes.popper}>
          {children}
          <div ref={setArrowElement} className={styles.arrow} style={popperStyles.arrow} />
        </div>
      )}
    </>
  );
}

export default TooltipContainer;
