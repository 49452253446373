import { VFC } from 'react';
import OptionsMenu from '../../../components/shared/OptionsMenu/OptionsMenu';
import ArrowIcon from '../../../components/svgs/arrowIcon';
import BtButton from '../../bt-button/bt-button';
import styles from './BidIntentControls.module.scss';
import {ReactComponent as CircleXIcon} from '../../../assets/svg/circleXIcon.svg';
import {ReactComponent as CircleCheckIcon} from '../../../assets/svg/circleCheckIcon.svg';

export enum BidIntentStatus {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  UNDECIDED = 'UNDECIDED'
}

type props = {
  status: BidIntentStatus,
  onChange: (value: BidIntentStatus) => void
}

export const bidIntentOptions = {
  [BidIntentStatus.ACCEPTED]: {
    color: 'var(--green-700)',
    text: 'Avser att lämna anbud',
    className: 'success',
    icon(color?: string, className?: string, ref?: any) {
      return <CircleCheckIcon className={className} ref={ref} fill={color || this.color} width='18px' height='18px' />
    }
  },
  [BidIntentStatus.DECLINED]: {
    color: 'var(--red-700)',
    text: 'Tacka nej',
    className: 'failure',
    icon(color?: string, className?: string, ref?: any) {
      return <CircleXIcon className={className} ref={ref} fill={color || this.color} width='18px' height='18px' />
    }
  },
}

const BidIntentControls: VFC<props> = ({ status = BidIntentStatus.UNDECIDED, onChange }) => {
  return (
    <div className={styles.container}>
      {status === BidIntentStatus.UNDECIDED ? (
        Object.entries(bidIntentOptions).map(([key, value]) => (
          <BtButton
            key={key}
            class='white'
            onClick={() => onChange(key as BidIntentStatus)}
            leftIcon={value.icon('var(--gray-700)')}
          >
            {value.text}
          </BtButton>
      ))) : (
        <OptionsMenu
          selectedItem={bidIntentOptions[status].text}
          options={Object.entries(bidIntentOptions).map(([key, value]) => ({label: value.text, action: () => onChange(key as BidIntentStatus)}))}>
          <BtButton
            rightIcon={<ArrowIcon color={bidIntentOptions[status].color} />}
            class={bidIntentOptions[status].className}
          >
            {bidIntentOptions[status].icon()}
            {bidIntentOptions[status].text}
          </BtButton>
        </OptionsMenu>
      )}
    </div>
  );
}

export default BidIntentControls;