import axios from 'axios';

class ServiceCollectiveAgreement {

  getCollectiveAgreement = async (organizationNumber) => {
    const res = await axios.get(`/api/collectiveAgreement`, { params: { organizationNumber } });
    return res.data.data;
  }
 
}

export default new ServiceCollectiveAgreement();
