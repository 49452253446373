import React from "react";
import "./app-chat-message.css";
import moment from "moment";
import PropTypes from "prop-types";
import _ from "lodash";
import ReactHtmlParser from "react-html-parser";
import Moment from "react-moment";
import AppChatPerson from "../app-chat/app-chat-person.jsx";
import * as DOMPurify from 'dompurify';
import File from '../../components/File/File';

export class AppChatMessage extends React.Component {
  element;

  files = this.props.files;
  name = this.props.name;
  type = this.props.type;
  date = this.props.date;
  message = this.props.message;
  email = this.props.email;
  phone = this.props.phone;
  read = this.props.read;
  newMessage = this.props.newMessage;

  static contextTypes = {
    router: PropTypes.object
  };

  getLocalDateTime(givenDate) {
    const utcDate = givenDate.toString();
    // var gmtDateTime = moment.utc(utcDate, "YYYY-MM-DD HH");
    const gmtDateTime = moment.utc(utcDate);
    const local = gmtDateTime.local().format("YYYY-MMM-DD h:mm A");
    return moment(local, "YYYY-MMM-DD h:mm A").format("llll");
  }
  render() {
    if (!this.props.files) {
      this.props.files = [];
    }
    const isHtml = /<\/?[a-z][\s\S]*>/i.test(this.props.message);
    return (
      <div
        className={`app-chat-message 
                message
                person-${this.props.type}
                ${this.props.newMessage ? "new-message" : ""}
                `}
      >
        <div className="overflow">
          <div className="wrapper">
            <div className="head">
              <AppChatPerson
                name={this.props.name}
                avatar={this.props.avatarUrl}
                {...this.props}
              />
              <div className="info">
                <span className="date">
                  {moment(this.props.date).format("YYYY-MM-DD HH:mm")}
                  </span>
              </div>
            </div>
            <div className="body">
              <span
                className={`${!isHtml ? "style--message-chat" : undefined}`}
                dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.props.message)}}
                style={{ marginBottom: '10px' }}
              >
                
              </span>
              <div className="uploaded-files" style={{ display: 'flex', 'flexWrap': 'wrap', gap: '10px' }}>
                {!_.isEmpty(this.props.files) &&
                  this.props.files.map((item, key) => {
                    return (
                      <File
                        key={key}
                        fileName={item.fileName || item.file}
                        file={item.file}
                        scope={this.props.scope}
                        scopeOptions={this.props.scopeOptions}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
