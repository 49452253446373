import React, { Component, createRef } from 'react';
import { serviceAreas } from '../../services/service-areas';
import { ServiceSupplier } from '../../services/service-supplier';
import services from '../../services';

import { debounce } from 'lodash';
import Select, { components } from 'react-select';
import ReactLoading from 'react-loading';
import { SearchIcon } from '../../components/svgs';
import Pagination from '@mui/material/Pagination';
import { tableIcons } from './icons';

import './dataTable.min.css';
import './app-supplier-database.css';
import './supplier-database-mini.css';

export default class SupplierDatabaseMini extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterSearch: {
        searchFilter: '',
        areaId: props.areaUid ? { value: props.areaUid, label: props.areaName } : '',
        kommun: '',
        rating: '',
        order: [{ column: '0', dir: 'asc' }],
        columns: [
          { data: '0', name: 'name' },
          { data: '1', name: 'area' },
          { data: '2', name: 'kommun' },
        ],
        draw: '1',
        start: 0,
        project: props.selectMode ? props.selectMode : false,
      },
      areas: [],
      cities: [],
      data: [],
      isLoading: false,
      addUpdateSupplier: false,
      modalAddAreaShown: false,
      modalAddAreaDataLoading: false,
      showCreditReport: false,
      selectedSupplierIds: [...(props.selectedSupplierIds || [])],
      editSupplierData: [],
      creditReport: {},
      currentPage: 1
    };
    this.tableRef = createRef();
    this.debouncedSearch = debounce(this.fetchSearchData, 1000);
  }

  async componentDidMount() {
    let filterData = this.state.filterSearch;
    const { areaUid, modifySuppName } = this.props;

    if (modifySuppName) {
      this.handleChange(modifySuppName, 'searchFilter');
    } else {
      if (areaUid) filterData.areaId = areaUid;

      this.fetchSuppliers(filterData);
    }

    this.fetchAdditionalData();
  }

  fetchAdditionalData = () => {
    this.fetchCities();
    this.fetchAreas();
  };

  resetFilter = () => {
    this.setState({
      filterSearch: {
        searchFilter: '',
        areaId: '',
        kommun: '',
        rating: '',
        order: [{ column: '0', dir: 'asc' }],
        columns: [
          { data: '0', name: 'name' },
          { data: '1', name: 'area' },
          { data: '2', name: 'kommun' },
        ],
      },
      selectedSupplierIds: [],
    });
  };

  getSupplierIds = () => this.state.selectedSupplierIds;

  toggleSort = colNum => {
    const { filterSearch } = this.state;
    const order = filterSearch.order[0];
    if (order.column !== colNum) {
      order.column = colNum;
      order.dir = 'asc';
    } else {
      order.dir = order.dir === 'asc' ? 'desc' : 'asc';
    }
    this.handleChange([order], 'order');
  };

  handleSupplierRowClick(rowData, selectedIds = this.state.selectedSupplierIds || []) {
    if (!rowData) return;

    const index = selectedIds.findIndex(s => s == rowData.sup_id);
    let newSupplierIds = [...selectedIds];

    if (index <= -1) {
      newSupplierIds.push(rowData.sup_id);
    } else {
      newSupplierIds.splice(index, 1);
    }

    this.setState({ selectedSupplierIds: newSupplierIds });
    if (this.props.selectEvent) this.props.selectEvent(newSupplierIds);
  }

  handleChange = (value, type, togglePage) => {
    let { filterSearch } = this.state;
    const { selectMode } = this.props;
    let filters = filterSearch;

    if (type !== 'searchFilter') {
      filters[type] = value;
      this.setState({
        filterSearch: filters,
      });
    }

    let filterData = {
      searchFilter: filterSearch.searchFilter,
      areaId: filterSearch.areaId,
      kommun: filterSearch.kommun,
      project: selectMode ? selectMode : false,
      rating: filterSearch.rating,
      order: filterSearch.order,
      columns: filterSearch.columns,
      start: filterSearch.start,
    };

    if(togglePage){
      this.setState({
        currentPage: 1
      })
      filterData.start = 0
    }

    this.fetchSuppliers(filterData);
  };

  renderDropdown = (placeholder, options, type) => {
    const { filterSearch } = this.state;

    const DropdownIndicator = props => {
      const { SortArrow } = tableIcons;
      return (
        <components.DropdownIndicator {...props}>
          <SortArrow color={'#2b3046'} />
        </components.DropdownIndicator>
      );
    };

    return (
      <div className="bt-select-wrapper notranslate">
        <div className="bt-select" select="">
          <Select
            isSearchable={false}
            placeholder={placeholder}
            options={options}
            onChange={e => this.handleChange(e.value, type, true)}
            classNamePrefix="supplierDB"
            className="supplierDB-dropdown"
            components={{ DropdownIndicator }}
            defaultValue={filterSearch[type]}
          />
        </div>
      </div>
    );
  };

  checkSelectedSupplier = sup => {
    const { selectedSupplierIds = [] } = this.state;
    return selectedSupplierIds.length ? selectedSupplierIds.findIndex(s => s == sup.sup_id) > -1 : false;
  }

  fetchAreas = async () => {
    const areas = [{ label: 'Alla', value: '' }];
    const areasData = await serviceAreas.getAreas();
    areasData.forEach(item => areas.push({ label: item.name, value: item._id }));

    this.setState({ areas });
  };

  fetchCities = async () => {
    await services.getCities().then(data => {
      let cities = [{ label: 'Alla', value: '' }];
      data.forEach(item => cities.push({ label: item, value: item }));
      this.setState({ cities });
    });
  };

  toggleLoader = () => this.setState({ isLoading: !this.state.isLoading });

  fetchSuppliers = async sup => {
    this.toggleLoader();
    sup.mini = true; //fetch data for mini database without credit data
    const data = await ServiceSupplier.fetchSupplier(sup);
    this.toggleLoader();

    if (data.data) {
      let _data = { data: data.data, page: 0, totalCount: data.recordsFiltered };
      this.setState({ data: _data, draw: parseInt(data.draw) + 1 });
    }
  };

  fetchSearchData = input => {
    this.handleChange(input, 'searchFilter');
  };

  search = event => {
    const searchKey = event.target.value;
    let filterData = this.state.filterSearch;
    filterData['searchFilter'] = searchKey;

    this.setState({
      currentPage: 1
    })
    filterData.start = 0

    this.setState({ filterData });
    this.debouncedSearch(searchKey);
  };

  customHeader = (title, colNum) => {
    const { SortArrow } = tableIcons;
    const isActive = this.state.filterSearch.order[0].column === colNum;
    const titleClass = isActive ? 'sorted' : '';

    return (
      <div onClick={() => this.toggleSort(colNum)} className={`sup-db-title ${titleClass}`}>
        {title}
        {isActive && <SortArrow />}
      </div>
    );
  };

  onPageChanged = currentPage => {
    this.setState({
      currentPage
    });
    const pageOffset = (currentPage - 1) * 10;
    this.handleChange(pageOffset, 'start');
  };

  handleRowClick = rowData => {
    const { selectMode, mini } = this.props;
    if (selectMode || mini) {
      this.handleSupplierRowClick(rowData);
      return;
    }
  };

  getColumns = () => {
    return [
      {
        title: this.customHeader('Företagsnamn', '0'),
        field: 'sup_name',
      },

      {
        title: this.customHeader('Yrkesområde', '1'),
        field: 'area',
      },

      {
        title: this.customHeader('Kommun', '2'),
        field: 'city',
      },
    ];
  };

  render() {
    const { selectMode } = this.props;
    const { data, cities, areas, isLoading, filterSearch, selectedSupplierIds } = this.state;

    return (
      <div className="column-content sp-main-container">
        <div className="sp-filters-main">
          <div class="ar-row">
            <div className="ar-column sp-input-col">
              <div className="bt-search">
                <div className="left">
                  <SearchIcon size={18} />
                </div>

                <div className="right search--wrapper">
                  <input
                    ref={el => (this.inputSearch = el)}
                    name="search"
                    placeholder="Sök"
                    value={filterSearch.searchFilter}
                    onChange={this.search}
                  />
                </div>
              </div>
            </div>

            <div className="ar-column sp-filter-other">
              <div className="sp-filter-other-container">
                {this.renderDropdown('Yrkeskategori', areas, 'areaId')}
                {this.renderDropdown('Kommun', cities, 'kommun')}
              </div>
            </div>
          </div>
        </div>

        <div class="sp-db-mini-table">
          <table>
            <thead>
              <tr>
                <th>{this.customHeader('Företagsnamn', '0')}</th>
                <th>{this.customHeader('Yrkesområde', '1')}</th>
                <th>{this.customHeader('Kommun', '2')}</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <ReactLoading color="#ff5e15" type="spin" width="25px" height="25px" />
              ) : data.data && data.data.length ? (
                data.data.map(sup => (
                  <tr
                    className={`${this.checkSelectedSupplier(sup) ? 'selected' : ''} miniDatabaseRow`}
                    onClick={() => this.handleRowClick(sup)}
                  >
                    <td>{sup.sup_name}</td>
                    <td>{sup.area}</td>
                    <td>{sup.city}</td>
                  </tr>
                ))
              ) : (
                <div class='no-record-text'><div>Kunde inte hitta något resultat</div></div>
              )}
            </tbody>
          </table>

        </div>

        {!selectMode && (
          <div className="sup-pagination">
            {data.totalCount > 0 && (
              <Pagination
                count={Math.ceil(data.totalCount/10)}
                onChange={(_, value) => this.onPageChanged(value)}
                page={this.state.currentPage}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}
