import Collapsible from "../../../components/shared/Collapsible";
import { ServiceProjects } from "../../../services/service-projects";
import React from "react";
import appState from "../../../state/AppStateContainer";
import moment from "moment";
import BidIntentControls, { BidIntentStatus } from "../BidIntentControls/BidIntentControls";
import { ServiceQuotation } from "../../../services/service-quotation";
import BtButton from "../../bt-button/bt-button";
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import ShowMoreText from "../../../components/shared/ShowMoreText";
import HTMLParser from "../../../components/shared/HTMLParser/HTMLParser";
import { DemandsContainer, LabeledBLock } from "../StaticComponents";
import fileDownload from "js-file-download";
import _ from "lodash";
import { ReactComponent as ShareIcon } from '../../../assets/svg/shareIcon.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/svg/calendarIcon.svg';
import { ReactComponent as RoundedSquareCheckbox } from '../../../assets/svg/rounded_square.svg';
import { ReactComponent as ClipIcon } from '../../../assets/svg/clip.svg';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import ical from "ical-generator";
import { btHasObject } from "../../../utils/bt-array";
import { formatPrice } from "../../../util";
import Selector from "../../../components/shared/Selector/Selector";
import { NotificationManager } from 'react-notifications';
import $ from 'jquery';
import './CollapsibleView.scss';
import { ServiceSupplier } from "../../../services/service-supplier";
import Modal from "../../../components/shared/Modal/Modal";
import TitleWrapper from "../../../components/shared/TitleWrapper/TitleWrapper";
import QuoteSharingModal from "../QuoteSharingModal/QuoteSharingModal";
import { getUID } from '../../../global/app';
import DatePicker from "react-datepicker";
import helperImage from "../../../assets/images/help.png"
import TooltipContainer from "../../../components/shared/TooltipContainer/TooltipContainer";
import Spinner from "../../../components/shared/Spinner/Spinner";
import DownloadButton from "../../../components/shared/DownloadButton";
import File from "../../../components/File/File";
import BtRow from "../../bt-row/bt-row";
import BtColumn from "../../bt-column/bt-column";
import { FileScope } from "../../../util/appEnum";
import Field from "../../../components/shared/Field/Field";
import { ErrorMessageIcon } from "../../../components/shared/ErrorMessageIcon/ErrorMessageIcon";
import StyledCheckbox from "../../../components/shared/StyledCheckbox/StyledCheckbox";
import { injectIntl } from "react-intl";
import Editor from "../../../components/shared/Editor/Editor";

const quotationValidityOptions = [ 30, 60, 90 ];

const defaultBidDeclineReasons = [
  { label: 'Har ej kapacitet att utföra jobbet', value: 'Har ej kapacitet att utföra jobbet' },
  { label: 'Omfattning otydlig', value: 'Omfattning otydlig' },
  { label: 'För kort anbudstid', value: 'För kort anbudstid' },
  { label: 'Ej intresserad', value: 'Ej intresserad' },
];

class CollapsibleView extends React.Component {

  elInputFile;
  constructor(props, context) {
    super(props, context);
    this.state = {
      showInfo: true,
      supplierData: [],
      supplieBidForm: [],
      projectAreaData: [],
      projectData: [],
      quotationAttachments: [],
      quotationDemands: [],
      files: [],
      newFiles: [],
      quoteMessageContent: '',
      reservationContent: '',
      price: '',
      supplierId: '',
      quotationId: '',
      processForm: false,
      demands: [],
      demandsError: '',
      supplierPriceDetails: [],
      showBidDeclinedModal: false,
      bidDeclinedReason: '',
      previousBidIntent: null,
      quotationValidity: '',
      showRequestShareModal: false,
      quoteSharingBtnLoader: false,
      openView: props.openView,
      isSubmittingRejectingIntent: false,
    };
    this.quotationDataNode = React.createRef(null);
  }
  componentDidMount() {
    const { versionData, isPublic } = this.props;
    this.setState({ allVersions: versionData });
    this.setQuotationData(versionData);

    // Update seen status only when supplier expand the collapsible view
    if(this.props.openView && !isPublic) this.updateQuotationIsSeenStatus(versionData);
  }

  componentDidUpdate(prevProps) {
    const { versionData } = this.props;
    const { versionData: oldVersionData } = prevProps;
    if (!_.isEqual(versionData, oldVersionData)) {
      this.setQuotationData(versionData);
    }
  }

  updateQuotationIsSeenStatus = quoteData => {
    const { quoteRefId, supplier, quoteVersionNo, quotation, supplierMemberId } = quoteData;
    if (supplierMemberId && appState.isSupplierRole()) {
      const payload = {
        quoteRefId,
        supplierId: supplier._id,
        quotationId: quotation.id,
        areaId: quotation.area,
        quoteVersionNo,
        supplierMemberId,
      };

      ServiceQuotation.updateQuotationIsSeen(payload);
    }
  };

  setQuotationData(quotationData) {
    let demands = [];
    if (quotationData && quotationData.supplierBid && quotationData.supplierBid.demands) {
      let mapQuotationDemands = quotationData.quotation.demands.map(d => {
        return { demandId: d.demandId._id };
      });
      demands = quotationData.supplierBid.demands.filter(demand => {
        return btHasObject(mapQuotationDemands, 'demandId', demand.demandId);
      });
    }
    this.setState(
      {
        supplierSlug: quotationData.supplierSlug,
        supplierSlugForReal: quotationData.supplier?.slug,
        supplierMemberId: quotationData.supplierMemberId,
        quotationData: quotationData.quotation,
        supplierData: quotationData.supplier,
        supplieBidForm: quotationData.supplierBid,
        projectAreaData: quotationData.quotation.area,
        projectData: quotationData.quotation.project,
        quotationAttachments: quotationData.quotation.attachments,
        quotationDemands: quotationData.quotation.demands,
        files: quotationData.supplierBid ? quotationData.supplierBid.attachments : [],
        quoteMessageContent: quotationData.supplierBid ? quotationData.supplierBid.message ?? null : null,
        reservationContent: quotationData.supplierBid ? quotationData.supplierBid.content ?? null : null,
        price: quotationData.supplierBid ? quotationData.supplierBid.price : null,
        supplierId: quotationData.supplier?._id,
        quotationId: quotationData.quotation.id,
        supplierPriceDetails:
          quotationData.supplierBid && !_.isEmpty(quotationData.supplierBid.priceDetails)
            ? quotationData.supplierBid.priceDetails.map(x => ({
                priceType: x.priceType.name,
                price: formatPrice(x.price || 0),
                _id: x.priceType._id,
              }))
            : quotationData.quotation.priceDetails.map(x => ({
                priceType: x.priceType.name,
                price: formatPrice(x.price || 0),
                _id: x.priceType._id,
              })),
        demands: demands,
        quoteVersionNo: quotationData.quoteVersionNo,
        quoteRefId: quotationData.quoteRefId ? quotationData.quoteRefId : '',
        status: quotationData.status,
        intentStatus: quotationData?.intentStatus?.intent || BidIntentStatus.UNDECIDED,
        wasEverSubmitted: quotationData.wasEverSubmitted,
        quotationValidity: this.setQuotationValidity(quotationData?.supplierBid?.quotationValidity),
      },
    );
  }

  toggleRequestShareModal = () => {
    this.setState({ showRequestShareModal: !this.state.showRequestShareModal });
  };

  setQuotationValidity = value => {
    if (typeof value !== 'string') return "";
    return new Date(value);
  };

  renderQuotationInfo = quote => {
    const { status, quotation, quoteVersionNo, supplier } = quote;
    const { project:projectData, area:projectAreaData } = quotation;
    const isPublic = this.props.isPublic;

    const isFormDisabled = (bidStatus = this.state.status) => {
      const isReadOnlyAccessRole = appState.isReadOnlyAccessRole();
      return isReadOnlyAccessRole || bidStatus?.isLocked;
    }
    
    const isDisabled = isFormDisabled();
    const { openView } = this.state;
    const versionNumber = parseInt(quoteVersionNo + 1);
  
    const unlockBidForm = async () => {
      await ServiceProjects.unlockBidForm(
        projectData.slug,
        projectAreaData._id,
        supplier._id,
        quoteVersionNo,
      );
      this.setState({
        status: { ...status, reason: 'Unlocked', isLocked: false, unlockedAt: new Date() },
      });
    };

    const getUnlockedPart = (bidStatus = this.state.status) => {
      let statusText = '';

      if (bidStatus.reason == 'Unlocked') {
        statusText += ' Anbudsformuläret är upplåst.';
      }

      return statusText;
    };

    const formatDate = date => {
      return date && moment(date).locale('sv').format('D MMMM YYYY');
    };

    const getDatePart = (bidStatus = this.state.status) => {
      const hasDateExpiredOnce = moment(status.expirationDate).isBefore(new Date());

      let datePart = '';

      if (bidStatus.submittedAt) {
        datePart += `Besvarad ${formatDate(bidStatus.submittedAt)}`;
      } else {
        if (bidStatus.reason == 'Expired' || hasDateExpiredOnce) {
          datePart += `Utgånget den ${formatDate(bidStatus.expirationDate)}`;
        } else {
          datePart += `Utgångsdatum den ${formatDate(bidStatus.expirationDate)}`;
        }
      }

      return datePart;
    };

    const showClockIcon = () => {
      const { status } = this.state;
      if (status.reason == 'NotLatestVersion' && !status.submittedAt) return;
      return <AccessTimeIcon fontSize="small" />;
    }

    const getStatusMessage = (bidStatus = this.state.status) => {
      let statusMessage = '';

      if (bidStatus.reason == 'NotLatestVersion') {
        if (bidStatus.submittedAt) {
          statusMessage += getDatePart(bidStatus) + ' ';
        } else {
          statusMessage = 'Endast senaste versionen går att skicka in.';
        }
      } else if (appState.isReadOnlyAccessRole()) {
        statusMessage = getDatePart(bidStatus);
      } else {
        statusMessage = getDatePart(bidStatus) + getUnlockedPart(bidStatus);
      }

      return statusMessage;
    };

    const renderIntentSelector = () => {
      const { intentStatus } = this.state;

      return (
        <div className="page-quotation-status-div">
          {(appState.isSupplierRole() || appState.isWriteAccessRole()) && (
            <BidIntentControls
              status={intentStatus}
              onChange={async value => {
                if (intentStatus != value) {
                  if (value === BidIntentStatus.DECLINED) {
                    this.setState({
                      previousBidIntent: intentStatus,
                      intentStatus: value,
                      showBidDeclinedModal: true,
                    });
                  } else {
                    this.setState({ intentStatus: value });
                    try {
                      await ServiceQuotation.updateBidIntent(
                        projectData.slug,
                        projectAreaData.slug,
                        supplier._id,
                        parseInt(quoteVersionNo),
                        { intent: value, reasonDeclined: '' },
                        this.state.supplierMemberId
                      );
                      NotificationManager.success('Tack! Din avsiktsförklaring är sparad.');
                    } catch (error) {
                      NotificationManager.error(`${error} Oväntat fel`)
                      throw error;
                    }
                  }
                }
              }}
            />
          )}
        </div>
      );
    };

    const fileViewer = (item, i) => {
      const { showAllAttachedFiles } = this.state;
      if (!showAllAttachedFiles && i >= 3) return;
      return (
        <File
          key={item.fileId}
          file={item.fileId || item.fileName}
          fileName={item.fileName}
          disabled={!item.fileId}
          scope={FileScope.BidRequestAttachment}
          scopeOptions={{
            quotationId: this.props?.versionData?.quoteRefId,
            versionNumber: this.props?.versionData?.quoteVersionNo,
          }}
        />
      );
    };

    const generateCalendarFile = () => {
      const calendar = ical();
      calendar.createEvent({
          allDay: true,
          start: moment(this.state?.status?.expirationDate),
          summary: `${this.state.projectAreaData.name} till ${this.state.projectData.name}, ${appState.getCurrentOrgName()} – Sista dagen att lämna anbud`,
          description: `Öppna anbudssidan i Accurator<${window.location.href}>`,
      });
  
      return calendar.toString()
    }

    const renderContentBlock2 = () => {
      const { status } = this.state;
      const isDisabled = isFormDisabled();
      const userName = appState.getUserName();

      const downloadCalendarFile = () => fileDownload(
        generateCalendarFile(),
        'AccuratorEvent',
        'text/calendar'
      );

      const openRequestShareModal = async () => {
        const { supplierData } = this.state;
        const userId = appState.getUserId();
        this.toggleRequestShareModal();
        this.setState({ showRequestShareModal: true, fetchingSupplierMembers: true });
        let supplierMembers = [];
        if (supplierData?._id) {
          supplierMembers = await ServiceSupplier.getSupplierMembers(supplierData?._id);
          supplierMembers = supplierMembers.filter(member => member._id !== userId);
        }
        this.setState({ supplierMembers, fetchingSupplierMembers: false });
      };

      return (
        <div className="padded-block content-block2">
          <div className="sub-block">
            <span><PersonOutlineIcon fontSize="small" /> Svarar som {userName}</span>
            {!isPublic && (
              <BtButton size='xxs' color='white' disabled={isDisabled} onClick={!isDisabled ? openRequestShareModal : undefined}  leftIcon={<ShareIcon style={{ color: 'var(--gray-700)' }} fontSize="small" />} className="quotation-share-button">
                Dela förfrågan
              </BtButton>
            )}
          </div>
          <div className="sub-block">
            <span>{showClockIcon()}{getStatusMessage()}</span>
            {(!isDisabled && status.expirationDate && moment(status.expirationDate).diff(new Date(), 'days') >= 0) && (
              <div className="calendar-wrapper">
                <TooltipContainer delayed renderReferenceComponent={(className, ref) =>
                  <BtButton class={className} ref={ref} size='xxs' color='white' leftIcon={<CalendarIcon style={{ fill: 'var(--gray-700)', width: '15px' }} />} onClick={downloadCalendarFile}>
                    Lägg till i kalender
                  </BtButton>
                }>
                  <p style={{width:"300px"}}>
                    Klicka för att ladda ner en händelse som .ics-fil. Öppna .ics-filen för att lägga till händelsen i din kalender, 
                    t.ex. Outlook. Om du vill att .ics-filer alltid ska öppnas i din kalender, kan du kryssa för “Öppna alltid filer 
                    av den här typen” i Chrome.
                  </p>
                  <img src={helperImage} width="300px" style={{ borderRadius: '8px' }} />
                </TooltipContainer>
              </div>
            )}
            {openView && shouldButtonBeVisible() && (
                <BtButton
                  size='xxs'
                  color='white'
                  onClick={unlockBidForm}
                  leftIcon={<LockOpenOutlinedIcon style={{ width: '18px' }} />}
                >
                  Lås upp
                </BtButton>
              )}
          </div>
        </div>
      );
    };

    const onToggleFileViewer = () => {
      const { quotationAttachments } = this.state;
      if (quotationAttachments?.length) {
        this.setState({ showAllAttachedFiles: !this.state.showAllAttachedFiles });
      }
    };

    const renderContentBlock3 = () => {
      const { 
        quotationData,
        showAllAttachedFiles,
        projectData,
        projectAreaData,
        supplierData,
        quoteVersionNo
      } = this.state;
      const { slug: projectSlug, name: projectName } = projectData;
      const { slug: areaSlug, name: areaName } = projectAreaData;
      const { _id: supplierId } = supplierData ?? {};

      const quoteAttachments = quotationData?.attachments;
      const hasMoreThan3Documents = quoteAttachments?.length > 3;

      return (
        <div className="padded-block content-block3">
          <LabeledBLock label="Information" containerClass="information ">
            <ShowMoreText>
              <HTMLParser data={quotationData?.content} />
            </ShowMoreText>
          </LabeledBLock>
          <LabeledBLock
            label="Handlingar"
            containerClass="files-container"
            childrenClass="files-sub-container"
          >
            <div className="files-viewer-container">{quoteAttachments?.map(fileViewer)}</div>
            {hasMoreThan3Documents && (
              <div className="bottom-text" onClick={onToggleFileViewer}>
               {showAllAttachedFiles ? `Se mindre` : `Visa alla ${quoteAttachments?.length} filer`}
              </div>
            )}
            {quoteAttachments?.length > 0 && (
              <DownloadButton
                url={ServiceQuotation.getDownloadQuotationFilesInZipURL({
                  projectSlug,
                  areaSlug,
                  supplierId,
                  versionNumber: quoteVersionNo,
                  orgId: quotationData.area.orgId,
                })}
                fileName={`${projectName} - ${areaName} - V${quoteVersionNo + 1} - Handlingar.zip`}
                render={({ downloading, progress, onClick }) => (
                  downloading ? (
                    <div className="zipDownloadProgressContainer" onClick={onClick}>
                      <span>{progress === 0 ? 'Förbereder' : 'Laddar ner'}</span>
                      <Spinner progress={progress} />
                    </div>
                  ) : (
                    <div className="bottom-text" onClick={onClick}>
                      Ladda ner alla handlingar som .zip
                    </div>
                  )
                )}
              />
            )}
          </LabeledBLock>
        </div>
      );
    };

    const shouldButtonBeVisible = () => {
      const { status } = this.state;
      const { isPublic } = this.props;
      const isWriteAccessUser = appState.isWriteAccessRole();

      if (isWriteAccessUser && status.isLocked && !isPublic) return true;
      return false;
    };

    const renderFormContent = () => {
      const isDisabled = isFormDisabled();
      const { reservationContent, quoteMessageContent, demands, quotationDemands, quotationData, quotationValidity } = this.state;
  
      const btCheckClickHandler = target => {
        var demands = this.state.demands;
        var index = demands.findIndex(demand => {
          return demand.demandId == target.demandId._id;
        });
        if (index == -1) {
          demands.push({ demandId: target.demandId._id, required: target.required });
        } else if (index > -1) {
          demands.splice(index, 1);
        }
        let demandsError = this.state.demandsError;
        if (this.state.demands.length > 0) {
          demandsError = '';
        }
        this.setState({ demands: [...demands], demandsError: demandsError });
      };
  
      const renderDemands = () => {
        const items = quotationDemands?.map((item, key) => (
          <li data-uid={key} className="page-quotation-industry-item" key={key}>
            <label className='page-quotation-demands-label' style={{ cursor: isDisabled ? 'not-allowed' : 'pointer', pointerEvents: isDisabled ? 'none' : 'auto', color: 'var(--gray-900)' }}>
              <StyledCheckbox
                checked={btHasObject(demands, 'demandId', item.demandId._id)}
                onClick={() => !isDisabled ? btCheckClickHandler(item) : null}
              />
              {item.demandId.name}
              {item.required && <span style={{ color: 'var(--orange-500)', marginRight: '8px' }}>*</span>}
              <ErrorMessageIcon errorMessageStyles={{  whiteSpace: 'nowrap' }} style={{ width: 'fit-content', display: 'inline-block' }} errorMessage={item.errorMessage} />
            </label>
          </li>
        ));
        return <ul className="page-quotation-industry-items">{items}</ul>;
      };

      const elBtnAttachClickCallback = _e => {
        this.elInputFile.click();
      };

      const getFileObject = (item) => {
        return {
          id: getUID(),
          fileId: item.fileId || item._id,
          fileName: item.name || item.fileName,
          mimeType: item.mimeType,
          fileObj: item._id ? {} : item,
        };
      }

      const uploadItemRemoveClickCallback = index => {
        if (this.state.files && this.state.files.length > 0) {
          var file = this.state.files[index];
          delete this.state.newFiles[file.id];
          this.state.files.splice(index, 1);
    
          this.setState({
            files: [...this.state.files],
            newFiles: this.state.newFiles,
          });
        }
      };
    

      const elInputFileChangeCallback = e => {
        var arr = [];
        var files = e.target.files;
        for (let i = 0; i < files.length; i++) {
          var fileObj = getFileObject(files[i]);
          arr.push(fileObj);
          this.state.newFiles[fileObj.id] = fileObj;
        }
        this.setState({
          files: [...this.state.files, ...arr],
          newFiles: this.state.newFiles,
        });
      };

      const renderEditor = (title, editorValue, onChange, attachFile, ) => {
        const isDisabled = isFormDisabled();
        const {
          projectData,
          projectAreaData,
          supplierData,
          quoteVersionNo,
          files,
          status,
        } = this.state;

        const { slug: projectSlug, name: projectName } = projectData;
        const { slug: areaSlug, name: areaName } = projectAreaData;
        const { _id: supplierId } = supplierData ?? {};

        return (
          <BtRow>
            <BtColumn className="app-chat-mail-batch quotation-main-box">
              <span style={{ marginBottom: '8px' }} className="headline-label">{title}</span>
              <div className="message">
                <div className="message-wrapper" id="quotation-editor-wrapper">
                  <div>
                    <Editor
                      value={editorValue}
                      onChange={onChange}
                      isDisabled={isDisabled}
                      wrapperStyle={{ height: '291px' }}
                    />
                  </div>
                </div>
                {attachFile && (
                  <div className="quote-file-upload-container">
                    <div
                      className="btn-attach-wrapper"
                      onClick={elBtnAttachClickCallback}
                      style={{ padding: '0' }}
                    >
                      <div style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}>
                        <input
                          type="file"
                          multiple
                          onClick={event => {
                            event.target.value = null;
                          }}
                          onChange={!isDisabled ? elInputFileChangeCallback : null}
                          disabled={isDisabled}
                          ref={el => (this.elInputFile = el)}
                        />
                        <BtButton color='white' size='xxs' leftIcon={<ClipIcon style={{ fill: isDisabled ? 'var(--gray-500)' : '' }} />} disabled={isDisabled}>Ladda upp bilaga, t.ex. offert</BtButton>
                      </div>
                    </div>
                    <div className="upload-files-wrapper quotation-uploaded-files">
                      {files?.map((file, index) => <File
                        key={file._id}
                        file={file?.fileId || ''}
                        fileName={file.fileName}
                        blob={!file?.fileId && file.fileObj}
                        onRemove={!isDisabled ? (() =>  uploadItemRemoveClickCallback(index)) : undefined}
                        scope={FileScope.BidResponseAttachment}
                        scopeOptions={{
                          quotationId: this.props?.versionData?.quoteRefId,
                          versionNumber: this.props?.versionData?.quoteVersionNo,
                          supplierId: this.props?.versionData?.supplier?._id
                        }}
                      />)}
                      {status.isLocked && files?.length > 0 && (
                        <DownloadButton
                          url={ServiceQuotation.getDownloadQuotationResponseFilesInZipURL({
                            projectSlug,
                            areaSlug,
                            supplierId,
                            versionNumber: quoteVersionNo,
                            orgId: this.props?.versionData?.quotation?.area?.orgId,
                          })}
                          fileName={`${projectName} - ${areaName} - V${quoteVersionNo + 1} - Handlingar.zip`}
                          render={({ downloading, progress, onClick }) => (
                            downloading ? (
                              <div className="zipDownloadProgressContainer" onClick={onClick}>
                                <span>{progress === 0 ? 'Förbereder' : 'Laddar ner'}</span>
                                <Spinner progress={progress} />
                              </div>
                            ) : (
                              <div style={{ marginTop: 0 }} className="bottom-text" onClick={onClick}>
                                Ladda ner alla handlingar som .zip
                              </div>
                            )
                          )}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </BtColumn>
          </BtRow>
        );
      };

      const savePrice = (name, price, id, required) => {
        // TODO : we need to show update the price if object exist otherwise we need push a create a object
        const isSupplierPriceAlreadyExist = this.state.supplierPriceDetails.filter(
          x => x.priceType === name,
        );
        if (!_.isEmpty(isSupplierPriceAlreadyExist)) {
          this.setState({
            [name]: '',
            supplierPriceDetails: this.state.supplierPriceDetails.map(el =>
              el.priceType === name ? { ...el, price } : el,
            ),
          });
        } else {
          this.setState({
            [name]: '',
            supplierPriceDetails: [
              ...this.state.supplierPriceDetails,
              {
                price,
                priceType: name,
                required: required,
                _id: id,
              },
            ],
          });
        }
      };

      const formatInput = (price = '') => {
        price = JSON.stringify(price);
        var pp = +price.replace(/\D/g, '');
        var tt = pp.toLocaleString();
        tt = tt.replace(new RegExp(',', 'g'), '.');
        return tt == 0 ? '' : tt;
      }

      const getFieldInfo = value => {
        const valueObj = this.state.supplierPriceDetails?.find(f => f?.priceType === value);
        if (!_.isEmpty(valueObj) && valueObj.priceType) {
          return {
            price: valueObj.price,
            errorMessage: valueObj.errorMessage,
          };
        }

        return {
          price: '',
          errorMessage: '',
        }
      };

      const renderPriceColumn = pd => {
        const isDisabled = isFormDisabled();
        const fieldInfo = getFieldInfo(pd.priceType.name);
        return (
          <Field
            label={pd.priceType.name}
            onChange={(value) => savePrice(pd.priceType.name, value, pd.priceType._id, pd.required)}
            value={formatInput(fieldInfo.price)}
            disabled={isDisabled}
            errorMessage={fieldInfo.errorMessage}
            required={pd.required}
            unit={pd.unit}
          /> 
        );
      };
      const onQuoteMessageContentChange = quoteMessageContent => {
        this.setState({
          quoteMessageContent: quoteMessageContent,
        })
      }

      const onReservationContentChange = reservationContent => {
        this.setState({
          reservationContent: reservationContent,
        })
      }

      const getEditorStateContent = editorState => {

        const isEmpty = str => {
          return $.trim($('<div>' + str + '</div>').text()) === '';
        };

        if (isEmpty(editorState) || editorState === null) {
          return '';
        } else {
          return editorState;
        }
      };

      const unformatPrice = (formattedPrice = '') => {
        let unformatted = formattedPrice;
    
        const decimalSeparatedArray = formattedPrice.toString().split(',');
    
        const integerValue = decimalSeparatedArray[0].split('.').join('');
        unformatted = integerValue;
    
        return unformatted.replace(/\D/g, '');
      };

      const saveQuotation = async () => {
        const {
          files,
          newFiles,
          price,
          quoteMessageContent,
          demands,
          quotationData,
          reservationContent,
          quotationValidity,
        } = this.state;

        let quotationDemands = this.state.quotationDemands.map(demand => ({ ...demand, errorMessage: '' }));
        let supplierPriceDetails = this.state.supplierPriceDetails.map(priceField => ({ ...priceField, errorMessage: '' }));

        this.setState({ demandsError: null, processForm: true, quotationDemands, supplierPriceDetails });

        if (
          _.isEmpty(files) &&
          _.isEmpty(newFiles) &&
          !price &&
          (quoteMessageContent == 'null' || !quoteMessageContent) &&
          _.isEmpty(supplierPriceDetails) &&
          _.isEmpty(demands)
        ) {
          this.setState({ processForm: false });
          return NotificationManager.error(
            this.props.intl.formatMessage({
              id: 'global.DuKanInteLämnaInEttTomtBud',
              defaultMessage: 'Du kan inte lämna in ett tomt bud.',
            }),
            this.props.intl.formatMessage({
              id: 'global.error',
              defaultMessage: 'fel',
            }),
          );
        }

        const requiredDemandsNotFilledIn = quotationDemands.some(quotationDemand => quotationDemand.required && !demands.find(demand => demand.demandId === quotationDemand.demandId._id));

        if (requiredDemandsNotFilledIn) {
          quotationDemands = quotationDemands.map(quotationDemand => {
            if (quotationDemand.required && !demands.find(demand => demand.demandId === quotationDemand.demandId._id)) {
              return {
                ...quotationDemand,
                errorMessage: 'Obligatoriskt skallkrav',
              }
            }

            return quotationDemand
          });
        }

        const requiredPriceFieldsNotFilledIn = quotationData.priceDetails.some(quotationPriceDetail => quotationPriceDetail.required && supplierPriceDetails.find(priceDetail => priceDetail._id === quotationPriceDetail.priceType._id)?.price === '');

        if (requiredPriceFieldsNotFilledIn) {
          supplierPriceDetails = supplierPriceDetails.map(supPriceDetail => {
            const foundRequiredField = quotationData.priceDetails.find(priceDetail => supPriceDetail._id === priceDetail.priceType._id && priceDetail.required);
            if (foundRequiredField &&  supPriceDetail?.price === '' ) {
              return {
                ...supPriceDetail,
                errorMessage: 'Obligatoriskt prisfält',
              }
            }

            return supPriceDetail
          });
        }
        if (requiredDemandsNotFilledIn || requiredPriceFieldsNotFilledIn) {
          NotificationManager.error(`Obligatoriska ${[requiredPriceFieldsNotFilledIn ? "prisfält" : undefined, requiredDemandsNotFilledIn ? "skallkrav" : undefined].filter(x=>x).join(" och ")} måste fyllas i.`)
          this.setState({ processForm: false, supplierPriceDetails, quotationDemands })
          return;
        }
        this.setState({ message: false });
        let formData = {};
    
        formData.message = getEditorStateContent(quoteMessageContent);
        formData.content = getEditorStateContent(reservationContent);
        formData.supplierId = this.state.supplierId;
        formData.quotationId = this.state.quotationId;
        formData.attachments = JSON.stringify(this.state.files);
        formData.newFiles = this.state.newFiles;
        formData.price = this.state.price;
        formData.quoteVersionNo = this.state.quoteVersionNo;
    
        formData.supplierPriceDetails = this.state.supplierPriceDetails.map(el => ({
          ...el,
          price: +unformatPrice(el.price || 0),
        }));
    
        formData.demands = this.state.demands;
        formData.quoteRefId = this.state.quoteRefId;
        formData.quotationValidity = quotationValidity;
        try {
          if (isPublic) {
            const orgId = this.state.projectAreaData.orgId;
            const projectId = this.state.projectData.id;
            const areaId = this.state.projectAreaData._id;
            await ServiceQuotation.saveSalesProjectQuotationBid({
              ...formData,
              orgId,
              projectId,
              areaId,
            });

            if (this.state.newFiles.length > 0)  {
              const quotation = await ServiceQuotation.fetchPublicQuotation({
                orgId,
                projectId,
                areaId,
              });
              const correctVersionOfQuotationData = quotation.find(
                quotation => quotation.quoteVersionNo === formData.quoteVersionNo,
              );
              this.setQuotationData(correctVersionOfQuotationData);
            }
          } else {
            await ServiceQuotation.saveProjectQuotationInfo({
              ...formData,
            });

            if (this.state.newFiles.length > 0) {
              let data = await ServiceQuotation.getProjectQuotation(this.state.projectData.slug, this.state.projectAreaData.slug, this.state.supplierId, formData.quoteVersionNo)
              const correctVersionOfQuotationData = data.find(
                quotation => quotation.quoteVersionNo === formData.quoteVersionNo,
              );
              this.setQuotationData(correctVersionOfQuotationData);
            }
          }
          
          
          this.setState({
            processForm: false,
            newFiles: [],
            demandsError: '',
            status: {
              ...this.state.status,
              isLocked: true,
              reason: 'Submitted',
              submittedAt: new Date()
            }
          });
          if (this.props.onSubmit) {
            this.props.onSubmit();
          } else {
            NotificationManager.success('Anbudsformuläret har skickats in');
          }
        } catch (e) {
          if (e.response.status === 400) {
            const missingMandatoryPriceField = e.response?.data?.error === 'required.price.field.not.provided';
            const missingMandatoryDemand = e.response?.data?.error === 'required.demand.not.provided';
            if (
              missingMandatoryPriceField ||
              missingMandatoryDemand
            ) {
              NotificationManager.error(`Obligatoriska ${[missingMandatoryPriceField ? "prisfält" : undefined, missingMandatoryDemand ? "skallkrav" : undefined].filter(x=>x).join(" och ")} måste fyllas i.`);
            }
          }
          console.log({e});
        } finally {
          this.setState({ processForm: false });
        }
      };

      const quickPicker = (day, i) => (
        <div
          key={day}
          onClick={() => !isDisabled ? onValidityQuickPick(day) : null}
          className={`quickPicker ${i == 0 ? 'left-border' : ''} ${isDisabled ? 'quickPickerDisabled' : ''}`}
        >
          {day} dagar
        </div>
      );

      const onValidityQuickPick = (day) => {
        let today = new Date();
        let extendedDate = new Date();
        extendedDate.setDate(today.getDate() + day);
        this.setState({ quotationValidity: extendedDate });
      }

      const onChangeQuoteValidity = (date, e) => {
        e.preventDefault();
        this.setState({ quotationValidity: new Date(date) });
      }
  
      return (
        <div className="quotation-form-container">
          <p className="heading">Fyll i anbudsformulär</p>
          <div className={`form-content ${isDisabled && 'disabledPage'}`}>
            {renderEditor('Meddelande', quoteMessageContent, onQuoteMessageContentChange, true,)}
            {renderEditor('Reservationer', reservationContent, onReservationContentChange, false,)}
  
            {quotationData?.priceDetails?.length > 0 && (
              <div className="quote-price-container">
                {quotationData?.priceDetails?.map(pd => {
                  return (
                    <div key={pd?.priceType?.name} className="quote-price-box">
                      {renderPriceColumn(pd)}
                    </div>
                  );
                })}
              </div>
            )}
  
            <DemandsContainer>{renderDemands()}</DemandsContainer>
  
            <div className="quoteValidityContainer">
              <span className="Yrkeskategori-label">Anbudets giltighetstid</span>
              <div className="quoteValidityPickerContainer">
                <label className="pickerIconContainer">
                  <CalendarIcon style={{ fill: 'var(--gray-700)', width: '15px', height: '14px' }} />
                  <DatePicker
                    wrapperClassName="quoteValidity-date-picker-container"
                    dateFormat="yyyy-MM-dd"
                    selected={quotationValidity}
                    onChange={onChangeQuoteValidity}
                    disabled={isDisabled}
                    popperPlacement="top"
                    popperModifiers={{
                      flip: {
                        behavior: ['top'],
                      },
                      preventOverflow: {
                        enabled: false,
                      },
                      hide: {
                        enabled: false,
                      },
                    }}
                    minDate={new Date()}
                    placeholderText={isDisabled ? "" : "Välj datum"}
                  />
                </label>
                {quotationValidityOptions.map(quickPicker)}
              </div>
            </div>
  
            <BtRow>
              <BtButton
                loaderShow = {this.state.processForm}
                disabled = {isDisabled}
                onClick={!isDisabled ? saveQuotation : undefined}
              >
                Skicka
              </BtButton>
            </BtRow>
          </div>
        </div>
      );
    }

    const onChangeCollapsibleVersion= (data) => {
      this.setState({ openView: !this.state.openView });

      if(!this.state.openView && !isPublic) this.updateQuotationIsSeenStatus(data);
    }

    const quotationDataForQuoteShare = () => {
      const { quoteRefId, quoteVersionNo, supplierId, projectAreaData, projectData, supplierMemberId } = this.state;
      let data = {
        quoteRefId,
        quoteVersionNo,
        supplierId,
        areaId: projectAreaData?._id,
        projectId: projectData?.id,
        supplierMemberId
      };
      return data;
    };

    const {
      showBidDeclinedModal,
      previousBidIntent,
      intentStatus,
      bidDeclinedReason,
      supplierMemberId,
      showRequestShareModal,
      supplierMembers,
      fetchingSupplierMembers,
      isSubmittingRejectingIntent,
    } = this.state;
  
    return (
      <Collapsible
        onChangeCollapse={() => onChangeCollapsibleVersion(quote)}
        isOpen={openView}
        key={quotation?.id}
        collapsibleClassName="quote-collapsible"
        headerClassName="quote-header-collapsible"
        titleClassName="quote-title-collapsible"
        header={
          <div className="padded-block">
            <div className="title">{`Förfrågningsunderlag | Version ${versionNumber}`}</div>
            <div className="page-quotation-status-div">
              {!openView && <div className="quote-status">{getStatusMessage(status)}</div>}
              {openView && !isDisabled && !isPublic && renderIntentSelector()}
            </div>
          </div>
        }
      >
        {openView && (
          <div className="generic-card quotation-info-content-container">
            <div className="info-content-container">
              {renderContentBlock2()}
              {renderContentBlock3()}
              {renderFormContent()}
            </div>
          </div>
        )}
        <Modal
          title="Välj anledning"
          show={showBidDeclinedModal}
          setShow={value =>
            this.setState({
              showBidDeclinedModal: value,
              intentStatus: !value
                ? previousBidIntent || BidIntentStatus.UNDECIDED
                : intentStatus,
            })
          }
          buttonInfo={
            !appState.isReadOnlyAccessRole() && {
              label: 'Lägg till',
              loaderShow: isSubmittingRejectingIntent,
              disabled: !bidDeclinedReason?.label,
              action: async () => {
                try {
                  this.setState({ isSubmittingRejectingIntent: true });
                  await ServiceQuotation.updateBidIntent(
                    projectData.slug,
                    projectAreaData.slug,
                    supplier._id,
                    parseInt(quoteVersionNo),
                    {
                      intent: intentStatus,
                      reasonDeclined: bidDeclinedReason.label,
                    },
                    supplierMemberId
                  );
                  this.setState({ bidDeclinedReason: null, showBidDeclinedModal: false });
                  NotificationManager.success('Tack! Din avsiktsförklaring är sparad.')
                } catch (error) {
                  NotificationManager.error(`${error} Oväntat fel`)
                  throw error;
                } finally {
                  this.setState({ isSubmittingRejectingIntent: false })
                }
              },
            }
          }
        >
          {!appState.isReadOnlyAccessRole() && (
            <TitleWrapper title="Välj meddelande eller skriv valfri text">
              <Selector
                isCreatable
                value={bidDeclinedReason}
                onChange={value => this.setState({ bidDeclinedReason: value })}
                options={defaultBidDeclineReasons}
                formatCreateLabel={userInput => `Skriv "${userInput}"`}
                placeholder=""
              />
            </TitleWrapper>
          )}
        </Modal>
        {showRequestShareModal && !appState.isReadOnlyAccessRole() && 
        (
            <QuoteSharingModal
              onClose={this.toggleRequestShareModal}
              supplierMembers={supplierMembers}
              showRequestShareModal={showRequestShareModal}
              getQuotationData={quotationDataForQuoteShare}
              fetchingSupplierMembers={fetchingSupplierMembers}
              allowNewContacts={true}
            />
          )}
      </Collapsible>
    );
  };

  render() {
    return this.state.quotationData ? this.renderQuotationInfo(this.props.versionData) : null
  }
}

export default injectIntl(CollapsibleView);