import React from 'react';
import { SearchIcon } from '../../svgs';
import './SearchBarWithActions.css';

 const SearchBarWithActions = ({ value, onChange, children, style,searchicon,className },ref) => {
  return (
    <div className={`searchBarWithActions ${className}`} style={style} ref={ref}>
      <div className='searchBarContainer'>
      {searchicon ? searchicon :  <SearchIcon size={18} /> } 
        <input
          name='search'
          placeholder='Sök'
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
      {children}
    </div>
  );
}
export default React.memo(React.forwardRef(SearchBarWithActions))