import { FC, ReactNode } from "react"
import { TabsType } from "./PageWithTabs"
import AppLayout from "../../layout/app-layout/app-layout"

type Props = {
  tabs: TabsType,
  title: string,
  actions?: ReactNode,
  children: ReactNode,
}

export const TabPage: FC<Props> = ({ tabs, title, actions, children }) => {
  return (
    <AppLayout title={title}>
      <div style={{ margin: "32px" }}>
        <div className="tab-container">
          {tabs}
          <div className="tab-actions">{actions}</div>
        </div>
        {children}
      </div>
    </AppLayout>
  )
}