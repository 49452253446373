import { FC, useEffect, useState } from "react"
import { OptionType } from "../../models/global.model";
import AppEditList from "./app-edit-list";
import styles from './app-edit-price-fields.module.scss';
import { serviceAreas } from "../../services/service-areas";

type Props = {
  
}

type Area = {
  name: string;
  _id: string;
  isActive: boolean;
}

const AppEditCategories : FC<Props> = _props => {
  const [categories, setCategories] = useState<OptionType[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      const all = (await serviceAreas.getAreas() ?? []).map((x : Area) => ({ label: x.name, value: x._id }));
      setCategories(all);
      setLoading(false);
    })();
  }, []);
  return <div>
    <p className={styles.intro}>
      Här kan du byta namn på eller ta bort yrkeskategorier. 
      Namnbyten påverkar både befintliga upphandlingar och leverantörer. 
      Borttagna kategorier försvinner från leverantörer men befintliga upphandlingar påverkas inte.
    </p>
    <AppEditList 
      name="Yrkeskategori"
      plural="yrkeskategorier"
      list={categories ?? []}
      loading={loading}
      onChangeLabel={async (label, id) => { 
        await serviceAreas.renameArea(id, label); 
        setCategories(prev => {
          const index = prev.findIndex(p => p?.value === id);
          const updated = [...prev];
          updated[index] = { label: label, value: id };
          return updated;
        });
      }}
      onDelete={async (id) => { 
        await serviceAreas.deleteArea(id);
        setCategories(prev => {
          const index = prev.findIndex(p => p?.value === id);
          const updated = [...prev];
          updated.splice(index, 1);
          return updated;
        });
      }}
    />
  </div>;
}

export default AppEditCategories;