const userRolesDropdown = [
  {
    label: 'Administratör',
    value: 'Administratör',
    val: 'Management',
  },
  {
    label: 'Deltagare',
    value: 'Deltagare',
    val: 'Inköp',
  },
  {
    label: 'Besökare',
    value: 'Besökare',
    val: 'Kalkyl',
  },
  {
    label: 'Beställare',
    value: 'Beställare',
    val: 'Client',
  },
];

const superAdminRolesDropdown = [
  {
    label: 'Super admin',
    value: 'Super admin',
    val: 'superAdmin',
  },
];

const credibilityData = [
  {
    label: "SYNA",
    value: "syna",
  },
]

export { userRolesDropdown, superAdminRolesDropdown, credibilityData };
