import {CSSProperties, forwardRef, PropsWithChildren } from "react";

type Props = {
  className?: string,
  style?: CSSProperties,
}

const BtColumn = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({className = "", children, ...props}, ref) => {
    return <div ref={ref} className={`bt-column ${className}`} {...props}>{children}</div>
  }
)

export default BtColumn;