import { FC, useCallback } from "react"
import AppLayout from "../../components/layout/app-layout/app-layout";
import FileArea from "./FileArea";
import Card from "../../components/shared/Card/card";
import { RouteComponentProps } from "react-router-dom";
import appState from "../../state/AppStateContainer";
type Props = {
  history: RouteComponentProps['history'],
  location: RouteComponentProps['location'],
}

const PageFiles : FC<Props> = ({ history, location }) => {
  const path = "/" + location.pathname.split("/").slice(3).join('/');

  const root = "/files";
  const orgSlug = appState.getCurrentOrgSlug();
  const baseUrl = `/${orgSlug}${root}`;

  const updatePath = useCallback((getNewPath: (path: string) => string) => {
    const newPath = getNewPath(path);
    history.push(`${baseUrl}${newPath}`);
  }, [path]);

  return (
    <AppLayout title="Filer">
      <div style={{margin: "32px"}}>
        <Card>
          <FileArea root={root} path={path} setPath={updatePath} />
        </Card>
      </div>
    </AppLayout>
  );

}

export default PageFiles;