import { FC, ReactNode, useEffect, useRef, useState } from "react";
import styles from "./TemplateItem.module.scss";
import { ReactComponent as PlusIcon } from '../../../assets/svg/circleAddLightIcon.svg';
import { OverviewItemType } from "../Tabs/Overview/OverviewTab";

export type TemplateOptionType = {
  id: string,
  name: string,
  itemType: OverviewItemType,
  component: ReactNode,
}

type TemplateItemProps = {
  options: TemplateOptionType[],
  index: number,
  template: (TemplateOptionType | null)[],
  setTemplate: (template: (TemplateOptionType | null)[]) => void,
}

const TemplateItem: FC<TemplateItemProps> = ({ options, index, template, setTemplate }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [mousePosition, setMousePosition] = useState<{x: number, y: number}>();
  const [selectedItem, setSelectedItem] = useState<TemplateOptionType>();
  
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedItem) {
      const newTemplate = [...template];
      newTemplate[index] = selectedItem;
      setTemplate(newTemplate);
    }
  }, [selectedItem]);

  return (
    <button
      className={`${styles.templateItem} ${isFocused ? styles.isFocused : ''}`}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onMouseMove={(event: any) => {
        if (!isFocused) {
          let eventDoc, doc, body;

          event = event || window.event; // IE-ism
    
          // If pageX/Y aren't available and clientX/Y are,
          // calculate pageX/Y - logic taken from jQuery.
          // (This is to support old IE)
          if (event.pageX == null && event.clientX != null) {
              eventDoc = (event.target && event.target.ownerDocument) || document;
              doc = eventDoc.documentElement;
              body = eventDoc.body;
    
              event.pageX = event.clientX +
                (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
                (doc && doc.clientLeft || body && body.clientLeft || 0);
              event.pageY = event.clientY +
                (doc && doc.scrollTop  || body && body.scrollTop  || 0) -
                (doc && doc.clientTop  || body && body.clientTop  || 0 );
          }

          if (event.pageY + 300 > window.innerHeight) {
            event.pageY = window.innerHeight - 320;
          }
          if (ref?.current?.offsetWidth && event.pageX + ref.current.offsetWidth > window.innerWidth) {
            event.pageX = window.innerWidth - (ref?.current?.offsetWidth + 20);
          }
          setMousePosition({x: event.pageX, y: event.pageY})
        }
      }}
    >
        {<span>Välj nytt diagram <PlusIcon style={{ fill: 'var(--gray-700)' }} /></span>}
        {isFocused && options.length > 0 && <div ref={ref} className={styles.mouseContainer} style={{ top: mousePosition?.y, left: mousePosition?.x }}>
          {options.map(option => (
            <div key={option.id} onClick={() => setSelectedItem(option)}>
              {option.name}
            </div>
          ))}
          </div>}
    </button>
  )
}

export default TemplateItem;