import { CircularProgress } from '@mui/material';
import { FC } from 'react';
import './Spinner.scss';

type Props = {
  isHidden?: boolean,
  progress: number,
  size?: 'xxs'
  style?: React.CSSProperties,
}

const Spinner: FC<Props> = ({ isHidden, progress = 0, size, style }) => {
  return (
    <div className={`spinner ${size}`} style={{ visibility: isHidden ? 'hidden' : 'visible', ...style }}>
      <CircularProgress
        size={size === 'xxs' ? '17px' : '30px'}
        variant={progress > 0 ? 'determinate' : 'indeterminate'}
        value={progress * 100}
      />
      <span className='spinnerProgress'>
        {progress > 0 ? `${Math.round(progress * 100)}${size === 'xxs' ? '' : '%'}` : ''}
      </span>
    </div>
  )
}

export default Spinner;