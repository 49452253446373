import React from "react";

export default class DaysCounter extends React.PureComponent {
  returnDaysLeftString() {
    var str = "";

    if (this.props.daysLeft == "-") {
      str = "Klicka för att ändra slutdatum för anbud";
    } else {
      if (this.props.daysLeft == 1) {
        str = "dag kvar";
      } else {
        str = "dagar kvar";
      }
    }

    return str;
  }

  render() {
    return (
      <div className="date-end">
        <span>{this.props.daysLeft}</span>
        <br/>
        {this.returnDaysLeftString()}
      </div>
    );
  }
}
