import { Container } from "unstated";
import $ from "jquery";
import { EventEmitter } from "fbemitter";
import moment from "moment";
import services from "../services";
import _ from "lodash";
import momentLocalizer from "react-widgets-moment";
import appEnum, { SavingState } from "../util/appEnum"
import commonHelper from "../util/commonHelper"
import { ServiceUser } from "../services/service-user";
import { serviceOrganization } from "../services/service-organization";
import { ServiceProjects } from "../services/service-projects";
import * as Sentry from "@sentry/react";

export class AppStateContainer extends Container {
  currentOrganization;
  organizations;
  orgSlug;
  avatarUrlFetched=false;
  emitter = new EventEmitter();
  state = {
    tmpToken: this.loadObj("tmpToken"),
    token: this.loadObj("token"),
    organizations: null,
    currentOrganization: null,
    loaded: false,
    language: this.normalizeLanguageCode(this.loadString("language") || "en"),
    sidebarActive: false,
    editingTranslationId: null,
    editingTranslationDefault: null,
    messages: {},
    supplierToken: null,
    supplierApplicationCount: 0,
    autoSavingState: SavingState.HIDDEN,
    globalNotifications: {
      project: 0,
      internalChat: 0,
    }
  };
  #timeout = undefined;

  normalizeLanguageCode(code) {
    if (code.indexOf("-") >= 0) {
      return code.split("-")[0];
    }
    return code;
  }

  persistObj(key, obj) {
    if (obj === undefined) localStorage.removeItem(key)
    localStorage.setItem(key, JSON.stringify(obj));
  }

  loadObj(key) {
    let getLocalItem = localStorage.getItem(key);
    return (!getLocalItem || getLocalItem === "undefined" || getLocalItem === "null") ? null : JSON.parse(getLocalItem);
  }

  persistString(key, value) {
    localStorage.setItem(key, value);
  }

  loadString(key) {
    return localStorage.getItem(key);
  }

  constructor() {
    super();

    //Load any async state here
    moment.locale(this.state.language);
    momentLocalizer();
    this.setState({ loaded: true }, () => {
      moment.locale(this.state.language);
      momentLocalizer();
    });

  }


  async setSupplierToken({userData, organizationData}) {
    if(organizationData){
      this.setState({
        organizations: organizationData,
        currentOrganization: organizationData[0], 
      })
      this.currentOrganization = organizationData[0]
      this.orgSlug = `/${organizationData[0].slug}`;
    }
    
    this.setState({ supplierToken: userData });
  }

  async setToken({userData, organizationData}, setUrl) {
    this.persistObj("token", userData);
    if(organizationData){
      this.persistObj("organizations", organizationData);
      // this.persistObj("currentOrganization", organizationData[0]);
      // this.setState({
      //   currentOrganization: organizationData[0]
      // })
      this.currentOrganization = organizationData[0]
      this.orgSlug = `/${organizationData[0].slug}`;
    }
    
    await this.setState({ token: userData },()=>{
      this.setAatarFetchStateToFalse()
    })

    if(setUrl) window.location.href = `${this.orgSlug}/dashboard`;
  }

  async setTokenLocalStorage({userData, organizationData,slugFromUrl}) {
    this.persistObj("token", {...this.loadObj("token"),...userData});
    this.setState((prevState)=>({
      token: {...prevState.token,...userData}
    }))
    if(organizationData){
      this.persistObj("organizations", organizationData);
      // this.persistObj("currentOrganization", organizationData[0]);
      this.currentOrganization = organizationData[0]

    }
    // await this.setState({ token: userData });
    // if(this.currentOrganization.slug != slugFromUrl) window.location.reload();
  }

  clearToken() {
    this.setToken({});
    localStorage.removeItem('token');
    localStorage.removeItem('organizations');
    localStorage.removeItem('currentOrganization');
    Sentry.configureScope(scope => scope.setUser(null));
  }

  async setLoginAsToken(token) {
    const tmpToken = this.state.token;
    this.state.token.route = undefined;
    this.persistObj("tmpToken", this.state.token);
    this.persistObj("token", token);
    await this.setState({ token, tmpToken });
  }

  setHeaderTitle(title, titleId) {
    this.persistString("title", title);
    this.persistString("titleId", titleId);
    this.setState({ title: title, titleId });
  }
  async setLanguage(language) {
    this.persistString("language", language);
    moment.locale(language);
    momentLocalizer();
    this.setState({ language }, () => {
      this.emitter.emit("onLanguageChange");
    });
  }

  setSidebarActive(isActive) {
    this.setState({ sidebarActive: isActive }, () => {
      if (this.state.sidebarActive) {
        $("body").addClass("overflow backdrop");
        // $(".navbar.navbar-fixed-top").css("width", "calc(100% - 240px)");
        $(".navbar-header").css("padding-left", "0px");
      } else {
        $("body").removeClass("overflow backdrop");
        // $(".navbar.navbar-fixed-top").css("width", "100%");
        // $(".navbar-header").css("padding-left", "220px");
      }
    });
  }

  changeLanguage() {
    if (this.state.language === "sv") this.setLanguage("en");
    else this.setLanguage("sv");
  }

  changeLanguageTo(language) {
    this.setLanguage(language);
  }

  async logout() {
    const tmpToken = this.state.tmpToken;
    if (tmpToken) {
      this.persistObj("tmpToken", null);
      await this.setToken(tmpToken);
      await this.setState({ tmpToken: null });
    } else {
      await this.setToken({});
    }
  }
  signout(){
    this.clearToken()
  }
  toggleSidebar(options = {}) {
    if (options.sidebarClose && !this.state.sidebarActive) {
      return;
    }
    this.setSidebarActive(!this.state.sidebarActive);
  }

  getRoleFromUrlParams() {
    const historyRole = _.get(window, "history.state.state.role");
    return historyRole || commonHelper.getQueryStringArray().r;
  }

  getUserRole = () => this.getLoggedInUserDetail()?.role;
  
  getUserId = () => this.getLoggedInUserDetail()?.id;

  getUserEmail = () => this.getLoggedInUserDetail()?.email;

  isSuperAdmin = () => this.getUserRole() == appEnum.Role.SuperAdmin;

  superAdminOrgAndUser = () => this.isSuperAdmin() && this.getCurrentOrgSlug() === 'superadmin';
  
  isManagementRole() {
    const role = this.getUserRole();
    return role == appEnum.Role.Management || role == appEnum.Role.Admin || role == appEnum.Role.SuperAdmin;
  }

  isKalkylRole() {
    const role = this.getUserRole();
    return role == appEnum.Role.Kalkyl;
  }

  isInkopRole() {
    const role = this.getUserRole();
    return role == appEnum.Role.Inköp;
  }
  isClientRole() {
    const role = this.getUserRole();
    return role == appEnum.Role.Client;
  }
  isSupplierRole() {
    const role = this.getUserRole();
    return role == appEnum.Role.Supplier;
  }
  isWriteAccessRole() {
    const role = this.getUserRole();
    return role == appEnum.Role.Admin || role == appEnum.Role.Management || role == appEnum.Role.Inköp || role == appEnum.Role.SuperAdmin;
  }
  isAdminRole() {
    const role = this.getUserRole();
    return role == appEnum.Role.Admin || role == appEnum.Role.Management || role == appEnum.Role.SuperAdmin;
  }
  isNonAdminUser() {
    return this.isKalkylRole() || this.isInkopRole() || this.isClientRole();
  }
  isReadOnlyAccessRole() {
    return this.isKalkylRole() || this.isClientRole();
  }
  isCustomerUser() {
    const role = this.getUserRole();
    return role != appEnum.Role.Supplier && Object.values(appEnum.Role).includes(role);
  }

  getLoggedInUserDetail = () => {
    if (this.isSupplierSpecificRoute()) return this.state?.supplierToken
    return this.state?.token;
  }
  
  isGhostUser() {
    return this.state && this.state.token && this.state.token.isGhost;
  }

  fetchOrganizations = async () => {
    if(this.state.token && this.state.token.id){
      let orgData = await ServiceUser.getUserOrganizations(this.state.token.id);
      if(orgData && orgData[0]){
        const { organizations } = orgData[0];
        this.setOrgData(organizations);
      }
    }
  }

  fetchAndSetToken = async (currentOrg, isOrgChangedToken = false) => {
    const { slug } = currentOrg;
    if(this.state.token && this.state.token.id){
      let orgToken = await ServiceUser.fetchAndSetToken(slug, isOrgChangedToken);
      
      if(orgToken)
      this.setTokenLocalStorage({...orgToken,slugFromUrl:slug});
      else return;
    }
  }

  fetchUpdatedOrganizations = async () => {
    if(this.state.token && this.state.token.id){
      let orgData = await ServiceUser.getUserOrganizations(this.state.token.id);
      if(orgData && orgData[0]){
        const { organizations } = orgData[0];
        this.persistObj("organizations", organizations);
        return organizations;
        // await this.setState({ organizations: organizations });
      }
    }
  }

  setOrgData = async (data) => {
    this.persistObj("organizations", data);
    // this.persistObj("currentOrganization", data[0]);
    const updatedCurrentOrg = data.find(org => org.slug == this.currentOrganization?.slug);
    if (updatedCurrentOrg) {
      this.currentOrganization = updatedCurrentOrg;
    } else {
      this.currentOrganization = data[0];
    }

    // await this.setState({ organizations: data, currentOrganization: data[0] });
  }


  getCurrentOrg = () => {
   return  this.currentOrganization || this.loadObj("token")
  }
  

  getCurrentOrgSlug = () => {
    // let currentOrg = this.loadObj('currentOrganization');
    let currentOrg = this.currentOrganization;
    return currentOrg && currentOrg.slug
  }

  getOrgSlugFromUrl = () => {
    let requestedUrl = window.location.pathname;
    const [, slugFromUrl] = requestedUrl.split("/");
    return slugFromUrl || "";
  }

  isSuperAdminOrg = () => {
    let currentOrg = this.currentOrganization;
    return currentOrg && currentOrg.slug === 'superadmin'
  }  

  getCurrentOrgName = () => {
    // let currentOrg = this.loadObj('currentOrganization');
    let currentOrg = this.currentOrganization || this.getOrgFromToken();
    return currentOrg && currentOrg.name 
  }

  updateChangedOrgSlug = (updatedOrgSlug) => {
    let _url = window.location.pathname.split('/');
    _url.splice(0,2);
    _url = _url.join("/");
    let updatedPath = `/${updatedOrgSlug}/${_url}`;
    window.location.pathname = updatedPath;
  }

  getCurrentOrgUrl = async () => {
    const getDomainName = (url) => {
      return (new URL(url)).hostname;
    }

    var [, slugFromUrl] = window.location.pathname.split("/");
    try {
      const { org, wasValid } = await serviceOrganization.validateAndGetOrgSlug(getDomainName(window.location.origin), slugFromUrl);
      slugFromUrl = org.slug;
      if(org.orgSlugFromHistory) this.updateChangedOrgSlug(slugFromUrl);
      if(org.slug) this.orgSlug = `/${slugFromUrl}`; //TODO: I think we can remove it 

      let currentOrg 
      // let currentOrg = this.loadObj('currentOrganization');
      await this.fetchOrganizations();
      const organizations = this.loadObj('organizations');
      if(!slugFromUrl && this.state.token && this.state.token.role === appEnum.Role.SuperAdmin){
        currentOrg = organizations.find(({slug}) => slug === "superadmin")
      }
      else  currentOrg  = this.currentOrganization || this.getOrgFromToken() || (organizations && organizations[0])
      this.setCurrentOrganization(currentOrg)
      if(slugFromUrl === "error") {
        slugFromUrl = currentOrg && currentOrg.slug
      }
      if(slugFromUrl && currentOrg && (slugFromUrl != currentOrg.slug)){
        let orgExist = organizations.find(o => o.slug === slugFromUrl);
        currentOrg = orgExist;
        
        if(orgExist){
          this.setCurrentOrganization(currentOrg)
        }
        this.fetchAndSetToken({slug: slugFromUrl})
      } 
      this.orgSlug = currentOrg && `/${currentOrg.slug}`;

      if (!this.orgSlug && org) {
        this.orgSlug = `/${org.slug}`;
        this.setCurrentOrganization(org);
        this.fetchAndSetToken({slug: org.slug});
      }
  
      return {slug: this.orgSlug, wasValid} 
    } catch (error) {
      const errorStatus = error?.response?.status;
      if (errorStatus === 401) {
        this.signout();
        location.reload();
      }
      return { slug: null, wasValid: false };
    }
  }
  _getCurrentOrgUrl(){
    const [, slugFromUrl] = window.location.pathname.split("/");
    return this.orgSlug ? this.orgSlug : slugFromUrl ? `/${slugFromUrl}` : "";
  }
  _getCurrentOrgSlug(){
    const [, slugFromUrl] = window.location.pathname.split("/");
    return this.orgSlug ? this.orgSlug.replace("/", "") : slugFromUrl || "";
  }

  getOrganizations = () => this.loadObj("organizations") || [];
  

  setCurrentOrganization = (currentOrganization) => {
    this.currentOrganization = currentOrganization
  }
  setAatarFetchStateToFalse(){
    this.avatarUrlFetched = false;

  }
  setAvatarFetchState(){
    this.avatarUrlFetched = true;
  }
  getAvatarFetchedState(){
    return this.avatarUrlFetched
  }

  setAvatarFetchTokenState(){
    return this.avatarUrlFetched
  }

  setAvatarUrl(url){
   
    let _token = this.loadObj("token");
    if(_token){
      _token.avatarUrl = url;
      this.persistObj("token", _token);
      this.setState((prevState)=> ({...prevState,token:{...prevState.token,avatarUrl:url}}))
    }
  }

  getAvatarUrl(){
    return this.state.token && this.state.token.avatarUrl || "";
  }
  getAvatarPath() {
    return this.state.token && this.state.token.imgId || "";
  }
  getOrgSlugFromToken = () => this.state.token && this.state.token.orgSlug;

  getOrgFromToken = () => {
    let orgSlugFromToken = this.state.token && this.state.token.orgSlug
    let localOrganizations = this.loadObj('organizations'); 
    if(orgSlugFromToken && localOrganizations){
      return localOrganizations.find(o=> o.slug === orgSlugFromToken);
    }
  }

  getOrgFromUrl = () => {
    let orgSlugFromUrl = this.getOrgSlugFromUrl()
    let localOrganizations = this.loadObj('organizations'); 
    if(orgSlugFromUrl && localOrganizations){
      return localOrganizations.find(o=> o.slug === orgSlugFromUrl);
    }
  }

  getUpdatedRoleOrganizations = (organizations, role) => {
    let currentOrgSlug = this.getCurrentOrgSlug(); 
    const updatedOrgIndex = organizations.findIndex(o => o.orgId.slug === currentOrgSlug);
    organizations[updatedOrgIndex].role = role;
    return organizations;
  }

  getUserDetails = async () => {
    if (this.isSupplierSpecificRoute()) return;
    let userId = this.getUserId();
    const localToken = this.loadObj("token");
    if(userId){  
      try {
        const user = await ServiceUser.getUserById(userId);
        const _user = this.state.token;
        _user.avatarUrl = user.avatarUrl;
        _user.name = user.name;
        _user.phone = user.phone;
        _user.preferences = user.preferences;
        localToken.avatarUrl = user.avatarUrl;
        localToken.name = user.name;
        localToken.phone = user.phone;
        localToken.preferences = user.preferences;
        this.setState({ token: _user });
        this.persistObj('token', localToken);
        Sentry.setUser({ id: userId, email: _user.email });  
        return _user;
      } catch (error) {
        console.log("could not fetch user", error);
        return null;
      }
    }
  }
  getProjectFilterPreferences = () =>{
    const userData = this.state.token.preferences;
    return userData && userData.projectFilter
  }

  getProjectListPreferences = () => {
    return this.state.token.preferences && this.state.token.preferences.projectTable && this.state.token.preferences.projectTable.length > 0 && this.state.token.preferences.projectTable
  }

  getProjectOverviewPreferences = () => {
    return this.state.token.preferences?.projectOverview;
  }

  getDashboardOverviewPreferences = () => {
    return this.state.token.preferences?.dashboard?.overview;
  }

  setDashboardOverviewPreferences = (data) => {
    this.setState({ token: {...this.state.token, preferences: { ...this.state.token.preferences, dashboard: { ...this.state.token.preferences?.dashboard, overview: data } }} })
  }
  getListViewBetaPreference = () => {
    // return this.state.token?.preferences?.listViewBeta?.enabled ?? false;
    return true;
  }

  getUserToken = () => this.getLoggedInUserDetail()?.accessToken;

  isSupplierSpecificRoute = () => {
    const supplierSpecificUrls = ['quotation', 'conversation'];
    const splittedUrl = window.location.pathname.split('/');
    return supplierSpecificUrls.some(url => splittedUrl[2]?.includes(url));
  }
  getUserName = () => this.getLoggedInUserDetail()?.name;

  getSupplierApplicationCount = () => this.state.supplierApplicationCount;

  setSupplierApplicationCount = (count) => this.setState({supplierApplicationCount: count*1});

  setAutosavingState = (value) => {
    this.setState({ autoSavingState: value })
    clearTimeout(this.#timeout);
    if (value === SavingState.DONE) {
      this.#timeout = setTimeout(() => this.setState({ autoSavingState: SavingState.HIDDEN }), 3000);
    }
  };
  getAutosavingState = () => this.state.autoSavingState;

  setGlobalNotifications = (globalNotifications) => this.setState({ globalNotifications: {...this.state.globalNotifications, ...globalNotifications} });

  getGlobalNotifications = () => this.state.globalNotifications;

  refreshProjectNotifications = async () => {
    let projectNotifications;
    if (!this.isClientRole()) {
      projectNotifications = (await ServiceProjects.getCounter())?.count;
    } else {
      projectNotifications = 0;
    }
    console.log({projectNotifications})
    this.setGlobalNotifications({ project: projectNotifications });
  } 
  
  getActiveProducts = (org) => {
    const organization = org ?? this.getCurrentOrg();
    const products = organization?.activeProducts ?? [];
    if (products.length === 0) {
      return ["PURCHASING"];
    }
    return products;
  };
  isPurchasingActive = (org) => this.getActiveProducts(org).includes("PURCHASING");
  isSalesActive = (org) => this.getActiveProducts(org).includes("SALES");

  setSupplierProfileHasBeenUpdated = (supplierProfileHasBeenUpdated) => this.setState({supplierProfileHasBeenUpdated})
  hasSupplierProfileBeenUpdated = () => this.state.supplierProfileHasBeenUpdated;

  setInvoiceEmail = (email) => this.setState({invoiceEmail: email})
  getInvoiceEmail = () => this.state.invoiceEmail ?? this.getCurrentOrg()?.invoiceEmail ?? '';
  
  setInvoiceRef = (ref) => this.setState({invoiceRef: ref})
  getInvoiceRef = () => this.state.invoiceRef ?? this.getCurrentOrg()?.invoiceRef ?? '';
}

const appState = new AppStateContainer();

window.APP_STATE = appState;

export default appState;
