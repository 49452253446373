import React from 'react';
import './Legends.css';
const Legends = ({ legendsArray = [] }) => {
  return (
    <div className="legends">
      {legendsArray.map(({ color, label }) => (
        <div key={label} className="legend-item">
          <div className="legend-color" style={{ backgroundColor: color }}></div>
          <div className="legend-label">{label}</div>
        </div>
      ))}
    </div>
  );
};

export default React.memo(Legends);
// 0 Förfrågan I Utvärdering & Förhandling
