import React from 'react';
import { ServiceProjects } from '../../services/service-projects';
import appState from "../../state/AppStateContainer";
import appEnum from "../../util/appEnum"
import { FormattedMessage, injectIntl } from "react-intl";
import AppLabel from "../app-label/app-label"
import $ from 'jquery'

import "./app-project-settings.css";
import { CustomAvatar } from '../../components/shared';


export class AppProjectSettings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
        if (props.currentProject) {
            this.state = this.updateState(props.currentProject);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.currentProject != nextProps.currentProject) {
            let state = this.updateState(nextProps.currentProject);
            this.setState(state);
        }
    }

    updateState(project) {
        // Update member state
        //this.updateMembersState(project);

        const members = this.getMembers(project);

        return {
            name: project && project.name,
            number: project && project.number,
            responsible: project && project.responsible,
            customer: project && project.customer,
            province: project && project.province,
            entreprenad: project && project.entreprenad && appEnum.getEntreprenadCostValueByKey(project.entreprenad),
            estimatedCost: project && appEnum.getProjectCostValueByKey(project.estimatedCost),
            projectType: project && project.projectTypeName,
            members: members
        };
    }

    // async updateMembersState(project) {
    //     let response;
    //     if (project)
    //         response = await ServiceProjects.getProjectMembers(project.slug);
    //     else
    //         response = [];

    //     this.setState({ members: response });
    // }

    getMembers(project) {
        if (!project || !project.area) return [];
        const members = project.user || [];

        for (let ai = 0; ai < project.area.length; ai++) {
            const area = project.area[ai];

            for (let mi = 0; area && area.areaId && area.areaId.members && mi < area.areaId.members.length; mi++) {
                const dbMem = area.areaId.members[mi];
                let existingMember = members.find(m => m._id == dbMem._id);
                if (!existingMember) {
                    let role = dbMem.role;
                    if (this.props.users) {
                      role = this.props.users.find(u => u.value == dbMem._id).role;
                    }

                    if (role == 'Management') {
                      role = 'Administratör'
                    } else if (role == 'Inköp') {
                      role = 'Deltagare'
                    } else if (role == 'Kalkyl') {
                      role = 'Besökare'
                    }
                    
                    existingMember = {_id: dbMem._id, name: dbMem.name, roles: [role]};
                    members.push(existingMember);
                }
            }
        }

        return members;
    }

    editProjectBtnCallback() {
        if (this.props.editProjectBtnCallback) {
            this.props.editProjectBtnCallback();
        }
    }

    toggleGalleryBar(e) {
        const $togglerBar = $(e.target).parents('.floating-sidebar');
        $togglerBar.toggleClass('open');
    }

    dummyOnChange(e) {

    }

    expandMoreInfo(e) {
        $(e.target).addClass('expanded');
    }
    
    render() {
        return (
            <div className="app-project-settings app-project-settings-custom">
                {
                    appState.isManagementRole() &&
                    <a className="l-edit" href="#" onClick={this.editProjectBtnCallback.bind(this)}>
                        Redigera
                    </a>
                }
                <div className="content-left">
                    <div onClick={(e) => this.expandMoreInfo(e)}>
                        <AppLabel headline="Projektnamn">
                            <input readOnly disabled name="name" value={this.state.name || ""} onChange={this.dummyOnChange.bind(this)}/>
                        </AppLabel>
                        <AppLabel headline="Projektnummer">
                            <input readOnly disabled name="number" value={this.state.number || ""} onChange={this.dummyOnChange.bind(this)}/>
                        </AppLabel>
                        <AppLabel headline="Beställare">
                            <input readOnly disabled name="customer" value={this.state.customer || ""} onChange={this.dummyOnChange.bind(this)}/>
                        </AppLabel>
                        <AppLabel headline="Ort/kommun">
                            <input readOnly disabled name="province" value={this.state.province || ""} onChange={this.dummyOnChange.bind(this)}/>
                        </AppLabel>
                        <AppLabel headline="Entreprenad">
                            <input readOnly disabled name="entreprenad" value={this.state.entreprenad || ""} onChange={this.dummyOnChange.bind(this)}/>
                        </AppLabel>
                        <AppLabel headline="ESTIMERAD PROJEKTKOSTNAD I MSEK">
                            <input readOnly disabled name="entreprenad" value={this.state.estimatedCost || ""} onChange={this.dummyOnChange.bind(this)}/>
                        </AppLabel>
                        <AppLabel headline="PROJEKTTYP">
                            <input readOnly disabled name="projectType" value={this.state.projectType || ""} onChange={this.dummyOnChange.bind(this)}/>
                        </AppLabel>
                    </div>
                    <AppLabel headline="Medarbetare" class="employees">
                        {this.state && this.state.members && this.state.members.map((v, i) => {
                            const role = v.roles && v.roles.join(", ");
                            const avatarUrl = this.props.users.find(user => user.value === v._id.toString())?.avatarUrl;
                            return (
                                <div className="employee" key={v._id}>
                                    <CustomAvatar src={avatarUrl} alt={v.name} />
                                    <label>{role ? `${v.name} (${role})` : v.name}</label>
                                </div>
                            )
                        })}
                    </AppLabel> 
                </div>
            </div>
        );
    }
}

export default injectIntl(AppProjectSettings);