import { CustomNavLink } from '../../routes';
import FatArrowIcon from '../../svgs/fatArrowIcon';
import styles from './BackButton.module.scss';
import { ReactComponent as LightArrow } from '../../../assets/svg/lightArrow.svg'
import React, { CSSProperties, VFC } from 'react';

type Props = {
  to?: string,
  onClick?: () => void,
  style?: CSSProperties,
  path?: string[]
}

const BackButton:VFC<Props> = ({ to, onClick, style, path = [] }) => {
  return (
    <div className={styles.container}>
      <div className={styles.backButtonContainer} style={style}>
        {to ? (
          <CustomNavLink to={to}>
            <FatArrowIcon />
          </CustomNavLink>
        ) : (
          <div onClick={onClick}>
            <FatArrowIcon />
          </div>
        )}
      </div>

      {path?.length > 0 && (
        <div className={styles.pathContainer}>
          {path.map((pathSegment, index) => (
            <React.Fragment key={pathSegment}>
              <span>{pathSegment}</span>
              {path.length - 1 !== index && <LightArrow width={'16px'} height={'16px'} fill={'var(--gray-900)'} />}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  )
}

export default BackButton;