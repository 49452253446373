import React from 'react';
import PageLogin from "../page-login/page-login"

import './page-home.css';

export default class PageHome extends React.Component {

  render() {

    return (
      <div className="page-home">
        <PageLogin></PageLogin>
      </div>
    );
  }
}