import React, { useRef, useState, useCallback } from 'react';
import { FileDrop } from 'react-file-drop';
import { getUID } from '../../global/app';
import { CustomAvatar } from './index';
import Cropper from 'react-easy-crop';
import { ReactComponent as CircleXIconFilled } from '../../assets/svg/circleXIconFilled.svg';
import styles from './FileDropper.module.scss';


const FileDropper = ({ userName, onChange, avatarUrl }) => {
  const [image, setImage] = useState(null);
  const [error,setError] = useState("")
  const [crop, setCrop] = useState({x:0,y:0});
  const [zoom, setZoom] = useState(1);
  const [cropping, setCropping] = useState(false);
  
  var fileDroppperRef = useRef(0);
  var parentRef = useRef(0);

  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    onChange({image, croppedAreaPixels});
  }

  const onRemove = (event) => {
    if (avatarUrl) {
      setImage(avatarUrl);
    } else {
      setImage(null);
    }
    setCropping(false);
    setCrop({x:0,y:0});
    setZoom(1);
    onChange({image:null, croppedAreaPixels: null});
    event.preventDefault();
  }

  React.useEffect(() => {
    if (avatarUrl) {
      setImage(avatarUrl);
      setCropping(false);
      setCrop({x:0,y:0});
      setZoom(1);
    }  
  }, [avatarUrl]);
  const onFileInputChange = event => {
    setError("")
    const { files } = event.target;
    let img = files[0];
    if(img){
      const ext = img.name.split('.').pop().toLowerCase() || "";
  
      if(ext !== "png" && ext !== "jpg" && ext !== "jpeg" && ext !== "gif") {
        return setError("Endast bilder är tillåtna");
      }
      try {
        const url = URL.createObjectURL(img);        
        setImage(url);  
        onChange({image: img, croppedAreaPixels: null});
        setCropping(true);
        } catch (e) {
        console.log("Could not convert url to object url", e);
      }
    }
  };
  const onFileDrop = files => {
    onDragLeave();
    onFileInputChange({ target: { files } });
  };
  const onDrop = e => {
    fileDroppperRef.current.handleDrop(e);
    e.preventDefault();
  };
  const onDragEnter = () => {
    parentRef.current.style.transition = 'all 300ms ease';
    parentRef.current.style.boxShadow = ' 0px 0px 70px 0px rgb(163 163 163 / 82%) inset';
  };
  const onDragLeave = () => {
    parentRef.current.style.transition = 'all 300ms ease';
    parentRef.current.style.boxShadow = 'none';
  };
  if (cropping && image) {
    return (
      <div className="file-dropper-container">
        <span>Beskär bilden: dra för att flytta, skrolla för att zooma.</span>
        <div style={{position:"relative", width: "100%", height: "280px" }}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            maxZoom={10}
            aspect={1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropShape="round"
            showGrid={false}
            classes={{
              containerClassName: styles.cropper,
              cropAreaClassName: styles.cropArea,
            }}
          />
          <div className={styles.removeIcon}>
            <CircleXIconFilled onClick={onRemove} />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      className="file-dropper-container"
      ref={parentRef}
      onDragEnter={() => onDragEnter()}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      {image ? (
       <div className="dropped-img-file-container"> <img src={image} className="dropped-img-file" /> </div>
      ) : (
        <CustomAvatar alt={userName} src={image} size="140px" />
      )}
      <input onChange={onFileInputChange} style={{display: 'none'}} type="file" className="hidden" accept="image/png, image/gif, image/jpeg" />
      <div className="hairLine"/>
      <FileDrop ref={fileDroppperRef} onDrop={onFileDrop}>
        Klicka eller dra och släpp en bild här för att ladda upp profilbild
      </FileDrop>
      <p className="file-tips">PNG, JPG, GIF</p>
      <h3 className="file-tips" style={{color:"red", margin:0}}>{error}</h3>
    </div>
  );
};

export default React.memo(FileDropper);
