export function http(request) {
	return new Promise((resolve, reject ) => {
		let response;
		fetch(request)
			.then(res => {
				response = res;
				return res.json();
			})
			.then(body => {
				response.parsedBody = body;
				if(response.status < 400) {
					resolve(response);
				} else {
					reject(response);
				}
			})
			.catch(err => {
				reject(err);
			});
	});
};


export async function get(
	path,
	args = { method: "get" }
) {
	return await http(new Request(path, args));
};

export async function post(
	path,
	body = {},
	args
) {
	if (!args) {
		args = { method: "post", body: JSON.stringify(body) }
	}
	return await http(new Request(path, args));
};

export async function put(
	path,
	body,
	args = { method: "put", body: JSON.stringify(body) }
) {
	return await http(new Request(path, args));
};

export async function patch(
	path,
	body,
	args = { method: "put", body: JSON.stringify(body) }
) {
	return await http(new Request(path, args));
};



// example consuming code 
// interface ITodo {
// 	userId: number;
// 	id: number;
// 	title: string;
// 	completed: boolean;
// }
