import { FC, useEffect, useState } from "react"
import { OptionType } from "../../models/global.model";
import AppEditList from "./app-edit-list";
import { ServiceErfa } from "../../services/service-erfa";
import styles from './app-edit-price-fields.module.scss';

type Props = {
  
}

type Question = {
  question: string;
  _id: string;
}

const AppEditPriceFields : FC<Props> = _props => {
  const [questions, setQuestions] = useState<OptionType[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const all = (await ServiceErfa.getQuestions()).map((x : Question) => ({ label: x.question, value: x._id }));
      setQuestions(all);
      setLoading(false);
    })();
  }, []);
  return <div>
    <p className={styles.intro}>Här kan du ändra eller ta bort de frågor som föreslås när man skapar erfarenhetsåterföringar. Förändringar påverkar inte erfarenhetsåterföringar som redan är skapade.</p>
    <AppEditList 
      name="Fråga"
      plural="frågor"
      list={questions ?? []}
      loading={loading}
      onChangeLabel={async (label, id) => {         
        const created = await ServiceErfa.changeQuestion(id, label);
        setQuestions(prev => {
          const index = prev.findIndex(p => p?.value === id);
          const updated = [...prev];
          updated[index] = { label: created.question, value: created._id };
          return updated;
        });
      }}
      onDelete={async (id) => { 
        await ServiceErfa.deleteQuestion(id);
        setQuestions(prev => {
          const index = prev.findIndex(p => p?.value === id);
          const updated = [...prev];
          updated.splice(index, 1);
          return updated;
        })        
      }}
    />
  </div>;
}

export default AppEditPriceFields;