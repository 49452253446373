import React, { useMemo } from "react"
import { Checkbox, makeStyles } from "@material-ui/core";
import { areEqual } from "../../../util";
import "./StyledCheckbox.css";
import clsx from 'clsx';

  function StyledCheckbox(props) {
    const { color, dash, className, ...otherProps } = props;

    const useStyles = useMemo(() => (
      makeStyles({
        root: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        icon: {
          borderRadius: 4,
          width: 14,
          height: 14,
          boxShadow: 'none',
          backgroundColor: 'white',
          border:"1px solid #D1D5DB",
          backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
          'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
          },
        },
        checkedIcon: {
          backgroundColor: color || '#FF5C00',
          borderColor: color || "#FF5C00",
          boxShadow: 'inset 0 0 0 0 rgba(16,22,26,.2)',
          backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,.1))',
          display:"flex",
          '&:before': {
            display: 'block',
            width: dash ? 10 : 14,
            height: dash ? 10 : 14,
            backgroundImage: dash ?
              "url(\"data:image/svg+xml;utf8,%3Csvg%20viewBox%3D%22-0.355%20-0.355%2010%2010%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20id%3D%22Dash--Streamline-Radix%22%20height%3D%2210%22%20width%3D%2210%22%3E%3Cdesc%3EDash%20Streamline%20Icon%3A%20https%3A%2F%2Fstreamlinehq.com%3C%2Fdesc%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M0.0929%204.645C0.0929%204.1422251999999995%200.5005452%203.73458%201.00332%203.73458H8.28668C8.789516733333333%203.73458%209.197099999999999%204.1422251999999995%209.197099999999999%204.645S8.789516733333333%205.55542%208.28668%205.55542H1.00332C0.5005452%205.55542%200.0929%205.147836733333333%200.0929%204.645Z%22%20fill%3D%22white%22%20stroke-width%3D%220.71%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E\")"
              : "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
              " fillRule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
              "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            backgroundRepeat: 'no-repeat',
            content: '""',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          },
          'input:hover ~ &': {
            backgroundColor: color || '#FF5C00',
          },
        },
      })
    ), [color, dash]);
    const classes = useStyles();
  
    return (
      <Checkbox
        className={`${classes.root} StyledCheckbox ${className}`}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
        icon={<span className={classes.icon} />}
        inputProps={{ 'aria-label': 'decorative checkbox' }}
        {...otherProps}
      />
    );
  }
  export default React.memo(StyledCheckbox, areEqual);