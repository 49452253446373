import { FC, useCallback, useState } from 'react';
import Modal from '../../components/shared/Modal/Modal';
import Selector from '../../components/shared/Selector/Selector';
import { ServiceSupplier } from '../../services/service-supplier';
import { NotificationManager } from "react-notifications";
import Tabs from '../../components/shared/Tabs';

type Props = {
  show: boolean,
  setShow: (value: boolean) => void,
  areas: any[],
  onSave: (modifiedAreaId: string) => void,
}

const RecategorizeModal : FC<Props> = ({ show, setShow, areas, onSave }) => {
  const [sourceAreaId, setSourceAreaId] = useState(null);
  const [areaIdToChange, setAreaIdToChange] = useState(null);
  const [tab, setTab] = useState(0);
  
  const recategorizeSuppliers = useCallback(async () => {
    if (sourceAreaId && areaIdToChange) {
      const response = await ServiceSupplier.recategorizeSuppliers(tab == 0 ? "ADD" : "REMOVE", sourceAreaId, areaIdToChange);
      const updatedText = response.updatedSuppliers.length == 1 ? "en leverantör uppdaterad" : `${response.updatedSuppliers.length} leverantörer uppdaterade`;
      const errorText = response.errors.length == 1 ? "en leverantör ej uppdaterad" : `${response.errors.length} leverantörer ej uppdaterade`;
      if (response.errors.length > 0) {
        NotificationManager.error(`Omkategoriseringen har sparats: ${updatedText}, ${errorText}.`, "Något gick fel");
      } else {
        NotificationManager.success(`Omkategoriseringen har sparats: ${updatedText}.`, "Sparat");
      }
      onSave(areaIdToChange);
      setShow(false);
    }
  }, [tab, sourceAreaId, areaIdToChange, setShow]);

  return (
    <Modal
      show={show}
      setShow={setShow}
      title="Omkategorisera"
      buttonInfo={{ label: 'Spara', action: recategorizeSuppliers }}
    >
      <div style={{fontSize: "14px", color: "var(--gray-700)", display: "flex", gap: "16px", flexDirection: "column"}}>
        <Tabs tabs={["Lägg till", "Ta bort"]} selected={tab} onChange={setTab} />
        {tab === 0 && (<>
          Lägg till alla leverantörer i 
          <Selector
            placeholder='Kategori'
            options={areas}
            value={sourceAreaId ? areas.find(area => area.value == sourceAreaId) : null}
            onChange={event => setSourceAreaId(event?.value)}                    
          />
          till 
          <Selector
            placeholder='Annan kategori'
            options={areas}
            value={areaIdToChange ? areas.find(area => area.value == areaIdToChange) : null}
            onChange={event => setAreaIdToChange(event?.value)}                    
          />
        </>)}
        {tab === 1 && (<>
          Ta bort alla leverantörer i 
          <Selector
            placeholder='Kategori'
            options={areas}
            value={sourceAreaId ? areas.find(area => area.value == sourceAreaId) : null}
            onChange={event => setSourceAreaId(event?.value)}                    
          />
          från 
          <Selector
            placeholder='Annan kategori'
            options={areas}
            value={areaIdToChange ? areas.find(area => area.value == areaIdToChange) : null}
            onChange={event => setAreaIdToChange(event?.value)}                    
          />
        </>)}
      </div>

    </Modal>
  );

}

export default RecategorizeModal;
