import React from 'react';

const renderSource = source => {
  const { source_id, source_name, agreements } = source;
  return agreements.map((agreement) => renderAgreement(source_name, agreement))
}

const renderAgreement = (sourceName, agr) => {
  const { agreement = '', arbetsgivarorganisation = '' } = agr;
  return (
    <tr>
      <td>{sourceName}</td>
      <td>{agreement}</td>
      <td>{arbetsgivarorganisation}</td>
    </tr>
  );
}
export default function CollectiveAccordion({ data }) {
  const { kollektivavtalskollen, byggInstSektorn } = data;
  const companyName = kollektivavtalskollen.source_data && kollektivavtalskollen.source_data.length && kollektivavtalskollen.source_data[0].company_name;
  return (
    <div className="collectiv-drawer-inner">
      <h2 className="collectiv-drawer-title" title={companyName}>
        <b>Kollektivavtal</b> {companyName}
      </h2>

      <h3>Information från Kollektivavtalskollen<sup>1</sup></h3>

      {kollektivavtalskollen.error ? (
        <p>Kunde inte hämta data: {kollektivavtalskollen.error} </p>
      ) : (
        <table style={{width: "100%", fontSize:"14px"}}>
          <tr>
            <th>Fackförbund</th>
            <th>Avtal</th>
            <th>Arbetsgivarorganisation</th>
          </tr>
          {kollektivavtalskollen.source_data.map(source => renderSource(source))}
        </table>
      )}

      <h3>Information från Bygg- och installationssektorn<sup>2</sup></h3>

      {byggInstSektorn.error ? (
        <p>Kunde inte hämta data: {byggInstSektorn.error} </p>
      ) : (<>
        {byggInstSektorn.memberships.length > 0 && (
          <p style={{marginTop: "0px"}}>Medlem i {byggInstSektorn.memberships.map(m => m.name).join(", ").replace(/, [^,]*$/, " och ")}.</p>
        )}
        <table style={{width: "100%", fontSize:"14px"}}>
          <tr>
            <th>Fackförbund</th>
            <th>Avtal</th>
            <th>Arbetsgivarorganisation</th>
          </tr>
          {byggInstSektorn.collectiveAgreements.map(c => (
            <tr>
              <td>{c.FackligMotpart}</td>
              <td>{c.Avtal}</td>
              <td>{c.Medlemsorganisation}</td>
            </tr>
          ))}
          {byggInstSektorn.substituteAgreements.map(c => (
            <tr>
              <td>{c.FackligMotpart}</td>
              <td>{c.Avtal}</td>
              <td>(hängavtal)</td>
            </tr>
          ))}
        </table>
      </>)}

      <p><sup>1</sup> Kollektivavtalskollen innehåller information från fackförbunden och inkluderar avtal med Byggnads, 
              Kommunal, Fastighet, Elektrikerna, Handels, Målarna, Akavia, Forena och Finansförbundet 
              (fler förbund/avtal kommer, denna lista är senast uppdaterad 2024-08-21).</p>
      <p style={{marginTop: "0px"}}><sup>2</sup> Bygg- och installationssektorn innehåller information från arbetsgivarorganisationer och inkluderar medlemskap, kollektivavtal och hängavtal från Byggföretagen, Installatörsföretagen, Maskinentreprenörerna, Plåt & Ventföretagen, Måleriföretagen och Glasbranschföreningen.</p>
    </div>
  );
}
