import axios from 'axios';

export const hideAndCreateNewPriceType = async (newName: string, priceTypeId: string): Promise<{ name: string, _id: string }> => {
  const response = await axios.put(`/api/priceTypes/${priceTypeId}/hide-and-create-new`, {
    newName
  });

  return response.data;
}

export const deletePriceType = async (priceTypeId: string): Promise<void> => {
  const response = await axios.delete(`/api/priceTypes/${priceTypeId}`);

  return response.data;
}