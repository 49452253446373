import { FC, useEffect, useState } from "react"
import { OptionType } from "../../models/global.model";
import AppEditList from "./app-edit-list";
import { editPriceUnit, deletePriceUnit } from "../../services/priceUnitService";
import { ServiceQuotation } from "../../services/service-quotation";
import styles from './app-edit-demands.module.scss';

type Props = {
  
}

const AppEditPriceUnits : FC<Props> = _props => {
  const [units, setUnits] = useState<OptionType[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      let allUnits = await ServiceQuotation.getPriceUnits();
      type LabelAndValue = { label: string, value: string };
      allUnits = allUnits
        .map((d: { _id: string, name: string }) => ({ label: d.name, value: d._id }))
        .sort((a: LabelAndValue, b: LabelAndValue) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      setUnits(allUnits);
      setLoading(false);
    })();
  }, []);
  return <>
    <p className={styles.intro}>Här kan du ändra eller ta bort de enheter som föreslås för prisfält när man skapar förfrågningsunderlag. Förändringar påverkar inte förfrågningsunderlag som redan är skapade.</p>
    <AppEditList 
      name="Enhet"
      list={units ?? []}
      loading={loading}
      onChangeLabel={async (label, id) => { 
        const created = await editPriceUnit(label, id);
        setUnits(prev => {
          const index = prev.findIndex(p => p?.value === id);
          const updated = [...prev];
          updated[index] = { label: created.name, value: created._id };
          return updated;
        });
      }}
      onDelete={async (id) => { 
        await deletePriceUnit(id); 
        setUnits(prev => {
          const index = prev.findIndex(p => p?.value === id);
          const updated = [...prev];
          updated.splice(index, 1);
          return updated;
        })        
      }}
    />
    </>;
}

export default AppEditPriceUnits;