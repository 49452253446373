import React, { Fragment, PureComponent } from 'react';
import Select, { components } from 'react-select';
import { Add } from '../../components/svgs';

const Option = props => (
  <div className="ar-multiselect-checkboxes">
    <components.Option {...props}>
      <input type="checkbox" checked={props.isSelected} onChange={e => null} />{' '}
      <label>{props.value} </label>
    </components.Option>
  </div>
);

const Menu = props => {
  const { selectProps } = props;
  return (
    <Fragment>
      <components.Menu {...props}>
        <div>
          <div class="ar-props-children">{props.children}</div>
          {selectProps.selectProps.bottomOption && selectProps.inputValue && !selectProps.selectProps.hideSuggestion && (
            <button
              className={'change-data'}
              onClick={() => selectProps.selectProps.onAddArea(selectProps.inputValue)}
            >
              <span class="add-sp-svg-wrap">
                <Add className="add-supplier-member-btn" />
              </span>
              Skapa "{selectProps.inputValue}" kategori
            </button>
          )}
        </div>
      </components.Menu>
    </Fragment>
  );
};

export default class MultiSelect extends PureComponent {
  state = {
    inputValue: '',
  };
  render() {
    const {
      data,
      onSelect = undefined,
      defaultData,
      placeholder = 'Välj eller lägg till..',
    } = this.props;
    return (
      <Select
        placeholder={placeholder}
        closeMenuOnSelect={false}
        isMulti
        components={{ Menu, Option, MultiValue }}
        selectProps={this.props}
        onInputChange={e => this.setState({ inputValue: e })}
        value={defaultData}
        options={data || []}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        onChange={onSelect}
        classNamePrefix="custom-add-sup-multi"
      />
    );
  }
}

const MultiValue = props => {
  return (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
};
