import ConstructImage from '../../../assets/images/construct.png';
import AccuratorLogo from '../../../assets/svg/accurator_logo.svg';
import styles from './bidError.module.scss';

const QuotationError = ({ error }: { error?: string }) => {
  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <img src={ConstructImage} alt="Error" />
        <div>
          <p>Något gick fel</p>
          <p>{error}</p>
        </div>
      </div>
      <div className={styles.footer}>
        <img src={AccuratorLogo} alt="Accurator Logo" />
        <p>Accurator AB | <a href="mailto:support@accurator.se">support@accurator.se</a> | 08 156-225 | Vallgatan 5, 170 67 Solna</p>
      </div>
    </div>
  );
};

export default QuotationError;
