import axios from 'axios';

class ServiceSynaController {
  async getSynaDetails(organizationNumber) {
    const response = await axios.get("/api/syna/details", {
      params: { organizationNumber: organizationNumber }
    });
    return response.data;
  }

  async getSynaReport(organizationNumber) {
    const response = await axios.get("/api/syna/report", {params : {organizationNumber}});
    return response.data;
  }
}

export const ServiceSyna = new ServiceSynaController();
