import AddCircleIcon from '@material-ui/icons/AddCircle';
import Field from '../../components/shared/Field/Field';
import BtButton from '../bt-button/bt-button';
import { CrossSvg } from '../../components/svgs';
import { ReactComponent as ClipIcon } from '../../assets/svg/clip.svg';
import DatePicker from '../../components/shared/DatePicker/DatePicker';
import File from '../../components/File/File';


const hasFileCommentOrDate = (item) => {
  return item && (item.file || item.validUntilDate || item.submittedDate || item.comment)
}

function renderUploadedFiles(item, namePrefix, handleChange) {
  return (
    <File
      file={item.fileId || item.name}
      fileName={item.fileId ? item.fileName : item.name}
      onRemove={() => {
        handleChange(namePrefix + "File", undefined, true);
      }}
      blob={item.fileId ? undefined : item}
    />
  );
}

const FileCommentDate = ({item, isEditing, onRemove, onAdd, namePrefix, addButtonText, headline, handleChange, newFile, scope, scopeOptions}) => {
  const agreementHeadline = namePrefix == "ue2021Form" ? "Blankett" : "Avtal";
  const datePickerHeadline = namePrefix == "ue2021Form" ? "Inskickat datum" : "Giltigt till";
  const pickedDate = item?.validUntilDate || item?.submittedDate;
  const comment = item?.comment ?? "";
  
  if (isEditing) {
    return <>
      <div className="ar-add-sup-member" style={{ gap: '15px', display: 'flex', flexDirection: 'column', fontSize: '16px' }}>
        <div className='ar-add-sup-member-headline'>
          {headline}
          {onRemove && (
            <div className="ar-remove-sup-member" onClick={onRemove}>
              <CrossSvg size='16px' />
            </div>
          )}
        </div>
        
        <div className='ar-add-sup-member-agreement-headline'>
          {agreementHeadline}
          <div className="app-chat-mail-batch" style={{ marginTop: '5px', display: 'flex', alignItems: 'center', gap: '10px' }}>
            { !newFile && <label style={{ letterSpacing: 'normal' }}>
              <BtButton class='addEditSupplierUploadFileButton' color='white' size='xxs' leftIcon={
                <ClipIcon style={{ width: '15px', height: '15px', fill: 'var(--gray-700)' }} />
              }>
                Ladda upp fil
                <input
                  type="file"
                  name={namePrefix + "File"}
                  onClick={event => {
                    event.target.value = null;
                  }}
                  onChange={(e) => handleChange(namePrefix + "File", e.target.files[0], true)}
                  style={{ display: 'none' }}
                />
              </BtButton>
            </label>}
            <div className="upload-files-wrapper uploaded-files">
              {newFile &&
                renderUploadedFiles(newFile, namePrefix, handleChange) 
              }
            </div>
          </div>
        </div>
        
        <DatePicker
          label={datePickerHeadline}
          selected={pickedDate}
          onChange={(date, e) => {
            e.preventDefault();
            handleChange(namePrefix + "Date", date);
          }}
        />
        <Field
          label='Kommentar'
          value={comment}
          onChange={(value) => handleChange(namePrefix + "Comment", value)}
        />
      </div>
    </>;
  } else {
    if (hasFileCommentOrDate(item)) {
      return <dl className="frameworkAgreement">
        <div className='ar-add-sup-member-headline'>
          {headline}
          {onRemove && (
            <div className="ar-remove-sup-member" onClick={onRemove}>
              <CrossSvg size='16px' />
            </div>
          )}
        </div>
        {item.file && <div>
          <dt style={{ marginBottom: '5px' }}>{agreementHeadline}</dt>
          <dd>
            <File
              scope={scope}
              scopeOptions={scopeOptions}
              file={item.file.file}
              fileName={item.file.fileName}
            />
          </dd>
        </div>}
        {pickedDate && <div>
          <dt>{datePickerHeadline}</dt>
          <dd>{(pickedDate) && new Date(pickedDate).toLocaleDateString()}</dd>
        </div>}
        {item.comment && <div>
          <dt>Kommentar</dt>
          <dd>{item.comment}</dd>
        </div>}
      </dl>
    } else {
      return <div>
        <BtButton 
          color="white" 
          size="xxs"
          onClick={onAdd}
          leftIcon={<AddCircleIcon htmlColor="var(--orange-500)"/>}
        >
            {addButtonText}
        </BtButton>
      </div>;
    }
  }
}

export default FileCommentDate;