import React from "react";

import "./app-chat-file-uploads.css";
import PropTypes from "prop-types";
import $ from "jquery";
import File from '../../components/File/File'
import BtCenter from "../bt-center/bt-center";
import _ from "lodash";
export class AppChatFileUploads extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  };
  dates = [
    "Januari",
    "Februari",
    "Mars",
    "April",
    "Maj",
    "Juni",
    "Juli",
    "Augusti",
    "September",
    "Oktober",
    "November",
    "December"
  ];

  nameClient = this.props.nameClient;
  nameUser = this.props.nameUser;

  filesClient = this.props.filesClient;
  filesUser = this.props.filesUser;

  getMonthName(month) {
    return this.dates[month];
  }

  sortFilesByExtension(files) {
    if (files) {
      files = files.sort(function(x, y) {
        if (x.date > y.date) return -1;
        if (x.date < y.date) return 1;
        if (x.date == y.date) return 0;
      });
    }

    return files;
  }

  sortFiles(files) {
    if (files) {
      files = files.sort(function(x, y) {
        if (x.date > y.date) return -1;
        if (x.date < y.date) return 1;
        if (x.date == y.date) return 0;
      });
    }

    return files;
  }

  toggleGalleryBar(e, abc) {
    const $togglerBar = $(e.target).parents(".uploads-gallery-wrapper");
    $togglerBar.toggleClass("open");
  }

  renderFiles(files, isSupplierMember) {
    if (files) {
      files = this.sortFiles(files);
      files = this.sortFilesByExtension(files);
      var currentMonth = "";
      var collection = [];

      const filesGroupedByEmail = _.groupBy(files, (item => item?.user?.email));

      return Object.entries(filesGroupedByEmail).map(([userEmail, files]) =>{
        files.forEach(item => {
          const date = new Date(item.date);
          const year = date.getFullYear();
          const month = date.getMonth();
          const key = year + "-" + month;
          if (key != currentMonth) {
            if (collection[key] == undefined) {
              collection[key] = {};
              var obj = collection[key];
              obj.title = this.getMonthName(month) + " " + year;
              obj.collection = [];
            }
            collection[key].collection.push(item);
          }
        });
        var collectionKeys = Object.keys(collection);
        if (collectionKeys.length > 0) {
          const name = !isSupplierMember && files[0]?.user?.name ? files[0].user.name : this.props.nameClient;
          return (
            <div key={userEmail}>
              <BtCenter>
                <h4>{name}</h4>
              </BtCenter>
              {collectionKeys.map(key => {
                var month = collection[key];
                return (
                  <div className="content" key={month.title}>
                    <div className="date">{month.title}</div>
                    <div className="files uploaded-files" style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                      {month.collection.map((file) => {
                        return (
                          <File
                            file={file.file}
                            fileName={file.fileName || file.file}
                            key={file.file}
                            scope={this.props.scope}
                            scopeOptions={this.props.scopeOptions}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        } else return null;
      })
    }
  }

  render() {
    var filesClient = this.renderFiles(this.props.filesClient);
    var filesUser = this.renderFiles(this.props.filesUser);

    return (
      <div className="app-chat-file-uploads uploads-gallery-files custom-scrollbar">
        <span
          className="gallery-files-toggler"
          onClick={this.toggleGalleryBar.bind(this)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="14"
            viewBox="0 0 9 14"
          >
            <path
              fill="#FA6400"
              fillRule="evenodd"
              d="M3.28206469,7 L7.93269901,11.6506343 C8.355767,12.0737023 8.355767,12.759631 7.93269901,13.182699 C7.50963103,13.605767 6.8237023,13.605767 6.40063432,13.182699 L0.983967654,7.76603235 C0.560899671,7.34296436 0.560899671,6.65703564 0.983967654,6.23396765 L6.40063432,0.817300987 C6.8237023,0.394233004 7.50963103,0.394233004 7.93269901,0.817300987 C8.355767,1.24036897 8.355767,1.9262977 7.93269901,2.34936568 L3.28206469,7 Z"
            />
          </svg>
        </span>
        {Object.keys(filesClient).length <= 0 &&
          Object.keys(filesUser).length <= 0 && (
            <div className="no-uploads">Finns inga uppladdningar av filer</div>
          )}

        {Object.keys(filesClient).length > 0 && (
          <div className="uploads uploads-client">
            {filesClient}
          </div>
        )}

        {Object.keys(filesUser).length > 0 && (
          <div className="uploads uploads-user">
            {filesUser}
          </div>
        )}
      </div>
    );
  }
}
