import { FC, CSSProperties, ReactNode } from 'react';
import './TitleWrapper.css';

type Props = {
  title?: ReactNode,
  style?: CSSProperties,
  className?: string
  titleMargin?: string | number
}

const TitleWrapper: FC<Props> = ({ title, children, style, className }) => {
  return (
    <div className={`titleWrapperContainer ${className}`} style={style}>
      <p>{title}</p>
      {children}
    </div>
  );
}

export default TitleWrapper;