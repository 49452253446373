import { useEffect, useState } from "react";
import serviceSales, { Subscription, SubscriptionEvent } from "../../services/service-sales";
import { formatDate } from "../../util";
import { ServiceQuotation } from "../../services/service-quotation";
import { ServiceUser } from "../../services/service-user";
import { NotificationManager } from "react-notifications"; 
import { Loading } from "../../components/shared";
import SubscriptionSettings from "./SubscriptionSettings";

const AllQuotationsSubscription = () => {
  const [subscription, setSubscription] = useState<Subscription>();
  const [loading, setLoading] = useState(false);
  const [allUsers, setAllUsers] = useState(new Map<string, string>());

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await ServiceQuotation.fetchAllReceived();
        setSubscription(response.subscription);
        const userIds = Array.from((response?.subscription?.history ?? [])
          .map((event: SubscriptionEvent) => event.user)
          .filter((x:string) => !!x)
          .reduce((set: Set<string>, id: string) => set.add(id), new Set()));

        const users = await Promise.all(userIds.map(id => ServiceUser.getUserById(id)));
        const userById = users.reduce((map: Map<string, string>, user: any) => map.set(user._id, `${user.name} <${user.email}>`), new Map());
        setAllUsers(userById);  
      } catch (e) {        
        NotificationManager.error((e as Error).toString(), "Kunde inte hämta förfrågningar");
        console.error(e);
      }
      setLoading(false);
    })();
  }, [])

  const onSubscribe = async () => {
    const subscription = await serviceSales.orderAllQuotationsSubscription();
    setSubscription(subscription);
    NotificationManager.success("Prenumerationen kommer förnyas automatiskt ett år i taget.", "Sparat");
  }

  const onCancel = async () => {
    const subscription = await serviceSales.cancelAllQuotationsSubscription();
    setSubscription(subscription);
    NotificationManager.success(`Prenumerationen kommer avslutas efter nuvarande fakturaperiod (den ${formatDate(subscription?.active?.endDate)})`, "Sparat");
  }

  if (loading) {
    return <Loading type="inline" />
  }

  return <SubscriptionSettings 
    productName="Accurator Kontroll"
    purchasePage="Förfrågningar"
    subscription={subscription}
    loading={loading}
    onSubscribe={onSubscribe}
    onCancel={onCancel}
    allUsers={allUsers}
  />;
}

export default AllQuotationsSubscription;