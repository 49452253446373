import React, { useState, useEffect, useImperativeHandle } from 'react';
import './reason-box.css';
import BtButton from '../bt-button/bt-button';
import PlusIcon from '../../assets/svg/plus.svg';
import SupplierBlockedReasons from '../app-supplier-database/supplier-blocked-reasons';

export const ReasonBox = React.forwardRef(({ showModal, blacklistReason, submitReason, onCancel }, ref) => {
  const [reason, setReason] = useState('');

  const reasonBoxInput = React.createRef();

  useImperativeHandle(ref, () => ({

    reset() {
      setReason("");
    }

  }));

  useEffect(() => {
    if (reasonBoxInput.current) {
      reasonBoxInput.current.focus();
    }
  }, [reasonBoxInput, showModal]);

  if (showModal) {
    return (
      <div className="reasonBox">
        <img className={'cancelReasonBox'} src={PlusIcon} onClick={() => onCancel()} />
        <div className="reasonList">
          <SupplierBlockedReasons reasons={blacklistReason} />
        </div>
        <div className="inputBox">
          <input
            ref={reasonBoxInput}
            className="reasonBoxInput"
            placeholder="Skriv anledning till spärr"
            type="text"
            value={reason}
            onChange={e => setReason(e.target.value)}
          />
          <BtButton
            class="btn-right reasonBox-btn"
            disabled={!reason}
            type="submit"
            onClick={() => {
              setReason('');
              submitReason(reason);
            }}
          >
            Spara
          </BtButton>
        </div>
      </div>
    );
  }
  return null;
});
