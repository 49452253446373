import { FC, useState } from "react";
import Modal from "../../components/shared/Modal/Modal";
import { Reason } from "../../models/supplier.model";
import SupplierBlockedReasons from "./supplier-blocked-reasons";
import Field from "../../components/shared/Field/Field";
import { ServiceSupplier } from "../../services/service-supplier";
import { NotificationManager } from "react-notifications";

type Props = {
  supplierId: string,
  supplierName: string,
  reasons: Reason[],
  block: boolean,
  show: boolean,
  onClose: () => void,
  onSuccess: (isBlacklist: boolean, reasons: Reason[]) => void,
}
const BlockSupplierModal : FC<Props> = ({ show, onClose, onSuccess, supplierId, supplierName, block, reasons}) => {
  const [reason, setReason] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const onSave = async () => {
    setIsSaving(true);
    try {
      const response = await ServiceSupplier.putBlock(supplierId, block, reason);
      NotificationManager.success("Sparat");
      onSuccess(response.isBlacklist, response.reasons);
      onClose();
    } catch (e) {
      NotificationManager.error((e as Error).toString(), "Fel");
    }
    setIsSaving(false);
  }

  const title = block ? "Spärra leverantör" : "Ta bort spärr";
  const body = block ? 
    <p style={{color: "var(--gray-700)"}}>Hindra <span style={{fontWeight: 500, color: "var(--gray-900)"}}>{supplierName}</span> från att väljas i projekt.</p> :
    <p style={{color: "var(--gray-700)"}}>Tillåt att <span style={{fontWeight: 500, color: "var(--gray-900)"}}>{supplierName}</span> väljs i projekt.</p>;
  supplierId;
  return (
    <Modal show={show} setShow={onClose} title={title} buttonInfo={{
      label: block ? "Spärra" : "Ta bort spärr",
      action: onSave,
      loaderShow: isSaving,
      disabled: !reason,
    }}>
      <div style={{display: "flex", flexDirection: "column", gap: "16px", fontSize: "14px"}}>
        {body}
        <SupplierBlockedReasons reasons={reasons}/>
        <Field label="Skriv anledning" value={reason} onChange={setReason} />
      </div>
    </Modal>
  );
}

export default BlockSupplierModal;