export function btSort(array, key, order = 'asc') {
	if (array) {
		let orderI = 1;
		if(order != 'asc') {
			orderI = -1;
		}
		array = array.sort(function (x, y) {
			x = x[key]; 
			y = y[key];

			if(Object.prototype.toString.call(x) === '[object String]' && 
			Object.prototype.toString.call(y) === '[object String]') {
				try {
					x = x.toUpperCase();
					y = y.toUpperCase();
				} catch (error) {
					console.error(error);
				}
			}

			if(order == 'asc') {
				return (x < y) ? -1 : (x > y) ? 1 : 0;
			} else {
				return (x > y) ? -1 : (x < y) ? 1 : 0;
			}
			if (x == y) return 0;
		});
	}

	return array;
}


export function btSplitColumns(array, columns = 1) {
	if(array) {
		var size = Math.ceil(array.length / columns);
		var arrayOfArrays = [];
		for (var i = 0; i < array.length; i += size) {
			arrayOfArrays.push(array.slice(i, i + size));
		}
		
		return arrayOfArrays;
	}

	return [];
}

export function btHasObject(contentArray, key, value) {
	let found = false;
	if (contentArray) {
		found = contentArray.some(content => {
			return  (content[key] == value);
		});
	}

	return found;
}

export function btHas(content, needle) {
	var temp;
	var type = typeof content;
	type = type.toLowerCase();

	if (Array.isArray(content)) {
		for (var i = 0; i < content.length; i++) {
			temp = content[i];
			if (temp == needle) {
				return true;
			}
		}
	} else if (type == 'object') {
		if (typeof content[needle] !== 'undefined')
			return true;
	} else {
		if (!content && content == needle) {
			return true;
		}

		if (content && content.indexOf(needle) >= 0) {
			return true;
		}
	}

	return false;
}

export function btHasKey(content, needle) {
	if (content.hasOwnProperty(needle)) {
		return true;
	}

	return false;
}

// Accepts the array and key
export function groupBy(array, key) {
	// Return the end result
	let grp = array.reduce((result, currentValue) => {
	  // If an array already present for key, push it to the array. Else create an array and push the object
	  (result[currentValue[key]] = result[currentValue[key]] || []).push(
		currentValue
	  );
	  // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
	  return result;
	}, {}); // empty object is the initial value for result object

	let grpArray = []
	for (const key in grp) {
		grpArray.push({key: key, data: grp[key]});
	}

	return grpArray;
}

export const sortArray = (arr) => {
	return arr.sort((a, b) => {
		if (a.name < b.name) return -1;
		if (a.name > b.name) return 1;
		return 0;
	});
};
  
 