import styles from './page-category-mapping.module.scss';
import { FC, useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AppLayout from '../../components/layout/app-layout/app-layout';
import BackButton from '../../components/shared/BackButton/BackButton';
import BtButton from '../bt-button/bt-button';
import Selector from '../../components/shared/Selector/Selector';
import { OptionType } from '../../models/global.model';
import { SingleValue } from 'react-select';
import SearchBarWithActions from '../../components/shared/SearchBarWithActions/SearchBarWithActions';
import MaterialTable from 'material-table';
import StyledCheckbox from '../../components/shared/StyledCheckbox/StyledCheckbox';
import CloseIcon from '@material-ui/icons/Close';
import { serviceAreas } from '../../services/service-areas';
import Pagination from '@mui/material/Pagination';
import React from 'react';
import { NotificationManager } from "react-notifications";
import appState from '../../state/AppStateContainer';

type Params = {
  areaSlug: string;
  project: string;
  version: string;
};

type RouteTypes = RouteComponentProps<Params, Record<string, unknown>>;

type Props = {
  history: RouteTypes['history'];
  match: RouteTypes['match'];
  category: string;
}

type CustomerCategory = {
  _id: string,
  name: string,
  organization: string,
  orgId: string,
}

type Area = {
  _id: string,
  name: string, 
  slug: string,
  customerAreas: {
    areaId: string,
    orgId: string,
  }[],
}

const getColumns = () => {
  return [
    {
      title: 'Yrkeskategori',
      field: 'name',
      render: (rowData : CustomerCategory) => {
        return (
          <div className="name">
            <span>{rowData.name}</span>
          </div>
        )
      },
    },
    {
      title: 'Organisation',
      field: 'organization',
    },
  ];
};

const PageCategoryMapping: FC<Props> = ({category, history}) => {
  const [area, setArea] = useState<Area>();
  const [submitting, setSubmitting] = useState(false);
  const [organizationOptions, setOrganizationOptions] = useState<OptionType[]>([]);
  const [organization, setOrganization] = useState<SingleValue<OptionType>>(null);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<CustomerCategory[]>([])
  const [filteredCategories, setFilteredCategories] = useState<CustomerCategory[]>([]);
  const [customerCategoryOptions, setCustomerCategoryOptions] = useState<CustomerCategory[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  
  const tableRef = useRef();

  const fetchAccuratorCategory = async (slug : string) => {
    const areas = (await serviceAreas.fetchAreas()) as Area[] | null;
    if (areas) {
      const area = areas.find(a => a.slug == slug);
      setArea(area);
    }
  }

  const fetchCustomerCategories = async () => {
    const areas = (await serviceAreas.getAllCustomerAreas()) as CustomerCategory[] | null;
    if (areas) {
      setCustomerCategoryOptions(areas);
      const uniqueOrgs = areas.reduce((set, area) => set.add(area.organization), new Set<string>());
      setOrganizationOptions(Array.from(uniqueOrgs).map(s => ({label: s, value: s})));
    }
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchAccuratorCategory(category);
      await fetchCustomerCategories();        
      setLoading(false);
    })();
  }, [category]);

  useEffect(() => {
    const filtered = customerCategoryOptions
      .filter(val => val.name.toLowerCase().indexOf(searchValue.toLowerCase()) != -1)
      .filter(val => !organization || val.organization == organization.label);

    setFilteredCategories(filtered);
    setCurrentPage(1);
  }, [customerCategoryOptions, searchValue, organization])

  useEffect(() => {
    if (area && area.customerAreas && customerCategoryOptions.length) {
      const selected = customerCategoryOptions.filter(a => area.customerAreas.find(ca => ca.areaId == a._id && ca.orgId == a.orgId));
      setSelectedCategories(selected);
    }
  },
  [area, customerCategoryOptions]); 



  const onSelectionChange = (rowData : CustomerCategory, checked: boolean) => {
    if (checked && !selectedCategories.find(c => c._id == rowData._id && c.orgId == rowData.orgId)) {
      setSelectedCategories([...selectedCategories, rowData]);
    } else if (!checked && selectedCategories.find(c => c._id == rowData._id && c.orgId == rowData.orgId)) {
      setSelectedCategories(selectedCategories.filter(c => !(c._id == rowData._id && c.orgId == rowData.orgId)));
    }
  }

  const renderSelectedCategory = (sup : CustomerCategory) => {
    return (
      <div className={`selected-supplier-mod`} key={sup._id + sup.orgId}>
        <div className="sup_name_head">
          <StyledCheckbox checked onClick={() => onSelectionChange(sup, false)} />
          <div className="sup_name">{sup.name}</div>
          <div style={{marginLeft: "16px", color: "var(--gray-700)"}}>{sup.organization}</div>
        </div>
        
        <div className="icon-dropdown-container">
          <div className='selectedSupplierCloseDiv' onClick={() => onSelectionChange(sup, false)}>
            <span>Ta bort</span>
            <CloseIcon className="supList-remove-icon" htmlColor='var(--gray-500)' />
          </div>
        </div>
      </div>
    );
  };

const renderSelectedCategories = () => {
  const ValdComponent = React.memo(() => (
    <>
      <b>{selectedCategories.length}</b> {`vald${selectedCategories.length > 1 ? 'a' : ''}`}
    </>
  ));
  ValdComponent.displayName = 'ValdComponent';

  const vald = selectedCategories.length ? <ValdComponent /> : 'Inga valda kategorier';
  return (
    <div className="sup-pagination selected-supplier-mod-container">
      <div className="selected-supplier-mod-head">
        <div>
          Valda kategorier
          <span className="vald">{vald}</span>
        </div>
      </div>
      <span className="border"></span>

      {selectedCategories && selectedCategories.length > 0 ? (
        selectedCategories.map(sup => renderSelectedCategory(sup))
      ) : (
        <span style={{ fontSize: '14px' }}>Dina valda leverantörer kommer visas här.</span>
      )}
    </div>
  );
}

  const renderSuppliers = () => {
    return <>
      <SearchBarWithActions value={searchValue} onChange={setSearchValue} style={undefined} searchicon={undefined} className={undefined}>
        <Selector
          options={organizationOptions}
          value={organization}
          onChange={setOrganization}
          isSearchable
          isClearable
          className={styles.filterSelector}
          placeholder="Organisation"
        />
      </SearchBarWithActions>
      <MaterialTable
        localization={{ body: { emptyDataSourceMessage: 'Kunde inte hitta något resultat' } }}
        options={{
          pageSize: 10,
          paging: true,
          sorting: false,
          draggable: false,
          search: false,
          toolbar: false,
          detailPanelColumnStyle: { display: 'none' },
          rowStyle: () => ({
            color: 'var(--gray-800)',
          }),
          showSelectAllCheckbox: false,
          selection: true,
          selectionProps: (rowData : CustomerCategory) => {
            const isChecked = selectedCategories && selectedCategories.findIndex(s => s._id === rowData._id && s.orgId == rowData.orgId) > -1;
            return ({
              icon:<StyledCheckbox onChange={(_ : any, checked : boolean) => onSelectionChange(rowData, checked)}
                checked={isChecked}
              />,
              checkedIcon:<StyledCheckbox onChange={(_ : any, checked: boolean) => onSelectionChange(rowData, checked)}
                checked={isChecked}
              />,
              checked:isChecked,
              disableRipple:true,
              color:"default",
          })}  
        }}
        tableRef={tableRef}
        style={{ margin: '25px 0px' }}
        columns={getColumns()}
        data={filteredCategories.slice((currentPage - 1) * 10, currentPage * 10)}
        components={{
          Pagination: () => null,
        }}
        isLoading={loading}
        onRowClick={(_, rowData : CustomerCategory | undefined) => rowData && onSelectionChange(rowData, !selectedCategories.find(c => c._id == rowData._id && c.orgId == rowData.orgId))}
      />
      <div className="sup-pagination">
        {filteredCategories.length > 0 && (
          <Pagination
            count={Math.ceil(filteredCategories.length/10)}
            onChange={(_, value) => setCurrentPage(value)}
            page={currentPage}
          />
        )}
      </div>

    </>
  }

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      await serviceAreas.saveCustomerAreas(category, selectedCategories.map(c => ({ areaId: c._id, orgId: c.orgId })));
      NotificationManager.success("Sparat");
      const orgSlug = appState.getCurrentOrgSlug();
      history.push(`/${orgSlug}/supplier-database`);
    } catch (e : any) {
      const data = e?.response?.data;
      let error;
      if (data?.message) {
        error = `${data?.message}: ${data?.error}`;
      } else if (data?.error) {
        error = `${data?.error}`;
      } else {
        error = data;
      }
      NotificationManager.error(`Det gick inte att spara. ${error}`);
    }
    setSubmitting(false);
  }

  return (
    <AppLayout title={
      <BackButton
        to={"/supplier-database"}
        path={["Leverantörsdatabas", area ? `Koppla ${area.name} till kunders kategorier` : "..."]}
      />
    }>
      <div className={styles.container}>
        <div className={styles.content}>
          {renderSuppliers()}
          {renderSelectedCategories()}
          <div style={{alignSelf: "flex-end"}}>
            <BtButton
              loaderShow={submitting}
              onClick={handleSubmit}
            >
              Spara
            </BtButton>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}

export default PageCategoryMapping;
