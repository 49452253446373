import { FC, ReactNode } from "react"
import styles from "./SupplierAccountCard.module.scss"
import BtButton from "../../bt-button/bt-button"
import { isEmpty } from "lodash"
import TooltipContainer from "../../../components/shared/TooltipContainer/TooltipContainer"
import { logMessage } from "../../../services/service-logs";
import { SlackChannels } from "../../../util/appEnum"

export type SupplierStatus = {
  organizationNumber?: string,
  numOfUnansweredQuotations?: number,
  numOfQuotationsLast12Months?: number,
  numOfOrganizationsSupplierIsMissing?: number,
  notPartOfSalesOrg?: boolean,
  noSupplierProfile?: boolean,
  orgSlug?: string,
  adminUsers?: {
    name: string,
    email: string,
  }[],
}

type Props = {
  supplierStatus?: SupplierStatus,
}

export const SupplierAccountCard: FC<Props> = ({ supplierStatus }) => {
  if (isEmpty(supplierStatus)) {
    return null;
  }
  
  let text: ReactNode = null;
  let buttonText = '';

  if (supplierStatus?.numOfUnansweredQuotations) {
    text = <span>Ni har <b>{supplierStatus?.numOfUnansweredQuotations}</b> obesvarade förfrågningar senaste året i Accurator. Undvik att missa förfrågningar med ett leverantörskonto för ert företag.</span>;
    buttonText = 'Skapa konto';
  } else if (supplierStatus?.numOfQuotationsLast12Months) {
    text = <span>Ni har tagit emot <b>{supplierStatus?.numOfQuotationsLast12Months}</b> förfrågningar senaste året i Accurator. Hantera alla på samma ställe och slipp leta i mejlkorgen med ett leverantörskonto för ert företag.</span>;
    buttonText = 'Skapa konto';
  } else if (supplierStatus?.numOfOrganizationsSupplierIsMissing) {
    text = <span>Ni saknas i <b>{supplierStatus?.numOfOrganizationsSupplierIsMissing}</b> av våra kunders leverantörsdatabaser. Öka er synlighet och nå ut till fler med ett leverantörskonto för ert företag.</span>;
    buttonText = 'Skapa konto';
  } else if (supplierStatus?.notPartOfSalesOrg) {
    text = <div>
      <span>Det finns ett leverantörskonto för ert företag. Be någon av <TooltipContainer renderReferenceComponent={(className, ref) => <span className={className} ref={ref} style={{ borderBottom: '1px dotted var(--light-blue-800)', cursor: 'default' }}>administratörerna</span>}>
      {supplierStatus.adminUsers && supplierStatus?.adminUsers?.length > 0 && (
        <ul>
          {supplierStatus.adminUsers.map((adminUser) => <li>{adminUser.name} ({adminUser.email})</li>)}
        </ul>
      )}
      </TooltipContainer> att bjuda in dig för att ta del av fördelarna.</span>
    </div>;
  } else if (supplierStatus?.noSupplierProfile) {
    text = <span>Du har ett leverantörskonto, men leverantörspresentationen är inte ifylld. Ta kontroll över hur ert företag presenteras för inköpare genom att fylla i presentationen.</span>;
    buttonText = 'Fyll i presentation';
  }

  const onClick = async () => {
    const notRedirect =
      window.location.host === 'localhost:3000' ||
      window.location.host === 'test.accurator.se' ||
      window.location.host === 'app.accurator.se';
    const href = notRedirect ? window.location.href.split('/').slice(0, 3).join('/') : `https://app.accurator.se`;
    if (buttonText === 'Skapa konto') {
      window.location.href = `${href}/supplier-sign-up`;
      await logMessage('New supplier account creation button click', SlackChannels.SUPPLIERS);
    } else if (buttonText === 'Fyll i presentation') {
      window.location.href = `${href}/${supplierStatus?.orgSlug}/supplier-presentation`;
    }
  }

  return (
    <div className={styles.card}>
      <div className={styles.container}>
        <p>Leverantörskonto</p>
        <div className={styles.content}>
          {text}
        </div>
      </div>
      {buttonText && <BtButton color="white" onClick={onClick}>{buttonText}</BtButton>}
    </div>
  )
}