import * as React from "react";
import { FormattedMessage } from "react-intl";

export default class ValidatorEx extends React.Component {
  state = {
    isValid: false,
    showError: false
  };

  UNSAFE_componentWillMount() {
    if (this.props.validationContext) {
      this.props.validationContext.registerValidator(this);
    }
  }

  componentWillUnmount() {
    if (this.props.validationContext) {
      this.props.validationContext.unRegisterValidator(this);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let shouldValidate =
      this.props.shouldValidateUponChange == null
        ? true
        : this.props.shouldValidateUponChange;
    if (nextProps.value != this.props.value && shouldValidate) {
      this.validateInternal(nextProps.value);
    }
  }

  //public method
  validate(value) {
    let isValid = this.validateInternal(value || this.props.value);

    return isValid;
  }

  reset() {
    this.setState({
      showError: false
    });
  }

  validateInternal(value, shouldModifyState = true) {
    let isValid = false;
    let valType = this.props.validationType.toLowerCase();
    switch (valType) {
      case "required":
        {
          isValid = this.requiredFieldValidator(value);
        }
        break;
      case "regex":
        {
          isValid = this.regexValidator(value);
        }
        break;
      case "range":
        {
          isValid = this.rangeValidator(value);
        }
        break;
      case "compare":
        {
          isValid = this.compareValidator(value);
        }
        break;
      case "validatepassword":
        {
          isValid = this.passwordValidator(value);
        }
        break;
      case "email":
        {
          isValid = this.emailValidator(value);
        }
        break;
      case "checkenddate":
        {
          isValid = this.dateValidator(value);
        }
        break;
      case "swedenphonenumbervalidate":
        {
          isValid = this.validateSwedenPhoneNumber(value);
        }
        break;
    }

    if (shouldModifyState) {
      this.setState({
        isValid: isValid,
        showError: !isValid
      });
    }

    return isValid;
  }

  /********************** Required validation section start **********************************
  ===========================================================================================*/

  validateSwedenPhoneNumber(value) {
    return /^((((0{2}?)|(\+){1})46))[\d]{9}$/.test(value);
  }

  requiredFieldValidator(validateValue) {
    let val = validateValue;
    if (!val || val.length == 0 || (val.trim && val.trim().length == 0))
      return this.validationFailed();
    else return this.validationSuccessful();
  }

  regexValidator(validateValue) {
    let val = validateValue;
    let pattern = this.props.pattern;

    if (!val || !pattern) return this.validationSuccessful();
    else
      return new RegExp(pattern).test(val)
        ? this.validationSuccessful()
        : this.validationFailed();
  }

  emailValidator(validateValue) {
    let val = validateValue;
    //let pattern = this.props.pattern
    let pattern =
      "([a-zA-Z0-9_\\.\\-])+\\@(([a-zA-Z0-9\\-])+\\.)+([a-zA-Z0-9]{2,4})";

    if (!val) return this.validationSuccessful();
    else
      return new RegExp(pattern).test(val)
        ? this.validationSuccessful()
        : this.validationFailed();
  }

  rangeValidator(validateValue) {
    let dType = (this.props.type || "double").toLowerCase();
    let val =
      dType == "double" ? parseFloat(validateValue) : parseInt(validateValue);
    let min =
      dType == "double" ? parseFloat(this.props.min) : parseInt(this.props.min);
    let max =
      dType == "double" ? parseFloat(this.props.max) : parseInt(this.props.max);

    if (isNaN(val) || (isNaN(min) && isNaN(max)))
      return this.validationSuccessful();
    else
      return val >= min && (isNaN(max) || val <= max)
        ? this.validationSuccessful()
        : this.validationFailed();
  }

  dateValidator(validateValue) {
    let dType = (this.props.type || "date").toLowerCase();
    let currentDateval = validateValue
      ? this.convertDateStringToDate(validateValue)
      : null;
    let minDate = this.props.min
      ? this.convertDateStringToDate(this.props.min)
      : null;

    if (this.isValidDate(currentDateval) && this.isValidDate(minDate)) {
      currentDateval = this.removeTimeFromDate(currentDateval);
      minDate = this.removeTimeFromDate(minDate);

      var startRepReturnVal = true;
      if (currentDateval < minDate) {
        startRepReturnVal = false;
      }
      return startRepReturnVal
        ? this.validationSuccessful()
        : this.validationFailed();
    } else {
      return this.validationSuccessful();
    }
  }

  compareValidator(validateValue) {
    let validateValueTo = this.props.valueToCompare;
    if (validateValue == validateValueTo) {
      return true;
    } else {
      return false;
    }
  }

  passwordValidator(validateValue) {
    if (!validateValue || validateValue.length == 0) return true;
    if (validateValue.length >= 4) return true;
    else return false;
  }

  validationFailed() {
    return false;
  }

  validationSuccessful() {
    return true;
  }

  /**
   * Removes time part from the provided date object. Example: 2018-10-09 14:06:07 becomes 2018-10-09 00:00:00.
   */
  removeTimeFromDate(date) {
    if (date) {
      return date.setHours(0, 0, 0, 0);
    }

    return null;
  }

  convertDateStringToDate(dateStr) {
    return dateStr && dateStr != "-" && new Date(dateStr);
  }

  isValidDate(date) {
    return date != null;
  }
  /*=======================================================================================
   ********************* Required validation section end ***********************************/

  render() {
   
    if(this.props.nodeType === "p") {
      return (
        this.state.showError && (
          <FormattedMessage id={this.props.errorKey || "FieldIsRequired"}>
            {txt => (
              <p className={`validator ${this.props.className}`} style={{fontSize:"14px",fontWeight:"500"}}>{txt}</p>
            )}
          </FormattedMessage>
        )
      );
    }
    return (
      this.state.showError && (
        <FormattedMessage id={this.props.errorKey || "FieldIsRequired"}>
          {txt => (
            <span className={`validator ${this.props.className}`}>{txt}</span>
          )}
        </FormattedMessage>
      )
    );
  }
}
