import axios from "axios";
import BaseService from "./baseService";

class ContractService extends BaseService {
  async uploadContract(
    slug,
    areaId,
    title,
    file,
    supplierId,
    attachments,
    amount,
    monitorSupplier,
    supplierControlsHTML
  ) {
    const payload = {
      slug,
      title,
      areaId,
      supplierId,
      file,
      ...(attachments.reduce((prev, attachment, index) => {
        prev["attachment_" + index] = attachment; return prev;
      }, {})),
      amount,
      monitorSupplier,
      supplierControlsHTML,
    };
    const formData = new FormData();
    for (let key in payload) {
      if (payload[key] != undefined && payload[key] != null) formData.append(key, payload[key]);
    }
    const response = await axios.post(
      "/api/project/" + slug + "/manualContract",
      formData
    );
    return response.data.data;
  }

  async deleteContract(slug, areaId, filePath) {
    const payload = {
      areaId,
      filePath,
    };
    const response = await axios.delete(
      "/api/project/" + slug + "/manualContract",
      {
        data: payload
      }
    );
    return response.data.data;
  }
}

export const ManualContractService = new ContractService();
