import React from "react";
import "./app-label.css";

export default class AppLabel extends React.Component {
  render() {
    const style = this.props.customStyle ? this.props.customStyle : {};
    return (
      <label className={`app-label ${this.props.class}`}>
        <span className="headline" style={style}>
          {this.props.headline}
        </span>
        {this.props.required ? <span className="mandatory">*</span> : ""}
        {this.props.children}
      </label>
    );
  }
}
