import { FC, useState } from "react";
import appState from "../../../state/AppStateContainer";
import CustomAvatar from "../CustomAvatar";
import Modal from "../Modal/Modal";
import { TrashIcon } from "../../svgs";
import TitleWrapper from "../TitleWrapper/TitleWrapper";
import Selector from "../Selector/Selector";
import { ControlProps, OptionProps, SingleValue, components } from 'react-select';
import { ServiceProjects } from "../../../services/service-projects";
import { ServiceUser } from "../../../services/service-user";
import _ from "lodash";
import { autosave } from "../../../util";

const MAX_TEAM_SIZE = 5;

type OptionType = {
  label: string;
  value: string;
  __isNew__?: boolean | undefined;
  avatarUrl: string,
} | null;

type Props = {
  users: OptionType[],
  areaUsers: {
    _id: string,
    name: string,
    avatarUrl: string,
  }[],
  currentProject: any,
  areaId: string,
  setAreaUsers?: (areaUsers: {
    _id: string,
    name: string,
    avatarUrl: string,
  }[]) => void,
}

export const TeamBuilder: FC<Props> = ({ users, areaUsers, setAreaUsers, currentProject, areaId }) => {
  const [showTeamBuilderModal, setShowTeamBuilderModal] = useState(false);
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<SingleValue<OptionType>>(null);
  const isReadOnlyUser = appState.isReadOnlyAccessRole();
  const shouldNotRender = (!isReadOnlyUser && areaUsers.length < MAX_TEAM_SIZE) || (areaUsers && areaUsers.length > 0);

  const renderOption = (value: { avatarUrl: string, label: string }) => (
    value ? <CustomAvatar style={{ marginRight: '10px' }} size="24px" src={value.avatarUrl} alt={value.label} /> : null
  );

  const addUserToArea = async (userName: string, userId: string, avatarUrl: string) => {
    const projectId = currentProject._id;

    const userExistsInArea = areaUsers.find(user => user._id == userId);
    if (!userExistsInArea) {
      autosave(async () => {
        await ServiceUser.addMemberToArea(userId, projectId, areaId);
        ServiceProjects.addAreaMember(projectId, areaId, { name: userName, _id: userId, avatarUrl });
        if(setAreaUsers) {
          setAreaUsers([...areaUsers, { name: userName, _id: userId, avatarUrl }]);
        }
      })
    }
    setSelectedUser(null);
    setShowTeamBuilderModal(false);
  }

  const removeUserFromArea = async (userId: string) => {
    const projectId = currentProject._id;

    autosave(async () => {
      await ServiceUser.removeMemberFromArea(userId, projectId, areaId);
      ServiceProjects.removeAreaMember(projectId, areaId, userId);
      if(setAreaUsers) {
        setAreaUsers(areaUsers.filter(user => user._id !== userId));
      }
    })
    
    setSelectedUser(null);
    setShowTeamBuilderModal(false);
  }

  return shouldNotRender ? (
    <div className='teamBuilderContainer' onClick={e => e.stopPropagation()}>
      <div onClick={() => {
        setShowTeamModal(true);
      }}>
        {areaUsers.map(({name, _id, avatarUrl}) => 
          <CustomAvatar key={name+_id} alt={name} src={avatarUrl} size="35px" />
        )}
      </div>

      {!isReadOnlyUser && areaUsers.length < MAX_TEAM_SIZE && (
        <div className={`teamBuilderAddDiv ${areaUsers.length === 0 ? 'noUsersSelected' : ''}`} onClick={() => {
          setShowTeamBuilderModal(true);
        }}>
          <svg width="13px" height="16px" viewBox="0 0 15 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.76923 4.82353C2.76923 2.15957 4.88727 0 7.5 0C10.1127 0 12.2308 2.15957 12.2308 4.82353C12.2308 6.49625 11.3957 7.9701 10.1274 8.83533C12.9737 9.92091 15 12.7192 15 16L15 18L0 18L0 16C0 12.7192 2.02635 9.92091 4.87257 8.83533C3.6043 7.9701 2.76923 6.49625 2.76923 4.82353ZM7.49998 7.52941C8.96566 7.52941 10.1538 6.31795 10.1538 4.82353C10.1538 3.32911 8.96566 2.11765 7.49998 2.11765C6.0343 2.11765 4.84613 3.32911 4.84613 4.82353C4.84613 6.31795 6.0343 7.52941 7.49998 7.52941ZM2.07813 15.8823C2.13953 12.8829 4.54347 10.4706 7.5 10.4706C10.4565 10.4706 12.8605 12.8829 12.9219 15.8823L2.07813 15.8823Z" id="Shape" fill="var(--gray-300)" fillRule="evenodd" stroke="none" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
            <path fill="var(--white)" fillRule="evenodd" d="M6.66666667,5.33333333 L11.3333333,5.33333333 C11.7015232,5.33333333 12,5.63181017 12,6 C12,6.36818983 11.7015232,6.66666667 11.3333333,6.66666667 L6.66666667,6.66666667 L6.66666667,11.3333333 C6.66666667,11.7015232 6.36818983,12 6,12 C5.63181017,12 5.33333333,11.7015232 5.33333333,11.3333333 L5.33333333,6.66666667 L0.666666667,6.66666667 C0.298476833,6.66666667 0,6.36818983 0,6 C0,5.63181017 0.298476833,5.33333333 0.666666667,5.33333333 L5.33333333,5.33333333 L5.33333333,0.666666667 C5.33333333,0.298476833 5.63181017,0 6,0 C6.36818983,0 6.66666667,0.298476833 6.66666667,0.666666667 L6.66666667,5.33333333 Z" />
          </svg>
        </div>
      )}
      <Modal
        title='Ansvarig'
        show={showTeamModal}
        setShow={(value) => setShowTeamModal(value)}
        hasCloseButton
        unmountWhenHidden
      >
        {areaUsers && areaUsers.length > 0 && (
          <div className='teamBuilderModalContainer'>
            {areaUsers.map(user => (
              <div key={user._id} className="tbSubContainer">
                <div>
                  <CustomAvatar alt={user.name} src={user.avatarUrl} size="35px" className="selected-team-avatar" />  <div>{user.name}</div>
                </div>

                {!isReadOnlyUser && (
                  <div onClick={() => {
                    if (areaUsers.length == 1) {
                      setShowTeamModal(false);
                    }
                    removeUserFromArea(user._id);
                  }}>
                    <TrashIcon color='var(--gray-400)' />
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </Modal>
      <Modal
        title='Lägg till person'
        show={showTeamBuilderModal}
        setShow={(value) => setShowTeamBuilderModal(value)}
        buttonInfo={{
          label: 'Lägg till', disabled: areaUsers.length >= MAX_TEAM_SIZE, action: async () => {
            if (selectedUser && selectedUser.label && areaUsers.length < MAX_TEAM_SIZE) {
              addUserToArea(selectedUser.label, selectedUser.value, selectedUser.avatarUrl);
            }
          }
        }}
        unmountWhenHidden
      >
        <TitleWrapper title='Person'>
          <Selector
            value={selectedUser}
            onChange={(value) => setSelectedUser(value)}
            options={users}
            placeholder=''
            isOptionDisabled={(option) => !!areaUsers.find(user => user._id == option?.value)}
            components={{
              Control: ({ children, ...rest }: ControlProps) => (
                <components.Control {...rest}>
                  {selectedUser ? renderOption(selectedUser) : ''} {children}
                </components.Control>
              ),
              Option: ({ children, ...rest }: OptionProps) => (
                <components.Option {...rest}>
                  {
                    // @ts-ignore
                    renderOption(rest.data)
                  } {children}
                </components.Option>
              ),
            }}
          />
        </TitleWrapper>
      </Modal>
    </div>
  ) : null
}

export default TeamBuilder;