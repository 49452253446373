import MaterialTable, { Column, MaterialTableProps, Query, QueryResult  } from "@material-table/core";
import { ReactElement, useCallback, useRef } from "react";
import { tableIcons } from "../../../ts-components/app-supplier-database/icons";
import styles from './DataTable.module.scss';
import Pagination from "@mui/material/Pagination";
import { createPortal } from "react-dom";

export type CustomTableProps<T extends object> = MaterialTableProps<T>

type Props<T extends Object> = CustomTableProps<T> & {
  tableRef?: any,
  title?: string | ReactElement,
  isLoading: boolean,
  columns: Column<T>[],
  data: T[] | ((query: Query<object>) => Promise<QueryResult<T>>),
  onRowClick?: (
    event?: React.MouseEvent,
    rowData?: T,
    toggleDetailPanel?: (panelIndex?: number) => void
  ) => void,
  pageSize?: number,
  style?: React.CSSProperties,
  isScrollable?: boolean,
}

const DataTable = <T extends Object = {}>({
  tableRef,
  title,
  isLoading,
  columns,
  data,
  onRowClick,
  pageSize,
  style,
  isScrollable,
} : Props<T>) => {
  const pagination = useRef(null);
  const rowStyle = useCallback(() => ({
    color: 'var(--gray-800)'
  }), []);

  const hasPaging = !!(pageSize && pageSize > 0);

  let tableStyle: React.CSSProperties = { marginBottom: '0' } ?? {};

  if (isScrollable) {
    tableStyle = {...tableStyle, minWidth: '100%', width: 'fit-content'};
  }

  return (<>
    <div style={style} className={`supplier-db-main-table-wrap ${styles.container} ${isScrollable ? styles.isScrollable : ''}`}>
      <MaterialTable<T>
        tableRef={tableRef}
        title={title}
        icons={tableIcons}
        style={tableStyle}
        isLoading={isLoading}
        options={{
          sorting: true,
          draggable: false,
          search: false,
          toolbar: false,
          paging: hasPaging,
          pageSize: pageSize,
          detailPanelColumnStyle: { display: 'none' },
          rowStyle: rowStyle,
          columnResizable: false,
        }}
        localization={{ body: { emptyDataSourceMessage: 'Kunde inte hitta något resultat' } }}
        columns={columns}
        data={data}
        onRowClick={onRowClick}
        components={{
          Pagination: ({page, count, onPageChange}) => {
            if (!hasPaging) return null;
            return pagination.current && createPortal(
              <Pagination
                count={Math.ceil(count/10)}
                onChange={(_, value) => onPageChange(_, value - 1)}
                page={page+1}
              />, pagination.current
            );
          },
        }}
      />
    </div>
    {hasPaging && <div ref={pagination} className="sup-pagination org-pagination" style={{display:"flex", justifyContent: "center", marginTop: "16px"}}></div>}
    </>
  );
}

export default DataTable;