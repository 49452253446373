import React from 'react';
import './MessageLog.css';
import moment from 'moment';
import CustomAvatar from './../CustomAvatar';

export default function MessageLog({name, log, timestamp, avatarUrl}) {
  return (
      <div className='messageLog'>
      {name && <CustomAvatar alt={name} src={avatarUrl} className="org-switcher-avatar" />}
        <div>
          <div className='messageLogContent'>
            {name && <p className='messageLogName'>{name}</p>}
            <p>{log}</p>
          </div>
          <p>{moment(timestamp).format('YYYY-MM-DD HH:mm')}</p>
        </div>
      </div>
  );
}