import axios from 'axios';

export const hideAndCreateNewDemand = async (newName: string, demandId: string): Promise<{ name: string, _id: string }> => {
  const response = await axios.put(`/api/demands/${demandId}/hide-and-create-new`, {
    newName
  });

  return response.data;
}

export const deleteDemand = async (demandId: string): Promise<void> => {
  const response = await axios.delete(`/api/demands/${demandId}`);

  return response.data;
}