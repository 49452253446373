//import { Component, h, Host, Element, Prop } from '@stencil/core';
import React from "react";
//import { IAppChatWriteMessageSubmitData } from '../app-chat-write-message/app-chat-write-message';
//import anime from 'animejs';
import { AppHeadsup } from "../app-headsup/app-headsup";
import { AppChatMessages } from "../app-chat-messages/app-chat-messages";
import { AppChatFileUploads } from "../app-chat-file-uploads/app-chat-file-uploads";
import PropTypes from "prop-types";
import _ from "lodash";
import "./app-chat.css";
import appState from "../../state/AppStateContainer";
import moment from "moment";
import { ServiceChat } from "../../services/service-chat";
import PageInternalChatMember from "../page-internal-chat/page-internal-chat-member";
import BtGrid from "../bt-grid/bt-grid";
import BtRow from "../bt-row/bt-row";
import BtColumn from "../bt-column/bt-column";
import ChatInput from "../app-chat-write-message/ChatInput";
import { FileScope } from "../../util/appEnum";
import { resizeScroller } from "../app-chat/app-chat";

moment.locale("sv");

export class AppInternalMessage extends React.Component {
  intervalID = 0;

  //elTextarea;
  //elBtnSend;
  state = {
    clients: [],
    currentSupplier: {},
    currentClient: {},
    totalFilesClient: [],
    totalFilesUser: [],
    messages: []
  };
  elColumnOverflow;
  elMessages;
  elColumnUploads;
  elAppChatWriteMessage;
  elAppChatFileUploads;
  elAppHeadsup;

  element;

  messages = this.props.messages;
  currentSupplier = this.props.currentSupplier;
  currentClient = this.props.currentClient;
  clients = this.props.clients;
  totalFilesClient = this.props.totalFilesClient;
  totalFilesUser = this.props.totalFilesUser;
  baseURL = this.props.baseURL;
  role = this.props.role;
  newMessage = Array();
  chatOnly = this.props.chatOnly;
  notReadMessageCounter;
  static contextTypes = {
    router: PropTypes.object
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    // if (this.props.currentSupplier === nextProps.currentSupplier) {
    this.componentHasHeight(this.elColumnOverflow).then(() => {
      this.bind();
      this.windowResizeCallback(null);
      setTimeout(() => {
        this.windowResizeCallback(null);
      }, 200);
    });

    this.intervalID = setTimeout(() => {
      this.resizeAppChatWriteMessage();
      this.resizeAppChatFileUploads();
      this.positionAppHeadsup();
      // this.handleHeadsup();
    }, 1000);
    // }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  componentHasHeight(el) {
    return new Promise((_resolve, _reject) => {
      let timeout = () => {
        setTimeout(() => {
          if (el && el.offsetHeight > 1) {
            _resolve();
          } else {
            timeout();
          }
        }, 0);
      };
      timeout();
    });
  }

  bind() {
    this.unbind();
    window.addEventListener("resize", this.windowResizeCallback);
  }

  unbind() {
    window.removeEventListener("resize", this.windowResizeCallback);
  }

  windowResizeCallback = () => {
    this.resizeAppMessages();
    this.resizeAppChatWriteMessage();
    this.resizeAppChatFileUploads();
  };

  resizeAppMessages() {
    // this.elMessages.style.height = 0 + "px";
    // this.elMessages.style.height =
    //   this.elColumnOverflow.offsetHeight - 0 + "px"; //this.elAppChatWriteMessage.offsetHeight) + 'px';
  }

  resizeAppChatWriteMessage() {
    // this.elAppChatWriteMessage.style.width = this.elColumnOverflow.offsetWidth + 'px';
  }

  resizeAppChatFileUploads() {
    // var rect = this.elColumnUploads.getBoundingClientRect();
    // this.elColumnUploads.style.height = window.innerHeight - rect.top + "px";
  }

  positionAppHeadsup() {
    // var elRect = this.elAppHeadsup.getBoundingClientRect();
    // var messagesRect = this.elMessages.getBoundingClientRect();
    // var rect = this.elColumnUploads.getBoundingClientRect();
    // this.elAppHeadsup.style.top = messagesRect.top + "px";
    // this.elAppHeadsup.style.left = rect.left - elRect.width - 50 + "px";
  }

  handleHeadsup() {
    var newMessages = this.elMessages.querySelectorAll(".new-message");
    var found = 0;
    newMessages.forEach(el => {
      // Check if message is in viewport
      if (!this.isInViewport(el)) {
        found++;
      } else {
        el.classList.remove("new-message");
      }
    });

    this.notReadMessageCounter = found;
  }

  isInViewport = elem => {
    var bounding = elem.getBoundingClientRect();
    var itemTop =
      bounding.top - this.elColumnUploads.getBoundingClientRect().top;
    var viewportHeight = this.elMessages.getBoundingClientRect().height;
    return (
      itemTop >= -10 &&
      (itemTop < viewportHeight / 2 ||
        itemTop + (bounding.height - 40) <= viewportHeight)
    );
  };

  sendMessage(data, animateScroll) {
    var func = () => {
      // this.element.classList.remove("animate-opacity");
      this.props.currentClient.hasConversation = true;
      //this.props.messages.unshift(data);
      this.setState({
        clients: [...this.props.clients]
      });
      // this.element.classList.add("added-message");
      // setTimeout(() => {
      //   this.element.classList.add("animate-opacity");
      //   this.element.classList.remove("added-message");
      //   this.handleHeadsup();
      // }, 1000);

      if (data.type == "client") {
        this.setState({
          totalFilesClient: [...this.props.totalFilesClient, ...data.files]
        });
      } else {
        this.setState({
          totalFilesUser: [...this.props.totalFilesUser, ...data.files]
        });
      }

      this.resizeAppChatFileUploads();
    };

    if (animateScroll) {
      this.scrollTop(func);
    } else {
      func();
    }
  }

  scrollTop(func) {
    var scrollYpos = this.elMessages.scrollTop;
    var duration = 1000;
    var delay = 0;
    if (scrollYpos <= 0) {
      duration = 0;
      delay = 0;
    } else if (scrollYpos < 200) {
      duration = 300;
    }

    var obj = {
      targets: this.elMessages,
      scrollTop: 0,
      duration: duration,
      delay: delay,
      easing: "easeInOutCubic",
      complete: func
    };
    if (!func || func instanceof Event) {
      delete obj.complete;
    }

    //anime(obj);
  }

  elWriteMessageUpdate() {
    this.windowResizeCallback(null);
  }

  elWriteMessageComplete() {
    this.windowResizeCallback(null);
  }
  // getLocalDateTime(givenDate) {
  //   const utcDate = givenDate.toString();
  //   // var gmtDateTime = moment.utc(utcDate, "YYYY-MM-DD HH");
  //   const gmtDateTime = moment.utc(utcDate);
  //   const local = gmtDateTime.local().format("YYYY-MMM-DD h:mm A");
  //   return moment(local, "YYYY-MMM-DD h:mm A").format("llll");
  // }

  async elWriteMessageSubmit(data) {
    if (data.message == "" && _.isEmpty(data.files)) {
      return;
    }
    var today = new Date();
    const messageObj = {};
    messageObj.message = data.message || "";
    messageObj.name = appState.state.token.name;
    messageObj.email = appState.state.token.email;
    messageObj.phone = appState.state.token.phone;
    messageObj.type = "user";
    messageObj.date = moment();
    messageObj.timestamp =
      today.getFullYear() + "-" + today.getMonth() + "-" + today.getDay();
    messageObj.files = data.files.map(x => ({
      // date:moment().format("YYYY-MM-DD"),
      // id: x.lastModified,
      mimeType: x.mimeType,
      date: moment(),
      file: x.name
    }));
    this.sendMessage(messageObj);
    await ServiceChat.sendMessage(
      this.props.currentClient._id,
      data.message,
      data.files
    );
    // await ServiceProjects.postAdminMessage(
    //   this.props.currentProjectId,
    //   this.props.currentSupplier._id,
    //   this.props.currentClient._id,
    //   data.message,
    //   data.files,
    //   this.props.areaId
    // );
    // this.responseDemo();
  }

  editMemberBtnCallback(isOwnEdit) {
    if (this.props.editMemberBtnCallback) {
      let obj = this.props.currentClient;
      obj.isOwnEdit = isOwnEdit;
      this.props.editMemberBtnCallback(obj);
    }
  }

  render() {
    const isGhost = appState.isGhostUser();
    return (
      <div className="app-chat">
        <div ref={el => (this.elAppHeadsup = el)}>
          <AppHeadsup
            onClick={this.scrollTop.bind(this)}
            count={this.notReadMessageCounter}
          />
        </div>
        <BtGrid className="chat-msg-cn-wrapper info--style" id="chat-msg-cn-wrapper">
          <BtRow className="chat-message-window">
              <div
                className="custom-scrollbar chat-message-scroller style--2"
                ref={el => (this.elMessages = el)}
              >
                <AppChatMessages
                  currentClient={this.props.currentClient}
                  chatOnly={this.props.chatOnly}
                  scope={FileScope.InternalConversation}
                  scopeOptions={{
                    toUserId: this.props.currentClient._id
                  }}
                  messages={
                    _.isEmpty(this.state.messages)
                      ? this.props.messages
                      : this.state.messages
                  }
                  className="messages"
                ></AppChatMessages>
              </div>
            {/* <BtColumn className="bt-contract info--panel">
              {this.props?.currentClient && (
                <PageInternalChatMember
                  editMemberBtnCallback={this.editMemberBtnCallback.bind(this)}
                  currentMember={this.props.currentClient}
                ></PageInternalChatMember>
              )}
            </BtColumn> */}
            <BtColumn
              className="bt-contract column-uploads uploads-gallery-wrapper"
              id="uploads-gallery-wrapper"
              ref={el => (this.elColumnUploads = el)}
            >
              <AppChatFileUploads
                nameUser={`${this.props.adminUser.name}`}
                nameClient={this.props.currentClient.name}
                scope={FileScope.InternalConversation}
                scopeOptions={{
                  toUserId: this.props.currentClient._id
                }}
                filesClient={
                  _.isEmpty(this.state.totalFilesClient)
                    ? this.props.totalFilesClient
                    : this.state.totalFilesClient
                }
                filesUser={
                  _.isEmpty(this.state.totalFilesUser)
                    ? this.props.totalFilesUser
                    : this.state.totalFilesUser
                }
                ref={el => (this.elAppChatFileUploads = el)}
              ></AppChatFileUploads>
            </BtColumn>
          </BtRow>
          {!isGhost && appState.state.token.id !== this.props.currentMember._id && this.props.currentMember?._id && (
            <BtRow className="bt-contract app-chat-footer">
              <BtColumn>
                <ChatInput onSubmit={this.elWriteMessageSubmit.bind(this)} resizeScroller={resizeScroller}/>
              </BtColumn>
            </BtRow>
          )}
        </BtGrid>
      </div>
    );
  }
}
