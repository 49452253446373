import { Card, Color, DonutChart, Text } from "@tremor/react";
import { FC } from "react";
import { formatAmount } from "../../../../util";
import styles from './PieChart.module.scss';
import { ReactComponent as InfoIcon } from '../../../../assets/svg/InfoIconLight.svg';
import { Legend } from "@tremor/react";
import TooltipContainer from "../../TooltipContainer/TooltipContainer";

type Props = {
  title: string,
  data: {
    key?: string;
    value: number;
    name: string;
  }[],
  isPercentage?: boolean,
  style?: React.CSSProperties,
  infoText?: string,
  colors?: Color[],
  categories?: string[],
}

const PieChart: FC<Props> = ({ data, categories, title, isPercentage, style, infoText, colors = ['emerald', 'yellow', 'rose', 'slate'] }) => {
  const percentageFormatter = (number: string): string => `${number}%`;

  return (
    <div style={style} className={styles.pieChartWrapper}>
      <Card key={title}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <Text>
            {title}
          </Text>
          {infoText && (
            <TooltipContainer renderReferenceComponent={(className, ref) => <div className={className} ref={ref}><InfoIcon /></div>}>
              <div style={{ maxWidth: "300px", fontSize: "14px", color: "var(--gray-700)" }}>
                <p style={{ fontWeight: 500, color: "var(--gray-900)"}}>{title}</p>
                <p style={{marginTop: "0.5em"}}>{infoText}</p>
              </div>
            </TooltipContainer>
          )}
        </div>
        {categories && <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
          <Legend categories={categories} colors={colors} style={{ flexDirection: 'column' }} />
        </div>}
        <DonutChart
          data={data}
          className="mt-2"
          category='value'
          index='name'
          colors={colors}
          valueFormatter={isPercentage ? (value) => percentageFormatter(formatAmount(value)) : formatAmount}
        />
      </Card>
    </div>
  );
}

export default PieChart;