import axios from "axios";

export const fetchConversation = async (path) => {
  const res = await axios.get(`/api${path}/conversation`);
  return res.data;
}

export const updateConversation = async (path, content) => {
  const res = await axios.post(`/api${path}/conversation`, {
    content,
  });
  return res.data;
}
