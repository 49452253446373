import { CSSProperties, FC } from 'react';
import styles from './NotificationCounter.module.scss';

type Props = {
  counter?: number,
  style?: CSSProperties,
  className?: string,
}

const NotificationCounter: FC<Props> = ({ counter, style, className }) => {
  return !!counter && counter > 0 ? (
    <div className={`${styles.container} ${className}`} style={style}>
      {counter}
    </div>
  ) : null;
}

export default NotificationCounter;