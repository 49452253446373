import * as React from 'react';
import ValidationContext from '../utils/ValidationContext'
import ValidatorEx from '../controls/ValidatorEx'

/**
* Base class for the majority of the components.
* This class makes it easy for the sub-classes to use filters, validators and query logged-in user information.
*
* @extends React.Component
*/
export default class BaseComponent extends React.Component {

  _defaultValidationContext = null
  constructor(props) {
    super(props);
    this._defaultValidationContext = new ValidationContext();

  }

  componentWillUnmount() {
    this._defaultValidationContext = null;
  }

  /**
  * Determines if the user is logged in.
  */
  isUserLoggedIn(userDetails) {
    return false;
  }

  /**
  * Gets validation context of this instance.
  */
  getDefaultValidationContext() {
    return this._defaultValidationContext;
  }

  /**
  * Returns whether all validators of this class satisfy their validation conditions.
  */
  isPageValid() {
    let context = this.getDefaultValidationContext();

    return context.validate();
  }

  /**
  * Resets all validators of this instance.
  */
  resetValidators() {
    let context = this.getDefaultValidationContext();

    return context.reset();
  }

  /**
  * Renders RequiredFieldValidator.
  * @param {object} value Value to check required condition against.
  * @param {string} errorKey LanguageKey which is shown if the condition fails.
  * @param {string} nodeType set nodetype to p. Default is span
  */
   renderRequiredValidator(value, errorKey = "Required",nodeType) {

    return this.renderValidator(value, errorKey, "Required",{nodeType});
  }

  /**
  * Renders RangeFieldValidator.
  * @param {object} value Value to validate.
  * @param {string} errorKey LanguageKey which is shown if the condition fails.
  * @param {int} min Minimum range.
  * @param {int} max Maximum range.
  * @param {string} vType Type of the value.
  */
  renderRangeValidator(value, errorKey, min = 0, max = 100, vType = "double") {
    return this.renderValidator(value, errorKey, "Range", { min: min, max: max, type: vType });
  }

  /**
  * Renders RegexFieldValidator.
  * @param {object} value Value to validate.
  * @param {string} errorKey LanguageKey which is shown if the condition fails.
  * @param {string} pattern Regex pattern that the validator will use for valdiation.
  */
  renderRegexValidator(value, errorKey, pattern) {
    return this.renderValidator(value, errorKey, "Regex", { pattern: pattern });
  }

  /**
  * Renders CompareFieldValidator.
  * @param {object} value Value to validate.
  * @param {object} valueToCompare A value to compare with the first param of the method(value).
  * @param {string} errorKey LanguageKey which is shown if the condition fails.
  */
  renderCompareValidator(value, valueToCompare, errorKey = "IsMatch") {
    return this.renderValidator(value, errorKey, "Compare", { valueToCompare: valueToCompare });
  }

  /**
  * Renders PasswordFieldValidator.
  * @param {object} value Value to validate.
  * @param {string} errorKey LanguageKey which is shown if the condition fails.
  * @param {bool} shouldValidateUponChange Tells the method whether to validate as the value changes or to validate when the user is done with editing.
  */
  renderPasswordValidator(value, errorKey = "IsPasswordValid", shouldValidateUponChange = true) {
    return this.renderValidator(value, errorKey, "ValidatePassword", { shouldValidateUponChange: shouldValidateUponChange });
  }

  /**
  * Renders EmailFieldValidator.
  * @param {object} value Value to validate.
  * @param {string} errorKey LanguageKey which is shown if the condition fails.
  */
  renderEmailValidator(value, errorKey = "Är denna e-post korrekt?") {
    return this.renderValidator(value, errorKey, "Email");
  }


   /**
  * Renders EmailFieldValidator.
  * @param {object} value Value to validate.
  * @param {string} errorKey LanguageKey which is shown if the condition fails.
  */
 renderPhoneNumberValidator(value, errorKey = "nummer inte giltigt") {
  return this.renderValidator(value, errorKey, "swedenPhoneNumberValidate");
}

  /**
  * Renders PhoneOrSMSNumberFieldValidator.
  * @param {object} value Value to validate.
  * @param {string} errorKey LanguageKey which is shown if the condition fails.
  */
  renderPhoneOrSMSNumberValidator(value, errorKey = "IsNumberValid") {
    return this.renderRegexValidator(value, errorKey, "^\\d{0,20}$");
  }


  /**
  * Renders EndNotLessThanStartDateFieldValidator.
  * @param {object} value Value to validate.
  * @param {string} errorKey LanguageKey which is shown if the condition fails.
  * @param {int} min Minimum value.
  * @param {string} vType Type of the value.
  */
  renderEndNotLessThanStartDateValidator(value, errorKey, min, vType = "date") {
    return this.renderValidator(value, errorKey, "checkenddate", { min: min, type: vType });
  }


  /**
  * Generic method to render different kind of  validators.
  * @param {object} value Value to validate.
  * @param {string} errorKey LanguageKey which is shown if the condition fails.
  * @param {object} type Type of the validator.
  * @param {object} vProps Properties of the validator.
  */
  renderValidator(value, errorKey, type, vProps = {}) {
    return (
      <ValidatorEx errorKey={errorKey}
        validationType={type} value={value}
        validationContext={this.getDefaultValidationContext()}
        className="error show"
        {...vProps} />
    );
  }

  /**
  * Determines if the debug mode is active.
  */
  isDebugMode() {
    let hash = window.location.hash;
    return hash && hash.indexOf("debug") >= 0;
  }

  /**
  * Returns debug level.
  */
  getDebugLevel() {
    let hash = window.location.hash;
    let index = hash ? hash.indexOf("level=") : -1;
    if (index >= 0)
      return parseInt(hash.substr(index + 6, 1));

    return -1;
  }
}
