import { useEffect } from "react";
import { withRouter } from "react-router-dom";

// Scroll to top when location changes
function ScrollToTop(props) {
  const { pathname } = props.location;

  useEffect(() => {
    // window.scrollTo(0, 0);
    document.getElementsByClassName("body-wrapper")?.[0]?.scrollTo(0,0);
  }, [pathname]);

  return null;
}

export default withRouter(ScrollToTop);