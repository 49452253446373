import React from 'react';
import './styles.scss';

export default function ShowMoreText({ children, customClass = '' }) {
  const ref = React.createRef();
  const [showMore, setShowMore] = React.useState(false);
  const [showLink, setShowLink] = React.useState(false);

  React.useLayoutEffect(() => {
    if (ref.current.clientHeight < ref.current.scrollHeight) setShowLink(true);
  }, [ref]);

  const onClickMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className={`show-more-text-container ${customClass}`}>
      <div ref={ref} className={`${showMore ? 'show-more' : 'show-less'}`}>
        {children}
      </div>
      {showLink && (
        <div className="btn" onClick={onClickMore}>
          {showMore ? 'Läs mindre' : 'Läs mer'}
        </div>
      )}
    </div>
  );
}
