
import { FC, useState } from 'react';
import { Droppable, Draggable, DraggableStateSnapshot, NotDraggingStyle, DraggingStyle } from 'react-beautiful-dnd';
import { DragDropContext } from 'react-beautiful-dnd';
import type { DroppableProvided, DragUpdate } from 'react-beautiful-dnd';
import { TrashIcon, DraggableIndicatorIcon } from '../../svgs';
import './draggable-dropdown.css';
import { OptionType, OptionTypeWithRequired } from '../../../models/global.model';
import Field from '../Field/Field';
import StyledCheckbox from '../StyledCheckbox/StyledCheckbox';
import Selector from '../Selector/Selector';

type Props = {
  options: OptionType[],
  list: OptionTypeWithRequired[],
  onChange: (options: OptionTypeWithRequired[]) => void,
  units?: OptionType[],
};

const DraggableDropdown: FC<Props> = ({
  options,
  list,
  onChange,
  units,
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const getStyles = (
    style: DraggingStyle | NotDraggingStyle | undefined,
    snapshot: DraggableStateSnapshot
  ) => {
    const styles: (DraggingStyle | NotDraggingStyle | undefined) & { transform?: string, backgroundColor?: string, borderColor?: string } = { ...style };
    if (styles?.transform) {
      const axisLockY =
        "translate(0px" +
        styles.transform.slice(
          styles.transform.indexOf(","),
          styles.transform.length
        );
      styles.transform = axisLockY;
    }

    if (snapshot.isDragging) {
      setIsDragging(true);
      styles.backgroundColor = 'var(--gray-50)';
      styles.borderColor = 'var(--gray-300)';
    }

    return styles;
  }

  const handleOnDragEnd = (result: DragUpdate) => {
    setIsDragging(false);
    const destination = result.destination;

    if(destination) {
      const items = Array.from(list);
      const [ reorderedItem ] = items.splice(result.source.index, 1);
      items.splice(destination?.index, 0, reorderedItem);
      onChange(items);
    }
  };

  const onSelectValue = (value: string, index: number) => {
    const previous = list[index];
    if (!previous) {
      throw new Error("Could not find key for list item with index " + index);
    }
    const option = { ...previous, label: value };
    const newList = [...list];
    newList[index] = option;
    onChange(newList);
  };

  const onRemoveOption = (index: number) => {
    const newList = [...list];
    newList.splice(index, 1);
    onChange(newList);
  };
  
  const onRequiredChange = (opt: OptionTypeWithRequired, index: number, value: boolean) => {
    const newList = [...list];
    if (opt) {
      newList[index] = {...opt, required: value};
    }
    
    onChange(newList);
  }

  const onUnitChange = (opt: OptionTypeWithRequired, index: number, value: OptionType) => {
    const newList = [...list];
    if (opt) {
      newList[index] = {...opt, unit: value?.value};
    }
    onChange(newList);
  }

  return (
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="columns" >
          {(provided: DroppableProvided) => (
            <ul
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={`droppable-container ${isDragging ? 'isDragging' : ''}`}
            >
              {list?.map((column, index) => {
                return (
                  <Draggable
                    key={column?.value}
                    draggableId={column?.value || `draggableId-${index}`}
                    index={index}
                  >
                    {(_provided, snapshot) => {
                      return (
                      <li
                        ref={_provided.innerRef}
                        {..._provided.draggableProps}
                        {..._provided.dragHandleProps}
                        style={getStyles(_provided.draggableProps.style, snapshot)}
                      >
                        <div className="dropdownContainer">
                          <span className="draggable-icon">
                            <DraggableIndicatorIcon />
                          </span>
                          <div className="draggable">
                            <Field 
                              autocomplete={options?.filter(d => !list.find(item => d?.label === item?.label)).map(x => x?.label ?? "")}
                              value={column?.label}
                              onChange={value => onSelectValue(value, index)}
                              onBlur={e => {
                                const option = options.find(o => o?.label.toLowerCase().trim() === e.target.value.toLowerCase().trim());
                                if (option) {
                                  onSelectValue(option.label, index);
                                } else if (e.target.value.trim() !== e.target.value) {
                                  onSelectValue(e.target.value.trim(), index);
                                }
                              }}
                            />
                          </div>
                          {units && (
                            <div style={{marginLeft: "12px", minWidth: "10em"}}>
                              <Selector 
                                options={units}
                                isCreatable
                                value={column?.unit ? {value:column?.unit, label: column?.unit } : {value: "kr", label: "kr"}}
                                onChange={value => onUnitChange(column, index, value)}
                                formatCreateLabel={(userInput) => `Skapa \"${userInput}\"`}
                              ></Selector>
                            </div>
                          )}
                          {column?.required !== undefined && (
                            <label
                              style={{ 
                                color: 'var(--gray-700)', fontSize: '14px', marginLeft: "10px", letterSpacing: "initial",
                                display: "flex", flexDirection: "row", alignItems: "center",
                              }}
                            >
                              <StyledCheckbox
                                onChange={() => onRequiredChange(column, index, !column?.required)}
                                checked={column?.required}
                              />
                              Obligatoriskt
                            </label>
                          )}
                          <span
                            className="remove-icon"
                            onClick={() => onRemoveOption(index)}
                          >
                            <TrashIcon />
                          </span>
                        </div>
                      </li>
                    )}}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
    </DragDropContext>
  );
}

export default DraggableDropdown;
