import React, { useEffect, useState } from 'react';
import AppProjectSettings from '../app-project-settings/app-project-settings';
import AppProjectPurchaseList from '../app-project-purchase-list/app-project-purchase-list';
import ProjectTabsAndActions from './project-tabs-and-actions'
import { areEqual } from '../../util/index';
import BackButton from '../../components/shared/BackButton/BackButton';
import './page-projects-single.css';
import BtGrid from '../bt-grid/bt-grid';
import BtRow from '../bt-row/bt-row';
import BtColumn from '../bt-column/bt-column';
import AppLayout from '../../components/layout/app-layout/app-layout';
import appState from '../../state/AppStateContainer';

let scrollPos = 0;
let scrollScope = null;
let scrollTimer = undefined;

const useRestoreAndRememberScrollPosition = (key) => {
  useEffect(() => {
    const el = document.getElementsByClassName("body-wrapper")?.[0];
    if (el) {
      if (scrollScope == key) {
        el.scrollTo(0, scrollPos);
      } else {
        scrollScope = key;
        scrollPos = el.scrollTop;
      }
      const onScroll = event => {
        if (scrollTimer) clearTimeout(scrollTimer);
        scrollTimer = setTimeout(() => {
          const el = document.getElementsByClassName("body-wrapper")?.[0];
          if (el) {
            scrollPos = el.scrollTop;
            scrollScope = key;
            //console.log({scrollPos, scrollScope})  
          }  
        }, 50);
      };
      el.addEventListener("scroll", onScroll);
      return () => {
        const el = document.getElementsByClassName("body-wrapper")?.[0];
        if (el) {
          el.removeEventListener("scroll", onScroll);
        }
        clearTimeout(scrollTimer);
        scrollTimer = undefined;
      };
    }
  }, []);
}

function PageProjectsSingle(props) {
  const {
    lastStep = false,
    currentProject,
    setCurrentProject,
    allowEditing,
    isWinnerEditable,
    contractUploadEvent,
    step,
    isContractSigning,
    editProjectBtnCallback,
    onStepChange = () => {},
    onNewArea = () => {},
    onErfa = () => {},
    onToggleProjectStatus = () => {},
    selectedArea,
    look, setLook,
    history
  } = props;

  const [searchValue, setSearchValue] = useState('');
  useRestoreAndRememberScrollPosition(currentProject._id);

  function handleEditProjectBtn() {
    if (editProjectBtnCallback) editProjectBtnCallback(currentProject);
  }

  useEffect(() => {
    if (!appState.getListViewBetaPreference() && step > 5) {
      onStepChange(1);
    }
  }, [appState.getListViewBetaPreference()])

  return (
    <AppLayout 
      title={
        <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
          {selectedArea ? (
            <>
              <BackButton 
                to={`/projects/${currentProject.slug}`}
                path={[currentProject.name, currentProject.area.find(a => a.areaId.slug == selectedArea).areaId.name]}
              />
            </>
          ) : (
            <>
              <BackButton 
                to="/projects"
                path={[currentProject.name]}
              />
            </>
          )}
        </div>
      }
  >
    <div className={`page-projects-single`}>
      <div>
        <BtGrid>
          <BtRow>
            <BtColumn className="column-content">
              {!selectedArea && (
                <ProjectTabsAndActions
                  currentProject={currentProject}
                  allowEditing={allowEditing}
                  isWinnerEditable={isWinnerEditable}
                  contractUploadEvent={contractUploadEvent}
                  isContractSigning={isContractSigning}
                  step={step}
                  onStepChange={onStepChange}
                  selected={step} 
                  onChange={(e) => {
                    onStepChange(e);
                    setSearchValue('');
                  }} 
                  onToggleProjectStatus={onToggleProjectStatus}
                  onErfa={onErfa}
                  onNewArea={onNewArea}
                  history={history}
                />
              )}

              {(step < 5 || step === 6) && (
                <AppProjectPurchaseList
                  currentProject={currentProject}
                  setCurrentProject={setCurrentProject}
                  allowEditing={allowEditing}
                  isWinnerEditable={isWinnerEditable}
                  contractUploadEvent={contractUploadEvent}
                  isContractSigning={isContractSigning}
                  step={step}
                  onStepChange={onStepChange}
                  onToggleProjectStatus={onToggleProjectStatus}
                  refreshCurrentProject={props.refreshCurrentProject}
                  users={props.users}
                  addSupplier={props.addSupplier}
                  createNewVersion={props.createNewVersion}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  selectedArea={selectedArea}
                />
              )}
              {step == 5 && (
                <AppProjectSettings
                  editProjectBtnCallback={handleEditProjectBtn}
                  currentProject={currentProject}
                  users={props.users}
                />
              )}
            </BtColumn>
          </BtRow>
        </BtGrid>
      </div>
    </div>
    </AppLayout>
  );
}

export default React.memo(PageProjectsSingle, areEqual);
