import axios from "axios";

type SalesOrgApplication = {
  organizationNumber: string
  adminEmail: string
  recipients: {
    email: string
    name: string
    personalNumber: string
  }[],
}

class ServiceSalesOrgApplication {
  async listPendingSignedApplications() {
    const res = await axios.get(`/api/salesOrgApplications`, { params: { pending: true, signed: true } });
    return res.data;
  }
  async approveApplication(_id: string) {
    const res = await axios.post(`/api/salesOrgApplications/${_id}/approval`);
    return res.data;
  }
  async rejectApplication(_id: string) {
    const res = await axios.post(`/api/salesOrgApplications/${_id}/rejection`);
    return res.data;    
  }

  async createSalesOrgApplication(data: SalesOrgApplication) {
    const res = await axios.post(`/api/salesOrgApplications`, data);
    return res.data;
  }

  async getSalesOrgApplication(code: string) {
    const res = await axios.get(`/api/salesOrgApplications/${code}`);
    return res.data;
  }

  async checkOrgNumber(orgNumber: string) {
    const res = await axios.get(`/api/salesOrgApplications/checkOrgNumber/${orgNumber}`);
    return res.data;
  }
}

export const serviceSalesOrgApplication = new ServiceSalesOrgApplication();