import React from 'react';
import Avatar from 'react-avatar';
import { areEqual } from '../../util';

type Props = {
  className?: string;
  alt? : string; 
  src? : string;
  size? : string;
  style? : any;
  color? : string;
};


const CustomAvatar = React.memo(({ className, alt = "", src = "", size = '30px', style, color } : Props) => {
  return <Avatar name={alt} src={src} round size={size} className={`${className} ${src ? 'generic-avatar-fit' : ''}`} style={style} color={color}/>;
}, areEqual);

export default CustomAvatar;
