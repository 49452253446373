import { FC, useState, useEffect } from "react";
import { humanizeBytes } from "../../../util";
import { ReactComponent as CircleXIconFilled } from '../../../assets/svg/circleXIconFilled.svg';
import styles from './ImagePreview.module.scss';
import _ from "lodash";
import { FileScope } from "../../../util/appEnum";
import { ScopeOptions } from "../../../models/global.model";

const MAX_IMAGE_SIZE = 100000; // 100 kB

export const getImageUrl = (
    blobPath: string,
    orgSlug: string,
    scope: FileScope,
    scopeOptions?: ScopeOptions
  ) => {
    return blobPath && orgSlug && scope && `/api/organizations/${orgSlug}/util/image/${scope}?key=${encodeURIComponent(blobPath.indexOf(".") >= 0 ? blobPath.split(".").slice(0, -1).join(".") : blobPath)}&${Object.entries(scopeOptions || {}).map(([key, value]) => `${key}=${value?.toString()}`).join('&') || ''}`
  }

type Props = {
  onRemove?: () => void,
  image?: File,
  fileId?: string,
  scope?: FileScope,
  orgSlug?: string,
  scopeOptions?: ScopeOptions,
  width?: string | number,
}

const ImagePreview: FC<Props> = ({ onRemove, image, fileId, scope, orgSlug, scopeOptions, width }) => {
  const [imagePreviewInfo, setImagePreviewInfo] = useState<{uri: string | null, width?: number, height?: number}>();

  useEffect(() => {
    if (!fileId && image instanceof File) {
      const reader = new FileReader();
      reader.onload = function(ev){
        const img = new Image();
        img.onload = () => {
          if (ev.target?.result) {
            setImagePreviewInfo({uri: ev.target.result as string, width: img.naturalWidth, height: img.naturalHeight});
          }
        }
        img.src = ev.target?.result as string;
      };
      reader.readAsDataURL(image);
    } else {
      setImagePreviewInfo(undefined);
    }
  }, [image])

  return (fileId || imagePreviewInfo?.uri) ? (
    <div className={styles.container}>
      <div className={styles.imagePreview}>
        <CircleXIconFilled className='removeOrgLogoIcon' onClick={onRemove} />
        <img src={fileId && scope && orgSlug ? getImageUrl(fileId, orgSlug, scope, scopeOptions) : (imagePreviewInfo?.uri || '')} style={{ width: width ?? '300px' }}/>
      </div>
      {imagePreviewInfo && image?.size && (() => {
        const size = humanizeBytes(image?.size);
        const maxImageSize = humanizeBytes(MAX_IMAGE_SIZE);
        return (
          <div className={styles.previewInfo}>
            <p>Filstorlek: {size} {`(${imagePreviewInfo.width}x${imagePreviewInfo.height} pixlar)`}</p>
            <p>{image?.size > MAX_IMAGE_SIZE ? `Maximal rekommenderad filstorlek: ${maxImageSize}` : ''}</p>
          </div>
        )
      })()}
    </div>
  ) : null
}

export default ImagePreview;
