import { FC, useEffect, useState } from "react";
import AppLayout from "../../components/layout/app-layout/app-layout";
import Tabs from "../../components/shared/Tabs";
import Field from "../../components/shared/Field/Field";
import Card from "../../components/shared/Card/card";
import styles from "./page-supplier-presentation.module.scss";
import BtButton from "../bt-button/bt-button";
import ServiceSales, { Contact, OtherEmailAdress } from "../../services/service-sales";
import Selector, { SelectorType } from "../../components/shared/Selector/Selector";
import { MultiValue } from "react-select";
import { NotificationManager } from "react-notifications";
import { Loading } from "../../components/shared";
import LogoForm from "../app-add-supplier/logoForm";
import { FileScope } from "../../util/appEnum";
import PlaceSelector from "./PlaceSelector";

import { ReactComponent as ForwardIcon } from '../../assets/svg/forward.svg';
import { ReactComponent as AddUserIcon } from '../../assets/svg/add-user.svg';
import { ReactComponent as RemoveUserIcon } from '../../assets/svg/remove-user.svg';
import { ReactComponent as CommentIcon } from '../../assets/svg/comment.svg';
import { ReactComponent as SoderbergpartnersLogo } from '../../assets/svg/soderbergpartners.svg';
import { ReactComponent as AccuratorLogo } from '../../assets/svg/logo.svg';
import { ReactComponent as ShoppingCartIcon } from '../../assets/svg/shopping_cart.svg';
import Modal from "../../components/shared/Modal/Modal";
import FileCommentDate from "../app-add-supplier/file-comment-date";
import TooltipContainer from "../../components/shared/TooltipContainer/TooltipContainer";
import { logInsuranceClick } from "../../services/service-logs";
import serviceSales from "../../services/service-sales";
import appState from "../../state/AppStateContainer";
import { isEmpty } from "lodash";
import FileUpload, { ExistingFile, NewFile } from "../../components/shared/FileUpload/FileUpload";

type Props = {

};

const newId = () => "new"+Date.now();

enum FieldName {
  name = "name",
  email = "email",
  phone = "phone",
}

const PageSupplierPresentation : FC<Props> = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [name, setName] = useState("");
  const [orgNr, setOrgNr] = useState("");
  const [logo, setLogo] = useState<{ file: string, name: string }>();
  const [newLogo, setNewLogo] = useState<File>();
  const [presentation, setPresentation] = useState("");
  const [address, setAddress] = useState("");
  const [website, setWebsite] = useState("");
  const [areas, setAreas] = useState<SelectorType[]>([]);
  const [places, setPlaces] = useState<string[]>([]);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [otherEmailAddresses, setOtherEmailAddresses] = useState<OtherEmailAdress[]>([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  
  const [showRedirect, setShowRedirect] = useState(false);
  const [redirectEmail, setRedirectEmail] = useState("");
  const [redirectMessage, setRedirectMessage] = useState("");
  const [redirectValue, setRedirectValue] = useState<SelectorType>(null);
  
  const [showMessage, setShowMessage] = useState(false);
  const [messageEmail, setMessageEmail] = useState("");
  const [messageValue, setMessageValue] = useState("");

  const [areaOptions, setAreaOptions] = useState<SelectorType[]>([]);

  const [liabilityInsuranceIsEditing, setLiabilityInsuranceIsEditing] = useState(false);
  const [liabilityInsurance, setLiabilityInsurance] = useState<{file?: {fileName: string, file: string, mimeType: string}, comment: string, validUntilDate?: Date}>();
  const [newLiabilityInsurance, setNewLiabilityInsurance] = useState<File>();
  const [existingFiles, setExistingFiles] = useState<ExistingFile[]>([]);
  const [newFiles, setNewFiles] = useState<NewFile[]>([]);
  const [errorMessages, setErrorMessages] = useState<{[key: string]: {[x in FieldName]: string}}>({});

  useEffect(() => {
    (async () => {
      setLoading(true);
      const [supplierProfile, areaOptions] = await ServiceSales.fetchSupplierProfile();
      setName(supplierProfile.name);
      if (supplierProfile.orgNr) setOrgNr(supplierProfile.orgNr);
      if (supplierProfile.logo) setLogo(supplierProfile.logo);
      setPresentation(supplierProfile.presentation);
      setAddress(supplierProfile.address);
      setWebsite(supplierProfile.website);
      setAreas(supplierProfile.areas.map(area => ({ label: area.name, value: area._id })));
      setPlaces(supplierProfile.places);
      setContacts(supplierProfile.contacts);
      setOtherEmailAddresses(supplierProfile.otherEmailAddresses);
      setAreaOptions(areaOptions.map(a => ({label: a.name, value: a._id})));
      setLiabilityInsurance(supplierProfile.liabilityInsurance);
      setExistingFiles(supplierProfile.existingFiles);
      setLoading(false);
    })();
  }, []);

  const onSave = async () => {
    setSaving(true);
    const convertedAreas = areas.map(a => ({_id: a?.value ?? "", name: a?.label ?? ""}));
    try {
      const response = await ServiceSales.saveSupplierProfile({
        name, logo, newLogo, presentation, address, website, areas: convertedAreas, places, contacts, otherEmailAddresses, 
        liabilityInsurance, newLiabilityInsurance, existingFiles, newFiles
      });
      if (response.errorMessages) {
        setErrorMessages(response.errorMessages);
        setSaving(false);
        return;
      }
      setLogo(response.logo);
      setNewLogo(undefined);
      setLiabilityInsurance(response.liabilityInsurance);
      setNewLiabilityInsurance(undefined);
      setLiabilityInsuranceIsEditing(false);
      setContacts(response.contacts);
      setOtherEmailAddresses(response.otherEmailAddresses);
      setExistingFiles(response.existingFiles);
      setNewFiles([]);
      setErrorMessages({});
      const supplierProfileHasBeenUpdated = await serviceSales.hasProfileBeenUpdated();
      appState.setSupplierProfileHasBeenUpdated(supplierProfileHasBeenUpdated);
      NotificationManager.success("Sparat");
    } catch (e) {
      NotificationManager.error((e as Error).toString(), "Fel");
    }
    setSaving(false);
  }

  const onChangeContact = (id: string, field: keyof Contact, value: string) => {
    const index = contacts.findIndex(contact => contact._id == id);
    if (index != -1) {
      contacts[index][field] = value;
    }
    setContacts([...contacts]);
  }

  const onDeleteContact = (id: string) => {
    setContacts(contacts.filter(contact => contact._id != id));
  }

  const renderContacts = (contacts: Contact[]) => {
    return (
      <div className={styles.contacts}>
        <span>De kontaktpersoner ni lägger till kommer synas som förslag och visas som verifierade av leverantören.
          Ni kan också se vilka andra e-postadresser som finns kopplade till ert organisationnummer.
          Om det behövs kan ni kommentera, föreslå någon annan, eller lägga till den som en föreslagen/verifierad kontaktperson.</span>
        {contacts.map(contact => (
          <div className={styles.contact} key={contact._id}>
            <div className={[styles.column, styles.grow].join(" ")}>
              <div className={styles.row}>
                <div className={[styles.column, styles.grow].join(" ")}>
                  <Field
                    value={contact.name}
                    onChange={value => onChangeContact(contact._id, "name", value)} 
                    placeholder="Namn *"
                    required
                    errorMessage={errorMessages?.[contact._id]?.name}
                  />
                </div>
                <div className={[styles.column, styles.grow].join(" ")}>
                  <Field 
                    value={contact.email} 
                    onChange={value => onChangeContact(contact._id, "email", value)} 
                    placeholder="E-post *"
                    required
                    errorMessage={errorMessages?.[contact._id]?.email}
                  />
                </div>
              </div>              
              <div className={styles.row}>
                <div className={[styles.column, styles.grow].join(" ")}>
                  <Field 
                    value={contact.title} 
                    onChange={value => onChangeContact(contact._id, "title", value)} 
                    style={{fontWeight:400}}
                    placeholder="Titel"
                  />                  
                </div>
                <div className={[styles.column, styles.grow].join(" ")}>
                  <Field 
                    value={contact.phone} 
                    onChange={value => onChangeContact(contact._id, "phone", value)} 
                    style={{fontWeight:400}}
                    placeholder="Telefon"
                    errorMessage={errorMessages?.[contact._id]?.phone}
                  />
                </div>
              </div>
            </div>
            <div className={styles.column}>
              <BtButton color="white" size="xxs" onClick={() => onDeleteContact(contact._id)}>
                <span style={{color: "var(--gray-500)"}}><RemoveUserIcon /></span>
              </BtButton>
            </div>
          </div>
        ))}
        <div key="newContactButton">
          <BtButton color="white" size="xxs" leftIcon={<AddUserIcon/>} onClick={() => {
            setContacts([...contacts, {_id: newId(), name:"", email: "", title:"", phone:""}])
          }}>
            Lägg till kontaktperson
          </BtButton>
        </div>
      </div>
    );
  }

  const renderOtherEmailAddresses = (otherEmailAddresses : OtherEmailAdress[]) => {
    const onRedirect = (email: string, comment: string, redirectToId: string) => {
      setRedirectEmail(email);
      setRedirectMessage(comment);
      const contact = contacts.find(contact => contact._id == redirectToId);
      if (contact) {
        setRedirectValue({label: `${contact.name} (${contact.email})`, value: contact._id});
      }
      setShowRedirect(true);
    }
    const onMessage = (email: string, comment: string) => {
      setMessageEmail(email);
      setMessageValue(comment);
      setShowMessage(true);
    }
    const onAddAsContact = (email: string) => {
      setContacts([...contacts, { _id: newId(), name: "", email, phone: "", title: "" }]);
    }
    const onSaveOtherEmail = (email: string, comment: string, redirectToId?: string) => {
      const otherEmail = otherEmailAddresses.find(o => o.email == email);
      if (otherEmail) {
        otherEmail.comment = comment;
        otherEmail.redirectToId = redirectToId;
        setOtherEmailAddresses([...otherEmailAddresses]);
      }
      setShowMessage(false);
      setShowRedirect(false);
    }

    const filteredOtherEmailAddresses = otherEmailAddresses
      .filter(address => !contacts.find(contact => contact.email.trim().toLowerCase() == address.email.trim().toLowerCase()));
    return (
      <div className={styles.otherEmails}>
        <span>Här visas vilka kontaktuppgifter våra användare har registrerat för ditt företag. 
        Du kan kontrollera att dessa uppgifter är korrekta och aktuella, samt föreslå nya kontakter om det behövs. 
        Om inget visas så betyder det antingen att våra användare har samma kontaktpersoner som du angett, 
        eller så är ditt organisationsnummer inte registrerat av våra användare i deras leverantörsregister.</span>

        {filteredOtherEmailAddresses.map(address => (
          <div className={styles.otherEmail} key={address.email}>
            <div className={styles.column}>
              <div className={styles.headline}>{address.email}</div>
              <TooltipContainer renderReferenceComponent={(className, ref) => <div className={`${styles.info} ${className}`} ref={ref}>Används i {address.count} leverantörsregister.</div>}>
                {address?.orgs?.length > 0 &&(
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    {address?.orgs?.map(org => <span key={org}>{org}</span>)}
                  </div>
                )}
              </TooltipContainer>
            </div>
            <div className={styles.buttons}>
              <TooltipContainer renderReferenceComponent={(className, ref) => (
                <div className={className} ref={ref}>
                  <BtButton color="white" size="xxs" onClick={() => onMessage(address.email, address.comment ?? "")}>
                    <span style={{color: (address.comment && !address.redirectToId) ? "var(--cyan-500)" : "var(--gray-500)"}}><CommentIcon /></span>
                  </BtButton>
                </div>
              )}>
                Kommentera
              </TooltipContainer>
              <TooltipContainer renderReferenceComponent={(className, ref) => (
                <div className={className} ref={ref}>
                  <BtButton color="white" size="xxs" onClick={() => onRedirect(address.email, address.comment ?? "", address.redirectToId ?? "")}>
                    <span style={{color: address.redirectToId ? "var(--cyan-500)" : "var(--gray-500)"}}><ForwardIcon /></span>
                  </BtButton>
                </div>
              )}>
                Hänvisa till annan kontaktperson
              </TooltipContainer>
              <TooltipContainer renderReferenceComponent={(className, ref) => (
                <div className={className} ref={ref}>
                  <BtButton color="white" size="xxs" onClick={() => onAddAsContact(address.email)} disabled={!!contacts.find(contact => contact.email.trim().toLowerCase() == address.email.trim().toLowerCase())}>
                    <span style={{color: "var(--gray-500)"}}><AddUserIcon /></span>
                  </BtButton>
                </div>
              )}>
                Lägg till som kontaktperson
              </TooltipContainer>
            </div>
          </div>
        ))}
        <Modal show={showRedirect} setShow={setShowRedirect} title={"Hänvisning"} buttonInfo={{label: "Spara", action: () => onSaveOtherEmail(redirectEmail, redirectMessage, redirectValue?.value)}}>
          <div className={styles.modal}>
            <p>Hänvisa de som använder e-postadressen {redirectEmail} till en annan kontaktperson. Visas tillsammans med kontaktpersonen i inköparens leverantörsregister.</p>
            <Field label="Meddelande" value={redirectMessage} onChange={setRedirectMessage}></Field>
            <Selector options={contacts.map(contact => ({ label: `${contact.name} (${contact.email})`, value: contact._id }))} label="Kontaktperson" value={redirectValue} onChange={setRedirectValue}></Selector>
          </div>
        </Modal>

        <Modal show={showMessage} setShow={setShowMessage} title={"Meddelande"} buttonInfo={{label: "Spara", action: () => onSaveOtherEmail(messageEmail, messageValue)}}>
          <div className={styles.modal}>
            <p>Skriv ett meddelande till de som använder {messageEmail}. Visas tillsammans med kontaktpersonen i inköparens leverantörsregister.</p>
            <Field label="Meddelande" value={messageValue} onChange={setMessageValue}></Field>
          </div>
        </Modal>

      </div>
    );
  }  

  return (    
    <AppLayout title="Leverantörspresentation">
      <div style={{margin: "32px"}}>
        <div className="tab-container">
          <Tabs
            tabs={["Leverantörspresentation"]}
            selected={selectedTab}
            onChange={tab => { 
              setSelectedTab(tab); 
            }}
          />
          <div className="tab-actions">
          </div>
        </div>
        <Card gap={24} className={styles.card}>
          {loading ? (
            <div style={{minHeight: "500px", display: "flex", justifyContent: "center", alignItems: "center"}}>
              <Loading type="inline"></Loading>
            </div>
          ) : (<>
              <span>Ta kontroll över hur ert företag presenteras i inköparnas leverantörsdatabas. Uppgifterna nedan kombineras med inköparnas egna uppgifter och visas då de väljer leverantörer till sina inköp.</span>
              <div style={{display:"flex", gap: "32px"}}>
                <Field label="Företagsnamn" value={name} onChange={setName}/>
                <Field disabled label="Organisationsnummer" value={orgNr} onChange={setOrgNr} style={{backgroundColor: "var(--gray-50)", borderRadius: "6px"}}/>
              </div>
              
              <LogoForm
                logo={logo}
                scope={FileScope.SupplierProfileLogo}
                scopeOptions={{  }}
                image={newLogo}
                onChange={file => {
                  if (file) {
                    setNewLogo(file);
                    setLogo(undefined);
                  } else {
                    setNewLogo(undefined);
                    setLogo(undefined);
                  }
                }}
              ></LogoForm>
              <Field label="Presentation" multiline value={presentation} onChange={setPresentation} />
              <Field label="Adress" multiline value={address} onChange={setAddress} />
              <Field label="Webbplats" value={website} onChange={setWebsite} />
              <Selector<SelectorType, true> isMulti isSearchable required options={areaOptions} label="Yrkeskategorier" value={areas} onChange={(values: MultiValue<SelectorType>) => setAreas([...values])} />
              <PlaceSelector places={places} onChange={setPlaces} required />
              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                {!liabilityInsuranceIsEditing && !liabilityInsurance && <span style={{ color: 'var(--gray-700)', fontWeight: 500 }}>Ladda upp befintlig försäkring</span>}
                <FileCommentDate 
                  item={liabilityInsurance}
                  isEditing={liabilityInsuranceIsEditing} 
                  onRemove={() => {
                    setLiabilityInsurance(undefined);
                    setNewLiabilityInsurance(undefined);
                    setLiabilityInsuranceIsEditing(false);
                  }}
                  onAdd={() => {
                    setLiabilityInsuranceIsEditing(true);
                  }}
                  namePrefix={"liabilityInsurance"} 
                  addButtonText={"Lägg till ansvarsförsäkring"} 
                  headline={"Ansvarsförsäkring"} 
                  handleChange={(field: string, value: string|Date|File, _: boolean) : void => {
                    if (field.endsWith("File")) {
                      setLiabilityInsurance({...liabilityInsurance, comment: liabilityInsurance?.comment ?? ""});
                      setNewLiabilityInsurance(value as File);
                    } else if (field.endsWith("Comment")) {
                      setLiabilityInsurance({...liabilityInsurance, comment: value as string});
                    } else if (field.endsWith("Date")) {
                      setLiabilityInsurance({...liabilityInsurance,  comment: liabilityInsurance?.comment ?? "", validUntilDate: value as Date});
                    }
                  }} 
                  newFile={newLiabilityInsurance} 
                  scope={FileScope.SupplierProfileFile}
                  scopeOptions={{ organizationNumber: orgNr }}
                />
                <div className={styles.insuranceContainer}>
                  <div className={styles.logoContainer}>
                    <span style={{ fontWeight: 500 }}>Är din försäkring aktuell?</span>
                    <div className={styles.logos}>
                      <AccuratorLogo />
                      <SoderbergpartnersLogo />
                    </div>
                  </div>
                  <span style={{ fontWeight: 500 }}>Entreprenadförsäkringar i samarbete med Söderberg &amp; Partners</span>
                  <span style={{ fontSize: '12px', color: 'var(--gray-500)', width: 'clamp(200px, 60%, 800px)', marginBottom: '8px' }}>Genom vårt samarbete med Söderberg &amp; Partners kan vi nu erbjuda våra kunder exklusiva entreprenadförsäkringar till konkurrenskraftiga priser. Dessa försäkringar är skräddarsydda för byggbranschen och ger dig det skydd du behöver för att trygga dina projekt. Spara 10% på din entreprenadförsäkring idag!</span>
                  <BtButton
                    size="xxs"
                    color="white"
                    leftIcon={<ShoppingCartIcon />}
                    style={{ width: 'fit-content' }}
                    onClick={() => {
                      logInsuranceClick();
                      window.open('https://www.soderbergpartners.se/forsakringar/offert-formular/', '_blank');
                    }}
                  >
                    Teckna ny ansvarsförsäkring
                  </BtButton>
                </div>
              </div>
              <div className={styles.row} style={{borderTop: "1px solid var(--gray-300)", paddingTop: "24px"}}>
                <div className={[styles.column, styles.label].join(" ")}>
                  Övriga filer
                </div>
                <div className={[styles.column, styles.grow].join(" ")}>
                  <FileUpload 
                    existingFiles={existingFiles}
                    setExistingFiles={setExistingFiles}
                    newFiles={newFiles}
                    setNewFiles={setNewFiles}
                    label="Lägg till filer"
                    hasRemoveAllButton={true}
                    scope={FileScope.SupplierProfileFile}
                    scopeOptions={{ organizationNumber: orgNr }}
                  />
                </div>
              </div>
              <div className={styles.row} style={{borderTop: "1px solid var(--gray-300)", paddingTop: "24px"}}>
                <div className={[styles.column, styles.label].join(" ")}>
                  Kontaktpersoner
                </div>
                <div className={[styles.column, styles.grow].join(" ")}>
                  {renderContacts(contacts)}
                </div>
              </div>
              <div className={styles.row} style={{borderTop: "1px solid var(--gray-300)", paddingTop: "24px"}}>
                <div className={[styles.column, styles.label].join(" ")}>
                  Andra e-postadresser
                </div>
                <div className={[styles.column, styles.grow].join(" ")}>
                  {renderOtherEmailAddresses(otherEmailAddresses)}
                </div>
              </div>
              <div style={{borderTop: "1px solid var(--gray-300)", paddingTop: "24px", color: "var(--gray-700)", fontWeight: 500, fontSize: "14px" }}>
                <TooltipContainer renderReferenceComponent={(className, ref) => (
                  <div
                    style={{ width: 'fit-content' }}
                    ref={ref}
                    className={className}
                  >
                    <BtButton onClick={onSave} loaderShow={saving} disabled={isEmpty(areas) || isEmpty(places)}>Spara</BtButton>
                  </div>
                )}>
                  {(isEmpty(areas) || isEmpty(places)) && [isEmpty(areas) ? 'Yrkeskategorier' : '', isEmpty(places) ? 'Platser' : ''].filter(x => x).join(' och ') + ' är obligatoriska'}
                </TooltipContainer>
              </div>          
            </>
          )}
        </Card>
      </div>
    </AppLayout>
  );
}

export default PageSupplierPresentation;