import React from 'react';
import PropTypes from "prop-types";
import BtGrid from '../bt-grid/bt-grid';
import BtRow from '../bt-row/bt-row';
import BtColumn from '../bt-column/bt-column';

export default class BrowserSupport extends React.Component {
    
    static contextTypes = {
        router: PropTypes.object
    }

    constructor(props, context) {
        super(props, context)
        this.state = {
        }
    }
    componentWillLoad() {
        
    }

    render() {
        return (
            <div className="page-browser-support">
                <BtGrid>
                    <BtRow>
                        <BtColumn>
                            <h2 className="title">Browser Not Supported</h2>
                            <p className="description">You are seeing this page, because you current browser doesn't support this website</p>
                            <div className="browsers-list">
                                <a target="_blank" href="https://www.google.com/chrome/"><img src="/assets/browsers/chrome.svg" alt="Error"/> <span>Google Chrome</span></a>
                                <a target="_blank" href="https://www.mozilla.org/en-US/firefox/new/"><img src="/assets/browsers/firefox.svg" alt="Error"/> <span>Mozilla Firefox</span></a>
                                <a target="_blank" href="https://www.apple.com/lae/safari/"><img src="/assets/browsers/safari.png" alt="Error"/><span>Safari</span></a>
                                <a target="_blank" href="https://www.microsoft.com/en-us/windows/microsoft-edge" className="brw-ie"><img src="/assets/browsers/edge.svg" alt="Error"/><span>MS Edge</span></a>
                            </div>
                        </BtColumn>
                    </BtRow>
                </BtGrid>
            </div >
        );
    }
}
