import { FC, ReactNode, useEffect, useState } from 'react';
import TrashIcon from '../../../../components/svgs/trashIcon';
import BtButton from '../../../bt-button/bt-button';
import styles from './SupplierMemberContainer.module.scss';
import { ReactComponent as EditIcon } from '../../../../assets/svg/editIcon.svg';
import { ReactComponent as UndoIcon } from '../../../../assets/svg/undoIcon.svg';
import { Member } from '../ContactInfoForm';
import Field from '../../../../components/shared/Field/Field';
import appState from '../../../../state/AppStateContainer';

type Props = {
  onRemove?: (value: boolean) => void,
  onChange: (values: Partial<Member>) => void,
  member: Member,
  errors: Omit<Member, '_id'>,
  editMode: boolean,
  onEdit: (id: string) => void,
  isSupplierBlocked?: boolean,
  profileInfo?: ReactNode,
}

const SupplierMemberContainer: FC<Props> = ({ onChange, onRemove, member, errors, editMode, onEdit, isSupplierBlocked, profileInfo }) => {
  const { name, email, title, phone, _id } = member;
  const [isRemoved, setIsRemoved] = useState(false);
  const isMemberTheLoggedInUser = _id === appState.getUserId();
  useEffect(() => {
    if (Object.values(errors || {}).some(value => value)) onEdit(_id);
  }, [errors]);

  return (
    <div style={{display: "flex", alignItems: "center", width: "100%"}}>
      {profileInfo}
      <div key="container" className={`${styles.container} ${isRemoved ? styles.isRemoved : ''} ${isSupplierBlocked && !editMode ? styles.blockedSupplier : ''}`}>
        <div className={styles.form}>
          <Field
            key="name"
            value={name}
            onChange={value => onChange({ name: value })}
            placeholder='Namn*'
            errorMessage={errors?.name}
            isEditMode={editMode}
            className={styles.boldText}
            style={{
              gridRow: !title ? '1 / span 2' : '1',
              alignSelf: !title ? 'center' : ''
            }}
          />
          <Field
            key="email"
            value={email}
            onChange={value => onChange({ email: value })}
            placeholder='E-post*'
            errorMessage={errors?.email}
            isEditMode={editMode}
            className={styles.lightText}
            style={{
              gridRow: !phone ? '1 / span 2' : '1',
              alignSelf: !phone ? 'center' : ''
            }}
          />
          {
            (title || editMode) && (
              <Field
                key="title"
                value={title}
                onChange={value => onChange({ title: value })}
                placeholder='Titel'
                errorMessage={errors?.title}
                isEditMode={editMode}
                className={styles.lightText}
              />
            )
          }
          {
            (phone || editMode) && (
              <Field
                key="phone"
                value={phone}
                onChange={value => onChange({ phone: value })}
                placeholder='Telefon'
                errorMessage={errors?.phone}
                isEditMode={editMode}
                className={styles.lightText}
              />
            )
          }
        </div>
        <div className={`${styles.buttonsDiv} ${editMode || isRemoved ? styles.hideButton : ''}`}>
          <BtButton
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.preventDefault();
              onEdit(_id)
            }}
            color='white'
            size='xxs'
            leftIcon={
              <EditIcon style={{ width: '14px', fill: 'var(--gray-700)' }} />
            }
          />
          <BtButton
            color='white'
            size='xxs'
            disabled={!onRemove}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.preventDefault();
              if (onRemove) {
                onRemove(!isRemoved);
              }
              setIsRemoved(!isRemoved);
            }}
            style={{ visibility: isMemberTheLoggedInUser ? 'hidden' : 'visible' }}
            leftIcon={
              isRemoved ? (
                <UndoIcon style={{ width: '14px', fill: 'var(--gray-700)' }} />
              ) : (
                <TrashIcon size='14px' color='var(--gray-700)' />
              )
            }
          />
        </div>
      </div>
    </div>
  );
}

export default SupplierMemberContainer;