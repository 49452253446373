import React from 'react';
import { areEqual } from '../../util';
import { SearchIcon } from '../svgs';

function SearchInput({ value, onChange, placeholder, className }) {
  return (
    <div className={`ar-column sp-input-col ${className}`}>
      <div className="bt-search">
        <div className="left">
          <SearchIcon size={18} />
        </div>

        <div className="right search--wrapper">
          <input name="search" placeholder={placeholder} value={value} onChange={onChange} />
        </div>
      </div>
    </div>
  );
}
export default React.memo(SearchInput,areEqual)