// import { Component, h, Prop, Element, Watch, Host } from '@stencil/core';
// import { RouterHistory, LocationSegments, injectHistory } from '@stencil/router';
// import { HTMLStencilElement } from '@stencil/router/dist/types/stencil.core';

import React from 'react';
import {withRouter} from 'react-router-dom'

//@withRouter
export class BtRoute extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            isActive: props.isActive
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.location !== nextProps.location) {
            this.handleHistory()
        }

        if (this.props.isActive != nextProps.isActive) {
            this.setState({isActive: nextProps.isActive});
        }
      }
    
    componentDidLoad() {
        // if(this.renderCallback) {
        //     this.renderCallback(this);
        // }
    }
    
    componentDidUpdate() {
        // if(this.renderCallback) {
        //     this.renderCallback(this);
        // }
    }

    handleHistory() {
        // var s = this.history.location.pathname.split('/').filter((el) => {
        //     return el != "";
        // });
        var state = false;
        let browserPath = this.props.location.pathname.replace(/\/+$/, "");
        let url = this.props.url.replace(/\/+$/, "");
        var hasAsterics = url.substr(-1);
        if(hasAsterics == '*') {
            url = url.substring(0, url.length - 1); 
            if(browserPath.indexOf(url) > -1 && browserPath != url) {
                state = true;
            }
        } else {
            if(browserPath.indexOf(url) > -1) {
                state = true;
            }
        }


        this.setState({isActive: state})
    }

    render() {
        let activeClass = this.props.activeClass || 'link-active';
        return (
            <div className={this.state.isActive ? activeClass : '' } 
                slot={this.props.slot} 
                url={this.props.url}>
                {this.props.children}
            </div>
        );
    }
}

export default withRouter(BtRoute);
//injectHistory(BtRoute);
