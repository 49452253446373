import React, { useMemo } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { areEqual } from '../../../util';
import './OverflowTip.css';

const OverflowTip = ({ content = [], overflowLength = 20 }) => {
  const getString = arr => {
    let textToShow = [];
    let hideCount = 0;

    let len = 0;

    for (const a of arr) {
      const s = (a.length <= overflowLength) ? a : a.substring(0, overflowLength - 3) + "...";
      if (len + s.length > overflowLength) {
        hideCount++;          
      } else {
        textToShow.push(s);
        len += s.length + 2; // 2 is the length of the ", " joiner
      } 
    }

    return [textToShow.join(", "), hideCount];
  };

  const [textToShow, hiddenTextCount] = useMemo(() => getString(content), [content]);


  return (
    <Tooltip title={content.join(", ")} interactive disableHoverListener={!hiddenTextCount}>
      <div className="overflowTip-container">
        {textToShow}
        {hiddenTextCount > 0 && (
          <div className="overflow-tip-hiddenCount">{`\t+${hiddenTextCount} fler`}</div>
        )}
      </div>
    </Tooltip>
  );
};

export default React.memo(OverflowTip, areEqual);
