import React from "react";

import "./bt-check.css";

export default class BtCheck extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: props.checked
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.checked != nextProps.checked) {
      this.setState({ checked: nextProps.checked });
    }
  }

  clickCallback(a, e) {
    let newValue = !this.state.checked;
    if (!this.props.disableAutoCheck && !this.props.disableClick) {
      this.setState({ checked: newValue });
    }
    if (!this.props.disableClick) {
      if (this.props.btCheckClick) this.props.btCheckClick(newValue, e);
    }
    if (this.props.extraData) {
      this.props.customEvent(newValue, JSON.parse(this.props.extraData));
    }
  }

  render() {
    let checked = this.state.checked;
    let style = this.props.style || {};
    return (
      <div
        style={style}
        extradata={this.props.extraData}
        id={this.props.id ? this.props.id : ""}
        className={`bt-check ${this.props.elementClassExtras} ${
          checked ? "checked" : ""
        }`}
        onClick={e => this.clickCallback(this, e)}
      >
        <label id={this.props.id ? this.props.id : ""}>
          {this.props.children}
        </label>
      </div>
    );
  }
}

// BtCheck.defaultProps = {
//     uid: null,
//     checked: null,
//     disableClick: false,
//     extraData: null,
//     btCheckClick: null
// }
