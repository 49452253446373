import { useEffect, useState, VFC } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { BidFormContentContainer } from '../StaticComponents';
import styles from './ContactInfoForm.module.scss';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SupplierMemberContainer from './SupplierMemberContainer/SupplierMemberContainer';
import BtButton from '../../bt-button/bt-button';
import { ServiceSupplier } from '../../../services/service-supplier';
import { NotificationManager } from "react-notifications";
import _ from 'lodash';
import File from '../../../components/File/File';
import { FileScope } from '../../../util/appEnum';

export type Member = {
  _id: string,
  name: string,
  email: string,
  title: string,
  phone: string
}

type Props = {
  projectData: {
    name: string,
    number: string,
  },
  supplierData: {
    _id: string,
    slug: string,
    name: string,
    website: string,
    organizationNumber: string,
    kommun: string,
    areaIds: {
      name: string
    }[],
    liabilityInsurance: {
      comment: string,
      file: {
        file: string,
        fileName: string,
      },
      validUntilDate: string
    }
  },
}

const ContactInfoForm: VFC<Props & RouteComponentProps> = ({ projectData, supplierData, match }) => {
  const currentPath = match.url;
  const {
    name: supplierName,
    website,
    organizationNumber,
    kommun,
    areaIds: areas,
    liabilityInsurance,
    _id: supplierId,
    slug: supplierSlug
  } = supplierData;

  const [members, setMembers] = useState<Member[]>([]);
  const [membersToRemove, setMembersToRemove] = useState<string[]>([]);
  const [newMembers, setNewMembers] = useState<Member[]>([]);
  const [fieldErrorMessages, setFieldErrorMessages] = useState<{[value: string]: Omit<Member, '_id'>}>({});
  const [loading, setLoading] = useState(false);
  const [shouldSave, setShouldSave] = useState(false);
  const [editModes, setEditModes] = useState<{[value: string]: boolean}>({}); 

  useEffect(() => {
    (async () => {
      if (supplierId) {
        const supMembers: Member[] = await ServiceSupplier.getSupplierMembers(supplierId);
        setMembers(supMembers.map(member => ({
          _id: member._id,
          name: member.name || '',
          email: member.email || '',
          title: member.title || '',
          phone: member.phone || ''
        })));
      }
    })();
  }, [supplierId])

  const renderFileDateAndComment = (
    item: Props['supplierData']['liabilityInsurance'],
    activeLabel: string,
    inactiveLabel: string,
    expiresLabel: string
  ) => {
    let active = false;
    let end;
    if (item.validUntilDate) {
      end = new Date(item.validUntilDate);
      end.setHours(23, 59, 59, 999);
      active = new Date() < end;
    }

    return <>
      <div className={styles.liabilityContainer}>
        {item.file &&
          <File
            file={item.file.file}
            fileName={item.file.fileName}
            scope={FileScope.SupplierFiles}
            scopeOptions={{
              supplierId: supplierData._id
            }}
          />
        }
        <div>
          {item.validUntilDate && (
            <>
              {active ? (
                <div className="collective-agreementFlag true">
                  <CheckIcon /> {activeLabel}
                </div>
              ) : (
                <div className="collective-agreementFlag false">
                  <CloseIcon /> {inactiveLabel}
                </div>
              )}
            </>
          )}
        </div>
        {end && 
          <span>{expiresLabel + " " + end.toLocaleDateString()}</span>
        }
      </div>
      <span>{item.comment}</span>
    </>
  }

  const saveChanges = async () => {
    setFieldErrorMessages({});
    setLoading(true);

    const membersToUpdate = members.filter(member => !membersToRemove.includes(member._id));

    try {
      await ServiceSupplier.updateSupplierMembers(supplierSlug, newMembers, membersToRemove, membersToUpdate);
      NotificationManager.success('Kontaktuppgifter sparade');
      const supMembers: Member[] = await ServiceSupplier.getSupplierMembers(supplierId);
      setMembers(supMembers.map(member => ({
        _id: member._id,
        name: member.name || '',
        email: member.email || '',
        title: member.title || '',
        phone: member.phone || ''
      })));
      setNewMembers([]);
      setMembersToRemove([]);
      setEditModes({});
      setShouldSave(false);
    } catch(p : any) {
      if (p.response.data.fieldErrorMessages) setFieldErrorMessages(p.response.data.fieldErrorMessages);
    } finally {
      setLoading(false);
    }
  }

  const renderSupplierMemberContainer = (member: Member, isNewMember: boolean) => {
    const setStateFunction = isNewMember ? setNewMembers : setMembers;
    const onEdit = (id: string) => setEditModes(prevState => ({...prevState, [id]: true}));
    return (
      <SupplierMemberContainer
        key={member._id}
        member={member}
        errors={fieldErrorMessages[member._id]}
        onChange={values => setStateFunction(oldState => {
          setShouldSave(true);
          // If has error messages reset them
          if (Object.values(fieldErrorMessages[member._id] || {}).some(error => error)) {
            setFieldErrorMessages(prevState => {
              const _temp = {...prevState};
              _temp[member._id] = {
                name: '',
                email: '',
                title: '',
                phone: ''
              };
              return _temp;
            })
          }
          const temp = [...oldState];
          const index = temp.findIndex(m => m._id == member._id);
          temp[index] = {...temp[index], ...values}
          return temp;
        })}
        onRemove={isRemoved => {
          setShouldSave(true);
          if (!isNewMember) {
            if (isRemoved) {
              setMembersToRemove(removedSupMembers => [...removedSupMembers, member._id]);
            } else {
              setMembersToRemove(removedSupMembers => removedSupMembers.filter(m => m != member._id));
            }
          } else {
            setStateFunction(oldState => oldState.filter(x => x._id !== member._id))
          }
        }}
        editMode={editModes[member._id] || isNewMember}
        onEdit={onEdit}
      />
    )
  }

  return (
    <BidFormContentContainer projectData={projectData} supplierData={{name: supplierName}} isSupplierView={true}>
      <div className={styles.container}>
        <p>Här kan ni som leverantör själva ändra, lägga till eller ta bort kontaktpersoner. För att ändra någon annan uppgift, <Link to={`${currentPath.replace('contactInfo', 'communication')}`}>skicka ett meddelande till beställaren.</Link></p>
        <div className={styles.content}>
          {supplierName && (
            <section className={styles.info}>
              <p>{supplierName}, <span>{organizationNumber}</span></p>
              <span>{website}</span>
            </section>
          )}
          {areas?.length > 0 && (
            <section className={styles.areas}>
              <p>Yrkesområden</p>
              <span>{areas?.map(area => area.name).join(', ')}</span>
            </section>
          )}
          {kommun && (
            <section className={`${styles.kommun} ${!areas?.length ? styles.spanAcross : null}`}>
              <p>Kommun</p>
              <span>{kommun}</span>
            </section>
          )}
          {!_.isEmpty(liabilityInsurance) && (
            <section className={styles.liability}>
              <p>Ansvarsförsäkring</p>
              {renderFileDateAndComment(liabilityInsurance, "Aktiv", "Utgången", "Giltig till")}
            </section>
          )}
          <section className={styles.members}>
            <p>Kontaktpersoner</p>
            {[...members, ...newMembers].map(member => {
              return renderSupplierMemberContainer(member, !!newMembers?.find(m => m._id == member._id))
            })}

            <div className={styles.addMemberContainer} onClick={() => setNewMembers(oldState => {
                const newMember = {
                  _id: `${Date.now()}`,
                  name: '',
                  email: '',
                  title: '',
                  phone: '',
                } as Member;
                
                return [...oldState, newMember];
              })
            }>
              <AddCircleIcon style={{ fill: 'var(--orange-500)' }} /> Lägg till kontaktperson
            </div>

            <div className={styles.buttonContainer}>
              <BtButton
                loaderShow={loading}
                onClick={saveChanges}
                disabled={!shouldSave}
              >
                Spara
              </BtButton>
            </div>
          </section>
        </div>
      </div>
    </BidFormContentContainer>
  );
}

export default withRouter(ContactInfoForm);