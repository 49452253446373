import React, { Component } from "react";
import { injectIntl } from "react-intl";
import ProjectTabsAndActions from './project-tabs-and-actions'
import BtButton from "../bt-button/bt-button"
import appState from '../../state/AppStateContainer';
import axios from "axios";
import BackButton from "../../components/shared/BackButton/BackButton";
import BtGrid from "../bt-grid/bt-grid";
import BtRow from "../bt-row/bt-row";
import BtColumn from "../bt-column/bt-column";
import AppLayout from "../../components/layout/app-layout/app-layout";
import HTMLParser from "../../components/shared/HTMLParser/HTMLParser";
import Editor from "../../components/shared/Editor/Editor";

const defaultStrategy = `Skriv din strategi här`;

export class ProjectStrategy extends Component {

  constructor(props)
  {
    super(props);
    
    this.state = {
      strategy: "",
      
    };
  }
  
  async handleSubmit(e) {
    let strategy = this.state.strategy;
    try {
      //const strategy = this.state.strategy;

      this.setState({ loaderShow: true });
      await this.updateStrategy(this.props.projectSlug, strategy);

      if (this.props.onStrategyUpdated) {
        this.props.onStrategyUpdated(this, strategy);
      }
      this.setState({ loaderShow: false });
    } catch (error) {
      console.error(error);
      this.setState({ loaderShow: false });
    }
  }
  
  async updateStrategy(projectSlug, strategy) {
    const response = await axios.put("/api/project/" + projectSlug + "/strategy", {
      strategy
    });
    return response.data;
  }

  componentDidMount() {
    var strategy = this.props.strategy;
    this.setState({
      strategy: this.props.strategy,
    });
  }
  
  UNSAFE_componentWillReceiveProps(nextProps) {

    var strategy = this.props.strategy;
    if (this.props.strategy != strategy ) {
      var strategy = strategy;
      
      this.setState({
        strategy: strategy,
        
      });
      this.updateEditorState(strategy)
    }
   
    

  }
  editProjectBtnCallback() {
    if (this.props.editProjectBtnCallback)
      this.props.editProjectBtnCallback(this.props.currentProject);
  }


  render() {
    function countAreasInStep(currentProject, step) {
      return currentProject.area.filter(a => (a.step || 1) == step).length;
    }  
    const { editorState } = this.state;
    const step = 0;
    return (
      <AppLayout 
        title={
          <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
            <BackButton to='/projects' />
            <div className="page-title">{this.props.currentProject.name}</div>
          </div>
        }
      >
      <div className="section-strategy">
        <BtGrid>
          <BtRow>
            <BtColumn className="column-content" style={{flexDirection: "column"}}>
              <ProjectTabsAndActions
                currentProject={this.props.currentProject}
                selected={step}
                onChange={this.props.onStepChange} 
                onToggleProjectStatus={this.props.onToggleProjectStatus}
                onErfa={this.props.onErfa}
                onErfaNew={this.props.onErfaNew}
                onNewArea={this.props.onNewArea}
                history={this.props.history}
              />
              <div class="app-project-settings">
                <div className="left--style">
                  <div className="left-box--style">

                    <p>
                      {" "}
                      Beroende på projektets entreprenadform, omfattning och villkor eller beroende på marknadskonjunkturen, är det viktigt att skapa en strategi som riktlinje för teamet, under detta kapitel tas beslut om hur kostnadsbedömning skall göras och/eller hur kommande köp av tjänster och material säkerställs.
                    </p>
                    <p>
                      <strong>Besluten kan variera mellan nedanstående strategiska val, t.ex.:</strong>
                    </p>
                    <ul className="listing bullet--style">
                      <li>Vi kalkylerar med erfarenhetsvärden</li>
                      <li>Vi bedömer kostnaden själva</li>
                      <li>Köpet omfattas av ett ramavtal med givna priser</li>
                      <li>Vi nyttjar marknaden för att bedöma en kostnadsnivå men vi bedömer att ny förfrågan kommer att krävas vid upphandling</li>
                      <li>Vi säkerställer redan i anbudsskedet att underlaget för kommande köp är tillräckligt väl genomarbetat och skarpt</li>
                      <li>Vi säkrar upp 70% med offerter</li>
                      <li>Vi får in minst 5 anbud inom varje kategori.</li>
                    </ul>
                  </div>
                </div>
                <div className="right--style" style={{ height: 'calc(100% - 80px)' }}>
                  <div className="right-box--style" style={{ height: '100%' }}>
                    {
                      appState.isWriteAccessRole() ?
                        <div style={{ height: '100%' }}>
                          <Editor value={this.state.strategy} onChange={value => this.setState({ strategy: value })} placeholder={defaultStrategy} />
                        </div>
                    
                        // <textarea
                        //   // type="textarea"
                        //   cols="50"
                        //   rows="10"
                        //   placeholder={defaultStrategy}
                        //   value={this.state.strategy || ""}
                        //   onChange={e => {
                        //     this.setState({
                        //       strategy: e.target.value
                        //     });
                        //   }}
                        // //onBlur={e => this.handleSubmit(e.target.value)}
                        // /> 
                        :
                        <div>
                          <HTMLParser data={this.state.strategy} />
                        </div>
                    }
                  </div>
                </div>
                {
                  appState.isWriteAccessRole() &&
                  <div className="clearfix">
                    <BtButton class="btn-right bt-modal-hides" loaderShow={this.state.loaderShow} type="submit"
                      onClick={this.handleSubmit.bind(this)}>Spara</BtButton>
                  </div>
                }
              </div>
            </BtColumn>
          </BtRow>
        </BtGrid>
      </div>
      </AppLayout>     
    );
  }
}

export default injectIntl(ProjectStrategy);
