import React, { useEffect, useState } from 'react';
import { ServiceAuth } from '../../services/service-auth';
import { NotificationManager } from "react-notifications";
import appState from '../../state/AppStateContainer';
import { ServiceUser } from '../../services/service-user';

export default function UserInvitation({ location, history }) {
  const [authenticatedUser, setAuthenticatedUser] = useState(null);

  const continueCurrentSession = async (orgData) => {
    //To fetch new organization token and set data to appStateContainer
    await appState.fetchAndSetToken(orgData);
    let orgs = await appState.fetchUpdatedOrganizations();
    let currentOrg = orgs.find(o => o.slug === orgData.slug);
    appState.setCurrentOrganization(currentOrg);
    return;
  }

  useEffect(() => {
    const invitationCode = new URLSearchParams(location.search).get('code');
    let [,orgSlug] = location.pathname.split("/");
    const authenticateUser = async () => {
      if (invitationCode) {
        const authenticateUser = await ServiceAuth.authenticateUserForSignup(invitationCode, orgSlug);
        // console.log(authenticateUser, 'authenticateUser');
        setAuthenticatedUser(authenticateUser);
        if (authenticateUser && authenticateUser._id) {
          history.push({
            pathname: `/${orgSlug}/signup`,
            state: { userData: authenticateUser },
          });
          return;
        } else if(authenticateUser && authenticateUser.message === "User already exist"){
          if(!appState.getUserId()) {
            NotificationManager.info(`Välkommen till ${authenticateUser.orgData.name}, logga in för att fortsätta`);
          }else if(appState.getUserId() && appState.getUserId() !== authenticateUser.userId){
            appState.signout();
          }else if(appState.getUserId() && appState.getUserId() == authenticateUser.userId){
            await continueCurrentSession(authenticateUser.orgData);
          }
          history.push(`/${orgSlug}/dashboard`)
          return;
        }
      }
      history.push(`/error`);
    };

    authenticateUser();
  }, []);

  return (
    <span className="app-loader loader-show">
      <span className="bounce1"></span>
      <span className="bounce2"></span>
      <span className="bounce3"></span>
    </span>
  );
}
