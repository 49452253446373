import React from 'react';
import Rating from 'react-rating';
import { areEqual } from '../../util';
import './Rating.css';
const StarRating = React.memo(
  ({
    numberOfStars,
    className,
    noNumber,
    emptySymbol = "fa fa-star-o",
    fullSymbol = "fa fa-star",
    rating,
    changeRating=()=> console.log("Please pass changeRating handler"),
    style = {},
    readOnly,
  }) => {
    const decimalSeparatedRating = rating.toFixed(1).replace(".",",");
    return (
      <span style={style} className={className} title={readOnly ? decimalSeparatedRating : ""}>
        <Rating
          emptySymbol={emptySymbol}
          fullSymbol={fullSymbol}
          fractions={1}
          stop={numberOfStars}
          initialRating={rating}
          onChange={changeRating}
          readonly={readOnly}
        />
        <React.Fragment>
          {!noNumber ? <div className="rating-number">{decimalSeparatedRating}</div> : null}
        </React.Fragment>
      </span>
    );
  },
  areEqual,
);

export default StarRating;
