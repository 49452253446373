import { FC, useState } from "react";
import { Subscription } from "../../services/service-sales";
import { formatDate, formatDateTime } from "../../util";
import BtButton from "../bt-button/bt-button";
import Modal from "../../components/shared/Modal/Modal";
import { Loading } from "../../components/shared";

type Props = {
  productName: string,
  purchasePage: string,
  subscription?: Subscription,
  loading: boolean,
  onSubscribe: () => void,
  onCancel: () => void,
  allUsers?: Map<string, string>,
}

const SubscriptionSettings : FC<Props> = ({ productName, purchasePage, subscription, loading, onSubscribe, onCancel, allUsers }) => {
  const [showSubscriptionHistory, setShowSubscriptionHistory] = useState(false);

  if (loading) {
    return <Loading type="inline" />
  }

  const isActive = !!subscription?.active?.startDate;
  const isCancelled = !subscription?.active?.autoRenew || subscription?.active?.cancelAt;
  const cancelAt = subscription?.active?.cancelAt;
  const endDate = subscription?.active?.endDate;
  const cancellationDelay = subscription?.active?.cancellationDelay;
  const cancellationDelayText = cancellationDelay == "P3M" ? ", 3 månaders uppsägningstid" : ""
  const num = (s: string | undefined) => s != undefined ? +s : NaN;

  return (<div style={{marginBottom: "16px"}}>
    {isActive ? <>
      Prenumeration på "{productName}" är
      {!isCancelled ? (
        <> aktiv och kommer att förnyas den {formatDate(subscription?.active?.endDate)} ({num(subscription?.active?.price) / num(subscription?.active?.duration)} kr/månad{cancellationDelayText}). 
          <BtButton 
            onClick={onCancel}
            size="xxs"
            color="white"
            style={{marginLeft: "8px"}}
          >
            Avsluta prenumeration
          </BtButton>
        </>
      ) : (
        
        <> 
          {cancelAt && endDate && cancelAt > endDate ? (
            <> uppsagd och kommer inte förnyas efter den {formatDate(cancelAt)}</>
          ) : (
            <> uppsagd och kommer att avslutas den {formatDate(subscription?.active?.endDate)}</> 
          )}  
          <> ({num(subscription?.active?.price) / num(subscription?.active?.duration)} kr/månad{cancellationDelayText}).</>
          <BtButton 
            onClick={onSubscribe}
            size="xxs"
            color="white"
            style={{marginLeft: "8px"}}
          >
            Förläng prenumeration
          </BtButton>
        </>
      )}
      <BtButton
        onClick={() => setShowSubscriptionHistory(true)}
        size="xxs"
        color="white"
        style={{marginLeft: "8px"}}
      >
        Visa historik
      </BtButton>
    </> : <>
      Prenumeration på "{productName}" är inte aktiv. Den kan köpas på sidan "{purchasePage}".
      {!!subscription?.history?.length && (
        <BtButton
          onClick={() => setShowSubscriptionHistory(true)}
          size="xxs"
          color="white"
          style={{marginLeft: "8px"}}
        >
          Visa historik
        </BtButton>        
      )}
    </>}
    <Modal 
      title="Prenumerationshistorik" 
      show={showSubscriptionHistory} 
      setShow={setShowSubscriptionHistory}
      cancelButtonText="OK"
      isScrollable
    >
      <div style={{fontSize: "14px", color: "var(--gray-700)", maxWidth: "700px"}}>
        {(subscription?.history ?? []).map(event => {
          const labels = {
            ORDER: "Beställdes",
            CANCEL: "Avslutades",
            UNCANCEL: "Förlängdes",
            RENEW: "Förnyades",
            EXPIRE: "Upphörde",
          };
          return (
            <p key={event._id}>
              {formatDateTime(event.date)}: {labels[event.action]} 
              {event.user && <> av {allUsers?.get(event.user) ?? event.user}</>}
              {!isNaN(+event?.price / +event?.duration) && <> ({+event.price / +event.duration} kr/månad)</>}
            </p>
          );
        })}
      </div>
    </Modal>
  </div>);
}

export default SubscriptionSettings;