import React from 'react';
import Tabs from '../../components/shared/Tabs';
import BtButton from '../bt-button/bt-button';
import appState from '../../state/AppStateContainer';
import { ReactComponent as MonitoringHeartIcon } from '../../assets/svg/monitoringHeartIcon.svg'

function areasInStep(currentProject, step) {
  if (step === 6) {
    return currentProject.area;
  }
  return currentProject.area.filter(a => (a.step || 1) == step);
}

function tooltip(currentProject, step) {
  const areas = areasInStep(currentProject, step);
  if (areas.length) {
    return "Upphandlingar:\n" + areas.map(a => a.areaId.name).join("\n");
  } else {
    return null;
  }    
}

export default function ProjectTabsAndActions({ currentProject, selected, onChange, onToggleProjectStatus, onErfa, onNewArea, history }) {
  
  const getDBIndexFromUIIndex = (stepIndex) => {
    let indexOfSelectedTab;
    if (stepIndex === 0) {
      indexOfSelectedTab = 0;
    } else if (appState.getListViewBetaPreference()) {
      if (stepIndex === 6) {
        indexOfSelectedTab = 1;
      } else {
        indexOfSelectedTab = stepIndex + 1;
      }
    } else {
      indexOfSelectedTab = stepIndex;
    }

    return indexOfSelectedTab;
  }

  const getUIIndexFromDBIndex = (stepIndex) => {
    let indexOfSelectedTab;
    if (stepIndex === 0) {
      indexOfSelectedTab = 0;
    } else if (appState.getListViewBetaPreference()) {
      if (stepIndex === 1) {
        indexOfSelectedTab = 6;
      } else {
        indexOfSelectedTab = stepIndex - 1;
      }
    } else {
      indexOfSelectedTab = stepIndex;
    }
    

    return indexOfSelectedTab;
  }

  const tabs = [
    "Strategi",
    `Förfrågan (${areasInStep(currentProject, 1).length})`, 
    `Utvärdering (${areasInStep(currentProject, 2).length})`, 
    `Förhandling (${areasInStep(currentProject, 3).length})`, 
    `Avtal (${areasInStep(currentProject, 4).length})`, 
    "Information"
  ]

  const tooltips= [
    null,
    tooltip(currentProject, 1),
    tooltip(currentProject, 2),
    tooltip(currentProject, 3),
    tooltip(currentProject, 4),
    null,
  ]

  if (appState.getListViewBetaPreference()) {
    tabs.splice(1, 0, `Inköpsplan (${areasInStep(currentProject, 6).length})`);
    tooltips.splice(1, 0, tooltip(currentProject, 6));
  }

  const organizationHasSyna = appState.getOrgFromUrl()?.hasSyna;

  return (
    <div className="tab-container">
      <Tabs
        tabs={tabs}
        tooltips={tooltips}
        selected={getDBIndexFromUIIndex(selected)}
        onChange={(x) => {
          onChange(getUIIndexFromDBIndex(x));
        }}
      />
      <div className="tab-actions" style={{ display: 'flex' }}>
        {organizationHasSyna && (
          <BtButton class="white" onClick={() => {
            const url = appState._getCurrentOrgUrl();
            history.push(`${url}/supplier-monitoring/events`, {
              project: {
                id: currentProject._id,
                name: currentProject.name,
              },
            })
          }} leftIcon={<MonitoringHeartIcon />}
          >
            Visa händelser
          </BtButton>
        )}
      {appState.isWriteAccessRole() && 
        <BtButton class="white" onClick={onToggleProjectStatus}>
          {currentProject.isOpen
            ? "Avsluta projektet"
            : "Återuppta projektet"}                        
        </BtButton>
      }
      {appState.isWriteAccessRole() && 
        <BtButton class="white" onClick={onErfa}>Erfarenhetsåterföring</BtButton>
      }
      {appState.isWriteAccessRole() && 
        <BtButton class="primary" onClick={onNewArea}>Ny upphandling</BtButton>
      }
      </div>
    </div>
  );
}
