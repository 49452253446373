export const regions = [
  {
    label: 'Blekinge',
    value: 'Blekinge',
  },
  {
    label: 'Dalarna',
    value: 'Dalarna',
  },
  {
    label: 'Gävleborg',
    value: 'Gävleborg',
  },
  {
    label: 'Gotland',
    value: 'Gotland',
  },
  {
    label: 'Halland',
    value: 'Halland',
  },
  {
    label: 'Jämtland',
    value: 'Jämtland',
  },
  {
    label: 'Jönköping',
    value: 'Jönköping',
  },
  {
    label: 'Kalmar',
    value: 'Kalmar',
  },
  {
    label: 'Kronoberg',
    value: 'Kronoberg',
  },
  {
    label: 'Norrbotten',
    value: 'Norrbotten',
  },
  {
    label: 'Skåne',
    value: 'Skåne',
  },
  {
    label: 'Stockholm',
    value: 'Stockholm',
  },
  {
    label: 'Södermanland',
    value: 'Södermanland',
  },
  {
    label: 'Uppsala',
    value: 'Uppsala',
  },
  {
    label: 'Värmland',
    value: 'Värmland',
  },
  {
    label: 'Västerbotten',
    value: 'Västerbotten',
  },
  {
    label: 'Västernorrland',
    value: 'Västernorrland',
  },
  {
    label: 'Västmanland',
    value: 'Västmanland',
  },
  {
    label: 'Västra Götaland',
    value: 'Västra Götaland',
  },
  {
    label: 'Örebro',
    value: 'Örebro',
  },
  {
    label: 'Östergötland',
    value: 'Östergötland',
  },
];