import * as React from 'react';
import PropTypes from 'prop-types';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import UserDetailsForm from '../../ts-components/page-registration/user-details';
import appState from '../../state/AppStateContainer';
import Tabs from '../../components/shared/Tabs'

import './styles.css';
import { ServiceUser } from '../../services/service-user';
import AppChangePassword from './../app-password/app-change-password';
import _ from 'lodash';
import { styled } from '@material-ui/core';
import AppLayout from '../../components/layout/app-layout/app-layout';
import AppEditDemands from '../app-edit-options/app-edit-demands';
import AppEditPriceFields from '../app-edit-options/app-edit-price-fields';

// import { styled } from '@material-ui/styles';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      className="custom-tab-style"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {(value === index) && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function Settings() {
  const [value, setValue] = React.useState(0);
  const [userData, setUserData] = React.useState(null);
 
  React.useEffect(() => {
    const fetchAndSetUser = async () => {
      const userId = appState.getUserId();
      let user = await ServiceUser.getUserById(userId);
      setUserData(user);
    };
    fetchAndSetUser();
  }, []);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <AppLayout title="Din profil">

    <div className="settings-container">
      <Tabs tabs={["Profil", "Byt lösenord"]} selected={value} onChange={handleChange} />
      <TabPanel value={value} index={0}>
        <div className="profile-wrapper">
          <UserDetailsForm
           
            isProfile
            user={userData}
            setParentUser={setUserData}
          />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="change-password-wrapper">
          <AppChangePassword
            email={_.get(userData, 'email', 'Loading email...')}
            orgSlug={appState._getCurrentOrgUrl()}
            changePassword
          />
        </div>
      </TabPanel>
    </div>
    </AppLayout>
  );
}
