import { FC } from "react";
import TitleWrapper from "../../components/shared/TitleWrapper/TitleWrapper";
import { ServiceContract } from "../../services/service-contract";
import { NotificationManager } from "react-notifications";
import BtButton from "../bt-button/bt-button";
import appState from "../../state/AppStateContainer";
import { ContractStatusType } from "../../models/global.model";

export type Signatory = {
  email: string,
  name: string,
  phone: string,
  company: string,
  sign_time: Date,
  seen_time: Date,
  rejected_time: Date,
  rejection_reason?: string,
  status: string,
  sign_order: number,
}

const getSignatoryStatus = (p: Signatory, status: ContractStatusType) => {
  if (p.sign_time) return "Signerat";
  if (p.rejected_time) return "Nekat";
  if (status == "timedout") return "Utgånget";
  if (status == "document_error") return "Dokumentfel";
  if (status == "canceled") return "Avbrutet";
  if (p.seen_time) return "Öppnat";
  if (p.status == "WAITING") return `Väntar`;
  if (p.status == "STOPPED") return `Ej längre möjligt`;
  return "Skickat";
}

const SendAgainState = {
  IN_PROGRESS:"IN_PROGRESS", ERROR:"ERROR", SUCCESS:"SUCCESS"
}

type Props = {
  loading: boolean,
  status?: ContractStatusType,
  signatories: Signatory[],
  sendAgain: {[key: string]: string},
  setSendAgain: React.Dispatch<React.SetStateAction<{[key: string]: string;}>>,
  error?: string,
  documentId: string,
  organizationNumber: string,
}

const ContractStatus: FC<Props> = ({ loading, signatories, status, sendAgain, setSendAgain, error, documentId, organizationNumber }) => {
  const sendContractEmailAgain = async (email: string, documentId: string) => {
    const setSendAgainState = (email: string, state: string) => {
      setSendAgain((prevState) => {
        const tempState = {...prevState};
        tempState[email] = state;
        return tempState;
      });  
    }
    setSendAgainState(email, SendAgainState.IN_PROGRESS);
    try {
      await ServiceContract.sendAgainSalesOrgContract(email, documentId, organizationNumber);
      NotificationManager.success("E-post med signeringslänk skickad på nytt.")
      setSendAgainState(email, SendAgainState.SUCCESS);
    } catch (e) {
      NotificationManager.error("Kunde inte skicka om kontraktet: " + (e as Error).message)
      setSendAgainState(email, SendAgainState.ERROR);
    }
  }

  const isReadOnlyUser = appState.isReadOnlyAccessRole();

  return (
    <TitleWrapper title='E-signering'>
      <div className="selectedContractStatuses">
        {!loading && status && (
          signatories.map(p => {
            const signatoryStatus = getSignatoryStatus(p, status);
            return (
              <div className="signatoryRow" key={p.email}>
                <span
                  className="signatoryName"
                  title={[p.name, p.email, p.phone, p.company].filter(x => x).join("\n")}
                >
                  {["Signerat"].includes(signatoryStatus) && (<span style={{ color: "var(--green-500)" }}>• </span>)}
                  {["Nekat", "Utgånget", "Dokumentfel", "Avbrutet", "Ej längre möjligt"].includes(signatoryStatus) && (<span style={{ color: "var(--red-500)" }}>• </span>)}
                  {["Öppnat", "Skickat", "Väntar"].includes(signatoryStatus) && (<span style={{ color: "var(--yellow-500)" }}>• </span>)}
                  {p.name}
                </span>
                <span className="signatoryStatus" title={signatoryStatus == "Nekat" ? (p.rejection_reason || "Inget meddelande") : undefined}>{signatoryStatus}</span>
                <div className="signatoryButtonWrapper">
                  <BtButton
                    class="white"
                    size="xxs"
                    style={{ fontSize: "12px", visibility: !["Skickat", "Öppnat"].includes(signatoryStatus) || isReadOnlyUser ? "hidden" : "visible" }}
                    onClick={() => sendContractEmailAgain(p.email, documentId)}
                    loaderShow={sendAgain[p.email] == SendAgainState.IN_PROGRESS}
                    disabled={sendAgain[p.email] == SendAgainState.SUCCESS || isReadOnlyUser}
                  >
                    {sendAgain[p.email] == SendAgainState.SUCCESS &&
                      <>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 29 29">
                          <path
                            fill="var(--green-500)"
                            d="M14.5,0 C6.50390909,0 0,6.50390909 0,14.5 C0,22.4960909 6.50390909,29 14.5,29 C22.4960909,29 29,22.4960909 29,14.5 C29,6.50390909 22.4960909,0 14.5,0 Z M23.3423636,10.1605455 L12.7969091,20.706 C12.5385455,20.9617273 12.2010909,21.0909091 11.8636364,21.0909091 C11.5261818,21.0909091 11.1887273,20.9617273 10.9303636,20.706 L5.65763636,15.4332727 C5.14354545,14.9191818 5.14354545,14.0834545 5.65763636,13.5693636 C6.17172727,13.0552727 7.00745455,13.0552727 7.52154545,13.5693636 L11.861,17.9088182 L21.4731818,8.29663636 C21.9872727,7.78254545 22.823,7.78254545 23.3370909,8.29663636 C23.8511818,8.81072727 23.8564545,9.64381818 23.3423636,10.1605455 Z"
                          />
                        </svg> Skickat
                      </>
                    }
                    {sendAgain[p.email] != SendAgainState.SUCCESS &&
                      "Skicka igen"
                    }
                  </BtButton>
                </div>
              </div>
            )
          })
        )}
        {!loading && !status && !error && (
          <span className="selectedContractMessage">Ingen e-signering</span>
        )}
        {error && (
          <span className="selectedContractMessage">{error}</span>
        )}
      </div>
    </TitleWrapper>
  )
}

export default ContractStatus;