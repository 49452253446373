import React from 'react';
import { NavLink } from 'react-router-dom';
import appState from '../../state/AppStateContainer';

export default function CustomNavLink({
  children,
  to,
  activeClassName = '',
  slot = undefined,
  title = undefined,
  exact = false,
  className = '',
  target = undefined,
  showLinkActive = undefined,
}) {
  const slug = appState.getCurrentOrgSlug();
  let _to = to;
  if (typeof _to === 'object') _to.pathname = `/${slug}${to.pathname}`;
  else if (typeof _to === 'string') _to = `/${slug}${to}`;
  return (
    <NavLink
      to={_to}
      activeClassName={activeClassName}
      slot={slot}
      title={title}
      exact={exact}
      className={className}
      show-link-active={showLinkActive}
      target={target}
    >
      {children}
    </NavLink>
  );
}
