import React from 'react';
import { areEqual } from '../../util';
import StarRating from '../../components/shared/Rating';

import '../app-chat-mail-batch/app-chat-mail-batch.css';
import '../app-select-industry-types/app-select-industry-types.css';
import './rating-form.css';

const RateQuestion = React.memo(
  ({ question, comment, setComment, rating, onRating, scrollId, isDisabled }) => {
    return (
      <div className="fm-group">
        <label>{question}</label>
        <div style={{ margin: "8px 0", display: 'flex' }} id={`scrollId${scrollId}`}>
          <StarRating
            numberOfStars={5}
            changeRating={onRating}
            rating={rating}
            readOnly={isDisabled}
            emptySymbol={`fa fa-star-o fa-2x medium`}
            fullSymbol={`fa fa-star fa-2x medium`}
            noNumber
            style={{ color: '#ff5e15' }}
          />
        </div>
        <div className="message-wrapper">
          <textarea
            className="rating-input comment"
            defaultValue={comment}
            disabled={isDisabled}
            onChange={e => setComment(e.target.value)}
          />
        </div>
      </div>
    );
  },
  areEqual,
);

export default RateQuestion;
