import { useEffect, useRef, useState } from "react";

export const useDebouncedState = <T>(defaultValue: T, delay = 500): [T, (value: T) => void, T] => {
  const [value, setValue] = useState(defaultValue);
  const [debouncedValue, setDebouncedValue] = useState(defaultValue);
  const timerRef = useRef<number>();

  useEffect(() => {
    timerRef.current = window.setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [value, delay]);

  return [value, setValue, debouncedValue];
};