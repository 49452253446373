import { useState } from 'react';
import AppLogin from '../app-login/app-login';
import ForgotPassword from '../app-login/forgot-password';
import CraneImage from '../../assets/images/crane.jpg'

import './page-login.css';
import BtBackground from '../bt-background/bt-background';
import BtGrid from '../bt-grid/bt-grid';
import BtRow from '../bt-row/bt-row';
import BtColumn from '../bt-column/bt-column';
import BtCenter from '../bt-center/bt-center';

const PageLogin = () => {
  const [showModalForgotPassword, setShowModalForgotPassword] = useState(false);  
  const [email, setEmail] = useState("");  
  const [forgotPasswordModalKey, setForgotPasswordModalKey] = useState(0);
      
  const toggleForgotPasswordModal = () => {
    setShowModalForgotPassword(!showModalForgotPassword);
    setForgotPasswordModalKey(forgotPasswordModalKey + 1);
  };
  return (
    <div className="page-login">
      <BtGrid>
        <BtRow>
          <BtColumn className="bt-w50 first-column bg-white">
            <BtCenter>
              <AppLogin
                email={email}
                setEmail={setEmail}
                className="page-padding"
                showFogotPassword={toggleForgotPasswordModal}
              ></AppLogin>
            </BtCenter>
          </BtColumn>
          <BtColumn className="second-column">
            <BtBackground
              className="bg-login bt-background-position-left"
              src={CraneImage}
            />
          </BtColumn>
        </BtRow>
      </BtGrid>
      <ForgotPassword
        initialEmail={email}
        show={showModalForgotPassword}
        setShow={toggleForgotPasswordModal}
        key={forgotPasswordModalKey}
      />
    </div>
  );
}

export default PageLogin;