import { FC, useCallback, useEffect, useMemo, useState } from "react";
import Field from "../../components/shared/Field/Field";
import styles from "./SearchFieldWithDropdown.module.scss";
import { Result } from "../../services/service-supplier-monitoring";
import { Loading } from "../../components/shared";
import _ from "lodash";

type Props = {
  className?: string,
  value: string,
  onChange: (value: string) => void,
  debouncedValue: string,
  onSelect: (entity: Result) => void,
  fetchSearchResults: (searchValue: string) => Promise<Result[]>,
  placeholder?: string,
}

export const SearchFieldWithDropdown: FC<Props> = ({
  value,
  onChange,
  debouncedValue,
  fetchSearchResults,
  onSelect,
  className,
  placeholder,
}) => {
  // Unique id to prevent targeting another field if multiple exists at the same time
  const fieldId = useMemo(() => `monitoringEntitiesSearchField${Date.now()}${Math.random()*1000000}`, []);
  const containerId = useMemo(() => `monitoringEntitiesSearchContainer${Date.now()}${Math.random()*1000000}`, []);
  const [searching, setSearching] = useState(true);
  const [searchResults, setSearchResults] = useState<Result[]>([]);
  const [searchVisible, setSearchVisible] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(0);

  const supplierResults = searchResults.filter(r => r.tags.type === 'Supplier') || [];
  const projectResults = searchResults.filter(r => r.tags.type === 'Project') || [];
  const userResults = searchResults.filter(r => r.tags.type === 'User') || [];

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const wrapperElement = document.getElementById(containerId);
      if (wrapperElement && !wrapperElement.contains(e.target as Node)) {
        setSearchVisible(false);
      }
    }
    window.addEventListener("click", handleClick);

    return () => window.removeEventListener("click", handleClick);
  }, []);

  useEffect(() => {
    const keydownHandler = (event: KeyboardEvent) => {
      if (event.key == 'ArrowUp') {
        setHighlightedIndex(Math.max(0, highlightedIndex - 1)) 
        event.stopPropagation();
      } else if (event.key == "ArrowDown") {
        setHighlightedIndex(Math.min(searchResults.length - 1, highlightedIndex + 1));
        event.stopPropagation();
      } else if (event.key == "Escape" && document.activeElement) {
        if (document.activeElement.id === fieldId) {
          (document.activeElement as HTMLElement).blur();
        }
        event.stopPropagation();
      } else if (event.key == "Enter") {
        onSelect(searchResults[highlightedIndex])
        setSearchVisible(false);
        event.stopPropagation();
      }
    }
    if (searchResults && searchResults.length > 0) {
      window.addEventListener("keydown", keydownHandler);
    }
  
    return () => window.removeEventListener("keydown", keydownHandler);
  }, [searchResults, highlightedIndex]);

  const search = useCallback(async () => {
    setSearching(true);
    if (debouncedValue) {
      setSearchVisible(true);
      const results = await fetchSearchResults(debouncedValue);
      setSearchResults(results);
      setHighlightedIndex(0);
    }
    setSearching(false);
  }, [debouncedValue]);

  useEffect(() => {
    search();
  }, [debouncedValue, search]);

  return <div
    className={className}
    style={{ position: 'relative' }}
    id={containerId}
  >
    <Field
      id={fieldId}
      style={{width: "320px"}}
      placeholder={placeholder}
      value={value}
      onChange={(newValue) => {
        onChange(newValue);
        if (value && !newValue) {
          setSearchVisible(false);
        }
      }}
      onFocus={() => {
        if (debouncedValue) {
          setSearchVisible(true);
        }
      }}
    />
    {searchVisible && (
      <div className={styles.searchContent}>
        {searching && <Loading type='inline' loadingStyle="bubbles" />}
        {!searching && (
          <>
            {_.isEmpty(searchResults) && <p style={{ fontSize: '12px', padding: '8px', textAlign: 'center' }}>Inget projekt, leverantör eller ansvarig matchade din sökning.</p>}
            {supplierResults && supplierResults.length > 0 && (
              <div className={styles.section}>
                <p>Leverantörer</p>
                {supplierResults.map((s, i) => (
                  <span
                    key={s.id}
                    title={s.title}
                    onMouseOver={() => setHighlightedIndex(i)}
                    className={highlightedIndex === i ? styles.highlighted : ""}
                    onClick={() => {
                      setSearchVisible(false);
                      onSelect(supplierResults[i]);
                    }}
                  >
                    {s.title}
                  </span>
                ))}
              </div>
            )}
            {projectResults && projectResults.length > 0 && (
              <div className={styles.section}>
                <p>Projekt</p>
                {projectResults.map((p, i) => (
                  <span
                    key={p.id}
                    title={p.title}
                    onMouseOver={() => setHighlightedIndex(i + supplierResults.length)}
                    className={highlightedIndex === i + supplierResults.length ? styles.highlighted : ""}
                    onClick={() => {
                      setSearchVisible(false);
                      onSelect(projectResults[i]);
                    }}
                  >
                    {p.title}
                  </span>
                ))}
              </div>
            )}
            {userResults && userResults.length > 0 && (
              <div className={styles.section}>
                <p>Ansvarig</p>
                {userResults.map((u, i) => (
                  <span
                    key={u.id}
                    title={u.title}
                    onMouseOver={() => setHighlightedIndex(i + supplierResults.length + projectResults.length)}
                    className={highlightedIndex === i + supplierResults.length + projectResults.length ? styles.highlighted : ""}
                    onClick={() => {
                      setSearchVisible(false);
                      onSelect(userResults[i]);
                    }}
                  >
                    {u.title}
                  </span>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    )}
  </div>
}