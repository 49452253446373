import React from 'react';
import Drawer from '@material-ui/core/Drawer';

function CustomDrawer({ position, children, toggleDrawer, isOpen }) {
  return (
    <React.Fragment>
      <Drawer anchor={position} open={isOpen} onClose={() => toggleDrawer(!isOpen)}>
        {children}
      </Drawer>
    </React.Fragment>
  );
}
export default React.memo(CustomDrawer)