import { FC, useEffect } from "react";
import Selector, { SelectorType } from "../../../components/shared/Selector/Selector";
import { uniqueId } from "lodash";
import styles from './CategorySelection.module.scss';
import BtButton from "../../bt-button/bt-button";
import { TrashIcon } from "../../../components/svgs";
import AddCircleIcon from '@material-ui/icons/AddCircle';

type SelectedCategory = {
  id: string,
  mainCategory: SelectorType & {subAreas: SelectorType[]} | null,
  subCategory: SelectorType | null,
};

type Props = {
  areas: (SelectorType & {subAreas: SelectorType[]})[],
  currentProjectAreas: {areaId: { name: string, slug: string }}[],
  selectedCategories: SelectedCategory[],
  setSelectedCategories: (fn: (prevState: SelectedCategory[]) => SelectedCategory[]) => void,
}

const newArea = (): SelectedCategory => ({
  id: uniqueId(),
  mainCategory: null,
  subCategory: null,
});

const CategorySelection: FC<Props> = ({ areas, currentProjectAreas, selectedCategories, setSelectedCategories }) => {
 useEffect(() => {
    if (selectedCategories.length === 0) {
      setSelectedCategories(() => [newArea()]);
    }
  }, [selectedCategories])

  const isSubAreaFieldMandatory = (mainArea: SelectorType & {subAreas: SelectorType[]} | null) => {
    return mainArea && currentProjectAreas && currentProjectAreas.find(area => area.areaId.name === mainArea.label);
  }
  const shouldSubAreaOptionBeDisabled = (mainCategory: SelectorType & {subAreas: SelectorType[]} | null, option: SelectorType) => !!currentProjectAreas && !!option && !!mainCategory && !!(currentProjectAreas.find(area => area.areaId.slug === option.value) || selectedCategories.find(_selectedCategory => _selectedCategory.mainCategory?.value === mainCategory?.value && _selectedCategory.subCategory?.value === option?.value));

  return (
    <div className={styles.container}>
      {selectedCategories.map((area, index) => {
        const getSelectedCategorySubAreas = () => {
          if (area.mainCategory) {
            return area.mainCategory.subAreas;
          }
          return []
        };

        const isLastArea = selectedCategories.length === 1;

        return (
          <div key={area.id} className={styles.areaContainer}>
            <Selector
              className={styles.areaSelector}
              label="Huvudkategori"
              value={area.mainCategory}
              isClearable
              onChange={(value) => {
                setSelectedCategories(prevState => {
                  const newState = [...prevState];

                  newState[index].mainCategory = value;
                  newState[index].subCategory = null;

                  return newState;
                });

                return null;
              }}
              options={areas}
              formatCreateLabel={(userInput) => `Skapa \"${userInput}\"`}
              placeholder='Välj huvudkategori'
            />
            <Selector
              className={`${styles.areaSelector}`}
              label="Underkategori"
              isCreatable
              isClearable
              isDisabled={!area.mainCategory}
              value={area.subCategory}
              onChange={(value) => {
                setSelectedCategories(prevState => {
                  const newState = [...prevState];
                  newState[index].subCategory = value;

                  return newState;
                })
              }}
              options={getSelectedCategorySubAreas()}
              formatCreateLabel={(userInput) => `Skapa \"${userInput}\"`}
              placeholder={`Välj eller skapa underkategori ${!isSubAreaFieldMandatory(area.mainCategory) ? '(valfritt)' : ''}`}
              isOptionDisabled={option => shouldSubAreaOptionBeDisabled(area.mainCategory, option)}
            />
            <BtButton
              color='white'
              size='xxs'
              disabled={isLastArea}
              onClick={() => {
                if (!isLastArea) {
                  setSelectedCategories(prevState => {
                    const newState = [...prevState];
                    newState.splice(index, 1)
                    return newState;
                  })
                }
              }}
              leftIcon={
                <TrashIcon size='14px' color='var(--gray-700)' />
              }
            />
          </div>
        )
      })}
      <BtButton 
        color="white" 
        size="xxs"
        style={{ width: 'fit-content' }}
        leftIcon={<AddCircleIcon htmlColor="var(--orange-500)"/>}
        onClick={() => {
          setSelectedCategories(prevState => [...prevState, newArea()]);
        }}
      >
        Lägg till yrkesområde
      </BtButton>
    </div>
  )
}

export default CategorySelection;