import React from 'react';
import PropTypes from "prop-types";
import ConstructImage from '../../assets/images/construct.png';
import BtGrid from '../bt-grid/bt-grid';
import BtRow from '../bt-row/bt-row';
import BtColumn from '../bt-column/bt-column';
import BtButton from '../bt-button/bt-button';
import { CustomLink } from '../../components/routes';
import * as Sentry from "@sentry/react";
import { withRouter } from 'react-router-dom';

class ResponseMessage extends React.Component {
    
    static contextTypes = {
        router: PropTypes.object
    }

    constructor(props, context) {
        super(props, context)
        this.state = {
        }
    }

    async componentDidMount() {
        const error = new Error("404 Not Found: " + this.props.location.pathname);
        console.log(error);
        Sentry.captureException(error);
    }

    render() {
        return (
            <div className="page-response-mesasge page--error">
                <BtGrid>
                    <BtRow>
                        <BtColumn>
                            <img src={ConstructImage} alt="Error"/>
                        </BtColumn>
                        <BtColumn>
                            <div className="rm-wrapper">
                                <h2 className="title">404</h2>
                                <h4 className="subtitle">Oh no! page not found</h4>
                                <p className="description">The page you're looking for isn't available. <br/> Try your search again or use the Go Home button below.</p>
                                <CustomLink to='/'><BtButton>Go to home</BtButton></CustomLink>
                                
                            </div>
                        </BtColumn>
                    </BtRow>
                </BtGrid>
            </div >
        );
    }
}

export default withRouter(ResponseMessage);