import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import Field from '../Field/Field';
import { createPortal } from 'react-dom';
import Card from '../Card/card';
import { SearchIcon2 } from '../../svgs/searchIcon2';
import serviceSearch, { Result } from "../../../services/service-search";
import { debounce } from "lodash";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styles from "./GlobalSearch.module.scss";
import { getRandomGenerator } from '../../../util';

type Props = {
  show: boolean,
  setShow: (value: boolean) => void,
} & RouteComponentProps

const GlobalSearch : FC<Props> = ({ show, setShow, history }) => {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState("");
  const [selected, setSelected] = useState(0);
  const [results, setResults] = useState<Result[]>([]);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState("");
  
  const keyHandler = useCallback((e : KeyboardEvent) => {
    const os = (navigator.platform.match(/mac|win|linux/i) || ["other"])[0].toLowerCase();
    const modifierKey = os == "mac" ? "metaKey" : "ctrlKey";

    if (show) {
      if (e.key == "Escape") {
        setShow(false);
      } else if (e.key == "ArrowUp") {  
        setSelected(Math.max(0, selected - 1));      
        e.stopPropagation();
      } else if (e.key == "ArrowDown") {
        setSelected(Math.min(results.length - 1, selected + 1));
        e.stopPropagation();
      } else if (e.key == "Enter") {
        go(results[selected].path);
        e.stopPropagation();
      }
    } else {
      if (e[modifierKey] && e.key == "k") {
        if (e.metaKey && modifierKey != "metaKey") return;
        if (e.ctrlKey && modifierKey != "ctrlKey") return;
        if (e.shiftKey) return;
        if (e.altKey) return;
        setShow(true);
        e.preventDefault();
        e.stopPropagation();
      }  
    }
  }, [show, selected, results]);

  useEffect(() => {    
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  }, [keyHandler]);

  useEffect(() => {
    if (show && searchInputRef.current) searchInputRef.current.focus();
  }, [show]);

  useEffect(() => {
    if (!show) {
      inputChange("");
      setSelected(0);
      setResults([]);
    }
  }, [show]);

  const debounced = useMemo(() => debounce(async (value) => {
    setLoading(true);
    setQuery(value);
    const results = await serviceSearch.search(value);
    setLoading(false);
    setResults(results.results);
    setSelected(0);
  }, 500), []);

  const inputChange = useCallback((value: string) => {
    setValue(value);
    debounced(value);
  }, []);

  const go = (path: string) => {    
    if (path.split("/")[1] != history.location.pathname.split("/")[1]) {
      window.location.href = path; // real navigation when changing org
    } else {
      history.push(path);
    }
    setShow(false);
  }
  
  return createPortal(
    <div style={{display: show ? "flex" : "none", position: "fixed", top: 0, left: 0, zIndex: 999, width: "100vw", height: "100vh", backgroundColor: "rgba(0,0,0,25%)", justifyContent:"space-around", flexDirection: "column", alignItems: "center"}} onClick={() => setShow(false)}>
      <div style={{width: "768px"}} onClick={e => e.stopPropagation()}>
        <Card>
          <Field 
            value={value} 
            onChange={inputChange} 
            ref={searchInputRef} 
            icon={<SearchIcon2 size={20} color="var(--gray-500)" />} 
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key == "ArrowUp" || e.key == "ArrowDown") {
                e.preventDefault(); // used for selecting result, shouldn't move text input cursor
              }
            }}
            placeholder="Sök bland projekt, leverantörer och sidor"
          />
          <div style={{marginTop: 16, height: 520 }}>
            {loading ? (
              [1,2,3,4,5].map((result) => 
                <div 
                  style={{
                    margin: "8px 0",
                  }} 
                  key={"placeholder" + result}
                >
                  <div className={styles.loadingPlaceholder} style={{                    
                    borderRadius: "6px",
                    width: `calc(30% + ${Math.round(getRandomGenerator(query + result)() * 50)}%)`,
                  }}>
                    <div style={{
                      padding: "12px",
                    }}>
                      &nbsp;
                    </div>
                  </div>
                </div>
              )              
            ) : (
              results.length > 0 ? (
                results.map((result, i) => 
                  <div 
                    style={{
                      backgroundColor: selected == i ? "var(--gray-100)" : undefined,
                      cursor: "pointer",
                      padding: "12px 16px",
                      borderRadius: "6px",
                      margin: "8px 0",
                    }} 
                    onMouseEnter={() => {setSelected(i)}}
                    onClick={() => go(result.path)}
                    key={result.path}
                  >
                    {result.title}
                    <span style={{color: "var(--gray-500)"}}>
                      {result?.tags?.type && (` – ${result?.tags?.type}`)}
                      {result?.tags?.org && (` – ${result?.tags?.org}`)}
                    </span>
                  </div>
                )
              ) : (
                <div 
                  style={{
                    padding: "12px 16px",
                    borderRadius: "6px",
                    margin: "8px 0",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "center"
                  }} 
                  key={"no result"}
                >
                  <span style={{color: "var(--gray-500)"}}>Inget projekt, leverantör eller sida matchade din sökning.</span>
                </div>
              )
            )}
          </div>
        </Card>
      </div>
    </div>
  , document.body);

}

export default withRouter(GlobalSearch);