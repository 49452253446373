import { FC } from "react";
import { Sort } from "../../services/service-supplier-monitoring";

type Props = {
  sort?: Sort,
  setSort: (sort: Sort) => void,
  field: string,
  children?: React.ReactNode,
  className?: string,
}

export const CustomSort: FC<Props> = ({ sort, setSort, field, children, className }) => {
  return (
    <span className={className} onClick={() => {
      if (sort?.field === field) {
        if (sort?.order === 'asc') {
          setSort({ field: field, order: 'desc' });
        } else {
          setSort({});
        }
      } else {
        setSort({ field: field, order: 'asc' });
      }
    }}>
      {children}
    </span>
  )
}