import React from 'react';
import PropTypes from 'prop-types';
import { ServiceAuth } from '../../services/service-auth';
import { debounce } from 'lodash';

import AppLabel from '../app-label/app-label';
import DatePicker2 from 'react-datepicker';
import Select, { createFilter } from 'react-select';
import BtButton from '../bt-button/bt-button';
import { ServiceTasks } from '../../services/service-tasks';
import { NotificationManager } from 'react-notifications';
import { FormattedMessage } from 'react-intl';
import update from 'immutability-helper';

import BaseComponent from '../BaseComponent';
import moment from 'moment';
import appState from '../../state/AppStateContainer';
import './page-tasks.css';
import BtGrid from '../bt-grid/bt-grid';
import BtRow from '../bt-row/bt-row';
import BtColumn from '../bt-column/bt-column';
import Tabs from '../../components/shared/Tabs';
import { CrossSvg } from '../../components/svgs';
import { Loading } from '../../components/shared';
import AppLayout from '../../components/layout/app-layout/app-layout';
import SearchBarWithActions from '../../components/shared/SearchBarWithActions/SearchBarWithActions';
import MaterialTable from 'material-table';
import OptionsMenu from '../../components/shared/OptionsMenu/OptionsMenu';
import StyledCheckbox from '../../components/shared/StyledCheckbox/StyledCheckbox';
import Modal from '../../components/shared/Modal/Modal';
import Field from '../../components/shared/Field/Field';
import DatePicker from '../../components/shared/DatePicker/DatePicker';
import Selector from '../../components/shared/Selector/Selector';

const statusOption = [
  { label: 'Pågående', value: 'inProgress' },
  { label: 'Slutförd', value: 'complete' },
];
export default class PageTasks extends BaseComponent {
  element;
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      projects: [],
      currentProject: null,
      modalCreateProject: true,
      slideId: 0,
      slide: 1,
      title: '',
      description: '',
      dueDate: '',
      projectId: [],
      projectTasks: [],
      userId: [],
      status: '',
      projectData: [],
      filterProjectData: [],
      filterUserData: [],
      filterProject: '',
      filterUser: '',
      searchFilter: '',
      taskId: '',
      activeNav: 1,
      errorMessages: {},
      showModal: false,
    };
  }

  async logoutClickCallback() {
    await ServiceAuth.logout();

    //TODO: Above method doesn't clear out storage, instead below line will navigate user to login screen...
    //...which will cause storage to clear.
    window.location.href = '/';
  }

  async componentDidMount() {
    // this.componentHasHeight(this.btSlider).then(() => {
    //   // this.doTheSlide(false);
    //   this.element.querySelector('.menu-left .bt-slider').classList.add('animate');
    // });

    //this.configureProject($('.ac-task'));
    this.setState({ loader: true });
    let projectTasks = await ServiceTasks.getProjectsTasks();
    let projects = await ServiceTasks.getProjectsForTasks();
    let UserData = await ServiceTasks.getProjectUsers();
    var projectData = [];
    var filterUserData = [];
    var filterProjectData = [];

    if (projects.length > 0) {
      filterProjectData.push({ label: 'Alla', value: '' });
      projects.forEach(item => {
        projectData.push({ label: item.name, value: item._id });
        filterProjectData.push({ label: item.name, value: item._id });
      });
    }
    if (UserData.length > 0) {
      filterUserData.push({ label: 'Alla', value: '' });
      UserData.forEach(item => {
        filterUserData.push({ label: item.name, value: item._id });
      });
    }

    this.setState({
      filterUserData,
      projectData,
      projectTasks,
      filterProjectData,
      loader: false,
    });
  }

  reset = () => {
    this.setState(
      {
        title: '',
        description: '',
        dueDate: '',
        projectId: [],
        userId: [],
        status: '',
        filterProject: '',
        filterUser: '',
        searchFilter: '',
        taskId: '',
        showModal: false,
        errorMessages: {},
      },
      () => {
        this.resetValidators();
      },
    );
  };

  componentHasHeight(el) {
    return new Promise((_resolve, _reject) => {
      let timeout = () => {
        setTimeout(() => {
          if (el && el.offsetHeight > 1) {
            _resolve();
          } else {
            timeout();
          }
        }, 1000);
      };
      timeout();
    });
  }

  renderMyTasks = () => {
    this.reset();
    let userData = appState.getLoggedInUserDetail();
    this.setState({ activeNav: 2, filterUser: userData.id }, () => {
      this.updateProjectTasks();
    });
  };

  renderAllTasks = () => {
    this.reset();
    this.setState({ activeNav: 1 }, () => {
      this.updateProjectTasks();
    });
  };

  renderHeader() {
    const isWriteAccessRole = appState.isWriteAccessRole();
    const { activeNav } = this.state;
    return (
      <div className="tab-container">
        <Tabs
          tabs={['Aktiviteter i projekt', 'Mina aktiviteter']}
          selected={activeNav - 1}
          onChange={activeNav == 2 ? this.renderAllTasks : this.renderMyTasks}
        />
        {isWriteAccessRole && (
          <div className="tab-actions">
            <BtButton onClick={this.addTaskBtnCallback}>Ny aktivitet</BtButton>
          </div>
        )}
      </div>
    );
  }

  changedPasswordScreen() {
    this.props.history.push({ pathname: '/change/password', state: { changePassword: true } });
  }

  addTaskBtnCallback = () => {
    this.setState({ showModal: true, usersData: [], status: statusOption[0] });
  };

  handleEditPopup = (projectId, projectName, modal) => {
    this.setState({ projectId: { label: projectName, value: projectId } }, () => {
      this.updateUserList(projectId);
      this.setState({ [modal]: true });
    });
  };

  handleChangeInput = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeProject = async e => {
    this.setState({ projectId: e, userId: '', errorMessages: { ...this.state.errorMessages, projectId: undefined } });
    this.updateUserList(e.value);
  };

  updateUserList = async id => {
    let users = await ServiceTasks.getProjectUsers(id);
    var usersData = [];
    if (users.length > 0) {
      users.forEach(item => {
        usersData.push({ label: item.name, value: item._id });
      });
    }
    this.setState({ usersData });
  };

  renderSlide1() {
    this.state.statusOption = [
      { label: 'Pågående', value: 'inProgress' },
      { label: 'Slutförd', value: 'complete' },
    ];
    return (
      <div className={'slide-1'}>
        <AppLabel headline="Namn för aktivitet" required>
          <input name="title" value={this.state.title} onChange={this.handleChangeInput} />
          {this.renderRequiredValidator(this.state.title)}
        </AppLabel>

        <AppLabel headline="Beskrivning av aktivitet" required>
          <div className="app-chat-mail-batch">
            <div className="message">
              <div className="message-wrapper">
                <textarea
                  name="description"
                  onChange={this.handleChangeInput}
                  value={this.state.description}
                ></textarea>
              </div>
            </div>
          </div>
          {this.renderRequiredValidator(this.state.description)}
        </AppLabel>

        <AppLabel headline="Förfallodatum" required>
          <DatePicker2
            name="dueDate"
            selected={this.state.dueDate ? new Date(this.state.dueDate) : null}
            minDate={new Date()}
            onChange={date => {
              this.setState({
                dueDate: date,
              });
            }}
          />
        </AppLabel>
        {this.renderRequiredValidator(this.state.dueDate)}
        <AppLabel headline="Projekt" required>
          <Select
            name="projectId"
            options={this.state.projectData}
            className="c-autosuggest"
            placeholder="Välj"
            value={this.state.projectId}
            onChange={this.handleChangeProject.bind()}
            classNamePrefix="custom-dropdown-page-task"
          />
        </AppLabel>
        {this.renderRequiredValidator(this.state.projectId)}
        <AppLabel headline="Tilldela aktivitet" required>
          <Select
            name="userId"
            options={this.state.usersData}
            className="c-autosuggest"
            placeholder="Välj"
            value={this.state.userId}
            onChange={v => {
              this.setState({ userId: v });
            }}
            classNamePrefix="custom-dropdown-page-task"
          />
        </AppLabel>
        {this.renderRequiredValidator(this.state.userId)}
        <AppLabel headline="Status" required>
          <Select
            name="status"
            options={this.state.statusOption}
            className="c-autosuggest"
            placeholder="Välj"
            value={this.state.status}
            onChange={v => {
              this.setState({ status: v });
            }}
            classNamePrefix="custom-dropdown-page-task"
          />
        </AppLabel>
        {this.renderRequiredValidator(this.state.status)}
      </div>
    );
  }

  showSuccessMessage(message) {
    NotificationManager.success(
      <FormattedMessage id={'global.tasksSuccess'} defaultMessage={message} />,
      <FormattedMessage id={'global.success'} defaultMessage={'Success'} />,
    );
  }

  async btCheckClickHandler(task, projectTask, project) {
    try {
      let formData = {
        taskId: task._id,
        status: task.status != 'complete' ? 'complete' : 'inProgress',
      };
      await ServiceTasks.updateTasks(formData);

      const projectIndex = this.state.projectTasks.findIndex(
        ({ _id }) => _id.projectId == project.projectId,
      );
      const taskIndex = projectTask.findIndex(pt => task._id == pt._id);
      let newProjectTasks = this.state.projectTasks;

      if (projectIndex > -1 && taskIndex > -1) {
        newProjectTasks = update(newProjectTasks, {
          [projectIndex]: {
            tasks: { [taskIndex]: { status: { $set: formData.status } } },
          },
        });
      }
      this.setState({ projectTasks: newProjectTasks });

      this.showSuccessMessage('Aktiviteten uppdaterad');
    } catch (error) {
      console.log(error, 'error:::::');
      NotificationManager.error(
        <FormattedMessage
          id={'global.tasksError'}
          defaultMessage="Det gick inte att spara aktivitetsstatus."
        />,
        <FormattedMessage id={'global.error'} defaultMessage={'Misslyckat'} />,
      );
    }
  }

  getErrorMessages = () => {
    const inputs = [
      { label: 'title', type: 'field' },
      { label: 'description', type: 'field' },
      { label: 'projectId', type: 'selector' },
      { label: 'userId', type: 'selector' },
      { label: 'dueDate', type: 'field' },
      { label: 'status', type: 'selector' }
    ];
    const errorMessages = {};

    for (const input of inputs) {
      if ((input.type === 'selector' && !this.state[input.label]?.value) || (input.type === 'field' && !this.state[input.label])) {
        errorMessages[input.label] = 'Obligatorisk';
      }
    }

    return errorMessages;
  }

  updateProjectTasksLocally = (task) => {
    const { projectTasks } = this.state;
    const projectIndex = projectTasks.findIndex(({ _id }) => _id.projectId == task?.projectId);
    const taskIndex = projectTasks[projectIndex].tasks?.findIndex(pt => task._id == pt._id);

    let newProjectTasks = projectTasks;

    if (projectIndex > -1 && taskIndex > -1) {
      newProjectTasks = update(newProjectTasks, {
        [projectIndex]: {
          tasks: { [taskIndex]: { $set: task } },
        },
      });
    }
    this.setState({ projectTasks: newProjectTasks });
  }

  handleSubmit = async () => {
    const errorMessages = this.getErrorMessages();

    if (Object.values(errorMessages).some(errorMessage => errorMessage)) {
      this.setState({ errorMessages, loaderShow: false });
      return;
    }

    this.setState({ loaderShow: true });
    let formData = {};

    formData.title = this.state.title;
    formData.description = this.state.description;
    formData.projectId = this.state.projectId.value;
    formData.assignTo = this.state.userId.value;
    formData.dueDate = this.state.dueDate;
    formData.status = this.state.status.value;
    if (this.state.taskId) {
      formData.taskId = this.state.taskId;
      const response = await ServiceTasks.updateTasks(formData);
      if (response?._id) {
        this.updateProjectTasksLocally(response);
        this.showSuccessMessage('Aktiviteten uppdaterad');
      }
    } else {
      await ServiceTasks.addTasks(formData);
      this.updateProjectTasks();
      this.showSuccessMessage('Aktiviteten sparad.');
    }

    this.setState({ loaderShow: false, showModal: false });
    this.reset();
  };

  onModalHide = () => {
    this.reset();
  };

  renderModal() {
    const { errorMessages } = this.state;

    const setValue = (fieldName, value) => {
      this.setState({ [fieldName]: value, errorMessages: { ...errorMessages, [fieldName]: undefined } })
    };

    return (
      <>
        <Modal
          title={(this.state.taskId ? "Redigera" : "Ny") + ' aktivitet'}
          buttonInfo={{ label: this.state.taskId ? "Spara" : 'Skapa', action: this.handleSubmit, loaderShow: this.state.loaderShow }}
          show={this.state.showModal}
          setShow={(value) => {
            if (!value) {
              setTimeout(() => {
                this.reset();
              }, 300);
            }
            this.setState({ showModal: value })
          }}
        >
          <div className='activityModalContainer'>
            <Field
              label='Namn för aktivitet'
              required
              value={this.state.title}
              onChange={(value) => setValue('title', value)}
              errorMessage={errorMessages.title}
              style={{ fontSize: '14px' }}
            />
            <Field
              label='Beskrivning av aktivitet'
              required
              value={this.state.description}
              onChange={(value) => setValue('description', value)}
              errorMessage={errorMessages.description}
              multiline
              style={{ fontSize: '14px' }}
            />
            <DatePicker
              minDate={new Date()}
              label='Förfallodatum'
              required
              selected={this.state.dueDate ? new Date(this.state.dueDate) : null}
              onChange={date => {
                setValue('dueDate', date)
              }}
              errorMessage={errorMessages.dueDate}
              style={{ fontSize: '14px' }}
            />
            <Selector
              label='Projekt'
              required
              options={this.state.projectData}
              placeholder="Välj"
              value={this.state.projectId}
              onChange={this.handleChangeProject.bind()}
              errorMessage={errorMessages.projectId}
            />
            <Selector
              label='Tilldela aktivitet'
              required
              options={this.state.usersData}
              placeholder="Välj"
              value={this.state.userId}
              onChange={value => {
                setValue('userId', value)
              }}
              errorMessage={errorMessages.userId}
            />
            <Selector
              label='Status'
              required
              options={statusOption}
              placeholder="Välj"
              value={this.state.status}
              onChange={value => {
                setValue('status', value)
              }}
              errorMessage={errorMessages.status}
            />
          </div>
        </Modal>
      </>
    );
  }

  filterChangeProject = value => {
    this.setState({ filterProject: value }, () => {
      this.updateProjectTasks();
    });
  };

  filterChangeUsers = value => {
    this.setState({ filterUser: value }, () => {
      this.updateProjectTasks();
    });
  };

  filterChangeInput = debounce(value => {
    this.setState({ searchFilter: value }, () => {
      this.updateProjectTasks();
    });
  }, 1500);

  updateProjectTasks = async () => {
    let userId = this.state.filterUser?.value;
    if (this.state.activeNav == 2) {
      let userData = appState.getLoggedInUserDetail();
      userId = userData.id;
    }
    let projectTasks = await ServiceTasks.getProjectsTasks(
      this.state.searchFilter,
      this.state.filterProject?.value,
      userId,
    );
    this.setState({ projectTasks });
  };

  getColumns = (project, tasks) => {
    return [
      {
        title: 'Namn',
        cellStyle: { fontSize: null, width: '330px', padding: "0px" },
        render: rowData => {
          const isChecked = rowData.status == 'complete' ? true : false;
          return (
            <div className={`name ${isChecked ? 'completed' : ''}`}>
              <div
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {isChecked ? (
                  <StyledCheckbox
                    onChange={() => this.btCheckClickHandler(rowData, tasks, project)}
                    checked={isChecked}
                  />
                ) : (
                  <StyledCheckbox
                    onChange={() => this.btCheckClickHandler(rowData, tasks, project)}
                    checked={isChecked}
                  />
                )}
              </div>
              {rowData?.title}
            </div>
          );
        },
      },
      {
        title: 'Ansvarig',
        cellStyle: { fontSize: null, width: 'unset' },
        render: rowData => <div>{rowData?.assignTo?.name}</div>,
      },
      {
        title: 'Förfallodatum',
        cellStyle: { fontSize: null, width: 'unset' },
        field: 'dueDate',
        render: rowData => (
          <div className="due-date">
            {moment(rowData.dueDate).locale('sv').format('DD MMMM YYYY')}
          </div>
        ),
      },
      {
        title: 'Projekt',
        cellStyle: { fontSize: null, width: '400px' },
        render: () => <div>{project?.projectName}</div>,
      },
      {
        title: '',
        align: 'center',
        render: rowData => {
          return (
            <div
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <OptionsMenu
                style={{ width: '100%', height: '100%', display: 'grid', placeItems: 'flex-end' }}
                options={[
                  {
                    label: 'Redigera',
                    action: () => this.editTask(rowData, project),
                  },
                  {
                    label: 'Ta bort',
                    action: () => this.deleteTask(rowData?._id),
                  },
                ]}
              />
            </div>
          );
        },
        cellStyle: {
          width: 'unset',
        },
      },
    ];
  };

  renderContent() {
    const { filterProjectData, filterProject, filterUserData, filterUser, loader } = this.state;
    return (
      <AppLayout title="Aktiviteter">
        <div className="app-activity-database column-content">
          {this.renderHeader()}

          <SearchBarWithActions onChange={this.filterChangeInput}>
            <Selector
              isClearable
              placeholder="Projekt"
              options={filterProjectData}
              value={filterProject || null}
              onChange={this.filterChangeProject}
              filterOption={createFilter({ ignoreAccents: false })}
            />
            {this.state.filterUserData.length > 0 && this.state.activeNav == 1 && (
              <Selector
                isClearable
                placeholder="Användare"
                options={filterUserData}
                value={filterUser || null}
                onChange={this.filterChangeUsers}
                filterOption={createFilter({ ignoreAccents: false })}
              />
            )}
          </SearchBarWithActions>

          <div className="ac-task-list">
            {loader ? (
              <Loading className="task-loader" type="inline" />
            ) : (
              this.state.projectTasks.length > 0 &&
              this.state.projectTasks.map(item => {
                var projectData = item._id;
                return (
                  <div className="ac-task" key={projectData.projectId}>
                    <div className="project-title">{projectData.projectName}</div>
                    {this.renderTasks(item)}
                  </div>
                );
              })
            )}
          </div>
        </div>
      </AppLayout>
    );
  }
  deleteTask = async id => {
    if (window.confirm('Är du säker på att du vill ta bort den här Aktiviteter?')) {
      await ServiceTasks.deleteTasks(id);
      this.showSuccessMessage('Aktiviteten borttagen');
      this.updateProjectTasks();
    }
  };
  editTask = (taskData, projectData) => {
    let status = { label: 'Pågående', value: 'inProgress' };
    if (taskData.status == 'complete') {
      status = { label: 'Slutförd', value: 'complete' };
    }
    this.setState(
      {
        title: taskData.title,
        description: taskData.description,
        status: status,
        dueDate: taskData.dueDate,
        projectId: {
          label: projectData.projectName,
          value: projectData.projectId,
        },
        userId: { label: taskData.assignTo.name, value: taskData.assignTo._id },
        taskId: taskData._id,
      },
      () => {
        this.updateUserList(projectData.projectId);
        this.setState({ showModal: true });
      },
    );
  };

  renderTasks = ({ tasks, _id }) => {
    return (
      <MaterialTable
        options={{
          sorting: false,
          draggable: false,
          search: false,
          toolbar: false,
          detailPanelColumnStyle: { display: 'none' },
          rowStyle: () => ({
            color: 'var(--gray-800)',
          }),
          emptyRowsWhenPaging: false,
          showSelectAllCheckbox: false,
          headerStyle: {
            backgroundColor: 'var(--gray-50)',
          },
          pageSize: tasks.length
        }}
        onRowClick={(e, rowData) => this.editTask(rowData, _id)}
        key={tasks.length}
        style={{ margin: '25px 0px' }}
        columns={this.getColumns(_id, tasks)}
        data={tasks}
        components={{
          Pagination: () => null,
        }}
      />
    );
  };
  render() {
    return (
      <div>
        {this.renderContent()}
        {this.renderModal()}
      </div>
    );
  }
}
