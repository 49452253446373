import React from 'react';
import './page-internal-chat.css';
import { serviceProfile } from '../../services/service-profile';
import { ServiceChat } from '../../services/service-chat';
import { AppInternalMessage } from '../app-chat/app-internal-chat';
import appState from '../../state/AppStateContainer';
import _, { debounce } from 'lodash';
import BtColumn from '../bt-column/bt-column';
import appEnum from '../../util/appEnum';
import AppLayout from '../../components/layout/app-layout/app-layout';
import BtRow from '../bt-row/bt-row';
import BackButton from '../../components/shared/BackButton/BackButton';
import { Loading } from '../../components/shared';

export class PageInternalChat extends React.Component {
  pagemounted = true;
  socket = null;
  constructor(props, context) {
    super(props, context);
  }

  state = {
    clients: [],
    currentSupplier: {},
    currentClient: {},
    totalFilesClient: [],
    totalFilesUser: [],
    messages: [],
    currentMember: {},
    _colleagues: [],
    colleagues: [],
    projectList: [],
    messageLoader: false
  };

  componentWillUnmount = async () => {
    // socket.disconnect();
    this.pagemounted = false;
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
    await ServiceChat.setChatDeliveryStatus(this.props.match.params.id, appEnum.DeliveryStatus.Seen);
  }

  componentDidMount() {
    this.socket = ServiceChat.createSocket(appState.getUserToken());
    this.socket.on(`chatmessage`, x => {
      const matchSlug = x.orgSlugs && x.orgSlugs.includes(appState.getCurrentOrgSlug());
      if (!matchSlug) return;
      this.subscriber(x); 
      this.updateDeliveryStatus();
    });
  }

  updateDeliveryStatus = debounce(async () => {
    await ServiceChat.setChatDeliveryStatus(this.props.match.params.id, appEnum.DeliveryStatus.Seen);
  }, 3000);

  async UNSAFE_componentWillMount() {
    await this.bind();
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname != nextProps.location.pathname) {
      await this.setCurrentMemberAndMessages(nextProps);
    }
  }

  getAvatarUrl = (msgObj, role) => {
    let url = this.state.messages.find(msg => msg[role] === msgObj[role]);
    const checkEmail = url && url.email === appState.state.token.email;
    url = url && url.avatarUrl;
    if (checkEmail && url !== appState.getAvatarUrl()) {
      appState.setAvatarUrl(url);
    }
    return url;
  };

  subscriber = async messageObject => {
    let clientAvatarUrl =
      this.getAvatarUrl(messageObject, 'toUserId') || this.state.currentMember.avatarUrl;
    let userAvatarUrl = this.getAvatarUrl(messageObject, 'fromUserId');

    if (
      messageObject.toUserId == appState.state.token.id &&
      messageObject.fromUserId == this.state.currentMember._id
    ) {
      const result = {
        message: messageObject.message,
        name: this.state.currentMember.name,
        email: this.state.currentMember.email,
        phone: this.state.currentMember.phone,
        date: messageObject.date,
        type: 'client',
        files: messageObject.attachment,
        avatarUrl: clientAvatarUrl,
      };
      if (this.pagemounted)
        this.setState({
          messages: [result, ...this.state.messages], // latest on-top
        });
    }
    if (
      messageObject.toUserId == this.state.currentMember._id &&
      messageObject.fromUserId == appState.state.token.id
    ) {
      const result = {
        message: messageObject.message,
        name: appState.state.token.name,
        email: appState.state.token.email,
        phone: appState.state.token.phone,
        date: messageObject.date,
        type: 'user',
        files: messageObject.attachment,
        avatarUrl: userAvatarUrl || appState.getAvatarUrl(),
      };
      if (this.pagemounted)
        this.setState({
          messages: [result, ...this.state.messages], // latest on-top
        });
    }
  };

  async setCurrentMemberAndMessages(props) {
    this.setState({messageLoader: true})

    let item = serviceProfile.getItemBySlug(props.match.params.id);
    const data = await ServiceChat.getChatMessages(props.match.params.id);
    if (item) {
      const currentOrgSlug = appState.getCurrentOrgSlug();
      item.role = item.organizations.find(o => o.orgId.slug === currentOrgSlug).role;
      if (this.pagemounted)
        this.setState({
          currentMember: { ...item },
        });
    }
    if (data) {
      this.loadMessages(data);
    } else {
      if (this.pagemounted)
        this.setState({
          totalFilesClient: [],
          totalFilesUser: [],
          messages: [],
        });
    }
    this.setState({messageLoader: false})

  }

  checkToSetAvatar = ({ fromUserId, toUserId }) => {
    if (
      fromUserId._id === appState.state.token.id &&
      fromUserId.avatarUrl !== appState.getAvatarUrl()
    ) {
      appState.setAvatarUrl(fromUserId.avatarUrl);
    } else if (
      toUserId._id === appState.state.token.id &&
      toUserId.avatarUrl !== appState.getAvatarUrl()
    ) {
      appState.setAvatarUrl(toUserId.avatarUrl);
    }
  };

  async loadMessages(messages) {
    messages.conversation.reverse(); //reverse to latest on-top;
    this.checkToSetAvatar(messages);
    try {
      const conversation = messages.conversation.map(x => {
        let checkUser = x.fromUserId === appState.state.token.id;
        return {
          fromUserId: x.fromUserId,
          toUserId: x.toUserId,
          message: x.message || '',
          name: checkUser ? appState.state.token.name : this.state.currentMember.name,
          email: checkUser ? appState.state.token.email : this.state.currentMember.email,
          phone: checkUser ? appState.state.token.phone : this.state.currentMember.phone,
          date: x.createdAt,
          type: checkUser ? 'user' : 'client',
          files: x.attachment,
          avatarUrl:
            messages.fromUserId._id === x.fromUserId
              ? messages.fromUserId.avatarUrl
              : messages.toUserId.avatarUrl,
        };
      });
      const totalFilesClient = _.flatten(
        messages.conversation
          .filter(x => x.fromUserId === this.state.currentMember._id)
          .map(x =>
            x.attachment.map(a => ({
              ...a,
              date: x.createdAt,
            })),
          ),
      );
      const totalFilesUser = _.flatten(
        messages.conversation
          .filter(x => x.fromUserId === appState.state.token.id)
          .map(x =>
            x.attachment.map(a => ({
              ...a,
              date: x.createdAt,
            })),
          ),
      );
      if (this.pagemounted)
        this.setState({
          messages: conversation,
          totalFilesClient: totalFilesClient,
          totalFilesUser: totalFilesUser,
        });
    } catch (error) {
      console.error('error', error);
    }
  }

  async bind() {
    this.obsColleagues = serviceProfile.data$.subscribe(async colleagues => {
      if (colleagues) await this.setCurrentMemberAndMessages(this.props);
    });
  }

  goBack = () => {
    const url = window.location.pathname.split('/');
    url.splice(3);
    this.props.history.push(url.join('/'));
  };

  render() {
    const {
      messages,
      totalFilesClient,
      totalFilesUser,
      clients,
      currentMember,
      messageLoader,
    } = this.state;
    const memberName = currentMember?.name ? `${currentMember?.name}` : "";
    return (
      <AppLayout
        title={<BackButton onClick={this.goBack} path={['Meddelanden', memberName]} />}
      >
        {messageLoader && <Loading className="chat-loader" type="inline" size="100px" />}
          <BtRow>
          <BtColumn>
            <div className="page-internal-chat">
              <AppInternalMessage
                adminUser={appState.state.token}
                ref={el => (this.appChat = el)}
                baseURL={this.baseURL}
                messages={messages}
                totalFilesClient={totalFilesClient}
                totalFilesUser={totalFilesUser}
                clients={clients}
                currentMember={currentMember}
                currentClient={currentMember}
                editMemberBtnCallback={this.editMemberBtnCallback}
                chatOnly={true}
              />
            </div>
          </BtColumn>
        </BtRow>
        
      </AppLayout>
    );
  }
}
