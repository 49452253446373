import axios from "axios";
import _ from "lodash";
import BaseService from "./baseService";

export class ServiceErfaController extends BaseService {

  async getQuestions() {
    const response = await axios.get("/api/question");
    return response.data;
  }

  async addQuestion(data) {
    const response = await axios.post("/api/question", data);
    return response.data;
  }

  async deleteQuestion(id) {
    const response = await axios.delete("/api/question/" + id);
    return response.data;
  }

  async changeQuestion(id, question) {
    const response = await axios.put("/api/question/" + id, { question });
    return response.data;
  }

  async submitErfa(data) {
    const response = await axios.post("/api/submit/erfaDoc", data);
    return response.data;
  }

  async getErfa(id) {
    const response = await axios.get(`/api/erfaDoc/${id}`);
    return response.data;
  }

  async getErfaForSupplier(id) {
    const response = await axios.get(`/api/erfaDocSup/${id}`);
    return response.data;
  }
  
  async submitRating(data){
    const formData = new FormData();
    for (let key in data) {
      if (key === "files") {
        for (const fileKey in data.files) {
          formData.append(fileKey, data.files[fileKey].fileObj);
        }
      } else {
        formData.append(key, data[key]);
      }
    }
    const response = await axios.post(`/api/rating`, data);
    return response.data;
  }

}

export const ServiceErfa = new ServiceErfaController();
