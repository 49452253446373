import styles from './bt-background.module.scss';

type Props = {
  src: string,
  className?: string,
  tint?: string
}

export default function BtBackground({src, className="", tint}: Props) {
  return <div className={`${styles.btBackground} ${tint != '' ? 'tint-' + tint : '' } ${className}`} style={{backgroundImage: `url(${src})`}}></div>
};