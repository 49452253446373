import { FC } from 'react';
import BtButton from '../../../bt-button/bt-button';
import styles from './SupplierMemberContainer.module.scss';
import { ReactComponent as AddUserIcon } from '../../../../assets/svg/add-user.svg';
import { Member } from '../ContactInfoForm';
import Field from '../../../../components/shared/Field/Field';
import TooltipContainer from '../../../../components/shared/TooltipContainer/TooltipContainer';

type Props = {
  onAdd: () => void,
  member: Member,
}

const SuggestedContactContainer: FC<Props> = ({ member, onAdd }) => {
  const { name, email, title, phone } = member;
  const editMode = false;

  return (
    <div style={{display: "flex", alignItems: "center"}}>
      <div className={`${styles.container}`}>
        <div className={styles.form}>
          <Field
            value={name}
            onChange={value => value}
            placeholder='Namn*'
            isEditMode={editMode}
            className={styles.boldText}
            style={{
              gridRow: !title ? '1 / span 2' : '1',
              alignSelf: !title ? 'center' : ''
            }}
          />
          <Field
            value={email}
            onChange={value => value}
            placeholder='E-post*'
            isEditMode={editMode}
            className={styles.lightText}
            style={{
              gridRow: !phone ? '1 / span 2' : '1',
              alignSelf: !phone ? 'center' : ''
            }}
          />
          {
            (title || editMode) && (
              <Field
                value={title}
                onChange={value => value}
                placeholder='Titel'
                isEditMode={editMode}
                className={styles.lightText}
              />
            )
          }
          {
            (phone || editMode) && (
              <Field
                value={phone}
                onChange={value => value}
                placeholder='Telefon'
                isEditMode={editMode}
                className={styles.lightText}
              />
            )
          }
        </div>
        <div className={`${styles.buttonsDiv}`}>
          <TooltipContainer renderReferenceComponent={(className, ref) => (
            <div className={className} ref={ref}>
              <BtButton
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault();
                onAdd();
              }}
              color='white'
              size='xxs'
              leftIcon={
                <span style={{ color: "var(--gray-500)" }}><AddUserIcon /></span>
              }
              />
            </div>
          )}><span style={{ fontSize: "14px", color: "var(--gray-700)" }}>Lägg till kontaktperson</span></TooltipContainer>
          
        </div>
      </div>
    </div>
  );
}

export default SuggestedContactContainer;