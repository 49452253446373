import axios from "axios";
import { BehaviorSubject } from "rxjs";
import { btSort } from "../utils/bt-array";
import { post } from "../utils/bt-http";
import { apiUrl } from "../global/app";
import BaseService from "./baseService";
import update from "immutability-helper";
import _ from "lodash";
import moment from "moment";
import { Immer } from "immer";
import { fetchConversation, updateConversation } from "./service-conversation";
export class ServiceProjectsController extends BaseService {
  data$ = new BehaviorSubject([]);
  datacurrent$ = new BehaviorSubject({ areas: [] });
  storage = [];
  current;
  immer = new Immer();
  

  constructor() {
    super();
    this.immer.setAutoFreeze(false);
  }

  getProjectBySlug(slug) {
    const foundItem = this.storage.find(v => {
      if (v.slug == slug) {
        return true;
      } else {
        return false;
      }
    });
    return foundItem;
  }

  // async getProjectBySlug(slug) {
  //   const response = await axios.get("/api/project", {
  //     params: { id: slug }
  //   });
  //   return response.data;
  // }

  setCurrent(data) {
    this.current = data;
    this.datacurrent$.next({ ...data });
  }

  add(data) {
    this.storage.push(data);
    btSort(this.storage, "name");
    this.storage = [...this.storage];
    this.data$.next(this.storage);
  }

  replace(data) {
    btSort(data, "name");
    this.storage = [...data];
    this.data$.next(this.storage);
  }

  replaceDataItem(dataItem) {
    // Replace data
    var foundIndex = this.storage.findIndex(val => {
      if (val.slug == dataItem.slug || val._id == dataItem._id) {
        return true;
      }
    });

    if (foundIndex > -1) {
      this.storage[foundIndex] = dataItem;
      this.replace(this.storage);
    }
  }

  /**
   * Replaces the areas within the store and the current viewed project
   * @param projectSlug
   * @param areas
   */
  replaceAreasBySlug(projectSlug, areas) {
    if (!this.current || this.current.slug != projectSlug) return;

    let newProjectAreas = [];
    let oldAreas = this.current.area || [];

    for (let i = 0; i < areas.length; i++) {
      const ci = areas[i];
      let areaToAdd = oldAreas.find(loa => loa.areaId._id == ci._id);
      if (!areaToAdd) {
        areaToAdd = {
          areaId: {
            isActive: true,
            isDeleted: false,
            name: ci.name,
            slug: ci.slug,
            __v: 0,
            _id: ci._id
          },
          endDate: ci.endDate,
          suppliers: []
        };
      }

      newProjectAreas.push(areaToAdd);
    }

    let newCurrentProject = update(this.current, {
      area: { $set: newProjectAreas }
    });
    this.setcurrent(newCurrentProject);
  }

  replaceAreaSuppliersBySlug(projectSlug, areaId, newSuppliers) {
    if (!this.current || this.current.slug != projectSlug) return;

    let areaIndex = this.current.area.findIndex(a => a.areaId._id == areaId);
    let storageArea = this.current.area[areaIndex];
    if (storageArea) {
      let oldSuppliers = storageArea.suppliers;
      if (!oldSuppliers) {
        oldSuppliers = storageArea.suppliers = [];
      }

      oldSuppliers = [...oldSuppliers];

      for (let i = 0; i < newSuppliers.length; i++) {
        const ns = newSuppliers[i];
        if (
          oldSuppliers.findIndex(
            os => os.supplierId._id == ns.supplierId._id
          ) <= -1
        ) {
          oldSuppliers.push(ns);
        }
      }

      for (let i = oldSuppliers.length - 1; i >= 0; i--) {
        const sup = oldSuppliers[i];
        if (
          newSuppliers.findIndex(
            os => os.supplierId._id == sup.supplierId._id
          ) <= -1
        ) {
          oldSuppliers.splice(i, 1);
        }
      }

      let newCurrentProject = update(this.current, {
        area: { [areaIndex]: { suppliers: { $set: oldSuppliers } } }
      });

      this.setCurrent(newCurrentProject);
    }
  }

  updatePriceInSupplier(projectSlug, areaId, supplierId, newPrice) {
    if (!this.current || this.current.slug != projectSlug) return;

    let areaIndex = this.current.area.findIndex(a => a.areaId._id == areaId);
    let supplierIndex = this.current.area[areaIndex].suppliers.findIndex(
      s => s.supplierId._id == supplierId
    );
    let priceDetailIndex = this.current.area[areaIndex].suppliers[
      supplierIndex
    ].priceDetails.findIndex(pd => pd.priceType._id == newPrice.priceType);
    let newCurrentProject = update(this.current, {
      area: {
        [areaIndex]: {
          suppliers: {
            [supplierIndex]: {
              priceDetails: {
                [priceDetailIndex]: { price: { $set: newPrice.price } }
              }
            }
          }
        }
      }
    });

    this.setCurrent(newCurrentProject);
  }

  updateWinner(projectSlug, areaId, supplierId, isRewarded) {
    if (!this.current || this.current.slug != projectSlug) return;

    let areaIndex = this.current.area.findIndex(a => a.areaId._id == areaId);
    let supplierIndex = this.current.area[areaIndex].suppliers.findIndex(
      s => s.supplierId._id == supplierId
    );
    let newCurrentProject = update(this.current, {
      area: {
        [areaIndex]: {
          suppliers: { [supplierIndex]: { reward: { $set: isRewarded } } }
        }
      }
    });

    this.setCurrent(newCurrentProject);
  }
  async deleteProject(projectSlug) {
    const response = await axios.delete("/api/project/" + projectSlug, {});
    return response.data;
  }

  remove(id) {
    var foundIndex = this.storage.findIndex(val => {
      if (val._id == id) {
        return true;
      }
    });

    if (foundIndex > -1) {
      this.storage.splice(foundIndex, 1);
      btSort(this.storage, "name");
      this.storage = [...this.storage];
      this.data$.next(this.storage);
    }
  }

  async clearNotifications(projectId) {
    const response = await axios.delete("/api/project/conversation/notifications", { params: { projectId } });
    const foundIndex = this.storage.findIndex(val => val._id == projectId);
    if (foundIndex > -1) {
      this.storage[foundIndex].notification = 0;
      this.storage = [...this.storage];
      this.data$.next(this.storage);
    }
    return response.data;
  }

  async clearAllNotifications() {
    const response = await axios.delete("/api/project/conversation/notifications/all");
    const storage = this.storage.map(val => ({ ...val, notification: 0 }));
    this.data$.next(this.storage);
    return response.data;
  }

  updateStepInArea(projectSlug, areaId, step) {
    if (!this.current || this.current.slug != projectSlug) return;

    let areaIndex = this.current.area.findIndex(a => a.areaId._id == areaId);
    let newCurrentProject = update(this.current, {
      area: { [areaIndex]: { step: { $set: step } } }
    });

    this.setCurrent(newCurrentProject);
  }
  updateEndDateInArea(projectSlug, areaId, dateEnd) {
    if (!this.current || this.current.slug != projectSlug) return;

    let areaIndex = this.current.area.findIndex(a => a.areaId._id == areaId);
    let newCurrentProject = update(this.current, {
      area: { [areaIndex]: { endDate: { $set: dateEnd } } }
    });

    this.setCurrent(newCurrentProject);
  }

  updateBudgetInArea(projectSlug, areaId, budget) {
    if (!this.current || this.current.slug != projectSlug) return;

    let areaIndex = this.current.area.findIndex(a => a.areaId._id == areaId);
    let newCurrentProject = update(this.current, {
      area: { [areaIndex]: { budget: { $set: budget } } }
    });

    this.setCurrent(newCurrentProject);
  }

  addAreaMember(projectId, areaId, member) {
    if (!this.current || this.current._id != projectId) return;

    let areaIndex = this.current.area.findIndex(a => a.areaId._id == areaId);
    const newCurrentProject = this.immer.produce(this.current, draft => {
      draft.area[areaIndex].areaId.members.push(member);
    });

    this.setCurrent(newCurrentProject);
  }

  removeAreaMember(projectId, areaId, userId) {
    if (!this.current || this.current._id != projectId) return;

    let areaIndex = this.current.area.findIndex(a => a.areaId._id == areaId);
    const newCurrentProject = this.immer.produce(this.current, draft => {
      draft.area[areaIndex].areaId.members = draft.area[areaIndex].areaId.members.filter(member => member._id !== userId);
    });

    this.setCurrent(newCurrentProject);
  }

  addContract(projectId, areaId, contract) {
    if (!this.current || this.current._id != projectId) return;

    let areaIndex = this.current.area.findIndex(a => a.areaId._id == areaId);
    const newCurrentProject = this.immer.produce(this.current, draft => {

      if (!draft.area[areaIndex].contracts) {
        draft.area[areaIndex].contracts = [contract];
      } else {
        draft.area[areaIndex].contracts.push(contract);
      }
    });

    this.setCurrent(newCurrentProject);
  }

  addManualContract(projectId, areaId, response) {
    if (!this.current || this.current._id != projectId) return;
    const areaIndex = this.current.area.findIndex(a => a.areaId._id == areaId);
    
    const newCurrentProject = this.immer.produce(this.current, draft => {
      const area = draft.area[areaIndex];
      if (!area.manualContract) {
        area.manualContract = [response]
      } else {
        area.manualContract.push(response)
      }
    });
    this.setCurrent(newCurrentProject);
  }

  deleteManualContract(projectId, areaId, manualContractFilePath) {
    if (!this.current || this.current._id != projectId) return;
    const areaIndex = this.current.area.findIndex(a => a.areaId._id == areaId);

    const newCurrentProject = this.immer.produce(this.current, draft => {
      draft.area[areaIndex].manualContract = draft.area[areaIndex].manualContract.filter(contract => contract.file !== manualContractFilePath);
    })
    this.setCurrent(newCurrentProject);
  }

  deleteContract(projectId, areaId, documentId) {
    if (!this.current || this.current._id != projectId) return;

    let areaIndex = this.current.area.findIndex(a => a.areaId._id == areaId);
    const contract = this?.current?.area[areaIndex]?.contracts?.find(contract => contract?.documentId === documentId);

    if (contract) {
      const newCurrentProject = this.immer.produce(this.current, draft => {
        draft.area[areaIndex].contracts = draft.area[areaIndex].contracts.filter(contract => contract?.documentId !== documentId);
      })
      this.setCurrent(newCurrentProject);
    } 
    //Move bid card if no signed or manual contract exists!!! 
    let area = this.current.area[areaIndex];
    if (!area?.contracts?.find(contract => contract?.lastStatus === "closed") && !area?.manualContract?.length && area?.step == 4) {
      this.dbChangeStep(this.current.slug, areaId, 3);
      this.updateStepInArea(this.current.slug, areaId, 3);
    }    
  }

  replaceAreaNotes(projectSlug, areaNotes) {
    if (!this.current || this.current.slug != projectSlug || !areaNotes) return;

    let newCurrentProject = this.current;

    for (let i = 0; i < areaNotes.length; i++) {
      const an = areaNotes[i];

      let areaIndex = newCurrentProject.area.findIndex(
        a => a.areaId._id == an.areaId
      );
      newCurrentProject = update(newCurrentProject, {
        area: { [areaIndex]: { note: { $set: an.note } } }
      });
    }

    this.setCurrent(newCurrentProject);
  }

  // updateAreaEndDate(project, area, endDate) {
  //   var foundIndex = this.storage.findIndex((val) => {
  //     if(val.slug == project.slug) {
  //       return true;
  //     }
  //   });

  //   if (foundIndex > -1) {
  //     let projectAreas = project.area;
  //     let areaIndex = projectAreas.findIndex((val) => val._id == area._id);
  //     let newProjectAreas = update(projectAreas, {[areaIndex]: {endDate: {$set: endDate}}});

  //     this.storage[foundIndex].area = newProjectAreas;
  //     this.replace(this.storage);

  //     if (this.current && this.current.slug == projectSlug) {
  //       this.current.area = newProjectAreas;
  //       this.setcurrent(this.current);
  //     }
  //   }
  // }

  clear() {
    this.replace([]);
    this.setCurrent({ areas: [] });
  }

  async dbAdd(data) {
    return await post(apiUrl + "/projects", data).then(data => {
      return data;
    });
  }

  async dbAddArea(projectSlug, areaId) {
    const response = await axios.post("/api/project/" + projectSlug + "/area", {
      areaId
    });
    return response.data;
  }

  async dbUpdateAreaBudge(projectSlug, areaId, budget) {
    const response = await axios.put(
      "/api/project/" + projectSlug + "/budget",
      {
        areaId,
        budget
      }
    );
    return response.data;
  }

  async dbUpdateEndDate(projectSlug, areaId, endDate) {
    const response = await axios.put(
      "/api/project/" + projectSlug + "/enddate",
      {
        areaId,
        endDate
      }
    );
    return response.data;
  }

  async dbChangeStep(projectSlug, areaId, step) {
    const response = await axios.put(
      "/api/project/" + projectSlug + "/area/step",
      {
        areaId,
        step
      }
    );
    return response.data;
  }

  async dbUpdateCalled(projectSlug, areaId, supplierId, iscalled) {
    const response = await axios.put("/api/project/" + projectSlug + "/call", {
      areaId,
      supplierId,
      isCalled: iscalled
    });
    let data = {
      projectSlug,
      areaId,
      supplierId,
      data: {type: "isCalled", value: iscalled}
    }
    await this.updateDataLocally(data);
    return response.data;
  }

  async unlockBidForm(projectSlug, areaId, supplierId, currentVersion) {
    const response = await axios.put(`/api/project/${projectSlug}/area/${areaId}/supplier/${supplierId}/version/${currentVersion}/unlock`, {});
    return response.data;
  }

  async dbUpdateMeeting(projectSlug, areaId, supplierId, meeting) {
    const response = await axios.put(
      "/api/project/" + projectSlug + "/meeting",
      {
        areaId,
        supplierId,
        meeting
      }
    );
    let data = {
      projectSlug,
      areaId,
      supplierId,
      data: {type: "meeting", value: meeting}
    }
    await this.updateDataLocally(data);
    return response.data;
  }

  async dbUpdateCallDetail(projectSlug, areaId, supplierId, callDetail) {
    const response = await axios.put(
      "/api/project/" + projectSlug + "/call-detail",
      {
        areaId,
        supplierId,
        callDetail
      }
    );
    let data = {
      projectSlug,
      areaId,
      supplierId,
      data: {type: "callDetails", value: response.data.info}
    }
    await this.updateDataLocally(data);
    return response.data;
  }

  async dbUpdateSupplierDate(projectSlug, areaId, supplierId, suppData) {
    const { date, emailText, version, currentVersion } = suppData;
    const response = await axios.put(
      "/api/project/" + projectSlug + "/supplier/date",
      {
        areaId,
        supplierId,
        date,
        emailText,
        version,
        currentVersion
      }
    );
    return response.data;
  }

  async dbRewardSupplier(projectSlug, areaId, supplierId, reward) {
    const response = await axios.put(
      "/api/project/" + projectSlug + "/reward",
      {
        areaId,
        supplierId,
        reward: reward
      }
    );
    return response.data;
  }

  async removeProjectArea(projectSlug, areaId) {
    const response = await axios.delete(
      "/api/project/" + projectSlug + "/area",
      { data: { areaId: areaId } }
    );
    return response.data;
  }

  async dbAddSuppliers(projectSlug, areaId, supplierIds, data) {
    const response = await axios.put(
      `/api/project/${projectSlug}/area/supplier`,
      {
        areaId,
        supplierIds,
        data
      }
    );
    return response.data;
  }
  async dbRemoveSupplier(projectSlug, areaId, supplierId) {
    const response = await axios.delete(
      `/api/project/${projectSlug}/area/${areaId}/supplier/${supplierId}`
    );
    return response.data;
  }

  async dbDeleteContract(projectSlug, areaSlug, documentId) {
    const response = await axios.delete(`/api/project/${projectSlug}/area/${areaSlug}/contract/${documentId}`);
    return response.data;  
  }

  addProjects(projects) {
    if (projects instanceof Array) {
      this.storage = projects;
    }
  }

  /**
   * addProject
   * @description add project
   * @static
   * @param {Object} data - add values
   * @returns {Object.<service>} -
   * @memberOf Service
   */
  async addProject(data) {
    const response = await axios.post("/api/project", data);
    return response.data;
  }

  /**
   * Update Project
   * @description update project
   * @static
   * @param {Object} data - add values
   * @returns {Object.<service>} -
   * @memberOf Service
   */
  async updateProject(data) {
    const response = await axios.put("/api/project/" + data.slug, data);
    return response.data;
  }

  /**
   * getProjects
   * @description Get projects
   * @static
   * @returns {Object.<projects>} -
   * @memberOf Service
   */
  async getProject(slug) {
    const response = await axios.get(`/api/projects/${slug}`);
    return response.data;
  }

  /**
   * getProjects
   * @description Get projects
   * @static
   * @returns {Object.<projects>} -
   * @memberOf Service
   */
  async getProjectList() {
    const response = await axios.get("/api/company/projects");
    return response.data;
  }

  async getCounter() {
    try{
      const response = await axios.get("/api/project/notification/counter");
      return response.data;
    } catch(e){
      console.log(e, "Error::::")
    }
  }
  async getSupplierMembers(supplierId, includeDeleted) {
    const response = await axios.get("/api/supplier/member", {
      params: { 
        supplierId, 
        ...(includeDeleted != undefined ? {includeDeleted} : {})  
      } 
    });
    return response.data;
  }

  async getConversationMembers(projectId, areaId, supplierId ) {
    const response = await axios.get("/api/project/conversation/suppliermembers", {
      params: { projectId, areaId, supplierId, } 
    });
    return response.data;
  }

  async getConversation(supplierId, projectId, supplierMemberId, areaId) {
    console.log({ supplierId, projectId, supplierMemberId, areaId })
    const response = await axios.get("/api/project/conversation/supplier", {
      params: { supplierId, projectId, supplierMemberId, areaId }
    });
    return response.data;
  }

  async postAdminMessage(
    projectId,
    supplierId,
    supplierMemberId,
    message,
    fileData,
    areaId
  ) {
    const data = {
      projectId,
      supplierId,
      supplierMemberId,
      message,
      fileData,
      areaId
    };
    const formData = new FormData();

    for (let key in data) {
      if (key === "fileData") {
        for (const file of data.fileData) {
          formData.append(key, file);
        }
      } else {
        formData.append(key, data[key]);
      }
    }

    const response = await axios.put("/api/conversation/message", formData);
    return response.data;
  }
  async postSupplierMessage(
    projectId,
    supplierId,
    supplierMemberId,
    message,
    fileData,
    areaId
  ) {
    const data = {
      projectId,
      supplierId,
      supplierMemberId,
      message,
      fileData,
      areaId
    };
    const formData = new FormData();

    for (let key in data) {
      if (key === "fileData") {
        for (const file of data.fileData) {
          formData.append(key, file);
        }
      } else {
        formData.append(key, data[key]);
      }
    }

    const response = await axios.put(
      "/api/conversation/supplier/message",
      formData
    );
    return response.data;
  }

  async verifyURL(accessToken, orgSlug) {
    const response = await axios.get("/api/project/conversation/verify", {
      params: { accessToken, orgSlug }
    });
    return response.data;
  }

  /**
   * getProjects
   * @description Get projects
   * @static
   * @returns {Object.<projects>} -
   * @memberOf Service
   */
  async getProjectMembers(projectSlug) {
    const response = await axios.get(
      "/api/project/" + projectSlug + "/members",
      {}
    );
    return response.data;
  }

  async dbUpdateProjectStep(projectSlug, newStep) {
    const response = await axios.put("/api/project/" + projectSlug + "/step", {
      step: newStep
    });
    return response.data;
  }
  async dbUpdateProjectCuurentStep(projectSlug, newStep) {
    const response = await axios.put(
      "/api/project/" + projectSlug + "/current/step",
      {
        step: newStep
      }
    );
    return response.data;
  }

  replaceNotesBySlug(projectSlug, newNotes) {
    var foundIndex = this.storage.findIndex(val => {
      if (val.slug == projectSlug) {
        return true;
      }
    });

    if (foundIndex > -1) {
      this.storage[foundIndex].notes = newNotes;
    }

    this.updateProjectProps("notes", newNotes);
  }

  replaceIsOpenBySlug(projectSlug, status) {
    var foundIndex = this.storage.findIndex(val => {
      if (val.slug == projectSlug) {
        return true;
      }
    });

    if (foundIndex > -1) {
      this.storage[foundIndex].isOpen = status;
    }
    this.replace(this.storage);

    this.updateProjectProps("isOpen", status);
  }

  replaceStrategyBySlug(projectSlug, strategy) {
    this.updateProjectProps("strategy", strategy);
  }

  updateProjectProps(propName, value) {
    if (!this.current) return;

    const newCurrent = update(this.current, { [propName]: { $set: value } });
    this.setCurrent(newCurrent);
  }

  async projectBySlug(slug) {
    const response = await axios.get(`/api/project/${slug}`);
    return response.data;
  }
  
  async getProjectBySlugForSupplier(slug, supplierId) {
    const response = await axios.get(`/api/project/${slug}/supplier/${supplierId}`);
    return response.data;
  }

  changeSupplierDate(projectSlug, areaSlug, supplierId, date) {
    if (!this.current || this.current.slug != projectSlug) return;

    const areaIndex = this.current.area.findIndex(
      a => a.areaId.slug == areaSlug
    );
    const newCurrentProject = update(this.current, {
      area: {
        [areaIndex]: {
          suppliers: {
            $apply: suppliers => {
              return suppliers.map(supplier => {

                let current_Date = moment.utc();
                let exp_check = current_Date.isAfter(date);
                let extendDate;
                if (supplier.supplierId._id === supplierId) {
                  extendDate = date;
                }
                return update(supplier, {
                  date: {
                    $set: extendDate ? extendDate : supplier.date
                  },
                });
              });
            }
          }
        }
      }
    });
    this.setCurrent(newCurrentProject);
  }

  updateDataLocally(updatedData) {
    const {projectSlug, areaId, supplierId, data} = updatedData;
    if (!this.current || this.current.slug != projectSlug) return;
    const areaIndex = this.current.area.findIndex(
      a => a.areaId._id == areaId
    );
    const newCurrentProject = update(this.current, {
      area: {
        [areaIndex]: {
          suppliers: {
            $apply: suppliers => {
              return suppliers.map(supplier => {
                if (supplier.supplierId._id === supplierId) {
                  if(supplier[data.type] && supplier[data.type] instanceof Array) {
                    supplier[data.type] = [...supplier[data.type], data.value];
                  } else {
                    supplier[data.type] = [data.value];
                  }
                }
                return update(supplier, {
                  [data.type] : {
                    $set: supplier[data.type]
                  }
                });
              });
            }
          }
        }
      }
    });
    this.setCurrent(newCurrentProject);
  }

  markQuoteSent(projectSlug, areaSlug, quotation) {
    if (!this.current || this.current.slug != projectSlug) return;

    const areaIndex = this.current.area.findIndex(
      a => a.areaId.slug == areaSlug
    );

    const endDate = quotation.quotationExpiryDate;
    const newCurrentProject = update(this.current, {
      area: {
        [areaIndex]: {
          isQuoteSent: { $set: true },
          endDate: { $set: endDate },
          quotations: {
            $apply: quotations => {
              if (quotations) {
                quotations.push({ version: quotation.__v });
                return quotations;
              } else {
                return [{ version: quotation.__v }];
              }
            }
          },
          suppliers: {
            $apply: suppliers => {
              return suppliers.map(supplier => {
                let isCalled = supplier.isCalled;
                const quoteData = quotation.suppliers.filter(
                  f => f.supplierId == supplier.supplierId._id
                );
                if (!_.isEmpty(quoteData)) {
                  isCalled = quoteData[0].isCalled;
                }
                return update(supplier, {
                  isCalled: {
                    $set: isCalled
                  },
                  priceDetails: {
                    $set: quotation.priceDetails
                  }
                });
              });
            }
          }
        }
      }
    });

    this.setCurrent(newCurrentProject);
  }

  replaceStepBySlug(projectSlug, newStep) {
    this.updateProjectProps("step", newStep);
  }

  async getProjectTypes() {
    const response = await axios.get("/api/project-types");
    return response.data;
  }
  async saveProjectAreasNote(projectSlug, data) {
    const response = await axios.put("/api/project/" + projectSlug + "/note", {
      areas: data
    });
    return response.data;
  }

  async updateProjectOpenStatus(projectSlug, status) {
    const response = await axios.put(
      "/api/project/" + projectSlug + "/is_open",
      {
        isOpen: status
      }
    );
    return response.data;
  }

  async getVersionData(projectSlug, areaId) {
    const response = await axios.get("/api/project/quotation/version", {
      params: { projectSlug, areaId }
    });
    return response.data;
  }

  async getProjectAreaComments(projectSlug, areaSlug) {
    return await fetchConversation(`/project/${projectSlug}/areas/${areaSlug}/comments`);
  }

  async updateProjectAreaComments(projectSlug, areaSlug, content) {
    return await updateConversation(`/project/${projectSlug}/areas/${areaSlug}/comments`, content)
  }

  #updateProjectAreaField(field) {
    return async (projectSlug, areaSlug, data) => {
      const response = await axios.put(`/api/projects/${projectSlug}/areas/${areaSlug}/${field}`, {
        data
      });
  
      if (!this.current || this.current.slug != projectSlug || !response.data) return;
  
      let areaIndex = this.current.area.findIndex(a => a.areaId.slug == areaSlug);
      const newCurrentProject = this.immer.produce(this.current, draft => {
        draft.area[areaIndex][field] = data;
      });
  
      this.setCurrent(newCurrentProject);
      return response.data.data;
    }
  }

  async updateProjectAreaAnswersLatestBy(projectSlug, areaSlug, date) {
    return await (this.#updateProjectAreaField('answersLatestBy'))(projectSlug, areaSlug, date);
  }

  async updateProjectAreaProductionEndsAt(projectSlug, areaSlug, date) {
    return await (this.#updateProjectAreaField('productionEndsAt'))(projectSlug, areaSlug, date);
  }

  async updateProjectAreaQuotationSentBy(projectSlug, areaSlug, date) {
    return await (this.#updateProjectAreaField('quotationSentBy'))(projectSlug, areaSlug, date);
  }

  async updateProjectAreaFinalCost(projectSlug, areaSlug, cost) {
    return await (this.#updateProjectAreaField('finalCost'))(projectSlug, areaSlug, cost);
  }
  async updateProjectAreaIsPublic(projectSlug, areaSlug, cost) {
    return await (this.#updateProjectAreaField('isPublic'))(projectSlug, areaSlug, cost);
  }
  async updateProjectAreaEstimatedAdditionsPercent(projectSlug, areaSlug, value) {
    return await (this.#updateProjectAreaField('estimatedAdditionsPercent'))(projectSlug, areaSlug, value);
  }
  async updateProjectAreaPurchaseGoalPercent(projectSlug, areaSlug, value) {
    return await (this.#updateProjectAreaField('purchaseGoalPercent'))(projectSlug, areaSlug, value);
  }

  async updateAndAutocompleteFields(projectSlug, areaSlug, field, allFields) {
    if (field === 'purchaseCompleted') {
      const value = allFields.purchaseCompleted;
      await (this.#updateProjectAreaField('purchaseCompleted'))(projectSlug, areaSlug, value);

      const leadTime = allFields.leadTime;
      const productionStartsAt = allFields.productionStartsAt;

      if (value) {
        if ((leadTime || leadTime === 0) && !productionStartsAt) {
          const date = moment(value).add(leadTime, 'milliseconds').toDate();
          await (this.#updateProjectAreaField('productionStartsAt'))(projectSlug, areaSlug, date);
        } else if ((!leadTime && leadTime !== 0) && productionStartsAt) {
          const difference = moment(productionStartsAt).diff(moment(value), 'milliseconds');
          await (this.#updateProjectAreaField('leadTime'))(projectSlug, areaSlug, Math.floor(difference / (1000 * 60 * 60 * 24 * 7)) * 1000 * 60 * 60 * 24 * 7);
        }
      }
    } else if (field === 'leadTime') {
      const value = allFields.leadTime;
      await (this.#updateProjectAreaField('leadTime'))(projectSlug, areaSlug, value);

      const purchaseCompleted = allFields.purchaseCompleted;
      const productionStartsAt = allFields.productionStartsAt;

      if (value || value === 0) {
        if (purchaseCompleted && !productionStartsAt) {
          const date = moment(purchaseCompleted).add(value, 'milliseconds').toDate();
          await (this.#updateProjectAreaField('productionStartsAt'))(projectSlug, areaSlug, date);
        } else if (!purchaseCompleted && productionStartsAt) {
          const date = moment(productionStartsAt).subtract(value, 'milliseconds').toDate();
          await (this.#updateProjectAreaField('purchaseCompleted'))(projectSlug, areaSlug, date);
        }
      }
    } else if (field === 'productionStartsAt') {
      const value = allFields.productionStartsAt;
      await (this.#updateProjectAreaField('productionStartsAt'))(projectSlug, areaSlug, value);

      const purchaseCompleted = allFields.purchaseCompleted;
      const leadTime = allFields.leadTime;

      if (value) {
        if (purchaseCompleted && (!leadTime && leadTime !== 0)) {
          const difference = moment(value).diff(moment(purchaseCompleted), 'milliseconds');
          await (this.#updateProjectAreaField('leadTime'))(projectSlug, areaSlug, Math.floor(difference / (1000 * 60 * 60 * 24 * 7)) * 1000 * 60 * 60 * 24 * 7);
        } else if (!purchaseCompleted && (leadTime || leadTime === 0)) {
          const date = moment(value).subtract(leadTime, 'milliseconds').toDate();
          await (this.#updateProjectAreaField('purchaseCompleted'))(projectSlug, areaSlug, date);
        }
      }
    }
  }

  // async getQuotationVersion()
}

export const ServiceProjects = new ServiceProjectsController();
