import { FC, useEffect, useState } from "react";
import { serviceAreas } from "../../services/service-areas";
import { Loading } from "../../components/shared";

type Props = {
  slug: string,
}

const AverageAdditionsPercentForCategoryTooltip: FC<Props> = ({ slug }) => {
  const [name, setName] = useState("");
  const [percent, setPercent] = useState("");
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const response = await serviceAreas.getAverageAdditionsPercent(slug);
      setName(response.name);
      setPercent(response.percent);
      setCount(response.count);
      setLoading(false);
    })();
  }, [])

  if (loading) {
    return <div style={{ marginBlock: "1em" }}><Loading loadingStyle="bubbles" type='inline' color="#ff5e15" size='30px'></Loading></div>;
  } else if (!name) {
    return null;
  } else if (count == 0) {
    return <p>Kunde inte beräkna genomsnittet för {name}, inga inköp med kontraktsumma och slutkostnad hittades.</p>
  } else {
    return (
      <p>Genomsnittet för {name} är <span style={{ fontWeight: "500", color: "var(--gray-900)" }}>{percent}%</span> (baserat på {count} inköp).</p>
    );
  }
}

export default AverageAdditionsPercentForCategoryTooltip;