import moment from 'moment';
import { FC } from 'react';
import { default as ReactDatePicker, registerLocale } from 'react-datepicker';
import { ReactComponent as CalendarIcon } from '../../../assets/svg/calendarIcon2.svg';
import Field from '../Field/Field';
import styles from '../DatePicker/DatePicker.module.scss';
import sv from 'date-fns/locale/sv';
import 'react-datepicker/dist/react-datepicker.css';
import { parseISO } from 'date-fns';

registerLocale('sv', sv);

const formatDate = (date: string | undefined) => {
  return date && moment(date).locale('sv').format('D MMMM YYYY');
};

type Props = {
  selected?: Date,
  onChange: (value: Date) => void,
  label?: string,
  required?: boolean,
  errorMessage?: string,
  warningMessage?: string,
  fieldClassName?: string,
  isClearable?: boolean,
  onFocusStylesEnabled?: boolean,
  onFocusClassName?: string,
  readOnly?: boolean,
  [x:string]: any;
}

const DatePicker: FC<Props> = ({ selected, onChange, label, required, warningMessage, errorMessage, style, fieldClassName, isClearable, onFocusStylesEnabled, onFocusStylesClassName, isDisabled, readOnly, ...props }) => {
  const parsedDate = typeof selected === 'string' ? parseISO(selected) : selected;

  if (readOnly) {
    return (
      <div>
        {formatDate(parsedDate?.toString())}
      </div>
    )
  }

  return (
    <div>
      <ReactDatePicker
        locale="sv"
        selected={parsedDate}
        onChange={onChange}
        popperProps={{positionFixed: true}}
        isClearable={!isDisabled && isClearable}
        disabled={isDisabled}
        wrapperClassName={styles.datePicker}
        customInput={(
          <div>
            <Field
              label={label}
              required={required}
              warningMessage={warningMessage}
              errorMessage={errorMessage}
              onFocusStylesEnabled={onFocusStylesEnabled}
              onFocusClassName={onFocusStylesClassName}
              disabled={isDisabled}
              icon={<CalendarIcon className={styles.datePickerIcon} width='18px' />}
              value={selected ? moment(selected).locale('sv').format('D MMMM YYYY') : ''}
              style={style}
              className={`${fieldClassName} ${isClearable ? styles.isClearable : ''}`}
            />
          </div>
        )}
        popperPlacement='bottom-end'
        showWeekNumbers
        {...props}
      />
    </div>
  );
}

export default DatePicker;