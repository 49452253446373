import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  matchPath,
  NavLink
} from "react-router-dom";
import { CustomAvatar } from '../../components/shared';

import "./app-chat-header-clients.css";
import PropTypes from "prop-types";
import _ from "lodash";
import appState from '../../state/AppStateContainer';
import BtGrid from "../bt-grid/bt-grid";
import BtRow from "../bt-row/bt-row";
import BtColumn from "../bt-column/bt-column";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import MailIcon from '../../components/svgs/emailIcon';

const AppChatPerson = props => {
  return (
    <div className={`app-chat-person 
      ${!props.hasConversation ? 'has-conversation' : ''}
      ${props.small ? 'small' : ''}
      ${props.isDeleted ? 'isDeleted' : ''}`
    }>
      <BtGrid className="bt-contract">
        <BtRow>
          <BtColumn className="bt-vcenter">
            <CustomAvatar size={'30px'} alt={props.name} src={props.avatar} />
          </BtColumn>
          <BtColumn className="bt-vcenter">
            <div className="wrapper">
              <div className="head">{props.head}</div>
              <div className="body">{props.name} {props.hasConversation && <MailIcon></MailIcon>}</div>
              <div className="foot" style={{ marginTop: '2px' }}>{props.email}</div>
              <div className="foot" style={{ marginTop: '2px' }}>{props.foot}</div>
            </div>
          </BtColumn>
        </BtRow>
      </BtGrid>
    </div>
  );
}
export class AppChatHeaderClients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {showAll: false};
  }
  element;
  static contextTypes = {
    router: PropTypes.object
  };
  baseURL = this.props.baseURL;
  clients = this.props.clients;

  sortClients() {
    const result = [...this.props.clients];
    return result.sort((a,b) => {
      if (a.isActive != b.isActive) {
        return a.isActive ? -1 : 1;
      }
      if (a.hasConversation != b.hasConversation) {
        return a.hasConversation ? -1 : 1;
      }
      if (a.name != b.name) {
        return a.name < b.name ? -1 : 1;
      }
      return 0;
    });
  }

  getNotification = _id => {
    const { notification } = this.props;
    let notificationCount = 0;

    if (_id && notification && notification.length) {
      const result =
        notification.find(
          ({ supplierMemberId }) => supplierMemberId === _id,
        );

      if (result) notificationCount = result.count;
    }

    return notificationCount;
  }

  componentDidUpdate() {
    this.props.resizeScroller();
  }

  render() {    
    const orgSlug = appState.getCurrentOrgSlug();
    let url = _.get(this, "context.router.route.match.params");
    const renderPerson = (item, onClick) => {
      const notification = this.getNotification(item._id);
      return (
        <a
          onClick={onClick ?? (() => {
            if (url) {
              url = `/${orgSlug}/projects/${url.project}/${url.type}/${url.supplier}/${item.slug}`;
              this.context.router.history.push(url);
              this.setState({ showAll: false });
            }
          })}
          // to={this.props.baseURL}
          className={url.supplierMember === item.slug ? "link-active" : ""}
          key={item._id}
          style={{ position: 'relative' }}
        >
          <AppChatPerson
            hasConversation={item.hasConversation}
            small
            name=
            {
              item.name
              /* this.props.role !== Roles.Supplier
                ? item.name
                : `${this.props.adminUser.firstName} ${this.props.adminUser.lastName}` */
            }
            email={item.email}
            foot={
              item.phone
              /* this.props.role !== Roles.Supplier
                ? item.phone
                : `${this.props.adminUser.firstName} ${this.props.adminUser.lastName}` */
            }
            avatar={item.avatar}
            isDeleted={!item.isActive}
          />
          <span {...(notification > 0 ? { 'data-counter': notification } : {})} style={{ position: 'absolute', top: 0, left: -20 }} />
        </a>
      );
    }

    const selectedPerson = this.props.clients.find(item => url.supplierMember === item.slug);
    const isDropdown = this.props.clients.length > 4;
    if (isDropdown) {
      return (
        <div className="app-chat-header-clients">
          {this.state.showAll ? (
            <>
              {selectedPerson && renderPerson(selectedPerson)}
              <a onClick={() => this.setState({ showAll: false })}><ExpandLessIcon></ExpandLessIcon></a>
              <br/>
              {this.sortClients().map(item => renderPerson(item))}
            </>
          ) : (
            <>
              {selectedPerson && renderPerson(selectedPerson, () => this.setState({ showAll: true }))}
              <a onClick={() => this.setState({ showAll: true })}><ExpandMoreIcon></ExpandMoreIcon></a>
            </>
          )}
        </div>
      );
    } else {
      return (
        <div className="app-chat-header-clients">
          {this.sortClients().map(item => renderPerson(item))}
        </div>
      );
    }
  }
}
