import { CSSProperties, FC } from "react";

type Props = {
  tabs: string[],
  tooltips?: string[],
  selected: number,
  onChange: (value: number) => void,
  style?: CSSProperties,
}

const Tabs: FC<Props> = ({ tabs, tooltips = [], selected, onChange, children, style }) => {
  return <div className="tabs" style={style}>
    {tabs.map((tab,i) => {
      return <div title={tooltips[i]} key={i} className={selected == i ? "tab selected" : "tab"} onClick={() => onChange(i)}>{tab}</div>
    })}
    <div className="children">
      {children}
    </div>
  </div>;
}

export default Tabs;
