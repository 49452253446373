import { FC } from "react";
import DatePicker from "../../components/shared/DatePicker/DatePicker";
import Field from "../../components/shared/Field/Field";
import ImagePreview from "../../components/shared/ImagePreview/ImagePreview";
import appState from "../../state/AppStateContainer";
import { FileScope } from "../../util/appEnum";
import BtButton from "../bt-button/bt-button";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import moment from "moment";
import { FeaturedAdInfo } from "../page-bid-request-list/types";
import { ErrorMessageIcon } from "../../components/shared/ErrorMessageIcon/ErrorMessageIcon";
import styles from "./featured-supplier-ad-form.module.scss";

type AdImageProps = {
  organizationNumber: string,
  onRemove: () => void,
  onChange: React.ChangeEventHandler<HTMLInputElement>,
  featuredInfo: FeaturedAdInfo,
}

const AdImage: FC<AdImageProps> = ({ organizationNumber, featuredInfo, onRemove, onChange }) => {
  return (
    <div>
      <p style={{ marginTop: 0, marginBottom: '7px', fontSize: '14px', fontWeight: 500, color: 'var(--gray-700)' }}>Ad bild <span style={{ color: 'var(--orange-500)' }}>*</span></p>
      <ImagePreview
        image={featuredInfo?.image as File}
        fileId={(featuredInfo?.image as { file: string; fileName: string; })?.file?.split(".")?.slice(0, -1)?.join(".")}
        scope={FileScope.FeaturedAdImage}
        scopeOptions={{ organizationNumber }}
        orgSlug={appState.getCurrentOrgSlug()}
        onRemove={onRemove}
        width='250px'
      />
      {(!featuredInfo?.image) && (
        <label style={{ letterSpacing: 'normal' }}>
          <BtButton 
            color="white"
            size="xxs"
            leftIcon={<AddCircleIcon htmlColor="var(--orange-500)"/>}
            style={{ width: 'fit-content' }}
          >
            {`Lägg till bild`}
            <input
              type="file"
              name={'featuredInfoImage'}
              accept='image/png, image/gif, image/jpeg'
              onClick={event => {
                event.currentTarget.value = '';
              }}
              onChange={onChange}
              style={{ display: 'none' }}
            />
          </BtButton>
          {featuredInfo?.errors?.image && !featuredInfo.image && <ErrorMessageIcon style={{ marginLeft: '8px' }} errorMessage={featuredInfo?.errors?.image} />}
        </label>
      )}
    </div>
  )
}

type Props = {
  featuredInfo: FeaturedAdInfo,
  setState: (getUpdatedState: (x: FeaturedAdInfo) => FeaturedAdInfo) => void,
  organizationNumber: string,
  onRemoveImage: () => void,
  onChangeImage: React.ChangeEventHandler<HTMLInputElement>,
}
const FeaturedSupplierAdForm: FC<Props> = ({ organizationNumber, featuredInfo, setState, onRemoveImage, onChangeImage }) => {
  return (
    <div className={styles.featuredSupplierOptionsAdApplications}>
      <DatePicker
        label='Önskat startdatum'
        selected={featuredInfo?.startDate}
        maxDate={featuredInfo?.endDate && moment(featuredInfo?.endDate).toDate()}
        onChange={(value) => setState((data: FeaturedAdInfo) => ({...data, startDate: value}))}
      />
      {<AdImage
        featuredInfo={featuredInfo}
        organizationNumber={organizationNumber}
        onRemove={onRemoveImage}
        onChange={(e) => {
          setState((data: FeaturedAdInfo) => ({...data, errors: {...data.errors, image: '',}}))
          onChangeImage(e);
        }}
      />}
      <Field
        label='Titel'
        value={featuredInfo?.title}
        errorMessage={featuredInfo?.errors?.title}
        required
        onChange={(value) => setState((data: FeaturedAdInfo) => ({...data, title: value, errors: {...data.errors, title: '',}}))}
      />
      <Field
        label='Beskrivning'
        multiline
        value={featuredInfo?.description}
        onChange={(value) => setState((data: FeaturedAdInfo) => ({...data, description: value}))}
      />
      <Field
        label='URL'
        value={featuredInfo?.url}
        onChange={(value) => setState((data: FeaturedAdInfo) => ({...data, url: value}))}
      />
    </div>
  )
}

export default FeaturedSupplierAdForm;