export default function CentralAgreementIcon({size = '20px', color = '#047857', style, className, innerRef}) {
  return (
    <svg width={size} height={size} style={style} className={className} ref={innerRef} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M13.8809 17.06C13.8809 17.7376 13.3306 18.2879 12.653 18.2879H2.32854C1.65092 18.2879 1.10062 17.7376 1.10062 17.06V2.89162C1.10062 2.214 1.65092 1.66369 2.32854 1.66369H9.60575V3.44603C9.60575 4.73145 10.6489 5.77457 11.9343 5.77457H13.8809V8.74377C13.8973 8.71913 13.9138 8.69039 13.9261 8.66574C13.9548 8.62057 13.9877 8.56718 14.0246 8.50969C14.3244 8.01687 14.8008 7.22427 14.8953 7.07232C14.9199 7.02714 14.9487 6.98607 14.9774 6.9409V5.44193C14.9774 5.29819 14.9487 5.15856 14.8953 5.03125C14.8378 4.89983 14.7598 4.78484 14.6571 4.68628L10.6858 0.866977C10.5873 0.772521 10.4723 0.698599 10.3491 0.649318C10.2218 0.600037 10.0903 0.571289 9.95482 0.571289H2.32854C1.04312 0.571289 0 1.61441 0 2.89983V17.0682C0 18.3536 1.04312 19.3968 2.32854 19.3968H12.653C13.9384 19.3968 14.9815 18.3536 14.9815 17.0682V14.3495C14.6283 14.8259 14.2628 15.2982 13.885 15.7581V17.06H13.8809Z" fill={color} />
        <path d="M19.771 6.84679L19.4589 6.66609L18.9537 6.37451C19.0359 6.1856 19.1098 5.99258 19.1673 5.79545C19.1837 5.72975 19.2042 5.66814 19.2166 5.60244C19.3069 5.1548 19.1509 4.84679 18.7032 4.57985C18.3172 4.34987 18.0133 4.37451 17.6642 4.66198C17.426 4.85911 17.233 5.0973 17.0523 5.34371C16.3952 6.23899 15.8367 7.19997 15.2535 8.14042L14.5472 9.31496C13.7833 10.6086 13.0646 11.9269 12.4116 13.2821C11.9804 14.1733 11.5739 15.0768 11.2864 16.0295C11.2576 16.161 11.2207 16.2965 11.2084 16.432C11.1919 16.5881 11.1344 16.7811 11.3028 16.8796C11.4671 16.9741 11.6108 16.8427 11.7422 16.7482C12.0954 16.4936 12.3911 16.1774 12.6745 15.8529C14.0585 14.2718 15.2371 12.547 16.3706 10.7811L17.2617 9.35192C17.6437 8.69894 18.042 8.05829 18.4199 7.40531C18.4897 7.28211 18.5636 7.1548 18.6334 7.03159L19.1016 7.30264L16.8182 11.2328C16.6457 11.5326 16.7977 11.931 17.1673 12.0172C17.2001 12.0254 17.2371 12.009 17.2535 11.9803L19.9188 7.38888C20.0215 7.19586 19.9599 6.95767 19.771 6.84679Z" fill={color} />
        <path fillRule="evenodd" clipRule="evenodd" d="M7.69479 5L4.30119 13.994H2.73332L6.14756 5H7.69479ZM11 13.9997L8.79777 8.11181H7.24022L8.35527 11.1141H6.13713V12.5434H8.88614L9.42698 13.9997H11Z" fill={color} />
      </g>
    </svg>
  );
}