import { FC } from "react"
import styles from './GeneralTab.module.scss';
import { TEMPLATE_OPTION_IDS } from "../../helper";

type Props = {
  templateItemRenderer: (id: TEMPLATE_OPTION_IDS) => JSX.Element,
}

const GeneralTab: FC<Props> = ({ templateItemRenderer }) => {
  return (
    <div className={styles.tabContainer}>
      <div className={styles.container}>
        {templateItemRenderer('4')}
        {/* {templateItemRenderer('15')} */}
        {templateItemRenderer('16')}
        {templateItemRenderer('17')}
        {templateItemRenderer('18')}
        {templateItemRenderer('19')}
        {templateItemRenderer('21')}
        {templateItemRenderer('35')}
      </div>
      <div className={styles.chartContainer}>
        {templateItemRenderer('22')}
        {templateItemRenderer('34')}
      </div>
    </div>
  )
}

export default GeneralTab;