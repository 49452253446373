import Radio from '@material-ui/core/Radio';
import { FC, ReactNode } from 'react';
import styles from './RadioButton.module.scss';

type Props = {
  checked: boolean;
  onChange: (event: { target: { checked: boolean; value: string } }) => void;
  label: ReactNode;
  value: string;
};

const RadioButton: FC<Props> = ({ checked, onChange, label, value }) => {
  return (
    <div>
      <label className={styles.radioBtnContainer}>
        <Radio
          edge="start"
          checked={checked}
          onChange={onChange}
          tabIndex={-1}
          disableRipple
          checkedIcon={<span className={`${styles.icon} ${styles.checkedIcon}`} />}
          icon={<span className={styles.icon} />}
          value={value}
        />
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
