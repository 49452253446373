import { useState, VFC } from 'react';
import Radio from '@material-ui/core/Radio';
import { validateEmail } from '../../util';
import styles from './styles.module.scss';
import Modal from '../../components/shared/Modal/Modal';
import Field from '../../components/shared/Field/Field';

const DEFAULT_MEMBER = {
  _id: '',
  name: '',
  email: '',
  phone: '',
  title: '',
};

type UserType = {
  _id: string;
  name: string;
  phone?: string;
  email: string;
  title?: string;
};
type ErrorsType = {
  name?: string;
  email?: string;
}

type Props = {
  users: UserType[];
  onSubmit: (data: any) => void,
  show: boolean,
  setShow: (value: boolean) => void,
  title: string,
  recipients: UserType[],
  primaryButtonLabel?: string,
};

const SelectOrAddSupplierContactModal: VFC<Props> = ({
  users = [],
  onSubmit,
  show,
  setShow,
  title,
  recipients,
  primaryButtonLabel,
}) => {
  const [checked, setChecked] = useState<UserType>(DEFAULT_MEMBER);
  const [newMember, setNewMember] = useState<UserType>({
    _id: 'newContactPerson',
    name: '',
    email: '',
  });
  const [errors, setErrors] = useState<ErrorsType>({});
  const [btnLoader, setBtnLoader] = useState<boolean>(false);

  const onSubmitHandler = () => {
    if (isMemberSelected()) {
      if (!areFieldsValid()) {
        return;
      }
    }
    setBtnLoader(true);
    let memberData = checked;
    if (checked?._id) {
      if (isNewContact(checked?._id)) {
        memberData = newMember as UserType;
        if (recipients.find(x => x.email.trim() === memberData.email)) {
          setErrors((prevState) => ({ ...prevState, email: 'E-postadressen finns redan bland mottagarna.'}));
          setBtnLoader(false);
          return;
        }
      }
      
      onSubmit(memberData);
      setShow(false);
      setBtnLoader(false);
    }
  };

  const isMemberSelected = () => {
    return checked?._id && isNewContact(checked?._id);
  };

  const handleChange = (fieldName: keyof ErrorsType, fieldValue: string) => {
    setNewMember({ ...newMember, [fieldName]: fieldValue });
  };

  const inputField = (label: string, fieldName: keyof ErrorsType) => {
    return (
      <Field errorMessage={errors[fieldName]} label={label} value={newMember[fieldName]} onChange={(value) => handleChange(fieldName, value)} />
    );
  };

  const areFieldsValid = () => {
    const name = newMember.name?.trim();
    const email = newMember.email?.trim();

    const errorMessages = {
      name: !name ? 'Detta fält är obligatoriskt' : '',
      email: validateEmail(email)
    }

    setErrors(errorMessages);

    return Object.values(errorMessages).every(v => v === '');
  };

  const createUI = () => {
    return (
      <div className={styles.form} onClick={() => {
        setChecked(newMember);
        console.log('g')
      }}>
        <div className={styles.row}>
          {inputField('Namn', 'name')}
          {inputField('E-post', 'email')}
        </div>
      </div>
    );
  };

  const isNewContact = (memberId: string) => {
    return memberId === 'newContactPerson';
  }

  return (
    <Modal
      hasCloseButton={false}
      title={title}
      show={show}
      setShow={setShow}
      buttonInfo={{
        label: primaryButtonLabel || 'Dela',
        action: onSubmitHandler,
        loaderShow: btnLoader,
        disabled: btnLoader || !checked?._id,
      }}
      isScrollable
    >
      <div className={styles.container}>
        <>
          {[...(users.filter(supMember => !recipients.find(recipient => recipient.email === supMember.email))), newMember]?.map(supMember => {
            const supMemberId = supMember?._id;
            const selected = checked?._id === supMemberId;
            const labelId = `checkbox-list-label-${supMemberId}`;
            const memberName =
            isNewContact(supMemberId) ? 'Annan mottagare' : supMember?.name;
            return (
              <div
                className={`
          ${selected ? styles.selected : ''}
          ${styles.rowContainer} 
          ${isNewContact(supMemberId) ? styles.noBorder : ''}
          `}
                key={supMemberId}
                onClick={() => {
                  setChecked(supMember as UserType);
                }}
              >
                <div className={styles.radioIcon}>
                  <Radio
                    edge="start"
                    checked={selected}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                    checkedIcon={<span className={`${styles.icon} ${styles.checkedIcon}`} />}
                    icon={<span className={styles.icon} />}
                    className={styles.sharingModal}
                  />
                </div>
                <div className={styles.row}>
                  <div className={styles.column}>
                    <span className={styles.head}>{memberName}</span>
                    <span className={styles.subHead}>{isNewContact(supMemberId) ? '' : supMember?.email}</span>
                  </div>
                  <div className={`${styles.column} ${styles.textAlignRight}`}>
                    <span className={styles.head}>{supMember?.title}</span>
                    <span className={styles.subHead}>{supMember?.phone}</span>
                  </div>
                </div>
              </div>
            );
          })}
          <div
            className={`${
              !isNewContact(checked?._id) ? styles.notSelectedNewUser : styles.activeNewMemberForm
            }`}
          >
            {createUI()}
          </div>
        </>
      </div>
    </Modal>
  );
};

export default SelectOrAddSupplierContactModal;
