import React, { useState } from 'react';
import _ from 'lodash';
import { ServiceAuth } from '../../services/service-auth';
import appState from '../../state/AppStateContainer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { NotificationIcon, SettingsIcon, LogoutIcon } from '../../components/svgs';
import './styles.css';
import { CustomAvatar, Loading } from '../../components/shared';
import { ServiceUser } from '../../services/service-user';
import CheckIcon from '@material-ui/icons/Check';
import { withRouter } from 'react-router';
import Field from '../../components/shared/Field/Field';
import { SearchIcon } from '../../components/svgs';

const OrganizationSwitcher = ({ history }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [loader, toggleLoader] = useState(false);
  const [orgSlug, setOrgSlug] = useState();
  const [avatarUrl, setAvatarUrl] = useState();
  const [isFetched, setFetched] = useState(false);
  const [search, setSearch] = useState("");

  const organizations = appState.getOrganizations()
    .sort((a,b) => a.name.localeCompare(b.name))
    .filter(org => org.name.toLowerCase().includes(search.toLowerCase()) || org.organizationNumber?.toLowerCase()?.includes(search.toLowerCase()));

  const hasMultipleOrgs = appState.getOrganizations().length > 1;

  const fetchOrganizations = async () => {
    await appState.fetchUpdatedOrganizations();
  };
  React.useEffect(() => {
    setOrgSlug(appState._getCurrentOrgUrl());
  }, []);

  // const image = () => _.get(appState, 'state.token.avatarUrl');

  const userName = () => _.get(appState, 'state.token.name');
  const userEmail = () => _.get(appState, 'state.token.email');
  
  // const getAvatar = async () => {
  //   const userId = appState.getUserId();
  //   let data;
  //   if (userId) {
  //     setFetched(true)
  //     data = await ServiceUser.getUserAvatarById(userId);
   
  //     if (data) {
  //       appState.setAvatarUrl(data.avatarUrl);
  //       setAvatarUrl(data.avatarUrl);
  //       setFetched(false)
  //     }
  //   }
  // };
  React.useEffect(() => {
    function handleClickEvent(e) {
      const wrapperElement = document.getElementById('organization-switcher-ddId');
      if (wrapperElement && !wrapperElement.contains(e.target) && showOptions) {
        setShowOptions(false);
      }
    }

    document.addEventListener('click', handleClickEvent);
    
    const orgSlug = appState._getCurrentOrgUrl();
    if (
      orgSlug === '/superadmin' &&
      appState.isSuperAdmin() &&
      (
        history.location.pathname.includes('/projects') ||
        history.location.pathname.includes('/tasks'))
    ) {
      return history.push('/');
    }

    return () => document.removeEventListener('click', handleClickEvent);
  }, [showOptions]);

  // React.useEffect(() => {
  //   const alreadyFetched = appState.getAvatarFetchedState();
  //   if (!alreadyFetched){
  //     const url = appState.getAvatarUrl();
  //     setAvatarUrl(url);
  //   }
  // });

  const onToggleSwitcher = async () => {
    if (!showOptions) {
      toggleLoader(true);
      await fetchOrganizations();
      toggleLoader(false);
    }
    setShowOptions(!showOptions);
    setSearch("");
  };

  const onSelectOrganization = async org => {
    const currentOrg = appState.getCurrentOrgSlug();
    if (currentOrg === org.slug) return;

    setShowOptions(false);
    let [, , endPoint] = window.location.pathname.split('/');
    const origin = window.location.origin;

    let updatedPath = `${origin}/${org.slug}/${endPoint}`;
    if (currentOrg === 'superadmin' || org.slug === 'superadmin') {
      updatedPath = `${origin}/${org.slug}/dashboard`;
    }

    const res = await appState.fetchAndSetToken(org);
    window.location = updatedPath;
  };

  const onSettings = () => {
    setShowOptions(false);
    history.push(`${orgSlug}/settings`);
  };

  return (
    <div className="organization-switcher">
      <div className="sub-organization-switcher">
        <div className="avatar-dropdown" id="organization-switcher-ddId" onClick={onToggleSwitcher}>
          <div>
            {/* <CustomAvatar
              alt={appState.getCurrentOrgName()}
              src={"/api/organizations/" + appState.getCurrentOrgSlug() + "/logo"}
              className="org-switcher-avatar"
            /> */}
            <span className="instance-name">{appState.getCurrentOrgName()}</span>
          </div>
          <div className="organization-switcher-toggle">
            {!showOptions ? (
              <ExpandMoreIcon fontSize="small" />
            ) : (
              <ExpandLessIcon fontSize="small" />
            )}
          </div>
        </div>
      </div>
      <ul id="org-dropdown" className={`avatar-dropdown-options ${showOptions ? 'show' : ''}`}>
        <ul>
          {hasMultipleOrgs && (
            <li onClick={e => e.stopPropagation()}>
              <Field icon={<SearchIcon size={18} />} onChange={setSearch} value={search} ref={(ref) => {if (ref) ref.focus()}}/>
            </li>
          )}
          {loader ? (
            <Loading type="inline" />
          ) : (
            organizations.map(org => (
              <li
                key={org.slug}
                className={
                  'avatar-dropdown-option' +
                  (org.slug == appState.getCurrentOrgSlug() ? ' selected' : '')
                }
                onClick={() => onSelectOrganization(org)}
                title={org?.name?.length > 42 ? org?.name : ''}
              >
                {hasMultipleOrgs && (appState.isPurchasingActive(org)) && <span className="badge purchasing">Inköp</span>}
                {hasMultipleOrgs && (appState.isSalesActive(org)) && <span className="badge sales">Sälj</span>}
                {org.name}
                {org.slug == appState.getCurrentOrgSlug() && <CheckIcon />}
              </li>
            ))
          )}
        </ul>
        {appState.isAdminRole() && (
          <li className="avatar-dropdown-option iconed" onClick={onSettings}>
            <SettingsIcon />
            Inställningar
          </li>
        )}
      </ul>
    </div>
  );
}

export default withRouter(OrganizationSwitcher);