import { FC } from "react";
import moment from "moment"
import { Reason } from "../../models/supplier.model";
//import MessageLog from "../../components/shared/MessageLog/MessageLog";

type Props = {
  reasons : Reason[]
};

const SupplierBlockedReasons : FC<Props> = ({reasons}) => {
  return <div style={{ display: "flex", flexDirection: "column", gap: "16px", maxHeight: "45vh", overflow: "auto" }}>
    {reasons.map(reason => (
      <div className="reasonListItem" key={reason._id || (""+reason.date)}>
        <span className="reason-reason">
          {reason.reason}
        </span>
        <span className="reasonDate">
          {reason.action == "unblock" ? "Spärr borttagen" : (reason.action == "block" ? "Spärrad" : "Ändrad")}
          {reason.adminName && " av " + reason.adminName}
          {", "}
          {moment(reason.date).format('YYYY-MM-DD HH:mm')}
        </span>
      </div>
    ))}
  </div>;
};

export default SupplierBlockedReasons;