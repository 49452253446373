import React, { useState, VFC } from 'react';
import Radio from '@material-ui/core/Radio';
import { validateEmail, validateOptionalPhone, showSuccessNotification, showErrorNotification } from '../../../util';
import styles from './styles.module.scss';
import { ServiceQuotation } from '../../../services/service-quotation';
import { Loading } from '../../../components/shared';
import Modal from '../../../components/shared/Modal/Modal';
import appState from '../../../state/AppStateContainer';

const NEW_MEMBER = {
  _id: 'newContactPerson',
  name: '',
  email: '',
  phone: '',
  title: '',
};
const DEFAULT_MEMBER = {
  _id: '',
  name: '',
  email: '',
  phone: '',
  title: '',
};

type supplierMemberProps = {
  _id: string;
  name: string;
  phone: string;
  email: string;
  title: string;
};

type props = {
  supplierMembers: supplierMemberProps[];
  showRequestShareModal: boolean;
  fetchingSupplierMembers: boolean;
  allowNewContacts: boolean;
  onClose: () => void;
  getQuotationData: () => {
    quoteRefId: string;
    quoteVersionNo: number | string;
    supplierId: string;
    areaId: string;
    projectId: string;
  };
};

const QuoteSharingModal: VFC<props> = ({
  supplierMembers = [],
  showRequestShareModal,
  onClose,
  getQuotationData,
  fetchingSupplierMembers,
  allowNewContacts,
}) => {
  const [checked, setChecked] = useState<supplierMemberProps>(DEFAULT_MEMBER);
  const [newMember, setNewMember] = useState<any>(NEW_MEMBER);
  const [errors, setErrors] = useState<any>({});
  const [btnLoader, setBtnLoader] = useState<boolean>(false);

  const closeRequestShareModal = () => {
    setChecked(DEFAULT_MEMBER);
    setNewMember(NEW_MEMBER);
    setBtnLoader(false);
    onClose();
  };

  const onSubmit = async () => {
    if (!isMemberSelected() || Object.values(errors).some(v => v !== '')) return;
    setBtnLoader(true);
    const quotationData = getQuotationData();
    let memberData = checked;
    if (checked?._id) {
      if (checked?._id === 'newContactPerson') memberData = newMember;
      ServiceQuotation.shareQuotation({ ...quotationData, memberData })
        .then(response => {
          if (response.isSuccess) showSuccessNotification(response.message);
          closeRequestShareModal();
        })
        .catch(error => {
          const errorMessage = error?.response?.data?.message;
          showErrorNotification(errorMessage);
          setBtnLoader(false);
        });
    }
  };

  const isMemberSelected = () => {
    if (checked?._id) {
      if (checked?._id === 'newContactPerson') {
        return newMember?.name && newMember?.phone && newMember?.email && newMember?.title;
      }
      return true;
    }
    return false;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = e.currentTarget.name;
    const fieldValue = e.currentTarget.value;
    setNewMember({ ...newMember, [fieldName]: fieldValue });
  };

  const inputField = (label: string, fieldName: string) => {
    return (
      <div className={styles.column}>
        <label className="ar-label">{label} *</label>
        <input
          className="ar-text-field"
          type="text"
          value={newMember[fieldName]}
          name={fieldName}
          onChange={handleChange}
          onBlur={validateField}
        />
        {errors[fieldName] && <div className={styles.error}>{errors[fieldName]}</div>}
      </div>
    );
  };

  const validateField = (e: React.FocusEvent<HTMLInputElement>) => {
    const fieldName = e.currentTarget.name;
    const fieldValue = e.currentTarget.value?.trim();
    if (fieldName == 'name' || fieldName == 'title')
      setErrors({
        ...errors,
        [fieldName]: fieldValue == '' ? 'Detta fält är obligatoriskt' : '',
      });
    if (fieldName == 'email') setErrors({ ...errors, [fieldName]: validateEmail(fieldValue) });
    else if (fieldName == 'phone')
      setErrors({ ...errors, [fieldName]: validateOptionalPhone(fieldValue) });
  };

  const createUI = () => {
    return (
      <div className={styles.form}>
        <div className={styles.row}>
          {inputField('Namn', 'name')}
          {inputField('Titel', 'title')}
        </div>

        <div className={styles.row}>
          {inputField('E-post', 'email')}
          {inputField('Telefonnummer', 'phone')}
        </div>
      </div>
    );
  };

  return (
    <Modal
      hasCloseButton={false}
      title="Dela förfrågan med en kollega"
      show={showRequestShareModal}
      setShow={!btnLoader ? closeRequestShareModal : () => null}
      buttonInfo={
        !appState.isReadOnlyAccessRole() && {
          label: 'Dela',
          action: onSubmit,
          loaderShow: btnLoader,
          disabled: btnLoader || !checked?._id,
        }
      }
      isScrollable
    >
      <div className={styles.container}>
        {fetchingSupplierMembers ? (
          <span>
            <Loading type="inline" className={styles.loader} />
          </span>
        ) : (
          <>
            {(allowNewContacts ? [...supplierMembers, NEW_MEMBER] : [...supplierMembers])?.map(supMember => {
              const supMemberId = supMember?._id;
              const selected = checked?._id === supMemberId;
              const labelId = `checkbox-list-label-${supMemberId}`;
              const memberName =
                supMemberId === 'newContactPerson' ? 'Ny kontaktperson' : supMember?.name;
              return (
                <div
                  className={`
            ${selected ? styles.selected : ''}
            ${styles.rowContainer} 
            ${supMemberId === 'newContactPerson' ? styles.noBorder : ''}
            `}
                  key={supMemberId}
                  onClick={() => {
                    setNewMember(NEW_MEMBER);
                    setErrors({});
                    setChecked(supMember);
                  }}
                >
                  <div className={styles.radioIcon}>
                    <Radio
                      edge="start"
                      checked={selected}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                      checkedIcon={<span className={`${styles.icon} ${styles.checkedIcon}`} />}
                      icon={<span className={styles.icon} />}
                      className={styles.sharingModal}
                    />
                  </div>
                  <div className={styles.row}>
                    <div className={styles.column}>
                      <span className={styles.head}>{memberName}</span>
                      <span className={styles.subHead}>{supMember?.email}</span>
                    </div>
                    <div className={`${styles.column} ${styles.textAlignRight}`}>
                      <span className={styles.head}>{supMember?.title}</span>
                      <span className={styles.subHead}>{supMember?.phone}</span>
                    </div>
                  </div>
                </div>
              );
            })}
            {allowNewContacts && (
              <div
                className={`${
                  checked?._id !== 'newContactPerson' ? styles.disabled : styles.activeNewMemberForm
                }`}
              >
                {createUI()}
              </div>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default QuoteSharingModal;
