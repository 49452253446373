import React from 'react';

import { Formik, Form } from 'formik';
import appState from '../../state/AppStateContainer';
import { serviceProfile } from '../../services/service-profile';
import _ from 'lodash';
import '../app-create-project/app-create-project.css';
import './add-member.css';
import BaseComponent from '../BaseComponent';
import MultiSelect from '../../components/shared/MultiSelect';
import { userRolesDropdown, superAdminRolesDropdown } from '../../staticData';
import { NotificationManager } from 'react-notifications';
import BtGrid from '../bt-grid/bt-grid';
import BtRow from '../bt-row/bt-row';
import BtColumn from '../bt-column/bt-column';
import Field from '../../components/shared/Field/Field';
import Selector from '../../components/shared/Selector/Selector';
import { validateEmail } from '../../util';

export default class AddMember extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        email: '',
        role: '',
      },
      errorMessage: {},
      error: '',
      defaults: [],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props != nextProps) {
      const _project = nextProps.projects.map(p => ({ label: p.name, value: p.name, id: p._id }));
      this.setState({
        projects: _project,
      });
      if (!_.isEqual(this.props.currentMember, nextProps.currentMember) && nextProps.currentMember) {
        const { email, role, projects } = nextProps.currentMember;
        let currentMemberData = {
          email,
          role,
          projects: _project,
        };
        const showSuperRoles = appState.isSuperAdminOrg() && appState.isSuperAdmin();
        const hasPurchasing = appState.isPurchasingActive();
        let roles = showSuperRoles ? superAdminRolesDropdown : (hasPurchasing ? userRolesDropdown : [userRolesDropdown[0]]);
        
        currentMemberData.role = roles.find(r => r.val === role);
        this.setState({ data: currentMemberData });

        const projectsData = nextProps.currentMember.projects;
        const defaultData = this.state.projects;
        if (projectsData && projectsData.length) {
          let projects = [];
          if (projectsData[0].projectId && projectsData[0].projectId._id) {
            projects = projectsData
              .filter(project => project.projectId && project.projectId._id)
              .map(project => ({
                label: project.projectId && project.projectId.name,
                value: project.projectId && project.projectId.name,
                _id: project.projectId && project.projectId._id,
                id: project.projectId && project.projectId._id,
                areaIds: project.areaIds,
              }));
          } else {
            projects = defaultData.filter(p1 => projectsData.some(p2 => p1.id === p2.projectId));
          }
          this.setState({
            defaults: [...projects],
            error: '',
          });
        } else
          this.setState({
            defaults: [],
          });
      }
    }
  }

  reset = () => {
    this.setState({
      errorMessage: {},
      error: '',
      defaults: [],
      data: {
        email: '',
        role: '',
      },
    });
  }

  onClose = () => {
    this.reset();
    if (this.props.onClose) this.props.onClose();
  };

  onSelectProject = projects => {
    this.setState({
      defaults: [...projects],
    });
  };

  handleChange = (value, name) => {
    const { errorMessage } = this.state;
    let data = this.state.data;
    if (value.label) data.role = value;
    else data[name] = value;
    if(Object.values(errorMessage).length) {
      errorMessage[name] = "";
      this.setState({ errorMessage });
    }
    this.setState({ data });
  };

  getRemovedProjectsForUser = async (projects) => {
    const { currentMember } = this.props;
    const oldProjects = currentMember && currentMember.projects;
    let oldProjectsClone = _.cloneDeep(oldProjects);

    await oldProjectsClone.map((project, i) => {
      let projectFound = projects.find(p =>  p.projectId === (project.projectId && project.projectId._id));
      if(!projectFound){ 
        oldProjectsClone[i].removed = true;
        oldProjectsClone[i].projectId = oldProjectsClone[i].projectId._id;
      }
    });
    oldProjectsClone = oldProjectsClone.filter(p => p.removed);
    return [...oldProjectsClone, ...projects];
  }
  
  getProjectsForUser = async () => {
    const {defaults = [] } = this.state;
    const { currentMember } = this.props;
   return await defaults.map(project => { 
      const userProjects = currentMember && currentMember.projects;
      let areaIds = []
      if(userProjects && userProjects.length){ 
        let projectFound = userProjects.find(p => p.projectId && p.projectId._id == project.id);

        if(projectFound && projectFound.areaIds) areaIds = projectFound.areaIds;
      }
      return({ projectId: project.id, startDate: new Date(), areaIds }); 
    })
  }

  validateData = () => {
    const { data } = this.state;
    const errorMessage = {};
    
    if(!data.email) errorMessage.email = "Obligatoriskt";
    else if(data.email && validateEmail(data.email)) errorMessage.email = validateEmail(data.email);

    if(!data.role) errorMessage.role = "Obligatoriskt";

    this.setState({ errorMessage })
    return Object.values(errorMessage).length;
  }

  handleSubmit = async () => {
    const { data } = this.state;
    const { currentMember } = this.props;

    if(this.validateData()) return;

    try {
      this.setState({ error: '' });
      this.props?.toggleBtnLoader(true);
      // Add member
      let _data = {
        email: data.email,
        role: data.role.val,
        projects: await this.getProjectsForUser(),
      };
      let response;
      if (currentMember) {
        _data.projects = await this.getRemovedProjectsForUser(_data.projects);
        let userData = await Object.assign(currentMember, _data);
        response = await serviceProfile.dbUpdateMember(userData);

        this.props?.updateMemberLocally(response?.data);
        
      } else {
        response = await serviceProfile.dbAddMember(_data);
        if (response.status == 200 && response?.user) this.props?.addMemberLocally(response?.user); 
      }
      if (response.status == 200) {
        NotificationManager.success('Sparad');
      } else {
        NotificationManager.error('Ett fel inträffade');
      }

      this.onClose();
    } catch (e) {
      this.handleError(e);
    } finally {
      this.props?.toggleBtnLoader(false);
    }
  };

  handleError = (e) => {
    const errorKey = _.get(e, 'response.data.error', 'error.unexpectedError');
    const errorMessage = this.props.intl.formatMessage({ id: errorKey });

    if(errorKey == "error.emailalreadytaken") this.setState({ errorMessage: { email: errorMessage }});
    else this.setState({ error: errorMessage });
    
    console.log({ errorKey, errorMessage });
    }

  renderFormSlide = () => {
    const { error, data, projects, defaults, errorMessage } = this.state;
    const { currentMember } = this.props;
    const isSuperAdmin = appState.getCurrentOrgSlug() && appState.getCurrentOrgSlug() === "superadmin" && appState.isSuperAdmin();
    const hasPurchasing = appState.isPurchasingActive();
    const isNonAdminUser = appState.isNonAdminUser();

    return (
      <div className="member-modal">
        <div className={`error ${error ? 'show' : 'hide'}`}>
         <p>{_.isObject(error) ? 'Ett fel inträffade' : error}</p>
        </div>
        
        <Field
          label='E-post'
          required
          value={data.email}
          onChange={(val) => this.handleChange(val, "email")}
          errorMessage={errorMessage?.email}
          style={{ fontSize: '14px' }}
          disabled={!!currentMember}
          className={`${currentMember ? 'disabled' : ''}`}
        />
        <Selector
          label='Roll'
          required
          options={isSuperAdmin ? superAdminRolesDropdown : (hasPurchasing ? userRolesDropdown : [userRolesDropdown[0]])}
          placeholder="Välj"
          value={data.role}
          onChange={val => this.handleChange(val, "role")}
          errorMessage={errorMessage?.role}
          isDisabled={isNonAdminUser}     
        />

       {!isSuperAdmin && hasPurchasing && <label className="ar-label ar-label-multiselect ar-select-label add-member">
          <span className="Projekt-label">Projekt</span>
          <MultiSelect
            placeholder="Välj"
            className="ar-multiselect-field ar-select-field"
            data={projects}
            bottomOption
            onSelect={this.onSelectProject}
            defaultData={defaults}
            hideSuggestion={true}
          />
        </label>}
      </div>
    );
  };

  render() {
    const { data } = this.state;

    return (
      <div className="app-add-supplier app-create-project" ref={el => (this.element = el)}>
        <div className="page-paddings">
          <BtGrid>
            <BtRow>
              <BtColumn className="colum-left pp-col-left">
                <div className="content-left">
                {data && (
                    <Formik>
                      {({ resetForm }) => (
                        <Form autoComplete="off">
                          <div className="mx-content">
                            {this.renderFormSlide()}
                          </div>
                        </Form>
                      )}
                    </Formik>
                )}
                </div>
              </BtColumn>
            </BtRow>
          </BtGrid>
        </div>
      </div>
    );
  }
}
