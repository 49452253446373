import moment from "moment";
import { FC, useState } from "react";
import appState from "../../state/AppStateContainer";
import { NotificationManager } from 'react-notifications';
import Modal from "../../components/shared/Modal/Modal";
import Field from "../../components/shared/Field/Field";
import DatePicker from "../../components/shared/DatePicker/DatePicker";
import EmailTemplate from "../../components/shared/EmailTemplate/EmailTemplate";

type Props = {
  show: boolean,
  setShow: (value: boolean) => void,
  defaultDate: Date,
  onSave: ({ date, emailText }: { date: Date, emailText: string }) => Promise<void>,
  data: { areaName: string, projectName: string, version: number },
}

const DateExtensionModal: FC<Props> = ({
  show,
  setShow,
  defaultDate,
  onSave,
  data
}) => {
  const [date, setDate] = useState(defaultDate || new Date());
  const [emailText, setEmailText] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const { areaName, projectName, version } = data;
  const { name, phone } = appState.state.token;
  const organizationName = appState.getCurrentOrgName();

  const onSubmit = async () => {
    setIsSaving(true);
    try {
      await onSave({ date: moment(date).endOf('day').utc().toDate(), emailText });
      NotificationManager.success('Uppdaterade utgångsdatumet');
    } catch (e) {
      let message = "Okänt fel"
      try {
        const err = (e as { message: any, response: {data: {message: string}}});
        message = err.message + " – " + err?.response?.data?.message;
      } catch (e2) {}
      NotificationManager.error(message);
    }
    setIsSaving(false);
  };

  return (
    <Modal
      title='Ändra anbudstid'
      show={show}
      setShow={setShow}
      buttonInfo={{ label: 'Skicka', action: onSubmit, loaderShow: isSaving }}
      hasCloseButton
    >
      <div className='dateExtensionModal'>
        <p>Välj nytt datum och skriv ett meddelande nedan för att ändra utgångsdatum. Följande e-postmeddelande skickas till leverantörens kontaktpersoner:<br/><br/></p>
        <EmailTemplate>
          <p>Hej &lt;Förnamn&gt;,</p>
          <p>{name} på {organizationName} har ändrat anbudstiden för {areaName} till projektet {projectName}.</p>
          
          <a style={{ alignSelf: 'center', backgroundColor: 'var(--orange-500)', padding: '16px', borderRadius: '6px', cursor: 'not-allowed', color: 'var(--white)', textDecoration: 'none', }}>
            Öppna anbudssidan i Accurator
          </a>
          <div>
            <p><strong>Anbudsinformation:</strong></p>
            <ul style={{ paddingLeft: '32px', margin: 0 }}>
              <li><p>Kategori: {areaName}</p></li>
              <li><p>Version: {version + 1}</p></li>
              <li>
                <p className='dateExtensionModalDateContainer'>
                  <span>Utgångsdatum:</span>
                  <DatePicker
                    minDate={new Date()}
                    selected={date}
                    onChange={setDate}
                  />
                </p>
              </li>
            </ul>
          </div>
          
          <div>
            <p><strong>Avsändare:</strong></p>
            <ul style={{ paddingLeft: '32px', margin: 0}}>
              <li><p>Namn: {name}</p></li>
              {phone ? <li><p>Telefonnummer: {phone}</p></li> : ''}
              <li><p>Företag: {organizationName}</p></li>
            </ul>
          </div>
          
          <div>
            <p><strong style={{ display: 'block', paddingBottom: '5px' }}>Meddelande från avsändaren:</strong></p>
            <Field multiline value={emailText} onChange={setEmailText} /><br/>
          </div>
        </EmailTemplate>
      </div>
    </Modal>
  )
}

export default DateExtensionModal;