import { FC } from "react";
import styles from "./XIconWrapper.module.scss";
import { ReactComponent as CloseIcon } from '../../../assets/svg/CloseIcon.svg';

type XIconWrapperProps = {
  onClick: () => void
}

const XIconWrapper: FC<XIconWrapperProps> = ({ onClick, children }) => {
  return (
    <div className={styles.xIconWrapper}>
      {children}
      <CloseIcon onClick={onClick} />
    </div>
  )
}

export default XIconWrapper;