import { FC, useCallback, useEffect, useState } from "react";
import styles from "./OverviewTab.module.scss";
import XIconWrapper from "../../XIconWrapper/XIconWrapper";
import TemplateItem, { TemplateOptionType } from "../../TemplateItem/TemplateItem";
import NewsCard from "../../NewsCard/NewsCard";
import appState from "../../../../state/AppStateContainer";
import { ServiceUser } from "../../../../services/service-user";
import { RSSFeedType, TemplateOptionCollectionType } from "../../helper";
import { ServiceDashboard } from "../../../../services/service-dashboard";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getImageUrl } from "../../../../components/shared/ImagePreview/ImagePreview";
import { FileScope } from "../../../../util/appEnum";
import _, { clamp } from "lodash";
import { autosave, getRandomGenerator } from "../../../../util";
import moment from "moment";

export enum OverviewItemType {
  'SMALL'= 'SMALL',
  'MEDIUM'= 'MEDIUM',
  'LARGE'= 'LARGE',
}

const Template = [
  OverviewItemType.SMALL, OverviewItemType.SMALL, OverviewItemType.SMALL,
  OverviewItemType.MEDIUM, OverviewItemType.LARGE,
  OverviewItemType.SMALL, OverviewItemType.SMALL, OverviewItemType.SMALL,
  OverviewItemType.MEDIUM, OverviewItemType.LARGE,
] as const;

type Props = RouteComponentProps & {
  rssFeed: RSSFeedType,
  templateOptions: TemplateOptionCollectionType,
}

const random = getRandomGenerator(moment().format('YYYY DDD H'));

const OverviewTab: FC<Props> = ({ templateOptions, rssFeed, history }) => {
  const [newsAndAds, setNewsAndAds] = useState<RSSFeedType>([]);

  const overviewTemplatePreference: (string | null)[] = appState.getDashboardOverviewPreferences();
  const [overviewTemplate, setOverviewTemplate] = useState<(TemplateOptionType | null)[]>([]);
  const updateOverviewTemplate = (template: (TemplateOptionType | null)[]) => {
    const finalTemplate = template.map(item => item ? item.id : null);
    
    autosave(async () => await ServiceUser.updatePreferences(appState.getUserId(), finalTemplate, 'dashboard.overview'));
    appState.setDashboardOverviewPreferences(finalTemplate);
  }
  const [hasLoadedPreferences, setHasLoadedPreferences] = useState(false);

  const getItemFromId = useCallback((itemId: string | null) => {
    if (!itemId) return null;
    
    const foundTemplateOption = templateOptions[itemId];

    if (!foundTemplateOption) return null;

    return foundTemplateOption;
  }, [templateOptions])
  
  useEffect(() => {
    (async () => {
      const featuredSuppliers = (await ServiceDashboard.getFeaturedSuppliers()).map((supplier: { featuredInfo: { title: string, url: string, image: {file: string}, description: string, }, _id: string }) => {
        return {
          title: supplier?.featuredInfo?.title,
          link: supplier?.featuredInfo?.url,
          image: getImageUrl(supplier?.featuredInfo?.image?.file, appState.getCurrentOrgSlug(), FileScope.FeaturedSupplierImage, { supplierId: supplier._id }),
          description: supplier?.featuredInfo?.description,
          supplierId: supplier._id,
          onFeaturedSupplierClick: async () => {
            await ServiceDashboard.logRegistryLookup(supplier._id);
            const url = appState._getCurrentOrgUrl();
            history.push(`${url}/supplier-database/featured/${supplier._id}`);
          },
        }
      });

      const newsAndAds = [...featuredSuppliers, ...rssFeed.slice(0, clamp(5 - (featuredSuppliers?.length ?? 0), 0, 5))];

      for (let i = newsAndAds.length - 1; i >= 1; i--) {
        const j = Math.floor(random() * (i+1));
        const temp = newsAndAds[i];
        newsAndAds[i] = newsAndAds[j];
        newsAndAds[j] = temp;
      }

      setNewsAndAds(newsAndAds);

      if (!overviewTemplatePreference || overviewTemplatePreference?.length < 10) {
        updateOverviewTemplate(['21', '4', '16', '33a', '1', '23', '24', '19', '7a', '13'].map(getItemFromId));
      } else {
        const overviewTemplate = overviewTemplatePreference.map(getItemFromId);
        setOverviewTemplate(overviewTemplate);
      }

      setHasLoadedPreferences(true);
    })();
  }, []);

  useEffect(() => {
    if (hasLoadedPreferences && !_.isEmpty(overviewTemplatePreference)) {
      const overviewTemplate = overviewTemplatePreference.map(getItemFromId);
      setOverviewTemplate(overviewTemplate);
    }
  }, [overviewTemplatePreference, templateOptions, hasLoadedPreferences]);

  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        {overviewTemplate.map((item, index:number) => {
          return (
            item ? (
              <XIconWrapper key={item.id} onClick={() => {
                const newTemplate = [...overviewTemplate];
                const index = newTemplate.findIndex(x => x?.id === item.id);
                newTemplate[index] = null;

                updateOverviewTemplate(newTemplate);
              }}>
                {item.component}
              </XIconWrapper>
            ) : (
              <TemplateItem
                key={`item-index-${index}`}
                template={overviewTemplate}
                setTemplate={updateOverviewTemplate}
                index={index}
                options={
                  Object
                  .entries(templateOptions)
                  .filter(([key, option]) =>
                    option.itemType === Template[index] && !overviewTemplate.find(item => item?.id === key)
                  ).map(([_, option]) => option)}
              />
            )
          )
        }
        )}
      </div>
      <div className={styles.newsColumn}>
        {newsAndAds.map(feed => <NewsCard key={feed.link} {...feed} />)}
      </div>
    </div>
  )
}

export default withRouter(OverviewTab);